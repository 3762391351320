import React, { useEffect, useRef, useState } from "react";
import TopBar from "./TopBar";
import styles from "./ModalNew.module.css";

const ModalNew = ({ show, children, style, closeHandler, ...props }) => {
  const [drag, setDrag] = useState(false);
  const modalContainerRef = useRef(null);
  const mouseDownHandler = (event) => {
    if (event.clientX >= modalContainerRef.current.scrollWidth)
      // alert('vertical scrollbar clicked');
      setDrag(true);
    else setDrag(false);
  };

  const mouseMoveHandler = (e) => {
    setDrag(true);
  };

  const mouseUpHandler = (e) => {
    if (!drag) closeHandler();
  };

  useEffect((show) => {
    if (show) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  if (show)
    return (
      <div>
        <div
          className={styles.Overlay}
          onMouseDown={mouseDownHandler}
          onMouseMove={mouseMoveHandler}
          onMouseUp={props?.disableOutsideClick ? null : mouseUpHandler}
        ></div>
        <div
          className={styles.ModalContainer}
          ref={modalContainerRef}
          onMouseDown={mouseDownHandler}
          onMouseMove={mouseMoveHandler}
          onMouseUp={props?.disableOutsideClick ? null : mouseUpHandler}
        >
          <div
            onMouseUp={(e) => e.stopPropagation()}
            className={[styles.Modal, styles.NewScrollBar].join(" ")}
            style={style}
          >
            {children}
          </div>
        </div>
      </div>
    );
  return null;
};

export default ModalNew;

const modalStyle = {
  maxWidth: 700,
  // margin: "10% 0",
  // top: 0,
  borderRadius: "8px",
  // transform: "translateX(-50%)",
  position: "relative",
  background: "transparent",
};

export const ModalWithHeader = (props) => {
  if (props.maxWidth) modalStyle["maxWidth"] = props.maxWidth;
  if (props.minWidth) modalStyle["minWidth"] = props.minWidth;
  if (props.margin) modalStyle["margin"] = props.margin;
  return (
    <ModalNew
      style={{ ...modalStyle }}
      className={styles.modalHeader}
      {...props}
    >
      <TopBar
        icon={props.icon}
        title={props.title}
        close={props.closeHandler}
      />
      {props.children}
    </ModalNew>
  );
};

export const ModalWithoutHeader = (props) => {
  if (props.maxWidth) modalStyle["maxWidth"] = props.maxWidth;
  return (
    <ModalNew style={{ ...modalStyle }} {...props}>
      {props.children}
    </ModalNew>
  );
};
