import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "../../../Profile.module.css";
import { Box } from "@mui/material";

class CoverFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  render() {
    return (
      <div
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          height: 180,
          borderRadius: 15,
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 180,
            overflow: "hidden",
            borderRadius: 10,
          }}
        >
          <img
            src={
              this.props.coverPicUrlview
                ? this.props.coverPicUrlview
                : this.props.userTypeView === "INDIVIDUAL"
                ? "/Image/Profile/cover.svg"
                : "/Image/Business Profile/Business_design_cover.png"
            }
            className={styles.coverPicture}
          />
        </div>

        {this.props.userTypeView === "LOCAL BUSINESS" && (
          <Box
            sx={{
              background: "#FFD221",
              color: "#000",
              fontWeight: 800,
              fontSize: "14px",
              p: "5px 16px",
              position: "absolute",
              left: 0,
              top: 20,
              fontStyle: "italic",
              borderRadius: "0px 10px 10px 0px",
            }}
          >
            Business
          </Box>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    coverPicUrl: state.profile.userCoverPic,
    coverPicUrlview: state.profile.userCoverPicView,
    userTypeView: state.profile.user_type_view,
  };
};

export default connect(mapStateToProps, null)(CoverFeed);
