import React from "react";
import "./CSS/SalePage.css";
import { lazy, Suspense } from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { useContext } from "react";
import { DiscoverContext } from "./DiscoverContext";
import SaleCardNew from "./SaleCardNew";
import MyFavouritesSidebar from "./MyFavouritesSidebar";
import { useState } from "react";
import { useEffect } from "react";

const EventCard = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./EventCard")), 1000);
  });
});

const BuisnessCard = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./BuisnessCard")), 1000);
  });
});

const MyFavourites = () => {
  const { state, getMyFavourites } = useContext(DiscoverContext);
  const [params, setParams] = useState({
    event: true,
    deals: true,
    stores: true,
  });

  useEffect(() => {
    getMyFavourites(params);
  }, [params]);

  return (
    <div className="sale_page_container">
      <div className="sale_page_sidebar">
        <MyFavouritesSidebar params={params} setParams={setParams} />
      </div>

      <div className="parent_sale">
        <div className="sale_top">
          <h1>My Favourites</h1>
        </div>

        <div className="sale_bottom">
          {state?.myFavourites?.sell?.length === 0 &&
            state?.myFavourites?.events?.length === 0 &&
            state?.myFavourites?.Business?.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={"/Image/apps/profile-resume-edit-view/no_result.jpeg"}
                  style={{ width: "40%" }}
                />
              </Box>
            )}
          {state?.myFavourites?.sell?.map((sell, index) => (
            <div key={index}>
              <Suspense
                fallback={
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={200} height={150} />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={180}
                      sx={{ fontSize: "1rem" }}
                    />
                  </Stack>
                }
              >
                <SaleCardNew
                  sell={sell.post_details}
                  removeFromWishlist={true}
                  id={sell.id}
                  params={params}
                />
              </Suspense>
            </div>
          ))}
        </div>

        <div className="events_bottom">
          {state?.myFavourites?.events?.map((event, index) => (
            <div key={index}>
              <Suspense
                fallback={
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={270} height={170} />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={250}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={30}
                      sx={{ fontSize: "1rem", marginTop: "20px" }}
                    />
                    <Skeleton variant="rounded" width={270} height={60} />
                  </Stack>
                }
              >
                <EventCard
                  event={event.post_details}
                  removeFromWishlist={true}
                  id={event.id}
                  params={params}
                />
              </Suspense>
            </div>
          ))}
        </div>

        <div className="buisness_bottom">
          {state?.myFavourites?.Business?.map((item, index) => (
            <div key={index}>
              <Suspense
                fallback={
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={270} height={170} />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={250}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={30}
                      sx={{ fontSize: "1rem", marginTop: "20px" }}
                    />
                    <Skeleton variant="rounded" width={270} height={60} />
                  </Stack>
                }
              >
                <BuisnessCard
                  business={item?.profile_details?.user_details[0]}
                  photo={item?.profile_details?.profile_pic}
                  removeFromWishlist={true}
                  id={item?.id}
                  params={params}
                />
              </Suspense>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyFavourites;
