import React, { useState } from "react";
import styles from "./css/mymessage.module.css";
import OptionModal from "./OptionModal";
import EditModal from "./EditModal";

export default function MyMessage(props) {
    const { message, deleteMessage, id, updateMessages, channelId, socket } =
        props;
    const [open, setOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    function handleOptionClick() {
        setOpen(true);
    }

    function handleModalClose() {
        setOpen(false);
    }

    function handleEditModalClose() {
        setShowEditModal(false);
    }

    function handleListItemClick(action) {
        if (action === "delete") {
            deleteMessage(id).finally(() => {
                updateMessages(channelId);
                setOpen(false);
            });
        } else if (action === "edit") {
            setOpen(false);
            setShowEditModal(true);
        }
    }

    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                {message}
                <img
                    onClick={handleOptionClick}
                    src="/Image/chat/options.svg"
                    style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        height: "13.64px",
                        width: "3.9px",
                    }}
                />
                <OptionModal
                    showEdit
                    open={open}
                    handleClose={handleModalClose}
                    handleListItemClick={handleListItemClick}
                />
                <EditModal
                    socket={socket}
                    open={showEditModal}
                    defaultValue={message}
                    handleClose={handleEditModalClose}
                    channelId={channelId}
                    messageId={id}
                />
            </div>
        </div>
    );
}
