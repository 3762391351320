import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const dealsList = [
  {
    src: "/Image/DashImage/Deals/Lifestyle.jpg",
    offer: "Get Upto 30% off",
    desc: "Only at Lifestyle Shop, Rohini Branch",
  },
  {
    src: "/Image/DashImage/Deals/haldiram.jpg",
    offer: "Get Upto 30% off",
    desc: "Only at Haldiram's, Rohini Branch",
  },
  {
    src: "/Image/DashImage/Deals/reliancesmart.webp",
    offer: "Get Upto 30% off",
    desc: "Only at Reliance Smart, Rohini Branch",
  },
];

const DealsCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      //   showDots={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      arrows={false}
      infinite={true}
    >
      {dealsList.map((deal, idx) => (
        <Box
          key={idx}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              width: "90%",
              height: "50vh",
              borderRadius: "20px",
              objectFit: "cover",
              position: "relative",
              background: `linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,1)), url('${deal.src}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              boxShadow: "0px 0px 10px 1px #888888",
              mt: 2,
              mb: 2,
            }}
          ></Card>
          <Box
            sx={{
              position: "absolute",
              bottom: 10,
              left: 30,
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              mb: 2,
            }}
          >
            <img
              src="/Image/DashImage/Deals/deals_1_icon.svg"
              alt="deals"
              style={{ width: "25px" }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#fff",
                textAlign: "left",
              }}
            >
              {deal.offer}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#fff",
                textAlign: "left",
              }}
            >
              {deal.desc}
            </Typography>
          </Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default DealsCarousel;
