import React from 'react'
import styles from "./css/PriceBar.module.css"
import { useTranslation } from "react-i18next";


const PriceBar = (props) => {

    // props -> price
    // props -> teacher
    // props -> remove
    // props -> shortDesc

    const { t } = useTranslation();


  return (
        <>
            <div className={styles.PriceDetails}>
                {props.remove ? null : <p className={styles.price}>{ props.price ?  ("$ " + props.price) : t("common.free") } </p>}
                <p className={styles.priceText} >{props.teacher ? null : props.shortDesc }</p>
            </div>
        </>
    )
}

export default PriceBar