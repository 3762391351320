export const GET_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const GET_GROUP_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;
export const GET_CLASSES_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const GET_GROUPS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;
export const POST_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const POST_GROUPS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;
export const POST_PRODUCT_SERVICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/productservice/`;
export const GET_PRODUCT_SERVICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/productservice/getProductService?group_id=`;
export const DELETE_PRODUCT_SERVICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/productservice/`;
export const EDIT_PRODUCT_SERVICE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/productservice/`;
export const GET_DASHBOARD_POST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`;
export const POST_LIKE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/like/`;
export const COMMENT_LIKE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/commentlike/`;
export const POST_COMMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/comment/`;
export const POST_COURSE_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/course/`;
export const POST_MEMBERS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/members/`;
export const INVITE_VIA_EMAIL = `${process.env.REACT_APP_API_BASE_URL}api/v1/members/invite-group-members/`;
export const UPDATE_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const GET_USER_SUGGESTIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/suggestions/`;
export const GET_CLASS_ID_BY_CODE = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/code/`;
export const GET_GROUP_ID_BY_CODE = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/code/`;
export const JOIN_GROUP_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/members/add-group-members/`;
export const GET_VALIDATE_GROUP_MEMBER = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/validmember/`;

export const DELETE_MEMBER_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/members/`;
export const GET_USER_SETTINGS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/user/setting/`;
export const PUT_USER_SETTINGS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/user/setting/`;
export const PUT_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const PUT_GROUP_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;

export const PAYMENT_CREATE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/payments/`;
export const PAYMENT_SUCCESS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/payments/success/`;
export const PAYMENT_FAILURE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/payments/failure/`;

export const TRANSLATE_DATA_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/translations/translate/`;
export const CONNECTIONS_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/connections/`;
export const GET_STUDENT_ASSIGNMENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/assignment-list/`;
export const GET_STUDENT_INDIVIDUAL_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const GET_ALL_ASSIGNMENTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const SUBMIT_ASSIGNMENT_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const GET_STUDENT_SUBMISSION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const UPDATE_STUDENT_ASSIGNMENT_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
// Assignment and Quiz

export const POST_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const UPDATE_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const GET_ASSIGNMENT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const PUT_ASSIGNMENT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;

export const GET_INDIVIDUAL_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const GET_STUDENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/student_list/`;
export const DELETE_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const SAVE_ASSIGNMENT_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const RETURN_ASSIGNMENT_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/graded/`;
export const GET_ALL_ASSIGNMENT_SUBMISSIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;

export const GET_QUIZ_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_STUDENT_INDIVIDUAL_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_STUDENT_QUIZ_SUBMISSION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;
export const SUBMIT_QUIZ_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;
export const UPDATE_STUDENT_QUIZ_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;

export const POST_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_INDIVIDUAL_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const PUT_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const DELETE_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_QUIZ_STUDENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/student_list/`;
export const RETURN_QUIZ_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/graded/`;
export const SAVE_QUIZ_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;

export const GET_ALL_QUIZ_SUBMISSIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;
export const GET_INDIVIDUAL_QUIZ_SUBMISSIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;

export const GET_STUDENT_QUIZ_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/quiz_list/`;
export const GET_ALL_ASSIGNMENT_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/quiz_assign_order/`;

export const POST_ASS_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/assign-comments/`;
export const POST_QUIZ_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-comments/`;
export const GET_ASS_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/assign-comments/?assign_id`;
export const GET_QUIZ_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-comments/?quiz_id`;

export const ASSIGN_STUDENTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/enrol-students/`;
export const UNASSIGN_STUDENTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/unassign-all/`;
export const GRADE_ZERO_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/grade-zero/`;

export const GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/get-assignment-submission/`;
