import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 880,
  boxShadow: 24,
};

export default function VideoModal({ playVideo, setPlayVideo }) {
  return (
    <div>
      <Modal
        open={playVideo}
        onClose={() => setPlayVideo(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            width="880"
            height="495"
            src="https://www.youtube.com/embed/phUE4GnVnNw?si=aehxnprQrjoR9MBi?&autoplay=1&vq=hd1080"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            allowfullscreen
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
}
