import React, { Component } from "react";
import MiddleContain from "./MiddleContain";
import { ModalWithHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import {
  getGroups,
  postGroup,
  editGroup,
  deleteGroup,
} from "../../../redux/actions/groups";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { handleErrorMessage } from "../../../redux/actions/classes";
// import MiddleContain from "../ClassModal/MiddleContain";

class GroupModal extends Component {
  state = {
    groupName:
      this.props.actionType == "EDIT" ? this.props?.editData?.name : "",
    groupDescription:
      this.props.actionType == "EDIT" ? this.props?.editData?.description : "",
    groupGrade:
      this.props.actionType == "EDIT" ? this.props?.editData?.grade : "",
    // groupSubject:
    //   this.props.actionType == "EDIT" ? this.props?.editData?.subject : "",
    groupId: this.props.actionType == "EDIT" ? this.props?.editData?.id : "",
    groupType:
      this.props.actionType == "EDIT" ? this.props?.editData?.group_type : "",
  };
  getState = () => {
    this.setState({
      groupName:
        this.props.actionType == "EDIT" ? this.props?.editData?.name : "",
      groupDescription:
        this.props.actionType == "EDIT"
          ? this.props?.editData?.description
          : "",
      groupGrade:
        this.props.actionType == "EDIT" ? this.props?.editData?.grade : "",
      // groupSubject:
      //   this.props.actionType == "EDIT" ? this.props?.editData?.subject : "",
      groupId: this.props.actionType == "EDIT" ? this.props?.editData?.id : "",
      groupType:
        this.props.actionType == "EDIT"
          ? this.props?.editData?.groupType
          : "PUBLIC",
    });
  };

  componentDidMount() {
    this.getState();
  }
  openwelcome = () => {
    this.props.closeModal();
    this.props.showWelcome();
  };

  onGroupNameChange = (e) => {
    this.setState({ groupName: e.target.value });
  };
  onGroupDescriptionChange = (e) => {
    this.setState({ groupDescription: e.target.value });
  };
  onGroupGradeChange = (e) => {
    this.setState({ groupGrade: e.target.value });
  };
  // onGroupSubjectChange = (e) => {
  //   this.setState({ groupSubject: e.target.value });
  // };
  onGroupTypeChange = (e) => {
    console.log("whtvalue", e.target.value);

    this.setState({ groupType: e.target.value });
  };

  // makeid = () => {
  //   var userData = localStorage.getItem("userData");
  //   var parsedUserData = JSON.parse(userData);
  //   var randomString = "";
  //   var result = "";
  //   var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   var charactersLength = 3;
  //   for (var i = 0; i < 3; i++) {
  //     randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }

  //   result =
  //     parsedUserData.token[0] +
  //     parsedUserData.token[1] +
  //     parsedUserData.token[29] +
  //     parsedUserData.token[28] +
  //     randomString +
  //     parsedUserData.token[14] +
  //     parsedUserData.token[16] +
  //     parsedUserData.token[15];

  //   return result;
  // };

  onGroupAdd = () => {
    let addedGroup = {};
    addedGroup.groupName = this.state.groupName;
    addedGroup.groupDescription = this.state.groupDescription;
    addedGroup.groupType = this.state.groupType;
    // addedGroup.groupCategory =this.state.groupCategory;
    addedGroup.groupGrade = "A";
    // addedGroup.groupSubject = this.state.groupSubject;
    // addedGroup.code = this.makeid();

    // if (true) {
    //   this.props.postGroup(addedGroup).then(() => this.props.getGroups());
    //   this.setState({
    //     groupName: "",
    //     groupDescription: "",
    //     groupType: "",
    //     // groupCategory:'Social',
    //     groupGrade: "A",
    //     // groupSubject: "Physics",

    //   });
    //   this.props.closeModal();
    // }
    if (addedGroup.groupName.length > 30) {
      this.props.handleErrorMessage("Group name should be less than 30 chars!");
      return;
    }
    if (addedGroup.groupDescription.length > 50) {
      this.props.handleErrorMessage(
        "Group description should be less than 50 chars!"
      );
      return;
    }
    if (addedGroup.groupName != "") {
      this.props.postGroup(addedGroup).then(() => this.props.getGroups());
      this.setState({
        groupName: "",
        groupDescription: "",
        groupType: "",
        // groupCategory:'Social',
        groupGrade: "A",
        // groupSubject: "Physics",
      });
      this.props.closeModal();
    } else {
      this.props.handleErrorMessage("Please fill required fields!");
    }
  };

  onGroupEdit = () => {
    let editedGroup = {};
    editedGroup.groupName = this.state.groupName;
    editedGroup.groupDescription = this.state.groupDescription;
    editedGroup.groupGrade = this.state.groupGrade;
    // editedGroup.groupSubject = this.state.groupSubject;
    editedGroup.groupType = this.state.groupType;

    editedGroup.groupId = this.props?.editData?.id;

    // console.log(editedGroup)
    // if(addedGroup.groupName != "" && addedGroup.groupGrade != "" && addedGroup.groupSubject != "" ){
    this.props.editGroup(editedGroup);
    this.setState({
      groupName: "",
      groupDescription: "",
      groupGrade: "",
      groupType: "",
      // groupSubject: "",
    });
    this.props.closeModal();
    // }
    // else{
    //   this.props.handleErrorMessage("Please fill required fields!");
    //   this.props.closeModal();
    // }
  };

  onGroupDelete = () => {
    let id = this.props.editData.id;
    this.props.deleteGroup(id);
    this.props.closeModal();
  };

  render() {
    return (
      <div>
        {this.props.actionType == "EDIT" ? (
          <ModalWithHeader
            title={
              this.props.actionType == "EDIT"
                ? t("group.editGroup")
                : this.props.t("common.createGroup")
            }
            show={this.props.show}
            closeHandler={this.props.closeModal}
            maxWidth="605px"
            minWidth="350px"
          >
            <div
              style={{ backgroundColor: "#fff", borderRadius: "0 0 15px 15px" }}
            >
              <MiddleContain
                value={4}
                open={this.openwelcome}
                onGroupAdd={this.onGroupAdd}
                onGroupEdit={this.onGroupEdit}
                onGroupNameChange={this.onGroupNameChange}
                onGroupDescriptionChange={this.onGroupDescriptionChange}
                onGroupGradeChange={this.onGroupGradeChange}
                onGroupTypeChange={this.onGroupTypeChange}
                onGroupSubjectChange={this.onGroupSubjectChange}
                editData={this.props.editData}
                actionType={this.props.actionType}
              />
            </div>
          </ModalWithHeader>
        ) : this.props.actionType == "DELETE" ? (
          <ModalWithHeader
            title={t("common.delete")}
            show={this.props.show}
            closeHandler={this.props.closeModal}
            style={{ maxWidth: 400  }}
          >
            <div
              style={{ backgroundColor: "#fff", borderRadius: "0 0 15px 15px" }}
            >
              <MiddleContain
                value={4}
                open={this.openwelcome}
                onGroupAdd={this.onGroupAdd}
                onGroupEdit={this.onGroupEdit}
                onGroupDelete={this.onGroupDelete}
                onGroupNameChange={this.onGroupNameChange}
                onGroupDescriptionChange={this.onGroupDescriptionChange}
                onGroupGradeChange={this.onGroupGradeChange}
                onGroupTypeChange={this.onGroupTypeChange}
                onGroupSubjectChange={this.onGroupSubjectChange}
                editData={this.props.editData}
                actionType={this.props.actionType}
              />
            </div>
          </ModalWithHeader>
        ) : (
          <ModalWithHeader
            title={
              this.props.actionType == "EDIT"
                ? t("group.editGroup")
                : this.props.t("common.createGroup")
            }
            show={this.props.show}
            closeHandler={this.props.closeModal}
            maxWidth="605px"
            minWidth="350px"
          >
            <div
              style={{ backgroundColor: "#fff", borderRadius: "0 0 15px 15px" }}
            >
              <MiddleContain
                value={4}
                onGroupAdd={this.onGroupAdd}
                onGroupEdit={this.onGroupEdit}
                onGroupNameChange={this.onGroupNameChange}
                onGroupDescriptionChange={this.onGroupDescriptionChange}
                onGroupGradeChange={this.onGroupGradeChange}
                onGroupTypeChange={this.onGroupTypeChange}
                onGroupSubjectChange={this.onGroupSubjectChange}
                editData={this.props.editData}
                actionType={this.props.actionType}
              />
            </div>
          </ModalWithHeader>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    //   subjects:state.classes.classes.map((each_class)=>{ return {id:each_class.id,
    //     name:each_class.name,
    //     grade:each_class.grade,
    //     subject:each_class.subject,
    //     image_url:each_class.cover_image || "./Image/DashImage/profile.png"}
    //   }) || [],
    //   routeType:'/class'
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postGroup: (data) => dispatch(postGroup(data)),
    getGroups: () => dispatch(getGroups()),
    editGroup: (data) => dispatch(editGroup(data)),
    deleteGroup: (data) => dispatch(deleteGroup(data)),
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(GroupModal)
);
