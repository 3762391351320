import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { categoryList } from "../../../../../../template/DummyData";
import SaveButton from "../../../../../../components/commons/SaveButton";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileData } from "../../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../../redux/constants/actionTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function AddCategory({ edit, setEdit }) {
  const dispatch = useDispatch();
  const businessCategory = useSelector(
    (state) => state.profile.business_category
  );
  const [category, setCategory] = useState("");

  useEffect(() => {
    if (businessCategory) {
      setCategory(businessCategory);
    }
  }, [businessCategory]);

  const SaveButtonOnClick = () => {
    let data = new FormData();
    data.append("business_category", category);

    dispatch(updateProfileData(EDIT_PROFILE_DATA, data)).then((res) => {
      if (res.val) {
        document.body.style.overflow = "visible";
        return;
      }
    });
    document.body.style.overflow = "visible";
    setEdit(false);
  };

  return (
    <div>
      <Modal
        open={edit}
        onClose={() => setEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {businessCategory
              ? "Edit Business Category"
              : "Add Business Category"}
          </Typography>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                sx: {
                  padding: "16.5px 0px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                fontSize: "17px",
                fontWeight: 600,
                lineHeight: "24px",
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
            >
              <MenuItem
                value=""
                sx={{
                  fontSize: "17px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  background: "none",
                }}
              >
                <img
                  src="/Image/CategoryIcons/Other.svg"
                  alt="select category"
                  style={{ marginRight: 8 }}
                />
                Select category
              </MenuItem>
              {categoryList.map((category, index) => (
                <MenuItem
                  key={index}
                  value={category}
                  sx={{
                    fontSize: "17px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    background: "none",
                  }}
                >
                  <img
                    src={`/Image/CategoryIcons/${category}.svg`}
                    alt="select category"
                    style={{ marginRight: 8, width: "25px" }}
                  />
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <SaveButton onClick={SaveButtonOnClick} />
        </Box>
      </Modal>
    </div>
  );
}
