import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CourseContent.module.css";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LinearProgressComponent from "./LinearProgressComponent";

const CourseContent = (props) => {
  const { t } = useTranslation();
  const sectionCount = props.content?.length ?? 0;
  const lectureCount = props.totalLecture ?? 0;
  const courseLength = props.courseLength ?? 0;

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ))(({ theme }) => ({
    marginBottom: "15px",
    borderRadius: "25px",

    border: `1px solid #E5E5E5`,
    // borderBottom: 0,
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&:first-of-type': {
      borderRadius: "25px",
    },
    '&:last-of-type': {
      borderRadius: "25px",
    },
    "&:before": {
      display: 'none'
    },

    "& .Mui-expanded": {  
      // borderBottom: `1px solid #E5E5E5`,
      "border-bottom-right-radius": '0px',
      "border-bottom-left-radius": '0px',
    }
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "#EEEFF0" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const stylesAccordionSummary = {
    backgroundColor: '#F8F9FB',
    minHeight: '5px',
    maxHeight: '42px',
    borderRadius: '25px',
    borderBottom: '1px solid #E5E5E5'
  }

  const getFileIcon = (type, isCompleted) => {
    if(isCompleted) {
      switch (type) {
        case "pdf":
          return "/Image/lecturePlan/article-(student)-green.svg";
        case "ppt":
        case "pptx":
          return "/Image/CourseMonetisation/green-variant/file-ppt-line.svg";
        case "doc":
        case "docx":
          return "/Image/CourseMonetisation/green-variant/file-word-line.svg";
        case "xls":
        case "xlsx":
          return "/Image/CourseMonetisation/green-variant/file-excel-line.svg";
        case "mp3":
          return "/Image/CourseMonetisation/green-variant/music-line.svg";
        case "video":
          return "/Image/CourseMonetisation/green-variant/video-line.svg";
        case "jpg":
        case "jpeg":
        case "png":
          return "/Image/CourseMonetisation/green-variant/image-line.svg";
        default:
          return "/Image/CourseMonetisation/green-variant/file-code-line.svg";
      }

    } else {
      switch (type) {
        case "pdf":
          return "/Image/lecturePlan/article-(student).svg";
        case "ppt":
        case "pptx":
          return "/Image/CourseMonetisation/file-ppt-line.svg";
        case "doc":
        case "docx":
          return "/Image/CourseMonetisation/file-word-line.svg";
        case "xls":
        case "xlsx":
          return "/Image/CourseMonetisation/file-excel-line.svg";
        case "mp3":
          return "/Image/CourseMonetisation/music-line.svg";
        case "video":
          return "/Image/CourseMonetisation/video-line.svg";
        case "jpg":
        case "jpeg":
        case "png":
          return "/Image/CourseMonetisation/image-line.svg";
        default:
          return "/Image/CourseMonetisation/file-code-line.svg";
      }
    }
    
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.header_left}>
          <span className={styles.heading}>
            {t("courseMonetisation.CourseContent")}
          </span>
          <div className={styles.details}>
            <span>
              {sectionCount > 1
                ? `${sectionCount} ${t("common.sections")}`
                : `${sectionCount} ${t("common.section")}`}{" "}
              &#x2022;
            </span>

            <span>
              {lectureCount > 1
                ? `${lectureCount} ${t("common.lectures")}`
                : `${lectureCount} ${t("common.lecture")}`}{" "}
              &#x2022;
            </span>

            <span>
              {courseLength < 60
                ? `${Math.floor(courseLength)}m`
                : `${Math.floor(courseLength / 60)}h ${Math.floor(
                    courseLength % 60
                  )}m`}
              &nbsp;
              {t("common.totalLength")}
            </span>
          </div>
        </div>
        {props.isEnrolled && (
          <div className={styles.progress_container}>
            <span>{t("courseMonetisation.yourProgress")}:</span>
            <LinearProgressComponent progress={props.progress ?? 0} />
          </div>
        )}
      </div>
      {/* ----- accordion ----- */}
      <div styles={styles.content}>
        {props.content?.length > 0 &&
          props.content?.map((section, index) => (
            <Accordion>
              <AccordionSummary
                key={section.id}
                expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                sx={stylesAccordionSummary}
                // aria-controls="panel1a-content"
                // id="panel1a-header"
              >
                <div className={styles.section_container}>
                  <span className={styles.section_title}>{`${t(
                    "common.section"
                  )} ${index + 1}: ${section?.title}`}</span>
                  <span className={styles.lecture_count}>
                    {section?.total_lecs ?? 0} {section?.total_lecs > 1 ? t("common.lectures") :t("common.lecture")}
                  </span>
                </div>
              </AccordionSummary>
              {/* <Divider /> */}
              {/* lecture array */}
              {section?.lectures?.length > 0 &&
                section?.lectures?.filter((lecture) =>
                            lecture?.isPublished === true
                          )?.map((lecture) => (
                  <AccordionDetails>
                    <div className={styles.lecture_container}>
                      <img
                        className={styles.icon}
                        src={getFileIcon(lecture?.fileType, lecture?.completed)}
                      />
                      <span className={lecture.completed ? styles.lecture_title_completed : styles.lecture_title}>
                        {lecture?.title}
                      </span>{" "}
                      <span className={lecture?.completed ? styles.duration_completed : styles.duration}>
                        {/* {lecture?.duration}m */}
                        {lecture?.duration < 60
                          ? `${Math.floor(lecture?.duration)}m`
                          : `${Math.floor(
                              lecture?.duration / 60
                            )}h ${Math.floor(lecture?.duration % 60)}m`}
                      </span>
                    </div>
                  </AccordionDetails>
                ))}
            </Accordion>
          ))}
      </div>
    </div>
  );
};

export default CourseContent;
