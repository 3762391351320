import { Component, useState } from "react";
import styles from "./classcss/memberlist.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Recordings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClassMenu: false,
      classId: "",
      memberId: "",
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.membersListContainer}>
        <div style={{ padding: 24, paddingBottom: 16 }}>
          <h4 style={{ letterSpacing: 0.1 }}>{t("common.recordings")}</h4>
          <p style={{ letterSpacing: 0.1, marginTop: 2, marginBottom: 0 }}>{this.props?.meetings?.length} {this.props?.meetings.length <= 1 ? t("common.recording") : t("common.recordings")}</p>
        </div>
        {
        this.props.meetings.map((meeting) => (
          <div>
            <hr className={styles.memberHr} />
            <div className={styles.memberContainer}>
              <div className={styles.memberDetailContainer}>
                <div>
                  <h4>{meeting.topic}</h4>
                  <p style={{ marginTop: 4 }}><i>{meeting.time}</i></p>
                </div>
              </div>
              <a href={`${meeting.playUrl}`} target="_blank" style={{textDecoration: "none", color: "black"}}><div>{("common.view")}</div></a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Recordings));
