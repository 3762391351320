import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../../../redux/actions/classes";
import styles from "../../ClassesGroups/ClassModal/css/MiddleContain.module.css";
import AgendaBottomContainer from "../../Dashboard/postModal/AgendaBottomContainer";
import HandleApiError from "../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

const AgendaMiddleContainer = ({
  t,
  onPost,
  post,
  updateTitle,
  updateMessage,
  updateLink,
  onPicInput,
  onVideoInput,
}) => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const handlePost = () => {
    if (document.getElementById("agendaTitle").value === "") {
      setError(true);
      setMessage(t("errors.AgendaTitleRequired"));
      return;
    }
    if (document.getElementById("agendaDescription").value.length > 300) {
      setError(true);
      setMessage(t("errors.DescriptionTooLong"));
      return;
    }
    if (
      document.getElementById("agendaLink").value !== "" &&
      !document
        .getElementById("agendaLink")
        .value.match(
          new RegExp(
            "^(ht|f)tp(s?)://[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/\\+&amp;%$#_]*)?$"
          )
        )
    ) {
      setError(true);
      setMessage(t("errors.EnterValidLink"));
      return;
    }
    onPost();
  };

  return (
    <div className={styles.classModal}>
      <HandleApiError
        error={error}
        message={message}
        onClose={() => setError(false)}
      />
      <h3 className={styles.inputLabel} htmlFor="classname">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            font: "normal normal 500 16px Montserrat",
            letterSpacing: "0px",
            color: "#000000",
            margin: "15px 0",
          }}
        >
          <img
            style={{ marginRight: "10px" }}
            src="/Image/modalIcon/title.svg"
          />
          {t("classGroup.agendaTitle")}*
        </div>
      </h3>
      <input
        id="agendaTitle"
        type="text"
        className={styles.inputField}
        value={post.agenda_title}
        onChange={(e) => updateTitle(e)}
        placeholder={t("classGroup.ExNewProductLaunch")}
        required
      />
      <h3 htmlFor="description" className={styles.inputLabel}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            font: "normal normal 500 16px Montserrat",
            letterSpacing: "0px",
            color: "#000000",
            margin: "15px 0",
          }}
        >
          <img
            style={{ marginRight: "10px" }}
            src="/Image/modalIcon/description.svg"
          />
          {t("common.description")}
        </div>
      </h3>
      <textarea
        id="agendaDescription"
        className={styles.inputField1}
        value={post.agenda_description}
        onChange={(e) => updateMessage(e)}
        type="textarea"
        rows="6"
        placeholder={t("classGroup.shareYourTask")}
      >
        {post.message}
      </textarea>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h3 htmlFor="subject" className={styles.inputLabel}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              font: "normal normal 500 16px Montserrat",
              letterSpacing: "0px",
              color: "#000000",
              margin: "15px 0",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src="/Image/modalIcon/link.svg"
            />
            {t("common.link")}
            <p
              style={{
                font: "italic normal normal 14px/18px Montserrat",
                color: "#000000",
                opacity: 0.5,
              }}
            >
              {" "}
              ({t("common.optional")})
            </p>
          </div>
        </h3>
      </div>
      <div className={styles.drop}>
        <input
          type="text"
          id="agendaLink"
          value={post.agenda_link}
          onChange={(e) => updateLink(e)}
          className={styles.inputField}
          placeholder={t("classGroup.ExAddLinksForAgenda")}
        />
      </div>
      <h3 htmlFor="media" className={styles.inputLabel}>
        {t("common.addMedia")}
      </h3>
      <AgendaBottomContainer
        image_url={post.image_url}
        video_url={post.video_url}
        fileInput={onPicInput}
        videoInput={onVideoInput}
      />
      <button
        onClick={handlePost}
        id="submitForm"
        className={styles.saveDetailsButton}
      >
        {t("common.save")}
      </button>
    </div>
  );
};

export default withTranslation()(AgendaMiddleContainer);
