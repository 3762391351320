import React from "react";
import { connect } from "react-redux";
import styles from "../css/CreateAssignment.module.css";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import { useTranslation } from "react-i18next";

function MarksReturnModal(props) {
  // props- stdCount, changeEdit

  const { t } = useTranslation();

  // type = 'unassign', 'assign','gradeZero','return'

  let message = props?.modalType == 'assign' ? t('assignment.assignTo') :
                  props?.modalType == 'unassign' ? t('assignment.unassign') :
                  props?.modalType == 'gradeZero' ? t('assignment.gradeZeroTo') :
                  props?.modalType == 'return' ? t('assignment.returnWorkTo') : 
                  null;

    let label = props?.modalType == 'assign' ? t('assignment.assign') :
                  props?.modalType == 'unassign' ? t('assignment.unassign') :
                  props?.modalType == 'gradeZero' ? t('assignment.gradeZero') :
                  props?.modalType == 'return' ? t('viewAssignment.return') : 
                  null;
      

  return (
    <div className={styles.discard_modal_container}>
      <div className={styles.modal_header}>
        <h4 className={styles.discard_modal_title}>
          {/* {console.log("BEFORE MODAL", props.returnList)} */}
          {/* Return work to {props.stdCount} student? */}
          {/* {`${t("assignment.returnWorkTo")} ${props.stdCount} ${t("assignment.student2")}`} */}
          {`${message} ${props.stdCount} ${t("assignment.student2")}`}
        </h4>
        <div className={styles.close_icon} onClick={props.changeEdit}>
          <img alt=""
            src="/Image/apps/profile-resume-edit-view/cross.svg"
            height={30}
          />
        </div>
      </div>

      <p className={styles.discard_modal_msg}>
        {/* Student will be notified and can check any grade you've left. */}
        {props?.modalType == 'assign' ? t('assignment.studentWillBeAssigned') : t("assignment.studentToBeNotified")}
      </p>
      <div className={styles.discard_btn}>
        <Button
          clickHandler={() => props.returnMarks()}
          type="solid"
          label={label}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, null)(MarksReturnModal);
