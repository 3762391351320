import React, { Component } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/modal.module.css";
import DiscussionBottomContainer from "./DiscussionBottomContainer";
import { GroupFeedsContext } from "../../ClassesGroups/CreateClass/GroupFeedsContext";
import Video from "../Components/Video";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validateFileSize } from "../../../redux/constants/commonFunctions";
import { withTranslation } from "react-i18next";

class DiscussionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.description
          : "",
      photoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.file_url
          : null,
      photoViewUrl: null,
      videoViewUrl: null,
      mediaFileViewUrl: null,
      user: "",
      video: false,
      open: false,
    };
  }

  getState = () => {
    this.setState({
      message:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.description
          : "",
      photoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.file_url
          : null,
      photoViewUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoViewUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileViewUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.file_url
          : null,
      user: "",
      video: false,
      open: false,
    });
  };

  componentDidMount() {
    this.getState();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  onPost = () => {
    let post = {};
    post.message = this.state.message;
    post.photoUrl = this.state.photoUrl;
    post.videoUrl = this.state.videoUrl;
    post.mediaFileUrl = this.state.mediaFile;
    post.type = "CLASS";
    if (
      this.state.message.trim() != "" ||
      this.state.photoUrl != null ||
      this.state.videoUrl != null ||
      this.state.mediaFileUrl != null
    ) {
      this.context.addPost(post);
    } else {
      // alert("Please Add something before posting");
      this.setState({
        open: true,
        alertMessage: "Please Add Something before posting",
      });
    }
    this.setState({
      message: "",
      photoUrl: null,
      videoUrl: null,
      video: false,
      mediaFileUrl: null,
      mediaFile: null,
      onImageEdit: false,
      onVideoEdit: false,
      onFileEdit: false,
    });
    this.props.closeModal();
  };

  onEdit = () => {
    if (
      this.state.photo === null ||
      this.state.video === null ||
      this.state.mediaFileUrl === null
    ) {
      this.setState({
        photo: this.props?.postData?.image_url,
        video: this.props?.postData?.video_url,
        mediaFile: this.props?.postData?.file_url,
      });
    }
    if (this.state.message === "") {
      this.setState({ message: this.props?.postData?.description });
    }

    let post = {};
    post.message = this.state.message;
    post.photoUrl = this.state.photoUrl;
    post.mediaFileUrl = this.state?.mediaFile;
    post.onImageEdit = this.state.onImageEdit;
    post.onVideoEdit = this.state.onVideoEdit;
    post.onFileEdit = this.state.onFileEdit;
    post.videoUrl = this.state.videoUrl;
    post.id = this.props?.postData?.id;
    post.type = "CLASS";

    if (
      this.props?.postData?.description.trim() !== this.state.message.trim() ||
      this.state.photo !== this.props?.postData?.image_url ||
      this.state.video !== this.props?.postData?.video_url ||
      this.state.mediaFile !== this.props?.postData?.file_url
    ) {
      if (
        this.state.message.trim() != "" ||
        this.state.photoUrl != null ||
        this.state.videoUrl != null ||
        this.state.mediaFileUrl != null
      ) {
        this.context.editPost(post);
      } else {
        // alert("Please Add something before posting");
        this.setState({
          open: true,
          alertMessage: "Please Add Something before posting",
        });
      }
    } else {
    }

    this.props.closeModal();
  };

  onDelete = () => {
    let id = this.props.postData.id;
    this.context.deletePost(id);
    this.props.closeModal();
  };

  onChange = (e) => {
    this.setState({ message: e.target.value });
    /* this.onChange(e); */
  };

  updateValue = (e) => {
    this.setState({ value: e.target.value });
    this.onChange(e);
  };

  onPicInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 500);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            photoViewUrl: URL.createObjectURL(e.target.files[0]),
            photoUrl: e.target.files[0],
            videoUrl: null,
            onImageEdit: true,
            videoViewUrl: null,
            video: false,
          }
    );
  };

  videoInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 1024);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            videoViewUrl: URL.createObjectURL(e.target.files[0]),
            videoUrl: e.target.files[0],
            video: true,
            photoUrl: null,
            photoViewUrl: null,
            onVideoEdit: true,
          }
    );
  };
  mediaFileInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 1024);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            mediaFileUrl: URL.createObjectURL(e.target.files[0]),
            mediaFile: e.target.files[0],
            onFileEdit: true,
          }
    );
  };

  delFile = () => {
    this.setState({ mediaFileUrl: null, mediaFile: null });
  };

  handleImageDel = () => {
    this.setState({ photoUrl: null, photoViewUrl: null });
  };
  handleVideoDel = () => {
    this.setState({ videoUrl: null, video: null });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.state.alertMessage}
          </Alert>
        </Snackbar>

        <div>
          <ModalWithHeader
            actionType={this.props.actionType}
            title={
              this.props.actionType == "EDIT"
                ? t("class.editDiscussion")
                : t("class.startAdiscussion")
            }
            show={this.props.showModal}
            closeHandler={this.props.closeModal}
          >
            {/* <ModalWithHeader actionType={this.props.actionType} title= {this.props.actionType == "EDIT" ? "Edit discussion" : "Start discussion"} show={this.props.showModal} closeHandler={this.props.closeModal}> */}
            <div className={styles.modalBody}>
              {this.props.actionType !== "DELETE" ? (
                <div style={{ position: "relative" }}>
                  {this.state.photoUrl || this.state.videoUrl ? (
                    <>
                      {!this.state.videoUrl ? (
                        <img src={this.state.photoViewUrl} width="100%" />
                      ) : (
                        <Video src={this.state.videoViewUrl} />
                      )}
                      {this.state.photoViewUrl ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 10,
                            height: 20,
                            width: 20,
                            zIndex: 10,
                            padding: 5,
                            borderRadius: "100%",
                            background: "#555555",
                            cursor: "pointer",
                          }}
                          onClick={this.handleImageDel}
                        >
                          <img
                            src="/Image/DashboardImages/postModImages/cross.svg"
                            alt="X"
                            className={styles.crossSvg}
                          />
                        </div>
                      ) : null}
                      {this.state.videoViewUrl ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 10,
                            height: 20,
                            width: 20,
                            zIndex: 10,
                            padding: 5,
                            borderRadius: "100%",
                            background: "#555555",
                            cursor: "pointer",
                          }}
                          onClick={this.handleVideoDel}
                        >
                          <img
                            src="/Image/DashboardImages/postModImages/cross.svg"
                            alt="X"
                            className={styles.crossSvg}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {this.state?.mediaFileUrl ? (
                    <p
                      style={{
                        border: "1px #757575 solid",
                        borderRadius: "20px",
                        padding: "9px",
                        color: "#757575",
                        width: "fit-content",
                        margin: "10px 0",
                      }}
                    >
                      {t("common.file")}:{" "}
                      {this?.state?.mediaFile?.name ||
                        this?.state?.mediaFileUrl.split("/")[8] ||
                        this.props?.state?.mediaFileUrl ||
                        "uploaded"}{" "}
                      <span
                        style={{ marginLeft: 20, cursor: "pointer" }}
                        onClick={this.delFile}
                      >
                        X
                      </span>
                    </p>
                  ) : null}
                  <textarea
                    onChange={(e) => this.onChange(e)}
                    type="textarea"
                    className={styles.textareaDisc}
                    rows="10"
                  >
                    {this.state?.message}
                  </textarea>
                </div>
              ) : (
                <div className={styles.container}>
                  {t("dashboard.sureDelete")}
                </div>
              )}

              <DiscussionBottomContainer
                actionType={this.props.actionType}
                videoInput={this.videoInput}
                fileInput={this.onPicInput}
                mediaFileInput={this.mediaFileInput}
                onPost={this.onPost}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
              />
            </div>
            {/* <img height="100px" src={this.state.image} /> */}
          </ModalWithHeader>
        </div>
      </>
    );
  }
}

DiscussionModal.contextType = GroupFeedsContext;
export default withTranslation()(DiscussionModal);
