import { React, Component } from "react";
import styles from "./classcss/invitestudents.module.css";
// import Header from "../../../components/header/Header";
import Chat from "../../../components/chat/Chat";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import MobileBottom from "../../Dashboard/Components/MobileBottom";
import { ChatProvider } from "../../../components/chat/ChatContext";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Link } from "react-router-dom";
import DownloadInviteeListModal from "./DonwloadInviteeListModal";
import InvitePeopleModal from "./InvitePeopleModal";
import MyDataGrid from "./ReactDataGrid";

class InviteStudents extends Component {
  constructor() {
    const { tableHeading } = styles;
    super();

    this.state = {
      showInviteeModal: false,
      showInvitePeopleModal: false,
      columns: [
        {
          key: "firstName",
          name: "First Name",
          editable: true,
          cellProps: { backgroundColor: "red" },
        },
        {
          key: "lastName",
          name: "Last Name",
          editable: true,
          className: { tableHeading },
        },
        {
          key: "emailId",
          name: "Email Id(Recommended)",
          editable: true,
          className: { tableHeading },
        },
      ],
      invitedStudents: [
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
        {
          firstName: "First Name",
          lastName: "Last Name",
          emailId: "Email (Recommended)",
        },
      ],
    };
  }
  InvitePeopleModalCloser = () => {
    this.setState({ showInvitePeopleModal: false });
  };

  InvitePeopleModalOpener = () => {
    this.setState({ showInvitePeopleModal: true });
  };
  InviteeModalCloser = () => {
    this.setState({ showInviteeModal: false });
  };

  InviteeModalOpener = () => {
    this.setState({ showInviteeModal: true });
  };
  updateRows = (rows) => {
    this.setState({ invitedStudents: rows });
  };
  render() {
    return (
      <></>
      // <div className={styles.app}>
      //   <Header toggleChatList={this.props.toggleChatList} />
      //   <div className={styles.app_body}>
      //     <div className="container">
      //       {this.state.showInviteeModal ? (
      //         <DownloadInviteeListModal
      //           show={this.state.showInviteeModal}
      //           InviteeModalCloser={this.InviteeModalCloser}
      //         />
      //       ) : null}
      //       {this.state.showInvitePeopleModal ? (
      //         <InvitePeopleModal
      //           showInvitePeopleModal={this.state.showInvitePeopleModal}
      //           InvitePeopleModalCloser={this.InvitePeopleModalCloser}
      //         />
      //       ) : null}
      //       <div
      //         className="col-12 col-xs-12 col-sm-12 col-md-12"
      //         style={{ marginTop: 75, display: "flex", marginBottom: 20 }}
      //       >
      //         <hr className={styles.inviteHr} />
      //         <h2 className={styles.groupHeading}>Numerical Practice Group</h2>
      //       </div>
      //       <div className="row">
      //         <div className="col-xs-0 col-sm-0 col-md-7">
      //           <div className={styles.instructionsContainer}>
      //             <h3 className={styles.inviteHeading}>
      //               Invite students and other teacher
      //             </h3>
      //             <ul className={styles.inviteList}>
      //               <li className={styles.listElement}>
      //                 Members who already have an Edmodo account can join your
      //                 Class
      //               </li>
      //               <li className={styles.listElement}>
      //                 Members who need an Edmodo account can sign up on their
      //                 own using the information provided.
      //               </li>
      //             </ul>
      //             <div
      //               className="row"
      //               style={{ display: "flex", justifyContent: "space-evenly" }}
      //             >
      //               <button
      //                 className={styles.groupinviteButton}
      //                 style={{ width: "45%" }}
      //                 onClick={this.InvitePeopleModalOpener}
      //               >
      //                 INVITE
      //               </button>
      //               <Link to="/class" style={{ width: "45%" }}>
      //                 <button className={styles.groupinviteButton}>
      //                   GO BACK TO CLASS
      //                 </button>
      //               </Link>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="col-xs-0 col-sm-0 col-md-5">
      //           <div className={styles.greyContainer}></div>
      //         </div>
      //       </div>
      //       <div className="col-12 col-xs-12 col-sm-12 col-md-5">
      //         <h3 className={styles.inviteHeading}>
      //           Create students account ahead of time
      //         </h3>
      //         <p className={styles.inviteContent}>
      //           Copy and paste from a spreadsheet to add to your members list.
      //           This will generate a handout with each student's login
      //           information that you can share.
      //         </p>
      //       </div>
      //       <div className="col-12 col-xs-12 col-sm-12 col-md-12">
      //         {/* <DataGrid columns={this.state.columns} rows={this.state.invitedStudents}/> */}
      //         {/* <CustomDataGrid updateRows={this.updateRows} columns={this.state.columns} rows={this.state.invitedStudents}/> */}
      //         {/* <TableContainer>
      //                <Table aria-label="simple table">
      //                   <TableHead>
      //                   <TableRow>
      //                   <TableCell></TableCell>
      //                       <TableCell className={styles.tableHeading}>First Name</TableCell>
      //                       <TableCell className={styles.tableHeading}>Last Name</TableCell>
      //                       <TableCell className={styles.tableHeading}>Email(Recommended)</TableCell>
      //                       </TableRow>
      //                   </TableHead>
      //                   <TableBody>
      //                 {this.state.invitedStudents.map((row,index) => (
      //                            <TableRow
      //                              key={row.emailId}
      //                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      //                            >
      //                              <TableCell component="th" scope="row" className={styles.invitedStudentSerialNum}>
      //                                  {index+1}.
      //                              </TableCell>
      //                              <TableCell className={styles.invitedStudent}>{row.firstName}</TableCell>
      //                              <TableCell className={styles.invitedStudent}>{row.lastName}</TableCell>
      //                              <TableCell className={styles.invitedStudent}>{row.emailId}</TableCell>
      //                            </TableRow>
      //                         ))}
      //                     </TableBody>
      //                </Table>
      //               </TableContainer> */}
      //         <MyDataGrid
      //           rows={this.state.invitedStudents}
      //           columns={this.state.columns}
      //         />
      //       </div>
      //       <div className="col-12 col-xs-12 col-sm-12 col-md-3">
      //         <button
      //           className={styles.groupinviteButton}
      //           onClick={this.InviteeModalOpener}
      //         >
      //           CREATE STUDENT ACCOUNT
      //         </button>
      //       </div>
      //       <div className="col-xs-0 col-sm-0 col-md-3">
      //         <ChatProvider>
      //           <Chat
      //             toggleChatList={this.props.toggleChatList}
      //             isChatListOpen={this.props.isChatListOpen}
      //           />
      //         </ChatProvider>
      //       </div>
      //     </div>
      //   </div>
      //   <MobileBottom />
      // </div>
    );
  }
}

export default InviteStudents;
