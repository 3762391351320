import { React, Component } from "react";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import styles from "./classcss/autosuggestioncomponent.module.css";
import { withTranslation } from "react-i18next";

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value, suggestions) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
        return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");
    return suggestions?.filter((user) => regex.test(user.name)) || [];
}

function renderSuggestion(suggestion, { query }) {
    const matches = AutosuggestHighlightMatch(suggestion.name, query);
    const parts = AutosuggestHighlightParse(suggestion.name, matches);
    const suggestionValue =
        parts.map((part) => part.text).join("") + `(${suggestion.id})`;
    return <div className={styles.dropdown_menu1}>{suggestionValue}</div>;
}
class AutosuggestionComponent extends Component {
    constructor() {
        super();

        this.state = {
            value: "",
            suggestions: [],
        };
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
        });
    };
    getSuggestionValue = (suggestion) => {
        this.props.addMemberUsers(suggestion);
        return suggestion.name;
    };
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.props.memberSuggestions),
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    render() {
        const { t } = this.props;
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: t("class.typeSomething"),
            value,
            onChange: this.onChange,
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={{
                    input: {
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginLeft: 20,
                        marginTop: 20,
                        borderRadius: "25px",
                        width: "85%",
                        marginLeft: "15px",
                        minHeight: "40px",
                        height: "max-content",
                        outline: "none",
                        border: "1px #c2c2c2 solid",
                        paddingLeft: "20px",
                        marginBottom: 0,
                    },
                    suggestion: {
                        listStyle: "none",
                    },
                }}
            />
        );
    }
}
export default withTranslation()(AutosuggestionComponent);
