import { React, useEffect } from "react";
import styles from "./CSSFiles/RightSidebar.module.css";
import IconTitle from "../../../components/commons/IconTitle";
import DashboardRightFeedInnerComp from "../../../components/commons/DashboardRightFeedInnerComp";
import ConnectionRightFeedComp from "../../../components/commons/ConnectionRightFeedComp";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { thumbnailList } from "../../../redux/constants/commonFunctions";
import { getAllCourses, clearCourses } from "../../../redux/actions/courses";
import newstyles from "../Components/CSSFiles/Sidebar.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MyGroup from "../Components/MyGroup";
import { useState } from "react";
import SidebarGroup from "./SidebarGroup";
import AddIcon from "@material-ui/icons/Add";
import EventsCarousel from "./EventsCarousel";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function RightSidebar({ GroupModalOpener }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMenu2, setShowMenu2] = useState(false);
  const { code } = useParams();

  useEffect(() => {
    if (code) {
      setShowMenu2(true);
    }
  }, []);

  const publicCourses =
    useSelector((state) => state?.courses?.publicCourses) || [];

  useEffect(() => {
    dispatch(clearCourses());
    dispatch(getAllCourses(null, true, null, null, null, null, 5));
    return () => {
      dispatch(clearCourses());
    };
  }, []);

  const newsContent = [
    {
      thumbnail: "/Image/DashImage/news1.webp",
      href: "https://www.business-standard.com/content/press-releases-ani/phonlo-announces-grand-opening-of-new-store-in-rohini-sector-3-on-june-4th-123053000668_1.html",
      content: "newsFeed.news1",
      channel: "newsFeed.BusinessStandard",
    },

    {
      thumbnail: "/Image/DashImage/news1.webp",
      href: "https://www.business-standard.com/article/current-affairs/migsun-group-buys-land-in-rohini-to-develop-high-street-retail-project-123030100856_1.html",
      content: "newsFeed.news2",
      channel: "newsFeed.BusinessStandard",
    },

    {
      thumbnail: "/Image/DashImage/news2.jpg",
      href: "https://www.facebook.com/GreatEasternRetail/posts/grand-opening-of-our-delhi-rohini-m2k-store-grateful-to-share-this-news-with-our/5362076537188604/",
      content: "newsFeed.news3",
      channel: "newsFeed.Facebook",
    },
  ];

  const toogleMenu2 = () => {
    setShowMenu2(!showMenu2);
  };

  return (
    <div className={styles.RightSidebar}>
      <DashboardRightFeedInnerComp content={newsContent} />
      <ConnectionRightFeedComp />
      <br />
      <EventsCarousel />
      <div className={newstyles.sidebar_stats}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 20px",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img
              src="/Image/DashImage/RightSidebar/groups_icon.svg"
              alt="event icon"
              style={{ width: "25px" }}
            />
            <Typography
              sx={{ fontWeight: 600, fontSize: "15px", color: "#535353" }}
            >
              {t("group.dashTitleGroup")}
            </Typography>
          </Box>
          <div className={newstyles.navbar_menu1}>
            <MoreHorizIcon
              onClick={toogleMenu2}
              className={newstyles.sidebar_icon2}
              style={{ marginTop: 7, cursor: "pointer" }}
            />
            {showMenu2 ? <MyGroup /> : <></>}
          </div>
        </Box>
        <SidebarGroup />
        <div className={newstyles.sidebar_stat1}>
          <p onClick={GroupModalOpener}>
            <AddIcon style={{ width: 24, height: 24 }} />
            <span style={{ marginLeft: 5, fontSize: "14px" }}>
              {t("common.createGroup")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default RightSidebar;
