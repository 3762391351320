import React from "react";
import LeftFeed from "./components/LeftFeed";
import LeftSideBar from "./components/LeftSideBar";
import RightFeed from "./components/RightFeed";
import CoverFeed from "./components/CoverFeed";
import Modal from "../../../../components/commons/Modal";
import HamburgerView from "./components/Modals/HamburgerView";
import { Redirect } from "react-router";
import styles from "../../Profile.module.css";
import { withRouter } from "react-router-dom";
import { FeedsProvider } from "../../../Dashboard/Components/FeedsContext";

class Feed extends React.Component {
  state = {
    dpfileloc: "/Image/apps/profile-resume-edit-view/default-user.png",
    error: false,
    message: "",
  };

  changeEdit(edit) {
    document.getElementById("hamburger-view").style.display = edit;
  }

  updateDp(fileloc) {
    this.setState({ dpfileloc: fileloc });
  }

  render() {
    if (!localStorage?.userData) return <Redirect to="/signin" />;
    if (
      JSON.parse(localStorage?.userData)?.profile_id !=
      this?.props?.match?.params?.id
    ) {
      return (
        <Redirect to={`/profile/${this?.props?.match?.params?.id}/view`} />
      );
    }

    return (
      <FeedsProvider>
        <div
          className={styles.profile_margin}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
          }}
        >
          <div className={styles.left_side_bar}>
            <LeftSideBar
              dpfileloc={this.state.dpfileloc}
              updateDp={this.updateDp.bind(this)}
              props={this.props}
            />
          </div>
          <div className={styles.cover_feed} style={{}}>
            <CoverFeed editpage={this.props.editpage} />

            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
              }}
            >
              <div
                className={styles.left_feed}
                style={{
                  margin: "15px 10px",
                }}
              >
                <LeftFeed
                  dpfileloc={this.state.dpfileloc}
                  updateDp={this.updateDp.bind(this)}
                  editpage={this.props.editpage}
                />
              </div>
              <div
                className={styles.right_feed}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                <RightFeed editpage={this.props.editpage} />
              </div>
              <div
                id="hamburger-view"
                className={styles.hamburger_view}
                // className={styles.right_feed}
                style={{
                  position: "absolute",
                }}
              >
                <Modal
                  card={
                    <HamburgerView changeEdit={this.changeEdit.bind(this)} />
                  }
                  justifyContent="flex-end"
                  scroll="none"
                />
              </div>
            </div>
          </div>
          {/* <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} /> */}
        </div>
      </FeedsProvider>
    );
  }
}

export default withRouter(Feed);
