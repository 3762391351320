import { GET_USER_SUGGESTIONS } from "../constants/actionTypes";

let userSuggestions = [];
export default (state = [], action) => {
  switch (action.type) {
    case GET_USER_SUGGESTIONS:
      userSuggestions = [...state];
      userSuggestions = action.payload;
      return userSuggestions;
    default:
      return [...state];
  }
};
