import React, { Component } from "react";
import styles from "./css/AgendaBottomContainer.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { handleErrorMessage } from "../../../redux/actions/groups";
import { validateFileSize } from "../../../redux/constants/commonFunctions";

export default class AgendaBottomContainer extends Component {
  state = {
    image_url: this.props.image_url || "",
    video_url: this.props.video_url || "",
  };

  handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const errorMessage = validateFileSize(file, 5120);
      this.setState({
        image_url: URL.createObjectURL(file),
      });
      if (errorMessage) {
        handleErrorMessage(errorMessage);
      } else {
        this.props.fileInput(event);
      }
    }
  };

  handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const errorMessage = validateFileSize(file, 5120);
      this.setState({
        video_url: URL.createObjectURL(file),
      });
      if (errorMessage) {
        handleErrorMessage(errorMessage);
      } else {
        this.props.videoInput(event);
      }
    }
  };
  render() {
    const { image_url, video_url } = this.state;
    return (
      <div className={styles.container}>
        {image_url && (
          <div style={{ display: "flex" }}>
            <img
              src={image_url}
              alt="Image"
              style={{
                height: "100px",
                objectFit: "contain",
              }}
            />
            <CloseIcon
              sx={{ ml: 1, cursor: "pointer" }}
              onClick={() => {
                this.setState({ image_url: "" });
              }}
            />
          </div>
        )}
        {video_url && (
          <div style={{ display: "flex" }}>
            <video
              src={video_url}
              alt="Video"
              style={{
                height: "100px",
                objectFit: "contain",
              }}
              controls
            />
            <CloseIcon
              sx={{ ml: 1, cursor: "pointer" }}
              onClick={() => {
                this.setState({ video_url: "" });
              }}
            />
          </div>
        )}
        {image_url === "" && video_url === "" && (
          <>
            <input
              onChange={this.handleImageChange}
              type="file"
              accept="image/*"
              className={styles.fileUpload}
              id={`choose_image`}
              name={`choose_image`}
            />
            <label
              htmlFor={`choose_image`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="/Image/DashboardImages/postModImages/photo.svg"
                alt="Photos"
                className={styles.photo}
              />
              Choose Image
            </label>
            <input
              onChange={this.handleVideoChange}
              type="file"
              accept="video/*"
              className={styles.fileUpload}
              id={`choose_video`}
              name={`choose_video`}
            />
            <label
              htmlFor={`choose_video`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="/Image/DashboardImages/postModImages/video.svg"
                alt="Video"
                className={styles.photo}
              />
              Choose Video
            </label>
          </>
        )}
      </div>
    );
  }
}
