import React from "react";

import styles from "../../apps/Profile/Profile.module.css";

// const CorouselImages = ["/Image/adds/ad1.png", "/Image/adds/ad2.png", "/Image/adds/ad3.png", "/Image/adds/ad4.png"]
// const url= ["https://www.shardauniversity.uz/ " , "https://www.khanacademy.org/" , "https://www.coursera.org/in" , "http://skills.sharda.ac.in/"];

export default function CorouselFeed(props) {
  const [index, setIndex] = React.useState(0);
  const corouselContent = props.corouselContent;

  return (
    <div
      className={styles.slideshow}
      style={{
        position: "relative",
        borderRadius: 10,
        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
      }}
    >
      <div
        className={styles.slideshowSlider}
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {corouselContent.map((item, index) => (
          <a
            // href={item.href}
            className={styles.slide}
            key={index}
            target="_blank"
            // style={{borderRadius: 10}}
          >
            <img
              src={item.img}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 10,
                objectFit: "cover",
                objectPosition: "left",
              }}
            />
          </a>
        ))}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 5,
          left: 0,
          right: 0,
        }}
      >
        <div className={styles.slideshowDots}>
          {corouselContent.map((_, idx) => (
            <div
              key={idx}
              href={idx}
              className={`${styles.slideshowDot} ${
                index === idx ? styles.slideshowDot_active : " "
              }`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>

        <div></div>
      </div>
    </div>
  );
}
