import { React, useState, useEffect, useRef, useContext } from "react";
import styles from "../../Dashboard/Components/CSSFiles/Post.module.css";
import Comment from "../../Dashboard/Components/Comment";
import Video from "../../Dashboard/Components/Video";
import Picker from "emoji-picker-react";
import "../../Dashboard/Components/CSSFiles/picker.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  postComment,
  postLike,
  postLikeDelete,
  translatePost,
} from "../../../redux/actions/dashboard";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PostModal from "../../Dashboard/postModal/PostModal";
import DiscussionModal from "../../Dashboard/postModal/DiscussionModal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import PostButtonBottom, {
  PostButtonBottomDirect,
} from "../../Dashboard/Components/PostButtonBottom";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { motion } from "framer-motion";
import ExpertDisplay from "../../Dashboard/Components/ExpertDisplay";
// import { FeedsContext } from "./FeedsContext";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import PDFViewer from "../../Dashboard/postModal/PDFViewer";
import { GroupFeedsContext } from "../../ClassesGroups/CreateClass/GroupFeedsContext";
import SellDisplay from "../../Dashboard/Components/SellDisplay";
import PollDisplayGroup from "../../Dashboard/Components/PollDisplayGroup";
import PostModalGroup from "../../Dashboard/postModal/PostModalGroup";
import EventDisplay from "../../Dashboard/Components/EventDisplay";
import CourseModal from "./CourseModal";
// import EventDisplay from "../../Dashboard/Components/EventDisplay";

const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const fadeInAnimationsVariations = {
  initial: {
    opacity: 1,
    y: 20,
  },
  animate: (idx) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * idx,
      type: "spring",
      stiffness: 500,
    },
  }),
};

const reactions = [
  "Like",
  "Celebrate",
  "Support",
  "love",
  "InsightFull",
  "Funny",
];

function PostOfGroup(props) {
  const {
    postComment,
    newComment,
    newTranslation,
    newLike,
    postLike,
    name,
    follower,
    timestamp,
    message,
    photoUrl,
    file_url,
    tags,
    title,
    video,
    shareModalOpener,
    profileImage,
    user,
    likes,
    likeDetails,
    postId,
    shareLink,
    comments,
    source,
    translatePost,
    currentLanguage,
    postLanguage,
    handleOpenSelectedComment,
    openSelectedComment,
    post_details,
    post_owner_id,
    postType,
    sellImages,
    pollOptions,
    pollVotes,
    original_post_owner,
    agenda_link,
    agenda_title,
    agenda_description,
  } = props;
  const [profileLikes, setProfileLikes] = useState(0);
  const [likeButtonPressed, setLikeButtonPressed] = useState(false);
  const [comment, setComment] = useState([]);
  const [likedId, setLikedId] = useState(0);
  const [editPostId, setEditPostId] = useState(0);
  const [action, setAction] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverEl, setPopoverEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [likeReaction, setLikeReaction] = useState("Like");
  const [expertDetails, setExpertDetails] = useState({
    profession: "accounting",
    location: "Rohini, Delhi, India",
    expertDesc: "testing",
  });

  const newRefComment = useRef(newComment);
  newRefComment.current = newComment;
  const newRefLike = useRef(newLike);
  newRefLike.current = newLike;
  const newRefTranslation = useRef(newTranslation);
  newRefTranslation.current = newTranslation;
  const profilePicUrl = useSelector((state) => state.profile.userProfilePic);
  const avatarImg = useSelector((state) => state.profile.avatarImg);
  const profileId = useSelector((state) => state.profile.user.profile_id);
  const open = Boolean(anchorEl);
  const [translatedMessage, setTranslatedMessage] = useState({});
  const [translatedMessageToShow, setTranslatedMessageToShow] =
    useState(message);
  const [seeTranslation, setSeeTranslation] = useState(true);
  const [likeClickable, setLikeClickable] = useState(true);
  const [distinctReactions, setDistictReactions] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showExpertModal, setShowExpertModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [userVoted, setUserVoted] = useState(null);

  let firstpostTime = new Date(timestamp);
  let postTime = firstpostTime.setMinutes(firstpostTime.getMinutes());
  let seconds = Math.floor((new Date() - postTime) / 1000);
  let years = seconds / 31536000;
  let months = seconds / 2592000;
  let days = seconds / 86400;
  let hours = seconds / 3600;
  let minutes = seconds / 60;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { repost } = useContext(GroupFeedsContext);

  const onRepost = (postId) => {
    repost(postId);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    if (postType === "Agenda") {
      setAction("EDIT");
      setAnchorEl(null);
      setShowAgendaModal(true);
    } else {
      setAction("EDIT");
      setAnchorEl(null);
      toggleModal(true);
    }
  };
  const handleDelete = () => {
    setAction("DELETE");
    setAnchorEl(null);
    toggleModal(true);
  };

  useEffect(() => {
    const voted = pollVotes?.filter(
      (vote) => vote?.profile_voted_by === profileId
    );
    if (voted[0]?.profile_voted_by === profileId) {
      setUserVoted(voted[0]?.poll_post);
    } else {
      setUserVoted(null);
    }
  }, [pollVotes]);

  useEffect(() => {
    setTranslatedMessageToShow("");
    if (message) {
      setTranslatedMessage({ lang: message });
      setTranslatedMessageToShow(message);
    }
    if (likeDetails != null) {
      setProfileLikes(likeDetails.length);
      likeDetails.map((data, index) => {
        setDistictReactions((prev) => [...prev, data.reaction_details.name]);
      });
      if (
        likeDetails.some(
          (likedPost) => likedPost.profile_liked_by == user.profile_id
        )
      ) {
        setLikeButtonPressed(true);
        likeDetails.map((data, index) => {
          if (data.profile_liked_by == user.profile_id) {
            setLikedId(data.id);
            setLikeReaction(data.reaction_details.name);
          }
        });
      }
    } else {
      setProfileLikes(0);
      setLikeButtonPressed(false);
      setLikedId(0);
    }
    if (comments != null) {
      setComment(comments);
    } else {
      setComment([]);
    }
  }, [postId, message]);

  const like = (currentLikes, reaction = "Like") => {
    setPopoverOpen(false);
    setLikeClickable(false);
    if (postId > 0) {
      const data = {
        postId: postId,
        profileId: profileId,
        reaction:
          reaction === "Like"
            ? 6
            : reaction === "Celebrate"
            ? 2
            : reaction === "Support"
            ? 3
            : reaction === "love"
            ? 1
            : reaction === "InsightFull"
            ? 4
            : 5,
      };

      if (!likeButtonPressed) {
        postLike(data).then(() => {
          setLikedId(newRefLike?.current?.id);
          setLikeClickable(true);
        });
        setProfileLikes(currentLikes + 1);
        setLikeReaction(reaction);
        setLikeButtonPressed(true);
        setDistictReactions((prev) => [...prev, reaction]);
      } else {
        dispatch(postLikeDelete(likedId)).then(() => {
          setLikeClickable(true);
        });
        setProfileLikes(currentLikes - 1);
        setLikeButtonPressed(false);
        setLikeReaction("Like");
        setLikedId(0);
        setDistictReactions((prev) => prev.slice(0, -1));
      }
    } else {
      if (!likeButtonPressed) {
        setProfileLikes(1);
        setLikeButtonPressed(true);
        setLikeReaction(reaction);
        setDistictReactions((prev) => [...prev, reaction]);
      } else {
        setLikeButtonPressed(false);
        setProfileLikes(0);
        setLikeReaction("Like");
        setDistictReactions((prev) => prev.slice(0, -1));
      }
    }
  };

  const [Input, setInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    var currentdate = new Date();
    let datetime =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    if (postId > 0) {
      const data = {
        postId: postId,
        description: Input,
        dateTime: datetime,
      };
      postComment(data).then(() => {
        setComment([...comment, newRefComment?.current]);
      });
    } else {
      setComment({
        postId: postId,
        description: Input,
        datetime: datetime,
        likedComment: null,
        id: -1,
      });
    }

    setInput("");
  };

  const sortfunction = (comment) => {
    comment.sort(function (a, b) {
      return b.id - a.id;
    });
  };
  const updateAddLikedComment = (id, newLikedComment) => {
    for (const c of comment) {
      if (c.id == id) {
        c.likedComment = [newLikedComment];
        break;
      }
    }
  };
  const updateRemoveLikedComment = (id) => {
    for (const c of comment) {
      if (c.id == id) {
        c.likedComment = null;
        break;
      }
    }
  };
  const commentSection = () => {
    if (comment[0]) {
      sortfunction(comment);
      return comment.map((c, i) => (
        <Comment
          key={i}
          photo={
            c?.profile_id?.profile_pic
              ? c?.profile_id?.profile_pic
              : c?.profile_id?.avatarImg
              ? c?.profile_id?.avatarImg
              : "/Image/header/ddefault_img.svg"
          }
          name={c?.profile_id?.name}
          commentdata={c?.description}
          id={c?.id}
          likedComment={c?.likedComment}
          updateAddLikedComment={updateAddLikedComment}
          updateRemoveLikedComment={updateRemoveLikedComment}
          comment_owner_id={c?.profile_id?.id}
          // timestamp={c.timestamp}
        />
      ));
    } else {
      return null;
    }
  };

  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showModal, toggleModal] = useState(false);
  const [showAgendaModal, setShowAgendaModal] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setInput(Input + emojiObject.emoji);
  };

  const [emoji, setEmoji] = useState(false);

  const toggleEmojiPicker = () => {
    setEmoji(!emoji);
  };

  const thumbsUp = () => {
    setInput(Input + "👍");
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const handleTranslatePost = (msg) => {
    setSeeTranslation(false);
    if (!translatedMessage.hasOwnProperty(currentLanguage)) {
      let data = { target: currentLanguage.slice(0, 2), text: msg };
      translatePost(data).then(() => {
        let translation = {
          ...translatedMessage,
          currentLanguage: newRefTranslation?.current,
        };
        setTranslatedMessage(translation);
        setTranslatedMessageToShow(newRefTranslation?.current);
      });
    } else {
      setTranslatedMessageToShow(translatedMessage.currentLanguage);
    }
  };

  const handleSeeOriginal = () => {
    setTranslatedMessageToShow(translatedMessage.lang);
    setSeeTranslation(true);
  };

  const EventModalOpener = () => {
    setShowEventModal(true);
  };

  const EventModalCloser = () => {
    setShowEventModal(false);
  };

  const ExpertModalOpener = () => {
    setShowExpertModal(true);
  };

  const ExpertModalCloser = () => {
    setShowExpertModal(false);
  };

  const handlePopoverClick = (event) => {
    setPopoverEl(event.currentTarget);
    setTimeout(() => {
      setPopoverOpen(true);
    }, 300);
  };

  const handlePopoverClose = () => {
    setPopoverEl(null);
    setPopoverOpen(false);
  };

  const CourseModalCloser = () => {
    setShowAgendaModal(false);
  };

  const openPopover = Boolean(popoverEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const StyledTooltip = styled((props) => (
    <Tooltip classes={{ popper: props.className }} {...props} />
  ))`
    & .MuiTooltip-tooltip {
      background-color: #000;
      color: #fff;
      border-radius: 20px;
    }
  `;

  const distinctReactionsSet = new Set(distinctReactions);
  const distinctReactionsArray = Array.from(distinctReactionsSet);
  const Source = localStorage.getItem("source");

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ mb: 2, borderRadius: "10px", position: "relative" }}
      >
        {emoji && (
          <div
            onClick={() => {
              toggleEmojiPicker();
            }}
            className={styles.overlay}
          />
        )}
        {Source == "GROUP" && action !== "" ? (
          <PostModalGroup
            getGroupPost={props.getGroupPost}
            group_id={props.group_id}
            actionType={action}
            postData={props.postData}
            showModal={showModal}
            closeModal={closeModal}
            showEventModal={showEventModal}
            EventModalOpener={EventModalOpener}
            EventModalCloser={EventModalCloser}
            showExpertModal={showExpertModal}
            ExpertModalOpener={ExpertModalOpener}
            ExpertModalCloser={ExpertModalCloser}
            showSellModal={showSellModal}
            setShowSellModal={setShowSellModal}
            postType={postType}
          />
        ) : null}
        {Source === "DASHBOARD" && action !== "" ? (
          <DiscussionModal
            actionType={action}
            postData={props.postData}
            showModal={showModal}
            closeModal={closeModal}
          />
        ) : null}
        {tags && <div className={styles.post_tag}>{tags}</div>}
        <div className={styles.post_header}>
          <div className={styles.infoContainer}>
            <img src={profileImage} className={styles.post_profile_pic} />
            <div className={styles.post_Info}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Link
                  to={`/profile/${post_owner_id}/view`}
                  style={{ textDecoration: "none" }}
                >
                  <h2 className={styles.post_info_userName}>{name}</h2>
                </Link>
                {postType === "Agenda" && (
                  <Typography
                    sx={{
                      background: "#ffd221",
                      color: "#1f2153",
                      padding: "0.5px 10px",
                      borderRadius: "17px",
                      fontSize: "14px",
                      fontWeight: 800,
                      fontStyle: "italic",
                    }}
                  >
                    Group Agenda
                  </Typography>
                )}
              </Box>

              {years >= 1 ? (
                <p className={styles.post_info_timestamp}>
                  {Math.floor(years)} {t("common.year")} {t("common.ago")}
                </p>
              ) : months >= 1 ? (
                <p className={styles.post_info_timestamp}>
                  {Math.floor(months)} {t("common.month")} {t("common.ago")}
                </p>
              ) : days >= 1 ? (
                <p className={styles.post_info_timestamp}>
                  {Math.floor(days)} {t("common.day")} {t("common.ago")}
                </p>
              ) : hours >= 1 ? (
                <p className={styles.post_info_timestamp}>
                  {Math.floor(hours)} {t("common.hour")} {t("common.ago")}
                </p>
              ) : minutes >= 1 ? (
                <p className={styles.post_info_timestamp}>
                  {Math.floor(minutes)} {t("common.minute")} {t("common.ago")}
                </p>
              ) : (
                <p className={styles.post_info_timestamp}>
                  {t("common.justNow")}
                </p>
              )}
            </div>
          </div>
          {props.menu ? (
            <div className="post-header-options">
              <MoreHorizIcon
                onClick={handleClick}
                style={{ marginTop: 7, cursor: "pointer", opacity: 0.6 }}
              />
              <Menu
                onClick={() => setEditPostId(props.postId)}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
                menuStyle={{ boxShadow: "none" }}
                className={styles.memberMenu}
              >
                <MenuItem onClick={handleEdit}>{t("common.edit")}</MenuItem>
                <MenuItem onClick={handleDelete}>{t("common.delete")}</MenuItem>
              </Menu>
            </div>
          ) : null}
        </div>

        <div
          className={styles.post_body}
          style={{ padding: original_post_owner ? "10px" : "0px" }}
        >
          <div
            style={{
              border: original_post_owner
                ? "1px solid rgba(0,0,0,0.2)"
                : "none",
              borderRadius: original_post_owner ? "10px" : "0px",
            }}
          >
            {original_post_owner && (
              <Box
                sx={{
                  display: "flex",
                  padding: "5px 20px",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    original_post_owner?.profile_pic ||
                    "/Image/header/ddefault_img.svg"
                  }
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "100%",
                    objectFit: "cover",
                  }}
                />
                <div className={styles.post_Info}>
                  <Link
                    to={`/profile/${original_post_owner?.id}/view`}
                    style={{ textDecoration: "none" }}
                  >
                    <h2 className={styles.post_info_userName}>
                      {original_post_owner?.name}
                    </h2>
                  </Link>
                </div>
              </Box>
            )}
            {message && (
              <div className={styles.postMessage}>
                <b
                  style={{
                    textTransform: "capitalize",
                    display: tags == "AGENDA" ? "block" : "none",
                  }}
                >
                  {tags == "AGENDA" ? title : null}
                </b>
                <br />
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <p>
                    {message?.includes("(Link:")
                      ? message.split("(Link:")[0]
                      : translatedMessageToShow}
                  </p>
                </div>
                <a
                  href={message?.split("(Link:").pop().split(")")[0]}
                  target="_blank"
                  style={{
                    display: "block",
                    margin: "10px 0",
                    color: "#00d498",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  {message?.includes("(Link:")
                    ? message?.split("(Link:").pop().split(")")[0]
                    : null}
                </a>
              </div>
            )}
            {agenda_title && (
              <div
                className={styles.postMessage}
                style={{ margin: "20px 5px 5px 5px" }}
              >
                <b>Title:</b> {agenda_title}
              </div>
            )}
            {agenda_description && (
              <div className={styles.postMessage} style={{ margin: "5px" }}>
                {agenda_description}
              </div>
            )}
            {agenda_link && (
              <div className={styles.postMessage} style={{ margin: "5px" }}>
                <b>Link:</b>{" "}
                <a
                  href={agenda_link}
                  target="_blank"
                  style={{
                    margin: "10px 0",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  {agenda_link}
                </a>
              </div>
            )}
            {video ? <Video src={video} /> : null}
            {file_url ? (
              <>
                <Box
                  onClick={() => window.open(file_url, "_blank")}
                  sx={{ width: "100%", cursor: "pointer" }}
                >
                  <PDFViewer
                    url={file_url}
                    width={
                      window.innerWidth <= 400
                        ? 250
                        : window.innerWidth <= 460
                        ? 300
                        : window.innerWidth <= 1200
                        ? 380
                        : window.innerWidth <= 1300
                        ? 480
                        : 560
                    }
                    show={false}
                  />
                </Box>
              </>
            ) : null}
            {post_details?.event_description && (
              <EventDisplay
                name={post_details?.name}
                address={post_details?.address}
                event_type={post_details?.event_type}
                start_date_time={post_details?.start_date_time}
                free={post_details?.free}
                event_description={post_details?.event_description}
                end_date_time={post_details?.end_date_time}
                pricing={post_details?.pricing}
                discounted_price={post_details?.discounted_price}
                show={false}
                postId={postId}
              />
            )}

            {post_details?.category && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  px: 3,
                  mb: 1,
                  alignItems: "center",
                }}
              >
                {post_details?.category && (
                  <>
                    <img
                      src={`/Image/CategoryIcons/${post_details?.category}.svg`}
                      alt="select category"
                      style={{ marginRight: 4, width: "20px" }}
                    />
                    <Typography sx={{ fontWeight: 600 }}>
                      {post_details?.category}
                    </Typography>
                  </>
                )}
              </Box>
            )}

            {photoUrl || sellImages?.length === 0 ? (
              <img
                src={photoUrl || "/Image/Discover/Sale/discover_sale.png"}
                alt=""
                style={{ width: "100%" }}
              />
            ) : null}

            {postType === "Event" && !photoUrl ? (
              <img
                src={"/Image/Discover/Events/discover_event.png"}
                alt=""
                style={{ width: "100%" }}
              />
            ) : null}

            <div className={styles.postMessage}>
              {message && currentLanguage.slice(0, 2) != postLanguage ? (
                seeTranslation ? (
                  <p
                    className={styles.translateText}
                    onClick={() => handleTranslatePost(message)}
                  >
                    {t("dashboard.translatePost")}
                  </p>
                ) : (
                  <p
                    className={styles.translateText}
                    onClick={() => handleSeeOriginal(message)}
                  >
                    {t("dashboard.seeOriginal")}
                  </p>
                )
              ) : null}
            </div>

            {post_details?.poll_question && (
              <PollDisplayGroup
                //   pollPost={post_details}
                from="GROUP"
                show={false}
                question={post_details?.poll_question}
                pollOptions={pollOptions}
                pollVotes={pollVotes}
                userVoted={userVoted}
                setUserVoted={setUserVoted}
                postId={postId}
              />
            )}

            {post_details?.purpose ? (
              <ExpertDisplay
                show={false}
                purpose={post_details?.purpose}
                location={post_details?.location}
              />
            ) : null}

            {post_details?.title ? (
              <SellDisplay
                images={sellImages}
                title={post_details?.title}
                description={post_details?.sell_description}
                discountedPrice={post_details?.discounted_price}
                discountOffer={post_details?.discount_offer}
                price={post_details?.pricing}
                isFree={post_details?.free}
                location={post_details?.location}
                category={post_details?.category}
                showEdit={false}
                postId={postId}
              />
            ) : null}

            {post_details?.location &&
            post_details?.latitude &&
            !post_details?.title ? (
              <Box sx={{ ml: 3, mr: 3, mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { lg: "row", xs: "column" },
                  }}
                >
                  <Chip
                    icon={<LocationOnIcon color="#000" />}
                    label={post_details?.location}
                    sx={{
                      mt: 1,
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                  />
                </Box>
              </Box>
            ) : null}
          </div>
        </div>

        <div className={styles.post_likeCommentBox}>
          <div className={styles.post_like} style={{ cursor: "pointer" }}>
            {distinctReactionsArray.map((reaction, idx) => (
              <img
                key={idx}
                src={`/Image/SmallReactions/${reaction}.svg`}
                alt="liked"
              />
            ))}
            <Box sx={{ ml: 0.5 }}>{profileLikes}</Box>
          </div>
          <div className={styles.post_comment} style={{ cursor: "pointer" }}>
            {comment != null ? comment.length : 0}{" "}
            {comment != null
              ? comment.length <= 1
                ? t("common.comment")
                : t("common.comments")
              : t("common.comment")}
          </div>
        </div>

        <div className={styles.post_option}>
          {likeClickable ? (
            <span>
              {!likeButtonPressed ? (
                <>
                  <PostButtonBottom
                    method={like}
                    argument={profileLikes}
                    color={"#666666"}
                    Display={ThumbUpOutlinedIcon}
                    text={t("common.Like")}
                    cursor={"pointer"}
                    onMouseEnter={handlePopoverClick}
                  />
                  <Popover
                    id={idPopover}
                    open={popoverOpen}
                    anchorEl={popoverEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        p: "5px 10px",
                        display: "flex",
                        overflow: "hidden",
                      }}
                    >
                      {reactions.map((reaction, idx) => (
                        <div key={idx}>
                          <StyledTooltip title={reaction} placement="top">
                            <li style={{ display: "flex" }}>
                              <motion.button
                                onClick={() => like(profileLikes, reaction)}
                                whileHover={{ scale: 1.3, originY: 1 }}
                                variants={fadeInAnimationsVariations}
                                initial="initial"
                                animate="animate"
                                viewport={{ once: true }}
                                custom={idx}
                                style={{
                                  padding: 0,
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  ":hover": { background: "none" },
                                }}
                              >
                                <img
                                  src={`/Image/Reactions/${reaction}.svg`}
                                  alt={reaction}
                                  style={{ width: "70%" }}
                                />
                              </motion.button>
                            </li>
                          </StyledTooltip>
                        </div>
                      ))}
                    </Box>
                  </Popover>
                </>
              ) : (
                <PostButtonBottom
                  method={like}
                  argument={profileLikes}
                  color={
                    likeReaction === "Like"
                      ? "secondary.main"
                      : likeReaction === "Celebrate"
                      ? "#44712E"
                      : likeReaction === "Support"
                      ? "#715E86"
                      : likeReaction === "love"
                      ? "#B24020"
                      : likeReaction === "InsightFull"
                      ? "#915907"
                      : likeReaction === "Funny"
                      ? "#1B707D"
                      : "#000"
                  }
                  image={`/Image/Reactions/${likeReaction}.svg`}
                  text={t(`common.${likeReaction}`)}
                  cursor={"pointer"}
                />
              )}
            </span>
          ) : (
            <span>
              {!likeButtonPressed ? (
                <PostButtonBottom
                  method={like}
                  argument={profileLikes}
                  color={"#666666"}
                  Display={ThumbUpOutlinedIcon}
                  text={t("common.Like")}
                  cursor={"not-allowed"}
                />
              ) : (
                <PostButtonBottom
                  method={like}
                  argument={profileLikes}
                  color={"secondary.main"}
                  Display={ThumbUpTwoToneIcon}
                  text={t("common.Like")}
                  cursor={"not-allowed"}
                />
              )}
            </span>
          )}
          <span>
            <PostButtonBottom
              method={handleOpenSelectedComment}
              argument={postId}
              color={"#666666"}
              Display={TextsmsOutlinedIcon}
              text={t("common.comment")}
              cursor={"pointer"}
            />
          </span>
          {/* <span>
          <PostButtonBottom
            method={onRepost}
            argument={postId}
            color={"#666666"}
            Display={RepeatOutlinedIcon}
            text={t("common.repost")}
            cursor={"pointer"}
          />
        </span>
        <span>
          <PostButtonBottomDirect
            method={shareModalOpener}
            color={"#666666"}
            Display={TelegramIcon}
            text={t("common.share")}
            cursor={"pointer"}
          />
        </span> */}
        </div>

        {openSelectedComment?.includes(postId) ? (
          <>
            <div className={styles.comment_input}>
              <div>
                <img
                  src={
                    profilePicUrl
                      ? profilePicUrl
                      : avatarImg
                      ? avatarImg
                      : "/Image/header/ddefault_img.svg"
                  }
                  className={styles.comment_avatar}
                />
              </div>
              <div className={styles.comment_input_div}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    value={Input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={t("dashboard.writeComment")}
                    sx={{
                      width: "100%",
                      padding: "4px 15px",
                      fontFamily: "primary.text",
                    }}
                    InputProps={{ disableUnderline: true }}
                  />

                  {emoji ? (
                    <Picker
                      className={styles.picker}
                      onEmojiClick={onEmojiClick}
                    />
                  ) : (
                    ""
                  )}
                </form>

                <div className={styles.comments_icons_div}>
                  <IconButton onClick={toggleEmojiPicker} sx={{ padding: 0 }}>
                    <SentimentSatisfiedOutlinedIcon />
                  </IconButton>
                </div>
              </div>
            </div>

            {comment[0] ? (
              <div className={styles.comments_div}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: "13px",
                    fontFamily: "primary.text",
                  }}
                >
                  {t("dashboard.mostRelevant")}
                </Typography>
                {commentSection()}
              </div>
            ) : null}
          </>
        ) : null}
      </Paper>
      <CourseModal
        title={t("classGroup.addAgenda")}
        closeModal={CourseModalCloser}
        show={showAgendaModal}
        agenda_title={agenda_title}
        agenda_description={agenda_description}
        agenda_link={agenda_link}
        image_url={photoUrl}
        video_url={video}
        postId={postId}
      />
    </>
  );
}

// export default Post;
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user,
    newComment: state?.dashboard?.newComment,
    newLike: state?.dashboard?.newLike,
    newTranslation: state?.dashboard?.newTranslation?.translated_text,
    currentLanguage:
      state?.dashboard?.currentLanguage ||
      localStorage.getItem("i18nextLng") ||
      navigator.language ||
      navigator.userLanguage ||
      "en",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postComment: (data) => dispatch(postComment(data)),
    postLikeDelete: (data) => dispatch(postLikeDelete(data)),
    postLike: (data) => dispatch(postLike(data)),
    translatePost: (data) => dispatch(translatePost(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostOfGroup);
