import { TextField } from "@mui/material";
import React from "react";
import { Placeholder } from "react-bootstrap";

export const ModalTextField2 = ({
  value,
  method,
  inputProp,
  name,
  multiline = false,
  rows = 1,
  placeholder = "",
}) => {
  return (
    <TextField
      name={name}
      fullWidth
      rows={rows}
      multiline={multiline}
      placeholder={placeholder}
      value={value}
      onChange={method}
      margin="normal"
      sx={{
        mt: 1,
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "secondary.main",
          },
          padding: multiline ? "6px 14px" : 0,
        },
      }}
      inputProps={{
        style: {
          padding: multiline ? 0 : "5px 14px",
        },
        ...inputProp,
      }}
    />
  );
};

const ModalTextfield = ({
  value,
  setValue,
  inputProp,
  multiline = false,
  rows = 1,
}) => {
  return (
    <TextField
      fullWidth
      rows={rows}
      multiline={multiline}
      defaultValue={value}
      onBlur={(e) => setValue(e.target.value)}
      margin="normal"
      sx={{
        mt: 1,
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "secondary.main",
          },
        },
      }}
      inputProps={{
        style: {
          padding: "5px 14px",
        },
        ...inputProp,
      }}
    />
  );
};

export default ModalTextfield;
