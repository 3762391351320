import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWithHeader } from '../../Dashboard/shared/ui/Modal/Modal';
import styles from './css/CreateEditCourseModal.module.css';
import MultipleSelectChip from '../../AssignmentAndQuiz/Components/MultipleSelectChip';
import Button from '../../../components/assignmentAndQuiz/Button/Button';
import UploadCourseFilesModal from "../Modals/UploadCourseFilesModal";
import { useSelector } from "react-redux";
import { validateFileSize } from "../../../redux/constants/commonFunctions";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


function CreateEditCourseModal(props) {
    const { t } = useTranslation();
    const dispatchReduxAction = useDispatch();
    const [thumbnailUrl, setThumbnailUrl] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [courseName, setCourseName] = useState(props.courseTitleType === t("common.edit") ? props.courseName : "");
    const [courseSubtitle, setCourseSubtitle] = useState(props.courseTitleType === t("common.edit") ? props.courseSubtitle : "");
    const [courseDescription, setCourseDescription] = useState(props.courseTitleType === t("common.edit") ? "" : "");
    const [coursePriceType, setCoursePriceType] = useState(`${t("createCourse.USD")} ($)`);
    const [coursePriceValue, setCoursePriceValue] = useState(null);
    const [freePaid, setFreePaid] = useState("");
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(!showModal);
    }
    const [style, setStyle] = useState(styles.createEditCourseModal);
    const profileId = useSelector((state) => state?.profile?.profileId) || 0;
    const classes = useSelector(state => state.classes.classes).filter((item) => item.class_owner == profileId)
    const classTags = props.classTags ?? [];
    const classLoggedIn = useSelector(state => state.classes.class);
    const currentClass = props.courseFeedCourse === true ? [classLoggedIn.name] : [];
    const [selectedClasses, setSelectedClasses] = useState(props.courseTitleType === t("common.edit") ? props.classTags.map((item) => item.name) : currentClass);
    const getSelectedClasses = (value) => {
        setSelectedClasses(value);
    }
    const handleThumbnail = (e) => {
        let errorMessage = validateFileSize(e.target.files[0], 5120);
        if (errorMessage) {
            dispatchReduxAction(handleErrorMessage(errorMessage));
        } else {
            setThumbnail(e.target.files[0])
            setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
        }
    }


    React.useEffect(() => {
        if (props?.courseDescription) {
            const html = props?.courseDescription;
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorcontent = EditorState.createWithContent(contentState);
            setCourseDescription(editorcontent);
        }
    }, []);

    // text editor on change function 
    const onEditorStateChange = (content) => {
        setCourseDescription(content);
    }

    return (<>
        {showModal && (
            <UploadCourseFilesModal
                courseTitleType={props.courseTitleType}
                handleClose={handleModal}
                courseType={props.courseType}
                courseName={courseName}
                coursePriceType={coursePriceType}
                coursePriceValue={props.courseTitleType === t("common.edit") ? props.coursePriceValue : coursePriceValue}
                courseSubtitle={courseSubtitle}
                courseDescription={draftToHtml(convertToRaw(courseDescription.getCurrentContent()))}
                classIds={selectedClasses.map(e => (classes.find(item => item.name === e))).map(x => x.id)}
                classTags={selectedClasses}
                thumbnail={thumbnail}
                thumbnailForDisplay={thumbnailUrl}
                isCourseFreeEdit={props.isCourseFreeEdit}
                isCourseFree={coursePriceValue !== 0 ? 'PAID' : 'FREE'}
                courseId={props.courseId}
                currentMediaFiles={props.currentMediaFiles}
                currentUrls={props.currentUrls}
                goBackClicked={false}
                courseView={props?.courseView}
                isActive={props?.isActive}
            />
        )}
        <div className={style}>
            <ModalWithHeader
                icon="/Image/CourseMonetisation/addIcon.svg"
                show={props.show}
                closeHandler={props.closeModal}
                maxWidth="605px"
                minWidth="250px"
                margin="10% 0 1% 0"
                title={`${props.courseTitleType} ${props.courseType} ${t("common.course")}`}
                disableOutsideClick={true}
            >

                <div className={styles.course_modal}>
                    <div className={styles.course_form}>
                        <div className={styles.thumbnail_area} >
                            <div
                                className={styles.upload_box} >
                                <input
                                    onChange={(e) => {
                                        // handleThumbnail(e)
                                    }}
                                    type="file"
                                    accept="image/*"
                                    className={styles.file_upload}
                                />
                                <img src="/Image/CreateCourse/upload.svg" alt="Photos" className={styles.upload_img} />
                                <p className={styles.upload_txt}>{t("createCourse.uploadThumbnail")}</p>
                                <p className={styles.support_txt}>{t("createCourse.supportJpegPngFiles")}</p>
                            </div>
                            {thumbnailUrl != null ?
                                <img src={thumbnailUrl} className={styles.thumbnail_pic} />
                                : null}
                        </div>

                        <h3 className={styles.input_label} for="publishTo">
                            {t("createCourse.publishTo")}{props.courseType == 'private' ? '*' : null}
                        </h3>

                        <MultipleSelectChip courseFeedCourse={props.courseFeedCourse} courseTitleType={props.courseTitleType} getSelectedClasses={getSelectedClasses} notDisabled="true" id="publishTo" classTags={classTags} assignmentClass={classes} />

                        <h3 for="courseName" className={styles.input_label}>
                            {t("createCourse.courseName")}
                        </h3>

                        <input
                            type="text"
                            id="courseName"
                            className={styles.input_field}
                            placeholder={t("createCourse.courseNamePH")}
                            onChange={(e) => setCourseName(e.target.value)}
                            defaultValue={props.courseName}
                        />

                        <h3 for="courseSubtitle" className={styles.input_label}>
                            {t("createCourse.courseSubtitle")}*
                        </h3>

                        <input
                            type="text"
                            id="courseSubtitle"
                            onChange={(e) => setCourseSubtitle(e.target.value)}
                            className={styles.input_field}
                            placeholder={t("createCourse.courseSubtitlePH")}
                            defaultValue={props.courseSubtitle}
                        />
                        <h3 for="description" className={styles.input_label}>
                            {t("common.description")}*
                        </h3>
                        {/* <textarea
                            rows="4"
                            type="text"
                            className={styles.textbox_field}
                            placeholder={t("createCourse.descPH")}
                            onChange={(e) => setCourseDescription(e.target.value)}
                            defaultValue={props.courseDescription}
                        /> */}
                        <Editor
                            toolbar={{
                                options: ['inline', 'list', 'history'],
                                inline: { options: ['bold', 'italic', 'underline'] },
                                list: { options: ['unordered', 'ordered'] },
                            }}
                            editorState={courseDescription}
                            toolbarClassName={styles.toolbarClassName}
                            wrapperClassName={styles.wrapper}
                            editorClassName={styles.textbox_field}
                            onEditorStateChange={onEditorStateChange}
                            placeholder={t("createCourse.descPH")}
                            defaultValue={props.courseDescription}
                        // initialEditorState={editorState}
                        />

                        {/* <textarea
                            disabled
                            value={draftToHtml(convertToRaw(courseDescription.getCurrentContent()))}
                        /> */}

                        <div className={styles.radio_div}>
                            <input
                                type="radio"
                                id="free"
                                name="freePaid"
                                onClick={() => {
                                    setFreePaid('FREE')
                                    setCoursePriceValue(0)
                                    setCoursePriceType('')
                                }}
                                className={styles.radio_btn}
                                disabled={props.courseTitleType === t("common.edit") ? true : false}
                            />
                            <span className={styles.radio_label}>{t("common.free")}</span>
                            <input
                                type="radio"
                                id="paid"
                                name="freePaid"
                                onClick={() => setFreePaid('PAID')}
                                className={styles.radio_btn}
                                disabled={props.courseTitleType === t("common.edit") ? true : false}
                            />
                            <span className={styles.radio_label}>{t("common.paid")}</span>
                        </div>
                        {
                            freePaid === 'PAID' ?
                                <div>
                                    <h3 for="description" className={styles.input_label}>
                                        {t("createCourse.price")}*
                                    </h3>
                                    <div className={styles.price_div}>
                                        <input
                                            type="text"
                                            id="priceType"
                                            className={styles.price_type_input}
                                            value={`${t("createCourse.USD")} ($)`}
                                        />
                                        <input
                                            type="number"
                                            id="price"
                                            onChange={(e) => setCoursePriceValue(Number(e.target.value))}
                                            className={styles.price_input}
                                            placeholder={"123"}
                                            defaultValue={props.coursePriceValue}
                                        />
                                    </div>
                                </div>
                                : null
                        }
                        <div className={styles.nextbtn_div} onClick={() => {
                            (props.courseTitleType === t("common.create") && freePaid !== "" && courseName !== "" && courseSubtitle !== "" && courseDescription !== "") && !(props?.courseType == 'Private' && selectedClasses?.length < 1) && handleModal();
                            (props.courseTitleType === t("common.create") && freePaid !== "" && courseName !== "" && courseSubtitle !== "" && courseDescription !== "") && !(props?.courseType == 'Private' && selectedClasses?.length < 1) && setStyle(styles.hideCreateEditCourseModal);
                            props.courseTitleType === t("common.edit") && !(props?.courseType == 'Private' && selectedClasses?.length < 1) && handleModal();
                            props.courseTitleType === t("common.edit") && !(props?.courseType == 'Private' && selectedClasses?.length < 1) && setStyle(styles.hideCreateEditCourseModal);
                        }}>
                            <Button
                                type="solid"
                                label={t("createCourse.next")}
                                disable={(props.courseTitleType === t("common.create") && (freePaid === "" || courseName === "" || courseSubtitle === "" || courseDescription === "" || (props?.courseType == 'Private' && selectedClasses?.length < 1)) || (props.courseTitleType === t("common.edit") && (props?.courseType == 'Private' && selectedClasses?.length < 1))) ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </ModalWithHeader>
        </div>
    </>
    );
}

export default CreateEditCourseModal;