import React, { useEffect, useState } from "react";
import styles from "./css/ChatList.module.css";
import ChatSmall from "./ChatSmall";
import ChatListHeader from "./ChatListHeader";
import axios from "axios";
// import SmallChatSearchView from "./SmallChatSearchView";

export default function ChatList(props) {
    // chatListType - normal, new_message
    const {
        openChat = () => {},
        chatListType = "normal",
        dmList: _dmList = [],
        updateDmList = () => {},
    } = props;

    const [showSuggestions, setShowSuggestions] = useState(
        chatListType === "new_message" ? true : false,
    );
    const [selectedNewMessageData, setSelectedNewMessageData] = useState([]);
    const [dmList, setdmList] = useState(_dmList);
    const [searchValue, setSearchValue] = useState("");

    // dmlist will be the dmlist from the server
    useEffect(() => {
        setdmList(_dmList);
    }, [_dmList]);

    async function serachUsers(searchTerm) {
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const userId = parsedUserData.user_id;
        const headers = {
            Authorization: `Token ${parsedUserData.token}`,
        };

        const result = await axios({
            url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?limit=100&search=${searchTerm}`,
            method: "get",
            headers,
        })
            .then((res) => {
                const filteredData = res.data.data.filter(
                    (item) => item.user_id !== userId,
                );
                return filteredData;
            })
            .catch(console.error);

        return result;
    }

    // handle search input change
    async function handleChange(e) {
        // if there is no input in the search, then show chat list from the server
        setSearchValue(e.target.value);
        if (!e.target.value) {
            setShowSuggestions(true);
            setdmList(...[_dmList]);
            return;
        }

        // get the results from the server and update the results
        const results = await serachUsers(e.target.value);
        setdmList(...[results]);

        setShowSuggestions(false);
    }

    function addItemInNewMessageIds(selectedUserId) {
        const index = dmList.findIndex((item) => {
            // recipient key is in the original dm list (suggested) and
            // user_id key is in the search api
            const userId = item?.dm_info?.members
                ? item?.dm_info.members[0].dm_id
                : item?.user_id;
            return userId === selectedUserId;
        });

        if (index < 0) return;
        setSelectedNewMessageData((prev) => {
            const updated = prev.filter((item) => {
                // recipient key is in the original dm list and
                // user_id key is in the search api
                const userId = item?.dm_info?.members
                    ? item?.dm_info.members[0].dm_id
                    : item?.user_id;
                return userId !== selectedUserId;
            });

            return [...updated, dmList[index]];
        });
    }

    function removeItemInNewMessageIds(removedUserId) {
        setSelectedNewMessageData((prev) => {
            const updated = prev.filter((item) => {
                // recipient key is in the original dm list and
                // user_id key is in the search api
                const userId = item?.dm_info?.members
                    ? item?.dm_info.members[0].dm_id
                    : item?.user_id;
                return userId !== removedUserId;
            });
            return updated;
        });
    }

    // create new channel api using userids
    async function createNewChannel(userIds) {
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const headers = {
            Authorization: `Token ${parsedUserData.token}`,
        };

        const channelType = userIds.length > 1 ? "group" : "direct";

        const result = await axios({
            url: `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channels/`,
            method: "post",
            headers,
            data: {
                channel_type: channelType,
                recipients_id: userIds,
            },
        }).then((res) => res.data.data);
        // result?.id - for a new message,
        // result?.channel_id - channel already exists
        return result?.id || result?.channel_id;
    }

    function handleOpenChat(channelOrUserId) {
        if (chatListType === "normal") {
            // channel id
            openChat(channelOrUserId);
        } else {
            // user id
            addItemInNewMessageIds(channelOrUserId);
        }
    }

    // oen the chat with new channelid
    async function handleNewMessageClick() {
        const userIds = selectedNewMessageData.map((item) => {
            const userId = item?.dm_info?.members
                ? item?.dm_info.members[0].dm_id
                : item?.user_id;
            return userId;
        });
        const channelId = await createNewChannel(userIds);
        await updateDmList();

        // reset to original state -----
        setSearchValue("");
        setSelectedNewMessageData([]);
        setShowSuggestions(true);
        // -----

        openChat(channelId);
    }

    return (
        <>
            <ChatListHeader
                searchValue={searchValue}
                chatListType={chatListType}
                handleChange={handleChange}
                showSuggestions={showSuggestions}
                removeItemInNewMessageIds={removeItemInNewMessageIds}
                data={selectedNewMessageData}
                handleNewMessageClick={handleNewMessageClick}
            />
            <div id="div-chatlist-container" className={styles.container}>
                {dmList.length
                    ? dmList.map((item, index) => {
                          // this could be channel id or the list of user ids incase of new message
                          const id =
                              chatListType === "normal"
                                  ? item?.channel
                                  : item?.dm_info?.members[0].dm_id ||
                                    item?.user_id;
                          // there will always be single memeber list, because we filtered the list in the parent
                          const name = item?.dm_info?.members
                              ? item?.dm_info.members[0].dm_name
                              : item.name;
                          return (
                              <ChatSmall
                                  key={id}
                                  openChat={handleOpenChat}
                                  name={name}
                                  id={id}
                                  lastMessage={item?.dm_info?.last_msg || ""}
                                  photo={
                                      item?.dm_info?.profile_pic ||
                                      item.profile_pic ||
                                      ""
                                  }
                                  // TODO make this dymanic
                                  // lastMessageTime={item.dm_info.msg_timestamp}
                                  lastMessageTime={item.elapsedTime}
                              />
                          );
                      })
                    : null}
            </div>
        </>
    );
}
