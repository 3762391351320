import { Box, Button, Typography } from "@mui/material";
import React from "react";

const PostButtonBottom = ({
  method,
  argument,
  color,
  Display,
  text,
  cursor,
  onMouseEnter,
  image = "",
  imageSize = "30px",
}) => {
  return (
    <Button
      onMouseEnter={onMouseEnter}
      onClick={() => method(argument)}
      disableRipple
      sx={{
        textTransform: "capitalize",
        color: color,
        ":hover": {
          background: "rgba(0,0,0,0.08)",
        },
        cursor: cursor,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {image !== "" ? (
          <img
            src={image}
            alt={`${image}`}
            style={{ marginRight: 5, width: imageSize, height: imageSize }}
          />
        ) : (
          <Display sx={{ mr: 1, color: color }} />
        )}
        <Typography
          sx={{ display: { lg: "block", xs: "none" }, fontWeight: 600 }}
        >
          {text}
        </Typography>
      </div>
    </Button>
  );
};

export const PostButtonBottomDirect = ({
  method,
  color,
  Display,
  text,
  cursor,
}) => {
  return (
    <Button
      onClick={method}
      disableRipple
      sx={{
        textTransform: "capitalize",
        color: color,
        ":hover": {
          background: "rgba(0,0,0,0.08)",
        },
        cursor: cursor,
      }}
    >
      <div style={{ display: "flex" }}>
        <Display sx={{ mr: 1, color: "#666666" }} />
        <Typography
          sx={{ display: { lg: "block", xs: "none" }, fontWeight: 600 }}
        >
          {text}
        </Typography>
      </div>
    </Button>
  );
};

export default PostButtonBottom;
