import React from "react";
import "./CSS/ProfileTypeToggle.css";

const ProfileTypeToggle = ({ profile_type, SwitchProfileType }) => {
  return (
    <div className="toggle-button-cover">
      <div className="button-cover">
        <div className="btnToggle b2" id="button-10">
          <input
            type="checkbox"
            className="checkbox_toggle"
            checked={profile_type === "PRIVATE" ? true : false}
            onChange={SwitchProfileType}
          />
          <div className="knobs">
            <span>Public</span>
          </div>
          <div className="layer"></div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTypeToggle;
