import React, { useState } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/ModalNew";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import styles from "../postModal/css/BottomContainer.module.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useTranslation } from "react-i18next";
import DoneButton from "../shared/ui/Modal/DoneButton";

const OccasionModal = ({
  show,
  closeModal,
  message,
  updateValueMessage,
  fileInput,
  pic,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(t("occasionModal.title"));

  const updateMessage = (item) => {
    updateValueMessage(item.msg);
    setTitle(t(item.text1));
  };

  const handleBack = () => {
    updateValueMessage("");
    setTitle(t("occasionModal.title"));
  };

  const occasionsList = [
    {
      text1: "occasionModal.opt1",
      text2: "occasionModal.opt1Sub",
      msg: "We are excited to have you join our team. Any tips to help them get started? #newhire #welcome",
    },
    {
      text1: "occasionModal.opt2",
      text2: "occasionModal.opt2Sub",
      msg: "We are excited and would like to appreciate! #kudos",
    },
    {
      text1: "occasionModal.opt8",
      text2: "occasionModal.opt8Sub",
      msg: "We are excited and would like to celebrate! #happybirthday",
    },
    {
      text1: "occasionModal.opt9",
      text2: "occasionModal.opt9Sub",
      msg: "We are excited and would like to share our new store opening! #newlyopened",
    },
    {
      text1: "occasionModal.opt3",
      text2: "occasionModal.opt3Sub",
      msg: "We are excited to announce the launch of our new project! #newproject",
    },
    {
      text1: "occasionModal.opt4",
      text2: "occasionModal.opt4Sub",
      msg: "Celebrating my work anniversary. Feeling thankful for this #workmilestone",
    },
    {
      text1: "occasionModal.opt5",
      text2: "occasionModal.opt5Sub",
      msg: "I'm excited to start my new job! #newjob",
    },
    {
      text1: "occasionModal.opt6",
      text2: "occasionModal.opt6Sub",
      msg: "I'm excited to share my educational milestone!",
    },
    {
      text1: "occasionModal.opt7",
      text2: "occasionModal.opt7Sub",
      msg: "Celebrating my new certification!",
    },
  ];

  const buttonStyle = {
    mb: 2,
    mr: 2,
    borderRadius: "50px",
    padding: "5px 17px",
    transition: "all 0.2s",
    fontWeight: 600,
    textTransform: "capitalize",
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          borderRadius: "0px 0px 15px 15px",
        }}
      >
        <Divider sx={{ background: "rgba(0,0,0,0.08)" }} />
        <Box
          sx={{
            maxHeight: "65vh",
            overflowY: "scroll",
          }}
        >
          {message == "" ? (
            <>
              {occasionsList.map((occasion, index) => (
                <Link
                  key={index}
                  sx={{ textDecoration: "none" }}
                  onClick={() => updateMessage(occasion)}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "15px 20px",
                      ":hover": { backgroundColor: "primary.background" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "primary.500",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      {t(occasion.text1)}
                    </Typography>
                    <Typography
                      sx={{
                        color: "primary.400",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    >
                      {t(occasion.text2)}
                    </Typography>
                  </Box>
                  <Divider sx={{ background: "rgba(0,0,0,0.08)" }} />
                </Link>
              ))}
            </>
          ) : (
            <Box sx={{ padding: "20px" }}>
              {pic == null ? (
                <Box
                  sx={{
                    background: "rgba(0,0,0,0.08)",
                    minHeight: "40vh",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    onChange={(e) => fileInput(e)}
                    type="file"
                    accept="image/*"
                    className={styles.fileUpload}
                  />
                  <Typography
                    sx={{
                      color: "secondary.main",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    <AddAPhotoIcon sx={{ mr: 1 }} />
                    {t("occasionModal.photo")}
                  </Typography>
                </Box>
              ) : (
                <img
                  src={pic}
                  alt="yourPic"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              )}
            </Box>
          )}
        </Box>
        <Divider sx={{ background: "rgba(0,0,0,0.08)", mb: 2 }} />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <DoneButton
            backMethod={message != "" ? handleBack : closeModal}
            disabled={pic == null ? true : false}
            closeModal={closeModal}
            showDivider={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default OccasionModal;
