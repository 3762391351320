import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { FeedsContext } from "./FeedsContext";

const PollDisplay = ({
  pollPost,
  PollModalOpener,
  show = true,
  question,
  pollOptions,
  updatePollPost,
  actionType,
  userVoted,
  setUserVoted,
  postId,
  from,
}) => {
  // const origin = from == "DASHBOARD" ? true : false;

  const { pollVote } = useContext(FeedsContext);
  const [totalCount, setTotalCount] = useState(1);

  const handlePollVote = (postid, optionId) => {

    pollVote(postid, optionId);
    setUserVoted(postid);
  };

  useEffect(() => {
    const totalPollCount = pollOptions?.reduce(
      (acc, poll) => acc + poll.poll_count,
      0
    );
    console.log(totalPollCount);
    setTotalCount(totalPollCount);
  });

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "8px 20px",
        mr: show === true ? 3 : 1,
        ml: 1,
        mb: 3,
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>
          {question ? question : pollPost?.question}
        </Typography>
        {show === true ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              disableRipple
              onClick={PollModalOpener}
              sx={{ background: "#666", padding: "5px" }}
            >
              <EditIcon fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
            {actionType !== "EDIT" ? (
              <IconButton
                disableRipple
                onClick={() => updatePollPost(null)}
                sx={{ background: "#666", ml: 1, padding: "5px" }}
              >
                <CloseIcon fontSize="small" sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        {pollOptions ? (
          <>
            {pollOptions.map((poll, idx) => (
              <Box
                key={poll?.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => handlePollVote(poll?.poll_post, poll?.id)}
                  disableRipple
                  variant={userVoted === postId ? "text" : "outlined"}
                  disabled={userVoted === postId ? true : false}
                  sx={{
                    width: userVoted === postId ? "85%" : "100%",
                    mt: 1,
                    borderRadius: userVoted === postId ? "5px" : "35px",
                    borderColor:
                      userVoted === postId ? "#fff" : "secondary.main",
                    color: "secondary.main",
                    ":hover": {
                      borderColor: "secondary.hover",
                      backgroundColor: "#e2f0fe",
                    },
                    textTransform: "capitalize",
                    display: "flex",
                    justifyContent:
                      userVoted === postId ? "flex-start" : "center",
                    background:
                      userVoted === postId && idx === 0
                        ? `linear-gradient(to right,#f8eaec 0%,#f8eaec ${(
                          (poll.poll_count / totalCount) *
                          100
                        ).toFixed()}%,#fff ${(
                          (poll.poll_count / totalCount) *
                          100
                        ).toFixed()}%,#fff 100%)`
                        : userVoted === postId && idx === 1
                          ? `linear-gradient(to right,#e3e9dd 0%,#e3e9dd ${(
                            (poll.poll_count / totalCount) *
                            100
                          ).toFixed()}%,#fff ${(
                            (poll.poll_count / totalCount) *
                            100
                          ).toFixed()}%,#fff 100%)`
                          : userVoted === postId && idx === 2
                            ? `linear-gradient(to right,#fae1dd 0%,#fae1dd ${(
                              (poll.poll_count / totalCount) *
                              100
                            ).toFixed()}%,#fff ${(
                              (poll.poll_count / totalCount) *
                              100
                            ).toFixed()}%,#fff 100%)`
                            : userVoted === postId && idx === 3
                              ? `linear-gradient(to right,#ffe5d9 0%,#ffe5d9 ${(
                                (poll.poll_count / totalCount) *
                                100
                              ).toFixed()}%,#fff ${(
                                (poll.poll_count / totalCount) *
                                100
                              ).toFixed()}%,#fff 100%)`
                              : "#fff",
                  }}
                >
                  {poll?.option}
                </Button>
                {userVoted === postId && (
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {((poll.poll_count / totalCount) * 100).toFixed()}%
                  </Typography>
                )}
              </Box>
            ))}
          </>
        ) : (
          <>
            {pollPost?.options.map((opt, idx) => (
              <Button
                key={idx}
                disableRipple
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: 1,
                  borderRadius: "35px",
                  borderColor: "secondary.main",
                  color: "secondary.main",
                  ":hover": {
                    borderColor: "secondary.hover",
                    backgroundColor: "#e2f0fe",
                  },
                  textTransform: "capitalize",
                }}
              >
                {opt.desc}
              </Button>
            ))}
          </>
        )}
      </Box>
      <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "13px",
            color: "secondary.main",
            mr: 1,
          }}
        >
          {totalCount} votes
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "13px",
            color: "primary.400",
            mr: 1,
          }}
        >
          {pollPost?.pollDuration == "1 week"
            ? "1w left"
            : pollPost?.pollDuration == "1 day"
              ? "1d left"
              : pollPost?.pollDuration == "3 days"
                ? "3d left"
                : pollPost?.pollDuration == "2 weeks"
                  ? "2w left"
                  : null}
        </Typography>
        {show === true ? (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "13px",
              color: "secondary.main",
              mr: 1,
            }}
          >
            View results
          </Typography>
        ) : null}
      </Box>
    </Paper>
  );
};

export default PollDisplay;
