import { React, Component } from "react";
import { connect } from "react-redux";
import { getGroups } from "../../../redux/actions/groups";
import MySubjectsComponent from "../Classes/MySubjectsComponent";

class MyGroups extends Component {
  constructor() {
    super();
    this.state = {
      showClassModal: false,
    };
  }
  ClassModalCloser = () => {
    this.setState({ showClassModal: false });
  };

  ClassModalOpener = () => {
    this.setState({ showClassModal: true });
  };
  componentDidMount = () => {
    this.props.getGroups();
  };
  render() {
    return (
      <MySubjectsComponent
        toggleChatList={this.props.toggleChatList}
        isChatListOpen={this.props.isChatListOpen}
        subjects={this.props.subjects}
        routeType={this.props.routeType}
        user={this.props?.user?.first_name && this.props?.user?.last_name ? this.props.user.first_name + " " + this.props.user.last_name : ""}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    subjects:
      state.groups.groups.map((each_class) => {
        return {
          id: each_class.id,
          name: each_class.name,
          grade: each_class.grade,
          // subject: each_class.subject,
          group_type: each_class.group_type,
          code: each_class.code,
          class_owner: each_class.group_owner,
          description: each_class.description,
          image_url: each_class.cover_image || "/Image/DashImage/group-cover.png",
        };
      }) || [],
    routeType: "/group",
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGroups: () => dispatch(getGroups()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyGroups);
