import React from "react";
import styles from "./Button.module.css";

const Button = (props) => {
  // props - type, clickHandler, label
  if (props.type == "solid") {
    return (
      <>
      {!props.disable ? 
        <button
        onClick={props.clickHandler}
        className={
          props?.fullWidth
            ? `${styles.full_width} ${styles.solid_btn}`
            : styles.solid_btn
        }
      >
        {props.label}
      </button>
      :
      <button
        // onClick={props.clickHandler}
        // className={styles.disable}
        className={
          props?.fullWidth
            ? `${styles.full_width} ${styles.disable}`
            : styles.disable
        }
      >
        {props.label}
      </button>
      }
      </>
    );
  }
  
   else {
    return (
      <button
        onClick={props.clickHandler}
        className={
          props?.fullWidth
            ? `${styles.full_width} ${styles.outline_btn}`
            : styles.outline_btn
        }
      >
        {props.label}
      </button>
    );
  }
};

export default Button;
