export const dummyData = [
    {
        id: 1,
        name: "Yash",
        image: "https://cdn.dummyjson.com/recipe-images/1.webp",
        lastMessage: "Great",
        lastMessageTime: "now",
    },
    {
        id: 2,
        name: "Riya Gupta",
        image: "https://cdn.dummyjson.com/recipe-images/2.webp",
        lastMessage: "wassup ?",
        lastMessageTime: "now",
    },
    {
        id: 3,
        name: "Aditya Goyal",
        image: "https://cdn.dummyjson.com/recipe-images/3.webp",
        lastMessage: "Hi, How are you doing? What is today's plan",
        lastMessageTime: "1hr",
    },
    {
        id: 4,
        name: "Yash",
        image: "https://cdn.dummyjson.com/recipe-images/1.webp",
        lastMessage: "Great",
        lastMessageTime: "now",
    },
    {
        id: 5,
        name: "Riya Gupta",
        image: "https://cdn.dummyjson.com/recipe-images/2.webp",
        lastMessage: "wassup ?",
        lastMessageTime: "now",
    },
    {
        id: 6,
        name: "Aditya Goyal",
        image: "https://cdn.dummyjson.com/recipe-images/3.webp",
        lastMessage: "Hi, How are you doing? What is today's plan",
        lastMessageTime: "1hr",
    },
    {
        id: 7,
        name: "Yash",
        image: "https://cdn.dummyjson.com/recipe-images/1.webp",
        lastMessage: "Great",
        lastMessageTime: "now",
    },
    {
        id: 8,
        name: "Riya Gupta",
        image: "https://cdn.dummyjson.com/recipe-images/2.webp",
        lastMessage: "wassup ?",
        lastMessageTime: "now",
    },
    {
        id: 9,
        name: "Aditya Goyal",
        image: "https://cdn.dummyjson.com/recipe-images/3.webp",
        lastMessage: "Hi, How are you doing? What is today's plan",
        lastMessageTime: "1hr",
    },
];
