import React, { useState } from 'react';
import styles from './FeatureVideosPage.module.css';
import VideoCard from '../../components/commons/VideoCard';
import { useTranslation } from 'react-i18next';

const FeatureVideosPage = () => {
  const { t } = useTranslation();

  const allVideos = [
    {
      'fileName': 'Profile-and-Resume.m4v',
      'text': 'common.profileResume',
    },
    {
      'fileName': 'Course.m4v',
      'text': 'common.courses',
    },
    {
      'fileName': 'Assignment-and-Quiz.m4v',
      'text': 'common.asgQuiz',
    },
    {
      'fileName': 'Chalkboard.m4v',
      'text': 'common.chalkboard',
    },
  ]



  return (
    <div className={styles.main_container}>
      <span className={styles.heading}>{t('common.chalkmatesFeatures')}</span>
      <div className={styles.videos_container}>
        {allVideos.map((item, index) => <>
          <div className={styles.video_card}>
            <VideoCard key={index} path={`/Image/FeatureVideos/${item.fileName}`} />
            <span>{t(`${item.text}`)}</span>
          </div>

        </>
        )}

      </div>
    </div>
  )
}

export default FeatureVideosPage;