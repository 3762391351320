import React from "react";
import styles from "../../apps/ClassesGroups/GroupModal/css/MiddleContain.module.css";

const PublicPrivateToggle = ({ handleChangeForSwitch, groupType }) => {
  return (
    <div>
      <div className={styles.switches_container}>
        <input
          className={styles.input_public_private}
          type="radio"
          id="switchMonthly"
          name="switchPlan"
          value="PUBLIC"
          checked={
            groupType == "PUBLIC" ? true : groupType == false ? true : false
          }
          onChange={handleChangeForSwitch}
        />
        <input
          className={styles.input_public_private}
          type="radio"
          id="switchYearly"
          name="switchPlan"
          value="PRIVATE"
          checked={groupType == "PRIVATE"}
          onChange={handleChangeForSwitch}
        />
        <label className={styles.input_label_monthly} for="switchMonthly">
          PUBLIC
        </label>
        <label className={styles.input_label_monthly} for="switchYearly">
          PRIVATE
        </label>
        <div className={styles.switch_wrapper}>
          <div className={styles.switch}>
            <div className={styles.switch_public}>PUBLIC</div>
            <div className={styles.switch_public}>PRIVATE</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicPrivateToggle;
