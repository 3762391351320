import React, { useState } from "react";
import AdCard from "./Components/AdCard/AdCard";
import InfoCard from "./Components/InfoCard/InfoCard";
import PictureCard from "./Components/PictureCard/PictureCard";
import "./Hero.css";
// import "../Hero/Hero.css";
import { Link } from "react-router-dom";
import { useLottie } from "lottie-react";
import HeroAnimation from "../../../animation/HeroAnimation.json";
import Confetti from "react-dom-confetti";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import Helmet from "helmet";
import { useDispatch } from "react-redux";
import { subscribeMember } from "../../../redux/actions/subscribe";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Layout from "../Layout/Layout";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import VideoModal from "./Components/VideoModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const infoList = [
  "home.info1",
  "home.info2",
  "home.info3",
  "home.info4",
  "home.info5",
];

const signButtons = [
  {
    name: "common.login",
    click: "/signin",
  },
  {
    name: "common.signup",
    click: "/signup",
  },
];

export default function HomePage() {
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const [playVideo, setPlayVideo] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <VideoModal playVideo={playVideo} setPlayVideo={setPlayVideo} />
      <Box sx={{ width: "100%", position: "relative" }}>
        <img
          src="/Image/Hero/Homepage-image.jpg"
          alt="homepage photo"
          className="homePhoto"
        />
        <Paper
          sx={{
            position: "absolute",
            p: { xs: "25px 25px 10px 25px", sm: "40px 40px 10px 40px" },
            top: { xs: "15vh", sm: "20vh" },
            left: { xs: "0px", sm: "50px", md: "110px" },
            right: { xs: "0px" },
            margin: { xs: "auto", sm: "0px" },
            borderRadius: "30px",
            width: { xs: "305px", sm: "350px" },
          }}
        >
          <Typography sx={{ fontSize: "18px", color: "#5b5b5b" }}>
            {t("home.headline1")}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography
              sx={{
                fontSize: { xs: "25px", sm: "29px" },
                color: "#ed2f75",
                fontWeight: 800,
              }}
            >
              {t("home.headline2")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "25px", sm: "29px" },
                color: "#3a3a3a",
                fontWeight: 800,
              }}
            >
              {t("home.headline3")}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "18px", color: "#5b5b5b" }}>
            {t("home.headline4")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: 2,
              mt: 2,
            }}
          >
            {infoList.map((info, idx) => (
              <Box
                key={idx}
                sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
              >
                <img
                  src="/Image/Hero/tick.svg"
                  alt="tick"
                  style={{ width: "18px" }}
                />
                <Typography
                  sx={{ fontSize: "14px", color: "#5b5b5b", fontWeight: 600 }}
                >
                  {t(`${info}`)}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 5,
              gap: 3,
            }}
          >
            {signButtons.map((btn, idx) => (
              <Button
                key={idx}
                onClick={() => history.push(`${btn.click}`)}
                disableRipple
                size="large"
                sx={{
                  background: "var(--primary-gradient)",
                  ":hover": {
                    background: "var(--primary-gradient)",
                  },
                  color: "#fff",
                  borderRadius: "10px",
                  px: { xs: 2, sm: 3 },
                  fontSize: "20px",
                  py: 0.5,
                }}
              >
                {t(`${btn.name}`)}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Button
              onClick={() => setPlayVideo(true)}
              disableRipple
              size="large"
              sx={{
                background: "none",
                ":hover": {
                  background: "none",
                },
                textTransform: "none",
                color: "#5b5b5b",
                borderRadius: "10px",
                px: 5,
                fontSize: "16px",
                py: 1,
                fontWeight: 600,
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 16px 0px",
                width: "90%",
              }}
              startIcon={
                <Avatar
                  variant="square"
                  src="/Image/Hero/play.svg"
                  sx={{ color: "#fff", width: "20px", height: "20px" }}
                />
              }
            >
              {t("common.learnMore")}
            </Button>

            <Box sx={{ display: "flex", alignItems: "center", mt: 4, gap: 1 }}>
              <Typography
                sx={{ fontSize: "14px", color: "#5b5b5b", fontWeight: 600 }}
              >
                {t("home.haveBusiness")}
              </Typography>
              <Typography
                onClick={() => history.push("signup")}
                sx={{
                  fontSize: "14px",
                  color: "#5371bd",
                  fontWeight: 600,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {t("home.createAccount")}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Box
        sx={{
          width: "100%",
          // padding: { xs: "0px", sm: "10px 20px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 3, sm: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, sm: 3 },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {/* <Typography sx={{ fontWeight: 500, color: "#a5a2a2" }}>
              {t("home.report")}
            </Typography> */}
            {/* <Typography
              onClick={() =>
                window.open(
                  "https://nxnearby.com/media/static/Privacy_Policy_Legal_Disclaimer.pdf",
                  "_blank"
                )
              }
              sx={{ fontWeight: 500, color: "#a5a2a2", cursor: "pointer" }}
            >
              {t("home.legalPolicy")}
            </Typography> */}
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, sm: 3 },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Typography sx={{ fontWeight: 500, color: "#a5a2a2" }}>
              {t("home.faq")}
            </Typography>
            <Typography sx={{ fontWeight: 500, color: "#a5a2a2" }}>
              {t("home.contactUs")}
            </Typography>
          </Box> */}
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 0, sm: 1.5 },
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: "#a5a2a2",
              textAlign: "center",
            }}
          >
            {t("home.copyright1")}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              color: "#a5a2a2",
              textAlign: "center",
            }}
          >
            {t("home.copyright2")}
          </Typography>
        </Box> */}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            mt: 1,
          }}
        >
          <Typography sx={{ fontWeight: 500, color: "#a5a2a2" }}>
            {t("home.bottomLine1")}
          </Typography>
          <img
            src="/Image/Hero/heart.svg"
            alt="heart"
            style={{ width: "15px", height: "auto" }}
          />
          <Typography sx={{ fontWeight: 500, color: "#a5a2a2" }}>
            {t("home.bottomLine2")}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
