import { useTranslation } from "react-i18next";
import styles from './CourseView.module.css'
import PriceBar from "../../Components/PriceBar";
import CourseButton from "../../Components/CourseButton";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useSelector } from 'react-redux';


function CourseAction(props){

    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem('userData'))?.user_subtype;
    const profileId = useSelector((state)=>state?.profile?.profileId)|| 0;

    const data = props?.data?.courses?.currentCourse;
    const activeState  = props?.data?.courses?.currentCourse?.active;
    const courseId =  props?.data?.courses?.currentCourse?.id;
    const coursePrice =  props?.data?.courses?.currentCourse?.price;



    var shortDesc="";
    var remove = false;
    var btnText=""
    var share = false
    var teacher = false
    var price = null
    // enroll here means that the course is enrollable
    var enroll = false;


    if(profileId === data?.owner?.id){
        price=data?.price;
        shortDesc=t("courseMonetisation.GetAccessText")
        teacher=true;
        btnText=t("courseMonetisation.Action")
    }

    else if(data?.is_enrolled ){
        shortDesc = t("courseMonetisation.ShareCourseContent");
        remove=true;
        teacher=false;
        btnText= t("courseMonetisation.ShareCourse");
        share=true;
    }
    else if(data?.free){
        shortDesc=t("courseMonetisation.GetAccessText");
        teacher=false;
        btnText=t("courseMonetisation.Enroll");
        enroll=true;
    }

    else if(!data?.free) {
        price=data?.price;
        shortDesc=t("courseMonetisation.GetAccessText")
        btnText=t("courseMonetisation.Buy")
        teacher=false;
        enroll=true;
    }

    return(<>
        
        {(props?.data?.courses?.currentCourse?.is_enrolled && !props?.data?.courses?.currentCourse?.free) ? null :
            <div className={styles.BuyingDetail}>
                <PriceBar shortDesc={shortDesc} price={price} remove={remove} />
                <CourseButton handleShowActionModal={props.handleShowActionModal} handleRedirect={props.handleRedirect} showModal={props.showModal} teacher={teacher} btnText={btnText} active={activeState} enroll={enroll} courseId={courseId} price={coursePrice}/>
            </div>
        }
     </>)


}

const mapStateToProps = (state, props) => {
    return {
      data: state
    };
  };

export default withTranslation()(connect(mapStateToProps,)(CourseAction));
