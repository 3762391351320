import React from "react";
import "./CSS/SaleSidebar.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Switch from "@mui/material/Switch";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };

const MyFavouritesSidebar = ({ params, setParams }) => {
  const history = useHistory();

  const handleFilters = (event) => {
    setParams((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div className="sale_page_sidebar_parent">
      <p
        className="sale_page_sidebar_gohome"
        style={{ display: "flex", gap: "5px", alignItems: "center" }}
        onClick={() => {
          history.push({ pathname: "/user" });
        }}
      >
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 1024 1024"
          fill="#000000"
          class="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z"
            fill=""
          />
        </svg>
        <span>Home</span>
      </p>

      <div>
        <p
          className="sale_page_sidebar_list_title"
          style={{ marginTop: "10px" }}
        >
          Filters
        </p>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="event"
                  checked={params.event}
                  onChange={handleFilters}
                />
              }
              label="Events"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="deals"
                  checked={params.deals}
                  onChange={handleFilters}
                />
              }
              label="Deals"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="stores"
                  checked={params.stores}
                  onChange={handleFilters}
                />
              }
              label="Stores"
            />
          </FormGroup>
        </Box>
      </div>
    </div>
  );
};

export default MyFavouritesSidebar;
