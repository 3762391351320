import React, { useState, useEffect } from "react";
import "./recording.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

function Recording({ showAudio, onSearch }) {
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);
  const [savedNotes, setSavedNotes] = useState([]);
  const [audio, setAudio] = useState(false);
  useEffect(() => {
    setAudio(showAudio);
  }, [showAudio]);

  const handleClose = () => {
    setAudio(!audio);
  };

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      console.log(transcript);
      setNote(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };

  const handleSaveNote = (e) => {
    setSavedNotes([...savedNotes, note]);
    setNote("");
    setAudio(!audio);
    setIsListening(false);
    onSearch(e, note);
  };

  return (
    <>
      <Modal
        open={audio}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // disableBackdropClick
      >
        <div className="audioContainer">
          <Box
            sx={{ position: "relative", cursor: "pointer" }}
            onClick={() => setIsListening((prevState) => !prevState)}
          >
            <img
              src="/Image/DashImage/searchbar-mic-icon.svg"
              height={80}
              width={80}
            />
            {isListening && <div className="pulse-ring"></div>}
          </Box>
          <p>
            {isListening
              ? note === null
                ? ""
                : `${note}`
              : "Click on icon to record"}
          </p>
          {/* <p>{note}</p> */}
          <div className="audio_buttons_container">
            <Button
              disabled={!note}
              onClick={(e) => handleSaveNote(e)}
              disableRipple
              sx={{
                background: "var(--primary-gradient)",
                ":hover": {
                  background: "var(--primary-gradient)",
                },
                textTransform: "none",
                color: "#fff",
                borderRadius: "10px",
                px: 2.5,
                "&.Mui-disabled": { color: "#fff" },
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setNote("");
                setAudio(!audio);
                setIsListening(false);
              }}
              disableRipple
              variant="outlined"
              sx={{
                background: "#f2f2f2",
                ":hover": {
                  background: "#e2e2e2",
                  border: "none",
                },
                textTransform: "none",
                color: "#000",
                borderRadius: "10px",
                px: 4,
                border: "none",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Recording;
