import axios from "axios";
import {
  errorMessageFromResponse,
  formatName,
} from "../constants/commonFunctions";
import { DELETE_USER, GET_CODE_SUGGESTIONS } from "../constants/actionTypes";
import { store } from "../store";
import { handleSuccessMessage } from "./success";
import { handleErrorMessage } from "./classes";

export function fetchProfileDataEdit(action, token, id) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    return axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${id}/?target=No_Translation`,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const user = { ...res.data.data };
          user.profile_completion_status = res.data.profile_completion_status;

          user.name = formatName(user.name);
          dispatch({
            type: action,
            payload: user,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function fetchProfileDataView(action, token, id) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    return axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${id}/?target=${lang}/`,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const user = res.data.data;
          user.name = formatName(user.name);
          dispatch({
            type: action,
            payload: user,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateProfileData(action, data) {
  const profileId = store.getState()?.profile?.user?.profile_id;
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${profileId}/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status == "1") {
          const value = Object.fromEntries(data.entries());
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Information updated successfully!"));
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function fetchSearchResultsData(action, url) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const payloadData = {
            data: res.data.data,
            nextSearch: res.data.next,
            prevSearch: res.data.previous,
          };

          dispatch({
            type: action,
            payload: payloadData,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export const getEnterpriseCodes = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/enterprise_codes/`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        const codeSuggestions = response.data.data;
        dispatch({ type: GET_CODE_SUGGESTIONS, payload: codeSuggestions });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const deleteUser = () => {
  const token = localStorage.getItem("token");
  var userData = localStorage.getItem("userData");
  var parsedUserData = JSON.parse(userData);

  const data = new FormData();
  data.append("user_id", parsedUserData.user_id);
  return async (dispatch) => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/users/deleteUser/`,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch({ type: DELETE_USER });
          localStorage.removeItem("userData");
          localStorage.removeItem("token");
          dispatch(handleSuccessMessage("User Deleted Successfully"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage("Unable to delete user: ", error));
        return error;
      });
  };
};
