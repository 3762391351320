import { Box, CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import CloseIcon from "@mui/icons-material/Close";

function PDFViewer({ url, width, handleRemovePdf, show = true }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Box sx={{ position: "relative" }}>
      <div
        className="flex items-center"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          overflow: "scroll",
        }}
      >
        {numPages ? (
          <button
            className="text-3xl text-center text-white rounded-full w-10 h-10 bg-primary-500 hover:bg-primary-600 border-[2px] border-primary-500 mr-auto"
            disabled={currentPage === 1}
            onClick={() => {
              currentPage <= 1
                ? setCurrentPage(1)
                : setCurrentPage(currentPage - 1);
            }}
          >
            <FiChevronLeft />
          </button>
        ) : null}
        <div style={{ width: "90%", border: "1px solid rgba(0,0,0,0.8)" }}>
          <Document
            file={url}
            renderMode="canvas"
            onLoadSuccess={onDocumentLoadSuccess}
            noData={<h1>No PDF found</h1>}
            error={
              <h1
                style={{ color: "red", fontWeight: 500, textAlign: "center" }}
              >
                Failed to load PDF.
              </h1>
            }
            loading={<CircularProgress />}
          >
            <Page pageNumber={currentPage} width={width} />
          </Document>
        </div>
        {numPages ? (
          <button
            className="text-3xl text-center text-white rounded-full w-10 h-10 bg-primary-500 hover:bg-primary-600 border-[2px] border-primary-500 ml-auto"
            disabled={currentPage === numPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <FiChevronRight />
          </button>
        ) : null}
      </div>
      {show && (
        <Box>
          <IconButton
            onClick={handleRemovePdf}
            disableRipple
            sx={{
              position: "absolute",
              right: 23,
              background: "#666",
              padding: "5px",
              top: 7,
              ":hover": {
                background: "#666",
              },
              right: 70,
              top: 12,
            }}
          >
            <CloseIcon fontSize="small" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default PDFViewer;
