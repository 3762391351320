import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../redux/constants/commonFunctions";
import styles from "./css/DueDate.module.css";

class DueDate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.due_date_container}>
        <img
          src="/Image/AssignmentAndQuizzes/DueDate.svg"
          alt="Due Date Icon"
        />
        <p className={styles.due_date}>
          {" "}
          {this.props.t("assignment.due")} {formatDate(this.props?.date)}
          {/* {props.dueDate}, {props.dueTime} */}
        </p>
      </div>
    );
  }
}

export default withTranslation()(DueDate);
