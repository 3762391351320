import {
  CHANGE_CLASS_ACTIVE_SECTION,
  CLASS_ID_BY_CODE,
  GET_CLASSES,
  GET_CLASS_DETAILS,
  POST_CLASS_DETAILS,
  PUT_CLASS_DETAILS,
  POST_COURSE_DETAILS,
  POST_MEMBERS_DETAILS,
  POST_MISSION,
  PUT_TIME_TABLE,
  DELETE_CLASS,
  DELETE_MEMBER,
  GET_CLASS_POST,
  GET_ALL_CLASS_OWNER_CODES,
} from "../constants/actionTypes";

const defaultState = {
  classes: [],
  class: {},
  courses: [],
  members: [],
  posts: [],
  classesActiveSection: "",
  classOwners : [],
};
let classData = {};
let index = 0;
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CLASSES:
      classData = { ...state };
      classData.classes = action.payload;
      return classData;

    case GET_CLASS_DETAILS:
      classData = {
        ...state,
        classes: [...state.classes],
        class: { ...state.class },
        courses: state?.courses.map((course) => {
          return { ...course };
        }),
        members: [...state.members],
        posts: [...state.posts]
      };
      classData.class = action.payload.class;
      classData.members = action.payload.members;
      classData.courses = action.payload.courses;
      classData.meetings = action.payload.meetings;
      

      return classData;
    case POST_CLASS_DETAILS:
    case PUT_CLASS_DETAILS:
      classData = {...state}
      index = classData.classes.findIndex(function(classess){
        return classess.id === action.payload.id;
     })
     classData.classes[index] = {...classData.classes[index], ...action.payload}
     return classData;

    case POST_MEMBERS_DETAILS:
      return { ...state };
    case POST_COURSE_DETAILS:
      classData = { ...state };
      classData.class.cover_image = action.payload
      return classData;

    case POST_MISSION:
      classData = { ...state };
      classData.class.vision_mission = action.payload;
      return classData;

      case PUT_TIME_TABLE:
        classData = { ...state };
        classData.timetable = action.payload;
        return classData;

    case CLASS_ID_BY_CODE:
      return { ...state };
    case CHANGE_CLASS_ACTIVE_SECTION:
      classData = { ...state };
      classData.classesActiveSection = action.payload;
      return classData;
    case DELETE_CLASS:
      classData = {...state};
        index = classData.classes.findIndex(function(clas){
        return clas.id === action.payload;
   })
   if (index !== -1) classData.classes.splice(index, 1);
      
  return classData;
   
  case DELETE_MEMBER:
    classData = {...state}
    classData.members = action.payload
      
  return classData;

  case GET_CLASS_POST:
    classData = {...state}
    classData.posts = action.payload
    return classData;

  case GET_ALL_CLASS_OWNER_CODES:
    classData = {...state};
    classData.classOwners = action.payload;
    return classData;


    default:
      return { ...state };
  }
};
