import React, { useContext } from "react";
import { Box, Button, Grid } from "@mui/material";
import "./CSS/MapPage.css";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CoverCard, {
  EventCoverCard,
  GroupCoverCard,
  SellCoverCard,
} from "./CoverCard";
import ItemCards, {
  EventItemCards,
  GroupItemCards,
  NeighbourItemCards,
  SellItemCards,
} from "./ItemCards";
import { DiscoverContext } from "./DiscoverContext";
import { store } from "../../redux/store";

const buttonStyleCard = {
  background: "none",
  width: "80%",
  alignItems: "center",
  color: "secondary.main",
  textTransform: "none",
};

const CategoryContent = ({
  activeCategory,
  places,
  peopleYouMayKnowContent,
}) => {
  const history = useHistory();
  const { state } = useContext(DiscoverContext);

  console.log("store", store?.getState()?.groups?.groups);

  const groupList = store?.getState()?.groups?.groups;
  switch (activeCategory) {
    case "latest":
      return (
        <div id="latest">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={12}>
                <EventCoverCard />
              </Grid>
              {state?.eventPosts?.map((place, index) => (
                <>
                  {index < 4 && (
                    <Grid item md={6}>
                      <EventItemCards place={place} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </div>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => history.push("/discover/event")}
              disableRipple
              sx={buttonStyleCard}
            >
              Discover more events
            </Button>
          </Box>
          <br />
          <p style={{ fontWeight: 600 }}>Nearby groups you might like</p>
          <br />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={12}>
                <GroupCoverCard />
              </Grid>
              {groupList.map((place, index) => (
                <>
                  {index < 2 && (
                    <Grid item md={6}>
                      <GroupItemCards place={place} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </div>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => history.push("groups")}
              disableRipple
              sx={buttonStyleCard}
            >
              Discover more groups
            </Button>
          </Box>

          <br />
          {peopleYouMayKnowContent && peopleYouMayKnowContent.length > 1 && (
            <p style={{ fontWeight: 600 }}>Neighbors you may know</p>
          )}
          <br />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              {peopleYouMayKnowContent.slice(0, 4).map((person, index) => (
                <React.Fragment key={index}>
                  {index < 4 && (
                    <Grid item md={6}>
                      <NeighbourItemCards person={person} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </div>

          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disableRipple
              style={buttonStyleCard}
              onClick={() => history.push("/connections")}
            >
              See more Neighbors
            </Button>
          </Box>

          <br />
          {places && places.length > 1 && (
            <div>
              <p style={{ fontWeight: 600 }}>Nearby business recommendations</p>
            </div>
          )}
          <br />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              {state?.localBusiness?.slice(0, 3).map((place, index) => (
                <Grid item md={6}>
                  <ItemCards place={place} />
                </Grid>
              ))}
            </Grid>
          </div>

          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disableRipple
              sx={buttonStyleCard}
              onClick={() => history.push("/discover/business")}
            >
              checkout more local favourites
            </Button>
          </Box>
        </div>
      );
    case "business":
      return (
        <div id="business">
          {state?.localBusiness && state?.localBusiness.length > 1 && (
            <div style={{ marginBottom: 16 }}>
              <p style={{ fontWeight: 600 }}>Nearby business recommendations</p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              {state?.localBusiness?.slice(0, 11).map((place, index) => (
                <Grid item md={6}>
                  <ItemCards place={place} />
                </Grid>
              ))}
            </Grid>
          </div>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disableRipple
              sx={buttonStyleCard}
              onClick={() => history.push("/discover/business")}
            >
              checkout more local favourites
            </Button>
          </Box>
        </div>
      );
    case "events":
      return (
        <div id="events">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={12}>
                <EventCoverCard />
              </Grid>
              {state?.eventPosts?.slice(0, 11)?.map((place, index) => (
                <Grid key={index} item md={6}>
                  <EventItemCards place={place} />
                </Grid>
              ))}
            </Grid>
          </div>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => history.push("/discover/event")}
              disableRipple
              sx={buttonStyleCard}
            >
              Discover more events
            </Button>
          </Box>
        </div>
      );
    case "sale":
      return (
        <div id="sale">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid container spacing={2}>
              {state?.sellPosts?.slice(0, 11).map((place, index) => (
                <>
                  {index === 0 && (
                    <Grid item md={12}>
                      <SellCoverCard place={place} />
                    </Grid>
                  )}
                  {index > 0 && (
                    <Grid item md={6}>
                      <SellItemCards place={place} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </div>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => history.push("/discover/sale")}
              disableRipple
              sx={buttonStyleCard}
            >
              See more listings
            </Button>
          </Box>
        </div>
      );
    default:
      return null;
  }
};

function mapStateToProps(state) {
  return {
    peopleYouMayKnowContent: state.profile?.peopleYouMayKnow || [],
  };
}

export default withTranslation()(connect(mapStateToProps)(CategoryContent));
