import React from 'react'
import { useTranslation } from 'react-i18next';
import Button from '../../../components/assignmentAndQuiz/Button/Button';
import styles from "./css/CourseActionModal.module.css"

const CourseActionModal = (props) => {
  // expected props = type (active,inactive,delete), onCancel function and onConfirm function

  const {t} = useTranslation();
  let headerText = "";
  let mainText = ""

  switch(props.type){
    case "delete": {
      headerText = t("courseMonetisation.deleteCourse"); 
      mainText = t("courseMonetisation.areYouSure");
      break;
    }
    case "active": {
      headerText = t("courseMonetisation.makeActive"); 
      mainText = t("courseMonetisation.doYouWantActive");
      break;
    }
    case "inactive": {
      headerText = t("courseMonetisation.makeInactive"); 
      mainText = t("courseMonetisation.doYouWantInactive");
      break;
    }
    default: break;
  }
  

  return (
    <div className={styles.action_modal_container}>
      <div className={styles.header_text}>
        {headerText}
      </div>

      <div className={styles.main_text}>
        {mainText}
      </div>

      <div className={styles.buttons}>
        <Button label={t("common.cancel")} clickHandler={props.onCancel}></Button>
        <Button label={t("common.confirm")} type="solid" clickHandler={props.onConfirm}></Button>
      </div>

    </div>
  )
}

export default CourseActionModal;