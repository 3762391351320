import React from "react";
import EditAbout from "./Modals/EditAbout";
import Modal from "../../../../../components/commons/Modal";
import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

class AboutFeed extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      about: this.props.about || t("profile.writeShortBioAboutYourself"),
      // pictureAsFile: null,
      edit: false,
      text: "You can write about yourself",
    };

    this.changeText = this.changeText.bind(this);
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeText(text) {
    this.setState({ text: text });
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.about_div}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 20px 0px 0px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
            <img
              src={
                this.props.userType === "INDIVIDUAL"
                  ? "/Image/Profile/about.svg"
                  : "/Image/Business Profile/business_info.svg"
              }
              alt={
                this.props.userType === "INDIVIDUAL" ? "about" : "business info"
              }
              style={{ width: "20px" }}
            />
            <Typography
              sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
            >
              {this.props.userType === "INDIVIDUAL"
                ? t("profile.about")
                : t("profile.businessInfo")}
            </Typography>
          </Box>
          <div
            onClick={() => {
              this.setState({ edit: true });
              document.body.style.overflow = "hidden";
            }}
          >
            <img
              src="/Image/Profile/edit.svg"
              style={{ height: 20, cursor: "pointer" }}
            />
          </div>
        </div>

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "20px 30px",
            borderRadius: 10,
          }}
        >
          <p className={styles.section_desc}>
            {this.props.about || t("profile.writeShortBioAboutYourself")}
          </p>
        </div>

        {this.state.edit ? (
          <Modal
            card={
              <EditAbout
                modalTitle={t("profile.editAbout")}
                // text={this.state.text}
                changeEdit={this.changeEdit.bind(this)}
                changeText={this.changeText.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    about: state?.profile?.about,
    userType: state?.profile?.user?.user_type,
  };
}

export default withTranslation()(connect(mapStateToProps, null)(AboutFeed));
