import {
  GET_ERROR_MESSAGE,
  GET_CLASSES,
  GET_CLASS_DETAILS,
  POST_CLASS_DETAILS,
  PUT_CLASS_DETAILS,
  POST_COURSE_DETAILS,
  POST_MISSION,
  POST_MEMBERS_DETAILS,
  GET_USER_SUGGESTIONS,
  CLASS_ID_BY_CODE,
  CHANGE_CLASS_ACTIVE_SECTION,
  CHANGE_GROUP_ACTIVE_SECTION,
  PUT_TIME_TABLE,
  DELETE_CLASS,
  DELETE_MEMBER,
  GET_CLASS_POST,
  POST_GROUP_MISSION,
  UPDATE_GROUP_COVER,
  ERROR_STATUS_CODE,
  GET_ALL_CLASS_OWNER_CODES,
  GET_GROUPS,
  GET_MYGROUPS,
} from "../constants/actionTypes";
import axios from "axios";
import moment from "moment";
import {
  GET_CLASS_DETAILS_API,
  GET_CLASSES_API,
  POST_CLASS_DETAILS_API,
  POST_COURSE_DETAILS_API,
  GET_GROUP_DETAILS_API,
  POST_MEMBERS_DETAILS_API,
  GET_USER_SUGGESTIONS_API,
  GET_CLASS_ID_BY_CODE,
  DELETE_MEMBER_API,
  PUT_CLASS_DETAILS_API,
  INVITE_VIA_EMAIL,
} from "../constants/apis";
import { store } from "../store";
import { getGroups } from "./groups";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleSuccessMessage } from "./success";
import { getAllAssignments, getAllAssignmentsQuizes } from "./assignment";
import { getAllQuizzes } from "./quiz";

export const handleErrorMessage = (errorMessage) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessageFromResponse(errorMessage),
  };
};
export const getClassDetails = (classId) => {
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  return async (dispatch) => {
    // dispatch(getAllAssignments(classId, 'class'))
    // dispatch(getAllQuizzes(classId, 'class'))
    const getClassDetailsEndpoint = `${GET_CLASS_DETAILS_API}${classId}/?target=${lang}/`;
    const token = store.getState()?.profile?.user?.token;
    await axios
      .get(getClassDetailsEndpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // dispatch(getAllAssignmentsQuizes(classId, 'class'))
        dispatch({
          type: ERROR_STATUS_CODE,
          payload: response.status,
        })
        const data = response.data.data;
        const { courses, members, meetings } = data;
        // let myCourses = courses.map((each_course) => {
        //   return {
        //     id: each_course.id,
        //     title: each_course.name,
        //     link: each_course.external_link,
        //     src: each_course.image_url || "/Image/class/courseDefault.png",
        //   };
        // });
        let myCourses = [];
        let myMembers = members.map((each_member) => {
          return {
            id: each_member.id,
            name: each_member.profile.name,
            university: each_member.profile.organisation,
            img:
              each_member.profile.profile_pic || "/Image/DashImage/profile.png",
            privilige: each_member.privilige,
            profileId: each_member.member_users,
          };
        });
        let myMeetings = meetings.reduce((recordingsArr, each_meetings) => {
          const newMeetingObject = {
            uid: each_meetings.meeting_uuid,
            topic: each_meetings.topic,
            time: moment(each_meetings.meeting_created_at).format("LLLL"),
            count: each_meetings.recording_count,
          };
          const recordingObjects = each_meetings?.recordings.map(
            (recording, index, recordings) => {
              const newRecordingObject = { ...newMeetingObject };
              newRecordingObject.playUrl = recording.play_url;
              newRecordingObject.topic =
                recordings.length > 1
                  ? `${each_meetings.topic}-Session${index + 1}`
                  : `${each_meetings.topic}`;
              return newRecordingObject;
            }
          );
          return recordingObjects.length > 0
            ? [...recordingsArr, ...recordingObjects]
            : [...recordingsArr];
        }, []);
        // let sortedPosts = posts.sort((a, b) => b.id - a.id);
        // let myPosts = sortedPosts.map((post) => post);
        const classDetails = {
          class: {
            id: data.id,
            name: data.name,
            description: data.description,
            grade: data.grade,
            subject: data.subject,
            cover_image: data.cover_image,
            code: data.code,
            class_owner: data.class_owner,
            vision_mission: data.vision_mission,
            time_table: data.time_table,
          },
          members: myMembers,
          courses: myCourses,
          // posts: myPosts,
          meetings: myMeetings,
        };
        dispatch({ type: GET_CLASS_DETAILS, payload: classDetails });
        return response;
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: ERROR_STATUS_CODE,
            payload: error.response.status,
          })
        } else {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        }
        return error;
      });
  };
};

export const getClasses = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_CLASSES_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const classes = response.data.data.map(
          (classData) => classData.classes
        );
        dispatch({ type: GET_CLASSES, payload: classes });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
export const getMyGroups = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/group/my-groups/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const classes = response.data.data.map(
          (classData) => classData
        );
        dispatch({ type: GET_MYGROUPS, payload: classes });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getUserSuggestions = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_USER_SUGGESTIONS_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const userSuggestions = response.data.data;
        dispatch({ type: GET_USER_SUGGESTIONS, payload: userSuggestions });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const postClass = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    const data1 = { ...data };
    delete data1.code;
    if (Object.values(data1).every((field) => field === "")) {
      dispatch(handleErrorMessage("You can't submit an empty form"));
    } else {
      await axios({
        method: "post",
        url: POST_CLASS_DETAILS_API,
        data: {
          class_owner: store.getState()?.profile?.user.profile_id,
          grade: data.classGrade,
          name: data.className,
          description: data.classDescription,
          subject: data.classSubject,
          code: data.code,
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          if (response?.data?.status) {
            dispatch({
              type: POST_CLASS_DETAILS,
              payload: response.data.status,
            });
            dispatch(handleSuccessMessage("Class created successfully"));
          }
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    }
  };
};

export const editClass = (data) => {
  const token = store.getState()?.profile?.user?.token;

  const formData = new FormData();
  if (data.className !== "") formData.append("name", data.className);

  if (data.classDescription !== "")
    formData.append("description", data.classDescription);

  if (data.classGrade !== "") formData.append("grade", data.classGrade);

  if (data.classSubject !== "") formData.append("subject", data.classSubject);

  let value = Object.fromEntries(formData.entries());
  value = { ...value, id: data.classId };

  return async (dispatch) => {
    await axios({
      method: "put",
      url: `${PUT_CLASS_DETAILS_API}${data.classId}/`,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({ type: PUT_CLASS_DETAILS, payload: value });
          dispatch(handleSuccessMessage("Successfully updated class details"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const deleteClass = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${PUT_CLASS_DETAILS_API}${data}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        dispatch({ type: DELETE_CLASS, payload: data });
        dispatch(handleSuccessMessage("Class deleted successfully"));
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const postCourse = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  formData.append("name", data.title);
  if (data.group_courses != null)
    formData.append("group_courses", data.group_courses);
  formData.append("external_link", data.link);
  if (data.src != null) {
    formData.append("image_url", data.src.photo, data?.src.photo.name);
  }
  if (data.class_courses != null)
    formData.append("class_courses", data.class_courses);
  return async (dispatch) => {
    await axios({
      method: "post",
      url: POST_COURSE_DETAILS_API,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: POST_COURSE_DETAILS,
            payload: response.data.data,
          });
          dispatch(
            handleSuccessMessage("Your new course is added successfully!")
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
export const updateClass = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  if (data?.file != null) {
    formData.append("cover_image", data?.file, data?.file?.name);
  }
  const url =
    data?.updateType === "Class"
      ? `${GET_CLASS_DETAILS_API}${data.id}/`
      : `${GET_GROUP_DETAILS_API}${data.id}/`;
  return async (dispatch) => {
    await axios({
      method: "put",
      url: url,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          if (data?.updateType === "Class") {
            dispatch({
              type: POST_COURSE_DETAILS,
              payload: URL.createObjectURL(data?.file),
            });
          } else {
            dispatch({
              type: UPDATE_GROUP_COVER,
              payload: URL.createObjectURL(data?.file),
            });
          }

          dispatch(handleSuccessMessage("Updated successfully"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

/* ----------------------------------Missions---------------------------------- */
export const updateMission = async (data, onSuccess, onError) => {
  try {
    const token = store.getState()?.profile?.user?.token;
    const formData = new FormData();
    formData.append("group_objective", data?.missionText);
    const url =
      data?.routeType === "Class"
        ? `${GET_CLASS_DETAILS_API}${data.class_id}/`
        : `${GET_GROUP_DETAILS_API}${data.classId}/`;

    const response = await axios.put(url, formData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data.status === "1"
    ) {
      onSuccess(response.data.data);
      if (data?.routeType === "Class") {
        store.dispatch({
          type: POST_MISSION,
          payload: data?.missionText,
        });
      } else {
        store.dispatch({
          type: POST_GROUP_MISSION,
          payload: data?.missionText,
        });
      }
    }
  } catch (error) {
    onError(error);
    store.dispatch(handleErrorMessage(errorMessageFromResponse(error)));
    return error;
  }
};
/* ----------------------------------Time Table---------------------------------- */
export const updateTimeTable = async (data, onSuccess, onError) => {
  try {
    const token = store.getState()?.profile?.user?.token;
    const formData = new FormData();
    if (data != null) {
      formData.append("time_table", data.timeTable, data.timeTable.name);
    }
    const url =
      data?.routeType === "Class"
        ? `${GET_CLASS_DETAILS_API}${data.classId}/`
        : `${GET_GROUP_DETAILS_API}${data.classId}/`;

    const response = await axios.put(url, formData, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data.status === "1"
    ) {
      onSuccess(response.data.data);
      store.dispatch({
        type: PUT_TIME_TABLE,
        payload: response.data.data.timeTable,
      });
      store.dispatch(getClasses());
    }
  } catch (error) {
    onError(error);
    store.dispatch(handleErrorMessage(errorMessageFromResponse(error)));
    return error;
  }
};

export const inviteViaEmail = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: INVITE_VIA_EMAIL,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(handleSuccessMessage("Invite Sent!"));
          dispatch(getClasses());
          dispatch(getGroups());
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
export const postMembers = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: POST_MEMBERS_DETAILS_API,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          //check if class or group and then call
          dispatch(handleSuccessMessage("Member added successfully!"));
          dispatch(getClasses());
          dispatch(getGroups());
          dispatch({
            type: POST_MEMBERS_DETAILS,
            payload: response.data.status,
          });
        }
      })
      .catch(async (error) => {
        // const errorMsg = error?.response?.data?.error?.detail || error?.response?.data?.error || "Network Error";
        console.log('error aara')
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const deleteMemberAction = (profileId, classId, memberId) => {
  return async (dispatch) => {
    const deleteMemberApiUrl = `${DELETE_MEMBER_API}${profileId}/`;
    const token = store.getState()?.profile?.user?.token;
    const groupOrClass = window.location.pathname.split("/")[1];
    let data = {
      class_members: classId,
    };
    if (groupOrClass == "group") {
      data = {
        group_members: classId,
      };
    }

    await axios({
      method: "DELETE",
      url: deleteMemberApiUrl,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        let memberData = store.getState()?.classes?.members;
        const index = memberData?.findIndex(function (member) {
          return member.id === memberId;
        });
        if (index !== -1) memberData.splice(index, 1);

        dispatch({ type: DELETE_MEMBER, payload: memberData });
        dispatch(handleSuccessMessage("Member deleted successfully"));
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
export const joinClassByCode = (data) => {
  const token = store.getState()?.profile?.user?.token;
  let member_users = store.getState()?.profile?.user?.profile_id;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_CLASS_ID_BY_CODE}?class_code=${data.class_code}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.length == 0) {
            const errorMsg = "No Such Class Found";
            dispatch(handleErrorMessage(errorMsg));
          } else {
            let memberData = [
              {
                member_users: member_users,
                privilige: "",
                class_members: response.data.data.id,
              },
            ];
            dispatch(postMembers(memberData));
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const changeActiveSection = (sectionName) => {
  return {
    type: CHANGE_CLASS_ACTIVE_SECTION,
    payload: sectionName,
  };
};

export const getPosts = (classId) => {
  return async (dispatch) => {
    const token = store.getState()?.profile?.user?.token;
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/class/${classId}/`,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          const data = response.data.data.filter((obj) =>
            obj.post_type.includes("CLASS")
          );
          let posts = data.map((posts) => posts);

          dispatch({ type: GET_CLASS_POST, payload: posts });
          return posts;
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };
};

// ------------

export const getClassOwners = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_CLASSES_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const classOwnerCodes = response.data.data.map(
          (classData) => classData.classes.class_owner
        );
        dispatch({ type: GET_ALL_CLASS_OWNER_CODES, payload: classOwnerCodes });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};