import React, { useState, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import IconTitle from "../../components/commons/IconTitle";
import styles from "./css/CreateAssignment.module.css";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import SaveButton from "../../components/commons/SaveButton";
import AssignedToModal from "./Modals/AssignedToModal";
import Modal from "../../components/commons/Modal";
import DiscardDeleteAssignmentModal from "./Modals/DiscardDeleteAssignmentModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  getClassDetails,
  handleErrorMessage,
  changeActiveSection,
} from "../../redux/actions/classes";
import moment from "moment";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";
import { CircularProgress } from "@mui/material";

import {
  createAssignment,
  updateAssignment,
} from "../../redux/actions/assignment";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";
import { createQuiz, updateQuiz } from "../../redux/actions/quiz";
import LinkModal from "./Modals/LinkModal";
import { isValidUrl } from "../../redux/constants/commonFunctions";
import { getGroupDetails } from "../../redux/actions/groups";
// import { useSelect } from "@mui/base";

const CreateUpdateAssignmentFormComponent = (props) => {
  // props - assignmentType, actionType

  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const routeType = location?.state?.routeType;
  const class_name = useSelector((state) =>routeType=='class' ? state?.classes?.class?.name : state?.groups?.group?.name);
  const isIdValid = useSelector((state)=>state?.errors?.statusCode === 400 ? false : true);
  const dispatch = useDispatch();
  const classLoggedIn = [useSelector(state => state?.classes?.class)];

  useEffect(() => {
    // console.log(routeType, 'routeType')
      if (routeType == 'group'){
        dispatch(getGroupDetails(params.id)).then(()=>{setIsLoading(false)});
      }else{
        dispatch(getClassDetails(params.id)).then(()=>{setIsLoading(false)});
      }
  }, []);

  const actionType = location?.state?.actionType;
  const assignment = location?.state?.assignment;
  const isOwner =  location?.state?.isOwner ?? false;
  const showFourZeroFour = !isOwner || !isIdValid;
  
  const [assignedToModalOpen, setAssignedToModalOpen] = useState(false);
  const [discardAssignmentModalOpen, setDiscardAssignmentModalOpen] =
    useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [linkVal, setLinkVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const assignedToModalClose = () => {
    setAssignedToModalOpen(false);
  };
  const discardAssignmentModalClose = () => {
    setDiscardAssignmentModalOpen(false);
  };
  const changeLink = (e) => {
    setLinkVal(e.target.value);
  };
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  //gathering data

  const [assignmentTitle, setAssignmentTitle] = useState(
    actionType === "edit" ? assignment?.title : ""
  );
  const [assignmentInstructions, setAssignmentInstructions] = useState(
    actionType === "edit" ? assignment?.details : ""
  );
  const [maxMarks, setMaxMarks] = useState(
    actionType === "edit" ? assignment?.max_marks : ""
  );
  const [dueDate, setDueDate] = useState(
    actionType === "edit" ? assignment?.due_date : ""
  );
  const [dueTime, setDueTime] = useState(
    actionType === "edit" ? assignment?.timestamp : ""
  );
  const [dateTime, setDateTime] = useState(
    actionType === "edit"
      ? assignment?.due_date
      : moment(Date()).format("YYYY-MM-DDTHH:mm:ssZ")
  );
  const [isLock, setIsLock] = useState(
    actionType === "edit" ? assignment?.is_lock : false
  );
  const [assignmentUrl, setAssignmentUrl] = useState(
    actionType === "edit" ? assignment?.url : null
  );
  const [assignmentFile, setAssignmentFile] = useState(null);
  const [assignmentFileUrl, setAssignmentFileUrl] = useState(
    actionType === "edit" ? assignment?.file : null
  );
  const [assignedToStatus, setAssignedToStatus] = useState("");

  const maxMarkCheck = (mm) => {
    switch (mm) {
      case null:
      case undefined:
      case "":
        dispatch(handleErrorMessage(t("errorMessageTranslations.enterValidNumber")));
        setMaxMarks(mm);
        return false;
      default:
        if (!isNaN(mm)) {
          if (mm <= 0){
            dispatch(handleErrorMessage(t("errorMessageTranslations.marksCannotBeNegative")));
            setMaxMarks(mm);
            return false;
          } else if (mm > 2000000000) {
            dispatch(handleErrorMessage(t("errorMessageTranslations.maxMarksCannotBeGreaterThan2B")));
            setMaxMarks(mm);
            return false;
          } else {
            setMaxMarks(mm);
            return true;            
          }
        }     
  };

  }

  const getMaxMarks = (mm) => {
  maxMarkCheck(mm);
}

  const getDueDate = (dd, dt) => {
    setDueDate(moment(dd).format("YYYY-MM-DDT"));
    setDueTime(moment(dt).format("HH:mm:ssZ"));
    setDateTime(
      `${moment(dd).format("YYYY-MM-DDT")}${moment(dt).format("HH:mm:ssZ")}`
    );
  };

  const getLockStatus = (lc) => {
    setIsLock(lc);
  };

  const getAssignToStatus = (value) => {
    setAssignedToStatus(value);
  }

  const submitLink = () => {
    if (isValidUrl(linkVal)) {
      setAssignmentUrl(linkVal);
      setLinkModalOpen(false);
    } else {
      dispatch(handleErrorMessage(t("errorMessageTranslations.validUrl")));
      setLinkVal("");
    }
  };

  // const keyDownHandler = (e) => {
  //   if(e.key === "Enter"){
  //     submitLink();
  //   }    
  // }

  const submitCreateAssignmentForm = () => {
    const assignmentData = {
      title: assignmentTitle,
      details: assignmentInstructions,
      max_marks: maxMarks,
      due_date: dateTime,
      is_lock: isLock,
      classes: params.id,
      url: assignmentUrl,
      file: assignmentFile,
      assign_all: assignedToStatus,
    };

    if (actionType === "edit") {
      assignmentData.id = assignment?.id;
      assignmentData.assign_all = null; // to skip showing the requiredField prompt when editing an assignment
    }

    if (
      assignmentData.title.trim() == "" ||
      assignmentData.max_marks == "" ||
      assignmentData.due_date == "" ||
      assignmentData.assign_all == ""
    ) {
      dispatch(handleErrorMessage(t("errorMessageTranslations.requiredFields")));
    } else if (maxMarkCheck(assignmentData.max_marks)) {    
        if (actionType === "edit") {
            setIsLoadingBtn(true)
            dispatch(updateAssignment(assignmentData, routeType )).then(()=>{
              setIsLoadingBtn(false)
              history.push({
                pathname: `/${routeType}/${params.id}`,
                state: { activeSection: "Assignments" },
              });
            });
      
        } else {
          if (
            assignmentData.due_date <
            moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
          ) {
            dispatch(handleErrorMessage(t("errorMessageTranslations.dueDateGreaterThanCurrentDate")));
          }
          else{
            setIsLoadingBtn(true)
            dispatch(createAssignment(assignmentData, routeType)).then(()=>{
              setIsLoadingBtn(false)
              history.push({
                pathname: `/${routeType}/${params.id}`,
                state: { activeSection: "Assignments" },
              });
            });
          }
      }
    }
  };

  const submitCreateQuizForm = () => {
    const quizData = {
      title: assignmentTitle,
      details: assignmentInstructions,
      max_marks: maxMarks,
      due_date: dateTime,
      classes: params.id,
      is_lock: isLock,
      url: assignmentUrl,
      // file: assignmentFile
      // assign_all: assignedToStatus,
    };

    if (actionType === "edit") {
      quizData.id = assignment?.id;
    }
    // console.log("quizData", quizData);
    if (
      quizData.title.trim() == "" ||
      quizData.max_marks == "" ||
      quizData.due_date == "" 
      // quizData.due_date == "" ||
      // quizData.assign_all == ""
    ) {
      dispatch(handleErrorMessage(t("errorMessageTranslations.requiredFields")));
    } else if (maxMarkCheck(quizData.max_marks)) {
         if (actionType === "edit") {
            setIsLoadingBtn(true)
            dispatch(updateQuiz(quizData,routeType)).then(()=>{
              setIsLoadingBtn(false)
              history.push({
                pathname: `/${routeType}/${params.id}`,
                state: { activeSection: "Assignments" },
              });
            });
            
        } else {
          if (
            quizData.due_date < moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
          ) {
            dispatch(handleErrorMessage(t("errorMessageTranslations.dueDateGreaterThanCurrentDate")));
          } 
          else{
            setIsLoadingBtn(true)
            dispatch(createQuiz(quizData, routeType)).then(()=>{
              setIsLoadingBtn(false)
              history.push({
                pathname: `/${routeType}/${params.id}`,
                state: { activeSection: "Assignments" },
              });
            });
            
          } 
      }
    }
  };

  const discardFunction = () => {
    setAssignmentTitle(null);
    setAssignmentInstructions(null);
    setMaxMarks(null);
    setDateTime(null);
    setIsLock(null);
    setAssignmentUrl(null);
    setAssignmentFile(null);
    setAssignmentFileUrl(null);
    history.push({
      pathname: `/${routeType}/${params.id}`,
      state: { activeSection: "Assignments" },
    });
  };

  const removeSubmittedFile = (filetype) => {
    if(filetype=="FILE"){
      if(assignmentFile!=null || assignmentFileUrl!=null){
        setAssignmentFile(null);
        setAssignmentFileUrl(null);
      }
     
    }
    else{
      if(assignmentUrl!=null){
        setAssignmentUrl(null);
      }
     
    }
  };

  if (props.assignmentType === "QUIZ") {
    return (
      <>
        { isLoading ? (
          <div className={styles.loader_style}>
            <CircularProgress sx={{color: "#00d498"}} size={50} />
          </div>
        ) : showFourZeroFour ? <ErrorFourZeroFour/> :
          <div className={styles.main_container}>
          <LinkModal
            show={linkModalOpen}
            shareModalCloser={() => setLinkModalOpen(false)}
            linkVal={linkVal}
            changeLink={changeLink}
            submitLink={submitLink}
          />
  
          {assignedToModalOpen ? (
            <Modal
              card={
                <AssignedToModal
                  assignmentClass={classLoggedIn}
                  assignmentType="QUIZ"
                  changeEdit={assignedToModalClose}
                  submitForm={submitCreateQuizForm}
                  getDueDate={getDueDate}
                  getMaxMarks={getMaxMarks}
                  lockStatus={getLockStatus}
                  actionType={actionType}
                  assignment={assignment}
                  isLoadingBtn={isLoadingBtn}
                  getAssignToStatus={getAssignToStatus}
                />
              }
            />
          ) : null}
          {discardAssignmentModalOpen ? (
            <Modal
              card={
                <DiscardDeleteAssignmentModal
                  modalMessage={t("quiz.quizDiscardMessage")}
                  modalLabel={t("common.discard")}
                  assignmentType="QUIZ"
                  changeEdit={discardAssignmentModalClose}
                  discardFunction={discardFunction}
                />
              }
            />
          ) : null}
          <h5
            className={styles.back_btn}
            onClick={() => setDiscardAssignmentModalOpen(true)}
          >
            <img src="/Image/assignment/back_arrow.svg" alt="" />
            &nbsp; &nbsp;{class_name}
          </h5>
          <div className={styles.feed_container}>
            <div className={styles.title_container}>
              <IconTitle
                title={t("quiz.quiz")}
                img="/Image/assignment/White/Quiz.svg"
                notClickable={true}
                type="ASSIGNMENT"
              />
              <Button
                type="solid"
                // label={t("assignment.assign")}
                label={
                  actionType === "edit"
                    ? t("assignment.save")
                    : t("assignment.assign")
                }
                clickHandler={() => {
                  setAssignedToModalOpen(true);
                }}
              />
            </div>
            <div className={styles.form_container}>
            <div className={styles.form}>
              <p className={styles.input_label} for="quizTitle">
                <div className={styles.input_title_container}>
                  <img
                    className={styles.lable_icon}
                    src="/Image/assignment/Title.svg"
                  />
                  {t("quiz.quizTitle")}*
                </div>
              </p>
              <input
                type="text"
                className={styles.input_field}
                placeholder={t("assignment.assignmentTitlePlaceholder")}
                value={assignmentTitle}
                onChange={(e) => {
                  setAssignmentTitle(e.target.value);
                }}
              />
              <h3 for="quizInstructions" className={styles.input_label}>
                <div className={styles.input_title_container}>
                  <img
                    className={styles.lable_icon}
                    src="/Image/assignment/Instructions.svg"
                  />
                  {t("assignment.instructions")}
                </div>
              </h3>
              <textarea
                type="text"
                className={styles.input_field1}
                placeholder={t("assignment.instructionsPlaceholder")}
                value={assignmentInstructions}
                onChange={(e) => {
                  setAssignmentInstructions(e.target.value);
                }}
              ></textarea>
              <h3 for="QuizAttachment" className={styles.input_label}>
                <div className={styles.input_title_container}>
                  <img
                    alt=""
                    className={styles.lable_icon}
                    src="/Image/assignment/Add attachment.svg"
                  />
                  {t("quiz.addQuiz")}
                </div>
              </h3>
              <div className={styles.attachment_icon_container}>
                <div className={styles.uploadBox}>
                  <img
                    src="/Image/assignment/Add link.svg"
                    alt="Photos"
                    className={styles.photo}
                    onClick={() => setLinkModalOpen(true)}
                  />
                </div>
              </div>
              <br />
  
              {assignmentUrl != null ? (
                <AttachmentContainer
                  assignmentFileType="LINK"
                  linkName={assignmentUrl?.split("/")?.pop()?.substr(0, 8)}
                  link={assignmentUrl}
                  pageType="Submission"
                  closeBtn={true}
                  removeSubmittedFile = {removeSubmittedFile}
                />
              ) : null}
            </div>
            </div>
          </div>
        </div>
        }
      </>
    )      
  } else {
    return (
        <>
          {/* {console.log("TheLog",classLoggedIn)} */}
          { isLoading ? (
            <div className={styles.loader_style}>
              <CircularProgress sx={{color: "#00d498"}} size={50} />
            </div>
            ) : showFourZeroFour ? <ErrorFourZeroFour/> :
            <div className={styles.main_container}>
              <LinkModal
                show={linkModalOpen}
                shareModalCloser={() => setLinkModalOpen(false)}
                linkVal={linkVal}
                changeLink={changeLink}
                submitLink={submitLink}
              />
              {assignedToModalOpen ? (
                <Modal
                  card={
                    <AssignedToModal
                      assignmentClass={classLoggedIn}
                      assignmentType="ASSIGNMENT"
                      changeEdit={assignedToModalClose}
                      getDueDate={getDueDate}
                      getMaxMarks={getMaxMarks}
                      submitForm={submitCreateAssignmentForm}
                      lockStatus={getLockStatus}
                      actionType={actionType}
                      assignment={assignment}
                      isLoadingBtn={isLoadingBtn}
                      getAssignToStatus={getAssignToStatus}
                    />
                  }
                />
              ) : null}
              {discardAssignmentModalOpen ? (
                <Modal
                  card={
                    <DiscardDeleteAssignmentModal
                      modalMessage={t("assignment.assignmentDiscardMessage")}
                      modalLabel={t("common.discard")}
                      assignmentType="ASSIGNMENT"
                      changeEdit={discardAssignmentModalClose}
                      discardFunction={discardFunction}
                    />
                  }
                />
              ) : null}
              <h5
                className={styles.back_btn}
                onClick={() => setDiscardAssignmentModalOpen(true)}
              >
                <img src="/Image/assignment/back_arrow.svg" />
                &nbsp; &nbsp;{class_name}
              </h5>
              <div className={styles.feed_container}>
                <div className={styles.title_container}>
                  <IconTitle
                    title={t("assignment.assignment")}
                    img="/Image/assignment/White/Assignment.svg"
                    notClickable={true}
                    type="ASSIGNMENT"
                  />
                  <Button
                    type="solid"
                    // label={t("assignment.assign")}
                    label={
                      actionType === "edit"
                        ? t("assignment.save")
                        : t("assignment.assign")
                    }
                    clickHandler={() => {
                      setAssignedToModalOpen(true);
                    }}
                  />
                </div>
                <div className={styles.form_container}>
                  <div className={styles.form}>
                  <h3 className={styles.input_label} for="assignmentTitle">
                    <div className={styles.input_title_container}>
                      <img
                        className={styles.lable_icon}
                        src="/Image/assignment/Title.svg"
                      />
                      {t("assignment.assignmentTitle")}*
                    </div>
                  </h3>
                  <input
                    type="text"
                    className={styles.input_field}
                    placeholder={t("assignment.assignmentTitlePlaceholder")}
                    // value={assignmentTitle}
                    // onChange={(e) => {
                    //   setClassName(e.target.value);
                    //   props.onClassNameChange(e);
                    // }}
      
                    value={assignmentTitle}
                    onChange={(e) => {
                      setAssignmentTitle(e.target.value);
                    }}
                  />
                  <h3 for="instructions" className={styles.input_label}>
                    <div className={styles.input_title_container}>
                      <img
                        className={styles.lable_icon}
                        src="/Image/assignment/Instructions.svg"
                      />
                      {t("assignment.instructions")}
                    </div>
                  </h3>
                  <textarea
                    className={styles.input_field1}
                    placeholder={t("assignment.instructionsPlaceholder")}
                    // onChange={(e) => {
                    //   setClassDescription(e.target.value);
                    //   props.onClassDescriptionChange(e);
                    // }}
      
                    value={assignmentInstructions}
                    onChange={(e) => {
                      setAssignmentInstructions(e.target.value);
                    }}
                  >
                    {/* {classDescription} */}
                  </textarea>
                  <h3 for="fileAttachment" className={styles.input_label}>
                    <div className={styles.input_title_container}>
                      <img
                        className={styles.lable_icon}
                        src="/Image/assignment/Add attachment.svg"
                      />
                      {t("assignment.addAttachments")}
                    </div>
                  </h3>
                  <div className={styles.attachment_icon_container}>
                    {/* <img src = "./Image/dropArrow.svg"></img> */}
                    <div className={styles.uploadBox}>
                      <input
                        onChange={(e) => {
                          setAssignmentFile(e.target.files[0]);
                          setAssignmentFileUrl(
                            e.target.files[0]
                          );
                        }}
                        type="file"
                        accept=".doc,.docx,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.txt,image/*"
                        className={styles.fileUpload}
                      />
                      <img
                        src="/Image/assignment/Add files.svg"
                        alt="Photos"
                        className={styles.photo}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div
                      className={styles.uploadBox}
                      onClick={() => setLinkModalOpen(true)}
                    >
                      <img
                        src="/Image/assignment/Add link.svg"
                        alt="Photos"
                        className={styles.photo}
                      />
                    </div>
                  </div>
                  {assignmentFile || assignmentFileUrl != null ? (
                    <>
                      <br />
                      <AttachmentContainer
                        assignmentFileType="FILE"
                        fileName={
                          assignmentFile?.name ||
                          assignmentFileUrl?.split("/")?.pop()?.substr()
                        }
                        file={assignmentFileUrl}
                        pageType="Submission"
                        closeBtn={true}
                        removeSubmittedFile = {removeSubmittedFile}
                      />
                    </>
                  ) : null}
                  {assignmentUrl != null ? (
                    <>
                      <br />
                      <AttachmentContainer
                        assignmentFileType="LINK"
                        linkName={assignmentUrl?.split("/")?.pop()?.substr()}
                        link={assignmentUrl}
                        pageType="Submission"
                        removeSubmittedFile = {removeSubmittedFile}
                        closeBtn={true}
                      />
                    </>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      
    );
  }
};

export default CreateUpdateAssignmentFormComponent;

// function mapDispatchToProps(dispatch) {
  // return {
    // createAssignment: (data,type) => dispatch(createAssignment(data,type)),
    // updateAssignment: (data,type) => dispatch(updateAssignment(data,type)),
    // getClassDetails: (id) => dispatch(getClassDetails(id)),
    // createQuiz: (data,type) => dispatch(createQuiz(data,type)),
    // handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    // updateQuiz: (data,type) => dispatch(updateQuiz(data,type)),
    // changeActiveSection: (activeSection) => dispatch(changeActiveSection(activeSection)),
    // getGroupDetails: (id) => dispatch(getGroupDetails(id)),
  // };
// }

// const mapStateToProps = (state) => {
//   return {
//     isIdValid: state?.errors?.statusCode === 400 ? false : true,
//   };
// };

// export default withTranslation()(
  
// );


// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CreateUpdateAssignmentFormComponent)
