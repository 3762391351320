import React from "react";
import { connect } from "react-redux";

import styles from "../../../Profile.module.css";

class SocailFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  render() {
    return (
      <div className={styles.desc}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 8,
          }}
        >
          <a
            href={this.props.facebook || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.facebook ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/fb.svg"
              alt="facebook"
              style={{ width: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.linkedin || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.linkedin ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/twitter.svg"
              alt="Twitter"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.twitter || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.twitter ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/instagram.png"
              alt="Instagram"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.instagaram || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.instagaram ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/linkedIn.svg"
              alt="Linkedin"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.medium || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.medium ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/website.svg"
              alt="website"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    facebook: state.profile?.socialLinksView?.facebook || "",
    linkedin: state.profile?.socialLinksView?.linkedin || "",
    twitter: state.profile?.socialLinksView?.twitter || "",
    instagaram: state.profile?.socialLinksView?.instagaram || "",
    medium: state.profile?.socialLinksView?.medium || "",
  };
}
export default connect(mapStateToProps, null)(SocailFeed);
