import { store } from "../../../../redux/store";

// const token = store.getState()?.profile?.user?.token;
// window.location.href.replace("template/1","")

export default {
  fname: store.getState()?.profile?.nameView.split(" ")[0] || " ",
  lname: store.getState()?.profile?.nameView.split(" ")[1] || "",
  phone: store.getState()?.profile?.phoneView || "",
  website: window.location.href.replace("template/1", "") || "www.chalkmates.com",
  profession: store.getState()?.profile?.designationView || "Director",
  dob: store.getState()?.profile?.dobView || "1964-07-12",
  nationality: store.getState()?.profile?.addressView || "",
  residencecity: store.getState()?.profile?.locationView || "",
  residencestate: "No information available",
  email: store.getState()?.profile?.user_email || "",
  dp: store.getState()?.profile?.userProfilePicView || "/Image/apps/profile-resume-edit-view/dpic.png",
  about: store.getState()?.profile?.aboutView || "No data found.",
  // languages: [
  //   {
  //     lang: "English",
  //     progress: 90,
  //     color: "#6564DB",
  //   },
  //   {
  //     lang: "Hindi",
  //     progress: 10,
  //     color: "#23E6E8",
  //   },
  //   {
  //     lang: "Punjabi",
  //     progress: 40,
  //     color: "#FFAF02",
  //   },
  //   {
  //     lang: "Bengali",
  //     progress: 70,
  //     color: "#236AE8",
  //   },
  //   {
  //     lang: "Catonize",
  //     progress: 40,
  //     color: "#fd0275",
  //   },
  // ],
  languages: store.getState()?.profile?.language_knownView.split(","),
  interests: store.getState()?.profile?.interestsView || [],
  skillsView: store.getState()?.profile?.skillsView || [],
  // interests: {
  //   labels: ["Music", "Reading", "Swimming", "Fishing"],
  //   datasets: [
  //     {
  //       // label: '# of Votes',
  //       data: [12, 19, 3, 5],
  //       backgroundColor: ["#236AE8", "#6564DB", "#51D8DE", "#FFAF02"],
  //     },
  //   ],
  // },
  // interests: {

  skillsDp: [
    { name: "dummy", val: 12 },
    { name: "Reading", val: 12 },
    { name: "Swimming", val: 12 },
    { name: "Fishing", val: 12 },
  ],

  skills: {
    music: {
      labels: ["Music", "Music"],
      datasets: [
        {
          data: [15, 10],
          backgroundColor: ["#236AE8", "#E5E7E7"],
        },
      ],
    },
    art: {
      labels: ["Art", "Art"],
      datasets: [
        {
          data: [15, 10],

          backgroundColor: ["#236AE8", "#E5E7E7"],
        },
      ],
    },
    teaching: {
      labels: ["Teaching", "Teaching"],
      datasets: [
        {
          data: [15, 10],
          backgroundColor: ["#236AE8", "#E5E7E7"],
        },
      ],
    },
    outdoor: {
      labels: ["Outdoor", "Outdoor"],
      datasets: [
        {
          data: [15, 10],
          backgroundColor: ["#236AE8", "#E5E7E7"],
        },
      ],
    },
    sports: {
      labels: ["Sports", "Sports"],
      datasets: [
        {
          data: [15, 10],
          backgroundColor: ["#236AE8", "#E5E7E7"],
        },
      ],
    },
    adventure: {
      labels: ["Adventure", "Adventure"],
      datasets: [
        {
          data: [15, 10],
          backgroundColor: ["#236AE8", "#E5E7E7"],
        },
      ],
    },
  },
  educationWork: store.getState()?.profile?.educationView || [],
  experience: store.getState()?.profile?.experienceView || [],
  // educationWork: [
  //   {
  //     time: "Jul’13 2020",
  //     title: "People you may know",
  //     description: "Director, Business Head, Education sector under Regent Group.",
  //     color: "#23E6E8",
  //   },
  //   {
  //     time: "Jul’13 2020",
  //     title: "People you may know",
  //     description: "Director, Business Head, Education sector under Regent Group.",
  //     color: "#0C1B7A",
  //   },
  //   {
  //     time: "Jul’13 2020",
  //     title: "People you may know",
  //     description: "Director, Business Head, Education sector under Regent Group.",
  //     color: "#236AE8",
  //   },
  //   {
  //     time: "Jul’13 2020",
  //     title: "People you may know",
  //     description: "Director, Business Head, Education sector under Regent Group.",
  //     color: "#FFAF02",
  //   },
  //   {
  //     time: "Jul’13 2020",
  //     title: "People you may know",
  //     description: "Director, Business Head, Education sector under Regent Group.",
  //     color: "#6564DB",
  //   },
  // ],
};
