import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getPosts } from "../../../redux/actions/dashboard";
import { getGroups } from "../../../redux/actions/groups";
import { getGroupDetails, validMember } from "../../../redux/actions/groups";
import { joinGroup } from "../../../redux/actions/groups";
import styles from "./CSSFiles/SidebarGroup.module.css";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { store } from "../../../redux/store.js";
import { Box, Button, Stack } from "@mui/material";
import JoinGroupModal from "./JoinGroupModal";
import JoinPublicGroupModal from "./JoinPublicGroupModal";
import AddIcon from "@mui/icons-material/Add";
class SidebarGroup extends Component {
  state = {
    showShareJoinClassModal: false,
    showShareJoinGroupModal: false,
    joinGroupCode: "",
    joinClassCode: "",
    group_id: "",
    group_name: "",
    showJoinButton: true,
  };
  shareModalOpener = () => {
    this.setState({ showShareJoinGroupModal: true });
  };
  shareJoinGroupModalCloser = () => {
    this.setState({ showShareJoinGroupModal: false });
  };

  // memberCheck = (id) => {
  //   this.props.getGroupDetails(id).then(() => {
  //     const stateCheck = store.getState();
  //     const owner = store.getState()?.groups?.group?.group_owner;
  //     const arr = stateCheck?.groups?.members;
  //     const ifMember = arr.map((item) => {
  //       if (item.profileId == owner) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //     // console.log('line44', ifMember)
  //     if (ifMember.includes(true)) {
  //       // let { history } = this.props;
  //       // history.push({
  //       //   pathname: `/group/${id}`,
  //       //   state: { subjectId: id },
  //       // });
  //       this.shareModalOpener()
  //     } else this.shareModalOpener();
  //   });
  // };

  validMemberCheck = (id) => {
    const onSuccess = (response) => {
      if (response) {
        let { history } = this.props;
        history.push({
          pathname: `/group/${id}`,
          state: { subjectId: id },
        });
      }
    };
    const onError = (error) => {
      if (error.response.status === 403) {
        this.shareModalOpener();
      }
    };
    validMember(id, onSuccess, onError);
  };

  componentDidMount = () => {
    this.props.getGroups();
    // this.props.getGroupDetails();
    // this.props.joinGroup();
    // this.props.getPosts();
  };
  getSubjectDetails = (subjectId) => {
    let { history } = this.props;
    history.push({
      pathname: `/group/${subjectId}`,
      state: { subjectId: subjectId },
    });
  };

  sortfunction = (myGroup) => {
    myGroup.sort(function (a, b) {
      return b.id - a.id;
    });
  };

  join = (id) => {
    this.props.joinGroup({ group_id: id }).then((res) => console.log(res));
    let { history } = this.props;
    history.push({
      pathname: `/group/${id}`,
      state: { subjectId: id },
    });
  };

  render() {
    const owner = store.getState()?.profile?.user?.profile_id;
    let count = 0;
    return (
      <div>
        <JoinPublicGroupModal
          show={this.state.showShareJoinGroupModal}
          shareModalCloser={this.shareJoinGroupModalCloser}
          group_id={this.state.group_id}
          group_name={this.state.group_name}
          joinPublicGroup={this.join}
        />
        {this.sortfunction(this.props.myGroups)}
        {this.props.myGroups.length != 0 ? (
          this.props.myGroups.map((data, index) => {
            if (data.group_owner == owner || data.group_type == "PUBLIC") {
              count++;
              if (count <= 3) {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: "0px 15px",
                    }}
                  >
                    <div
                      key={index}
                      className={styles.sidebarClass}
                      onClick={() => {
                        this.setState({
                          group_id: data.id,
                          group_name: data.name,
                        });
                        // this.memberCheck(data.id);
                      }}
                    >
                      <div className={styles.classImage}>
                        <img
                          className={styles.classimg}
                          src={
                            data.coverImg || index % 2 === 0
                              ? "/Image/DashImage/RightSidebar/group_1.png"
                              : "/Image/DashImage/RightSidebar/group_2.png"
                          }
                        />
                      </div>
                      <div className={styles.classPara}>
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          {data.name}
                        </p>
                        <div
                          style={{
                            textAlign: "left",
                            padding: "2px 0 0 0",
                            fontSize: "14px",
                            fontWeight: "300",
                          }}
                        >
                          {data.description.length > 20
                            ? data.description.slice(0, 20) + "..."
                            : data.description}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            padding: "2px 0 0 0",
                            fontSize: "14px",
                            fontWeight: "300",
                          }}
                        >
                          {data.group_owner_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        this.setState({
                          group_id: data.id,
                          group_name: data.name,
                        });
                        this.validMemberCheck(data.id);
                      }}
                      disableRipple
                      size="small"
                      // startIcon={<AddIcon fontSize="small" />}
                      sx={{
                        textTransform: "capitalize",
                        background: "var(--optional-gradient)",
                        borderRadius: "20px",
                        color: "#fff",
                        p: "3px 12px",
                        ":hover": {
                          background: "var(--optional-gradient)",
                        },
                        "& .MuiButton-startIcon": { marginRight: "2px" },
                      }}
                    >
                      {this.props.t("common.join")}
                    </Button>
                  </Box>
                );
              }
            }
          })
        ) : (
          <div className={styles.classText}>
            {this.props.t("dashboard.noGroupSidebar")}
          </div>
        )}
        {/* {this.props.myGroups.length > 3 ? ( */}
        {count > 3 ? (
          <div className={styles.sidebar_stat1}>
            <Link
              to="/groups"
              style={{
                textDecoration: "none",
                color: "#396dd4",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {this.props.t("dashboard.allGroups")}
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    myGroups:
      state?.groups?.groups?.map((each_group) => {
        return {
          name: each_group?.name,
          id: each_group?.id,
          coverImg: each_group?.cover_image,
          description: each_group?.description,
          group_owner: each_group?.group_owner,
          group_type: each_group?.group_type,
          grade: each_group?.grade,
        };
      }) || [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGroups: () => dispatch(getGroups()),
    getGroupDetails: (data) => dispatch(getGroupDetails(data)),
    joinGroup: (data) => dispatch(joinGroup(data)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarGroup))
);
