import React from 'react'
import styles from './css/FileTypeDropDown.module.css'
import { withTranslation, useTranslation } from "react-i18next";

class FileTypeDropDown extends React.Component{

    constructor(props){
        super(props);
    }
    render(){
        return (
            <div className={styles.drop_down_menu}>
                <div className={styles.drop_down_item} onClick={() => this.props.openLinkModal()}>
                    <img className={styles.drop_down_item_icon} src="/Image/assignment/AddLink.png" alt="addLink"/>
                    <p className={styles.drop_down_item_name}>{this.props.t("common.link")}</p>
                </div>
                <div className={styles.drop_down_item}>
                <input onChange={(e) => this.props.handleSubmissionFileUpload(e)}type="file" accept=".doc,.docx,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" className={styles.fileUpload} />
                    <img className={styles.drop_down_item_icon} src="/Image/assignment/AddFiles.png" alt="addLink"/>
                    <p className={styles.drop_down_item_name}>{this.props.t("common.file")}</p>
                </div>
                <hr />
        
                <div className={styles.drop_down_item} onClick={() => this.props.openLinkModal()}>
                    <img className={styles.drop_down_item_icon} src="/Image/assignment/Quiz.png" alt="addLink"/>
                    <p className={styles.drop_down_item_name}>Google Docs</p>
                </div>
                <div className={styles.drop_down_item} onClick={() => this.props.openLinkModal()}>
                    <img className={styles.drop_down_item_icon} src="/Image/assignment/Assignment.png" alt="addLink"/>
                    <p className={styles.drop_down_item_name}>Google Sheet</p>
                </div>
                <div className={styles.drop_down_item} onClick={() => this.props.openLinkModal()}>
                    <img className={styles.drop_down_item_icon} src="/Image/assignment/Assignment.png" alt="addLink"/>
                    <p className={styles.drop_down_item_name}>Google Slides</p>
                </div>
            </div>
          )
    }
 
}

export default withTranslation()(FileTypeDropDown)
