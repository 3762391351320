import React from "react";
import CorouselFeed from "../../../../../components/commons/CorouselFeed";
import PeopleYouMayKnow from "../../../../../components/commons/PeopleYouMayKnowFeed";
import UpskillingFeed from "../../../../../components/commons/UpskillingFeed";
import styles from "../../../Profile.module.css";

export default class RightFeed extends React.Component {
  corouselContent = [
    {
      img: "/Image/adds/ad1.jpg",
      // href: "https://www.shardauniversity.uz/",
    },

    {
      img: "/Image/adds/ad2.webp",
      // href: "https://www.khanacademy.org/",
    },

    {
      img: "/Image/adds/ad3.jpg",
      // href: "https://www.coursera.org/in",
    },

    {
      img: "/Image/adds/ad4.png",
      // href: "http://skills.sharda.ac.in/",
    },
  ];

  render() {
    return (
      <div>
        <div className={styles.rightSide}>
          <CorouselFeed corouselContent={this.corouselContent} />

          <br />

          <PeopleYouMayKnow hamburgerDisplay={this.props?.hamburgerDisplay} />

          <br />
          <br />

          {/* <UpskillingFeed />
          <br />
          <br /> */}
        </div>
      </div>
    );
  }
}
