import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./css/MiddleContain.module.css";
import { Avatar, Button } from "@mui/material";
import PublicPrivateToggle from "../../../components/commons/PublicPrivateToggle";

function MiddleContain(props) {
  const [groupName, setGroupName] = useState(
    props.actionType == "EDIT" ? props.editData?.name : ""
  );
  const [groupDescription, setGroupDescription] = useState(
    props.actionType == "EDIT" ? props.editData?.description : ""
  );
  const [groupType, setGroupType] = useState(
    props.actionType == "EDIT" ? props.editData?.group_type : false
  );

  const { t } = useTranslation();

  const handleChangeForSwitch = (e) => {
    const { value } = e.target;
    setGroupType(value);
    props.onGroupTypeChange(e);
  };

  const properButtonStyles = {
    textTransform: "none",
    color: "#fff",
    borderRadius: "10px",
    px: 1.5,
    "& .MuiButton-startIcon": { marginRight: "7px" },
    minWidth: "0px",
    height: "45px",
    paddingRight: { xs: "4px", sm: "12px" },
    py: { xs: 1, sm: 0.5 },
  };

  return (
    <div className={styles.classModal}>
      {props.actionType == "EDIT" || props.actionType == "ADD" ? (
        <form>
          <h3 className={styles.inputLabel} for="classname">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                font: "normal normal 500 20px Montserrat",
                letteSpacing: "0px",
                color: "gray",
                margin: "15px 0",
                marginLeft: "18px",
              }}
            >
              {t("modals.nameGroup")}*
            </div>

            <PublicPrivateToggle
              handleChangeForSwitch={handleChangeForSwitch}
              groupType={groupType}
            />
          </h3>
          <input
            style={{ marginBottom: "10px" }}
            type="text"
            className={styles.inputField}
            placeholder={t("modals.nameClassPlaceholder")}
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
              props.onGroupNameChange(e);
            }}
          />
          <h3 for="description" className={styles.inputLabel}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                font: "normal normal 500 20px Montserrat",
                letteSpacing: "0px",
                color: "gray",
                margin: "15px 0",
                marginLeft: "18px",
              }}
            >
              {t("modals.description")}
            </div>
          </h3>

          <textarea
            className={styles.inputField1}
            placeholder={t("modals.describeGroupPlaceholder")}
            onChange={(e) => {
              setGroupDescription(e.target.value);
              props.onGroupDescriptionChange(e);
            }}
          >
            {groupDescription}
          </textarea>

          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                props.actionType == "EDIT"
                  ? props.onGroupEdit()
                  : props.onGroupAdd();
              }}
              disableRipple
              size="small"
              sx={{
                mr: "25px",
                mt: "20px",
                mb: "20px",
                background: "#1e4387",
                ":hover": {
                  background: "#1e4387",
                },
                ...properButtonStyles,
                paddingRight: "12px",
              }}
              startIcon={
                <Avatar
                  variant="square"
                  src="/Image/DashboardImages/postModImages/add_post_icon.svg"
                  sx={{
                    color: "#fff",
                    width: "auto",
                    height: "18px",
                  }}
                />
              }
            >
              {props.actionType == "EDIT"
                ? t("group.editGroup")
                : t("modals.createGroupButton")}
            </Button>
          </div>
        </form>
      ) : (
        <form>
          <h3 className={styles.inputLabel}>{t("group.sureToDeleteGroup")}</h3>
          <button
            onClick={() => {
              props.onGroupDelete();
            }}
            id="submitForm"
            className={styles.saveDetailsButton}
          >
            {t("group.deleteGroup")}
          </button>
        </form>
      )}
    </div>
  );
}

export default MiddleContain;
