import React from "react";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import styles from "./CSSFiles/Mobileburger.module.css";
import ClassModal from "../../ClassesGroups/ClassModal/ClassModal";
import GroupModal from "../../ClassesGroups/GroupModal/GroupModal";
import { connect } from "react-redux";
import { fetchProfileDataEdit } from "../../../redux/actions/profile";
import { GET_PROFILE_DATA_EDIT } from "../../../redux/constants/actionTypes";
import { withRouter } from "react-router-dom";

class MobileBurger extends React.Component {
  state = {
    showClassModal: false,
    showGroupModal: false,
  };
  closeBurger = () => {
    let { history } = this.props;
    history.push(`/user`);
  };
  GroupModalOpener = () => {
    this.setState({ showGroupModal: true });
  };
  ClassModalOpener = () => {
    this.setState({ showClassModal: true });
  };
  ClassModalCloser = () => {
    this.setState({ showClassModal: false });
  };
  GroupModalCloser = () => {
    this.setState({ showGroupModal: false });
  };
  componentDidMount = () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props.fetchProfileDataEdit(
      GET_PROFILE_DATA_EDIT,
      token,
      JSON.parse(localStorage.userData).profile_id
    );
  };
  render() {
    return (
      <div className={styles.mobileBurger}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "7px 0 7px 0",
            zIndex: "200",
          }}
        >
          <ClassModal
            actionType="ADD"
            closeModal={this.ClassModalCloser}
            show={this.state.showClassModal}
            showWelcome={this.WelcomeModalOpener}
          />
          <GroupModal
            actionType="ADD"
            closeModal={this.GroupModalCloser}
            show={this.state.showGroupModal}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <img
              onClick={this.closeBurger}
              src="/Image/apps/profile-resume-edit-view/cross.svg"
              height={30}
            />
          </div>
        </div>

        <div>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "-45px",
            }}
          >
            <Sidebar
              GroupModalOpener={this.GroupModalOpener}
              ClassModalOpener={this.ClassModalOpener}
            />
            <RightSidebar />
          </div>
        </div>
      </div>
    );
  }
}
/* export default withRouter(MobileBurger); */
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user,
    first_name: state?.profile?.user?.first_name,
    last_name: state?.profile?.user?.last_name,
    designation: state?.profile?.about?.designation,
    coverPicUrl: state?.profile?.userCoverPic,
    name: state?.profile?.name,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataEdit: (action, token, id) =>
      dispatch(fetchProfileDataEdit(action, token, id)),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileBurger)
);
