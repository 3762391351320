import React, { useState } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/ModalNew";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { mapStyle } from "../../../components/MapStyles";

const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const LocationModal = ({
  show,
  closeModal,
  method,
  coordinates,
  setCoordinates,
}) => {
  const addressLocal = JSON.parse(localStorage.getItem("address"));
  const [address, setAddress] = useState(addressLocal);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setAddress(value);
    setCoordinates(ll);
  };

  const selectLocation = () => {
    method(address);
    closeModal();
  };

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle,
    });
  };

  return (
    <>
      <ModalWithHeader
        title={"Move map to pin location"}
        show={show}
        closeHandler={closeModal}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "0px 0px 8px 8px",
            minHeight: "65vh",
            //position: "relative",
          }}
        >
          <Divider sx={{ background: "rgba(0,0,0,0.08)" }} />
          <Box sx={{ maxHeight: "40vh" }}>
            <Map
              google={google}
              zoom={16}
              style={{ width: "100%", height: "40vh" }}
              containerStyle={{ width: "100%", height: "40vh" }}
              initialCenter={{
                lat: coordinates.lat,
                lng: coordinates.lng,
              }}
              center={{
                lat: coordinates.lat,
                lng: coordinates.lng,
              }}
              onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            >
              <Marker
                name={"Current location"}
                draggable={true}
                position={{ lat: coordinates.lat, lng: coordinates.lng }}
                icon={{
                  url: "/Image/Discover/location-icon.svg",
                  // anchor: new google.maps.Point(32, 32),
                  // scaledSize: new google.maps.Size(64, 64),
                }}
              />
            </Map>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 20,
              position: "relative",
              mt: "32vh",
            }}
          >
            <Button
              onClick={selectLocation}
              variant="contained"
              disableRipple
              sx={{
                display: "flex",
                flexDirection: "column",
                textTransform: "none",
                background: "#0076B6",
                color: "#fff",
                padding: { lg: "12px 45px", xs: "10px 20px" },
                borderRadius: "50px",
                width: "50%",
                ":hover": {
                  background: "#0076B6",
                },
                //position: "absolute",
                //top: 200,
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "17px", xs: "14px" },
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Use this location
              </Typography>
              <Typography
                sx={{ fontSize: "15px", fontWeight: 400, lineHeight: "20px" }}
              >
                {address?.slice(0, 50)}...
              </Typography>
            </Button>

            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  key={suggestions.description}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    //position: "absolute",
                    // bottom: 150,
                    // zIndex: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "80%",
                    }}
                  >
                    <SearchIcon sx={{ mt: "20px" }} />
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                      style={{
                        marginTop: 30,
                        padding: 10,
                        borderRadius: 8,
                        width: "100%",
                      }}
                    />
                  </div>
                  <div
                    className="autocomplete-dropdown-container"
                    style={{
                      width: "70%",
                      maxHeight: "10vh",
                      overflowY: "scroll",
                    }}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Box>
        </Box>
      </ModalWithHeader>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GoogleMapsApiKey,
})(LocationModal);
