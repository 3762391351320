import React, { Component } from "react";
import styles from "./css/referral.module.css";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import Button from "../button/button";
import { withTranslation } from "react-i18next";
import { store } from "../../redux/store";
// import Career from '././Image/DashboardImages/careerModalImages/careermodal.svg';
class Referral extends Component {
  constructor() {
    super();
    this.state = {
      copied: false,
    };
  }
  render() {
    const userId = store?.getState()?.profile?.profileId;
    // console.log('Profile Id', userId)
    return (
      <div>
        <ModalWithHeader
          title={this.props.t("modals.chalkmatesReferral")}
          show={this.props.show}
          closeHandler={this.props.closeModal}
          maxWidth="540px"
          minWidth="350px"
        >
          <div className={styles.bottomContainer}>
            <img
              src="/Image/header/referralImage.jpeg"
              className={styles.refrralImage}
            ></img>
            <div className={styles.title}>
              {this.props.t("modals.shareLove")}
            </div>
            <div className={styles.info}>
              {this.props.t("modals.invitePeopleDescription")}
            </div>
            <div
              style={{
                cursor: "pointer",
                padding: "15px 20px",
                marginBottom: "20px",
                backgroundColor: this.state.copied ? "#e0ffef" : "#F2F3F5",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                navigator.clipboard.writeText(`https://nxnearby.com/share_referral/${userId}`);
                this.setState({ copied: true });
              }}
            >
              <p className={styles.link}>https://nxnearby.com/share_referral/{userId}</p>
              <img
                className={styles.copyButton}
                height="25px"
                width="25px"
                src="/Image/DashboardImages/sharemodImages/copy.svg"
              />
            </div>

            <div className={styles.info}>
              {this.props.t("modals.sendInvitation")}
            </div>

            <a href="mailto:?subject=Join nxNearby Now!&body=Hi there,%0A%0anxNearby is a place where you can build your social network, manage and monetize your brand.%0A%0AJoin nxNearby and learn better together.%0A%0AVisit: https://www.nxnearby.com">
              <button className={styles.mailBtn}>
                {this.props.t("modals.sendMail")}
              </button>
            </a>
          </div>
        </ModalWithHeader>
      </div>
    );
  }
}

export default withTranslation()(Referral);
