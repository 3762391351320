import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Lato",
    color: "#000",
  },
  palette: {
    primary: {
      main: "#F33070",
      hover: "#FF4A10",
      background: "rgba(0,0,0,0.08)",
      500: "#000",
      400: "rgba(0,0,0,0.6)",
      black: "#000",
    },
    secondary: {
      main: "#F33070",
      hover: "#0267C8",
    },
  },
});

export default theme;
