import ChatList from "./ChatList";
import styles from "./css/NewMessageView.module.css";

function NewMessageView(props) {
    const { openChat, dmList, updateDmList } = props;
    // remove the groups from here
    const filteredDmList = dmList.filter(
        (item) => item.dm_info.members.length === 1,
    );
    return (
        <div className={`${styles.container}`}>
            <ChatList
                chatListType="new_message"
                openChat={openChat}
                dmList={filteredDmList}
                updateDmList={updateDmList}
            />
        </div>
    );
}

export default NewMessageView;
