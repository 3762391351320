import React, { Component } from "react";
import SaveButton from "../../../components/commons/SaveButton";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import { withTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
class JoinPublicGroupModal extends Component {
  render() {
    return (
      <div>
        <ModalWithHeader
          title={this.props.t("common.joinGroup")}
          closeHandler={this.props.shareModalCloser}
          show={this.props.show}
          style={{ backgroundColor: "white", padding: 20, maxWidth: 350 }}
        >
          <Typography fontSize="large">
            Do you want to join{" "}
            <span style={{ fontStyle: "italic", fontWeight: "600" }}>
              {this?.props?.group_name}
            </span>{" "}
            group?
          </Typography>
          <SaveButton
            btntitle={this.props.t("common.join")}
            onClick={() => this.props.joinPublicGroup(this.props.group_id)}
          />
        </ModalWithHeader>
      </div>
    );
  }
}

export default withTranslation()(JoinPublicGroupModal);
