import { React, Component } from "react";
import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { withRouter } from "react-router-dom";

class FeaturedFeed extends Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  state = {
    image: null,
  };

  componentDidMount() {
    this.context.getProfileGallery(this.props.match.params.id);
  }

  handleImageChange(event) {
    if (event.target.files[0] != null) {
      this.context.addGalleryImage(
        event.target.files[0],
        this.props.match.params.id
      );
    }
  }

  render() {
    const { t } = this.props;
    console.log(this.props.galleryImages);
    return (
      <Box style={{ width: "100%" }}>
        <div className={styles.profile_title}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", gap: 2, mb: 1.5, alignItems: "center" }}
            >
              <img
                src="/Image/Profile/photo.svg"
                alt="about"
                style={{ width: "25px" }}
              />
              <Typography
                sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
              >
                {t("profile.featured")}
              </Typography>
            </Box>

            {this.props.editPage && (
              <>
                <label htmlFor="imageSelector">
                  <div style={{ marginRight: "24px" }}>
                    <img
                      src="/Image/Profile/edit.svg"
                      style={{ height: 20, cursor: "pointer" }}
                    />
                  </div>
                </label>

                <input
                  type="file"
                  className={`${styles.inputfield_cover} ${styles.input_foc}`}
                  id="imageSelector"
                  onChange={this.handleImageChange}
                  style={{ display: "none", cursor: "pointer" }}
                  accept=".png, .jpeg"
                />
              </>
            )}
          </div>
        </div>

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            borderRadius: 10,
            display: "flex",
            padding: 10,
          }}
        >
          {this.context.state.profileGallery.length === 0 ? (
            <p style={{ fontSize: 16, color: "#000" }}>
              {t("profile.noInformationAvailable")}
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <ImageList
                sx={{ width: "100%", maxHeight: 350 }}
                cols={4}
                rowHeight={164}
              >
                {this.context.state.profileGallery.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      srcSet={`${item.image}`}
                      src={`${item.image}`}
                      alt={item.profile}
                      loading="lazy"
                      style={{
                        width: "164px",
                        height: "164px",
                        objectFit: "cover",
                        borderRadius: 10,
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          )}
        </div>
      </Box>
    );
  }
}

FeaturedFeed.contextType = ProfileContext;

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    galleryImages: state.profile,
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps)(FeaturedFeed))
);
