import { MEET_SUCCESS, MEET_FAILURE, HEADER, GET_USER_SETTINGS, PUT_USER_SETTINGS, PAYMENT_CREATE } from "../constants/actionTypes";

const defaultState = {
  zoom_settings: {},
  paymentCreated: {}
};
let zoomData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case MEET_SUCCESS:
      return { ...state, meetData: action.payload };

    case MEET_FAILURE:
      return { ...state, message: "" };

    case HEADER:
      return { ...state, isHeader: action.payload };

    case GET_USER_SETTINGS:
      zoomData = { ...state };
      zoomData.zoom_settings = action.payload;
      return zoomData;
    case PUT_USER_SETTINGS:
      zoomData = { ...state }
      zoomData.zoom_settings.zoomSettings = {...zoomData.zoom_settings.zoomSettings, 
        subscribed: action.payload?.zoom_settings?.subscribed || zoomData.zoom_settings.zoomSettings?.subscribed, 
        default_meeting_link: action.payload?.zoom_settings?.default_meeting_link || zoomData.zoom_settings.zoomSettings?.default_meeting_link,
        account_type: action.payload?.zoom_settings?.account_type || zoomData.zoom_settings.zoomSettings?.account_type,
      };
      return zoomData;
    case PAYMENT_CREATE:
      return {
         ...state,
         paymentCreated: action.payload
      }

    default:
      return { ...state };
  }
};
