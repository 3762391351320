import React, { useEffect, useState, useRef } from "react";
import "./Nav.css";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Nav() {
  const [toggle, setToggle] = useState(false);
  function handleClick() {
    toggle ? setToggle(false) : setToggle(true);
  }
  const { t } = useTranslation();
  const initLang =
    localStorage.getItem("i18nextLng") ||
    navigator.language ||
    navigator.userLanguage;
  const [lang, setLang] = useState(initLang);
  const [showLangMenu, setShowLangMenu] = useState(false);

  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const toggleLanguageMenu = () => {
    setShowLangMenu((prev) => !prev);
  };

  useEffect(() => {
    if (initLang == "uz") {
      setLang("O'zbek");
    } else if (initLang == "ru") {
      setLang("Pусский");
    } else if (initLang == "hi") {
      setLang("हिंदी");
    } else {
      setLang("English");
    }
  }, []);

  useEffect(() => {
    let handler = (event) => {
      if (
        !navNode.current?.contains(event.target) &&
        !toggler.current?.contains(event.target)
      ) {
        setShowLangMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const navNode = useRef();
  const toggler = useRef();

  const changeSelectedLanguage = (selectedLang) => {
    i18next.changeLanguage(selectedLang);
    localStorage.setItem("i18nextLng", selectedLang);
    if (selectedLang == "uz") {
      setLang("O'zbek");
    } else if (selectedLang == "ru") {
      setLang("Pусский");
    } else if (selectedLang == "hi") {
      setLang("हिंदी");
    } else {
      setLang("English");
    }
  };

  return (
    <div>
      <div className="nav-active">
        <Link to="/">
          <div className="logo-section">
            {!navbar ? (
              <img
                className="logo"
                src="Image/HomeImages/Logo.png"
                alt="NavLogo"
              />
            ) : (
              <img className="logo" src="Image/HomeImages/Logo.png" alt="" />
            )}
          </div>
        </Link>
        <div className={!navbar ? "navlink" : "navlink-active"}>
          {/*<a className="signin-header" href="https://video.chalkmates.ml/" target="_blank">
            {!navbar ? <img className="logo" src="Image/vidPanda-white.svg" alt="" /> : <img className="logo" src="Image/vidPanda.svg" alt="" />}
          </a>*/}

          {/* <Link className={!navbar ? "signin" : "signin-active"} to="/signup">
            <div>{t("common.signup")}</div>
          </Link>

          <Link className="signin-header" to="/signin">
            <div
              className={!navbar ? "join-header" : "join-header-active"}
              style={{ cursor: "pointer" }}
            >
              {t("common.login")}
            </div>
          </Link> */}
          <div
            ref={navNode}
            className={!navbar ? "language-div" : "language-div-dark"}
            onClick={() => toggleLanguageMenu()}
          >
            {!navbar ? (
              <img
                className="language-header"
                src="/Image/header/language_icon.svg"
                alt="language Icon"
                style={{ width: "35px", height: "auto" }}
              />
            ) : (
              <img
                className="language-header"
                src="/Image/header/language_icon.svg"
                alt="language icon"
                style={{ width: "35px", height: "auto" }}
              />
            )}
            <div className="langTextDiv">{lang}</div>
            {showLangMenu ? (
              <div className="dropdownLanguage">
                <ul className="dropdownList">
                  <div>
                    <li
                      className={lang == "en" ? "activeList" : "inactiveList"}
                      onClick={() => changeSelectedLanguage("en")}
                    >
                      English
                    </li>

                    {/* <hr />
                    <li
                      className={lang == "ru" ? "activeList" : "inactiveList"}
                      onClick={() => changeSelectedLanguage("ru")}
                    >
                      Pусский
                    </li>

                    <hr />
                    <li
                      className={lang == "uz" ? "activeList" : "inactiveList"}
                      onClick={() => changeSelectedLanguage("uz")}
                    >
                      O'zbek
                    </li> */}
                    <hr />
                    <li
                      className={lang == "hi" ? "activeList" : "inactiveList"}
                      onClick={() => changeSelectedLanguage("hi")}
                    >
                      हिंदी
                    </li>
                  </div>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* <div className="nav-mobile">
        <div className="nav-icon">
          <img src="Image/HomeImages/Logo.png" alt="logo" className="logo" />
          {toggle ? (
            <img
              className="menu_icon"
              onClick={handleClick}
              src="Image/HomeImages/menu-black.svg"
              alt=""
            />
          ) : (
            <img
              className="menu_icon"
              onClick={handleClick}
              src="Image/HomeImages/close.svg"
              alt=""
            />
          )}
        </div>
        <div className={`side-nav  ${toggle ? "side-nav-active" : ""}`}>
          <div className="side-nav-links">
            <div onClick={() => setToggle(false)}>
              <Link to="/signup">{t("common.signup")}</Link>
            </div>
            <div onClick={() => setToggle(false)} className="">
              {" "}
              <Link to="/signin">{t("common.login")}</Link>
            </div>
            <div
              className="togglerLang"
              ref={toggler}
              onClick={() => toggleLanguageMenu()}
            >
              <img src="Image/languageBlack.png" alt="language icon" />
              <div style={{ textTransform: "uppercase", color: "black" }}>
                {lang}
              </div>
              {showLangMenu ? (
                <div className="dropdownLanguage">
                  <ul className="dropdownList">
                    <div>
                      <li
                        className={lang == "en" ? "activeList" : "inactiveList"}
                        onClick={() => changeSelectedLanguage("en")}
                      >
                        English
                      </li>

                      <hr />
                      <li
                        className={lang == "hi" ? "activeList" : "inactiveList"}
                        onClick={() => changeSelectedLanguage("hi")}
                      >
                        हिंदी
                      </li>
                    </div>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Nav;
