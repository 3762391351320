export const categoryList = [
  "Appliances",
  "Automotive",
  "Baby & kids",
  "Bicycles",
  "Clothing & accessories",
  "Electronics",
  "Furniture",
  "Garden",
  "Home decor",
  "Home sales",
  "Musical instruments",
  "Neighbour made",
  "Neighbour services",
  "Other",
  "Pet supplies",
  "Property rentals",
  "Sports & outdoors",
  "Tickets",
  "Tools",
  "Toys & games",
];
