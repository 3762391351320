import React from "react";
import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
// import ModalHeader from "../../../../../../components/commons/ModalHeader"
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import { updateProfileData } from "../../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import CancelIcon from "@mui/icons-material/Cancel";
import { withTranslation } from "react-i18next";

class AddTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagContent: this.props?.tagContent || "",
      tag: "",

      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    document.body.style.overflow = "visible";
    this.props.changeEdit(false);
  }

  SaveButtonOnClick() {
    this.props.changeEdit(false);
    let data = new FormData();
    data.append("tags", this.state.tagContent);

    this.props.updateProfileData(EDIT_PROFILE_DATA, data).then((res) => {
      //
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  ScrollBody() {
    const {t} = this.props;
    return (
      <div>
        <div style={{ display: "flex", marginBottom: 15, justifyContent: "space-between", alignItems: "center" }}>
          <input
            className={`${styles.modal_para} ${styles.modal_input_text}`}
            style={{
              flex: 1,
            }}
            type="text"
            placeholder={t("profile.addTags")}
            value={this.state.tag}
            onChange={(event) => {
              this.setState({ tag: event.target.value });
            }}
          />

          {/* <div> */}
            &nbsp;
          <button
            className={`${styles.btn} ${styles.connect_btn}`}
            style={{
              flex: 0.1,
            }}
            
            onClick={(e) => {
              e.preventDefault();
              var newTagContent ;
              if(this.state.tag.trim() != ""){
                if( !this.props.tagContent.split(',').includes(this.state.tag.trim())){
                  newTagContent = this.state.tag;
                  if (this.state.tagContent.length != 0) {
                    newTagContent += "," + this.state.tagContent;
                  }
                  this.setState({ tagContent: newTagContent, tag: "" });
                }
                else{
                  this.setState({error: true, message:t("errors.cantAddDuplicateTag")})
                }
              }
              else{
                 this.setState({error: true, message:t("errors.cantAddEmptyTag")})
              }
              
            }}
          
          >
            <p className={styles.btn_text} style={{padding: '0 12px'}}>{t("common.add")}</p>
          </button>

          <br />
        </div>

        <div style={{ borderBottom: "2px solid #5555555a" }} />
        <br />
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {/* <Stack direction="row" spacing={1}> */}
          {
            // this.state.tagContent.length == 0 ? null :
            //   this.state.tagContent?.split(",").map(item => {
            //     return (
            //       <Chip
            //         label={item}
            //         // onClick={handleClick}
            //         onDelete={() => {
            //           const updatedContent = this.state.tagContent.split(",").filter(f => {
            //             return f === item ? false : true
            //           })

            //           this.setState({ tagContent: updatedContent.join(",") })
            //         }}
            //       />
            //     )
            //   }) || null

            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.state.tagContent.length == 0
                ? null
                : this.state.tagContent?.split(",").map((item, idx) => {
                    return (
                    <>{ item != "" ?
                      <li key={idx} className={styles.tagList}>
                        {item}
                        <div style={{display: "flex", margin: '0 -10px 0 8px'}} >
                        <CancelIcon
                          style={{ fill: "#878484", cursor: "pointer" }}
                          size={10}
                          onClick={() => {
                            const updatedContent = this.state.tagContent.split(",").filter((f) => {
                              return f === item ? false : true;
                            });

                            this.setState({ tagContent: updatedContent.join(",") });
                          }}
                        />
                        </div>
                      </li> : null
                      } </>
                    );
                  })}
            </ul>
          }

          {/* </Stack> */}
        </ul>
      </div>
    );
  }

  render() {
    //
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action, data) => dispatch(updateProfileData(action, data)),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddTags));
