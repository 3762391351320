import React from "react";
import AboutFeed from "./AboutFeed";
import FeaturedFeed from "./FeaturedFeed";
import InterestsFeed from "./InterestsFeed";
import ExperienceFeed from "./ExperienceFeed";
import EducationFeed from "./EducationFeed";
import LicencesFeed from "./LicencesFeed";
import VolunteerFeed from "./VolunteerFeed";
import EndorsementsFeed from "./EndorsmentsFeed";
import AchievementsFeed from "./AchievementsFeed";
import MidDpicFeed from "./MidDpicFeed";
import EditDescFeed from "./EditDescFeed";
import styles from "../../../Profile.module.css";
import PostsFeed from "./PostsFeed";
import { connect } from "react-redux";
import CategoryFeed from "../../edit/components/CategoryFeed";
import OfferingFeed from "../../edit/components/OfferingFeed";

class LeftFeed extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          justifyContent: "center",
        }}
      >
        <div className={styles.leftbar_hamburger_view}>
          <div className={styles.dpic_inside_ham}>
            <MidDpicFeed props={this.props} />
          </div>
          <EditDescFeed mobileView={true} />
          <br />
        </div>

        <div
          id={
            this.props.userTypeView === "INDIVIDUAL" ? "About" : "Business Info"
          }
          style={{}}
        >
          <AboutFeed editpage={this.props.editpage} />
        </div>

        <br />

        {this.props.userTypeView === "LOCAL BUSINESS" && (
          <>
            <div id="Business Category" style={{ width: "100%" }}>
              <CategoryFeed editPage={false} />
            </div>

            <br />

            <div id="Business Offering" style={{ width: "100%" }}>
              <OfferingFeed editPage={false} />
            </div>

            <br />
          </>
        )}

        {this.props.userTypeView === "INDIVIDUAL" && (
          <>
            <div id="Posts" style={{ width: "100%" }}>
              <PostsFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Photo Gallery" class={styles.Featured} style={{}}>
              <FeaturedFeed editPage={false} />
            </div>

            <br />
          </>
        )}

        {this.props.userTypeView === "LOCAL BUSINESS" && (
          <>
            <div
              id="Photo Gallery"
              class={styles.Featured}
              style={{ width: "100%" }}
            >
              <FeaturedFeed editPage={false} />
            </div>

            <br />

            <div id="Last 5 Posts" style={{ width: "100%" }}>
              <PostsFeed editpage={true} />
            </div>

            <br />

            <div
              id="License and Certification"
              style={{ scrollMarginTop: 120 }}
            >
              <LicencesFeed editpage={this.props.editpage} />
            </div>

            <br />
          </>
        )}

        {this.props.userTypeView === "INDIVIDUAL" && (
          <>
            <div id="Hobbies & Interests" style={{}}>
              <InterestsFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Professional Experience" style={{}}>
              <ExperienceFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Educational Qualification" style={{}}>
              <EducationFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Volunteer Experience" style={{}}>
              <VolunteerFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Skills" style={{}}>
              <EndorsementsFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Achievements" style={{}}>
              <AchievementsFeed editpage={this.props.editpage} />
            </div>
          </>
        )}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    userTypeView: state.profile.user_type_view,
  };
};

export default connect(mapStateToProps, null)(LeftFeed);
