export const formatName = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const validateFileSize = (inputFile, standardSize) => {
  // pass standardSize (the max allowed file size) parameter in KB
  const size = standardSize > 1024 ? (standardSize/1024) + " MB" : standardSize + " KB"; 

  return (inputFile?.size / 1024 > standardSize
    ? `Maximum allowed file size is ${size}. Please lower the file size and retry uploading.`
    : "");
}

  

export const errorMessageFromResponse = (resp) => {
  let errorMsg = "";
  if (typeof resp === "object") {
    const errorObj = resp?.response?.data?.error;
    if (errorObj != null) {
      errorMsg = Object.keys(errorObj).includes("message")
        ? errorObj?.message
        : Object.values(errorObj)[0];
    }
  } else if (typeof resp === "string") {
    errorMsg = resp;
  }
  return errorMsg;
};

export const userSubType = (subType) => {
  let newUserSubType = "";
  if (subType === "UNI_CLG") {
    newUserSubType = "University/College";
  }
  if (subType === "COMPANY") {
    newUserSubType = "Company";
  }
  if (subType === "PVT_COACHING") {
    newUserSubType = "Private coaching";
  }
  if (subType === "SCHOOL") {
    newUserSubType = "School";
  }
  if (subType === "FACULTY") {
    newUserSubType = "Faculty";
  }
  if (subType === "STUDENT") {
    newUserSubType = "Student";
  }
  if (subType === "TRAINER") {
    newUserSubType = "Trainer";
  }
  if (subType === "PROFESSIONALL") {
    newUserSubType = "Professional";
  }
  return newUserSubType;
};

export const getStatusDescription = (status) => {
  let statusDesc = "";
  switch (status) {
    case "GRD":
      statusDesc = "Graded";
      break;
    case "AS":
      statusDesc = "Assigned";
      break;
    case "SUB":
      statusDesc = "Submitted";
      break;
    case "DL":
      statusDesc = "Done Late";
      break;
    default:
      statusDesc = "Assigned";
      break;
  }
  return statusDesc;
};

export const formatDate = (timestamp) => {
  let date = new Date(Date.parse(timestamp));
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let month = months[date.getMonth()];
  let day = date.getDate();
  let postfix = "th";
  switch (day) {
    case 1:
      postfix = "st";
      break;
    case 21:
      postfix = "st";
      break;
    case 31:
      postfix = "st";
      break;
    case 2:
      postfix = "nd";
      break;
    case 22:
      postfix = "nd";
      break;
    case 3:
      postfix = "rd";
      break;
    case 23:
      postfix = "rd";
      break;
    default:
      postfix = "th";
  }

  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime =
    day + postfix + " " + month + ", " + hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const extractDomain = (link) => {
  if (link !== undefined && link !== null) {
    const url = new URL(link);
    let domain = url.hostname;
    let domainArr = domain.split(".");
    let domainName = domainArr[domainArr?.length - 2];
    return domainName;
  }
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " year(s) ";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month(s) ";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " day(s) ";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hour(s) ";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minute(s) ";
  }
  return Math.floor(seconds) + " second(s) ";
};

export const isValidUrl = (urlString) => {
  let expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  let regex = new RegExp(expression);
  return urlString.match(regex);
}

export const generateRandomThumbnail = ()=>{

  const thumbnailList = [
    "/randomThumbnails/andrew-neel-cckf4TsHAuw-unsplash.png",
    "/randomThumbnails/annie-spratt-4A1pj4_vClA-unsplash.png",
    "/randomThumbnails/chris-montgomery-smgTvepind4-unsplash.png",
    "/randomThumbnails/compare-fibre-Y8TiLvKnLeg-unsplash.png",
    "/randomThumbnails/element5-digital-OyCl7Y4y0Bk-unsplash.png",
    "/randomThumbnails/firmbee-com-gcsNOsPEXfs-unsplash.png",
    "/randomThumbnails/jamie-taylor--9C3TMXwQjQ-unsplash.png",
    "/randomThumbnails/jason-goodman-Oalh2MojUuk-unsplash.png",
    "/randomThumbnails/kelly-sikkema-Y63DCcIKlIs-unsplash.png",
    "/randomThumbnails/kenny-eliason-1-aA2Fadydc-unsplash.png",
    "/randomThumbnails/nick-morrison-FHnnjk1Yj7Y-unsplash.png",
    "/randomThumbnails/priscilla-du-preez-ggeZ9oyI-PE-unsplash.png",
    "/randomThumbnails/samantha-borges-EeS69TTPQ18-unsplash.png",
    "/randomThumbnails/wes-hicks-4-EeTnaC1S4-unsplash.png",
  ]

  return thumbnailList[Math.floor(Math.random() * thumbnailList.length)];
  // return thumbnailList[Math.floor(Math.random() * (thumbnailList.length - 1 + 1) + 1) - 1];
  
  // Math.floor(Math.random() * (max - min + 1) + min)

}

export const thumbnailList = [
  "/randomThumbnails/andrew-neel-cckf4TsHAuw-unsplash.png",
  "/randomThumbnails/annie-spratt-4A1pj4_vClA-unsplash.png",
  "/randomThumbnails/chris-montgomery-smgTvepind4-unsplash.png",
  "/randomThumbnails/compare-fibre-Y8TiLvKnLeg-unsplash.png",
  "/randomThumbnails/element5-digital-OyCl7Y4y0Bk-unsplash.png",
  "/randomThumbnails/firmbee-com-gcsNOsPEXfs-unsplash.png",
  "/randomThumbnails/jamie-taylor--9C3TMXwQjQ-unsplash.png",
  "/randomThumbnails/jason-goodman-Oalh2MojUuk-unsplash.png",
  "/randomThumbnails/kelly-sikkema-Y63DCcIKlIs-unsplash.png",
  "/randomThumbnails/kenny-eliason-1-aA2Fadydc-unsplash.png",
  "/randomThumbnails/nick-morrison-FHnnjk1Yj7Y-unsplash.png",
  "/randomThumbnails/priscilla-du-preez-ggeZ9oyI-PE-unsplash.png",
  "/randomThumbnails/samantha-borges-EeS69TTPQ18-unsplash.png",
  "/randomThumbnails/wes-hicks-4-EeTnaC1S4-unsplash.png",
]