import React, { Component } from "react";
import SuggestionCard from "./SuggestionCard";
import styles from "./CSSFiles/suggestions.module.css";
import { createRef } from "react";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const cardsList = [
  {
    src: "/Image/DashImage/Business/business1.jpg",
    link: "https://mcdelivery.co.in/",
  },
  {
    src: "/Image/DashImage/Business/business2.jpg",
    link: "https://www.samsung.com/in/apps/smart-switch/",
  },
  {
    src: "/Image/DashImage/Business/business3.jpg",
    link: "https://www.pantaloons.com/",
  },
];

class Suggestions extends Component {
  ref = createRef();
  scrollLeft = () => {
    this.ref.current.scrollLeft -= 235;
  };
  scrollRight = () => {
    this.ref.current.scrollLeft += 235;
  };
  render() {
    return (
      <div>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ color: "#535353", fontWeight: 600, ml: 2 }}>
            Nearby Local Business
          </Typography>
          <div className={styles.settingsContainer}>
            <img
              src="/Image/DashboardImages/DashCommonImages/chevron-left.svg"
              onClick={this.scrollLeft}
              style={{ position: "absolute", right: "38px", cursor: "pointer" }}
            />
            <img
              src="/Image/DashboardImages/DashCommonImages/chevron-right.svg"
              onClick={this.scrollRight}
              style={{ position: "absolute", right: 0, cursor: "pointer" }}
            />
          </div>
        </Box>
        <div ref={this.ref} className={styles.container}>
          {cardsList.map((card, idx) => (
            <Box key={idx}>
              <SuggestionCard Background={`${card.src}`} link={card.link} />
            </Box>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Suggestions);
