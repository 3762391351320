import { gql } from "graphql-request";

export const CREATE_COURSE_MUTATION = gql`
  mutation ($input: CreateCourseInput!) {
    createcourse(input: $input) {
      id
      thumbnail
      name
      description
      subtitle
      public
      owner {
        name
        id
        profile_link
      }
      user_profile {
        id
        name
        profile_pic
      }
      files {
        id
        course_id
        media
      }
      urls {
        url
        id
      }
      active
      free
      price
      currency
      members_count
      classes {
        id
        name
      }
      is_enrolled
      section {
        id
        title
        lectures {
          id
          title
          description
          fileLink
          url
          fileType
          article
          duration
          completed
        }
        total_lecs
        sec_mins
        quiz {
          id
          title
          details
          max_marks
          template {
            ques_count
          }
        }
      }
      course_mins
      total_lecs
      progress_bar
      is_draft
    }
  }
`;

export const EDIT_COURSE_MUTATION = gql`
  mutation ($input: UpdateCourseInput!) {
    updatecourse(input: $input) {
      id
      thumbnail
      name
      description
      subtitle
      public
      owner {
        name
        id
        profile_link
      }
      user_profile {
        id
        name
        profile_pic
      }
      files {
        id
        course_id
        media
      }
      urls {
        url
        id
      }
      active
      free
      price
      currency
      members_count
      classes {
        id
        name
      }
      is_enrolled
      section {
        id
        title
        lectures {
          id
          title
          description
          fileLink
          url
          fileType
          article
          duration
          completed
        }
        total_lecs
        sec_mins
        quiz {
          id
          title
          details
          max_marks
          template {
            ques_count
          }
        }
      }
      course_mins
      total_lecs
      progress_bar
      is_draft
    }
  }
`;

export const DELETE_COURSE_MUTATION = gql`
  mutation ($input: ID!) {
    deletecourse(id: $input) {
      message
    }
  }
`;

export const UPDATE_COURSE_STATUS_MUTATION = gql`
  mutation ($input: UpdateCourseInput!) {
    updatecourse(input: $input) {
      id
      name
      active
      utimestamp
    }
  }
`;

export const ENROLL_COURSE_MUTATION = gql`
  mutation ($input: PaymentInput, $id: ID!) {
    enrollcourse(input: $input, id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_COURSE_MUTATION = gql`
  mutation ($input: UpdateCourseInput!) {
    updatecourse(input: $input) {
      id
      thumbnail
      name
      description
      subtitle
      public
      owner {
        name
        id
        profile_link
      }
      user_profile {
        id
        name
        profile_pic
      }
      files {
        id
        course_id
        media
      }
      urls {
        url
        id
      }
      active
      free
      price
      currency
      members_count
      classes {
        id
        name
      }
      is_enrolled
      section {
        id
        title
        lectures {
          id
          title
          description
          fileLink
          url
          fileType
          article
          duration
          completed
        }
        total_lecs
        sec_mins
        quiz {
          id
          title
          details
          max_marks
          template {
            ques_count
          }
        }
      }
      course_mins
      total_lecs
      progress_bar
      is_draft
    }
  }
`;

export const PUBLISH_COURSE_AND_SECTION_MUTATION = gql`
  mutation ($input: ID!) {
    pubCourseandSection(course_id: $input) {
      id
      thumbnail
      name
      description
      subtitle
      public
      owner {
        name
        id
        profile_link
      }
      user_profile {
        id
        name
        profile_pic
      }
      files {
        id
        course_id
        media
      }
      urls {
        url
        id
      }
      active
      free
      price
      currency
      members_count
      classes {
        id
        name
      }
      is_enrolled
      section {
        id
        title
        lectures {
          id
          title
          description
          fileLink
          url
          fileType
          article
          duration
          completed
          isPublished
        }
        total_lecs
        sec_mins
        quiz {
          id
          title
          details
          max_marks
          template {
            ques_count
          }
        }
      }
      course_mins
      total_lecs
      progress_bar
      is_draft
    }
  }
`;
