import reactDom from "react-dom";
import React from "react";
import styles from "./button.module.css";

class Button extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    return (
      <button className={styles.btn} style={{ marginLeft: 15 }}>
        {this.props.btn_name}
      </button>
    );
  }
}
export default Button;
