import React from "react";

export default class TimeTitleDescComp extends React.Component {
  render() {
    let totalExp = 100 / this.props.data.length;
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div id="timeline" style={{ backgroundColor: "#E5E7E7", height: 8, width: "70em", position: "absolute", marginTop: "2.4em", zIndex: -1, borderRadius: 8 }} />
        {this.props.data.map((edval, idx) => {          return (
            <div key={idx} style={{ width: totalExp + "%" }}>
              <div style={{ marginBottom: "1em", paddingRight: "2em" }}>
                <p style={{ color: "#555555", fontSize: 15 }}>{`${edval.end_date}`}</p>
              </div>
              <div style={{ backgroundColor: "#236ae8", height: 15, width: 15, borderRadius: 8, marginBottom: "2em" }}></div>
              <div>
                <p style={{ color: "#000", fontSize: 15, fontWeight: 600 }}>{edval.title ?? `${edval.degree} | ${edval.school}`}</p>
                {/* {edval.title ?? edval.degree ?? edval.school ?? edval.field_of_study} */}
              </div>
              <div style={{ paddingRight: "2em" }}>
                <p 
                style={{ 
                  color: "#555555", 
                  fontSize: 15, 
                  // overflow: "hidden",
                  // textOverflow: "ellipsis", 
                  // whiteSpace: "pre"  
                  }}>{edval.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
