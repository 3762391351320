import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { handleErrorMessage } from "../redux/actions/classes";
import { handleSuccessMessage } from "../redux/actions/success";

export default class UnProtectedRouteWithoutHeader extends Component {
  constructor() {
    super();

    this.state = {
      errorOpen: false,
    };
  }

  errorCloser = () => {
    this.props.handleErrorMessage("");
  };
  successMsgCloser = () => {
    this.props.handleSuccessMessage("");
  };
  render() {
    return (
      <>
        <Snackbar
          open={this.props.errorMsg != "" ? true : false}
          autoHideDuration={6000}
          onClose={this.errorCloser}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.errorCloser}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.props.errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.props.successMsg != "" ? true : false}
          autoHideDuration={6000}
          onClose={this.successMsgCloser}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.successMsgCloser}
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.props.successMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMsg: state?.errors?.errorMessage || "",
    successMsg: state?.success?.successMessage || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleErrorMessage: (errorMsg) => dispatch(handleErrorMessage(errorMsg)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
  };
};

UnProtectedRouteWithoutHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnProtectedRouteWithoutHeader);
export { UnProtectedRouteWithoutHeader };
