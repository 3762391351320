import React, { Component, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  commentLike,
  commentLikeDelete,
} from "../../../redux/actions/dashboard";
import styles from "./CSSFiles/comment.module.css";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import RecommendRoundedIcon from "@mui/icons-material/RecommendRounded";
import { Link } from "react-router-dom";

function Comment(props) {
  const {
    key,
    photo,
    name,
    commentdata,
    id,
    likedComment,
    user,
    commentLike,
    commentLikeDelete,
    newCommentLike,
    comment_owner_id,
  } = props;

  const newRefCommentLike = useRef(newCommentLike);
  newRefCommentLike.current = newCommentLike;
  const [likes, setLikes] = useState(0);
  const [likeButtonPressed, setLikeButtonPressed] = useState(false);
  const [likedId, setLikedId] = useState(null);
  const [likeClickable, setLikeClickable] = useState(true);
  let data = {
    commentId: id,
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (likedComment != null) {
      // console.log("likedComment----", likedComment)

      setLikes(likedComment.length);
      if (
        likedComment.some(
          (likedComment) => likedComment.profile_liked_by == user.profile_id
        )
      ) {
        setLikeButtonPressed(true);
        likedComment.map((data, index) => {
          if (data.profile_liked_by == user.profile_id) {
            setLikedId(data.id);
          }
        });
      }
    } else {
      setLikes(0);
      setLikeButtonPressed(false);
      setLikedId(null);
    }
  }, [id]);

  const liker = (currentLikes) => {
    setLikeClickable(false);
    if (!likeButtonPressed) {
      commentLike(data).then(() => {
        setLikedId(newRefCommentLike.current.id);
        props.updateAddLikedComment(id, newRefCommentLike.current);
        setLikeClickable(true);
      });
      setLikes(currentLikes + 1);
      setLikeButtonPressed(true);
    } else {
      commentLikeDelete(likedId).then(() => {
        setLikeClickable(true);
      });
      props.updateRemoveLikedComment(id);
      setLikes(currentLikes - 1);
      setLikeButtonPressed(false);
      setLikedId(null);
    }
  };

  return (
    <div className={styles.outerContainer}>
      <img
        src={photo}
        height="40px"
        width="40px"
        style={{ borderRadius: "100%" }}
      />
      <div className={styles.commentContainer}>
        <div className={styles.innerContainer}>
          <Link
            to={`/profile/${comment_owner_id}/view`}
            style={{ textDecoration: "none" }}
          >
            <div className={styles.titleDiv}>
              <span className={styles.name}>{name}</span>
            </div>
          </Link>
          <p className={styles.message}>{commentdata}</p>
        </div>
        <div className={styles.likeDiv}>
          <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
            {likeClickable ? (
              <span
                onClick={() => liker(likes)}
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                {t("common.Like")}
              </span>
            ) : (
              <span style={{ cursor: "not-allowed" }}>{t("common.Like")} </span>
            )}
            {likeButtonPressed ? (
              <RecommendRoundedIcon
                sx={{ fontSize: "15px", color: "#378fe9" }}
              />
            ) : (
              <RecommendRoundedIcon sx={{ fontSize: "15px", color: "#000" }} />
            )}

            {likes}
          </span>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user,
    newCommentLike: state?.dashboard?.newCommentLike,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    commentLike: (data) => dispatch(commentLike(data)),
    commentLikeDelete: (data) => dispatch(commentLikeDelete(data)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Comment)
);
// photo,name,timestamp,commentdata
