import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function addAchievement(achState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("name", achState.name);
    data.append("description", achState.description);
    data.append("issuer", achState.issuer);
    data.append("issue_date", achState.issue_date);
    data.append("profile_id", JSON.parse(localStorage.getItem("userData")).profile_id);
    data.append("upload_img", achState.pictureAsFile, achState.pictureAsFile.name);

    // validations
    if (achState.issue_date == null) {
      return { val: false, message: "error at adding achievement - Empty issue date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/achievement/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Successfully added achievement"))
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateAchievement(achState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("name", achState.name);
    data.append("description", achState.description);
    data.append("issuer", achState.issuer);
    data.append("issue_date", achState.issue_date);
    data.append("profile_id", JSON.parse(localStorage.getItem("userData")).profile_id);
    if (achState.uploadImageFlag) {
      data.append("upload_img", achState.pictureAsFile, achState.pictureAsFile.name);
    }

    // validaitions
    if (achState.issue_date == null) {
      return { val: false, message: "error at updating achievement - Empty issue date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/achievement/${achState.id}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status=="1") {
          let value = Object.fromEntries(data.entries());
          if (achState.uploadImageFlag) {
            value = {...value, id: achState.id, upload_img: URL.createObjectURL(achState.pictureAsFile)}
          }
          else{
            value = {...value, id: achState.id, upload_img: achState.pictureAsFile}
          }
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated Successfully"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
