import React, { createContext, Component } from "react";
import { io } from "socket.io-client";

export const ChatContext = createContext();

class ChatProviderComponent extends Component {
  state = {
    socket: undefined,
    messages: [
      {
        from: "Me",
        message: "Thank You",
      },
    ],
  };

  componentDidMount() {
    // const socket = io.connect("http://localhost:3000");
    const url = process.env.REACT_APP_CHAT_SERVER_URL + ":" + process.env.REACT_APP_CHAT_SERVER_PORT;
    // const url = 'http://44.239.103.245' + ':' + '3000';
    //
    // const socket = io.connect(url);
    //
    // socket.on("recieve-message", message=>{
    //     const mess = [...this.state.messages];
    //     const newMessages = [...mess, {from:"You", message:message}]
    //     this.setState({messages:newMessages})
    // })
    // this.setState({socket:socket})
  }

  sendMessage = (message) => {
    this.state.socket.emit("send-message", message);
    const mess = [...this.state.messages];
    const newMessages = [...mess, { from: "Me", message: message }];
    this.setState({ messages: newMessages });
  };

  render() {
    let contextData = { state: this.state, sendMessage: this.sendMessage };
    return (
      <ChatContext.Provider value={contextData}>
        {this.props.children}
        {/* {} */}
      </ChatContext.Provider>
    );
  }
}

export const ChatProvider = ChatProviderComponent;
