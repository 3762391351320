import React from "react";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

class Tags extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "10px 20px",
            borderRadius: 10,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <img src="/Image/Profile/tags.svg" alt="tag" width="20px" />
            <Typography sx={{ color: "#606060", fontSize: "17px" }}>
              {t("profile.tags")}
            </Typography>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.tagContent?.split(",").map((item, idx) => {
                return (
                  <>
                    {item != "" ? (
                      <li key={idx} className={styles.tagList}>
                        {item}
                      </li>
                    ) : null}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tagsView || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Tags));
