import { React, Component } from "react";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import { connect } from "react-redux";
import ClassGroupComponent from "../CreateClass/ClassGroupComponent";
import { withRouter } from "react-router";
import { getGroupDetails, getGroups } from "../../../redux/actions/groups";
import { store } from "../../../redux/store";

class Group extends Component {
  constructor() {
    super();

    this.state = {
      groupId: store?.getState()?.groups?.group?.id,
      showQrModal: false,
      activeSection: "Post",
      subjectName: "",
      showCareerModal: false,
      showShareModal: false,
      showCourseModal: false,
      samplePosts: [
        {
          name: "Anshika",
          follower: "234",
          timestamp: "2 hrs",
          likes: "0",
          likedPost: null,
          comments: null,
          profilePic: "/Image/Classes/anshika_dp.jpeg",
          message:
            "Within an online discussion, the individual student responds to the course material (lectures and course books, for example) and to comments from other students. Students usually respond to those topics within the broader conversation that most clearly speak to their individual concerns. These situations result in smaller conversations taking place simultaneously within the group. While students should read all of their classmates’ contributions, they actively engage in only those parts of the dialog most relevant to their own interests.",
          tag: "AGENDA",
          photoUrl: "/Image/Classes/groupPost1.png",
          share_link: "qwe123",
        },
        {
          name: "Shivam",
          follower: "432",
          timestamp: "2 hrs",
          likes: "0",
          comments: null,
          likedPost: null,
          profilePic: "/Image/Classes/shivam_dp.jpg",
          message:
            "Group learning in an online classroom can take the form of discussion among the whole class or within smaller groups. Online courses offer the opportunity to create a highly social learning environment, characterized by participation and interactivity for both students and instructors.",
          photoUrl: "/Image/Classes/groupPost2.png",
          share_link: "po98u",
        },
      ],
      members: [],
    };
  }
  getActiveClassDetails = (subjectId) => {
    let { history } = this.props;
    history.push({
      pathname: `/group/${subjectId}`,
      state: { subjectId: subjectId },
    });
    this.props.getGroups();
    this.props.getGroupDetails(subjectId).then(() => {
      this.setState({ subjectId: this.props.subjectId });
      this.setState({ subjectName: this.props.subjectName });
    });
  };

  componentDidMount = () => {
    const subjectId = this.props.history.location.pathname.split("/")[2] || "";
    this.getActiveClassDetails(subjectId);
  };

  render() {
    return (
      <ClassGroupComponent
        subjectName={this.props.subjectName}
        subjectCode={this.props.subjectCode}
        class_id={this.props.history.location.pathname.split("/")[2]}
        group_id={this.props.group_id}
        routeType={this.props.routeType}
        imageCover={this.props.imageCover}
        toggleChatList={this.props.toggleChatList}
        isChatListOpen={this.props.isChatListOpen}
        subject={this.props.subject}
        grade={this.props.grade}
        getActiveClassDetails={this.getActiveClassDetails}
        samplePosts={this.state.samplePosts}
        members={this.props.members}
        myCourses={this.props.myCourses}
        myClasses={this.props.myClasses}
        userName={
          this.props?.user?.first_name && this.props?.user?.last_name
            ? this.props.user.first_name + " " + this.props.user.last_name
            : ""
        }
        meetings={this.props.meetings}
        activeSectionState={this.props?.location?.state?.activeSection}
        groupObjective={this.props.group_objective}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    subjectName: state?.groups?.group?.name || "",
    subjectCode: state?.groups?.group?.code || "",
    user: state.profile.user || "",
    grade: state?.groups?.group?.grade || "",
    subject: state?.groups?.group?.subject || "",
    imageCover:
      state?.groups?.group?.cover_image || "/Image/Group/group_defaultCover.png",
    myClasses:
      state?.groups?.groups?.map((each_group) => {
        return { name: each_group?.name, id: each_group?.id, group_type: each_group?.group_type, group_owner: each_group?.group_owner };
      }) || [],
    myCourses: [...state?.groups?.courses] || [],
    members: state?.groups?.members,
    group_id: state?.groups?.group?.id,
    routeType: "Group",
    meetings: state?.groups?.meetings || [],
    groupObjective: state?.groups?.group?.group_objective || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupDetails: (subjectId) => dispatch(getGroupDetails(subjectId)),
    getGroups: () => dispatch(getGroups())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Group));
