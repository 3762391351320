import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import IconTitle from "../../../components/commons/IconTitle";
import DatePickerComponent from "../Components/DatePickerComponent";
import MultipleSelectChip from "../Components/MultipleSelectChip";
import styles from "../css/CreateAssignment.module.css";
import TimePickerComponent from "../Components/TimePickerComponent";
import moment from "moment";
import { WindowRounded } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TooltipWrapper from "../../CourseMonetization/Components/TooltipWrapper";

function AssignedToModal(props) {
  // props - assignmentType, changeEdit, assignmentClass
  const { t } = useTranslation();
  const [assignmentDate, setAssignmentDate] = useState(
    props?.actionType === "edit" ? props.assignment?.due_date : new Date()
  );
  const [assignmentTime, setAssignmentTime] = useState(
    props?.actionType === "edit" ? props.assignment?.due_date : new Date()
  );
  const [lockStatus, setLockStatus] = useState(
    props?.actionType === "edit" ? !props.assignment?.is_lock : true
  );
  const [maxMarks, setMaxMarks] = useState(
    props?.actionType === "edit" ? props.assignment?.max_marks : ""
  );

  const [assignTo, setAssignTo] = useState("");

  const getAssignmentDate = (date) => {
    setAssignmentDate(date);
    props.getDueDate(date, assignmentTime);
  };
  const getAssignmentTime = (time) => {
    setAssignmentTime(time);
    props.getDueDate(assignmentDate, time);
  };
  const getLockStatus = () => {
    setLockStatus(!lockStatus);
    console.log(`Status is: ${lockStatus}`);
    props.lockStatus(lockStatus);
  };

  const submitAssignment = () => {
    props.submitForm();
  };

  const hanleAssignTo = (e) => {
    setAssignTo(e.target.value)
    console.log("River",e.target.value)
    props.getAssignToStatus(e.target.value);
  }
  if (props.assignmentType === "QUIZ") {
    return (
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <IconTitle
            title={t("quiz.quiz")}
            img="/Image/assignment/White/Quiz.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />
          <div className={styles.close_icon} onClick={props.changeEdit}>
            <img alt="" src="/Image/assignment/Cross.svg" />
          </div>
        </div>
        <div className={styles.modal_form}>
        <h3 className={styles.input_label} for="assignedToQuiz">
          <div className={styles.input_title_container}>
            {t("assignment.assignedTo")}*
          </div>
        </h3>
        <MultipleSelectChip
          isAssignmentQuiz = {true}
          assignmentClass={props?.assignmentClass}
          // onChange={(e) => {
          //   setClassName(e.target.value);
          //   props.onClassNameChange(e);
          // }}
        />
        {/* ------------- assignAll Section -------------- */}
        {/* <div className={styles.radio_group}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={hanleAssignTo}
            >
              <FormControlLabel 
                sx={{marginRight: "5px", fontFamily: "Montserrat"}} value={true} control={<Radio size="small" sx={{"&.Mui-checked": {color: "#00d498"},marginRight: "0px", fontFamily: "Montserrat"}}  />} label={t("assignment.assignToAll")} />
              <TooltipWrapper title={t("assignment.assignToAllToolipQuiz")}>
                <img src="/Image/Icons/Iicon.svg" className={styles.tooltip_icon} />
              </TooltipWrapper>

              <FormControlLabel 
                sx={{marginRight: "5px", fontFamily: "Montserrat"}} value={false} control={<Radio size="small" sx={{"&.Mui-checked": {color: "#00d498"},marginRight: "0px", fontFamily: "Montserrat"}}  />} label={t("assignment.assignToSelected")} />
              <TooltipWrapper title={t("assignment.assignToSelectedTooltipQuiz")}>
                <img src="/Image/Icons/Iicon.svg" className={styles.tooltip_icon} />
              </TooltipWrapper>
            </RadioGroup>
          </FormControl>
        </div> */}

        

        <div className={styles.max_due_container}>
          <div>
            <h3 className={styles.input_label} for="maxMarks">
              <div className={styles.input_title_container}>
                {t("assignment.maxMarks")}*
              </div>
            </h3>
            <input
              type="number"
              className={styles.input_field}
              placeholder="100"
              value={maxMarks}
              onChange={(e) => {
                props.getMaxMarks(e.target.value);
                setMaxMarks(e.target.value);
              }}
            />
          </div>
          <div className={styles.due_date_time_container}>
            <h3 className={styles.input_label} for="dueOnQuiz">
              <div className={styles.input_title_container}>
                {t("assignment.dueOn")}*
              </div>
            </h3>
            <div className={styles.date_time_container}>
              <DatePickerComponent
                assignmentDate={assignmentDate}
                getAssignmentDate={getAssignmentDate}
                isSmallHeight={window.innerHeight<700}
              />
              <TimePickerComponent
                assignmentTime={assignmentTime}
                getAssignmentTime={getAssignmentTime}
                isSmallHeight={window.innerHeight<700}
              />
            </div>
          </div>
        </div>
        <div className={styles.lock_dueDate}>
          {!lockStatus === true ? (
            <input
              type="checkbox"
              onClick={getLockStatus}
              checked
              className={styles.checkDone}
            />
          ) : (
            <input type="checkbox" onClick={getLockStatus} />
          )}
          <p>{t("assignment.lockAfterDueDate")}</p>
        </div>
        <div className={styles.assign_btn_container}>
          <Button
            type="solid"
            label={props?.isLoadingBtn ? <CircularProgress color="inherit" size={20} /> : t("assignment.assign")}
            clickHandler={submitAssignment}
            disable={props?.isLoadingBtn ? true : false}
            fullWidth={true}
          />
        </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <IconTitle
            title={t("assignment.assignment")}
            img="/Image/assignment/White/Assignment.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />
          <div className={styles.close_icon} onClick={props.changeEdit}>
            <img alt="" src="/Image/assignment/Cross.svg" />
          </div>
        </div>
        <div className={styles.modal_form}>
        <h3 className={styles.input_label} for="assignedTo">
          <div className={styles.input_title_container}>
            {t("assignment.assignedTo")}*
          </div>
        </h3>
        <MultipleSelectChip
          isAssignmentQuiz = {true}
          assignmentClass={props?.assignmentClass}
          // value={assignmentTitle}
          // onChange={(e) => {
          //   setClassName(e.target.value);
          //   props.onClassNameChange(e);
          // }}
        />

        {/* ------------- assignAll Section -------------- */}
        <div className={styles.radio_group}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={hanleAssignTo}
            >
              <FormControlLabel 
                sx={{marginRight: "5px", fontFamily: "Montserrat"}} disabled={props?.actionType === "edit"} value={true} control={<Radio size="small" sx={{"&.Mui-checked": {color: "#00d498"},marginRight: "0px", fontFamily: "Montserrat"}}  />} label={t("assignment.assignToAll")} />
              <TooltipWrapper title={t("assignment.assignToAllToolip")}>
                <img src="/Image/Icons/Iicon.svg" className={styles.tooltip_icon} />
              </TooltipWrapper>

              <FormControlLabel 
                sx={{marginRight: "5px", fontFamily: "Montserrat"}} disabled={props?.actionType === "edit"} value={false} control={<Radio size="small" sx={{"&.Mui-checked": {color: "#00d498"},marginRight: "0px", fontFamily: "Montserrat"}}  />} label={t("assignment.assignToSelected")} /> 
              
              <TooltipWrapper title={t("assignment.assignToSelectedTooltip")}>
                <img src="/Image/Icons/Iicon.svg" className={styles.tooltip_icon} />
              </TooltipWrapper>
            </RadioGroup>
          </FormControl>
        </div>

        <div className={styles.max_due_container}>
          <div>
            <h3 className={styles.input_label} for="maxMarks">
              <div className={styles.input_title_container}>
                {t("assignment.maxMarks")}*
              </div>
            </h3>
            <input
              type="number"
              className={styles.input_field}
              placeholder="100"
              // value={assignmentTitle}
              // onChange={(e) => {
              //   setClassName(e.target.value);
              //   props.onClassNameChange(e);
              // }}

              value={maxMarks}
              onChange={(e) => {
                props.getMaxMarks(e.target.value);
                setMaxMarks(e.target.value);
              }}
            />
          </div>
          <div className={styles.due_date_time_container}>
            <h3 className={styles.input_label} for="dueOn">
              <div className={styles.input_title_container}>
                {t("assignment.dueOn")}*
              </div>
            </h3>
            <div className={styles.date_time_container}>
              <DatePickerComponent
                assignmentDate={assignmentDate}
                getAssignmentDate={getAssignmentDate}
                isSmallHeight={window.innerHeight<700}
              />
              <TimePickerComponent
                assignmentTime={assignmentTime}
                getAssignmentTime={getAssignmentTime}
                isSmallHeight={window.innerHeight<700}
              />
            </div>
          </div>
        </div>
        <div className={styles.lock_dueDate}>
          {!lockStatus === true ? (
            <input
              type="checkbox"
              onClick={getLockStatus}
              checked
              className={styles.checkDone}
            />
          ) : (
            <input type="checkbox" onClick={getLockStatus} />
          )}
          <p>{t("assignment.lockAfterDueDate")}</p>
        </div>
        <div className={styles.assign_btn_container}>
          <Button
            type="solid"
            label={props.isLoadingBtn ? <CircularProgress sx={{color: "#00d498"}} size={20} /> : t("assignment.assign")}
            // clickHandler={props.changeEdit}
            clickHandler={submitAssignment}
            disable={props?.isLoadingBtn ? true : false}
            fullWidth={true}
          />
        </div>
        </div>
      </div>
    );
  }
}

export default AssignedToModal;
