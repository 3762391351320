import React from "react";
import styles from "../css/MessageBubble.module.css";

// MessageBubble Component
const MessageBubble = ({ text, time, isSender, user }) => {
    return (
        <div
            className={`d-flex my-2 mx-2 ${isSender ? "" : "flex-row-reverse"}`}
        >
            <div
                className={`${styles["conversation-image"]} rounded-pill mx-2`}
                style={{
                    backgroundImage: `url(${
                        user?.image || "Image/header/ddefault_img.svg"
                    })`,
                }}
            ></div>
            <div
                style={{
                    maxWidth: "60%",
                }}
            >
                <div
                    className={`
                        ${
                            isSender
                                ? "rounded-bottom-4 rounded-end-4"
                                : "rounded-top-4 rounded-start-4"
                        }
                    `}
                    style={{
                        backgroundColor: isSender ? "#fef7f8" : "#d71963",
                        color: isSender ? "#000" : "#fff",
                        padding: "10px 15px",
                    }}
                >
                    <p className={`${styles["conversation-text"]} fw-bold m-0`}>
                        {text}
                    </p>
                </div>
                <p
                    className={`${styles["conversation-time"]} m-0 mt-1`}
                    style={{
                        textAlign: isSender ? "left" : "right",
                    }}
                >
                    {time}
                </p>
            </div>
        </div>
    );
};

export default MessageBubble;
