import React from "react";
import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { updateCoverPic } from "../../../../../redux/actions/user_profile";
import { UPDATE_COVER_PIC } from "../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../components/commons/HandleApiError";
import { Box } from "@mui/material";

class CoverFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverPicUrl:
        this.props.coverPicUrl ||
        "/Image/apps/profile-resume-edit-view/cover-image.png",
      file: null,
      error: false,
      message: "",
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    this.setState({
      coverPicUrl: URL.createObjectURL(event.target.files[0]),
    });
    if (event.target.files[0] != null)
      this.props
        .updateCoverPic(event.target.files[0], "UPDATE_COVER_PIC")
        .then((res) => {
          if (res.val) {
            //
            return;
          }
          this.setState({ error: true, message: res.message });
        });
  }

  render() {
    return (
      <div
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          height: 180,
          borderRadius: 8,
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 180,
            overflow: "hidden",
            borderRadius: 10,
          }}
        >
          <img
            src={
              this.props.coverPicUrl
                ? this.props.coverPicUrl
                : this.props.userType === "INDIVIDUAL"
                ? "/Image/Profile/cover.svg"
                : "/Image/Business Profile/Business_design_cover.png"
            }
            className={styles.coverPicture}
          />
        </div>

        {this.props.userType === "LOCAL BUSINESS" && (
          <Box
            sx={{
              background: "#FFD221",
              color: "#000",
              fontWeight: 800,
              fontSize: "14px",
              p: "5px 16px",
              position: "absolute",
              left: 0,
              top: 20,
              fontStyle: "italic",
              borderRadius: "0px 10px 10px 0px",
            }}
          >
            Business
          </Box>
        )}

        {this.props.editpage ? (
          <div
            style={{
              padding: 8,
              backgroundColor: "#fff",
              borderRadius: 30,
              cursor: "pointer",
              height: 15,
              width: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              position: "absolute",
            }}
          >
            <label for="iconimage-cover">
              <div>
                <img
                  src="/Image/Profile/upload_img.svg"
                  alt=" "
                  style={{
                    cursor: "pointer",
                    border: "1px solid #808080",
                    borderRadius: "100%",
                    width: "35px",
                  }}
                />
              </div>
            </label>

            <input
              type="file"
              className={`${styles.inputfield_cover} ${styles.input_foc}`}
              id="iconimage-cover"
              onChange={this.handleImageChange}
              style={{ display: "none", cursor: "pointer" }}
              accept="/Image/png, /Image/jpeg"
            />
          </div>
        ) : null}

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    coverPicUrl: state.profile.userCoverPic,
    userType: state?.profile?.user?.user_type,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateCoverPic: (coverPic, action) =>
      dispatch(updateCoverPic(coverPic, action)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverFeed);
