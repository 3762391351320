// imports

// packages
import React from "react";
import desc from "./desc";
import "./Template3.css";
import AspectRatio from "react-aspect-ratio";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

// components
import TitleColorTemp3 from "./components/TitleColorTemp3";
import ProgressBarStatic from "./components/ProgressBarStatic";
import CircularProgress from "./components/CircularProgress";
import TitleIconDescComp from "./components/TitleIconDescComp";
import ExpTimelineVerticalTemp4 from "./components/ExpTimelineVerticalTemp4";
import ShareModal from "../../../Dashboard/shareModal/ShareModal";

// icons
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CreateIcon from "@material-ui/icons/Create";
import SportsIcon from "@material-ui/icons/Sports";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import HomeIcon from "@material-ui/icons/Home";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import { fetchProfileDataView } from "../../../../redux/actions/profile";
import { GET_PROFILE_DATA_VIEW } from "../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../components/commons/HandleApiError";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class Template3 extends React.Component {
  state = {
    // show: false,
    showShareModal: false,
    link: "ccy-beag-tdz",
  };
  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };
  // data
  skills = [
    {
      lang: "Music",
      progress: 90,
      color: "#6564DB",
    },
    {
      lang: "Art",
      progress: 10,
      color: "#23E6E8",
    },
    {
      lang: "Teaching",
      progress: 40,
      color: "#FFAF02",
    },
    {
      lang: "Outdoor",
      progress: 70,
      color: "#236AE8",
    },
    {
      lang: "Sports",
      progress: 50,
      color: "#fe0574",
    },
  ];

  interests = [
    {
      lang: "Music",
      color: "#062f89",
      icon: <FlightTakeoffIcon style={{ color: "#fff" }} />,
    },
    {
      lang: "Reading",
      color: "#fc790a",
      icon: <MenuBookIcon style={{ color: "#fff" }} />,
    },
    {
      lang: "Swimming",
      color: "#177df8",
      icon: <CreateIcon style={{ color: "#fff" }} />,
    },
    {
      lang: "Fishing",
      color: "#fe0574",
      icon: <SportsIcon style={{ color: "#fff" }} />,
    },
  ];
  componentDidMount() {
    this.props.fetchProfileDataView(GET_PROFILE_DATA_VIEW, JSON.parse(localStorage.userData).token, window.location.pathname.slice(9, -11));
  }

  // main render
  render() {
    const { t } =this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 50 }}>
        <div id="topviewable" style={{}}>
          <div style={{ position: "relative" }}>
            <img
              style={{
                opacity: 0.1,
                position: "absolute",
                top: "50%",
                marginLeft: "-29em",
                left: "50%",
                marginTop: "30em",
                height: "60em",
                width: "56em",
              }}
              src="/Image/apps/profile-resume-edit-view/Chalkmate_Watermark.png"
            />
          </div>
          <AspectRatio ratio="3/4" style={{ width: "82em", userSelect: "none" }}>
            <div
              style={
                {
                  // display: 'flex',
                  // alignItems: 'center',
                  // flexDirection: 'column',
                }
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "82px"
                }}
              >
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 55,
                    textTransform: "uppercase",
                    marginBottom: 10,
                    color: "#2a2a2a",
                  }}
                >
                  {this.props.nameView.split(" ")[0] || " "}
                  <br />
                  {this.props.nameView.split(" ")[1] || " "}
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 30,
                    marginTop: 10,
                    color: "#2E302F",
                    opacity: 0.7,
                  }}
                >
                  {this.props.profession}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-around",
                  paddingLeft: 400,
                  paddingRight: 400,
                }}
              >
                {/* <MailOutlineIcon width={10} height={10} style={{ color: '#fff' }} /> */}
                {/* <div style={styles.scard}>
                                    <TitleIconDescComp title={this.props.dob} desc="Date of Birth" titleSize={16} descSize={13} titleColor="#555555" descColor="#555555" src="/Image/apps/profile-resume-edit-view/dob-blue.png" iconbgcolor="#fff" iconSize={40} />
                                </div> */}
                <div style={styles.scard}>
                  <TitleIconDescComp
                    title={this.props.nationality}
                    desc={t("common.address")}
                    titleSize={16}
                    descSize={13}
                    titleColor="#555555"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/nationality-blue.png"
                    iconbgcolor="#fff"
                    iconSize={40}
                  />
                </div>
                <div style={styles.scard}>
                  <TitleIconDescComp
                    title={this.props.residencecity}
                    desc={t("common.location")}
                    titleSize={16}
                    descSize={13}
                    titleColor="#555555"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/location-blue.png"
                    iconbgcolor="#fff"
                    iconSize={40}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 50,
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <div>
                  <TitleColorTemp3 justifycontent="flex-end" bgcolor={["#fb0188", "#fb077a"]} title={t("template.profile")} dir="left" />
                  <div
                    style={{
                      display: "flex",
                      padding: "20px 0px 20px 80px",
                      minHeight: "210px",
                    }}
                  >
                    <p
                      style={{
                        color: "#555555",
                        fontWeight: 500,
                        fontSize: 15,
                      }}
                    >
                      {this.props.about}
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <TitleColorTemp3 justifycontent="flex-end" bgcolor={["#ffa204", "#fb8418"]} title={t("profile.education")} dir="left" />
                  <div
                    style={{
                      padding: "20px 0px 20px 80px",
                      minHeight: "425px",
                    }}
                  >
                    <ExpTimelineVerticalTemp4
                      data={
                        this.props.educationWork.length != 0
                          ? this.props.educationWork
                          : [
                              {
                                degree: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                field_of_study: t("template.noInfoAvailable"),
                                participations: t("template.noInfoAvailable"),
                                school: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                              },
                              {
                                degree: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                field_of_study: t("template.noInfoAvailable"),
                                participations: t("template.noInfoAvailable"),
                                school: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                              },
                              {
                                degree: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                field_of_study: t("template.noInfoAvailable"),
                                participations: t("template.noInfoAvailable"),
                                school: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                              },
                            ]
                      }
                      durationColor="#FE7D05"
                      durationWeight={600}
                    />
                  </div>
                </div>
                <br />
                {/* <br /> */}
                <div>
                  <TitleColorTemp3 justifycontent="flex-end" bgcolor={["#17aaff", "#1b87f1"]} title={t("profile.skills")} dir="left" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 0px 20px 80px",
                    }}
                  >
                    <br />
                    {this.props.skillsView.length != 0
                      ? this.props.skillsView.slice(0, 5).map((item, index) => {
                          return <CircularProgress width={80} title={item.title} progress={"100"} fg={"#6564DB"} weight={500} />;
                        })
                      : [t("template.noInfo"), t("template.noInfo"), t("template.noInfo"), t("template.noInfo")].slice(0, 5).map((item, index) => {
                          return <CircularProgress width={'max-content'} title={item} progress={"100"} fg={"#6564DB"} weight={500} />;
                        })}
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  padding: "50px 0px 100px 0px",
                  margin: "0px 30px 0px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <div
                      style={{
                        marginTop: 100,
                        borderRadius: 200,
                        padding: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 0px 20px 20px #f0f0f0",
                      }}
                    >
                      <img 
                        // crossOrigin="" 
                        src={this.props.dp} style={{ width: 220, height: 220, boxShadow: "0px 0px 10px 10px #55555529", borderRadius: 200 }} />

                      <div style={{ position: "relative" }}>
                        <img
                          src={"/Image/apps/profile-resume-edit-view/profile-line.png"}
                          style={{ position: "absolute", width: 550, zIndex: -1, left: -690, top: -320 }}
                          id="lines"
                        />
                        <img
                          src={"/Image/apps/profile-resume-edit-view/contacts-line.png"}
                          style={{ position: "absolute", width: 180, left: -54, top: -250, zIndex: -1 }}
                          id="lines"
                        />
                        <img
                          src={"/Image/apps/profile-resume-edit-view/education-line.png"}
                          style={{ position: "absolute", width: 500, left: -600, top: -20, zIndex: -1 }}
                          id="lines"
                        />
                        <img
                          src={"/Image/apps/profile-resume-edit-view/skills-line.png"}
                          style={{ position: "absolute", width: 570, left: -690, top: -30, zIndex: -1 }}
                          id="lines"
                        />
                        <img
                          src={"/Image/apps/profile-resume-edit-view/languages-line.png"}
                          style={{ position: "absolute", width: 600, left: -320, top: -10, zIndex: -1 }}
                          id="lines"
                        />
                        <img
                          src={"/Image/apps/profile-resume-edit-view/experience-line.png"}
                          style={{ position: "absolute", width: 170, left: -60, top: -40, zIndex: -1 }}
                          id="lines"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <img src={"/Image/apps/profile-resume-edit-view/qrcode.png"} style={{ height: 180 }} /> */}
                  <QRCode
                    value={`${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`}
                    id="qrCodeEl"
                    size={111}
                    style={{ height: "180px", width: "180px" }}
                  />
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                }}
              >
                <div>
                  <TitleColorTemp3 justifycontent="flex-start" bgcolor={["#133daa", "#023085"]} title={t("template.contact")} dir="right" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 20,
                      fontSize: 15,
                      minHeight: "210px",
                    }}
                  >
                    <br/>
                    {/* <div style={{ display: "flex", color: "#555555", textTransform: "none", fontWeight: 500, marginBottom: 10 }}>
                      <img src="/Image/apps/profile-resume-edit-view/phone-temp3.png" style={{ height: 35 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.phone}</p>
                    </div> */}
                    <div style={{ display: "flex", color: "#555555", textTransform: "none", fontWeight: 500, marginBottom: 10 }}>
                      <img src="/Image/apps/profile-resume-edit-view/mail-temp3.png" style={{ height: 35 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.email}</p>
                    </div>
                    <div style={{ display: "flex", color: "#555555", textTransform: "none", fontWeight: 500, marginBottom: 10 }}>
                      <img src="/Image/apps/profile-resume-edit-view/website-temp3.png" style={{ height: 35 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.website}</p>
                    </div>
                    <div style={{ display: "flex", color: "#555555", textTransform: "none", fontWeight: 500 }}>
                      <img src="/Image/apps/profile-resume-edit-view/location-temp3.png" style={{ height: 35 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.nationality + ", " + this.props.residencecity}</p>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div>
                  <TitleColorTemp3 justifycontent="flex-start" bgcolor={["#17aaff", "#1b87f1"]} title={t("profile.experience")} dir="right" />
                  <div
                    style={{
                      padding: "20px 0px 20px 30px",
                      minHeight: "445px",
                    }}
                  >
                    <ExpTimelineVerticalTemp4
                      data={
                        this.props.experience != 0
                          ? this.props.experience
                          : [
                              {
                                company: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                employment_type: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                                title: t("template.noInfoAvailable"),
                              },
                              {
                                company: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                employment_type: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                                title: t("template.noInfoAvailable"),
                              },
                              {
                                company: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                employment_type: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                                title: t("template.noInfoAvailable"),
                              },
                            ]
                      }
                      durationColor="#FE7D05"
                      durationWeight={600}
                    />
                  </div>
                </div>
                <br />
                {/* <br /> */}
                <div>
                  <TitleColorTemp3 justifycontent="flex-start" bgcolor={["#feab11", "#fc8c00"]} title={t("profile.interests")} dir="right" />
                  <br />
                  <div
                    style={{
                      padding: "20px 0px 20px 30px",
                    }}
                  >
                    <div style={styles.lcard}>
                      {this.props.interests != 0
                        ? this.props.interests.slice(0, 5).map((lval) => {
                            return <ProgressBarStatic width={80} fg="#FFAF02" progress={"100"} title={lval.title} bgcolor="#E5E7E7" weight={500} />;
                          })
                        : [t("template.noInfo"), t("template.noInfo"), t("template.noInfo"), t("template.noInfo")].map((lval) => {
                            return <ProgressBarStatic width={"unset"} fg="#FFAF02" progress={"100"} title={lval} bgcolor="#E5E7E7" weight={500} />;
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AspectRatio>
        </div>

        <div style={{ margin: "30px 0px 100px 0px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <div
            onClick={() => {
              document.body.style.maxWidth = "85em";
              const input = document.getElementById("topviewable");

              html2canvas(input, {
                y: 0,
                allowTaint : true,
                useCORS: true,
                logging: true, 
              }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "px", "a4", true);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                pdf.addImage(imgData, "JPEG", 0, 0, width, height);
                pdf.save("resume.pdf");
                // window.open(pdf.output('datauristring'))
                document.body.style.maxWidth = "100%";
              });
            }}
          >
            <div id="btn-ftr" style={styles.footerbtn}>
              <p style={styles.btntitle}>{t("template.printThisResume")}</p>
            </div>
          </div>
          <div onClick={this.shareModalOpener}>
            <div id="btn-ftr" style={styles.footerbtn}>
              <p style={styles.btntitle}>{t("template.shareThisResume")}</p>
            </div>
          </div>
          <div>
            <Link to={`/profile/${this.props.profile_id}/view`} style={{ textDecoration: "none" }}>
              <div id="btn-ftr" style={styles.footerbtn}>
                <p style={styles.btntitle}>{t("template.viewDetailedResume")}</p>
              </div>
            </Link>
          </div>
        </div>
        <ShareModal closeModal={this.shareModalCloser} show={this.state.showShareModal} shareLink={window.location.href} />
      </div>
    );
  }
}
function mapStateToProps(state,props) {
  const {t} = props;
  return {
    nameView: state?.profile?.nameView || "",
    // lname: state?.profile?.nameView?.nameView.split(" ")[1] || "",
    website: window.location.href.replace("template/1", "") || "www.chalkmates.com",
    profession: state?.profile?.designationView || t("template.noInfoAvailable"),
    dob: state?.profile?.dobView || t("template.noInfoAvailable"),
    nationality: state?.profile?.addressView || t("template.noInfoAvailable"),
    residencecity: state?.profile?.locationView || t("template.noInfoAvailable"),
    residencestate: t("template.noInfoAvailable"),
    email: state?.profile?.user_email || t("template.noInfoAvailable"),
    dp: state?.profile?.userProfilePicView || "/Image/apps/profile-resume-edit-view/default-user.png",
    about: state?.profile?.aboutView || t("template.noInfoAvailable"),
    languages: state?.profile?.language_knownView || t("template.noInfoAvailable"),
    interests: state?.profile?.interestsView || [],
    skillsView: state?.profile?.skillsView || [],
    educationWork: state?.profile?.educationView || [
      {
        degree: t("template.noInfoAvailable"),
        description: t("template.noInfoAvailable"),
        end_date: t("template.noInfoAvailable"),
        field_of_study: t("template.noInfoAvailable"),
        participations: t("template.noInfoAvailable"),
        school: t("template.noInfoAvailable"),
        start_date: t("template.noInfoAvailable"),
      },
    ],
    experience: state?.profile?.experienceView || [
      {
        company: "DataPitcher",
        description: "",
        employment_type: "Internship",
        end_date: "2021-04-15",
        start_date: "2020-12-15",
        title: "Angular Developer",
      },
    ],
    profile_id: state?.profile?.profileIdView || "",

    // nameView: state?.profile?.user?.first_name || "Write a short bio about yourself.",
    certificationView: state?.profile?.certificationView || [],
    volunteerView: state?.profile?.volunteerView || [],
    achievementView: state?.profile?.achievementView || [],
    organisationView: state?.profile?.organisationView || t("template.noInfoAvailable"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
  };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Template3));

const styles = {
  scard: {
    borderRadius: 10,
    padding: "10px 10px 10px 20px",
  },

  vwbtnftr: {
    display: "flex",
    flexDirection: "row",
  },

  footerbtn: {
    borderRadius: 30,
    border: "2px solid #00d498",
    margin: "10px 60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"10px"
  },

  btntitle: {
    padding: "3px 40px 3px 40px",
    fontSize: 10,
    fontWeight: 600,
  },
};
