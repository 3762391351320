import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default class HandleApiError extends React.Component {
  render() {
    return (
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={this.props.error} onClose={this.props.onClose}>
        <MuiAlert onClose={this.props.onClose} severity="error" elevation={6} variant="filled">
          {this.props.message}
        </MuiAlert>
      </Snackbar>
    );
  }
}
