import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function addSocialLinks(data, action) {
  return async (dispatch) => {
    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/sociallinks/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: res.data.data,
          });
          dispatch(handleSuccessMessage("Link added successfully"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateSocialLinks(data, action, id) {
  return async (dispatch) => {
    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/sociallinks/${id}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status=="1") {
        let value = Object.fromEntries(data.entries());
        value = {...value, id: id}
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated successfully"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
