import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./css/IndividualComment.module.css";
import { CircularProgress } from "@mui/material";
import { formatDate } from "../../redux/constants/commonFunctions";

class IndividualComment extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return this.props.isCommentLoading ? (
      <div className={styles.loader_style}>
        <CircularProgress size={20} />
      </div>
    ) : (
      <div className={styles.individual_comment_div}>
        <img
          src={this.props?.profilePic || "/Image/DashImage/profile.png"}
          alt="user_profile"
        />
        <div className={styles.indiviudal_comment_content}>
          <div className={styles.indiviudal_comment_content_flex}>
            <h5>{this.props.senderName}</h5>
            <p>
              {formatDate(this.props.time)}
            </p>
          </div>
          <p>{this.props.comment}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    // profile_pic: state?.profile?.userProfilePic,
    // first_name: state?.profile?.user?.first_name,
    // last_name: state?.profile?.user?.last_name,
  };
};

export default connect(mapStateToProps, null)(IndividualComment);
