import React, { createContext } from "react";

export const HeaderContext = createContext();

export class HeaderProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editpage: false,
      searchpage: false,
      searchText: 1,
    };

    this.setEditPage = (e) => {
      this.setState({ editpage: e });
    };

    this.setSearchPage = (e) => {
      this.setState({ searchpage: e });
    };

    this.setSearchText = (e) => {
      this.setState({ searchText: e.length });
    };
  }

  render() {
    return <HeaderContext.Provider value={[this.state, this.setEditPage, this.setSearchPage, this.setSearchText.bind(this)]}>{this.props.children}</HeaderContext.Provider>;
  }
}
