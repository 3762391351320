import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Document, Page, pdfjs } from "react-pdf";
import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import ReactPlayer from "react-player";
import cloneDeep from "lodash.clonedeep";
import styles from "./css/CourseStreamPage.module.css";
import CourseHeader from "./Components/CourseHeader";
import ContentDrawer from "./Components/ContentDrawer";
import { temp_course_content } from "../AssignmentAndQuiz/temp_data";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";
import {
  getCourseDetails,
  markAsCompleted,
  getCourseProgress,
} from "../../redux/actions/courses";
import Modal from "../../components/commons/Modal";
import WelcomeToCourseModal from "./Modals/WelcomeToCourseModal";

// required for react-pdf
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const CourseStreamPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentLecture, setCurrentLecture] = useState({});
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  
  const isMobileSmallScr  = useMediaQuery('(max-width:320px)');
  const isMobileMidScr    = useMediaQuery('(min-width:321px) and (max-width: 375px)');
  const isMobileLargeScr  = useMediaQuery('(min-width:376px) and (max-width: 425px)');
  const isTabletSmallScr  = useMediaQuery('(min-width: 425px) and (max-width: 550px)');
  const isTabletMidScr    = useMediaQuery('(min-width: 551px) and (max-width: 700px)');
  const isTabletLargeScr  = useMediaQuery('(min-width: 701px) and (max-width: 950px)');
  const isDesktop         = useMediaQuery('(min-width: 1001px)');

  /* To track if the user is attempting the course for the first time,
   * this would help us show the congratsModal only when the user reaches 100% progress for the first time.
   * Therefore preventing the congratsModal to show up the next time when user revisits the course after
   *  already finishing it earlier.
   */
  const [isFirstAttempt, setIsFirstAttempt] = useState(false);
  const [videoTimeStamp, setVideoTimeStamp] = useState(false);

  const currentCourse =
    useSelector((state) => state?.courses?.currentCourse) ?? false;

  useEffect(() => {
    if (!currentCourse) {
      dispatch(getCourseDetails(params.id)).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  /* This useEffect will run setCurrentLecture() only once,
   * because the first time when lectureFinishedHandler() runs,
   * it will set isFirstAttempt to true, therefore preventing this useEffect from
   * resetting the currentLecture to lecture[0] once again.
   * (And we want currentLecture to be lecture[0] only when the course loads.)
   */
  useEffect(() => {
    if (!isFirstAttempt) {
      setCurrentLecture(currentCourse?.section?.[0].lectures?.[0]);
    }
  }, [currentCourse]);

  useEffect(() => {
    if (isFirstAttempt && currentCourse?.progress_bar == 100) {
      setOpen(false);
      setShowCongratsModal(true);
    }
  }, [currentCourse]);

  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // };

  const isEnrolled =
    useSelector((state) => state?.courses?.currentCourse?.is_enrolled) ?? false;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleContentSelection = (lecture, sectionIndex, lectureIndex) => {
    // let data = { ...lecture };
    let data = cloneDeep(lecture);
    data.sectionIndex = sectionIndex;
    data.lectureIndex = lectureIndex;
    setCurrentLecture(data);
    setVideoTimeStamp(false);
    if(isMobileSmallScr || isMobileMidScr || isMobileLargeScr || isTabletSmallScr || isTabletMidScr || isTabletLargeScr) {
      handleDrawerClose();
    }
  };

  const lectureFinishedHandler = () => {
    // console.log('inside lectureFinishedHandler')
    if (!currentLecture?.completed) {
      setIsFirstAttempt(true);
      dispatch(
        markAsCompleted(
          currentLecture?.id,
          currentLecture?.sectionIndex,
          currentLecture?.lectureIndex
        )
      )
        // .then(() => dispatch(getCourseProgress(params.id)))
        .then(() => {
          setCurrentLecture({
            ...currentLecture,
            completed: true,
          });
        });
    }
  };
  

  
  let iframeWidth, iframeHeight, videoWidth, videoHeight;
  
  if (isMobileSmallScr) {
    iframeWidth  = 320;
    iframeHeight = 450;
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else if (isMobileMidScr) {
    iframeWidth  = 320; 
    iframeHeight = 500; 
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else if (isMobileLargeScr) {
    iframeWidth  = 320; 
    iframeHeight = 650; 
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else if (isTabletSmallScr) {
    iframeWidth  = 500;
    iframeHeight = 500; 
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else if (isTabletMidScr) {
    iframeWidth  = 750; 
    iframeHeight = 750; 
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else if (isTabletLargeScr) {
    iframeWidth  = 750;
    iframeHeight = 750; 
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else if (isDesktop) {
    iframeWidth  = 1300;
    iframeHeight = 700;
    videoWidth   = "100%";
    videoHeight  = "80vh";
  } else {
    iframeWidth  = 1300;
    iframeHeight = 700;
    videoWidth   = "100%";
    videoHeight  = "80vh";
  }
  

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#00d498" }} size={50} />
      </div>
    );
  } else if (!isEnrolled || currentCourse?.section?.length === 0) {
    return <ErrorFourZeroFour />;
  } else {
    return (
      <div className={styles.main}>
        {showCongratsModal && (
          <Modal
            card={
              <WelcomeToCourseModal
                congrats={true}
                closeModal={() => setShowCongratsModal(false)}
              />
            }
            onOutsideClick={() => setShowCongratsModal(false)}
          />
        )}
        <CourseHeader
          title={currentCourse?.name}
          studentName={currentCourse?.user_profile?.name}
          profilePic={currentCourse?.user_profile?.profile_pic}
          progress={currentCourse?.progress_bar}
        />
        <ContentDrawer
          sections={currentCourse?.section}
          isOpen={open}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
          onSelection={handleContentSelection}
        />
        <div
          className={!open ? styles.container_full : styles.container_shrunk}
        >
          {currentLecture?.fileType == "video" && (
            <ReactPlayer
              className={styles.react_player}
              config={{ file: { attributes: { controlsList: "nodownload" } } }} // Disable download button
              onContextMenu={(e) => e.preventDefault()} // Disable right click
              width={videoWidth}
              height={videoHeight}
              url={currentLecture?.fileLink ?? currentLecture?.url}
              onEnded={lectureFinishedHandler}
              onProgress={(prog) => {
                // console.log("insideArrow", prog.played.toFixed(1));
                if (prog.played.toFixed(1) == 0.9 && !videoTimeStamp) {
                  setVideoTimeStamp(true);
                  lectureFinishedHandler();
                }
              }}
              controls
              playing
              muted
            />
          )}
          {console.log("currentLecture", currentLecture)}
          {/* --------- for documents ------------ */}
          {/* <Document
            file="https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"
            onLoadSuccess={() => console.log("PDF Loaded")}
          >
            <Page pageNumber={1} scale={1.2} />
          </Document> */}

          {currentLecture?.fileType == "pdf" && (
            <div className={styles.document_wrapper}>
              <iframe
                src={
                  `${currentLecture?.fileLink ?? currentLecture?.url}` +
                  "#toolbar=0"
                }
                width={iframeWidth}
                height={iframeHeight}
              />
              <Button
                type="solid"
                label={t("courseMonetisation.markComplete")}
                clickHandler={lectureFinishedHandler}
                disable={currentLecture?.completed}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default CourseStreamPage;
