import { Box, Chip, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../Profile.module.css";
import AddBusinessOffering from "./Modals/AddBusinessOffering";
import { useSelector } from "react-redux";

const OfferingFeed = ({ editPage }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const businessWebUrl = useSelector((state) => state.profile.business_web_url);
  const businessDelivery = useSelector((state) => state.profile.delivery);
  const businessStatus = useSelector((state) => state.profile.status);
  const businessTakeout = useSelector((state) => state.profile.take_away);
  const businessOpeningHours = useSelector(
    (state) => state.profile.opening_hours
  );
  const businessWebUrlView = useSelector(
    (state) => state.profile.business_web_url_view
  );
  const businessDeliveryView = useSelector(
    (state) => state.profile.delivery_view
  );
  const businessStatusView = useSelector((state) => state.profile.status_view);
  const businessTakeoutView = useSelector(
    (state) => state.profile.take_away_view
  );
  const businessOpeningHoursView = useSelector(
    (state) => state.profile.opening_hours_view
  );

  return (
    <>
      <AddBusinessOffering edit={edit} setEdit={setEdit} />
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 20px 0px 0px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
            <img
              src="/Image/Business Profile/business_offering.svg"
              alt="about"
              style={{ width: "20px" }}
            />
            <Typography
              sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
            >
              {t("profile.businessOffering")}
            </Typography>
          </Box>
          {editPage && (
            <div onClick={() => setEdit(true)}>
              <img
                src="/Image/Profile/edit.svg"
                style={{ height: 20, cursor: "pointer" }}
              />
            </div>
          )}
        </div>

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "15px 20px",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: "#606060" }}>
            URL -{" "}
            <Link sx={{ color: "#4773B9" }} href="#" underline="none">
              {editPage ? businessWebUrl : businessWebUrlView}
            </Link>
          </Typography>
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <img
              src={
                editPage
                  ? businessTakeout === "True"
                    ? "/Image/Business Profile/tick.svg"
                    : "/Image/Business Profile/cross.svg"
                  : businessTakeoutView === "True"
                  ? "/Image/Business Profile/tick.svg"
                  : "/Image/Business Profile/cross.svg"
              }
              alt="cross"
              style={{ width: "17px" }}
            />
            <Typography>Takeout Available</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <img
              src={
                editPage
                  ? businessDelivery === "True"
                    ? "/Image/Business Profile/tick.svg"
                    : "/Image/Business Profile/cross.svg"
                  : businessDeliveryView === "True"
                  ? "/Image/Business Profile/tick.svg"
                  : "/Image/Business Profile/cross.svg"
              }
              alt="tick"
              style={{ width: "17px" }}
            />
            <Typography>Delivery Available</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1, mt: 1, alignItems: "center" }}>
            <Typography>Business status -</Typography>
            <Typography
              sx={{
                background: "#0f9a49",
                color: "#fff",
                padding: "0.5px 10px",
                borderRadius: "17px",
                fontSize: "14px",
              }}
            >
              {editPage
                ? businessStatus === "OPEN"
                  ? "Open"
                  : "Closed"
                : businessStatusView === "OPEN"
                ? "Open"
                : "Closed"}
            </Typography>
          </Box>
          <Typography sx={{ color: "#000", mt: 1 }}>
            Opening Hours -{" "}
            {editPage ? businessOpeningHours : businessOpeningHoursView}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default OfferingFeed;
