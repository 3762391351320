import React, { Component } from "react";
import { ModalWithHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import CourseMiddleContainer from "./CourseMiddleContainer";
import AgendaMiddleContainer from "./AgendaMiddleContainer";
import { GroupFeedsContext } from "./GroupFeedsContext";
import { withTranslation } from "react-i18next";

class CourseModal extends Component {
  state = {
    agenda_title: this.props.agenda_title || "",
    agenda_description: this.props.agenda_description || "",
    agenda_link: this.props.agenda_link || "",
    image_url: this.props.image_url || "",
    video_url: this.props.video_url || "",
    image_file: null,
    video_file: null,
    post_owner_profile: "",
    visibility_type: "GROUP",
    post_type: "AGENDA",
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.state.post_owner_profile = user?.profile_id;
  }
  updateTitle = (e) => {
    this.setState({ agenda_title: e.target.value });
  };
  updateMessage = (e) => {
    this.setState({ agenda_description: e.target.value });
  };
  updateLink = (e) => {
    this.setState({ agenda_link: e.target.value });
  };
  onPicInput = (e) => {
    this.setState({
      image_file: e.target.files[0],
    });
  };
  onVideoInput = (e) => {
    this.setState({
      video_file: e.target.files[0],
    });
  };
  onPost = () => {
    if (this.props.postId !== undefined) {
      this.state.postId = this.props.postId;
      this.context.updateAgendaPost(this.state);
      this.props.closeModal();
      return;
    }
    this.context.addAgendaPost(this.state);
    this.clearState();
    this.props.closeModal();
  };

  clearState = () => {
    if (this.props.postId !== undefined) return;
    this.setState({
      agenda_title: "",
      agenda_description: "",
      agenda_link: "",
      image_url: "",
      video_url: "",
      post_owner_profile: "",
      image_file: null,
      video_file: null,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <ModalWithHeader
          title={this.props.title}
          show={this.props.show}
          closeHandler={this.props.closeModal}
          clearState={this.clearState}
          maxWidth="605px"
          minWidth="350px"
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "0 0 15px 15px",
              overflowY: "hidden",
            }}
          >
            {this.props.title == t("classGroup.addAgenda") ? (
              <AgendaMiddleContainer
                value={4}
                onPost={this.onPost}
                updateTitle={this.updateTitle}
                updateLink={this.updateLink}
                updateMessage={this.updateMessage}
                onPicInput={this.onPicInput}
                onVideoInput={this.onVideoInput}
                post={this.state}
              />
            ) : (
              <CourseMiddleContainer
                getActiveClassDetails={this.props.getActiveClassDetails}
                closeModal={this.props.closeModal}
              />
            )}
          </div>
        </ModalWithHeader>
      </div>
    );
  }
}
CourseModal.contextType = GroupFeedsContext;
export default withTranslation()(CourseModal);
