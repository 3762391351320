import { React, Component } from "react";
import styles from "../../Dashboard/Components/CSSFiles/Feed.module.css";
import PostGroup from "./PostGroup";
import PostOfGroup from "./PostOfGroup";
import { connect } from "react-redux";
import { CircularProgress, Divider } from "@mui/material";
import { GroupFeedsContext } from "./GroupFeedsContext";
import { getGroupPosts } from "../../../redux/actions/groups";
import { store } from "../../../redux/store";

class GroupFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSelectedComment: [],
      sharedPostId: null,
    };
  }

  handleOpenSelectedComment = (id) => {
    this.setState((prevState) => ({
      openSelectedComment: [...prevState.openSelectedComment, id],
    }));
  };

  handleSharedPostId = (id) => {
    this.setState({ sharedPostId: id });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
    const idOfPost = this.props?.match?.params?.idOfPost;

    this.handleSharedPostId(idOfPost);
    if (this.props.routeType == "Group") {
      getGroupPosts(this.props.group_id);
    }
  }

  componentDidUpdate() {
    if (this.props.routeType == "Group") {
      getGroupPosts(this.props.group_id);
    }
  }

  getGroupPost() {
    getGroupPosts(store?.getState()?.groups?.group?.id);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    if (
      (!this.context.state.loading && window.innerHeight + window.scrollY) >=
      document.body.offsetHeight - 20
    ) {
      this.context.onPaginatedSearch();
    }
  };

  render() {
    localStorage.setItem("source", "GROUP");
    return (
      <div className={styles.feed}>
        <PostGroup />
        {/* <GetStarted /> */}
        <Divider sx={{ mb: 2, background: "rgba(0,0,0,0.08)" }} />
        {this?.context?.state?.posts.map((post, index) => (
          <div key={index}>
            {this.state.sharedPostId === post.share_link ? (
              <PostOfGroup
                getGroupPost={this.getGroupPost}
                group_id={this.props.group_id}
                post_owner_id={post?.post_owner_profile?.id}
                name={post?.post_owner_profile?.name}
                follower={post?.post_owner_profile?.connections}
                timestamp={post?.timestamp}
                message={
                  post?.repost_of
                    ? post?.original_post_details?.description
                    : post?.description
                }
                agenda_link={post.post_details?.agenda_link}
                agenda_title={post.post_details?.agenda_title}
                agenda_description={post?.post_details?.agenda_description}
                // tag = {post.tag}
                profileImage={
                  post?.post_owner_profile?.profile_pic
                    ? post?.post_owner_profile?.profile_pic
                    : post?.post_owner_profile?.avatarImg
                    ? post?.post_owner_profile?.avatarImg
                    : "/Image/header/ddefault_img.svg"
                }
                photoUrl={
                  post?.repost_of
                    ? post?.original_post_details?.image_url
                    : post?.image_url
                }
                video={
                  post?.repost_of
                    ? post?.original_post_details?.video_url
                    : post?.video_url
                }
                likes={post.likes}
                likeDetails={post.reactions}
                shareLink={post.share_link}
                file_url={
                  post?.repost_of
                    ? post?.original_post_details?.file_url
                    : post.file_url
                }
                tags={post.tags}
                postId={post.id}
                comments={post.comments}
                shareModalOpener={() =>
                  this.props.shareModalOpener(post.share_link)
                }
                menu={
                  post?.post_owner_profile?.id == this.props?.userId
                    ? true
                    : false
                }
                postData={post}
                source="DASHBOARD"
                postLanguage={post?.language_code}
                handleOpenSelectedComment={this.handleOpenSelectedComment}
                openSelectedComment={this.state.openSelectedComment}
                post_details={
                  post?.repost_of
                    ? post?.original_post_details?.post_details
                    : post?.post_details
                }
                postType={post?.post_type}
                sellImages={post?.sell_images}
                pollOptions={post?.poll_options}
                pollVotes={post?.poll_votes}
                original_post_owner={
                  post?.original_post_details?.post_details?.post_owner_profile
                }
                user_type={post?.post_owner_profile?.user_details?.user_type}
              />
            ) : null}
          </div>
        ))}
        {this?.context?.state?.posts.length !== 0 ? (
          this?.context?.state?.posts.map((post, index) => (
            <div key={index}>
              {post?.visibility_type == "GROUP" && (
                <div>
                  <PostOfGroup
                    getGroupPost={this.getGroupPost}
                    group_id={this.props.group_id}
                    post_owner_id={post?.post_owner_profile?.id}
                    name={post?.post_owner_profile?.name}
                    follower={post?.post_owner_profile?.connections}
                    timestamp={post?.timestamp}
                    message={
                      post?.repost_of
                        ? post?.original_post_details?.description
                        : post?.description
                    }
                    // tag = {post.tag}
                    agenda_link={post.post_details?.agenda_link}
                    agenda_title={post.post_details?.agenda_title}
                    agenda_description={post?.post_details?.agenda_description}
                    profileImage={
                      post?.post_owner_profile?.profile_pic
                        ? post?.post_owner_profile?.profile_pic
                        : post?.post_owner_profile?.avatarImg
                        ? post?.post_owner_profile?.avatarImg
                        : "/Image/header/ddefault_img.svg"
                    }
                    photoUrl={
                      post?.repost_of
                        ? post?.original_post_details?.image_url
                        : post?.image_url
                    }
                    video={
                      post?.repost_of
                        ? post?.original_post_details?.video_url
                        : post?.video_url
                    }
                    likes={post.likes}
                    likeDetails={post.reactions}
                    shareLink={post.share_link}
                    file_url={
                      post?.repost_of
                        ? post?.original_post_details?.file_url
                        : post.file_url
                    }
                    tags={post.tags}
                    postId={post.id}
                    comments={post.comments}
                    shareModalOpener={() =>
                      this.props.shareModalOpener(post.share_link)
                    }
                    menu={
                      post?.post_owner_profile?.id == this.props?.userId
                        ? true
                        : false
                    }
                    postData={post}
                    source="DASHBOARD"
                    postLanguage={post?.language_code}
                    handleOpenSelectedComment={this.handleOpenSelectedComment}
                    openSelectedComment={this.state.openSelectedComment}
                    post_details={
                      post?.repost_of
                        ? post?.original_post_details?.post_details
                        : post?.post_details
                    }
                    postType={post?.post_type}
                    sellImages={post?.sell_images}
                    pollOptions={post?.poll_options}
                    pollVotes={post?.poll_votes}
                    original_post_owner={
                      post?.original_post_details?.post_details
                        ?.post_owner_profile
                    }
                    user_type={
                      post?.post_owner_profile?.user_details?.user_type
                    }
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <h4 style={{ textAlign: "center" }}>No posts to show!</h4>
        )}
        {this?.context?.state?.loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : null}
        {this?.context?.state?.nextLink === null &&
        !this?.context?.state?.firstLoad ? (
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <h4>No More posts to show</h4>
          </div>
        ) : null}
      </div>
    );
  }
}

GroupFeed.contextType = GroupFeedsContext;

const mapStateToProps = (state) => {
  return {
    userId: state?.profile?.profileId,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getGroupPosts: (groupId) => dispatch(getGroupPosts(groupId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupFeed);
