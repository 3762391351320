import React from "react";
import Modal from "../../../../../components/commons/Modal";
import AddLanguage from "./Modals/AddLanguage";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

class Languages extends React.Component {
  state = {
    edit: false,
  };

  changeEdit(edit) {
    this.setState({ edit: edit });
  }
  randomColor = Math.floor(Math.random() * 16777215).toString(16);
  bgcolor = [
    "#9C27B0",
    "#00D498",
    "#FFC107",
    "#0C1B7A",
    "#6564db",
    "#23e6e8",
    "#236ae8",
    "#ffaf02",
    "#fd0275",
  ];

  render() {
    const { t } = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "10px 20px",
            borderRadius: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <img
                src="/Image/Profile/language.svg"
                alt="language"
                width="20px"
              />
              <Typography sx={{ color: "#606060", fontSize: "17px" }}>
                {t("profile.languages")}
              </Typography>
            </Box>
            <div
              onClick={() => {
                this.setState({ edit: true });
                document.body.style.overflow = "hidden";
              }}
            >
              <img
                src="/Image/Profile/edit.svg"
                style={{ height: 17, cursor: "pointer" }}
              />
            </div>
          </Box>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.langContent?.split(",").map((item, idx) => {
                return (
                  <>
                    {item != "" ? (
                      <li key={idx} className={styles.langList}>
                        <hr
                          className={styles.langHr}
                          style={{
                            backgroundColor: this.bgcolor[idx],
                            borderColor: this.bgcolor[idx],
                          }}
                        />
                        {item}
                      </li>
                    ) : null}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
        {this.state.edit ? (
          <Modal
            card={
              <AddLanguage
                title={t("profile.editLanguages")}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    langContent: state?.profile?.language || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Languages));
