import React from "react";

import Modal from "../../../../../components/commons/Modal";
import UpdateInstituteCode from "./Modals/UpdateInstituteCode";

import styles from "../../../Profile.module.css";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class InstituteCode extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      edit: false,
      institueCode: t("profile.typeIstituteCode"),

      text: "",
    };

    this.changeText = this.changeText.bind(this);
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeText(text) {
    this.setState({ text: text });
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              fontSize: 14,
              margin: 0,
              color: "var(--secondary-main)",
              lineHeight: "24px",
            }}
          >
            {t("profile.instituteCode")}: {this.props.deptCode}
          </p>

          <div
            onClick={() => {
              this.setState({ edit: true });
              document.body.style.overflow = "hidden";
            }}
          >
            <img
              src="/Image/Chalkmate_Edit.svg"
              style={{ height: 20, cursor: "pointer" }}
            />
          </div>
        </div>
        {this.state.edit ? (
          <Modal
            card={
              <UpdateInstituteCode
                modalTitle={t("profile.instituteCode")}
                changeEdit={this.changeEdit.bind(this)}
                changeText={this.changeText.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    deptCode: state?.profile?.deptCode || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(InstituteCode));
