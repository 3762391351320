import React, { Component } from "react";
import styles from "./css/ChatSmall.module.css";

export default class ChatSmall extends Component {
    render() {
        const {
            image = "/Image/header/ddefault_img.svg",
            name,
            lastMessage,
            lastMessageTime,
            id,
            openChat,
            userId,
        } = this.props;

        return (
            <div onClick={() => openChat(id)} className={styles.container}>
                <div
                    className={
                        this.props.active
                            ? styles.innerContainerClicked
                            : styles.innerContainer
                    }
                >
                    <div className={styles.profilePicContainer}>
                        <img
                            className={styles.profilePic}
                            src={image}
                            alt="profile"
                        />
                    </div>
                    <div className={styles.nameContainer}>
                        <div className={styles.innerNameContainer}>
                            <span className={styles.name}>{name}</span>
                            <span className={styles.lastMessage}>
                                {lastMessage}
                            </span>
                        </div>
                        <div className={styles.dateContainer}>
                            {lastMessageTime}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
