import React from "react";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import {
  UPDATE_SOCIAL_LINKS,
  ADD_SOCIAL_LINKS,
} from "../../../../../../redux/constants/actionTypes";
import {
  addSocialLinks,
  updateSocialLinks,
} from "../../../../../../redux/actions/social";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

class EditSocial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      facebook: this.props.facebook,
      linkedin: this.props.linkedin,
      twitter: this.props.twitter,
      instagaram: this.props.instagaram,
      medium: this.props.medium,
      socialLinks: this.props.socialLinks,
      // socialLinksId: this.props.socialLinks.socialLinksId,

      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    let data = new FormData();
    data.append(
      "profile_id",
      JSON.parse(localStorage.getItem("userData")).profile_id
    );
    if (
      this.state.facebook.match(
        new RegExp("^https:\\/\\/[a-z]{2,3}\\.facebook\\.com\\/.*$")
      ) ||
      this.state.facebook == ""
    ) {
      data.append("facebook", this.state.facebook);
    } else {
      this.setState({ error: true, message: "Not a valid facebook url" });
      return;
    }

    if (
      this.state.twitter.match(
        new RegExp("^https:\\/\\/[a-z]{2,3}\\.twitter\\.com\\/.*$")
      ) ||
      this.state.twitter == ""
    ) {
      data.append("twitter", this.state.twitter);
    } else {
      this.setState({ error: true, message: "Not a valid twitter url" });
      return;
    }

    if (
      this.state.instagaram.match(
        new RegExp("^https:\\/\\/[a-z]{2,3}\\.instagram\\.com\\/.*$")
      ) ||
      this.state.instagaram == ""
    ) {
      data.append("instagaram", this.state.instagaram);
    } else {
      this.setState({ error: true, message: "Not a valid instagram url" });
      return;
    }

    if (
      this.state.linkedin.match(
        new RegExp("^https:\\/\\/[a-z]{2,3}\\.linkedin\\.com\\/.*$")
      ) ||
      this.state.linkedin == ""
    ) {
      data.append("linkedin", this.state.linkedin);
    } else {
      this.setState({ error: true, message: "Not a valid linkedin url" });
      return;
    }

    data.append("medium", this.state.medium);

    if (this.props.socialLinks != null) {
      this.props
        .updateSocialLinks(data, UPDATE_SOCIAL_LINKS, this.props.socialLinksId)
        .then((res) => {
          //
          if (res.val) {
            this.props.changeEdit();
            // document.body.style.overflow = "visible";
            return;
          }
          this.setState({ error: true, message: res.message });
        });
    } else {
      this.props.addSocialLinks(data, ADD_SOCIAL_LINKS).then((res) => {
        //
        if (res.val) {
          this.props.changeEdit();
          // document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
    }

    // this.props.changeEdit(false)
    // document.body.style.overflow = 'visible'
  }

  ScrollBody() {
    const { t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <pre
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <img
            src="/Image/Profile/fb.svg"
            style={{ height: 35, marginRight: 15 }}
          />
          <input
            className={styles.modal_para}
            value={this.state.facebook}
            onChange={(e) => this.setState({ facebook: e.target.value })}
            id={styles.textarea}
            type="text"
            placeholder={t("profile.modalPlaceholder.addFacebookLink")}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 13,
              borderRadius: 50,
              width: "90%",
            }}
          />
        </pre>
        <pre
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <img
            src="/Image/Profile/linkedIn.svg"
            style={{ height: 35, marginRight: 15 }}
          />
          <input
            className={styles.modal_para}
            value={this.state.linkedin}
            onChange={(e) => this.setState({ linkedin: e.target.value })}
            id={styles.textarea}
            type="text"
            placeholder={t("profile.modalPlaceholder.addLinkedinLink")}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 13,
              borderRadius: 50,
              width: "90%",
            }}
          />
        </pre>{" "}
        <pre
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <img
            src="/Image/Profile/twitter.svg"
            style={{ height: 35, marginRight: 15 }}
          />
          <input
            className={styles.modal_para}
            value={this.state.twitter}
            onChange={(e) => this.setState({ twitter: e.target.value })}
            id={styles.textarea}
            type="text"
            placeholder={t("profile.modalPlaceholder.addTwitterLink")}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 13,
              borderRadius: 50,
              width: "90%",
            }}
          />
        </pre>{" "}
        <pre
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <img
            src="/Image/Profile/instagram.png"
            style={{ height: 35, marginRight: 15 }}
          />
          <input
            className={styles.modal_para}
            value={this.state.instagaram}
            onChange={(e) => this.setState({ instagaram: e.target.value })}
            id={styles.textarea}
            type="text"
            placeholder={t("profile.modalPlaceholder.addInstagramLink")}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 13,
              borderRadius: 50,
              width: "90%",
            }}
          />
        </pre>{" "}
        <pre
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <img
            src="/Image/Profile/website.svg"
            style={{ height: 35, marginRight: 15 }}
          />
          <input
            className={styles.modal_para}
            value={this.state.medium}
            onChange={(e) => this.setState({ medium: e.target.value })}
            id={styles.textarea}
            type="text"
            placeholder={t("profile.modalPlaceholder.addMediumLink")}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 13,
              borderRadius: 50,
              width: "90%",
            }}
          />
        </pre>
      </div>
    );
  }

  // socialImages = [
  //     "/Image/apps/profile-resume-edit-view/facebook-colored.svg",
  //     "/Image/apps/profile-resume-edit-view/linkedin-colored.svg",
  //     "/Image/apps/profile-resume-edit-view/twitter-colored.svg",
  //     "/Image/apps/profile-resume-edit-view/instagram-colored.svg",
  //     "/Image/apps/profile-resume-edit-view/medium-colored.svg"
  // ]

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.title}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton
          onClick={() => {
            this.SaveButtonOnClick();
            document.body.style.overflow = "visible";
          }}
        />

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
  // componentDidMount(){
  //
  //
  //
  //
  //
  //
  //
  // }
}

function mapStateToProps(state) {
  // if( state.profile.socialLinks != null){
  //     return {socialLinksId: state.profile.socialLinks.id}
  // }
  return {
    facebook: state.profile?.socialLinks?.facebook || "",
    linkedin: state.profile?.socialLinks?.linkedin || "",
    twitter: state.profile?.socialLinks?.twitter || "",
    instagaram: state.profile?.socialLinks?.instagaram || "",
    medium: state.profile?.socialLinks?.medium || "",
    socialLinks: state.profile?.socialLinks || null,

    socialLinksId: state.profile?.socialLinks?.id || "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSocialLinks: (data, action) => dispatch(addSocialLinks(data, action)),
    updateSocialLinks: (data, action, id) =>
      dispatch(updateSocialLinks(data, action, id)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditSocial)
);
