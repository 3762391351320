import { gql } from "graphql-request";

export const MARK_AS_COMPLETED_MUTATION = gql`
  mutation ($input: Int!) {
    completeLecture(lec_id: $input) {
      completed
      progress_bar
    }
  }
`;

export const MARK_AS_UNCOMPLETED_MUTATION = gql`
  mutation ($input: Int!) {
    uncompleteLecture(lec_id: $input) {
      completed
      progress_bar
    }
  }
`;

export const CREATE_SECTION_MUTATION = gql`
  mutation ($input: SectionInput!) {
    createSection(input: $input) {
      id
      title
      lectures {
        id
        title
        description
        isPublished
        fileLink
        article
        fileType
        url
        duration
        timestamp
        utimestamp
        completed
      }
      timestamp
      utimestamp
      total_lecs
      sec_mins
    }
  }
`;

export const CREATE_LECTURE_MUTATION = gql`
  mutation ($input: LectureInput!, $id: ID!) {
    createLecture(input: $input, section_id: $id) {
      section {
        id
        # title
        # timestamp
        # utimestamp
        # total_lecs
        # sec_mins
        # quizOrLecture
      }
      id
      title
      isPublished
      fileType
      fileLink
      url
      duration
      # description
      # article
      # completed
    }
  }
`;

export const DELETE_COURSE_SECTION_MUTATION = gql`
  mutation ($input: [ID!]!) {
    deleteSection(sec_ids: $input)
  }
`;
export const DELETE_COURSE_LECTURE_MUTATION = gql`
  mutation ($input: [ID!]!) {
    deleteLectures(lec_ids: $input)
  }
`;

export const UPDATE_SECTION_MUTATION = gql`
  mutation ($id: ID!, $input: updateSectionInput!) {
    updateSection(id: $id, input: $input) {
      id
      title
      lectures {
        id
        title
        description
        isPublished
        fileLink
        article
        fileType
        url
        duration
        timestamp
        utimestamp
        completed
      }
      timestamp
      utimestamp
      total_lecs
      sec_mins
    }
  }
`;

export const UPDATE_LECTURE_MUTATION = gql`
  mutation ($id: ID!, $input: UpdateLectureInput!) {
    updateLecture(id: $id, input: $input) {
      id
      title
      description
      isPublished
      fileLink
      article
      fileType
      url
      duration
      timestamp
      utimestamp
      completed
    }
  }
`;
