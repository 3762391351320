import {
  GET_INDIVIDUAL_ASSIGNMENT,
  GET_ALL_ASSIGNMENTS,
  CREATE_CLASS_ASSIGNMENT,
  UPDATE_CLASS_ASSIGNMENT,
  DELETE_CLASS_ASSIGNMENT,
  GET_STUDENT_LIST,
  SAVE_ASSIGNMENT_MARKS,
  GET_ALL_ASSIGNMENT_SUBMISSIONS,
  GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION,
  GET_ALL_FILTERED_ASSIGNMENT_SUBMISSIONS,
  ADD_PRIVATE_COMMENT,
  DISPLAY_PRIVATE_COMMENT_ASSIGNMENT,
  GET_STUDENT_ASSIGNMENT_LIST,
  GET_STUDENT_INDIVIDUAL_ASSIGNMENT,
  GET_STUDENT_SUBMISSION,
  REMOVE_STUDENT_SUBMISSION,
  SUBMIT_ASSIGNMENT_SOLUTION,
  UPDATE_STUDENT_ASSIGNMENT_SOLUTION,
  RETURN_ASSIGNMENT_MARKS,
  PROGRESS_BAR_COUNT_ASG,
  GET_ALL_ASSIGNMENT_QUIZ,
  IS_LOADING,
  CLEAR_ASSIGNMENT_SUBMISSION,
  GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID,
  ASSIGN_STUDENTS_TO_ASSIGNMENT,
  UNASSIGN_STUDENTS_FROM_ASSIGNMENT,
  GRADE_ZERO,
  GET_INDIVIDUAL_ASSIGNMENT_PRE_SUBMISSION_DATA
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const defaultState = {
  assignments: [],
  graded_assignments: [],
  assignment: {},
  student_list: [],
  assignmentList: [],
  assignmentInd: {},
  comments: [],
  assignment_submission: {},
  assignment_submissions: [],
  filtered_assignment_submissions: [],
  submission: {},
  progressBarCountAsg: 0,
  allAssignmentQuiz: [],
  isLoading: false,
  individualAssignmentPreSubmissionData: {},
};

let assignmentData = {};
let data = {};
let index = 0;

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_ASSIGNMENTS:
      data = { ...state };
      data.assignments = action.payload;
      return data;

    case GET_INDIVIDUAL_ASSIGNMENT:
      data = { ...state };
      data.assignment = action.payload;
      return data;

    case GET_STUDENT_LIST:
      data = { ...state };
      data.student_list = action.payload;
      return data;

    case CREATE_CLASS_ASSIGNMENT:
      data = { ...state };
      data.assignments.push(action.payload);
      return data;

    case UPDATE_CLASS_ASSIGNMENT:
      assignmentData = { ...state };
      index = assignmentData.assignments.findIndex(function (asg) {
        return asg.id === action.payload.id;
      });
      if (index === -1) {
        return assignmentData;
      }
      assignmentData.assignments[index] = {
        ...assignmentData.assignments[index],
        ...action.payload,
      };
      return assignmentData;

    case DELETE_CLASS_ASSIGNMENT:
      assignmentData = { ...state };
      index = assignmentData.assignments.findIndex(function (asg) {
        return asg.id === action.payload;
      });
      if (index !== -1) assignmentData.assignments.splice(index, 1);

      return assignmentData;

    case ADD_PRIVATE_COMMENT:
      assignmentData = { ...state };
      assignmentData.comments.push(action.payload);
      return assignmentData;

    case DISPLAY_PRIVATE_COMMENT_ASSIGNMENT:
      assignmentData = { ...state };
      assignmentData.comments = action.payload;
      return assignmentData;

    case REMOVE_STUDENT_SUBMISSION:
      assignmentData = { ...state };
      assignmentData.submission = action.payload;
      return assignmentData;

    case SAVE_ASSIGNMENT_MARKS:
      data = { ...state };
      data.graded_assignments.push(action.payload);
      return data;

    case GET_STUDENT_ASSIGNMENT_LIST:
      assignmentData = { ...state };
      assignmentData.assignmentList = action.payload;
      return assignmentData;
    case GET_STUDENT_INDIVIDUAL_ASSIGNMENT:
      assignmentData = { ...state };
      assignmentData.assignmentInd = action.payload;
      return assignmentData;
    case GET_ALL_ASSIGNMENTS:
      assignmentData = { ...state };
      assignmentData.assignments = action.payload;
      return assignmentData;
    case SUBMIT_ASSIGNMENT_SOLUTION:
      assignmentData = { ...state };
      assignmentData.submission = action.payload;
      return assignmentData;
    case GET_STUDENT_SUBMISSION:
      assignmentData = { ...state };
      assignmentData.submission = action.payload;
      assignmentData.assignment_submission = action.payload;
      return assignmentData;
    
    case CLEAR_ASSIGNMENT_SUBMISSION:
      // assignmentData = { ...state };
      assignmentData = cloneDeep(state);
      assignmentData.submission = {};
      assignmentData.assignment_submission = {};
      assignmentData.comments=[];
      return assignmentData;
      
    case UPDATE_STUDENT_ASSIGNMENT_SOLUTION:
      assignmentData = { ...state };
      assignmentData.submission = action.payload;
      return assignmentData;
    // case GET_STUDENT_SUBMISSION:
    //   data = { ...state };
    //   data.assignment_submission = action.payload;
    //   return data;

    case GET_ALL_ASSIGNMENT_SUBMISSIONS:
      data = { ...state };
      data.assignment_submissions = action.payload;
      return data;

    case GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION:
      data = { ...state };
      data.assignment_submission = action.payload;
      return data;

    case GET_ALL_FILTERED_ASSIGNMENT_SUBMISSIONS:
      data = { ...state };
      data.filtered_assignment_submissions = action.payload;
      return data;

    case RETURN_ASSIGNMENT_MARKS:
      data = { ...state };
      let oldStudentList = data.student_list;
      let oldFilteredAsgSubmissionList = data.filtered_assignment_submissions;
      let submissionIdList = action.payload;

      submissionIdList.map((subID) => {
        let index = oldStudentList.map((item) => item.id).indexOf(subID);
        oldStudentList[index].submission_status = "GRD";

        index = oldFilteredAsgSubmissionList
          .map((item) => item.id)
          .indexOf(subID);
        oldFilteredAsgSubmissionList.splice(index, 1);
      });

      data.student_list = oldStudentList;
      data.filtered_assignment_submissions = oldFilteredAsgSubmissionList;
      return data;

    case PROGRESS_BAR_COUNT_ASG:
      data = { ...state };
      data.progressBarCountAsg = action.payload;
      return data;

    case IS_LOADING:
      data = { ...state };
      data.isLoading = action.payload;
      return data;
    case GET_ALL_ASSIGNMENT_QUIZ:
      data = { ...state };
      data.allAssignmentQuiz = action.payload;
      return data;
    
    case GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID:
      data = {...state};
      data.individualAssignmentSubmissionId = action.payload
      return data;

    case ASSIGN_STUDENTS_TO_ASSIGNMENT:
      data = {...state};
      data.student_list = action.payload;
      return data;
    
    case UNASSIGN_STUDENTS_FROM_ASSIGNMENT:
      data = {...state};
      data.student_list = action.payload;
      return data;
    
    case GRADE_ZERO:
      data = {...state};
      data.student_list = action.payload;
      return data;
    
    case GET_INDIVIDUAL_ASSIGNMENT_PRE_SUBMISSION_DATA:
      data = {...state};
      data.individualAssignmentPreSubmissionData = action.payload;
      return data;

    default:
      return { ...state };
  }
};
