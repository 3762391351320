import React from "react";
import { connect } from "react-redux";

class MidDpicFeed extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    this.props.props.updateDp(URL.createObjectURL(event.target.files[0]));
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: 15,
            backgroundColor: "#5555555A",
            height: 160,
            width: 160,
            borderRadius: 100,
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 30,
          }}
        >
          <div
            style={{
              position: "absolute",
              width: 160,
              height: 160,
              overflow: "hidden",
              borderRadius: 100,
            }}
          >
            <img
              src={
                this.props.profilePicUrl
                  ? this.props.profilePicUrl
                  : this.props.avatarImgView
                  ? this.props.avatarImgView
                  : "/Image/header/ddefault_img.svg"
              }
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              paddingBottom: 20,
            }}
          >
            <div
              style={{
                border: "5px solid #fff",
                padding: 8,
                borderRadius: 20,
                backgroundColor: "#6fc16a",
                zIndex: 2,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profilePicUrlview: state.profile.userProfilePicView,
    avatarImgView: state.profile.avatarImgView,
  };
}

export default connect(mapStateToProps)(MidDpicFeed);
