import React, { useState, useEffect } from 'react';
import styles from './css/PickPublicPrivateCourseModal.module.css';
import { useTranslation } from 'react-i18next';
import CreateEditCourseModal from './CreateEditCourseModal';
import Button from '../../../components/assignmentAndQuiz/Button/Button';
import TooltipWrapper from '../Components/TooltipWrapper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const PickPublicPrivateCourseModal = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const profileId = useSelector((state) => state?.profile?.profileId) || 0;
    const classes = useSelector((state) => state.classes.classes).filter(
        (item) => item.class_owner == profileId
    );

    const [style, setStyle] = useState(styles.popupModalBox);

    const [courseType, setCourseType] = useState("");

    const nextButtonHandler = () => {
        history.push({
            pathname: "/course/create/",
            state: {
                courseType: "Private    ",
                courseTitleType: "Create",
                routeType: props.routeType,
                courseFeedCourse: props.courseFeedCourse, // true means the course creation process initialed from inside a class, else from academics section.
                classes: classes,
            }
        })

    }


    useEffect(() => {
        document.body.style.overflow = "hidden"
        nextButtonHandler();
        return () => {
            document.body.style.overflow = "auto"

        }
    }, [])

    return <>
        {/* {showModal && (
            <CreateEditCourseModal
                courseType={courseType}
                closeModal={handleModal}
                show={showModal}
                courseTitleType={t("common.create")}
                courseFeedCourse={props.courseFeedCourse}
            />
        )} */}
        <div className={style}>
            <div className={styles.publicPrivate}>
                <div className={styles.publicPrivateHeading}>
                    <div className={styles.insidePublicPrivateHeading}>
                        <img src="/Image/Icons/note.svg" alt="topIcon" />
                        <p>{t("class.createProductService")}</p>
                    </div>
                    <img src="/Image/Icons/cross.svg" id={styles.cross} alt="cross" onClick={props.handleClose} />
                </div>
                <div className={styles.publicPrivateBoxes}>
                    <div className={styles.publicPrivateBox} onClick={() => {
                        setCourseType("Public")
                    }}>
                        <img src="/Image/Icons/public.svg" id={styles.lockIcon} alt="publicIcon" />
                        <div className={styles.publicPrivateBoxFlex}>
                            <h2>{t("courseMonetisation.publicProductService")}</h2>
                            <TooltipWrapper title="Anyone can enroll into the course">
                                <img src="/Image/Icons/Iicon.svg" />
                            </TooltipWrapper>
                        </div>
                        <img src={courseType === "Public" ? "/Image/Icons/selectedCircle.svg" : "/Image/Icons/circle.svg"} id={styles.select} alt="select" />
                    </div>
                    <div className={styles.publicPrivateBox} onClick={() => {
                        setCourseType("Private")
                    }}>
                        <img src="/Image/Icons/private.svg" id={styles.lockIcon} alt="privateIcon" />
                        <div className={styles.publicPrivateBoxFlex}>
                            <h2>{t("courseMonetisation.privateProductService")}</h2>
                            <TooltipWrapper title="Only the students from your classes can enroll into it">
                                <img src="/Image/Icons/Iicon.svg" />
                            </TooltipWrapper>
                        </div>
                        <img src={courseType === "Private" ? "/Image/Icons/selectedCircle.svg" : "/Image/Icons/circle.svg"} id={styles.select} alt="select" />
                    </div>
                </div>
                <div className={styles.nextbtn_div} onClick={() => {
                    courseType !== "" && nextButtonHandler();
                    courseType !== "" && setStyle(styles.hidePopupModalBox);
                }}>
                    <Button
                        fullWidth={true}
                        type="solid"
                        label={t("createCourse.next")}
                        disable={courseType === "" ? true : false}
                    />
                </div>
            </div>
        </div>
    </>
}

export default PickPublicPrivateCourseModal;