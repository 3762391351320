import * as React from "react";
import { TextField, InputBase } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import styles from "./DateTimePicker.module.css";
import { withStyles } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function DatePickerComponent(props) {
  // props - getAssignmentDate(params)
  const [value, setValue] = React.useState(props?.assignmentDate ?? new Date());
  const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "transparent",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "transparent",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent",
        },
      },
    },
  })(TextField);
  const theme = createTheme({
    palette: {
      primary: { main: "#00d498" },
    },
  });

  return (
    props.isSmallHeight ? 
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          dayOfWeekFormatter={(day) => day}
          PaperProps={{
            style: {
              borderRadius: "25px",
              fontFamily: "Montserrat",
            },
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#00d498",
                  color: "white",
                  fontFamily: "Montserrat",
                },
              },
            },
          }}
          closeOnSelect={false}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            props.getAssignmentDate(newValue);
          }}
          renderInput={(params) => (
            <div className={styles.date_field}>
              <CssTextField sx={{ width: "208px" }} size="small" {...params} />
            </div>
          )}
          {...props}
          inputProps={{
            style: {
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "400",
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
    :
    <ThemeProvider theme={theme}>
      {console.log(props.assignmentDate, "date-----------")}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          dayOfWeekFormatter={(day) => day}
          PaperProps={{
            style: {
              borderRadius: "25px",
              fontFamily: "Montserrat",
            },
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  backgroundColor: "#00d498",
                  color: "white",
                  fontFamily: "Montserrat",
                },
              },
            },
          }}
          closeOnSelect={false}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            props.getAssignmentDate(newValue);
          }}
          renderInput={(params) => (
            <div className={styles.date_field}>
              <CssTextField sx={{ width: "208px" }} size="small" {...params} />
            </div>
          )}
          {...props}
          inputProps={{
            style: {
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "400",
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
