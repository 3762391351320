import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/assignmentAndQuiz/Button/Button';
import styles from '../css/CreateAssignment.module.css';

function DiscardDeleteAssignmentModal(props) {
    // props- assignmentType, changeEdit, modalMessage, modalLabel
    const {t} = useTranslation()
    if (props.assignmentType == 'QUIZ') {
        return (
            <div className={styles.discard_modal_container}>
                <div className={styles.modal_header}>
                    <h4 className={styles.discard_modal_title}>{ props.modalLabel} {t("quiz.quiz")}</h4>
                    <div className={styles.close_icon} onClick={props.changeEdit}>
                        <img src="/Image/apps/profile-resume-edit-view/cross.svg" height={30} />
                    </div>
                </div>
                
                <p className={styles.discard_modal_msg}>{props.modalMessage}</p>
                <div className={styles.discard_btn}><Button clickHandler={props.discardFunction} type="solid" label={[t("common.yes"),", ", props.modalLabel ]}/></div>
            </div>
        );
        
    } else {
        return (
            <div className={styles.discard_modal_container}>
                <div className={styles.modal_header}>
                    <h4 className={styles.discard_modal_title}>{ props.modalLabel} {t("assignment.assignment")}</h4>
                    <div className={styles.close_icon} onClick={props.changeEdit}>
                            <img src="/Image/apps/profile-resume-edit-view/cross.svg" height={30} />
                    </div>
                </div>
                
                <p className={styles.discard_modal_msg}>{props.modalMessage}</p>
                <div className={styles.discard_btn}><Button clickHandler={props.discardFunction} type="solid" label={[t("common.yes"),", ", props.modalLabel]}/></div>
            </div>
        );
    }
   
}

export default DiscardDeleteAssignmentModal;