import { React, Component } from "react";
import styles from "./CSSFiles/Feed.module.css";
import MessageSender from "./MessageSender";
import Post from "./Post";
import { connect } from "react-redux";
// import GetStarted from "./GetStarted";
import Suggestions from "./Suggestions";
import { FeedsContext } from "./FeedsContext";
import { CircularProgress, Divider } from "@mui/material";
import { withRouter } from "react-router-dom";

class PostAndActivityFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSelectedComment: [],
    };
  }

  handleOpenSelectedComment = (id) => {
    this.setState((prevState) => ({
      openSelectedComment: [...prevState.openSelectedComment, id],
    }));
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    if (
      (!this.context.state.loading && window.innerHeight + window.scrollY) >=
      document.body.offsetHeight - 20
    ) {
      this.context.onPaginatedSearch();
    }
  };

  render() {
    console.log(this.context.state.postsByUserId);
    return (
      <div className={styles.feed}>
        <MessageSender isNotRequired={true} />
        {/* <GetStarted /> */}
        <Divider sx={{ mb: 2, background: "rgba(0,0,0,0.08)" }} />

        {this?.context?.state?.postsByUserId.map((post, index) => (
          <div key={index}>
            <Post
              post_owner_id={post?.post_owner_profile?.id}
              name={post?.post_owner_profile?.name}
              follower={post?.post_owner_profile?.connections}
              timestamp={post?.timestamp}
              message={
                post?.repost_of
                  ? post?.original_post_details?.description
                  : post?.description
              }
              // tag = {post.tag}
              profileImage={
                post?.post_owner_profile?.profile_pic
                  ? post?.post_owner_profile?.profile_pic
                  : post?.post_owner_profile?.avatarImg
                  ? post?.post_owner_profile?.avatarImg
                  : "/Image/header/ddefault_img.svg"
              }
              photoUrl={
                post?.repost_of
                  ? post?.original_post_details?.image_url
                  : post?.image_url
              }
              video={
                post?.repost_of
                  ? post?.original_post_details?.video_url
                  : post?.video_url
              }
              likes={post.likes}
              likeDetails={post.reactions}
              shareLink={post.share_link}
              file_url={
                post?.repost_of
                  ? post?.original_post_details?.file_url
                  : post.file_url
              }
              tags={post.tags}
              postId={post.id}
              comments={post.comments}
              shareModalOpener={() =>
                this.props.shareModalOpener(post.share_link)
              }
              menu={
                post?.post_owner_profile?.id == this.props?.userId
                  ? true
                  : false
              }
              postData={post}
              source="DASHBOARD"
              // language_code={post?.language_code}
              postLanguage={post?.language_code}
              handleOpenSelectedComment={this.handleOpenSelectedComment}
              openSelectedComment={this.state.openSelectedComment}
              post_details={
                post?.repost_of
                  ? post?.original_post_details?.post_details
                  : post?.post_details
              }
              postType={post?.post_type}
              sellImages={post?.sell_images}
              pollOptions={post?.poll_options}
              pollVotes={post?.poll_votes}
              original_post_owner={
                post?.original_post_details?.post_details?.post_owner_profile
              }
              user_type={post?.post_owner_profile?.user_details?.user_type}
            />
          </div>
        ))}
        {this?.context?.state?.loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={30} />
          </div>
        ) : null}
        {this?.context?.state?.postsByUserId?.length === 0 &&
        !this?.context?.state?.firstLoad ? (
          <div style={{ textAlign: "center" }}>
            <h4>No More posts to show</h4>
            <img
              src={"/Image/apps/profile-resume-edit-view/no_result.jpeg"}
              style={{ width: "100%" }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

PostAndActivityFeed.contextType = FeedsContext;

const mapStateToProps = (state) => {
  return {
    userId: state?.profile?.profileId,
  };
};
export default withRouter(connect(mapStateToProps, null)(PostAndActivityFeed));
