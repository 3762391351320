// imports

// packages
import React from "react";
import desc from "./desc";
import AspectRatio from "react-aspect-ratio";
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList } from "recharts";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

// compoments
import CircularProgress from "./components/CircularProgress";
import ProgressBarStatic from "./components/ProgressBarStatic";
import ExpTimelineTemp2 from "./components/ExpTimelineTemp2";

import { fetchProfileDataView } from "../../../../redux/actions/profile";
import { GET_PROFILE_DATA_VIEW } from "../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../components/commons/HandleApiError";
import { connect } from "react-redux";
import { color } from "@mui/system";
import ShareModal from "../../../Dashboard/shareModal/ShareModal";
import { withTranslation } from "react-i18next";

// global defaults

//charts
// defaults.color = '#676767';

class Template2 extends React.Component {
  state = {
    // show: false,
    showShareModal: false,
    link: "ccy-beag-tdz",
  };
  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };
  // data modified..
  bgcolor = ["#6564db", "#23e6e8", "#236ae8", "#ffaf02", "#fd0275"];

  

  componentDidMount() {
    this.props.fetchProfileDataView(GET_PROFILE_DATA_VIEW, JSON.parse(localStorage.userData).token, window.location.pathname.slice(9, -11));
    // .then(() => {

    //  let myskills = this.props.skillsView.map(skill => ({ name: skill.title, val:5}));
    //   console.log(myskills)
    // })
  }
  // main render
  render() {
  const { t } = this.props;
  let skillsDp = [
    { name: "No info", val: 5 },
    // { name: "Reading", val: 5 },
    // { name: "Swimming", val: 5 },
    // { name: "Fishing", val: 5 },
  ];
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 100 }}>
        <AspectRatio ratio="3/4" style={{ maxWidth: "85em", userSelect: "none" }}>
          <div style={styles.topviewable} id="topviewable">
            <div style={{ position: "relative" }}>
              <img
                style={{
                  opacity: 0.1,
                  position: "absolute",
                  // top: '50%',
                  marginLeft: "-29em",
                  left: "50%",
                  marginTop: "30em",
                  height: "60em",
                  width: "56em",
                }}
                src="/Image/apps/profile-resume-edit-view/Chalkmate_Watermark.png"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                backgroundColor: "#236ae8",
                padding: 50,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  backgroundColor: "#236ae8",
                  alignItems: "center",
                }}
              >
                <div style={{ alignItems: "center", display: "flex", marginRight: 60 }}>
                  <div>
                    <img 
                    // crossOrigin=" " 
                    src={this.props.dp} style={{ height: 230, width: 230, borderRadius: "50%" }} />
                  </div>
                </div>
                <div>
                  <p style={{ color: "#fff", margin: 0, fontWeight: 500, fontSize: 60, textTransform: "uppercase" }}>{this.props.nameView.split(" ")[0]}</p>
                  <p style={{ color: "#fff", margin: 0, fontWeight: 500, fontSize: 60, textTransform: "uppercase" }}>{this.props.nameView.split(" ")[1]}</p>
                  <p style={{ color: "#bdd2f8", margin: 0, marginTop: 20, fontSize: 30 }}>{this.props.profession}</p>
                </div>
              </div>

              <div>
                {/* <img src={"/Image/apps/profile-resume-edit-view/qr-code.png"} style={{ height: 150 }} /> */}
                <QRCode
                  bgColor="transparent"
                  value={`${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`}
                  id="qrCodeEl"
                  size={111}
                  style={{ height: "160px", width: "160px" }}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                padding: 100,
                paddingBottom: 0,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", color: "#0c1b7a", fontWeight: 600 }}>
                <div style={{ flex: 1.5, marginRight: 40 }}>
                  <p style={{ fontSize: 25 }}>{t("profile.about")}</p>
                  <p style={{ textTransform: "none", color: "#7e7e7e", fontWeight: 500, lineHeight: 1.5 }}>{this.props.about}</p>
                </div>
                <div style={{ flex: 1, paddingLeft: 100, borderLeft: "2px solid #e9e9e9" }}>
                  <p style={{ fontSize: 25 }}>{t("template.contact")}</p>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
                    {/* <div style={{ display: "flex", color: "#7e7e7e", textTransform: "none", fontWeight: 300, marginBottom: 10 }}>
                      <img src={"/Image/apps/profile-resume-edit-view/phone-gray.png"} style={{ height: 28 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.phone}</p>
                    </div> */}

                    <div style={{ display: "flex", color: "#7e7e7e", textTransform: "none", fontWeight: 300, marginBottom: 10 }}>
                      <img src={"/Image/apps/profile-resume-edit-view/location-gray.png"} style={{ height: 28 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{(this.props.nationality, this.props.residencecity, this.props.residencestate)}</p>
                    </div>
                    <div style={{ display: "flex", color: "#7e7e7e", textTransform: "none", fontWeight: 300, marginBottom: 10 }}>
                      <img src={"/Image/apps/profile-resume-edit-view/mail-gray.png"} style={{ height: 28 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.email}</p>
                    </div>
                    <div style={{ display: "flex", color: "#7e7e7e", textTransform: "none", fontWeight: 300, marginBottom: 10 }}>
                      <img src={"/Image/apps/profile-resume-edit-view/website-gray.png"} style={{ height: 28 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.website}</p>
                    </div>
                    <div style={{ display: "flex", color: "#7e7e7e", textTransform: "none", fontWeight: 300, marginBottom: 10 }}>
                      <img src={"/Image/apps/profile-resume-edit-view/nationality-gray.png"} style={{ height: 28 }} />
                      <p style={{ margin: 0, alignSelf: "center", marginLeft: 15 }}>{this.props.nationality}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 60, flex: 1 }}>
                <p style={{ fontSize: 25, textTransform: "uppercase", fontWeight: 600, color: "#0c1b7a", marginBottom: "10px"}}>{t("profile.experience")}</p>
                <div>
                  <ExpTimelineTemp2
                    data={
                      this.props.experience.length != 0
                        ? this.props.experience.slice(0, 5)
                        : [
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                          ]
                    }
                  />
                </div>
              </div>
              <div style={{ marginTop: 60, flex: 1 }}>
                <p style={{ fontSize: 25, textTransform: "uppercase", fontWeight: 600, color: "#0c1b7a", marginBottom: "10px" }}>{t("profile.education")}</p>
                <div>
                  <ExpTimelineTemp2
                    data={
                      this.props.educationWork.length != 0
                        ? this.props.educationWork.slice(0, 5)
                        : [
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                          ]
                    }
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 30, marginTop: 60 }}>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <p style={{ fontSize: 25, textTransform: "uppercase", fontWeight: 600, color: "#0c1b7a", marginBottom: -5 }}>{t("profile.skills")}</p>
                  {this.props.skillsView.length != 0 ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ height: "23em", width: "23em", fontSize: 15 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={this.props.skillsView.map((skill) => ({ name: skill.title, val: 5 }))}
                              dataKey="val"
                              cx="50%"
                              cy="40%"
                              innerRadius={60}
                              outerRadius={90}
                              fill="#82ca9d"
                            >
                              {this.props.skillsView.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.bgcolor[index % this.bgcolor.length]}></Cell>
                              ))}
                              <LabelList dataKey="name" stroke="rgb(85, 85, 85)" position="outside" lableline="true"></LabelList>
                            </Pie>
                            {/* <Pie data={this.props.skillsView} dataKey={5}  cx="50%" cy="40%" innerRadius={60} outerRadius={90} fill="#82ca9d">
                          {this.props.skillsView.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={this.bgcolor[index % this.bgcolor.length]} ></Cell>
                          ))}
                            <LabelList dataKey="title" stroke="black" position="outside" lableline="true"></LabelList>
                        </Pie> */}
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ height: "23em", width: "23em", fontSize: 15 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie data={skillsDp} dataKey="val" cx="50%" cy="40%" innerRadius={60} outerRadius={90} fill="#82ca9d">
                              {skillsDp.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.bgcolor[index % this.bgcolor.length]}></Cell>
                              ))}
                              <LabelList dataKey="name" stroke="rgb(85, 85, 85)" position="outside" lableline="true"></LabelList>
                            </Pie>
                            {/* <Pie data={this.props.skillsView} dataKey={5}  cx="50%" cy="40%" innerRadius={60} outerRadius={90} fill="#82ca9d">
                        {this.props.skillsView.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={this.bgcolor[index % this.bgcolor.length]} ></Cell>
                        ))}
                          <LabelList dataKey="title" stroke="black" position="outside" lableline="true"></LabelList>
                      </Pie> */}
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ marginLeft: 20, flex: 1, paddingLeft: 40 }}>
                  <p style={{ fontSize: 25, textTransform: "uppercase", fontWeight: 600, color: "#0c1b7a" }}>{t("profile.interests")}</p>

                  {
                    this.props.interests.length != 0 ? (
                      this.props.interests.slice(0, 5).map((item, index) => {
                        return <CircularProgress width={80} title={item.title} progress="100" fg="#236AE8" weight={500} />;
                      })
                    ) : (
                      <div style={{ padding: "25px 15px"}}>
                      <p style={{ fontSize: 17, color: "#555555", fontWeight: 500, margintop: 10, marginBottom: 0,}}>{t("template.noInfoAvailable")}</p>
                      </div>
                      // <CircularProgress title={t("template.noInfo")} progress="100" fg="#236AE8" weight={500} />
                    )
                    // t("template.noInfoAvailable")
                  }
                </div>
                <div
                  style={{
                    flex: 1,
                    paddingLeft: 10,
                  }}
                >
                  <p style={{ fontSize: 25, textTransform: "uppercase", fontWeight: 600, color: "#0c1b7a", marginBottom: 15 }}>{t("profile.languages")}</p>
                  {/* <div
                                        style={{
                                            borderLeft: '5px solid #dcdcdc',
                                            position: 'absolute',
                                            height: 200,
                                            borderRadius: 10,
                                        }}
                                    /> */}
                  <div style={styles.lcard}>
                    {this.props.languages.split(",") != '' ? 
                      <>
                        {this.props.languages.split(",").map((lval) => {
                          return <ProgressBarStatic width={80} fg="#23E6E8" progress="100" title={lval} bgcolor="#E5E7E7" weight={500} marginBottom={20} />;
                        })}
                      </>
                      :
                      <div style={{ width: "max-content"}}>
                        <p style={{ fontSize: 17, color: "#555555", fontWeight: 500, margintop: 10, marginBottom: 0,}}>{t("template.noInfoAvailable")}</p>
                      </div>
                    
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AspectRatio>

        <div style={{ margin: "30px 0px 100px 0px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <div
            onClick={() => {
              document.body.style.maxWidth = "85em";
              const input = document.getElementById("topviewable");

              html2canvas(input, {
                y: 0,
                allowTaint : true,
                useCORS: true,
                logging: true, 
              }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "px", "a4", true);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                pdf.addImage(imgData, "JPEG", 0, 0, width, height);
                pdf.save("resume.pdf");
                // window.open(pdf.output('datauristring'))
                document.body.style.maxWidth = "100%";
              });
            }}
          >
            <div id="btn-ftr" style={styles.footerbtn}>
              <p style={styles.btntitle}>{t("template.printThisResume")}</p>
            </div>
          </div>
          <div onClick={this.shareModalOpener}>
            <div id="btn-ftr" style={styles.footerbtn}>
              <p style={styles.btntitle}>{t("template.shareThisResume")}</p>
            </div>
          </div>
          <div>
            <Link to={`/profile/${this.props.profile_id}/view`} style={{ textDecoration: "none" }}>
              <div id="btn-ftr" style={styles.footerbtn}>
                <p style={styles.btntitle}>{t("template.viewDetailedResume")}</p>
              </div>
            </Link>
          </div>
        </div>
        <ShareModal closeModal={this.shareModalCloser} show={this.state.showShareModal} shareLink={window.location.href} />
      </div>
    );
  }
}
function mapStateToProps(state,props) {
  const {t} = props;
  return {
    nameView: state?.profile?.nameView || "",
    // lname: state?.profile?.nameView?.nameView.split(" ")[1] || "",
    website: window.location.href.replace("template/1", "") || "www.chalkmates.com",
    profession: state?.profile?.designationView || t("template.noInfoAvailable"),
    dob: state?.profile?.dobView || t("template.noInfoAvailable"),
    nationality: state?.profile?.addressView || t("template.noInfoAvailable"),
    residencecity: state?.profile?.locationView || t("template.noInfoAvailable"),
    residencestate: t("template.noInfoAvailable"),
    email: state?.profile?.user_email || "",
    dp: state?.profile?.userProfilePicView || "/Image/apps/profile-resume-edit-view/default-user.png",
    about: state?.profile?.aboutView ||  t("template.noInfoAvailable"),
    languages: state?.profile?.language_knownView || "",
    interests: state?.profile?.interestsView || [],
    skillsView: state?.profile?.skillsView || [],
    educationWork: state?.profile?.educationView || [
      {
        degree: t("template.noInfoAvailable"),
        description: t("template.noInfoAvailable"),
        end_date: t("template.noInfoAvailable"),
        field_of_study: t("template.noInfoAvailable"),
        participations: t("template.noInfoAvailable"),
        school: t("template.noInfoAvailable"),
        start_date: t("template.noInfoAvailable"),
      },
    ],
    experience: state?.profile?.experienceView || [
      {
        company: "DataPitcher",
        description: "",
        employment_type: "Internship",
        end_date: "2021-04-15",
        start_date: "2020-12-15",
        title: "Angular Developer",
      },
    ],
    profile_id: state?.profile?.profileIdView || "",

    // nameView: state?.profile?.user?.first_name || "Write a short bio about yourself.",
    certificationView: state?.profile?.certificationView || [],
    volunteerView: state?.profile?.volunteerView || [],
    achievementView: state?.profile?.achievementView || [],
    organisationView: state?.profile?.organisationView || t("template.noInfoAvailable"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
  };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Template2));

const styles = {
  li: {
    margin: 0,
    color: "#236ae8",
    fontSize: 40,
    display: "flex",
    alignItems: "center",
    marginLeft: -5,
    marginBottom: 20,
    // zIndex: 10
  },

  lihead: {
    color: "#7d7d7d",
    margin: 0,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.5,
  },

  lidesc: {
    color: "#606060",
    margin: "0px 0px 0px 0px",
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.5,
  },

  vwbtnftr: {
    display: "flex",
    flexDirection: "row",
  },

  footerbtn: {
    borderRadius: 30,
    border: "2px solid #00d498",
    margin: "10px 60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"10px"
  },

  btntitle: {
    padding: "3px 40px 3px 40px",
    fontSize: 10,
    fontWeight: 600,
  },

  lcard: {
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    lineHeight: 0.6,
    padding: 15,
    height: "12em",
  },
};
