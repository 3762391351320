import React, { Component } from "react";
import { Route } from "react-router-dom";
import { HeaderProvider } from "../apps/Profile/contexts/HeaderProvider";
import CareerModal from "../components/careerModal/CarrerModal";
import Referral from "../components/referral/referral";
import styles from "../apps/Profile/Profile.module.css";
import QRModal from "./QrModal/qrmodal";
import { ChatProvider } from "../components/chat/ChatContext";
import ProfileHeaderExplicit from "../components/profile-header-explicit/Header";
import IsAuthenticated from "./IsAuthenticated";
import MobileBottom from "../components/profile-mobilebottom-explicit/MobileBottom";
import Chat from "../components/chat/Chat";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { handleErrorMessage } from "../redux/actions/classes";
import { handleSuccessMessage } from "../redux/actions/success";

export default class ProtectedRoute extends Component {
  render() {
    return (
      <Route
        path={this.props.path}
        component={this.props.component}
        exact={this.props.exact}
      >
        <IsAuthenticated />
        {this.props.children}
      </Route>
    );
  }
}

class ProtectedRouteWithHeader extends Component {
  constructor() {
    super();

    this.state = {
      showQrModal: false,
      chatList: false,
      showCareerModal: false,
      showReferralModal: false,
      errorOpen: false,
    };
  }

  toggleChatList = () => {
    this.setState({ chatList: !this.state.chatList });
  };

  QRModalCloser = () => {
    this.setState({ showQrModal: false });
  };

  QRModalOpener = () => {
    this.setState({ showQrModal: true });
  };

  CareerModalCloser = () => {
    this.setState({ showCareerModal: false });
  };

  CareerModalOpener = () => {
    this.setState({ showCareerModal: true });
  };

  ReferralModalOpener = () => {
    this.setState({ showReferralModal: true });
  };

  ReferralModalCloser = () => {
    this.setState({ showReferralModal: false });
  };

  errorCloser = () => {
    this.props.handleErrorMessage("");
  };
  successMsgCloser = () => {
    this.props.handleSuccessMessage("");
  };
  render() {
    return (
      <>
        <Snackbar
          open={this.props.errorMsg != "" ? true : false}
          autoHideDuration={6000}
          onClose={this.errorCloser}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.errorCloser}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.props.errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.props.successMsg != "" ? true : false}
          autoHideDuration={6000}
          onClose={this.successMsgCloser}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.successMsgCloser}
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.props.successMsg}
          </Alert>
        </Snackbar>
        <div className={styles.header_feed}>
          <HeaderProvider>
            <ProfileHeaderExplicit
              show={this.QRModalOpener}
              hide={this.QRModalCloser}
              showcareer={this.CareerModalOpener}
              hidecareer={this.CareerModalCloser}
              showReferral={this.ReferralModalOpener}
              closeReferral={this.ReferralModalCloser}
              toggleChatList={this.toggleChatList}
              hideMobileNav={this.props?.hideMobileNav}
            />
            <QRModal
              closeModal={this.QRModalCloser}
              show={this.state.showQrModal}
            />
            <CareerModal
              closeModal={this.CareerModalCloser}
              show={this.state.showCareerModal}
            />
            <Referral
              closeModal={this.ReferralModalCloser}
              show={this.state.showReferralModal}
            />
          </HeaderProvider>

          <ChatProvider>
            <Chat
              toggleChatList={this.toggleChatList}
              isChatListOpen={this.state.chatList}
            />
          </ChatProvider>
        </div>
        <Route
          path={this.props.path}
          component={this.props.component}
          exact={this.props.exact}
        >
          <IsAuthenticated />
          <HeaderProvider>{this.props.children}</HeaderProvider>
        </Route>
        <MobileBottom
          toggleChatList={this.toggleChatList}
          isChatListOpen={this.state.chatList}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMsg: state?.errors?.errorMessage || "",
    successMsg: state?.success?.successMessage || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleErrorMessage: (errorMsg) => dispatch(handleErrorMessage(errorMsg)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
  };
};

ProtectedRouteWithHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRouteWithHeader);
export { ProtectedRouteWithHeader };
