import React from "react";
import styles from "../../../Profile.module.css";
import SaveButton from "../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import { updateProfileData } from "../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";
import LocationModal from "../../../../Dashboard/LocationModal/LocationModal";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      username: this.props.username,
      phone: this.props.phone,
      email: this.props.email,
      name: this.props.name,
      address:
        this.props.address || JSON.parse(localStorage.getItem("address")),
      dob: this.props.dob,
      location: this.props.location,
      contact_no: this.props.contact_no,
      error: false,
      message: "",
      showLocationModal: false,
      coordinates: {
        lat: JSON.parse(localStorage.getItem("lat")),
        lng: JSON.parse(localStorage.getItem("lng")),
      },
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    let data = new FormData();
    data.append("name", this.state.name);
    data.append("address", this.state.address);
    data.append("dob", this.state.dob);
    data.append("location", this.state.location);
    data.append("contact_no", this.state.contact_no);

    this.props.updateProfileData(EDIT_PROFILE_DATA, data).then((res) => {
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  setShowLocationModal = (value) => {
    console.log("value", value);
    this.setState({ showLocationModal: value });
  };

  handleAddress = (address) => {
    this.setState({ address });
  };

  handleCoordinatesChange = (coordinates) => {
    this.setState({ coordinates });
  };

  ScrollBody() {
    const { t } = this.props;
    return (
      <div>
        <LocationModal
          show={this.state.showLocationModal}
          closeModal={() => this.setShowLocationModal(false)}
          method={this.handleAddress}
          coordinates={this.state.coordinates}
          setCoordinates={this.handleCoordinatesChange}
        />
        <div
          style={{
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>{t("common.name")}</p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExJohnDoe")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this?.state?.name ?? ""}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <br />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>{t("profile.nickName")}</p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExIndia")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this?.state?.location ?? "India"}
              onChange={(event) => {
                this.setState({ location: event.target.value });
              }}
            />
            <br />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>
              {this.props.userType === "INDIVIDUAL"
                ? t("common.dob")
                : t("common.dateOfRegistration")}
            </p>
            <input
              className={styles.modal_para}
              type="date"
              placeholder={t("profile.modalPlaceholder.EnterYourDOB")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.dob}
              onChange={(event) => {
                this.setState({ dob: event.target.value });
              }}
            />
            <br />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>{t("common.address")}</p>
            <input
              onClick={() => this.setShowLocationModal(true)}
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExAddress")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
                cursor: "pointer",
              }}
              value={this?.state?.address ?? "Address"}
            />
            <br />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>{t("template.contact")}</p>
            <input
              className={styles.modal_para}
              type="number"
              placeholder={"0123456789"}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this?.state?.contact_no ?? "0123456789"}
              onChange={(event) => {
                this.setState({ contact_no: event.target.value });
              }}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.title}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    name: state?.profile?.name || "",
    address: state?.profile?.address || "",
    dob: state?.profile?.dob || "",
    location: state?.profile?.location || "",
    contact_no: state?.profile?.contact_no || "",
    userType: state?.profile?.user?.user_type,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action, data) =>
      dispatch(updateProfileData(action, data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditProfile)
);
