import React, { Component } from "react";
import styles from "./errorFourZeroFour.module.css";
import { Link } from "react-router-dom";

export default class errorFourZeroFour extends Component {
  render() {
    return (
      <div className={styles.fourZeroFour}>
        <img
          className={styles.fourZeroFourImage}
          src="/Image/pageNotFound.svg"
        />
        <div className={styles.fourZeroFourHeading}>
          <p className={styles.fourZeroFourOops}>Oops!</p>
          <p className={styles.fourZeroFourText}>
            We can't find the page you are looking for
          </p>
          <br />
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <button className={styles.fourZeroFourButton}>
              <b>GO BACK TO HOME</b>
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
