import React, { Component } from "react";
import styles from "./notificationsPage.module.css";
import { Link, useLocation } from "react-router-dom";
import { fetchPeopleYouMayKnow } from "../../../redux/actions/peopleyoumayknow";
import { FETCH_SEARCH_RESULTS } from "../../../redux/constants/actionTypes";
import { withRouter } from "react-router";
import { connect } from "react-redux";

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  searchResult = () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props
      .fetchPeopleYouMayKnow(FETCH_SEARCH_RESULTS, token)
      .then((res) => {
        if (res.val) {
          this.props.history.push("/search/results");
          return;
        }
        this.setState({ error: true, message: res.message });
      });
  };
  closeBurger = () => {
    let { history } = this.props;
    history.push("/user");
  };
  render() {
    return (
      <div className={styles.outerContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "7px 0 7px 0",
            zIndex: "200",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <img
              onClick={this.closeBurger}
              src="/Image/apps/profile-resume-edit-view/cross.svg"
              height={30}
            />
          </div>
        </div>
        Notifications
        <div>
          <div className={styles.notificationOuterDiv}>
            <Link
              to={`/profile/${
                JSON.parse(localStorage?.userData || "{}").profile_id
              }/edit`}
              style={{ textDecoration: "none" }}
            >
              <div className={styles.notification}>
                <img
                  src="/Image/notification/userProfile.svg"
                  style={{ width: 30, height: 30, opacity: 1 }}
                />
                <div style={{ fontSize: 14, color: "#000000" }}>
                  Complete your profile
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.dividerLine} />
          <div className={styles.notificationOuterDiv}>
            <Link to="/video" style={{ textDecoration: "none" }}>
              <div className={styles.notification}>
                <img
                  src="/Image/notification/videoConfrence.svg"
                  style={{ width: 30, height: 30, opacity: 1 }}
                />
                <div style={{ fontSize: 14, color: "#000000", marginLeft: 5 }}>
                  Start live session
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.dividerLine} />
          <div className={styles.notificationOuterDiv}>
            <Link style={{ textDecoration: "none" }}>
              <div className={styles.notification} onClick={this.searchResult}>
                <img
                  src="/Image/notification/searchMatch.svg"
                  style={{ width: 30, height: 30, opacity: 1 }}
                />
                <div
                  style={{
                    fontSize: 14,
                    color: "#000000",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                >
                  Search your match
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(NotificationsPage));
