export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const GET_ERROR_MESSAGE = "GET_ERROR_MESSAGE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const GET_EXPERIENCE = "GET_EXPERIENCE";
export const GET_EXPERIENCE_VIEW = "GET_EXPERIENCE_VIEW";
export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const UPDATE_LICENSES = "UPDATE_LICENSES";
export const ADD_LICENSES = "ADD_LICENSES";
export const UPDATE_VOLUNTEER = "UPDATE_VOLUNTEER";
export const ADD_VOLUNTEER = "ADD_VOLUNTEER";
export const GET_MODEL_SKILLS = "GET_MODEL_SKILLS";
export const UPDATE_SKILLS = "UPDATE_SKILLS";
export const GET_INTERESTS = "GET_INTERESTS";
export const GET_INTERESTS_VIEW = "GET_INTERESTS_VIEW";
export const GET_MODEL_INTERESTS = "GET_MODEL_INTERESTS";
export const ADD_INTERESTS = "ADD_INTERESTS";
export const UPDATE_INTERESTS = "UPDATE_INTERESTS";
export const UPDATE_ACHIEVEMENTS = "UPDATE_ACHIEVEMENTS";
export const ADD_ACHIEVEMENTS = "ADD_ACHIEVEMENTS";
export const UPDATE_TAGS = "UPDATE_TAGS";
export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_VIEW = "GET_TAGS_VIEW";
export const ADD_TAGS = "ADD_TAGS";
export const GET_PROFILE_PIC = "GET_PROFILE_PIC";
export const UPDATE_PROFILE_PIC = "UPDATE_PROFILE_PIC";
export const GET_GROUPS = "GET_GROUPS";
export const GET_MYGROUPS = "GET_MYGROUPS";

export const GET_CLASSES = "GET_CLASSES";
export const GET_CLASS_DETAILS = "GET_CLASS_DETAILS";
export const GET_GROUP_DETAILS = "GET_GROUP_DETAILS";
export const POST_CLASS_DETAILS = "POST_CLASS_DETAILS";
export const PUT_CLASS_DETAILS = "PUT_CLASS_DETAILS";
export const POST_GROUP_DETAILS = "POST_GROUP_DETAILS";
export const JOIN_MEMBER_DETAILS = "JOIN_MEMBER_DETAILS";
export const POST_SEARCHED_GROUP_DETAILS = "POST_SEARCHED_GROUP_DETAILS";

export const UPDATE_ABOUT = "UPDATE_ABOUT";
export const GET_ABOUT = "GET_ABOUT";
export const GET_PROFILE_DATA_EDIT = "GET_PROFILE_DATA_EDIT";
export const GET_PROFILE_DATA_VIEW = "GET_PROFILE_DATA_VIEW";
export const GET_PEOPLE_YOU_MAY_KNOW = "GET_PEOPLE_YOU_MAY_KNOW";
export const GET_PEOPLE_YOU_MAY_KNOW_VIEW = "GET_PEOPLE_YOU_MAY_KNOW_VIEW";
export const POST_COURSE_DETAILS = "POST_COURSE_DETAILS";
export const POST_MEMBERS_DETAILS = "POST_MEMBERS_DETAILS";
export const EDIT_PROFILE_DATA = "EDIT_PROFILE_DATA";
export const UPDATE_GROUP_COVER = "UPDATE_GROUP_COVER";
export const DELETE_USER = "DELETE_USER";

export const GET_COVER_PIC = "GET_COVER_PIC";
export const UPDATE_COVER_PIC = "UPDATE_COVER_PIC";
export const GET_DASHBOARD_POST = "GET_POST";
export const POST_LIKE = "POST_LIKE";
export const COMMENT_LIKE = "COMMENT_LIKE";
export const POST_COMMENT = "POST_COMMENT";
export const POST_DISLIKE = "POST_DISLIKE";
export const COMMENT_DISLIKE = "COMMENT_DISLIKE";
export const GET_USER_SUGGESTIONS = "GET_USER_SUGGESTIONS";
export const HEADER = "HEADER";
export const CLASS_ID_BY_CODE = "CLASS_ID_BY_CODE";

export const MEET_SUCCESS = "MEET_SUCCESS";
export const MEET_FAILURE = "MEET_FAILURE";
export const UPDATE_SOCIAL_LINKS = "UPDATE_SOCIAL_LINKS";
export const ADD_SOCIAL_LINKS = "ADD_SOCIAL_LINKS";
export const CHANGE_CLASS_ACTIVE_SECTION = "CHANGE_CLASS_ACTIVE_SECTION";
export const CHANGE_GROUP_ACTIVE_SECTION = "CHANGE_GROUP_ACTIVE_SECTION";

export const FETCH_SEARCH_RESULTS = "FETCH_SEARCH_RESULTS";

// export const FETCH_SEARCH_RESULTS = "FETCH_SEARCH_RESULTS"

export const SENT_CONNECTIONS = "SENT_CONNECTIONS";
export const CONNECTION_INVITATION = "CONNECTION_INVITATION";
export const ACCPET_CONNECTION = "ACCPET_CONNECTION";
export const MY_CONNECTIONS = "MY_CONNECTIONS";
export const WITHDRAW_CONNECTION = "WITHDRAW_CONNECTION";
export const REMOVE_CONNECTION = "REMOVE_CONNECTION";
export const IGNORE_CONNECTION = "IGNORE_CONNECTION";
export const SEND_CONNECTION = "SEND_CONNECTION";
export const GET_SUCCESS_MESSAGE = "GET_SUCCESS_MESSAGE";
export const POST_MISSION = "POST_MISSION";
export const POST_GROUP_MISSION = "POST_GROUP_MISSION";

export const DELETE_MEMBER = "DELETE_MEMBER";
export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const PUT_USER_SETTINGS = "PUT_USER_SETTINGS";

export const GET_CODE_SUGGESTIONS = "GET_CODE_SUGGESTIONS";
export const PUT_TIME_TABLE = "PUT_TIME_TABLE";
export const PUT_GROUP_DETAILS = "PUT_GROUP_DETAILS";

export const PAYMENT_CREATE = "PAYMENT_CREATE";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";

export const TRANSLATE_DATA = "TRANSLATE_DATA";
export const CHANGE_CURRENT_LANGUAGE = "CHANGE_CURRENT_LANGUAGE";
export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";
export const SIGNUP_STATUS = "SIGNUP_STATUS";
export const DELETE_CLASS = "DELETE_CLASS";
export const DELETE_GROUP = "DELETE_GROUP";
export const GET_CLASS_POST = "GET_CLASS_POST";
export const GET_GROUP_POST = "GET_GROUP_POST";

export const SEARCH_PAGINATED_RESULT = "SEARCH_PAGINATED_RESULT";
/* getActiveClassDetails={this.props.getActiveClassDetails}
Feed context Line:105
this.props.getActiveClassDetails(this.props.class_id); 
file_url.split("/").pop()
*/

// Assignment-actions

export const GET_INDIVIDUAL_ASSIGNMENT = "GET_INDIVIDUAL_ASSIGNMENT";
export const GET_ALL_ASSIGNMENTS = "GET_ALL_ASSIGNMENTS";

export const CREATE_CLASS_ASSIGNMENT = "CREATE_CLASS_ASSIGNMENT";
export const UPDATE_CLASS_ASSIGNMENT = "UPDATE_CLASS_ASSIGNMENT";
export const DELETE_CLASS_ASSIGNMENT = "DELETE_CLASS_ASSIGNMENT";

export const GET_ASSIGNMENT_PAGE = "GET_ASSIGNMENT_PAGE";
export const GET_ASSIGNMENT_SUBMISSION = "GET_ASSIGNMENT_SUBMISSION";
export const POST_CREATE_ASSIGNMENT_SUBMISSION =
  "POST_CREATE_ASSIGNMENT_SUBMISSION";

export const PUT_ASSIGNMENT_SUBMISSION_UPDATE =
  "PUT_ASSIGNMENT_SUBMISSION_UPDATE";

export const NEW_REQUEST_FOR_ASSIGNMENT_SUBMISSION =
  "NEW_REQUEST_FOR_ASSIGNMENT_SUBMISSION";

export const GET_STUDENT_LIST = "GET_STUDENT_LIST";

// Quiz-actions

export const GET_STUDENT_ASSIGNMENT_LIST = "GET_STUDENT_ASSIGNMENT_LIST";
export const GET_STUDENT_INDIVIDUAL_ASSIGNMENT =
  "STUDENT_INDIVIDUAL_ASSIGNMENT";
export const SUBMIT_ASSIGNMENT_SOLUTION = "SUBMIT_ASSIGNMENT_SOLUTION";
export const GET_STUDENT_SUBMISSION = "GET_STUDENT_SUBMISSION";
export const UPDATE_STUDENT_ASSIGNMENT_SOLUTION =
  "UPDATE_STUDENT_ASSIGNMENT_SOLUTION";
export const SAVE_ASSIGNMENT_MARKS = "SAVE_ASSIGNMENT_MARKS";
// export const GET_STUDENT_SUBMISSION = "GET_STUDENT_SUBMISSION";
export const GET_ALL_ASSIGNMENT_SUBMISSIONS = "GET_ALL_ASSIGNMENT_SUBMISSIONS";
export const GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION =
  "GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION";
export const GET_ALL_FILTERED_ASSIGNMENT_SUBMISSIONS =
  "GET_ALL_FILTERED_ASSIGNMENT_SUBMISSIONS";

export const ADD_PRIVATE_COMMENT = "ADD_PRIVATE_COMMENT";
export const DISPLAY_PRIVATE_COMMENT_ASSIGNMENT =
  "DISPLAY_PRIVATE_COMMENT_ASSIGNMENT";
export const DISPLAY_PRIVATE_COMMENT_QUIZ = "DISPLAY_PRIVATE_COMMENT_QUIZ";
export const REMOVE_STUDENT_SUBMISSION = "REMOVE_STUDENT_SUBMISSION";
export const GET_QUIZ_LIST = "GET_QUIZ_LIST";
export const GET_STUDENT_INDIVIDUAL_QUIZ = "GET_STUDENT_INDIVIDUAL_QUIZ";
export const GET_STUDENT_QUIZ_SUBMISSION = "GET_STUDENT_QUIZ_SUBMISSION";
export const SUBMIT_QUIZ_SOLUTION = "SUBMIT_QUIZ_SOLUTION";
export const UPDATE_STUDENT_QUIZ_SOLUTION = "UPDATE_STUDENT_QUIZ_SOLUTION";

export const CREATE_CLASS_QUIZ = "CREATE_CLASS_QUIZ";
export const GET_INDIVIDUAL_QUIZ = "GET_INDIVIDUAL_QUIZ";
export const UPDATE_CLASS_QUIZ = "UPDATE_CLASS_QUIZ";
export const DELETE_CLASS_QUIZ = "DELETE_CLASS_QUIZ";
export const GET_QUIZ_STUDENT_LIST = "GET_QUIZ_STUDENT_LIST";
export const RETURN_QUIZ_MARKS = "RETURN_QUIZ_MARKS";
export const SAVE_QUIZ_MARKS = "SAVE_QUIZ_MARKS";

export const GET_ALL_QUIZ_SUBMISSIONS = "GET_ALL_QUIZ_SUBMISSIONS";
export const GET_INDIVIDUAL_QUIZ_SUBMISSIONS =
  "GET_INDIVIDUAL_QUIZ_SUBMISSIONS";
export const GET_ALL_FILTERED_QUIZ_SUBMISSIONS =
  "GET_ALL_FILTERED_QUIZ_SUBMISSIONS";
export const RETURN_ASSIGNMENT_MARKS = "RETURN_ASSIGNMENT_MARKS";
export const GET_STUDENT_QUIZ_LIST = "GET_STUDENT_QUIZ_LIST";
export const PROGRESS_BAR_COUNT_ASG = "PROGRESS_BAR_COUNT_ASG";
export const PROGRESS_BAR_COUNT_QUIZ = "PROGRESS_BAR_COUNT_QUIZ";
export const GET_ALL_ASSIGNMENT_QUIZ = "GET_ALL_ASSIGNMENT_QUIZ";
export const IS_LOADING = "IS_LOADING";
export const IS_LOADING_QUIZ = "IS_LOADING_QUIZ";
export const ERROR_STATUS_CODE = "ERROR_STATUS_CODE";
export const GET_ALL_CLASS_OWNER_CODES = "GET_ALL_CLASS_OWNER_CODES";
export const GET_ALL_GROUP_OWNER_CODES = "GET_ALL_GROUP_OWNER_CODES";
export const CLEAR_ASSIGNMENT_SUBMISSION = "CLEAR_ASSIGNMENT_SUBMISSION";
export const CLEAR_QUIZ_SUBMISSION = "CLEAR_QUIZ_SUBMISSION";

export const POST_PRODUCT_SERVICE = "POST_PRODUCT_SERVICE";
export const POST_CURRENT_PRODUCT_SERVICE = "POST_CURRENT_PRODUCT_SERVICE";

export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const CREATE_COURSE = "CREATE_COURSE";
export const EDIT_COURSE = "EDIT_COURSE";

export const GET_ALL_CREATED_COURSES = "GET_ALL_CREATED_COURSES";
export const CLEAR_ALL_COURSES = "CLEAR_ALL_COURSES";
export const DELETE_COURSE = "DELETE_COURSE";
export const UPDATE_COURSE_STATUS = "UPDATE_COURSE_STATUS";
export const ENROLL_COURSE = "ENROLL_COURSE";
export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_FEED = "GET_COURSE_FEED";
export const GET_PUBLIC_COURSES = "GET_PUBLIC_COURSES";
export const GET_ENROLLED_COURSES = "GET_ENROLLED_COURSES";

export const PAYMENT_CREATE_COURSE = "PAYMENT_CREATE_COURSE";
export const UPDATE_CURRENT_COURSE_STATUS = "UPDATE_CURRENT_COURSE_STATUS";
export const GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID =
  "GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID";
export const ASSIGN_STUDENTS_TO_ASSIGNMENT = "ASSIGN_STUDENTS_TO_ASSIGNMENT";
export const UNASSIGN_STUDENTS_FROM_ASSIGNMENT =
  "UNASSIGN_STUDENTS_FROM_ASSIGNMENT";
export const GRADE_ZERO = "GRADE_ZERO";
export const GET_INDIVIDUAL_ASSIGNMENT_PRE_SUBMISSION_DATA =
  "GET_INDIVIDUAL_ASSIGNMENT_PRE_SUBMISSION_DATA";

export const GET_COURSE_CONTENT = "GET_COURSE_CONTENT";
export const MARK_AS_COMPLETED = "MARK_AS_COMPLETED";
export const MARK_AS_UNCOMPLETED = "MARK_AS_UNCOMPLETED";
export const GET_COURSE_PROGRESS = "GET_COURSE_PROGRESS";

export const CREATE_SECTION = "CREATE_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";

export const CREATE_LECTURE = "CREATE_LECTURE";
export const UPDATE_LECTURE = "UPDATE_LECTURE";
export const DELETE_LECTURE = "DELETE_LECTURE";

export const UPDATE_COURSE = "UPDATE_COURSE";
