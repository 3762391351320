import React, { useState, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import styles from "./css/zoomHome.module.css";
import { ModalWithHeader } from "../../components/shared/ui/Modal/Modal";
import homepage from "./ui/homeimage.png";
import { connect } from "react-redux";
import {
  createMeet,
  meetSuccess,
  getUserSettings,
} from "../../redux/actions/zoomAction";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { store } from "../../redux/store";
import PaymentSuccessModal from "../../components/paymentModal/paymentSuccessModal";
import { useTranslation } from "react-i18next";

function HomePage(props) {
  const history = useHistory();
  const location = useLocation();
  const [showModal, setshowModal] = useState(false);
  const [Topic, setTopic] = useState("");
  const [Agenda, setAgenda] = useState("");
  const [Link, setLink] = useState("");
  const [topicValidation, setTopicValidation] = useState("");
  const [meet, setMeet] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(
    location?.state?.showPaymentSuccessModal ?? false
  );
  const horizontal = "center";
  const vertical = "top";
  const { t } = useTranslation();

  const APIbaseURL = process.env.REACT_APP_API_BASE_URL + "api/v1/zoom/create/";
  // const localURL = "http://127.0.0.1:8000/"

  const changeLink = (e) => {
    setLink(e.target.value);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const joinMeeting = () => {
    var meeting_id = Link.substring(
      Link.indexOf("j") + 2,
      Link.lastIndexOf("?")
    );
    setMeet(true);
    var meeting_pasword = Link.substring(Link.indexOf("?") + 5);

    var data = {
      meeting_id,
      meeting_pasword,
      role: 0,
    };
    props.setZoomData(data);
  };

  const onTopicChange = (e) => {
    setTopic(e.target.value);
  };

  const onAgendaChange = (e) => {
    setAgenda(e.target.value);
  };
  const handleMeet = (e) => {
    e.preventDefault();
    if (Topic === "") {
      setTopicValidation(1);
      return;
    }
    props.createMeet(Topic, Agenda).then((res) => {
      if (res?.data != "data:pass") {
        res.data.signature = res.signature
        props.setZoomData(res.data, 1);
      } else {
        setOpen(true);
        setSeverity("warning");
        setMessage("Hosts are busy. Retry after sometime.");
      }
    });
    // .catch((error) => {
    //   setOpen(true);
    //   setSeverity("error");
    //   setMessage(error?.error?.message);
    // });
  };
  function getZoomSettings() {
    props.getUserSettings().then(() => {});
  }
  useEffect(() => {
    getZoomSettings();
    setLink(
      store.getState()?.zoom?.zoom_settings?.zoomSettings?.default_meeting_link
    );
  }, []);

  return (
    <div className="home-page">
      {props.redirect ? <Redirect to="/chalkmeet" /> : null}
      {}
      <ModalWithHeader
        title={t("subscription.createNewMeeting")}
        show={showModal}
        closeHandler={() => {
          setshowModal(false);
        }}
      >
        <div className={styles.classModal}>
          <form>
            <div className={styles.formFields}>
              <h3 className={styles.inputLabel} for="classname">
                {t("subscription.topicOfMeeting")}
              </h3>
              <input
                type="text"
                className={styles.inputField}
                onChange={onTopicChange}
                value={Topic}
                placeholder={t("subscription.meetingTopicPlaceholder")}
              />
              <div
                className={topicValidation === 1 ? "" : styles.hidden}
                style={{
                  margin: "5px 0 15px 20px",
                  fontSize: "14px",
                  color: "#E0003B",
                }}
              >
                {t("subscription.thisFieldMayNotBeBlank")}
              </div>
              <h3 className={styles.inputLabel} for="classname">
                {t("subscription.agendaOfMeeting")}
              </h3>
              <input
                type="text"
                className={styles.inputField}
                onChange={onAgendaChange}
                value={Agenda}
                placeholder={t("subscription.agendaChangePlaceholder")}
              />
              <button
                onClick={handleMeet}
                id="submitForm"
                className={styles.saveDetailsButton}
              >
                {t("subscription.createMeeting")}
              </button>
            </div>
          </form>
        </div>
      </ModalWithHeader>
      <PaymentSuccessModal
        show={showPaymentSuccessModal}
        closeModal={() => setShowPaymentSuccessModal(false)}
      />
      <div className="home aic">
        <div className="meta">
          <h2 className="slogan s40 fontr b000">
            {t("subscription.premiumVideoMeetings")}
          </h2>
          {/* <h2 className="slogan s40 fontr b000">{t("subscription.nowFreeForAll")}</h2> */}
          <h2 className="tagline s15 fontr c777 n">
            {t("subscription.ourMeetServiceMoreSecure")}
            <br></br>
            {t("subscription.nowAvailableToAll")}
          </h2>
          <div className="actions aic">
            <input
              value={Link}
              onChange={changeLink}
              className="room-code fontr s15"
              placeholder={t("subscription.EnterCodeLink")}
              style={{ marginTop: "10px" }}
            />
            <div
              onClick={
                Link == "" || Link == undefined
                  ? () => {
                      if (!showModal) setshowModal(true);
                    }
                  : joinMeeting
              }
            >
              <button
                className="start fontr s15 cfff flex aic gap"
                style={{ cursor: "pointer", marginTop: "10px" }}
              >
                <div className="s24" />
                <FontAwesomeIcon className="famr" icon={faVideo} />
                {Link == "" || Link == undefined
                  ? t("subscription.createNewMeeting")
                  : t("subscription.joinMeeting")}
              </button>
            </div>
          </div>
        </div>
        <img className="videoChatHome" src={homepage} />
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    zoomData: state.meetData,
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    meetingLink: state?.zoom?.zoom_settings?.zoomSettings?.default_meeting_link,
    zoomId: state?.zoom?.zoom_settings?.zoomId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMeet: (Topic, Agenda) => dispatch(createMeet(Topic, Agenda)),
    getUserSettings: () => dispatch(getUserSettings()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

/* const createMeeting = () => {
        createMeet(Topic, Agenda)
        
        try {
            axios({
                method: 'post',
                url: APIbaseURL,
                data: {
                    username: `${JSON.parse(localStorage.getItem("userData")).first_name}`,
                    meeting_type:1,
                    topic : Topic,
                    agenda : Agenda
                },
                headers: {
                  Authorization: `Token ${localStorage.getItem("token")}`
                }
            }).then((response) => {
                if (response.data.status) {
                    
                    props.setZoomData(response.data.data, 1);
                }
                else {
                    alert(response.data.message)
                }
            })
        }
        catch (error) {
            alert("Something went wrong, Please try again.");
            
        }
    } */
