import { Component, useState } from "react";
import styles from "./classcss/memberlist.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  deleteMemberAction,
  getClasses,
  getClassDetails,
  changeActiveSection,
} from "../../../redux/actions/classes";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getGroupDetails } from "../../../redux/actions/groups";
import { store } from "../../../redux/store";
import { handleSuccessMessage } from "../../../redux/actions/success";

/* export default function MembersList(props) { */
class MembersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClassMenu: false,
      classId: "",
      memberId: "",
    };
  }
  /* const [showClassMenu, setShowClassMenu] = useState(false); */

  handleMember = () => {
    this.setState({ showClassMenu: !this.state.showClassMenu });
  };
  deleteMember = (member) => {
    this.props
      .deleteMemberAction(member.profileId, this.props.class_id, member.id)
      .then(() => {
        this.props.getGroupDetails(this.props.class_id)
        handleSuccessMessage("Member Deleted")
        this.props.changeActiveSection("Post");
      });
  };
  render() {
    const { t } = this.props;
    // console.log('line41', this.props.members)
    let ownerProfileId = store?.getState()?.groups?.group?.group_owner;
    return (
      <div className={styles.membersListContainer}>
        <div style={{ padding: 24, paddingBottom: 16 }}>
          <h4 style={{ letterSpacing: 0.1 }}>{t("common.members")}</h4>
          <p style={{ letterSpacing: 0.1, marginTop: 2, marginBottom: 0 }}>
            {window.location.pathname.includes("/class")
              ? this.props.members.length
              : this.props.group_members.length}{" "}
            {t("common.members")}
          </p>
        </div>
        {window.location.pathname.includes("/class")
          ? this.props.members.map((member) => (
            <div>
              <hr className={styles.memberHr} />
              <div className={styles.memberContainer}>
                <div className={styles.memberDetailContainer}>
                  <img
                    src={member.img || "/Image/DashImage/profile.png"}
                    className={styles.memberImage}
                  />
                  <div>
                    <h4>{member.name}</h4>
                    <p style={{ marginTop: 4 }}>{member.university}</p>
                  </div>
                </div>
                {member.privilige ? (
                  <div className={styles.memberPriv}>
                    {window.location.pathname.includes("/class") ? (
                      <p style={{ color: "#00d498", margin: 10 }}>
                        {t("common.classOwner")}
                      </p>
                    ) : (
                      <p style={{ color: "#00d498", margin: 10 }}>
                        {t("common.owner")}
                      </p>
                    )}

                    <p style={{ display: "none" }}>
                      {(ownerProfileId = member.profileId)}
                    </p>
                  </div>
                ) : (
                  <div>
                    {ownerProfileId == this.props.profile_id ? (
                      <img
                        src="/Image/Icons/delete.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={
                            /* deleteMemberAction(member.profileId, this.props.class_id)  this.deleteMember*/ () =>
                            this.deleteMember(member)
                        }
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          ))
          : this.props.group_members.map((member) => (
            <div>
              <hr className={styles.memberHr} />
              <div className={styles.memberContainer}>
                <div className={styles.memberDetailContainer}>
                  <img
                    src={member.img || "/Image/DashImage/profile.png"}
                    className={styles.memberImage}
                  />
                  <div>
                    <h4>{member.name}</h4>
                    <p style={{ marginTop: 4 }}>{member.university}</p>
                  </div>
                </div>
                {ownerProfileId == member.profileId ? (
                  <div className={styles.memberPriv}>
                    <p style={{ color: "#00d498", margin: 10 }}>
                      {t("common.owner")}
                    </p>
                    <p style={{ display: "none" }}>
                      {(ownerProfileId == member.profileId) && <img
                        src="/Image/Icons/delete.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={
                            /* deleteMemberAction(member.profileId, this.props.class_id)  this.deleteMember*/ () =>
                            this.deleteMember(member)
                        }
                      />}
                    </p>
                  </div>
                ) : (
                  <div>
                    {ownerProfileId == this.props.profile_id ? (
                      <img
                        src="/Image/Icons/delete.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={
                            /* deleteMemberAction(member.profileId, this.props.class_id)  this.deleteMember*/ () =>
                            this.deleteMember(member)
                        }
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    );
  }
}
/* export default MembersList; */
const mapStateToProps = (state) => {
  // console.log("State to props",state?.classes?.members);
  return {
    members: state?.classes?.members,
    group_members: state?.groups?.members,
    profile_id: state?.profile?.user?.profile_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteMemberAction: (profileId, classId, memberId) =>
      dispatch(deleteMemberAction(profileId, classId, memberId)),
    getClasses: () => dispatch(getClasses()),
    getClassDetails: (id) => dispatch(getClassDetails(id)),
    getGroupDetails: (id) => dispatch(getGroupDetails(id)),
    changeActiveSection: (section) => dispatch(changeActiveSection(section)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MembersList)
);
