import React from "react";
// import Add from './Image/DashboardImages/postModImages/add.svg'
// import Close from './Image/DashboardImages/postModImages/cross.svg'

const topBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "14px 16px",
  background: "#fff",
  borderRadius: "8px 8px 0px 0px",
  // borderBottom: "1px solid #eee",
  position: "sticky",
};

export default function TopBar(props) {
  return (
    <div style={topBarStyle}>
      <p
        style={{
          color: "#000",
          letterSpacing: "0px",
          color: "#000000",
          alignItems: "center",
          display: "flex",
          fontWeight: "500",
          marginLeft: 10,
          fontSize: "18px",
          fontFamily: "var(--primary-text)",
        }}
      >
        {/* <img
          style={{ paddingRight: "10px" }}
          src={props.icon ?? "/Image/DashboardImages/postModImages/add.svg"}
          alt="Add"
        /> */}
        {/* {props.title} */}
      </p>
      <button
        onClick={() => {props.close(); props.clearState && props.clearState()}}
        style={{
          position: "relative",
          cursor: "pointer",
          backgroundColor: "#fff",
          borderRadius: "100%",
          border: "none",
        }}
      >
        <img src="/Image/DashboardImages/postModImages/cross.svg" alt="X" />
      </button>
    </div>
  );
}
