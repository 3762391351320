import styles from "./css/MessagingPage.module.css";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useRef, useState } from "react";
import MessageBubble from "./components/MessageBubble";
import SearchView from "./components/SearchView";
import ConversationList from "./components/ConversationList";
import { dummyData } from "../../lib/utils/globals";

function MessagingPage() {
    const windowWidth = useRef(window.screen.width);
    const isMobileView = windowWidth?.current <= 800;
    const [showSearchView, setShowSearchView] = useState(false);
    const [user, setUser] = useState();
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        if (!isMobileView && dummyData.length > 0) {
            setUser(dummyData[0]); // Set initial state based on the first item
        }
    }, [dummyData, isMobileView]);

    const handleSearchTextChange = (e) => {
        // api call and get all the search from e.target.value
        if (!e.target.value) {
            setSearchData([]);
            return;
        }
        setSearchData(dummyData);
    };

    return (
        <div className={`${styles["main"]} rounded-3`}>
            <div className={`d-flex w-100`}>
                {(!isMobileView || (isMobileView && !user)) && (
                    <div className={`${styles["message-list"]}`}>
                        <div>
                            <div className="d-flex align-items-center justify-content-between mx-4 my-4">
                                <div>
                                    <h5 className="fw-bold m-0">Messages</h5>
                                </div>
                                <button className="btn p-0">
                                    <img
                                        src="/Image/Messaging/edit.svg"
                                        alt="edit"
                                        style={{ width: "1rem" }}
                                    />
                                </button>
                            </div>
                            <div
                                className={`${styles["searchbox-wrapper"]} border rounded-5 mx-3 mb-4`}
                            >
                                <img src="/Image/DashImage/search_1_icon.svg" />
                                <input
                                    onFocus={() => setShowSearchView(true)}
                                    onBlur={() => {
                                        if (!searchData.length)
                                            setShowSearchView(false);
                                    }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={handleSearchTextChange}
                                    className="form-control-plaintext text-secondary"
                                />
                            </div>
                        </div>

                        <div
                            className={`d-flex flex-column ${styles["message-list-container"]} overflow-scroll`}
                        >
                            {showSearchView ? (
                                <SearchView
                                    searchData={searchData}
                                    user={user}
                                    handleConversationCardClick={(item) => {
                                        setUser(item);
                                    }}
                                />
                            ) : (
                                <ConversationList
                                    data={dummyData}
                                    user={user}
                                    handleConversationCardClick={(item) => {
                                        setUser(item);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
                {user && (
                    <div
                        className={`${styles["conversations"]} position-relative`}
                    >
                        <div
                            className={`${styles["conversation-header"]} d-flex gap-2 p-3 fw-bold border-bottom align-items-center`}
                        >
                            {isMobileView && (
                                <button
                                    className="btn p-0"
                                    onClick={() => setUser(null)}
                                >
                                    <img
                                        src="/Image/lecturePlan/back-arrow.svg"
                                        alt="send"
                                        style={{ width: "1.3rem" }}
                                    />
                                </button>
                            )}
                            <div
                                className={`${styles["conversation-image"]} rounded-pill`}
                                style={{
                                    backgroundImage: `url(${user.image})`,
                                }}
                            ></div>
                            <div className="d-flex justify-content-center flex-column">
                                <p className="m-0">{user.name}</p>
                            </div>
                        </div>
                        <div
                            className={`${styles["conversation-body"]} mt-0 overflow-scroll`}
                        >
                            <MessageBubble
                                text="Hey there! How's it going?"
                                time="3:47 PM"
                                isSender={true}
                                user={user}
                                avatar="https://example.com/avatar1.png"
                            />
                            <MessageBubble
                                text="Hey! Not bad, just another Monday. How about you?"
                                time="3:50 PM"
                                isSender={false}
                            />
                            <MessageBubble
                                text="Hey! Not bad, just another Monday. How about you?"
                                time="3:50 PM"
                                isSender={false}
                            />
                            <MessageBubble
                                text="Hey! Not bad, just another Monday. How about you?"
                                time="3:50 PM"
                                isSender={false}
                            />
                            <MessageBubble
                                text="Hey! Not bad, just another Monday. How about you?"
                                time="3:50 PM"
                                isSender={false}
                            />
                            <MessageBubble
                                text="Hey! Not bad, just another Monday. How about you?"
                                time="3:50 PM"
                                isSender={false}
                            />
                        </div>
                        <div
                            className={`${styles["conversation-footer"]} position-absolute bottom-0 p-3 w-100 bg-white`}
                        >
                            <div
                                className={`${styles["searchbox-wrapper"]} border rounded-3 px-3 pe-0`}
                            >
                                <input
                                    type="text"
                                    placeholder="Type Here..."
                                    className="fw-bold form-control-plaintext text-secondary"
                                />
                                <button className="btn">
                                    <img
                                        src="/Image/Messaging/send.svg"
                                        alt="send"
                                        style={{ width: "1rem" }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MessagingPage;
