import React from "react";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

class Languages extends React.Component {
  bgcolor = [
    "#9C27B0",
    "#00D498",
    "#FFC107",
    "#0C1B7A",
    "#6564db",
    "#23e6e8",
    "#236ae8",
    "#ffaf02",
    "#fd0275",
  ];

  render() {
    const { t } = this.props;
    return (
      <div
        id="Languages"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "10px 20px",
            borderRadius: 10,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <img
              src="/Image/Profile/language.svg"
              alt="language"
              width="20px"
            />
            <Typography sx={{ color: "#606060", fontSize: "17px" }}>
              {t("profile.languages")}
            </Typography>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.langContent?.split(",").map((item, idx) => {
                return (
                  <>
                    {item != "" ? (
                      <li key={idx} className={styles.langList}>
                        <hr
                          className={styles.langHr}
                          style={{
                            backgroundColor: this.bgcolor[idx],
                            borderColor: this.bgcolor[idx],
                          }}
                        />
                        {item}
                      </li>
                    ) : null}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    langContent: state?.profile?.language_knownView || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Languages));
