import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import styles from "../../../Profile.module.css";

class InstituteCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  render() {
    
    const {t} = this.props;
    return (
      <>
        {this.props.deptCodeView != "" ? (
          <>
            <div
              className={styles.desc}
              style={{
                boxShadow: "0px 0px 20px #5555551a",
                padding: 10,
                borderRadius: 10,
              }}
            >
              <div style={{ display: "flex", padding: 5 }}>
                {/* justifyContent: "space-around", alignItems: "center", */}
                <p
                  style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#00d498" }}
                  // onClick={() => {
                  //   this.changeEdit(true);
                  //   document.body.style.overflow = "hidden";
                  // }}
                >
                  {t("profile.instituteCode")}: {this.props.deptCodeView}
                </p>
              </div>
            </div>
            <br />
          </>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    deptCodeView: state?.profile?.deptCodeView || "",
  };
}
export default withTranslation()(connect(mapStateToProps, null)(InstituteCode));
