import React, { Component } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/shareModal.module.css";
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { connect } from "react-redux";
import desc from "../../apps/Profile/pages/templates/desc";
// import Copy from './Image/DashboardImages/sharemodImages/copy.svg';
import { fixedEncodeURIComponent } from "../../redux/constants/commonFunctions";
import { withTranslation } from "react-i18next";

class ShareModal extends Component {

  // props
  // coursepage -> this is passed from coursepage, it will be true from coursepage only
  constructor(props) {
    super(props);
    this.state = {
      profileLink: desc.website,
    };
  }
  render() {
    const courseViewLink = "Hello there, I'm inviting you to view this Chalkmate course. " +  this.props?.link;
    const profilePageLink = "Hello there, I'm inviting you to view my Chalkmates profile. " + `${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`

    return (
      <div>
        <ModalWithHeader
          title={this.props.t("common.share")}
          show={this.props.show}
          closeHandler={this.props.closeModal}
          maxWidth="569px"
          minWidth="350px"
        >
          <div className={styles.container}>
            <div style={{fontSize: "16px", fontWeight: "700", color: "#3f3f3f"}}>{this.props.t("modals.shareVia")}</div>
            <div className={styles.socialContainer}>
              <WhatsappShareButton url={this.props.coursePage ? courseViewLink : profilePageLink} quote="Profile Link">
                <img src="/Image/DashImage/whatsapp.png" height={45} width={45}></img>
              </WhatsappShareButton>
              <FacebookShareButton url={this.props.coursePage ? courseViewLink : profilePageLink} quote="Profile Link">
                <img src="/Image/DashImage/facebook.png" height={45} width={45}></img>
              </FacebookShareButton>
              <TwitterShareButton url={this.props.coursePage ? courseViewLink : profilePageLink} quote="Profile Link">
                <img src="/Image/DashImage/twitter.png" height={45} width={45}></img>
              </TwitterShareButton>
                <a href={'https://www.linkedin.com/shareArticle?mini=true&url=https://www.nxnearby.com'}>
              <img src="/Image/DashImage/linkedin.png" height={45} width={45}></img>
              </a>
              <PinterestShareButton
                media={`${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`}
                url={`${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`}
                quote="Profile Link">
                <img src="/Image/DashImage/pinterest.png" height={45} width={45}></img>
              </PinterestShareButton>
            </div>
            <div style={{fontSize: "16px", fontWeight: "700", color: "#3f3f3f"}}>{this.props.t("modals.copyLink")}</div>
            <div className={styles.copyContainer}>
              <p className={styles.link} style={{width: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                {this.props.coursePage ? <span>{this.props.link}</span> : <span>{"https://nxnearby.com/profile/" + this.props.profile_id + "/view"}</span>}
              </p>
              <img
                onClick={() => {
                  this.props.coursePage ?  navigator.clipboard.writeText(this.props.link) :
                  navigator.clipboard.writeText(`${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`);
                }}
                className={styles.copyButton}
                height="25px"
                width="25px"
                src="/Image/DashboardImages/sharemodImages/copy.svg"
              />
            </div>
          </div>
        </ModalWithHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    profile_id: state?.profile?.user?.profile_id || "4",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(ShareModal));

// import {
//   FacebookShareButton,
//   LinkedinShareButton,
//   PinterestShareButton,
//   TwitterShareButton,
//   WhatsappShareButton,
// } from "react-share";
// import { ModalWithHeader } from "../shared/ui/Modal/Modal";
// import styles from "./css/shareModal.module.css";
// // import Copy from './Image/DashboardImages/sharemodImages/copy.svg';

// export default class ShareModal extends Component {
//   render() {
//     return (
//       <div>
//         <ModalWithHeader
//           title="Share"
//           show={this.props.show}
//           closeHandler={this.props.closeModal}
//         // closeModal={this.props.closeModal}
//           maxWidth="569px"
//         >
//           <div className={styles.container}>
//             <div className={styles.socialContainer}>
//               <WhatsappShareButton
//                 url={`https://chalkmates.com/post/${this.props.link}`}
//                 quote="I have published this awesome post on chalkmates"
//               >
//                 <img src="/Image/DashImage/whatsapp.png"></img>
//               </WhatsappShareButton>
//               <FacebookShareButton
//                 url={`https://chalkmates.com/post/${this.props.link}`}
//                 quote="I have published this awesome post on chalkmates"
//               >
//                 <img src="/Image/DashImage/facebook.png"></img>
//               </FacebookShareButton>
//               <TwitterShareButton
//                 url={`https://chalkmates.com/post/${this.props.link}`}
//                 quote="I have published this awesome post on chalkmates"
//               >
//                 <img src="/Image/DashImage/twitter.png"></img>
//               </TwitterShareButton>
//               <LinkedinShareButton
//                 url={`https://chalkmates.com/post/${this.props.link}`}
//                 quote="I have published this awesome post on chalkmates"
//               >
//                 <img src="/Image/DashImage/linkedin.png"></img>
//               </LinkedinShareButton>
//               <PinterestShareButton
//                 url={`https://chalkmates.com/post/${this.props.link}`}
//                 quote="I have published this awesome post on chalkmates"
//               >
//                 <img src="/Image/DashImage/pinterest.png"></img>
//               </PinterestShareButton>
//             </div>
//             <div className={styles.copyContainer}>
//               <p className={styles.link}>
//                 https://chalkmates.com/post/{this.props.link}
//               </p>
//               <img
//                 onClick={() => {
//                   navigator.clipboard.writeText(
//                     `https://chalkmates.com/post/${this.props.link}`
//                   );
//                 }}
//                 className={styles.copyButton}
//                 height="25px"
//                 width="25px"
//                 src="/Image/DashboardImages/sharemodImages/copy.svg"
//               />
//             </div>
//           </div>
//         </ModalWithHeader>
//       </div>
//     );
//   }
// }
