import React, { useState, useEffect, useRef } from "react";
import styles from "./css/CreateCoursePage.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import IconTitle from "../../components/commons/IconTitle";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import MultipleSelectChip from "../AssignmentAndQuiz/Components/MultipleSelectChip";
import { handleErrorMessage } from "../../redux/actions/classes";
import { validateFileSize } from "../../redux/constants/commonFunctions";
import { CircularProgress } from "@mui/material";
import {
  createCourse,
  clearCourses,
  updateCourse,
} from "../../redux/actions/courses";
import Modal from "../../components/commons/Modal";
import ConfirmAddContentModal from "./Modals/ConfirmAddContentModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TooltipWrapper from "../CourseMonetization/Components/TooltipWrapper";
import axios from "axios";
import { store } from "../../redux/store";
import { createProductService, editEcommerce } from "../../redux/actions/groups";
import { handleSuccessMessage } from "../../redux/actions/success";

const CreateCoursePage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const uploadButtonRef = useRef();
  const isEditing = location?.state?.edit;
  const currentCourse = location?.state?.currentCourse;
  console.log('line31', currentCourse)
  const courseType = location?.state?.courseType; // "Public" or "Private"
  const courseTitleType = location?.state?.courseTitleType; // "Create" or "Edit"

  const [thumbnail, setThumbnail] = useState(
    courseTitleType == "Edit" && currentCourse?.thumbnail
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSaveBtn, setIsLoadingSaveBtn] = useState(false);
  const [isLoadingNextBtn, setIsLoadingNextBtn] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [courseName, setCourseName] = useState(
    courseTitleType == "Edit" ? currentCourse?.name : ""
  );

  const [coursePrice, setCoursePrice] = useState(
    courseTitleType == "Edit" ? currentCourse?.value : ""
  );

  const [courseDiscountedPrice, setCourseDiscountedPrice] = useState(
    courseTitleType == "Edit" ? currentCourse?.discounted_price : ""
  );

  const [courseSubtitle, setCourseSubtitle] = useState(
    courseTitleType == "Edit" ? currentCourse?.sub_title : ""
  );
  const [courseDescription, setCourseDescription] = useState(
    courseTitleType == "Edit" ? currentCourse?.description : ""
  );
  const [discountedPrice, setDiscountedPrice] = useState(
    courseTitleType == "Edit" ? currentCourse?.description : ""
  );

  const [isFree, setIsFree] = useState(
    courseTitleType == "Edit" ? currentCourse?.free : true
  );

  const [coursePriceValue, setCoursePriceValue] = useState(
    courseTitleType == "Edit" ? currentCourse?.value : ""
  );

  const title = `${courseTitleType == "Create" ? t("common.create") : t("common.edit")
    } ${courseType == "Public" ? t("common.Public") : t("common.Private")} ${t(
      "common.productService"
    )}`;

  // const profileId = useSelector((state) => state?.profile?.profileId) || 0;
  // const classes = useSelector((state) => state.classes.classes).filter(
  //   (item) => item.class_owner == profileId
  // );
  const classes = location?.state?.classes;

  const classTags = props.classTags ?? [];
  const classLoggedIn = useSelector((state) => state.classes.class);
  const currentClass =
    props.courseFeedCourse === true ? [classLoggedIn.name] : [];

  const [selectedClasses, setSelectedClasses] = useState(
    courseTitleType === "Edit"
      ? currentCourse?.classes?.map((item) => item.name)
      : currentClass
  );

  const getSelectedClasses = (value) => {
    setSelectedClasses(value);
  };

  const handleThumbnail = (e) => {
    let errorMessage = validateFileSize(e.target.files[0], 5120);
    if (errorMessage) {
      dispatch(handleErrorMessage(errorMessage));
    } else {
      setThumbnail(e.target.files[0]);
      // setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    }
  };



  // console.log('line117', thumbnail)


  const handleEdit = () => {


    const obj = {
      id: currentCourse.id,
      name: courseName,
      value: coursePriceValue,
      sub_title: courseSubtitle,
      discounted_price: courseDiscountedPrice,
      description: courseDescription,


    }

    dispatch(editEcommerce(obj))
  }

  const handleNextAndSave = async (btnType) => {
    // if (file?.size) {
    // setLoading(true)

    // const formData = new FormData();
    // formData.append('product_service_type', 'PRIVATE');
    // // formData.append('thumbnail', thumbnail);
    // formData.append('group_id', '58');
    // // formData.append('cover_image', '');
    // formData.append('name', courseName);
    // formData.append('description', courseDescription);
    // formData.append('free', true);
    // formData.append('value', '10000');
    // formData.append('discountedPrice', '100');

    const data = {
      product_service_type: 'PRIVATE',
      thumbnail: thumbnail,
      //       // classes: selectedClasses
      //       //   ?.map((e) => classes.find((item) => item.name === e))
      //       //   ?.map((x) => x?.id),
      name: courseName,
      group_id: store?.getState()?.groups?.group?.id,
      sub_title: courseSubtitle,
      description: courseDescription,
      free: courseTitleType == "Edit" ? currentCourse?.free : isFree,
      value: isFree ? 0 : Number(coursePriceValue),
      discounted_price: isFree ? 0 : Number(courseDiscountedPrice),
      active: courseTitleType == "Edit" ? currentCourse?.active : true,
    };
    // console.log('line149', data)
    //       const token = store.getState()?.profile?.user?.token;
    // // const authToken = token;
    dispatch(createProductService(data)).then(() => {
      dispatch(handleSuccessMessage("Created"));
      // history.push({
      //   pathname: `/group/${store?.getState()?.groups?.group?.id}`,

      // });
      history.push({
        pathname: `/group/${store?.getState()?.groups?.group?.id}`,
        state: { activeSection: "Courses" },
      });
    })
    // createProductService(formData)
    //   const token = store.getState()?.profile?.user?.token;
    //   const config = {
    //       headers: {
    //           'content-type': 'application/json',
    //           Authorization: `Token ${token}`,
    //       },
    //   };
    //   const data={
    //           product_service_type: 'PRIVATE',
    //           thumbnail: thumbnail,
    //           group_id:'58',
    //           cover_image:'',
    //     //       // classes: selectedClasses
    //     //       //   ?.map((e) => classes.find((item) => item.name === e))
    //     //       //   ?.map((x) => x?.id),
    //           name: courseName,
    //           sub_title: courseSubtitle,
    //           description: courseDescription,
    //           free: courseTitleType == "Edit" ? currentCourse?.free : isFree,
    //           value: isFree ? 0 : Number(coursePriceValue),
    //           discountedPrice:courseDiscountedPrice,
    //       //     // active: courseTitleType == "Edit" ? currentCourse?.active : true,
    //         }
    //         console.log('169',data)
    //  await axios({
    //     method: "post",
    //     url: 'https://liquidapi.stage.nxnearby.com/api/v1/productservice/',
    //     data: formData,
    //     headers: {
    //       "content-type": "multipart/form-data",
    //       Authorization: `Token ${token}`,
    //     },
    //   })

    // axios.post('https://liquidapi.stage.nxnearby.com/api/v1/productservice', data, config).then((response) => {
    //     console.log(response.data);


    // })
    //     .catch(e => {
    //         console.log(e);
    //     })


  };


  //   const handleNextAndSave = (btnType) => {
  //     console.log('camee')
  //     const data = {
  //       product_service_type: 'PRIVATE',
  //       thumbnail: thumbnail,
  //       // classes: selectedClasses
  //       //   ?.map((e) => classes.find((item) => item.name === e))
  //       //   ?.map((x) => x?.id),
  //       name: courseName,
  //       sub_title: courseSubtitle,
  //       description: courseDescription,
  //       free: courseTitleType == "Edit" ? currentCourse?.free : isFree,
  //       value: isFree ? 0 : Number(coursePriceValue),
  //       discountedPrice:courseDiscountedPrice,
  //       active: courseTitleType == "Edit" ? currentCourse?.active : true,
  //     };
  //     console.log("inputData", data);

  //     // if (
  //     //   data.courseName.trim() == "" ||
  //     //   data.courseSubtitle.trim() == "" ||
  //     //   data.courseDescription.trim() == "" ||
  //     //   (data.courseType === "Private" ) ||
  //     //   (!isFree && coursePriceValue === "")
  //     // ) {
  //     //   // console.log('line 123',data)
  //     //   dispatch(
  //     //     handleErrorMessage(t("errorMessageTranslations.requiredFields"))
  //     //   );
  //     //   setIsLoadingNextBtn(false);
  //     //   setIsLoadingSaveBtn(false);
  //     // } else if (!isFree && !validatePrice(data.priceValue)) {
  //     //   setIsLoadingNextBtn(false);
  //     //   setIsLoadingSaveBtn(false);
  //     // } else if (courseTitleType == "Edit") {
  //     //   data.id = currentCourse?.id;

  //     //   if (thumbnail) {
  //     //     data.thumbnail = thumbnail;
  //     //   }



  //     //   dispatch(updateCourse(data, currentCourse?.is_draft)).then(() => {
  //     //     const cc = JSON.parse(localStorage.getItem("currentCourse"));
  //     //     if (btnType == "next" && cc?.id) {
  //     //       setIsLoadingNextBtn(false);
  //     //       history.push({
  //     //         pathname: `/course/content/${cc.id}`,
  //     //       });
  //     //     } else {
  //     //       setIsLoadingNextBtn(false);
  //     //       history.push({
  //     //         pathname: `/academics`,
  //     //         state: { switchSection: "course" },
  //     //       });
  //     //     }
  //     //   });
  //     // }




  // try {


  //   axios.post('https://liquidapi.stage.nxnearby.com/api/v1/productservice',data).then((res)=>console.log(res))
  // } catch (error) {
  //  console.log(error) 
  // }



  //       // dispatch(createCourse(data)).then(() => {
  //       //   const cc = JSON.parse(localStorage.getItem("currentCourse"));
  //       //   if (btnType == "next" && cc?.id) {
  //       //     setIsLoadingNextBtn(false);
  //       //     history.push({
  //       //       pathname: `/course/content/${cc.id}`,
  //       //     });
  //       //   } else {
  //       //     setIsLoadingSaveBtn(false);
  //       //     history.push({
  //       //       pathname: `/academics`,
  //       //       state: { switchSection: "course" },
  //       //     });
  //       //   }
  //       // });

  //   };

  const validatePrice = (price) => {
    let errorMessage = null;
    switch (price) {
      case null:
      case undefined:
      case "":
        errorMessage = "errorMessageTranslations.enterValidNumber";
        break;
      default:
        if (!isNaN(price)) {
          if (price < 0) {
            errorMessage = "courseMonetisation.priceCantBeNegative";
          } else if (price === 0) {
            errorMessage = "courseMonetisation.priceCantBeZero";
          }
        } else {
          errorMessage = "errorMessageTranslations.enterValidNumber";
        }
    }

    if (errorMessage) {
      dispatch(handleErrorMessage(t(errorMessage)));
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("currentCourse");
      dispatch(clearCourses());
    };
  }, []);

  console.log('isFree', isFree)

  return (
    <div className={styles.main}>
      {showConfirmModal && (
        <Modal
          card={
            <ConfirmAddContentModal
              onYes={() => {
                setIsLoadingNextBtn(true);
                handleNextAndSave("next");
              }}
              onLater={() => {
                setIsLoadingSaveBtn(true);
                handleNextAndSave("save");
              }}
            />
          }
          onOutsideClick={() => setShowConfirmModal(false)}
        />
      )}
      <IconTitle
        title={title}
        img="/Image/CourseMonetisation/booklet-line-2.svg"
        notClickable={true}
        type="ASSIGNMENT"
      />
      {/* add thumbnail upload here */}
      <div className={styles.thumbnail_area}>
        <div className={styles.upload_box}>
          <input
            onChange={(e) => {
              handleThumbnail(e);
            }}
            type="file"
            accept="image/*"
            className={styles.file_upload}
            ref={uploadButtonRef}
          />
          <img
            src="/Image/CreateCourse/upload.svg"
            alt="Photos"
            className={styles.upload_img}
          />
          <p className={styles.upload_txt}>
            {t("createCourse.uploadThumbnail")}
          </p>
          <p className={styles.support_txt}>
            {t("createCourse.supportJpegPngFiles")}
          </p>
        </div>
        {thumbnail && (
          <img
            src={
              typeof thumbnail === "string"
                ? thumbnail
                : URL.createObjectURL(thumbnail)
            }
            className={styles.thumbnail_pic}
          />
        )}

        {thumbnail && (
          <div
            className={styles.change_thumbnail}
            onClick={() => uploadButtonRef.current.click()}
          >
            <img
              src={"/Image/DashImage/camera.svg"}
              className={styles.camera_img}
              alt=""
              title={t("createCourse.changeThumbnail")}
            />
          </div>
        )}
      </div>

      {/* <div className={styles.input_label}>
        <span>{t("createCourse.publishTo")}*</span>
        <TooltipWrapper
          title={`${courseType == "Public" ? t("createCourse.publishPublicTooltip") : t("createCourse.publishPrivateTooltip")
            }`}
        >
          <img src="/Image/Icons/Iicon.svg" className={styles.tooltip_icon} />
        </TooltipWrapper>
      </div> */}

      {/* <MultipleSelectChip
        courseFeedCourse={props.courseFeedCourse}
        courseTitleType={courseTitleType}
        getSelectedClasses={getSelectedClasses}
        notDisabled="true"
        id="publishTo"
        classTags={currentCourse?.classes} // in case of Edit, the already selected classes.
        assignmentClass={classes} // classes is an array of all the classes where the logged-in user is the owner.
      /> */}

      {console.log(
        "id from location",
        location?.state?.courseID,
        location?.state?.edit
      )}

      <span className={styles.input_label}>
        {t("createCourse.productServiceName")}*
      </span>
      <input
        type="text"
        value={courseName}
        className={styles.input_field}
        placeholder={t("createCourse.productServiceNamePH")}
        onChange={(e) => setCourseName(e.target.value)}
      />
      <span className={styles.input_label}>
        {t("createCourse.productServiceSubtitle")}*
      </span>
      <input
        type="text"
        value={courseSubtitle}
        className={styles.input_field}
        placeholder={t("createCourse.productServiceSubtitlePH")}
        onChange={(e) => setCourseSubtitle(e.target.value)}
      />
      {!isFree && <>   <span className={styles.input_label}>
        {t("createCourse.price")}*
      </span>
        <input
          type="number"
          value={coursePriceValue}
          className={styles.input_field}
          placeholder={t("createCourse.pricePH")}
          onChange={(e) => setCoursePriceValue(e.target.value)}
        />
        <span className={styles.input_label}>
          {t("createCourse.discountedPrice")}*
        </span>
        <input
          type="number"
          value={courseDiscountedPrice}
          className={styles.input_field}
          placeholder={t("createCourse.discountedPricePH")}
          onChange={(e) => setCourseDiscountedPrice(e.target.value)}
        /></>}
      <span className={styles.input_label}>
        {t("courseMonetisation.description")}*
      </span>
      <textarea
        rows="4"
        type="text"
        value={courseDescription}
        className={styles.textbox_field}
        placeholder={t("createCourse.descPH")}
        onChange={(e) => setCourseDescription(e.target.value)}
        defaultValue={props.courseDescription}
      />

      <div className={styles.radio_group}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={
              courseTitleType == "Edit"
                ? currentCourse?.free
                  ? "free"
                  : "paid"
                : "free"
            }
            onChange={(e) => {
              setIsFree(e.target.value == "free");
            }}
          >
            <FormControlLabel
              sx={{ fontFamily: "Montserrat" }}
              disabled={courseTitleType == "Edit"}
              value={"free"}
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.Mui-checked": { color: "#00d498" },
                    marginRight: "0px",
                    fontFamily: "Montserrat",
                    "&.Mui-disabled": {
                      color: "#959595",
                    },
                  }}
                />
              }
              label={t("common.free")}
            />

            <FormControlLabel
              sx={{ fontFamily: "Montserrat" }}
              disabled={courseTitleType == "Edit"}
              value={"paid"}
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.Mui-checked": { color: "#00d498" },
                    marginRight: "0px",
                    fontFamily: "Montserrat",
                    "&.Mui-disabled": {
                      color: "#959595",
                    },
                  }}
                />
              }
              label={t("common.paid")}
            />
          </RadioGroup>
        </FormControl>
      </div>
      {/* 
      {!isFree && (
        <div>
          <h3 for="description" className={styles.input_label}>
            {t("createCourse.price")}*
          </h3>
           <div className={styles.price_div}>
            <input
              type="text"
              id="priceType"
              className={styles.price_type_input}
              value={`${t("createCourse.INR")}`}
              disabled
            />
            <input
              type="number"
              id="price"
              onChange={(e) => setCoursePriceValue(e.target.value)}
              className={styles.price_input}
              placeholder={"123"}
              value={coursePriceValue}
              disabled={courseTitleType == "Edit"}
            />
          </div> 
        </div>
      )} */}

      <div className={styles.btn_div}>
        {courseTitleType == "Edit" && (
          <>
            <Button
              type="solid"
              label={
                isLoadingNextBtn ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Update"
                )
              }
              disable={isLoadingNextBtn || isLoadingSaveBtn}
              clickHandler={() => {
                handleEdit("edit")
              }

                // courseTitleType != "Edit"
                //   ? () => setShowConfirmModal(true)
                //   : () => {
                //     setIsLoadingNextBtn(true);
                //     handleNextAndSave("next");
                //   }
              }
            /></>
          // <Button
          //   type="solid"
          //   // label={t("assignment.save")}
          //   label={
          //     isLoadingSaveBtn ? (
          //       <CircularProgress color="inherit" size={20} />
          //     ) : (
          //       t("assignment.save")
          //     )
          //   }
          //   disable={isLoadingNextBtn || isLoadingSaveBtn}
          //   clickHandler={() => {
          //     // setIsLoadingSaveBtn(true);
          //     // handleNextAndSave("save");
          //     setShowConfirmModal(true);
          //   }}
          // />
        )}
        {courseTitleType !== "Edit" && <Button
          type="solid"
          label={
            isLoadingNextBtn ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              t("common.post")
            )
          }
          disable={isLoadingNextBtn || isLoadingSaveBtn}
          clickHandler={() => {
            handleNextAndSave("next")
          }

            // courseTitleType != "Edit"
            //   ? () => setShowConfirmModal(true)
            //   : () => {
            //     setIsLoadingNextBtn(true);
            //     handleNextAndSave("next");
            //   }
          }
        />}
      </div>
    </div>
  );
};

export default CreateCoursePage;
