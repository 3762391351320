import { handleErrorMessage } from "./groups";
import { handleSuccessMessage } from "./success";
import axios from "axios";

export const forgotPassword = (emailData) => {
  emailData = { email: emailData };
  return async (dispatch) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/`,
      data: emailData,
    })
      .then((response) => {
        if (response?.data?.data.status)
          dispatch(
            handleSuccessMessage("Password reset link sent successfully")
          );
      })
      .catch(async (error) => {
        const errorObj = error.response?.data.error;
        const keys = errorObj && Object.keys(errorObj);
        dispatch(
          handleErrorMessage(
            errorObj[keys[0]] || "Error to process the request!"
          )
        );
      });
  };
};

export const resetPassword = (payload, onSuccess, onError) => {
  return async (dispatch) => {
    let data = new FormData();
    data.append("token", payload.token);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/validate_token/`,
      data: data,
    })
      .then((response) => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/confirm/`,
          data: payload,
        })
          .then((res) => {
            onSuccess(res);
          })
          .catch((error) => {
            onError(error);
          });
      })
      .catch(async (error) => {
        onError(error);
      });
  };
};
