import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { DiscoverContext } from "../../Discover/DiscoverContext";
import styles from "./CSSFiles/SellDisplay.module.css";

const categoryList = [
  "Appliances",
  "Automotive",
  "Baby & kids",
  "Bicycles",
  "Clothing & accessories",
  "Electronics",
  "Furniture",
  "Garden",
  "Home decor",
  "Home sales",
  "Musical instruments",
  "Neighbour made",
  "Neighbour services",
  "Other",
  "Pet supplies",
  "Property rentals",
  "Sports & outdoors",
  "Tickets",
  "Tools",
  "Toys & games",
];

const SellDisplay = ({
  sellPost,
  images,
  title,
  description,
  price,
  discountedPrice,
  discountOffer,
  actionType,
  setShowSellModal,
  isFree,
  location,
  category,
  showEdit = true,
  postId,
}) => {
  const { MarkInterested } = useContext(DiscoverContext);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 1 }}
    >
      <Paper
        // variant="outlined"
        elevation={0}
        sx={{ width: "97%", borderRadius: "8px", position: "relative" }}
      >
        {showEdit && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              px: 3,
              mb: 1,
              alignItems: "center",
            }}
          >
            {category && (
              <>
                <img
                  src={`/Image/CategoryIcons/${category}.svg`}
                  alt="select category"
                  style={{ marginRight: 4, width: "20px" }}
                />
                <Typography sx={{ fontWeight: 600 }}>{category}</Typography>
              </>
            )}
          </Box>
        )}
        {sellPost?.photoUrls && (
          <Carousel
            responsive={responsive}
            showDots={true}
            arrows={false}
            swipeable={true}
            draggable={true}
          >
            {sellPost?.photoUrls.map((photo, index) => (
              <img
                key={index}
                src={photo.src}
                alt="sellImage"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  maxHeight: "400px",
                  height: "100%",
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                }}
              />
            ))}
          </Carousel>
        )}
        {showEdit && (
          <IconButton
            disableRipple
            onClick={() => setShowSellModal(true)}
            sx={{
              background: "#666",
              padding: "5px",
              position: "absolute",
              right: 11,
              top: 9,
            }}
          >
            <EditIcon fontSize="small" sx={{ color: "#fff" }} />
          </IconButton>
        )}
        {images && (
          <Carousel
            responsive={responsive}
            showDots={true}
            arrows={false}
            swipeable={true}
            draggable={true}
            dotListClass={styles.custom_dot_list_style}
          >
            {images.map((img, index) => (
              <img
                key={index}
                src={img.image}
                alt="sellImage"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  maxHeight: "400px",
                  height: "100%",
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                }}
              />
            ))}
          </Carousel>
        )}

        <Box sx={{ p: "16px 24px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: { xs: "60%", sm: "70%" } }}>
              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: 600,
                  lineHeight: "25px",
                }}
              >
                {title ? title : sellPost?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "15px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src="/Image/DashImage/RightSidebar/connect_icon.svg"
                  alt="fire icon"
                  style={{
                    width: "9px",
                    filter: "grayscale(100%)",
                    marginRight: 5,
                  }}
                />
                {location ? location : sellPost?.pickLocation}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  p: "7px 16px",
                  background: "#319b47",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {sellPost?.isFree === true || isFree === true ? (
                  <Typography
                    sx={{
                      fontSize: "17px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      color: "#fff",
                    }}
                  >
                    Free
                  </Typography>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/Image/Discover/Sale/Asset 2.svg"
                        alt="rupee icon"
                        style={{
                          width: "9px",
                          marginRight: 8,
                        }}
                      />
                      {discountedPrice || sellPost?.discountedPrice
                        ? discountedPrice
                          ? discountedPrice
                          : sellPost?.discountedPrice
                        : price
                        ? price
                        : sellPost?.price}{" "}
                    </Typography>
                  </>
                )}
              </Box>
              {discountedPrice !== "" && sellPost?.discountedPrice !== "" && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      color: "#000",
                      textDecoration:
                        discountedPrice !== "" &&
                        sellPost?.discountedPrice !== ""
                          ? "line-through"
                          : "none",
                    }}
                  >
                    ₹{price ? price : sellPost?.price}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      color: "#000",
                    }}
                  >
                    {discountOffer ? discountOffer : sellPost?.discountOff}% Off
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: { xs: "55%", sm: "75%" } }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  textAlign: "left",
                  color: "#000",
                }}
              >
                Description
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                  color: "#656565",
                }}
              >
                {description ? description : sellPost?.desc}
              </Typography>
            </Box>
            <Box
              sx={{
                p: "7px 12px",
                background: "#d71a62",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                onClick={() => MarkInterested(postId)}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src="/Image/Discover/Sale/Asset 1.svg"
                  alt="message icon"
                  style={{
                    width: "17px",
                    marginRight: 8,
                  }}
                />
                Interested
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SellDisplay;
