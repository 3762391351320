import ChatSearch from "./ChatSearch";
import styles from "./css/ChatListHeader.module.css";
import ChatPill from "./ChatPill";

function ChatListHeader(props) {
    const {
        chatListType = "normal",
        showSuggestions = false,
        data = [],
        removeItemInNewMessageIds = () => {},
        handleNewMessageClick = () => {},
    } = props;

    return (
        <div className={`${styles.chatSearchWrapper} p-3`}>
            <ChatSearch {...props} />
            {chatListType === "new_message" &&
                showSuggestions &&
                !data.length && <p className="m-1 py-2">Suggestions</p>}
            {data && data.length ? (
                <>
                    <div className="d-flex gap-2 my-2 flex-wrap ">
                        {data.map((person) => {
                            return (
                                <ChatPill
                                    person={person}
                                    removeItemInNewMessageIds={
                                        removeItemInNewMessageIds
                                    }
                                />
                            );
                        })}
                    </div>
                    <button
                        className="btn border p-1"
                        onClick={handleNewMessageClick}
                    >
                        Send message
                    </button>
                </>
            ) : null}
        </div>
    );
}

export default ChatListHeader;
