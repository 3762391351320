import styles from "./css/SearchPlaceholder.module.css";

function SearchPlaceholder() {
    return (
        <div className={styles.placeholderWrapper}>
            <img
                height="200"
                width="200"
                src="/Image/chat/SearchPlaceholder.svg"
            />
            <p>
                Search your messages by recipient, content, or conversation name
            </p>
        </div>
    );
}

export default SearchPlaceholder;
