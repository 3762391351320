import React, { setState } from "react";
import styles from "./groupcss/Imagechangecover.module.css";
import { KeyboardArrowDown, Star } from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import { Icon } from "@material-ui/core";
import CourseModal from "../CreateClass/CourseModal";
import { connect } from "react-redux";
import { updateClass } from "../../../redux/actions/classes";
import { withTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';

class CoverFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListOpen: false,
      showAgendaModal: false,
      class_id_url: this.props.match.params.id,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleDropDownList = this.handleDropDownList.bind(this);
  }
  handleImageChange(event) {
    let cover_image = {
      file: event.target.files[0],
      fileUrl: URL.createObjectURL(event.target.files[0]),
    };
    cover_image = {
      ...cover_image,
      id: this.props.class_id,
      updateType: this.props.routeType,
    };
    this.props.updateClass(cover_image);
    // .then(() => this.props.getActiveClassDetails(this.props.class_id));
  }
  handleDropDownList = (event) => {
    this.setState({ isListOpen: !this.state.isListOpen });
  };
  CourseModalOpener = () => {
    this.setState({ showAgendaModal: true });
  };
  CourseModalCloser = () => {
    this.setState({ showAgendaModal: false });
  };
  // --------
  componentDidMount() {
    // const classid = this.props.match.params.id;
  }

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          height: 180,
          borderRadius: 8,
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 180,
            overflow: "hidden",
            borderRadius: 10,
          }}
        >
          <img
            alt=""
            src={this.props.imageCover}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <CourseModal
          title={t("classGroup.addAgenda")}
          routeType={this.props.routeType}
          closeModal={this.CourseModalCloser}
          show={this.state.showAgendaModal}
        />
        <div
          style={{
            padding: 8,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 10,
            position: "absolute",
            objectFit: "contain",
          }}
          className={styles.topLeft}
        >
          <h3
            style={{
              color: "black",
              font: "Montserrat",
              textTransform: "capitalize",
            }}
          >
            {this.props.className1}
          </h3>
          <p style={{ color: "black", font: "Montserrat", marginTop: 4 }}>
            <span className={styles.mediaQueryBreak}>
              {this.props.userName}
            </span>
            {/* <span className={styles.subDash}>|</span>
            <span className={styles.mediaQueryBreak}>{this.props.grade}</span>
            <span className={styles.subDash}>|</span>
            <span className={styles.mediaQueryBreak}>{this.props.subject}</span> */}
          </p>
        </div>
        <div
          style={{
            padding: 8,
            position: "absolute",
            bottom: 8,
            left: 16,
          }}
        >
          <h4
            style={{
              color: "black",
              font: "Montserrat",
              textTransform: "capitalize",
            }}
          >
            {this.props.routeType == "Class"
              ? t("class.class")
              : t("group.group")}{" "}
            {t("common.code")} :{" "}
            <span style={{ textTransform: "none" }}>
              {this.props.classCode}
            </span>
          </h4>
        </div>

        <div
          style={{
            padding: 8,
            backgroundColor: "#fff",
            borderRadius: 30,
            cursor: "pointer",
            height: 15,
            width: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            position: "absolute",
            objectFit: "contain",
            top: 8,
            right: 16,
          }}
        >
          <label htmlFor="iconimage-cover">
            <div>
              <img
                src="/Image/DashImage/camera.svg"
                alt=" "
                height="15px"
                width="15px"
                style={{ cursor: "pointer", marginTop: 3 }}
              />
            </div>
          </label>

          <input
            type="file"
            className={styles.inputfield_cover}
            id="iconimage-cover"
            onChange={this.handleImageChange}
            style={{ display: "none", cursor: "pointer", objectFit: "contain" }}
            accept="image/png, image/jpeg, image/svg"
          />
        </div>
        <div
          style={{
            padding: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            objectFit: "contain",
            bottom: 8,
            right: 16,
          }}
        >
          <div className={styles.dropdown}>
            <button
              onClick={this.handleDropDownList}
              className={styles.button1}
            >
              <AddIcon />
              {/* <div className={styles.createText}>{t("common.create")}</div> */}
              {/* <KeyboardArrowDown /> */}
            </button>
            {this.state.isListOpen ? (
              <div className={styles.dropdownMenu1}>
                <div style={{ padding: 8 }}>
                  <div
                    onClick={this.CourseModalOpener}
                    className={styles.selectionOption}
                  >
                    <img
                      alt=""
                      src="/Image/class/agenda.svg"
                    // style={{ marginRight: 10 }}
                    />
                    {t("class.agenda")}
                  </div>
                </div>
                <hr className={styles.Hr3} />
                <Link
                  to={
                    this.props.accountType == "PAID"
                      ? "/video"
                      : this.props.accountType == "FREE"
                        ? "/zoomconfiguration"
                        : "/subscription"
                  }
                >
                  <div
                    style={{ fontSize: 14, padding: 8 }}
                    className={styles.selectionOption}
                  >
                    <img
                      alt=""
                      src="/Image/class/start_class.svg"
                    // style={{ marginRight: 10 }}
                    />
                    {this.props.routeType === "Class"
                      ? t("class.startClass")
                      : t("group.startSession")}
                  </div>
                </Link>
                {/* --- assignment and quiz */}
                {/* {this.props?.isOwner ? (
                  <>
                    <hr className={styles.Hr3} />
                    <Link
                      to={{
                        pathname: `/assignment/create/${this.state.class_id_url}`,
                        state: {
                          routeType: this.props.routeType.toLowerCase(),
                          isOwner: this.props?.isOwner,
                        },
                      }}
                    >
                      <div
                        style={{ padding: 8 }}
                        className={styles.selectionOption}
                      >
                        <img
                          alt=""
                          src="/Image/assignment/assignment.svg"
                          style={{ height: "20px" }}
                        />
                        {t("assignment.assignment")}
                      </div>
                    </Link>

                    <hr className={styles.Hr3} />

                    <Link
                      to={{
                        pathname: `/quiz/create/${this.state.class_id_url}`,
                        state: {
                          routeType: this.props.routeType.toLowerCase(),
                          isOwner: this.props?.isOwner,
                        },
                      }}
                    >
                      <div
                        style={{ padding: 8 }}
                        className={styles.selectionOption}
                      >
                        <img
                          alt=""
                          src="/Image/assignment/Quiz.svg"
                          style={{ height: "20px" }}
                        />
                        {t("quiz.quiz")}
                      </div>
                    </Link>
                  </>
                ) : null} */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    isOwner:
      window.location.pathname.split("/")[1] === "group"
        ? state?.groups?.group?.group_owner === state?.profile?.profileId
        : state?.profile?.user?.user_subtype == "FACULTY",
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateClass: (coverImage) => dispatch(updateClass(coverImage)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CoverFeed))
);
