import React, { useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";
import IconTitle from "../../components/commons/IconTitle";
import styles from "./css/ViewAssignmentGradingPage.module.css";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import StudentNameComponent from "./Components/StudentNameComponent";
import Modal from "../../components/commons/Modal";
import { formatDate } from "../../redux/constants/commonFunctions";
import MarksReturnModal from "./Modals/MarksReturnModal";
import {
  saveAssignmentMarks,
  returnAssignmentMarks,
  getAllAssignmentSubmissions,
  getIndividualAssignment,
  getAllFilteredAssignmentSubmissions,
} from "../../redux/actions/assignment";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
  addPrivateComment,
  getAllFilteredQuizSubmissions,
  returnQuizMarks,
  saveQuizMarks,
  getPrivateComment,
} from "../../redux/actions/quiz";
import IndividualComment from "../../components/assignmentAndQuiz/IndividualComment";
import { handleErrorMessage } from "../../redux/actions/classes";
import { CircularProgress } from "@mui/material";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";

const ViewAssignmentGradingPage = (props) => {
  const history = useHistory();
  const params = useParams();
  const assignmentID = params.id;
  const location = useLocation();

  const [marksReturnModalOpen, setMarksReturnModalOpen] = useState(false);
  const [assignmentMarks, setAssignmentMarks] = useState(
    location?.state?.marks
  );
  const [index, setIndex] = useState(null);
  const { t } = useTranslation();
  const submission_id = location?.state?.submission_id || 0;
  const routeType = location?.state?.routeType;
  const isOwner =  location?.state?.isOwner ?? false;
  const showFourZeroFour = !isOwner

  const [submissionId, setSubmissionId] = useState(submission_id);
  const [privateComment, setPrivateComment] = useState(" ");
  const [isLoading, setIsLoading] = useState(false)

  const initialList = JSON.parse(JSON.stringify(location.state?.filtered_submissions ?? []))
  const [filteredSubmissionList, setFilteredSubmissionList] = useState(initialList)
  const [isCommentLoading, setIsCommentLoading] = useState(true);
    

  const handleCommentSubmission = (e) => {
    props
      .addPrivateComment(
        props.assignmentType,
        privateComment,
        assignmentID,
        filteredSubmissionList[index]?.students
      )
      .then(() => {
        props.getPrivateComment(
          props.assignmentType,
          assignmentID,
          filteredSubmissionList[index]?.students
        );
      });
    setPrivateComment("");
  };

  useEffect(() => {
    const currentIndex = filteredSubmissionList
            .map((e) => e.id)
            .indexOf(submissionId);
          setIndex(currentIndex);
  }, []);

  useEffect(() => {
    if (index !== null) {
      props.getPrivateComment(
        props.assignmentType,
        assignmentID,
        filteredSubmissionList[index]?.students
      );
      setIsCommentLoading(false);
    }
  }, [index]);

  // --------------------------------------

  const attachment_count =
    filteredSubmissionList[index]?.file_submitted === null &&
    filteredSubmissionList[index]?.url === null
      ? 0
      : filteredSubmissionList[index]?.file_submitted !== null &&
        filteredSubmissionList[index]?.url !== null
      ? 2
      : 1;

  const marksReturnModalClose = () => {
    setMarksReturnModalOpen(false);
  };

  const returnMarksHandler = () => {
    let marksList = [];
    marksList.push(submissionId);
    let data = {};
    data.submission_list = marksList;
    if (props.assignmentType == "QUIZ") {
      props.returnQuizMarks(data).then(()=> {
        setAssignmentMarks('');
    });
    } else {
      props.returnAssignmentMarks(data).then(()=> {
        setAssignmentMarks('');
      });
    }
    setMarksReturnModalOpen(false);
  };

  const onClickLeftArrow = () => {
    setIndex(index - 1);
    setSubmissionId(filteredSubmissionList[index - 1]?.id);
    if (filteredSubmissionList[index - 1]?.marks != null) {
      setAssignmentMarks(filteredSubmissionList[index - 1]?.marks);
    } else {
      setAssignmentMarks("");
    }
  };

  const onClickRightArrow = () => {
    setIndex(index + 1);
    setSubmissionId(filteredSubmissionList[index + 1]?.id);
    if (filteredSubmissionList[index + 1]?.marks != null) {
      setAssignmentMarks(filteredSubmissionList[index + 1]?.marks);
    } else {
      setAssignmentMarks("");
    }
  };

  const saveButtonHandler = () => {
    switch (assignmentMarks) {
      case null:
      case undefined:
      case "":
        props.handleErrorMessage(
          t("errorMessageTranslations.enterValidNumber")
        );
        break;
      default:
        if (!isNaN(assignmentMarks)) {
          if (
            assignmentMarks >= 0 &&
            assignmentMarks <= location?.state?.maxMarks
          ) {
            setIsLoading(true);
            let data = {};
            data.marks = assignmentMarks;
            data.id = submissionId;
            props.assignmentType === "QUIZ"
              ? props.saveQuizMarks(data).then(() => setIsLoading(false))
              : props.saveAssignmentMarks(data).then(() => setIsLoading(false));
          } else {
            assignmentMarks < 0
              ? props.handleErrorMessage(
                  t("errorMessageTranslations.marksCannotZero")
                )
              : props.handleErrorMessage(
                  t("errorMessageTranslations.marksCannotGreaterThanMaxmarks")
                );
          }
        }
    }
  };

  const getAsgStatus = (status) => {
    let statusDesc = "";
    switch (status) {
      case "GRD":
        statusDesc = t("viewAssignment.graded");
        break;
      case "AS":
        statusDesc = t("viewAssignment.assigned");
        break;
      case "SUB":
        statusDesc = t("viewAssignment.submitted");
        break;
      case "DL":
        statusDesc = t("viewAssignment.doneLate");
        break;
      default:
        statusDesc = t("viewAssignment.assigned");
        break;
    }
    return statusDesc;
  };

  // ------------------- Return PART begins -------

  return (
    <>
      {showFourZeroFour ? <ErrorFourZeroFour/> :
          <div className={styles.main_container}>
          {marksReturnModalOpen ? (
            <Modal
              card={
                <MarksReturnModal
                  stdCount="1"
                  changeEdit={marksReturnModalClose}
                  returnMarks={returnMarksHandler}
                  modalType={'return'}
                />
              }
            />
          ) : null}
    
          <p className={styles.back_btn} onClick={() => {
            // history.goBack()
            const type = window.location.pathname.split("/")[1];
            history.push({
              pathname: `/${type}/status/${props.asgId}`,
              state: {routeType: routeType, isOwner: isOwner},
            });
          }}>
            <img alt="" src="/Image/assignment/back_arrow.svg" />
            &nbsp; &nbsp;{location?.state?.className ||t("common.back")}
          </p>
          {/* ---------------- */}
          <div className={styles.center_page_container}>
            <div className={styles.title_container}>
              <IconTitle
                title={location?.state?.assignmentTitle || ""}
                img="/Image/assignment/White/Assignment.svg"
                notClickable={true}
                type="ASSIGNMENT"
              />
            </div>
    
            <div className={styles.student_header_container}>
              <div className={styles.student_name}>
                <div className={styles.student_name_container}>
                  <div className={styles.left_arrow_name}>
                    <div style={index > 0 ? { cursor: "pointer" } : null}>
                      <img
                        alt=""
                        onClick={index > 0 ? onClickLeftArrow : null}
                        className={styles.left_arrow}
                        src={
                          index > 0
                            ? "/Image/assignment/White/left arrow-active.svg"
                            : "/Image/assignment/White/left arrow.svg"
                        }
                      />
                    </div>
    
                    <StudentNameComponent
                      profilePic={
                        filteredSubmissionList[index]?.profile_pic?.profile_pic
                      }
                      studentName={filteredSubmissionList[index]?.student_name}
                    />
                  </div>
                  <div
                    className={styles.right_arrow}
                    style={
                      index < filteredSubmissionList.length - 1
                        ? { cursor: "pointer" }
                        : null
                    }
                  >
                    <img
                      alt=""
                      onClick={
                        index < filteredSubmissionList.length - 1
                          ? onClickRightArrow
                          : null
                      }
                      src={
                        index < filteredSubmissionList.length - 1
                          ? "/Image/assignment/White/right arrow-active.svg"
                          : "/Image/assignment/White/right arrow.svg"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.return_btn}>
                {/* {assignmentMarks == '' ? 'blank': 'notblank'}<br/> */}
                {/* {(assignmentMarks != null && assignmentMarks != ''  )? 'enable' : 'diabsle'} */}
                {/* {assignmentMarks != "" && assignmentMarks != null ? ( */}
                  <Button
                    type="solid"
                    label={t("assignment.return")}
                    clickHandler={(assignmentMarks != "" && assignmentMarks != null) ?
                      ()=>{setMarksReturnModalOpen(true)}
                      : null}
                    disable={assignmentMarks != "" && assignmentMarks != null ? false : true}
                  />
             {/* ) : (
                  <Button
                    label={t("viewAssignment.return")}
                    type="solid"
                    disable={true}
                  />    */}
                {/* )} */}
              </div>
            </div>
    
            {/* <div className={styles.assignment_detail_outer_container}> */}
            <div className={styles.assignment_details_container}>
              <div className={styles.assignment_status}>
                <span>
                  {attachment_count} {t("common.attachments")}
                </span>
                <span>
                  {getAsgStatus(
                    filteredSubmissionList[index]?.submission_status
                  )}
                </span>
                <span className={styles.submission_date}>
                  <span>
                    {t("viewAssignment.submittedOn")}{" "}
                    {formatDate(filteredSubmissionList[index]?.submission_date)}
                  </span>
                </span>
              </div>
    
              <div className={styles.assignment_right_and_left}>
                <div className={styles.assignment_details_left_container}>
                  <div className={styles.attachment_container_div}>
                    {filteredSubmissionList[index]?.file_submitted != null ? (
                      <>
                        <AttachmentContainer
                          assignmentFileType="FILE"
                          fileName={filteredSubmissionList[index]?.file_submitted
                            ?.split("/")
                            .pop()}
                          file={filteredSubmissionList[index]?.file_submitted}
                          pageType=""
                        />
                        <br />
                      </>
                    ) : null}
                    {filteredSubmissionList[index]?.url != null ? (
                      <AttachmentContainer
                        assignmentFileType="LINK"
                        link={filteredSubmissionList[index]?.url}
                        pageType=""
                      />
                    ) : null}
                  </div>
                  {props.comments?.length > 0 ? (
                    <div className={styles.comment_container}>
                      <p className={styles.comment_heading}>
                        {t("common.comments")}
                      </p>
                      {props?.comments?.map((comment, index) => (
                        <IndividualComment 
                          comment={comment?.description}
                          key={comment?.id}
                          senderName={comment?.sender_name}
                          profilePic={comment?.owner_pic}
                          time={comment?.timestamp}
                          isCommentLoading={isCommentLoading}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
    
                <div className={styles.assignment_details_right_container}>
                  <div className={styles.grade_container}>
                    <p>{t("assignment.grade")}</p>
                    <div className={styles.grade_and_save}>
                      <div className={styles.grade_input_container}>
                        <input
                          type="number"
                          className={styles.grade_input}
                          value={assignmentMarks}
                          onChange={(e) => {
                            setAssignmentMarks(e.target.value);
                          }}
                        />
                        /
                        <span className={styles.max_marks_text}>
                          {location?.state?.maxMarks || 0}
                        </span>
                      </div>
    
                      <div className={styles.assignment_save_button}>
                        <Button
                          // label={t("common.save")}
                          label={isLoading ? <CircularProgress color="inherit" size={15} /> : t("common.save")}
    
                          clickHandler={isLoading ? null : saveButtonHandler}
                        />
                      </div>
                    </div>
                  </div>
    
                  {/* BELOW CODE COMMENTED, TO BE USED LATER  */}
    
                  <div className={styles.work_div}>
                    <p>{t("assignment.addPrivateComment")}</p>
                    <input
                      type="text"
                      className={styles.private_comment_input}
                      onChange={(e) => {
                        setPrivateComment(e.target.value);
                      }}
                      value={privateComment}
                    />
                    <div className={styles.assignment_add_button}>
                      <Button
                        clickHandler={handleCommentSubmission}
                        label={t("common.add")}
                      />
                    </div>
                  </div>
    
                  {/* x------------------x  */}
                  {/* {console.log("The Game:",index, filteredSubmissionList)} */}
    
                  {props.comments?.length > 0 ? (
                    <div className={styles.comment_container_mobile}>
                      <p className={styles.comment_heading}>
                        {t("common.comments")}
                      </p>
                      {props?.comments?.map((comment, index) => (
                        <IndividualComment 
                        comment={comment?.description}
                      key={comment?.id}
                      senderName={comment?.sender_name}
                      profilePic={comment?.owner_pic}
                      time={comment?.timestamp}
                      isCommentLoading={isCommentLoading}
                    />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
    
  );
};

const mapStateToProps = (state, props) => {
  return {
    comments: window.location.pathname.split("/")[1] === "assignment"
        ? state?.assignment?.comments
        : state?.quiz?.comments,
    asgId : window.location.pathname.split("/")[1] === "assignment" 
        ? state?.assignment?.assignment?.id : state?.quiz?.quizInd?.id, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAssignmentMarks: (data) => dispatch(saveAssignmentMarks(data)),
    getAllFilteredAssignmentSubmissions: (id) =>
      dispatch(getAllFilteredAssignmentSubmissions(id)),

    returnAssignmentMarks: (submission_list) =>
      dispatch(returnAssignmentMarks(submission_list)),

    saveQuizMarks: (data) => dispatch(saveQuizMarks(data)),
    returnQuizMarks: (submission_list) =>
      dispatch(returnQuizMarks(submission_list)),
    getAllFilteredQuizSubmissions: (id) =>
      dispatch(getAllFilteredQuizSubmissions(id)),
    addPrivateComment: (type, comment, asgId, studentId) =>
      dispatch(addPrivateComment(type, comment, asgId, studentId)),
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    getPrivateComment: (type, asgId, studentId) =>
      dispatch(getPrivateComment(type, asgId, studentId)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ViewAssignmentGradingPage)
);
// 