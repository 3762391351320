import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import SaveButton from "../../../../../../components/commons/SaveButton";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileData } from "../../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../../redux/constants/actionTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 2,
};

export default function AddBusinessOffering({ edit, setEdit }) {
  const dispatch = useDispatch();
  const businessWebUrl = useSelector((state) => state.profile.business_web_url);
  const businessDelivery = useSelector((state) => state.profile.delivery);
  const businessStatus = useSelector((state) => state.profile.status);
  const businessTakeout = useSelector((state) => state.profile.take_away);
  const businessOpeningHours = useSelector(
    (state) => state.profile.opening_hours
  );
  const [url, setUrl] = useState(businessWebUrl || "");
  const [takeout, setTakeout] = useState(
    businessTakeout === "True" ? true : false || false
  );
  const [delivery, setDelivery] = useState(
    businessDelivery === "True" ? true : false || false
  );
  const [status, setStatus] = useState(businessStatus || "OPEN");
  const [openingHours, setOpeningHours] = useState(businessOpeningHours || "");

  useEffect(
    () => {
      if (businessWebUrl) {
        setUrl(businessWebUrl);
      }
      if (businessDelivery === "True") {
        setDelivery(true);
      } else {
        setDelivery(false);
      }
      if (businessStatus) {
        setStatus(businessStatus);
      }
      if (businessTakeout === "True") {
        setTakeout(true);
      } else {
        setTakeout(false);
      }
      if (businessOpeningHours) {
        setOpeningHours(businessOpeningHours);
      }
    },
    [businessWebUrl, businessDelivery, businessStatus],
    [businessOpeningHours],
    [businessTakeout]
  );

  const SaveButtonOnClick = () => {
    let data = new FormData();
    data.append("business_web_url", url);
    data.append("take_away", takeout ? "True" : "False");
    data.append("delivery", delivery ? "True" : "False");
    data.append("status", status);
    data.append("opening_hours", openingHours);
    dispatch(updateProfileData(EDIT_PROFILE_DATA, data)).then((res) => {
      if (res.val) {
        return;
      }
    });
    setEdit(false);
  };

  return (
    <div>
      <Modal
        open={edit}
        onClose={() => setEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Business Description
          </Typography>

          <Box>
            <Typography>URL</Typography>
            <TextField
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              sx={{
                width: "100%",
                "& label.Mui-focused": {
                  color: "#868686",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "1px solid #868686",
                  },
                },
              }}
              inputProps={{
                style: { padding: "8px 12px" },
              }}
              InputProps={{
                sx: { borderRadius: "25px" },
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Takeout Available</Typography>
            <Switch
              checked={takeout}
              onChange={(e) => setTakeout(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#F33070",
                },
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  backgroundColor: "#F33070",
                },
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Delivery Available</Typography>
            <Switch
              checked={delivery}
              onChange={(e) => setDelivery(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#F33070",
                },
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  backgroundColor: "#F33070",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Business Status</Typography>
            <FormControl sx={{ m: 1, width: "50%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="OPEN">Open</MenuItem>
                <MenuItem value="CLOSE">Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Typography>Opening hours</Typography>
            <TextField
              value={openingHours}
              onChange={(e) => setOpeningHours(e.target.value)}
              sx={{
                width: "100%",
                "& label.Mui-focused": {
                  color: "#868686",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "1px solid #868686",
                  },
                },
              }}
              inputProps={{
                style: { padding: "8px 12px" },
              }}
              InputProps={{
                sx: { borderRadius: "25px" },
              }}
            />
          </Box>

          <SaveButton onClick={SaveButtonOnClick} />
        </Box>
      </Modal>
    </div>
  );
}
