import { newExpression } from "@babel/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { postCourse } from "../../../redux/actions/classes";
import { handleSuccessMessage } from "../../../redux/actions/success";
import styles from "../../ClassesGroups/ClassModal/css/MiddleContain.module.css";

function CourseMiddleContainer(props) {
  const course = {
    title: "",
    src: null,
    link: "",
    class_courses: props.class_id,
    group_courses: props.group_id,
  };
  const [newCourse, setNewCourse] = useState(course);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  function addNewCourse() {
    props.postCourse(newCourse).then(() => {
      props.closeModal();
      const active_id = props.class_id != null ? props.class_id : props.group_id;
      props.getActiveClassDetails(active_id);
    });
  }
  const { t } = useTranslation()
  return (
    <div className={styles.classModal}>
      <div className={styles.thumbnailArea} style={{ position: "relative" }}>
        <div
          className={styles.uploadBox}
          style={{
            position: "relative",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            onChange={(e) => {
              setNewCourse({
                ...newCourse,
                src: {
                  photoUrl: URL.createObjectURL(e.target.files[0]),
                  photo: e.target.files[0],
                },
              });
              setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
            }}
            type="file"
            accept="image/*"
            className={styles.fileUpload}
            style={{ textAlign: "center" }}
          />

          <img src="/Image/DashboardImages/postModImages/photo.svg" alt="Photos" style={{ alignSelf: "center", marginLeft: "20px" }} />
          <h6>{t("class.addThumbnail")}</h6>
        </div>
        {thumbnailUrl != null ? <img src={thumbnailUrl} className={styles.thumbnailPic} style={{ position: "absolute", top: 0, left: 0, objectFit: "cover", borderRadius: "5px" }} /> : null}
      </div>
      <h3 className={styles.inputLabel} for="classname">
      <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/title.svg"/>{t("class.addTitle")}*</div>
      </h3>
      <input
        type="text"
        className={styles.inputField}
        placeholder={t("class.addTitleDesc")}
        value={newCourse.title}
        onChange={(event) => setNewCourse({ ...newCourse, title: event.target.value })}
      />
      <h3 for="description" className={styles.inputLabel}>
      <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/media.svg"/>{t("profile.media")}</div>
      </h3>
      <p className={styles.inputLabel}>{t("class.courseMediaDesc")}</p>
      <label for="upload_file">
        <div className={styles.saveDetailsButton} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p style={{ textAlign: "center", justifySelf: "center" }}> {t("common.upload")}</p>
        </div>
      </label>

      <input
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        id="upload_file"
        onChange={props.onFileUpload}
        style={{ display: "none", cursor: "pointer", objectFit: "contain" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <hr style={{ width: "44%", marginLeft: "15px", color: "#c2c2c2" }} />
        <p style={{ textAlign: "center" }}>{t("common.or")}</p>
        <hr style={{ width: "44%", marginRight: "15px", color: "#c2c2c2" }} />
      </div>
      <h3 className={styles.inputLabel} for="classname">
      <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/link.svg"/> {t("classGroup.addLink")}</div>
      </h3>
      <input
        type="text"
        className={styles.inputField}
        value={newCourse.link}
        onChange={(event) => setNewCourse({ ...newCourse, link: event.target.value })}
        placeholder={t("classGroup.courseLinkPlaceholder")}
      />
      <button onClick={() => addNewCourse(newCourse)} id="submitForm" className={styles.saveDetailsButton}>
      {t("common.save")}
      </button>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    class_id: state?.classes?.class?.id || null,
    group_id: state?.groups?.group?.id || null,
    successMsg: state?.success?.successMessage || "",
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postCourse: (course) => dispatch(postCourse(course)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseMiddleContainer);
