import React from "react";
import styles from "./css/AssignmentHeading.module.css";

function AssignmentHeading(props) {
  return (
    <div className={styles.headingDiv}>
      <img src="/Image/assignment/White/Assignment.svg" alt="" />
      <h4>{props.title}</h4>
    </div>
  );
}

export default AssignmentHeading;
