import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../apps/Profile/Profile.module.css";
import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { GET_PEOPLE_YOU_MAY_KNOW } from "../../redux/constants/actionTypes";
import {
  viewSentConnection,
  viewConnectionInvitations,
  acceptConnectionRequest,
  viewMyConnections,
  withdrawConnectionRequest,
  removeConnection,
  ignoreConnectionRequest,
} from "../../redux/actions/connections";
import {
  SENT_CONNECTIONS,
  CONNECTION_INVITATION,
  ACCPET_CONNECTION,
  MY_CONNECTIONS,
  WITHDRAW_CONNECTION,
  REMOVE_CONNECTION,
  IGNORE_CONNECTION,
} from "../../redux/constants/actionTypes";
import HandleApiError from "./HandleApiError";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { DiscoverContext } from "../../apps/Discover/DiscoverContext";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";

class Connections extends React.Component {
  state = {
    error: false,
    message: "",
  };

  acceptConnections(connectionId) {
    let data = this.props.viewConnectionInvitation.filter(
      (connection) => connection.id != connectionId
    );
    let acceptedConnections = this.props.viewConnectionInvitation.filter(
      (connection) => connection.id == connectionId
    );
    this.props.myConnections.push(acceptedConnections[0].from_user);
    // console.log(data, "data after accept connections")
    // console.log(acceptedConnections[0].from_user, "updated data after accept connections")
    this.props
      .acceptConnectionRequest(ACCPET_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
        // this.myConnections()
        // this.connectionInvitation()
        // this.sentConnections()
      });
  }
  withdrawConnections(connectionId) {
    let data = this.props.viewSentConnections.filter(
      (connection) => connection.id != connectionId
    );
    // console.log(data, "data after withdraw connections")
    this.props
      .withdrawConnectionRequest(WITHDRAW_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
  }
  removeConnections(profileID) {
    let data = this.props.myConnections.filter(
      (connection) => connection.id != profileID
    );
    this.props
      .removeConnection(REMOVE_CONNECTION, profileID, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
        // this.myConnections()
        // this.connectionInvitation()
        // this.sentConnections()
      });
  }
  ignoreConnections(connectionId) {
    let data = this.props.viewConnectionInvitation.filter(
      (connection) => connection.id != connectionId
    );
    // let ignoredConnections = this.props.viewConnectionInvitation.filter(connection => connection.id == connectionId)
    // console.log(ignoredConnections,"--------------")
    // this.props.myConnections.remove(ignoredConnections[0].from_user)

    this.props
      .ignoreConnectionRequest(IGNORE_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
    // this.myConnections()
    // this.connectionInvitation()
    // this.sentConnections()
  }
  myConnections() {
    this.props.viewMyConnections(MY_CONNECTIONS).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }
  connectionInvitation() {
    this.props.viewConnectionInvitations(CONNECTION_INVITATION).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }
  sentConnections() {
    this.props.viewSentConnection(SENT_CONNECTIONS).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }

  componentDidMount() {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props
      .fetchPeopleYouMayKnow(GET_PEOPLE_YOU_MAY_KNOW, token)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
    this.myConnections();
    this.connectionInvitation();
    this.sentConnections();
  }

  render() {
    const { t } = this.props;
    console.log(this.context.state.userProfiles);
    return (
      <div className={styles.root_search}>
        <div className={styles.mains_search}>
          {this.context.state.userProfiles.length !== 0 &&
            this.context.state.searched === true && (
              <div className={styles.peopleSection}>
                <div className={styles.profile_title}>
                  <div
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0px 20px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            className={styles.inner_icon}
                            src="/Image/Connections/results_icon.svg"
                            alt=" "
                          />
                        </div>
                        <div>
                          <p className={styles.icon_text}>
                            {t("common.searchResults")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.invitation_content}>
                  <div>
                    {this.context.state.userProfiles.map((item, idx) => {
                      return (
                        <div key={idx} className={styles.inner_invitation}>
                          <div style={{ display: "flex" }}>
                            <div
                              className={`${styles.center_div} ${styles.inner_invitation_img}`}
                            >
                              <img
                                src={
                                  item?.profile_data?.profile_pic ||
                                  "/Image/header/ddefault_img.svg"
                                }
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            </div>
                            <div className={styles.flex_col_cen}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <p className={styles.section_title}>
                                    {item?.profile_data?.name}
                                    <br />
                                    <span className={styles.followers}>
                                      {item?.profile_data?.designation}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <p
                                className={styles.followers}
                                style={{
                                  opacity: 0.7,
                                  color: "#555555",
                                  marginBottom: 6,
                                }}
                              >
                                {item?.profile_data?.organisation}
                              </p>
                            </div>
                          </div>
                          <div className={styles.flex_sp}>
                            <div>
                              <button
                                className={`${styles.btn} ${styles.connect_btn}`}
                              >
                                <p
                                  onClick={() =>
                                    this.props.history.push(
                                      `/profile/${item?.profile_data?.id}`
                                    )
                                  }
                                  className={styles.btn_text}
                                >
                                  {t("header.viewProfile")}
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <HandleApiError
                  error={this.state.error}
                  message={this.state.message}
                  onClose={() => this.setState({ error: false })}
                />
              </div>
            )}
          {this.context.state.userProfiles.length === 0 &&
          this.context.state.searched === true ? (
            <div>No results found</div>
          ) : null}

          <br />

          {this.props.peopleYouMayKnow.length != 0 && (
            <div className={styles.peopleSection}>
              <div className={styles.profile_title}>
                <div
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img
                          className={styles.inner_icon}
                          src="/Image/Connections/results_icon.svg"
                          alt=" "
                        />
                      </div>
                      <div>
                        <p className={styles.icon_text}>
                          {t("common.Suggestions")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.invitation_content}>
                <div>
                  {this.props.peopleYouMayKnow.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div style={{ display: "flex" }}>
                          <div
                            className={`${styles.center_div} ${styles.inner_invitation_img}`}
                          >
                            <img
                              src={
                                item.profile_pic ||
                                "/Image/header/ddefault_img.svg"
                              }
                              height="60px"
                              width="60px"
                              style={{ borderRadius: 40 }}
                            />
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.name}
                                  {item.designation !== "" && (
                                    <>
                                      <br />
                                      <span className={styles.followers}>
                                        {item.designation}
                                      </span>
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                            {item.organisation !== "" && (
                              <p
                                className={styles.followers}
                                style={{
                                  opacity: 0.7,
                                  color: "#555555",
                                  marginBottom: 6,
                                }}
                              >
                                {item.organisation}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button
                              className={`${styles.btn} ${styles.connect_btn}`}
                            >
                              <p
                                onClick={() =>
                                  this.props.history.push(`/profile/${item.id}`)
                                }
                                className={styles.btn_text}
                              >
                                {t("header.viewProfile")}
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <HandleApiError
                error={this.state.error}
                message={this.state.message}
                onClose={() => this.setState({ error: false })}
              />
            </div>
          )}

          <br />

          {(this.props.viewConnectionInvitation.length != 0 ||
            this.props.myConnections.length != 0) && (
            <div className={styles.peopleSection}>
              <div className={styles.profile_title}>
                <div
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className={styles.inner_icon}
                        src="/Image/Connections/Asset 2.svg"
                        alt=" "
                      />

                      <div>
                        <p className={styles.icon_text}>
                          {t("connections.myConnections")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.invitation_content}>
                <div>
                  {this.props.viewConnectionInvitation.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div className={styles.invitationSection}>
                          <div
                            className={`${styles.center_div} ${styles.inner_invitation_img}`}
                          >
                            {item.from_user.profile_pic != null ? (
                              <img
                                src={item.from_user.profile_pic}
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            ) : (
                              <img
                                src={"/Image/header/ddefault_img.svg"}
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            )}
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.from_user.name}
                                </p>
                                {item.from_user.designation !== "" && (
                                  <p>
                                    <br />
                                    <span className={styles.followers}>
                                      {item.from_user.designation}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                            {item.from_user.organisation !== "" && (
                              <p
                                className={styles.followers}
                                style={{
                                  opacity: 0.7,
                                  color: "#555555",
                                  marginBottom: 6,
                                }}
                              >
                                {item.from_user.organisation}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button
                              className={`${styles.btn_fade} ${styles.connect_btn_fade}`}
                            >
                              <p
                                onClick={() => this.ignoreConnections(item.id)}
                                className={styles.btn_text_fade}
                              >
                                <DeleteOutlineRoundedIcon
                                  style={{
                                    width: "22px",
                                    ":hover": { color: "#c71f3c" },
                                  }}
                                />
                                {t("connections.ignoreRequest")}
                              </p>
                            </button>
                          </div>
                          <div>
                            <Button
                              sx={{
                                backgroundColor: "#319b47",
                                padding: "5px 20px",
                                borderRadius: "16px",
                                border: "1px solid #319b47",
                                color: "#fff",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "#fff",
                                  color: "#319b47",
                                },
                                fontWeight: 600,
                              }}
                            >
                              <p
                                onClick={() => this.acceptConnections(item.id)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <PersonAddOutlinedIcon
                                  sx={{
                                    width: "22px",
                                    ":hover": { color: "#319b47" },
                                  }}
                                />
                                {t("connections.acceptRequest")}
                              </p>
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {this.props.myConnections.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div className={styles.invitationSection}>
                          <div
                            className={`${styles.center_div} ${styles.inner_invitation_img}`}
                          >
                            {item.profile_pic != null ? (
                              <img
                                src={item.profile_pic}
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            ) : (
                              <img
                                src={"/Image/header/ddefault_img.svg"}
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            )}
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.name}
                                  <br />
                                  <span className={styles.followers}>
                                    {item.designation}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p
                              className={styles.followers}
                              style={{
                                opacity: 0.7,
                                color: "#555555",
                                marginBottom: 6,
                              }}
                            >
                              {item.organisation}
                            </p>
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button
                              className={`${styles.btn_fade} ${styles.connect_btn_fade_remove}`}
                            >
                              <p
                                // onClick={() => this.props.history.push(`/profile/${item.id}`)}
                                onClick={() => this.removeConnections(item.id)}
                                className={styles.btn_text_fade}
                              >
                                <img
                                  src="/Image/Connections/Asset 4.svg"
                                  alt="accept"
                                  style={{ width: "14px" }}
                                />
                                {t("connections.removeConnection")}
                              </p>
                            </button>
                          </div>
                          <div>
                            <button
                              className={`${styles.btn} ${styles.connect_btn}`}
                            >
                              <p
                                onClick={() =>
                                  this.props.history.push(`/profile/${item.id}`)
                                }
                                className={styles.btn_text}
                              >
                                {t("header.viewProfile")}
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <br></br>
          <div
            className={styles.peopleSection}
            style={{ paddingBottom: "80px" }}
          >
            <div className={styles.profile_title}>
              <div
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className={styles.inner_icon}
                      src="/Image/Connections/Asset 3.svg"
                      alt=" "
                    />

                    <div>
                      <p className={styles.icon_text}>
                        {t("connections.sentConnections")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.invitation_content}>
              <div>
                {this.props.viewSentConnections.map((item, idx) => {
                  return (
                    <div key={idx} className={styles.inner_invitation}>
                      <div className={styles.invitationSection}>
                        <div
                          className={`${styles.center_div} ${styles.inner_invitation_img}`}
                        >
                          {item.to_user.profile_pic != null ? (
                            <img
                              src={item.to_user.profile_pic}
                              height="60px"
                              width="60px"
                              style={{ borderRadius: 40 }}
                            />
                          ) : (
                            <img
                              src={"/Image/header/ddefault_img.svg"}
                              height="60px"
                              width="60px"
                              style={{ borderRadius: 40 }}
                            />
                          )}
                        </div>
                        <div className={styles.flex_col_cen}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p className={styles.section_title}>
                                {item.to_user.name}
                                {item.to_user.designation !== "" && (
                                  <>
                                    <br />
                                    <span className={styles.followers}>
                                      {item.to_user.designation}
                                    </span>
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                          {item.to_user.organisation !== "" && (
                            <p
                              className={styles.followers}
                              style={{
                                opacity: 0.7,
                                color: "#555555",
                                marginBottom: 6,
                              }}
                            >
                              {item.to_user.organisation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.flex_sp}>
                        <div>
                          <button
                            className={`${styles.btn_fade} ${styles.connect_btn_fade}`}
                          >
                            <p
                              onClick={() => this.withdrawConnections(item.id)}
                              className={styles.btn_text_fade}
                            >
                              <DeleteOutlineRoundedIcon
                                style={{
                                  width: "22px",
                                  ":hover": { color: "#c71f3c" },
                                }}
                              />
                              {t("connections.withdrawRequest")}
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Connections.contextType = DiscoverContext;

function mapStateToProps(state) {
  return {
    peopleYouMayKnow: state.profile?.peopleYouMayKnow || [],
    viewSentConnections: state.profile?.viewSentConnections || [],
    viewConnectionInvitation: state.profile?.viewConnectionInvitation || [],
    acceptConnection: state.profile?.acceptConnection || [],
    myConnections: state.profile?.myConnections || [],
    withdrawConnection: state.profile?.withdrawConnection || [],
    removeConnection: state.profile?.removeConnection || [],
    ignoreConnectionRequest: state.profile?.ignoreConnectionRequest || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
    viewSentConnection: (action) => dispatch(viewSentConnection(action)),
    viewConnectionInvitations: (action) =>
      dispatch(viewConnectionInvitations(action)),
    acceptConnectionRequest: (action, id, data) =>
      dispatch(acceptConnectionRequest(action, id, data)),
    viewMyConnections: (action) => dispatch(viewMyConnections(action)),
    withdrawConnectionRequest: (action, id, data) =>
      dispatch(withdrawConnectionRequest(action, id, data)),
    removeConnection: (action, id, data) =>
      dispatch(removeConnection(action, id, data)),
    ignoreConnectionRequest: (action, id, data) =>
      dispatch(ignoreConnectionRequest(action, id, data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Connections))
);
