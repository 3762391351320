import React from "react";
import Modal from "../../../../../components/commons/Modal";
import AddTags from "./Modals/AddTags";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

class Tags extends React.Component {
  state = {
    edit: false,
  };

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "10px 20px",
            borderRadius: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <img src="/Image/Profile/tags.svg" alt="tag" width="20px" />
              <Typography sx={{ color: "#606060", fontSize: "17px" }}>
                {t("profile.tags")}
              </Typography>
            </Box>
            <div
              onClick={() => {
                this.setState({ edit: true });
                document.body.style.overflow = "hidden";
              }}
            >
              <img
                src="/Image/Profile/edit.svg"
                style={{ height: 17, cursor: "pointer" }}
              />
            </div>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.tagContent?.split(",").map((item, idx) => {
                return (
                  <>
                    {item != "" ? (
                      <li key={idx} className={styles.tagList}>
                        {item}
                      </li>
                    ) : null}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
        {this.state.edit ? (
          <Modal
            card={
              <AddTags
                title={t("profile.addTags")}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Tags));
