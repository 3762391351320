import styles from "./css/ChatSearch.module.css";

function ChatSearch(props) {
    const { handleChange, handleFocus, handleBlur, searchValue } = props;
    return (
        <div className={`${styles.searchboxWrapper}`}>
            <img src="/Image/DashImage/search_1_icon.svg" />
            <input
                value={searchValue}
                type="text"
                placeholder="Search"
                className="form-control-plaintext text-secondary"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    );
}

export default ChatSearch;
