import React from "react";
import { withTranslation } from "react-i18next";
import styles from "../Profile.module.css";

class SkillInterestImage extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div
        key={this.props.idx}
        className={styles.wrap_100}
        style={{ cursor: this.props.pointer ? "pointer" : "auto" }}
        // onCLick prop
        onClick={this.props.onClick}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              //idx prop
              src={this.props.image}
              height="60px"
              width="60px"
              //bgcolor prop
              style={{
                borderRadius: 40,
                margin: "5px 10px 5px 10px",
                backgroundColor: this.props.bgcolor,
                padding: 2,
              }}
            />
          </div>
          <div
            className={styles.col_7}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* textDecoration, name, color prop */}
            <p
              className={styles.section_title}
              style={{
                textDecoration: this.props.textDecoration,
                color: this.props.color,
              }}
            >
              {this.props.name}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(SkillInterestImage);
