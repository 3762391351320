import { React, Component } from "react";
import Sidebar from "./Sidebar";
import Feed from "./Feed";
import RightSidebar from "./RightSidebar";
import { FeedsProvider } from "./FeedsContext";
// import Chat from '../../components/chat/Chat';
import PremiumModal from "../premiumModal/PremiumModal";
import ClassModal from "../../ClassesGroups/ClassModal/ClassModal";
import GroupModal from "../../ClassesGroups/GroupModal/GroupModal";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./CSSFiles/Dashboard.css";
import ShareModal from "../shareModal/ShareModal";
import WelcomeModal from "../WelcomeclassModal/Welcome";
import { ChatProvider } from "../../../components/chat/ChatContext";
import { Link } from "react-router-dom";
import PostAndActivityFeed from "./PostAndActivityFeed";
import { GroupFeedsProvider } from "../../ClassesGroups/CreateClass/GroupFeedsContext";

class Home extends Component {
  state = {
    showPremiumModal: false,
    showClassModal: false,
    showGroupModal: false,
    showShareModal: false,
    showWelcomeModal: false,
    link: "ccy-beag-tdz",
    // shareLink: `${window.location.origin}/post/${this.state.link}`
  };

  PremiumModalOpener = () => {
    this.setState({ showPremiumModal: true });
    // alert("Hello");
  };

  PremiumModalCloser = () => {
    this.setState({ showPremiumModal: false });
  };

  ClassModalCloser = () => {
    this.setState({ showClassModal: false });
  };

  ClassModalOpener = () => {
    this.setState({ showClassModal: true });
  };

  GroupModalCloser = () => {
    this.setState({ showGroupModal: false });
  };

  GroupModalOpener = () => {
    this.setState({ showGroupModal: true });
  };

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };

  WelcomeModalCloser = () => {
    this.setState({ showWelcomeModal: false });
  };

  WelcomeModalOpener = () => {
    this.setState({ showWelcomeModal: true });
  };

  onLinkChange = (link) => {};

  render() {
    return (
      <div className="app">
        {/* <PopUp /> */}

        {/* App Body */}
        <div className="app-body">
          <ClassModal
            actionType="ADD"
            closeModal={this.ClassModalCloser}
            show={this.state.showClassModal}
            showWelcome={this.WelcomeModalOpener}
          />
          <GroupModal
            actionType="ADD"
            closeModal={this.GroupModalCloser}
            show={this.state.showGroupModal}
          />
          <PremiumModal
            closeModal={this.PremiumModalCloser}
            show={this.state.showPremiumModal}
          />
          <ShareModal
            closeModal={this.shareModalCloser}
            show={this.state.showShareModal}
            shareLink={`${window.location.origin}/post/${this.state.link}`}
          />
          <WelcomeModal
            closeModal={this.WelcomeModalCloser}
            show={this.state.showWelcomeModal}
          />

          <div className="container dashboardInnerContainer">
            <div className="row dashboard">
              <div className="col-xs-0 col-sm-0 col-md-3">
                <Sidebar
                  GroupModalOpener={this.GroupModalOpener}
                  ClassModalOpener={this.ClassModalOpener}
                  PremiumModalOpener={this.PremiumModalOpener}
                />
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-6 dashboardFeedMiddleRow">
                <FeedsProvider>
                  <GroupFeedsProvider>
                    {this.props.userPost === false ? (
                      <Feed shareModalOpener={this.shareModalOpener} />
                    ) : (
                      <PostAndActivityFeed
                        shareModalOpener={this.shareModalOpener}
                      />
                    )}
                  </GroupFeedsProvider>
                </FeedsProvider>
              </div>
              <div className="col-xs-0 col-sm-0 col-md-3">
                <RightSidebar GroupModalOpener={this.GroupModalOpener} />
              </div>
            </div>
          </div>
          {/* <ChatProvider>
            <Chat toggleChatList={this.props.toggleChatList} isChatListOpen={this.props.isChatListOpen}/>
          </ChatProvider> */}
        </div>
      </div>
    );
  }
}

export default Home;
