import React from "react";
import styles from "./css/SmallChatBox.module.css";
import ChatList from "./ChatList";
import ChatHeader from "./ChatHeader";

export default function SmallChatBox(props) {
    const { isChatListOpen, chatOpener, toggleChatList, dmList = [] } = props;

    const openChat = (id = "new_message", person = {}) => {
        chatOpener(id);
    };

    const openChatList = () => {
        toggleChatList();
    };

    // open new chat big box
    const handleCloseNewMessageBox = (e) => {
        e.stopPropagation();
        openChat("new_message");
    };

    return (
        <div id="div-smallchat" className={styles.outerContainer}>
            <ChatHeader
                arrowClick={openChatList}
                title={"Message"}
                handleNewMessageClick={handleCloseNewMessageBox}
            />
            {isChatListOpen && <ChatList openChat={openChat} dmList={dmList} />}
        </div>
    );
}
