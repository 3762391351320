import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";

export function fetchPeopleYouMayKnow(action, token) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/peopleyoumayknow/`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
