import React, { useState } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/ModalNew";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ModalTextField2 } from "../shared/ui/Modal/ModalTextfield";
import AddIcon from "@mui/icons-material/Add";
import DoneButton from "../shared/ui/Modal/DoneButton";
import { useTranslation } from "react-i18next";

const durations = ["1 day", "3 days", "1 week", "2 weeks"];

const buttonStyle = {
  mb: 2,
  mr: 2,
  borderRadius: "50px",
  padding: "5px 17px",
  transition: "all 0.2s",
  fontWeight: 600,
  textTransform: "capitalize",
};

const PollModal = ({ show, closeModal, updatePollPost, pollPost }) => {
  const [pollDetails, setPollDetails] = useState(
    pollPost || {
      question: "",
      options: [
        {
          name: "option1",
          desc: "",
          display: "Option 1",
          placeholder: "PollModal.option1Placeholder",
        },
        {
          name: "option2",
          desc: "",
          display: "Option 2",
          placeholder: "PollModal.option2Placeholder",
        },
      ],
      pollDuration: "1 week",
    }
  );

  const { t } = useTranslation();

  const handleDetails = (e) => {
    const { name, value } = e.target;
    if (pollDetails.options.some((opt) => opt.name === name)) {
      setPollDetails((prev) => ({
        ...prev,
        options: prev.options.map((opt) =>
          opt.name === name ? { ...opt, desc: value } : opt
        ),
      }));
    } else {
      setPollDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddOption = () => {
    const newOption = {
      name: `option${pollDetails.options.length + 1}`,
      desc: "",
      display: `Option ${pollDetails.options.length + 1}`,
      placeholder: `${t("PollModal.option3Placeholder")}`,
    };
    setPollDetails((prev) => ({
      ...prev,
      options: [...prev.options, newOption],
    }));
  };

  const handleRemoveOption = (e) => {
    console.log(e.target.id);
    setPollDetails((prev) => ({
      ...prev,
      options: prev.options.filter((opt) => opt.name !== e.target.id),
    }));
  };

  const isDoneButtonDisabled = () => {
    const option1 = pollDetails.options.find(
      (opt) => opt.name === "option1"
    ).desc;
    const option2 = pollDetails.options.find(
      (opt) => opt.name === "option2"
    ).desc;
    return pollDetails.question === "" || option1 === "" || option2 === "";
  };

  const handleSubmit = () => {
    updatePollPost(pollDetails);
    closeModal();
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          borderRadius: "0px 0px 15px 15px",
        }}
      >
        <Divider sx={{ background: "rgba(0,0,0,0.08)" }} />
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              maxHeight: { sm: "65vh", xs: "60vh" },
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              padding: "15px 20px",
              overflowX: "hidden",
            }}
          >
            <Typography sx={{ fontSize: "14px", color: "primary.black" }}>
              {t("PollModal.question")}*
            </Typography>
            <ModalTextField2
              value={pollDetails.question}
              method={handleDetails}
              name={"question"}
              multiline={true}
              rows={2}
              placeholder={t("PollModal.quesPlaceholder")}
              inputProp={{ maxLength: 140 }}
            />

            {pollDetails.options.map((opt, index) => (
              <React.Fragment key={index}>
                {["option3", "option4"].includes(opt.name) ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "14px", color: "primary.black" }}
                    >
                      {t("PollModal.option")} {index + 1}
                    </Typography>
                    <Link
                      onClick={handleRemoveOption}
                      id={opt.name}
                      sx={{
                        textDecoration: "none",
                        fontFamily: "primary.text",
                        cursor: "pointer",
                      }}
                    >
                      {t("PollModal.remove")}
                    </Link>
                  </Box>
                ) : (
                  <Typography
                    sx={{ fontSize: "14px", color: "primary.black", mt: 2 }}
                  >
                    {t("PollModal.option")} {index + 1}*
                  </Typography>
                )}
                <ModalTextField2
                  value={opt.desc}
                  method={handleDetails}
                  name={opt.name}
                  multiline={false}
                  placeholder={t(`${opt.placeholder}`)}
                  inputProp={{ maxLength: 30 }}
                />
              </React.Fragment>
            ))}

            {pollDetails.options.length < 4 && (
              <Button
                onClick={handleAddOption}
                disableRipple
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  borderColor: "secondary.main",
                  color: "secondary.main",
                  ":hover": {
                    background: "#fff",
                    borderColor: "secondary.hover",
                    color: "secondary.hover",
                  },
                  mt: 3,
                  width: { sm: "30%", xs: "100%" },
                }}
              >
                <AddIcon sx={{ mr: 1 }} />
                {t("PollModal.addOption")}
              </Button>
            )}

            <Typography sx={{ fontSize: "14px", color: "primary.black" }}>
              {t("PollModal.duration")}
            </Typography>
            <FormControl fullWidth margin="normal" sx={{ mt: 1 }}>
              <Select
                value={pollDetails.pollDuration}
                onChange={(e) =>
                  setPollDetails((prev) => ({
                    ...prev,
                    pollDuration: e.target.value,
                  }))
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "5px 14px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "secondary.main",
                  },
                }}
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: "rgba(0,116,228,0.08)",
                    },
                  },
                }}
              >
                {durations.map((duration, index) => (
                  <MenuItem
                    key={index}
                    value={duration}
                    sx={{
                      "&.MuiMenuItem-root .Mui-selected": {
                        backgroundColor: "rgba(0,116,228,0.08)",
                      },
                    }}
                  >
                    {duration}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography
              sx={{ fontSize: "14px", color: "primary.black", mt: 1 }}
            >
              {t("PollModal.message")}
            </Typography>
          </Box>

          <DoneButton
            type="submit"
            disabled={isDoneButtonDisabled()}
            backMethod={closeModal}
          />
        </form>
      </Box>
    </>
  );
};

export default PollModal;
