import {
  MEET_SUCCESS,
  MEET_FAILURE,
  HEADER,
  GET_USER_SETTINGS,
  PUT_USER_SETTINGS,
} from "../constants/actionTypes";
import axios from "axios";
import {
  GET_USER_SETTINGS_API,
  PUT_USER_SETTINGS_API,
} from "../constants/apis";
import { store } from "../store";
import { handleErrorMessage } from "./groups";
import { errorMessageFromResponse } from "../constants/commonFunctions";

export const meetSuccess = (data) => {
  return {
    type: MEET_SUCCESS,
    payload: data,
  };
};
export const meetFailure = (error) => {
  return {
    type: MEET_FAILURE,
    payload: error,
  };
};
export const headerVisible = (val) => {
  return {
    type: HEADER,
    payload: val,
  };
};

export const getUserSettings = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_USER_SETTINGS_API, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const data = {
          zoomSettings: response.data.data[0].zoom_settings,
          zoomId: response.data.data[0].id,
        };
        dispatch({ type: GET_USER_SETTINGS, payload: data });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const putUserSettings = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const id = store.getState()?.zoom?.zoom_settings?.zoomId;
  const userSettingsEndpoint = `${PUT_USER_SETTINGS_API}${id}/`;
  return async (dispatch) => {
    await axios
      .put(userSettingsEndpoint, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response.data.status == "1"
        ) {
          dispatch({ type: PUT_USER_SETTINGS, payload: data });
          return response;
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const createMeet = (Topic, Agenda) => {
  return async (dispatch) => {
    /*     let payload = JSON.stringify({
      username: `${JSON.parse(localStorage.getItem("userData")).first_name + " " + JSON.parse(localStorage.getItem("userData")).last_name}`,
      meeting_type: 1,
      topic: Topic,
      agenda: Agenda,
      source: localStorage.getItem("source"), 
      class_id: store.getState()?.classes?.class?.id,
    }); */
    const payload = new Object();
    payload.username = `${
      JSON.parse(localStorage.getItem("userData")).first_name +
      " " +
      JSON.parse(localStorage.getItem("userData")).last_name
    }`;
    payload.meeting_type = 1;
    payload.topic = Topic;
    payload.agenda = Agenda;
    payload.source = localStorage.getItem("source");
    if (localStorage.getItem("source") === "CLASS")
      payload.class_id = store.getState()?.classes?.class?.id;
    else payload.group_id = store.getState()?.groups?.group?.id;
    const APIbaseURL =
      process.env.REACT_APP_API_BASE_URL + "api/v1/zoom/create/";
    return axios
      .post(APIbaseURL, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // if (response.data.status) {
        dispatch(meetSuccess(response.data.data));
        /* 201 */
        return { val: true, data: response.data.data,signature:response.data.signature };
        // } else {
        //   return { message: response.data.message };
        // }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
