import React, { createContext, Component } from "react";
// import PostSampleImg2 from './Image/DashboardImages/DashCommonImages/postSampleImg2.png';
import { connect } from "react-redux";
import axios from "axios";
// import { handleErrorMessage } from "../../../redux/actions/classes";
import { handleErrorMessage } from "../../redux/actions/classes";
import { handleSuccessMessage } from "../../redux/actions/success";
import { CircularProgress } from "@mui/material";

export const DiscoverContext = createContext();

class DiscoverProviderComponent extends Component {
  state = {
    myFavourites: [],
    eventPosts: [],
    sellPosts: [],
    localBusiness: [],
    userProfiles: [],
    nextEventLink: null,
    prevEventLink: null,
    nextSaleLink: null,
    prevSaleLink: null,
    nextBusinessLink: null,
    prevBusinessLink: null,
    loading: true,
    firstLoad: true,
    searched: false,
  };

  componentDidMount() {
    this.getCategoryPosts(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?post_type=EVENT&limit=25`,
      "EVENT",
      {},
      true
    );
    this.getCategoryPosts(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?post_type=SELL&limit=25`,
      "SELL",
      {},
      true
    );
    this.getLocalBusiness(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/users/getLocalBusiness?limit=25`,
      {},
      true
    );
  }

  getCategoryPosts = (getUrl, type, params, setNew) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: getUrl,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
        params: params,
      })
        .then((response) => {
          const freshPost = response.data.data;
          this.setState({ loading: false });
          if (type === "EVENT") {
            if (setNew) {
              this.setState({
                eventPosts: [...freshPost],
              });
            } else {
              this.setState({
                eventPosts: [...this.state.eventPosts, ...freshPost],
              });
            }

            this.setState({ nextEventLink: response.data.next });
            this.setState({ prevEventLink: response.data.previous });
            this.setState({ firstLoad: false });
          } else {
            if (setNew) {
              this.setState({
                sellPosts: [...freshPost],
              });
            } else {
              this.setState({
                sellPosts: [...this.state.sellPosts, ...freshPost],
              });
            }
            this.setState({ nextSaleLink: response.data.next });
            this.setState({ prevSaleLink: response.data.previous });
            this.setState({ firstLoad: false });
          }
        })
        .catch((error) => {
          console.log("unable to find posts", error);
        });
    } catch (error) {
      console.log(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  getLocalBusiness = (url, params, setNew) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
        params: params,
      })
        .then((response) => {
          const freshPost = response.data.data;
          this.setState({ loading: false });
          if (setNew) {
            this.setState({
              localBusiness: [...freshPost],
            });
          } else {
            this.setState({
              localBusiness: [...this.state.localBusiness, ...freshPost],
            });
          }
          this.setState({ nextBusinessLink: response.data.next });
          this.setState({ prevBusinessLink: response.data.previous });
        })
        .catch((error) => {
          console.error(
            "There is some error is finding the businesses. Please try again after some time."
          );
        });
    } catch (error) {
      console.log(
        "There is some error is finding the businesses. Please try again after some time."
      );
    }
  };

  getMyFavourites = (params) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/my_favourites/`,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
        params: params,
      })
        .then((response) => {
          console.log(response.data.data);
          this.setState({ loading: false });
          this.setState({
            myFavourites: response.data.data,
          });
        })
        .catch((error) => {
          console.error(
            "There is some error is finding the businesses. Please try again after some time."
          );
        });
    } catch (error) {
      console.log(
        "There is some error is finding the businesses. Please try again after some time."
      );
    }
  };

  MarkInterested = (postId, profileId) => {
    const token = localStorage.getItem("token");
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    const data = new FormData();
    if (postId) {
      data.append("post_interested_to", postId);
    }
    if (profileId) {
      data.append("profile_interested_to", profileId);
    }
    data.append("profile_interested_by", parsedUserData.profile_id);
    try {
      axios({
        method: "post",
        url: postId
          ? `${process.env.REACT_APP_API_BASE_URL}api/v1/interested/`
          : `${process.env.REACT_APP_API_BASE_URL}api/v1/interestedProfile/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
        .then(() => {
          this.props.handleSuccessMessage("Post added to favourites");
          this.getMyFavourites({ event: true, deals: true, stores: true });
        })
        .catch(() => {
          this.props.handleErrorMessage(
            "Post is already marked as favourites."
          );
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  RemoveFromWishlist = (id, isPost = true, params) => {
    const token = localStorage.getItem("token");
    try {
      axios({
        method: "delete",
        url: isPost
          ? `${process.env.REACT_APP_API_BASE_URL}api/v1/interested/${id}/`
          : `${process.env.REACT_APP_API_BASE_URL}api/v1/interestedProfile/${id}/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
        .then(() => {
          this.props.handleSuccessMessage("Post removed from wishlist");
          this.getMyFavourites(params);
        })
        .catch((error) => {
          alert("Something went wrong, Please try again.");
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  getBusinessByIds = (businessIds) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/users/getLocalBusinessByArray/`,
        data: businessIds,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          this.setState({ localBusiness: response.data.data });
        })
        .catch((error) => {
          console.log("unable to find posts", error);
        });
    } catch (error) {
      console.log(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  getProfileByIds = (profileIds) => {
    this.setState({ searched: true });
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/users/getProfilesByArray/`,
        data: profileIds,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          this.setState({ userProfiles: response.data.data });
        })
        .catch((error) => {
          console.log("unable to find posts", error);
        });
    } catch (error) {
      console.log(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  getPostsByIds = (postIds, type) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPosts/`,
        data: postIds,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          if (type === "events") {
            this.setState({ eventPosts: response.data.data });
          } else if (type === "deals") {
            this.setState({ sellPosts: response.data.data });
          }
        })
        .catch((error) => {
          console.log("unable to find posts", error);
        });
    } catch (error) {
      console.log(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  search = (keyword, type) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/${type}/index?query=${keyword}`,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          const postIds = response.data.hits.hits.map(
            (hit) => hit._source.post_id
          );
          const businessIds = response.data.hits.hits.map(
            (hit) => hit._source.user_id
          );
          const result = postIds.map((id) => ({ id }));
          const businessResult = businessIds.map((id) => ({ id }));

          if (type === "stores") {
            this.setState({ localBusiness: [] });
            this.getBusinessByIds(businessResult);
          } else if (type === "profiles") {
            this.setState({ userProfiles: [] });
            this.getProfileByIds(businessResult);
          } else {
            if (result.length === 0) {
              if (type === "events") {
                this.setState({ eventPosts: [] });
                this.getCategoryPosts(
                  `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?post_type=EVENT&limit=5000`,
                  "EVENT",
                  {},
                  true
                );
              } else {
                this.setState({ sellPosts: [] });
                this.getCategoryPosts(
                  `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?post_type=SELL&limit=`,
                  "SELL",
                  {},
                  true
                );
              }
            } else {
              this.getPostsByIds(result, type);
            }
          }
        })
        .catch((error) => {
          console.log("unable to find posts", error);
        });
    } catch (error) {
      console.log(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  onPaginatedSearch = (params) => {
    if (this.state.nextEventLink != null) {
      this.setState({ loading: true });
      this.getCategoryPosts(this.state.nextEventLink, "EVENT", params, false);
    }
  };

  onPaginatedSellSearch = (params) => {
    if (this.state.nextSaleLink != null) {
      this.setState({ loading: true });
      this.getCategoryPosts(this.state.nextSaleLink, "SELL", params, false);
    }
  };

  onPaginatedBusinessSearch = (params) => {
    if (this.state.nextBusinessLink != null) {
      this.setState({ loading: true });
      this.getLocalBusiness(this.state.nextBusinessLink, params, false);
    }
  };

  render() {
    let contextData = {
      state: this.state,
      getCategoryPosts: this.getCategoryPosts,
      onPaginatedSearch: this.onPaginatedSearch,
      onPaginatedSellSearch: this.onPaginatedSellSearch,
      MarkInterested: this.MarkInterested,
      search: this.search,
      getMyFavourites: this.getMyFavourites,
      RemoveFromWishlist: this.RemoveFromWishlist,
      onPaginatedBusinessSearch: this.onPaginatedBusinessSearch,
      getLocalBusiness: this.getLocalBusiness,
    };
    return (
      <DiscoverContext.Provider value={contextData}>
        {this.props.children}
      </DiscoverContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
  };
};
function mapDispatchToProps(dispatch) {
  return {
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
  };
}
export const DiscoverProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscoverProviderComponent);
