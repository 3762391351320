import React from "react";
import { withRouter } from "react-router";

import styles from "../../apps/Dashboard/Components/CSSFiles/RightSidebar.module.css";
import { thumbnailList } from "../../redux/constants/commonFunctions";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

class DashboardRightFeedInnerComp extends React.Component {
  state = {};

  render() {
    return (
      <div className={styles.Rightsidebar_stats}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            p: "0px 15px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src="/Image/DashImage/RightSidebar/fire_icon.svg"
              alt="fire icon"
              style={{ width: "15px" }}
            />
            <Typography
              sx={{ fontWeight: "600", color: "#535353", fontSize: "15px" }}
            >
              {this.props.t("newsFeed.title")}
            </Typography>
          </Box>
          <MoreHorizIcon />
        </Box>
        {this.props?.content?.length > 0 &&
          this.props?.content?.map((item, idx) => {
            return (
              <Box
                onClick={() => window.open(`${item.href}`, "_blank")}
                key={idx}
                sx={{
                  cursor: "pointer",
                  ":hover": { background: "#f5f5f5" },
                  borderRadius: "8px",
                  padding: "1px 15px",
                }}
              >
                <div id={idx} className={styles.Rightsidebar_stat}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <img
                      src={item?.thumbnail ?? thumbnailList[idx % 13]}
                      style={{ width: 20, height: 20 }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#868686",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.t(`${item.channel}`)}
                    </Typography>
                  </Box>
                  <div
                    className={
                      this.props?.isCourse
                        ? styles.Rightsidebar_statNumber2
                        : styles.Rightsidebar_statNumber
                    }
                  >
                    {this.props?.isCourse ? (
                      <span>{item?.name}</span>
                    ) : (
                      <Typography
                        href={item.href}
                        target="_blank"
                        sx={{
                          fontWeight: "700",
                          color: "#000",
                          fontSize: "16px",
                          lineHeight: 1.25,
                        }}
                      >
                        {this.props.t(`${item.content}`)}
                      </Typography>
                    )}
                  </div>
                </div>
                {/* {idx == this.props.content.length - 1 ? null : (
                  <hr className={styles.Hr1} />
                )} */}
              </Box>
            );
          })}

        {/* Add the viewAllCourses button below this comment */}
        {this.props?.isCourse && (
          <>
            <hr className={styles.Hr1} />{" "}
            <span>
              <Link to="/courses" className={styles.sidebar_stat2}>
                {this.props.t("common.viewallcourses")}
              </Link>
            </span>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(DashboardRightFeedInnerComp));
