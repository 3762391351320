import React from "react";

import styles from "../../../../Profile.module.css";

import SkillInterestImage from "../../../../components/SkillInterestImage";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";
import {
  fetchModelInterests,
  updateInterests,
} from "../../../../../../redux/actions/interest";
import {
  GET_MODEL_INTERESTS,
  UPDATE_INTERESTS,
} from "../../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

class AddInterests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intIds: [],
      csvIntIds: "",
      intContent: [],

      selectedIds: [],
      unselectedIds: [],

      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  setIntIds() {
    let intIds = [];
    this.props.intContent.map((item) => {
      intIds.push(item.id);
    });

    let csvIds = "";
    intIds.map((item) => {
      csvIds += item + ",";
    });

    this.setState({ intIds: intIds, csvIntIds: csvIds });
  }

  getAllAndConvertToCSV() {
    let csvIds = this.state.csvIntIds;
    this.state.selectedIds.map((item) => {
      csvIds += item + ",";
    });
    return csvIds.slice(0, csvIds.length - 1);
  }

  getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  removeAddedIntContent(allContent) {
    let intContent = [];
    let unselectedIds = [];
    allContent.map((item) => {
      if (!this.state.intIds.includes(item.id)) {
        intContent.push(item);
        unselectedIds.push(item.id);
      }
    });
    this.setState({ intContent: intContent, unselectedIds: unselectedIds });
  }

  getExcludedIntContent() {
    this.props.fetchModelInterests(GET_MODEL_INTERESTS).then((res) => {
      if (res.val) {
        this.removeAddedIntContent(this.props.modelInterests);
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  componentDidMount() {
    this.setIntIds();
    this.getExcludedIntContent();
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  addSelectedId(id) {
    let updatedSelectedIds = [...this.state.selectedIds, id];
    this.setState({ selectedIds: updatedSelectedIds });

    let updatedUnselectedIds = this.state.unselectedIds;
    const index = updatedUnselectedIds.indexOf(id);
    if (index > -1) {
      updatedUnselectedIds.splice(index, 1);
    }
    this.setState({ unselectedIds: updatedUnselectedIds });
  }

  removeSelectedId(id) {
    let updatedUnselectedIds = [...this.state.unselectedIds, id];
    this.setState({ unselectedIds: updatedUnselectedIds });

    let updatedSelectedIds = this.state.selectedIds;
    const index = updatedSelectedIds.indexOf(id);
    if (index > -1) {
      updatedSelectedIds.splice(index, 1);
    }
    this.setState({ selectedIds: updatedSelectedIds });
  }

  ScrollBodyOnClick(id) {
    if (this.state.selectedIds.includes(id)) {
      this.removeSelectedId(id);
    } else {
      this.addSelectedId(id);
    }
  }

  ScrollBody() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.state.intContent.map((item, idx) => {
          return (
            <SkillInterestImage
              pointer={true}
              image={item.upload_img}
              onClick={() => this.ScrollBodyOnClick(item.id)}
              idx={idx}
              bgcolor={
                this.state.selectedIds.includes(item.id) ? "#00D498" : "#fff"
              }
              textDecoration={
                this.state.selectedIds.includes(item.id) ? "underline" : "none"
              }
              color={
                this.state.selectedIds.includes(item.id) ? "#00D498" : "#000"
              }
              name={item.title}
              follower={this.getRandomNumber()}
            />
          );
        })}
      </div>
    );
  }

  SaveButtonOnClick() {
    if (this.state.selectedIds.length == 0) {
      document.body.style.overflow = "visible";
      return;
    }

    const data = { interests: this.getAllAndConvertToCSV() };
    this.props
      .updateInterests(UPDATE_INTERESTS, data, this.state.selectedIds)
      .then((res) => {
        if (res.val) {
          this.props.changeEdit();
          document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.modalTitle}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SaveButton onClick={this.SaveButtonOnClick} />

          <div>
            <p style={{ fontSize: 14, color: "#00d498", fontWeight: 500 }}>
              {this.state.selectedIds.length + " " + t("common.selected")}
            </p>
          </div>
        </div>

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modelInterests: state.profile?.modelInterests || [],
    intContent: state.profile?.interests || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchModelInterests: (action) => dispatch(fetchModelInterests(action)),
    updateInterests: (action, data, arrData) =>
      dispatch(updateInterests(action, data, arrData)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AddInterests)
);
