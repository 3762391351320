import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function addEducation(edState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("school", edState.school);
    data.append("description", edState.description);
    data.append("start_date", edState.start_date);
    data.append("end_date", edState.end_date);
    data.append("participations", edState.participations);
    data.append("field_of_study", edState.field_of_study);
    data.append("degree", edState.degree);
    data.append("grade", edState.grade);
    data.append(
      "profile_id",
      JSON.parse(localStorage.getItem("userData")).profile_id
    );
    data.append(
      "upload_img",
      edState.pictureAsFile,
      edState.pictureAsFile.name
    );

    // validations
    if (edState.start_date == null || edState.end_date == null) {
      return {
        val: false,
        message: "error at adding education - Empty startdate and end date",
      };
    } else if (edState.start_date > edState.end_date) {
      return {
        val: false,
        message:
          "error at adding educaiton - start date must be less than end date",
      };
    }
    if (edState.grade.length > 1) {
      alert("error at adding educaiton - Grade must be single character");
      return {
        val: false,
        message: "error at adding educaiton - Grade must be single character",
      };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/education/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Education added successfully!"));
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        return {
          val: false,
          message: `Some error occured - ${res.data.message}`,
        };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateEducation(edState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("school", edState.school);
    data.append("description", edState.description);
    data.append("start_date", edState.start_date);
    data.append("end_date", edState.end_date);
    data.append("participations", edState.participations);
    data.append("field_of_study", edState.field_of_study);
    data.append("degree", edState.degree);
    data.append("grade", edState.grade);
    if (edState.uploadImageFlag) {
      data.append(
        "upload_img",
        edState.pictureAsFile,
        edState.pictureAsFile.name
      );
    }

    // validaitions
    if (edState.start_date == null || edState.end_date == null) {
      return {
        val: false,
        message: "error at adding education - Empty upload image",
      };
    } else if (edState.start_date > edState.end_date) {
      return {
        val: false,
        message:
          "error at adding education - start date must be less than end date",
      };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/education/${edState.id}/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status == "1") {
          let value = Object.fromEntries(data.entries());
          if (edState.uploadImageFlag) {
            value = {
              ...value,
              id: edState.id,
              upload_img: URL.createObjectURL(edState.pictureAsFile),
            };
          } else {
            value = {
              ...value,
              id: edState.id,
              upload_img: edState.pictureAsFile,
            };
          }
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated successfully"));
          return { val: true };
        }
        return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
