import React from "react";
// import SaleCard from "./SaleCard";
import "./CSS/SalePage.css";
import { lazy, Suspense } from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { useState } from "react";
import SaleSidebar from "./SaleSidebar";
import { useContext } from "react";
import { DiscoverContext } from "./DiscoverContext";
import SaleCardNew from "./SaleCardNew";
import { useEffect } from "react";
const SaleCard = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./SaleCard")), 1000);
  });
});

const SalePage = () => {
  const { state, onPaginatedSellSearch, getCategoryPosts } =
    useContext(DiscoverContext);
  const [params, setParams] = useState(null);

  useEffect(() => {
    getCategoryPosts(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?post_type=SELL&limit=25`,
      "SELL",
      params,
      true
    );
  }, [params]);

  return (
    <div className="sale_page_container">
      <div className="sale_page_sidebar">
        <SaleSidebar params={params} setParams={setParams} />
      </div>

      <div className="parent_sale">
        <div className="sale_top">
          <h1>For Sale & Free</h1>
        </div>

        <div className="sale_bottom">
          {state?.sellPosts?.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={"/Image/apps/profile-resume-edit-view/no_result.jpeg"}
                style={{ width: "40%" }}
              />
            </Box>
          )}
          {state?.sellPosts?.map((sell, index) => (
            <div key={index}>
              <Suspense
                fallback={
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={200} height={150} />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={180}
                      sx={{ fontSize: "1rem" }}
                    />
                  </Stack>
                }
              >
                <SaleCardNew sell={sell} />
              </Suspense>
            </div>
          ))}
        </div>

        {state?.sellPosts?.length >= 25 && (
          <div className="sale_page_load_more_button">
            <Button
              variant="outlined"
              onClick={() => onPaginatedSellSearch(params)}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalePage;
