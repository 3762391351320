import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { connect } from "react-redux";
class IconSectionView extends React.Component {
  render() {
    const { t } = this.props;
    const feedTitle = [
      this.props.userTypeView === "INDIVIDUAL"
        ? t("profile.about")
        : t("profile.businessInfo"),
      this.props.userTypeView === "LOCAL BUSINESS"
        ? t("profile.businessCategory")
        : null,
      this.props.userTypeView === "LOCAL BUSINESS"
        ? t("profile.licensesAndCertifications")
        : null,
      this.props.userTypeView === "LOCAL BUSINESS"
        ? t("profile.businessOffering")
        : null,
      t("profile.posts"),
      t("profile.featured"),
      this.props.userTypeView === "INDIVIDUAL" ? t("profile.interests") : null,
      this.props.userTypeView === "INDIVIDUAL" ? t("profile.experience") : null,
      this.props.userTypeView === "INDIVIDUAL" ? t("profile.education") : null,
      this.props.userTypeView === "INDIVIDUAL"
        ? t("profile.volunteerExperience")
        : null,
      this.props.userTypeView === "INDIVIDUAL"
        ? t("profile.skillsAndEndorsements")
        : null,
      this.props.userTypeView === "INDIVIDUAL"
        ? t("profile.achievements")
        : null,
    ];

    return (
      <div>
        <div
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "20px",
            borderRadius: 10,
          }}
        >
          {feedTitle.map((title, idx) => {
            if (title !== null) {
              return (
                <div>
                  <Button
                    href={`#${title}`}
                    disableRipple
                    key={idx}
                    sx={{
                      textTransform: "none",
                      textAlign: "left",
                      color: "#606060",
                      p: "6px 8px",
                      width: "100%",
                      fontSize: "14px",
                      lineHeight: 1.2,
                      display: "flex",
                      justifyContent: "flex-start",
                      ":hover": {
                        color: "#F33070",
                        backgroundColor: "#fef7f8",
                        fontWeight: 600,
                      },
                    }}
                  >
                    {title}
                  </Button>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    userTypeView: state?.profile?.user_type_view,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(IconSectionView)
);
