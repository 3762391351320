import React from "react";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import { updateProfileData } from "../../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

class EditAbout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      about: this.props.about,
      edit: false,
      text: this.props.about,
      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
    this.changeText = this.changeText.bind(this);
  }

  componentDidMount() {
    var id = document.getElementById("textarea");
    id.focus();
    id.select();
  }

  changeText(text) {
    this.setState({ text: text });
  }
  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  ModalHeaderOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    let data = new FormData();
    data.append("about", this.state.text);

    this.props.updateAbout(EDIT_PROFILE_DATA, data).then((res) => {
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });

    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    const { t } = this.props;
    return (
      <div>
        <p className={styles.modal_heading}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "10px", width: "25px" }}
              src="/Image/Profile/summary.svg"
            />
            {t("profile.summary")}
          </div>
        </p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            id="textarea"
            className={styles.modal_para}
            rows={8}
            placeholder={t("profile.modalPlaceholder.writeAboutYourself")}
            value={this.state.text}
            onChange={(event) => {
              this.changeText(event.target.value);
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.modalTitle}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    about: state?.profile?.about || "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAbout: (action, data) => dispatch(updateProfileData(action, data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditAbout)
);
