import React, { Component } from "react";
import { connect } from "react-redux";
import IconTitle from "../../../components/commons/IconTitle";
import ModalHeader from "../../../components/commons/ModalHeader";
import SaveButton from "../../../components/commons/SaveButton";
import ScrollDivBody from "../../../components/commons/ScrollDivBody";
import styles from "../css/ViewAssignmentDetailsModal.module.css";
import DueDate from "../../../components/assignmentAndQuiz/DueDate";
import { withTranslation } from "react-i18next";
import AttachmentContainer from "../../../components/commons/assignmentAndQuiz/AttachmentContainer";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import { Link, withRouter } from "react-router-dom";
import AssignmentHeading from "../../../components/assignmentAndQuiz/AssignmentHeading";
import { ModalWithoutHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

class ViewAssignmentDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_id_url: this.props.match.params.id,
      anchorEl: null,
      open: false
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, open:true });
    // console.log("handle click")
  };
  handleClose = event => {
    this.setState({ anchorEl: null, open: false });
  };
  editClickHandler = () => {
    this.setState({anchorEl: null, open: false});
    // console.log("inside edit click handler")
    let { history } = this.props;
    this.setState({ anchorEl: null,open: false  });
    if(this.props.type == 'QUIZ'){
      history.push({
        pathname: `/quiz/create/${this.props?.assignment?.classes ?? this.props?.assignment?.groups}`,
        state: { assignment: this.props?.assignment, actionType: "edit", routeType: this.props.routeType, isOwner: this.props?.isOwner },
      });
    }
    else{
      history.push({
        pathname: `/assignment/create/${this.props?.assignment?.classes ?? this.props?.assignment?.groups}`,
        state: { assignment: this.props?.assignment, actionType: "edit", routeType: this.props.routeType, isOwner: this.props?.isOwner },
      });
    }
   
  };

  render() {
    const { t } = this.props;
    // const open = this.state.anchorEl === null ? false : true;
    // console.log("RouteType in viewAsgModal", this.props.routeType);
    return (
      <div className={styles.view_assignment_modal}>
        {/* <ModalHeader title="Hello"/> */}
        <ModalWithoutHeader maxWidth={1000} closeHandler={this.props.shareModalCloser} show={this.props.show} style={{ backgroundColor: "white", padding: 20}}>
        <div className={styles.view_assignment_heading}>
          <AssignmentHeading title={this.props?.assignment?.title} />
          <DueDate date={this.props?.assignment?.due_date} />
        </div>
        <p className={styles.view_assignment_description}>{this.props?.assignment?.details}</p>
        {
          this.props?.assignment?.file ? (
            <div className={styles.view_assignment_attachment}>
            <AttachmentContainer assignmentFileType="FILE" fileName={this.props.assignment?.file?.split("/").pop()} fileSize="24MB" pageType="" file={this?.props?.assignment?.file}/>
            </div>
          ):null
        }
        { this.props?.assignment?.url ? (
                  <div className={styles.view_assignment_attachment}>
                  <AttachmentContainer assignmentFileType="LINK" linkName={this.props.assignment?.url?.split("/").pop()} link={this.props?.assignment?.url} pageType=""/>
                  </div>

        ): null          
        }
        <div className={styles.bottom_btns}>
          <div className={styles.view_assignment_button}>
          {/* <Link to={!this.props.isClassOwner?(this.props.type==="ASSIGNMENT" ? `/student/assignment/${this.props?.assignment?.id}` : `/student/quiz/${this.props?.assignment?.id}`)
          : (this.props.type==="ASSIGNMENT" ? `/assignment/view/${this.props?.assignment?.id}` : `/quiz/view/${this.props?.assignment?.id}`) } style={{textDecoration: "none"}} */}
          <Link
          to={{
            pathname: !this.props.isOwner?(this.props.type==="ASSIGNMENT" ? `/student/assignment/${this.props?.assignment?.id}` : `/student/quiz/${this.props?.assignment?.id}`)
            : (this.props.type==="ASSIGNMENT" ? `/assignment/view/${this.props?.assignment?.id}` : `/quiz/view/${this.props?.assignment?.id}`) ,
            state: {routeType : this.props.routeType,
              isOwner: this.props?.isOwner,
            } ,
          }} 
          style={{textDecoration: "none"}} >           
            
            <Button type="solid" label={this.props.type === "ASSIGNMENT" ? this.props.t("assignment.viewAssignment") : this.props.t("assignment.viewQuiz")}/>      
          </Link>
          </div> 
          {this.props?.showEditMenu ? (
          <div className={styles.dropdown}>
            <img
              className={styles.threeDotsMenu}
              src="/Image/assignment/dropdown.svg"
              alt=""
              onClick={this.handleClick}
            />

            <Menu
              id="basic-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  boxShadow:'0px 0px 20px rgba(63, 63, 65, 0.102)'
                }
              }}
            >
              <MenuItem onClick={() => this?.editClickHandler()}>{t("common.edit")}</MenuItem>
              <MenuItem onClick={() => {this.props.handleDeleteAssignment(this.props?.assignment?.id); this.setState({anchorEl: null, open: false}); this.props.shareModalCloser()}}>{t("common.delete")}</MenuItem>
            </Menu>
          </div>
          ) : null}
        </div>
        </ModalWithoutHeader>
      </div>
    );
  }
}

const mapStateToProps = (state,props)=>{
  return {
    // isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.group_owner === state?.profile?.profileId) : (state?.classes?.class?.class_owner === state?.profile.profileId && state?.profile?.user?.user_subtype == "FACULTY")
  }
}

export default withTranslation()(
  connect(
    mapStateToProps,null
  )(withRouter(ViewAssignmentDetailsModal))
  );
