import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../redux/actions/forgotPassword";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import SignNav from "../../home/Nav/SignNav";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import "../Sign.css";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { handleErrorMessage } from "../../../redux/actions/groups";
import MuiAlert from "@material-ui/lab/Alert";
import { useState } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ChangePassword() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const horizontal = "center";
  const vertical = "top";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  function onSubmit(data) {
    let token = location.search.split("=")[1];
    let payload = {
      token: token,
      password: data.password,
    };
    const onSuccess = (res) => {
      history.push("/signin");
    };
    const onError = (error) => {
      const errorObj = error.response?.data.error;
      const keys = errorObj && Object.keys(errorObj);
      setMessage(errorObj[keys[0]] || "Error while resetting the password");
      setSeverity("error");
      setOpen(true);
    };
    dispatch(resetPassword(payload, onSuccess, onError));
  }

  return (
    <>
      <SignNav />
      <img src="Image/signbgLeft.svg" alt="#" className="fp-bg" />
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ ml: 5, mr: { xs: 8, lg: 0 }, pr: { xs: 0, lg: 8 } }}
        >
          <Box sx={{ height: "90vh", display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 5,
                width: "100%",
              }}
            >
              <div className="sign-heading">
                <div>Reset Password</div>
              </div>
              <form className="forgot-input" onSubmit={handleSubmit(onSubmit)}>
                <input
                  className="sign-field"
                  {...register("password", { required: true, minLength: 8 })}
                  type="password"
                  placeholder={t("signin.newPassword")}
                />
                <div className={`input-alert`}>
                  {errors.password?.type === "required" &&
                    t("errors.passwordError")}
                </div>
                <div className={`input-alert`}>
                  {errors.password?.type === "minLength" &&
                    t("errors.validPasswordError")}
                </div>
                <input
                  className="sign-field"
                  {...register("confirm_password", {
                    required: true,
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  type="password"
                  placeholder={t("signup.confirmPassword")}
                />
                <div className={`input-alert`}>
                  {errors.confirm_password?.type === "required" &&
                    t("errors.confirmPasswordError")}
                </div>
                <div className={`input-alert`}>
                  {errors.confirm_password?.type === "validate" &&
                    t("errors.validConfirmPasswordError")}
                </div>
                <button className="button sign-btn">Reset</button>
              </form>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={0}
          md={5}
          sx={{
            mr: 4,
            display: { xs: "none", md: "block" },
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "15vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 8.png"
                alt="sign photo"
                style={{ width: "70%", borderRadius: "20px", marginRight: 20 }}
              />
              <img
                src="/Image/signImage/Asset 7.png"
                alt="sign photo"
                style={{ width: "85%", borderRadius: "20px" }}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "5vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 9.svg"
                alt="sign photo"
                style={{ width: "40%", borderRadius: "20px" }}
              />
              <img
                src="/Image/signImage/Asset 6.png"
                alt="sign photo"
                style={{ width: "90%", borderRadius: "20px" }}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "center",
                width: "100%",
                mt: 5,
              }}
            >
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "26px", fontWeight: 600, color: "#ed2f75" }}
              >
                {t("signup.bottomLine2")}{" "}
              </Typography>
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine3")}{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine2")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (payload, onSuccess, onError) =>
      dispatch(resetPassword(payload, onSuccess, onError)),
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
