import React, { createContext, Component } from "react";
// import PostSampleImg2 from './Image/DashboardImages/DashCommonImages/postSampleImg2.png';
import { connect } from "react-redux";
import axios from "axios";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { CircularProgress } from "@mui/material";

export const FeedsContext = createContext();

class FeedsProviderComponent extends Component {
  state = {
    postForProfile: [],
    postsByUserId: [],
    posts: [],
    class_posts: [],
    group_posts: [],
    nextLink: null,
    prevLink: null,
    loading: true,
    firstLoad: true,
  };

  getPosts = (getUrl, type = "") => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: getUrl,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        const freshPost = response.data.data;
        let freshNewPosts = freshPost.map((post) => {
          if (post.post_type === "Poll") {
            const newResponse = { ...post };
            if (post.post_details !== null) {
              if (typeof post.post_details.poll_options === "string") {
                post.post_details.poll_options = JSON.parse(
                  post.post_details.poll_options
                );
              }

              const parsedData = post.post_details.poll_options;
              newResponse.post_details = {
                ...newResponse.post_details,
                poll_options: parsedData,
              };
            }
            return newResponse;
          } else {
            return post;
          }
        });
        this.setState({ loading: false });
        if (type == "post") {
          this.setState({ posts: [...freshNewPosts] });
        } else {
          this.setState({ posts: [...this.state.posts, ...freshNewPosts] });
        }
        this.setState({ nextLink: response.data.next });
        this.setState({ prevLink: response.data.previous });
        this.setState({ firstLoad: false });
      });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  getPostsByUserId = (url, isViewProfile = false) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        const freshPost = response.data.data;
        let freshNewPosts = freshPost.map((post) => {
          if (post.post_type === "Poll") {
            const newResponse = { ...post };
            if (post.post_details !== null) {
              if (typeof post.post_details.poll_options === "string") {
                post.post_details.poll_options = JSON.parse(
                  post.post_details.poll_options
                );
              }
              const parsedData = post.post_details.poll_options;
              newResponse.post_details = {
                ...newResponse.post_details,
                poll_options: parsedData,
              };
            }
            return newResponse;
          } else {
            return post;
          }
        });
        this.setState({ loading: false });
        if (isViewProfile) {
          this.setState({ postForProfile: [...freshNewPosts] });
        } else {
          this.setState({ postsByUserId: [...freshNewPosts] });
          this.setState({ nextLink: response.data.next });
          this.setState({ prevLink: response.data.previous });
          this.setState({ firstLoad: false });
        }
      });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  componentDidMount() {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    if (parsedUserData !== null) {
      this.getPosts(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?limit=10`
      );
      this.getPostsByUserId(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPostByProfileId/${parsedUserData.profile_id}/`
      );
    }
  }
  makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  addPost = (post) => {
    this.setState({ firstLoad: true });
    let p = this.state.posts;
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    var currentdate = new Date();
    var datetime =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const data = new FormData();
    data.append("share_link", this.makeid(10));

    if (post.visibility == "Anyone") {
      data.append("visibility_type", "PUBLIC");
    } else if (post.visibility == "Connection Only") {
      data.append("visibility_type", "CONNECTIONS");
    } else {
      data.append("visibility_type", "MYSELF");
    }
    data.append("post_type", post?.type);
    data.append("date_and_time", datetime);
    data.append("language_code", post.language ? post.language : "en");
    data.append("post_owner_profile", parsedUserData.profile_id);
    data.append("description", post.message);
    if (post.photoUrl != null) {
      data.append("image_url", post.photoUrl, post.photoUrl.name);
    } else if (post.videoUrl != null) {
      data.append("video_url", post.videoUrl, post.videoUrl.name);
    }
    if (post.mediaFileUrl != null) {
      data.append("file_url", post.mediaFileUrl, post.mediaFileUrl.name);
    }
    if (post.pollPost != null) {
      data.append("poll_question", post.pollPost.question);
      if (post.pollPost.options.length === 4) {
        const pollOptions = JSON.stringify({
          [post.pollPost.options[0].desc]: "0",
          [post.pollPost.options[1].desc]: "0",
          [post.pollPost.options[2].desc]: "0",
          [post.pollPost.options[3].desc]: "0",
        });
        data.append("poll_options", pollOptions);
      } else if (post.pollPost.options.length === 3) {
        const pollOptions = JSON.stringify({
          [post.pollPost.options[0].desc]: "0",
          [post.pollPost.options[1].desc]: "0",
          [post.pollPost.options[2].desc]: "0",
        });
        data.append("poll_options", pollOptions);
      } else {
        const pollOptions = JSON.stringify({
          [post.pollPost.options[0].desc]: "0",
          [post.pollPost.options[1].desc]: "0",
        });
        data.append("poll_options", pollOptions);
      }
    }
    if (post.expertPost != null) {
      data.append("purpose", post.expertPost.profession);
      data.append("location", post.expertPost.location);
    }
    if (post.locationPost.address != null) {
      data.append("location", post.locationPost.address);
      data.append("latitude", post.locationPost.lat);
      data.append("longitude", post.locationPost.lng);
    }
    if (post.sellPost != null) {
      data.append("title", post.sellPost.title);
      const images = post.sellPost.photos;
      for (let i = 0; i < images.length; i++) {
        data.append("images", images[i].src);
      }
      data.append("category", post.sellPost.category);
      data.append("pricing", post.sellPost.price);
      data.append("discounted_price", post.sellPost.discountedPrice);
      data.append("discount_offer", post.sellPost.discountOff);
      data.append("free", post.sellPost.isFree);
      data.append("sell_description", post.sellPost.desc);
      data.append("latitude", post.locationPost.lat);
      data.append("longitude", post.locationPost.lng);
    }
    if (post.eventPost != null) {
      data.append("event_type", post.eventPost.event_type);
      data.append("name", post.eventPost.name);
      data.append("address", post.eventPost.address);
      data.append("free", post.eventPost.free);
      data.append("event_link", post.eventPost.event_link);
      data.append("event_description", post.eventPost.event_description);
      data.append("start_date_time", post.eventPost.start_date_time);
      data.append("end_date_time", post.eventPost.end_date_time);
      data.append("timezone", post.eventPost.timezone);
      data.append("latitude", post.locationPost.lat);
      data.append("longitude", post.locationPost.lng);
      data.append("pricing", post.eventPost.price);
      data.append("discounted_price", post.eventPost.discountedPrice);
    }
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        console.log(response);
        if (response.status === 201) {
          this.getPosts(
            `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?limit=10`,
            "post"
          );
          this.getPostsByUserId(
            `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPostByProfileId/${parsedUserData.profile_id}/`
          );
        } else {
          alert("Alert Response: " + response.data.message);
        }
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  repost = (id) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    const data = new FormData();
    data.append("date_and_time", "2023-09-19T14:30:00+00:00");
    data.append("description", "description");
    data.append("share_link", this.makeid(10));
    data.append("visibility_type", "PUBLIC");
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/repost/${id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.props.handleSuccessMessage("Reposted successfully");
            this.getPosts(
              `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?limit=10`,
              "post"
            );
            this.getPostsByUserId(
              `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPostByProfileId/${parsedUserData.profile_id}/`
            );
          } else {
            console.log("check");
            alert("Alert Response");
          }
        })
        .catch((error) => {
          alert(
            "You cannot repost your own post or You have already reposted this post."
          );
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  pollVote = (postId, optionId) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    const data = new FormData();
    data.append("poll_post_id", postId);
    data.append("poll_option_id", optionId);
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/poll-vote/${postId}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            // this.props.handleSuccessMessage("voted successfully");
            this.getPosts(
              `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?limit=10`,
              "post"
            );
            this.getPostsByUserId(
              `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPostByProfileId/${parsedUserData.profile_id}/`
            );
          } else {
            console.log("check");
            alert("Alert Response");
          }
        })
        .catch((error) => {
          alert("Something went wrong, Please try again..");
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  deletePost = (id) => {
    const token = localStorage.getItem("token");
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${id}/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }).then(() => {
        const oldPosts = [...this.state.posts];
        const index = oldPosts.findIndex(function (oldPost) {
          return oldPost.id === id;
        });
        if (index !== -1) oldPosts.splice(index, 1);
        this.setState({ posts: oldPosts });
        this.props.handleSuccessMessage("Deleted successfully");
        this.getPostsByUserId(
          `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPostByProfileId/${parsedUserData.profile_id}/`
        );
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  deleteSellImage = (id, postId) => {
    const token = localStorage.getItem("token");
    const data = new FormData();
    data.append("sell_post_id", postId);
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/delete-sell-image/${id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }).then(() => {
        this.props.handleSuccessMessage("Deleted successfully");
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  editPost = (post) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);

    const data = new FormData();
    if (post.message != null) {
      data.append("description", post.message);
    }
    if (post.photoUrl != null && post.onImageEdit == true) {
      data.append("image_url", post.photoUrl);
    }
    if (post.videoUrl != null && post.onVideoEdit == true) {
      data.append("video_url", post.videoUrl);
    }
    if (post.mediaFileUrl != null && post.onMediaFileEdit == true) {
      data.append("file_url", post.mediaFileUrl);
    }
    if (post.expertPost != null) {
      data.append("purpose", post.expertPost.profession);
      data.append("location", post.expertPost.location);
    }
    if (post.pollPost != null) {
      data.append("poll_question", post.pollPost.question);
      if (post.pollPost.options.length === 4) {
        const pollOptions = JSON.stringify({
          [post.pollPost.options[0].desc]: "0",
          [post.pollPost.options[1].desc]: "0",
          [post.pollPost.options[2].desc]: "0",
          [post.pollPost.options[3].desc]: "0",
        });
        data.append("poll_options", pollOptions);
      } else if (post.pollPost.options.length === 3) {
        const pollOptions = JSON.stringify({
          [post.pollPost.options[0].desc]: "0",
          [post.pollPost.options[1].desc]: "0",
          [post.pollPost.options[2].desc]: "0",
        });
        data.append("poll_options", pollOptions);
      } else {
        const pollOptions = JSON.stringify({
          [post.pollPost.options[0].desc]: "0",
          [post.pollPost.options[1].desc]: "0",
        });
        data.append("poll_options", pollOptions);
      }
    }
    if (post.sellPost != null) {
      data.append("title", post.sellPost.title);
      const images = post.sellPost.photos;
      for (let i = 0; i < images.length; i++) {
        data.append("images", images[i].src);
      }
      data.append("category", post.sellPost.category);
      data.append("pricing", post.sellPost.price);
      data.append("discounted_price", post.sellPost.discountedPrice);
      data.append("discount_offer", post.sellPost.discountOff);
      data.append("free", post.sellPost.isFree);
      data.append("sell_description", post.sellPost.desc);
      data.append("latitude", post.locationPost.lat);
      data.append("longitude", post.locationPost.lng);
    }

    try {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${post.id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const oldPosts = [...this.state.posts];
          const index = oldPosts.findIndex(function (oldPost) {
            return oldPost.id === post.id;
          });
          const newResponse = { ...response.data };
          if (response.data.post_type === "Poll") {
            if (typeof response.data.post_details.poll_options === "string") {
              response.data.post_details.poll_options = JSON.parse(
                response.data.post_details.poll_options
              );
            }
            console.log(response.data.post_details.poll_options);
            const parsedData = response.data.post_details.poll_options;
            newResponse.post_details = {
              ...newResponse.post_details,
              poll_options: parsedData,
            };
          }
          console.log(newResponse);
          const updatedPost = { ...newResponse };
          oldPosts[index] = updatedPost;
          this.setState({ posts: oldPosts });
          this.props.handleSuccessMessage("Updated successfully");
        } else {
          alert("Alert Response: " + response.data.message);
        }
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  onPaginatedSearch = () => {
    if (this.state.nextLink != null) {
      this.setState({ loading: true });
      this.getPosts(this.state.nextLink);
    }
  };

  render() {
    let contextData = {
      state: this.state,
      addPost: this.addPost,
      editPost: this.editPost,
      deletePost: this.deletePost,
      getPost: this.getPosts,
      getPostsByUserId: this.getPostsByUserId,
      onPaginatedSearch: this.onPaginatedSearch,
      repost: this.repost,
      deleteSellImage: this.deleteSellImage,
      pollVote: this.pollVote,
    };
    return (
      <FeedsContext.Provider value={contextData}>
        {this.props.children}
      </FeedsContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    // class_posts: state?.classes?.posts,
    // group_posts: state?.groups?.posts,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
  };
}
export const FeedsProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedsProviderComponent);
