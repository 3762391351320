import {
  CHANGE_GROUP_ACTIVE_SECTION,
  DELETE_GROUP,
  GET_ERROR_MESSAGE,
  GET_GROUPS,
  GET_GROUP_DETAILS,
  GET_GROUP_POST,
  POST_GROUP_DETAILS,
  PUT_GROUP_DETAILS,
  ERROR_STATUS_CODE,
  GET_ALL_GROUP_OWNER_CODES,
  JOIN_MEMBER_DETAILS,
  POST_SEARCHED_GROUP_DETAILS,
  POST_PRODUCT_SERVICE,
} from "../constants/actionTypes";
import axios from "axios";
import {
  DELETE_PRODUCT_SERVICE_API,
  EDIT_PRODUCT_SERVICE_API,
  GET_GROUPS_API,
  GET_GROUP_DETAILS_API,
  GET_GROUP_ID_BY_CODE,
  GET_PRODUCT_SERVICE_API,
  GET_VALIDATE_GROUP_MEMBER,
  JOIN_GROUP_API,
  POST_GROUPS_DETAILS_API,
  POST_PRODUCT_SERVICE_API,
  PUT_GROUP_DETAILS_API,
} from "../constants/apis";
import { store } from "../store";
import { postMembers } from "./classes";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import moment from "moment";
import { handleSuccessMessage } from "./success";
import { getAllAssignments, getAllAssignmentsQuizes } from "./assignment";
import { getAllQuizzes } from "./quiz";

export const handleErrorMessage = (errorMessage) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessageFromResponse(errorMessage),
  };
};
export const getGroupDetails = (groupId) => {
  return async (dispatch) => {
    // dispatch(getAllAssignments(groupId, 'group'))
    // dispatch(getAllQuizzes(groupId, 'group'))
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    const token = store.getState()?.profile?.user?.token;
    const getGroupDetailsEndpoint = `${GET_GROUP_DETAILS_API}${groupId}/?target=${lang}/`;
    await axios
      .get(getGroupDetailsEndpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // dispatch(getAllAssignmentsQuizes(groupId, 'group'))
        dispatch({
          type: ERROR_STATUS_CODE,
          payload: response.status,
        });
        const data = response.data.data;
        const { members, meetings } = data;
        let myCourses = [];
        let myMembers = members.map((each_member) => {
          return {
            id: each_member.id,
            name: each_member.profile.name,
            university: each_member.profile.organisation,
            img: each_member.profile.profile_pic,
            privilige: each_member.privilige,
            profileId: each_member.member_users,
          };
        });
        let myMeetings = meetings.reduce((recordingsArr, each_meetings) => {
          const newMeetingObject = {
            uid: each_meetings.meeting_uuid,
            topic: each_meetings.topic,
            time: moment(each_meetings.meeting_created_at).format("LLLL"),
            count: each_meetings.recording_count,
          };
          const recordingObjects = each_meetings?.recordings.map(
            (recording, index, recordings) => {
              const newRecordingObject = { ...newMeetingObject };
              newRecordingObject.playUrl = recording.play_url;
              newRecordingObject.topic =
                recordings.length > 1
                  ? `${each_meetings.topic}-Session${index + 1}`
                  : `${each_meetings.topic}`;
              return newRecordingObject;
            }
          );
          return recordingObjects.length > 0
            ? [...recordingsArr, ...recordingObjects]
            : [...recordingsArr];
        }, []);
        const groupDetails = {
          group: {
            id: data.id,
            name: data.name,
            description: data.description,
            grade: data.grade,
            subject: data.subject,
            cover_image: data.cover_image,
            code: data.code,
            group_owner: data.group_owner,
            groupVision: data.group_objective,
            time_table: data.time_table,
          },
          members: myMembers,
          courses: myCourses,
          // posts: myPosts,
          meetings: myMeetings,
        };
        dispatch({ type: GET_GROUP_DETAILS, payload: groupDetails });
        return response;
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: ERROR_STATUS_CODE,
            payload: error.response.status,
          });
        } else {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        }
        return error;
      });
  };
};

export const getGroups = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_GROUPS_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // const groups = response.data.data;
        // console.log(response.data.data.map((groupData) => console.log(groupData)))
        // console.log()
        const groups = response.data.data;

        dispatch({ type: GET_GROUPS, payload: groups });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const createProductService = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    const data1 = { ...data };
    const formData = new FormData();
    formData.append("product_service_type", "PRIVATE");
    if (data.thumbnail) formData.append("thumbnail", data.thumbnail);
    formData.append("group_id", data.group_id);
    formData.append("sub_title", data.sub_title);
    // formData.append('cover_image', '');
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("free", data.free);
    formData.append("value", data.value);
    formData.append("discounted_price", data.discounted_price);
    // console.log('idr bhi came')

    await axios({
      method: "post",
      url: POST_PRODUCT_SERVICE_API,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        // console.log('Prores', response)
        dispatch({
          type: POST_PRODUCT_SERVICE,
          payload: response?.data,
        }).then(() => {});
        // if (response.data.status) {
        //   dispatch({
        //     type: POST_GROUP_DETAILS,
        //     payload: response.data.status,
        //   });
        //   dispatch(handleSuccessMessage("Successfully created group"));
        // }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const validMember = async (data, onSuccess, onError) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  formData.append("group_id", data);
  await axios({
    method: "post",
    url: GET_VALIDATE_GROUP_MEMBER,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      onSuccess(response.data.data.valid_member);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getProductService = async (groupId, onSuccess, onError) => {
  const token = store.getState()?.profile?.user?.token;
  await axios({
    method: "GET",
    url: `${GET_PRODUCT_SERVICE_API}${groupId}`,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      store.dispatch({ type: POST_PRODUCT_SERVICE, payload: response.data.data });
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

export const postGroup = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    const data1 = { ...data };
    delete data1.code;
    if (Object.values(data1).every((field) => field === "")) {
      dispatch(handleErrorMessage("You can't submit an empty form"));
    } else {
      await axios({
        method: "post",
        url: POST_GROUPS_DETAILS_API,
        data: {
          group_owner: store.getState()?.profile?.user.profile_id,
          grade: "A",
          name: data.groupName,
          description: data.groupDescription,
          // subject: 'Physics',
          group_type: data.groupType,
          // groupCategory:'Social',
          code: data.code,
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            dispatch({
              type: POST_GROUP_DETAILS,
              payload: response.data.status,
            });
            dispatch(handleSuccessMessage("Successfully created group"));
          }
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    }
  };
};

export const joinGroup = (data) => {
  const token = store.getState()?.profile?.user?.token;
  let member_users = store.getState()?.profile?.user?.profile_id;
  return async (dispatch) => {
    const data1 = { ...data, member_users };
    if (Object.values(data1).every((field) => field === "")) {
      dispatch(handleErrorMessage("You can't submit an empty form"));
    } else {
      await axios({
        method: "post",
        url: JOIN_GROUP_API,
        data: {
          ...data1,
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            dispatch({
              type: JOIN_MEMBER_DETAILS,
              payload: response.data.data.group_members,
            });
            dispatch(handleSuccessMessage("Success"));
          }
        })
        .catch(async (error) => {
          dispatch({
            type: JOIN_MEMBER_DETAILS,
            payload: "",
          });
          // dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    }
  };
};

export const joinGroupByCode = (data) => {
  const token = store.getState()?.profile?.user?.token;
  let member_users = store.getState()?.profile?.user?.profile_id;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_GROUP_ID_BY_CODE}?group_code=${data.group_code}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.length == 0) {
            const errorMsg = "No Such Group Found";
            dispatch(handleErrorMessage(errorMsg));
          } else {
            dispatch({
              type: POST_SEARCHED_GROUP_DETAILS,
              payload: response.data.data,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const editGroup = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  if (data.groupName !== "") formData.append("name", data.groupName);

  if (data.groupDescription !== "")
    formData.append("description", data.groupDescription);

  if (data.groupGrade !== "") formData.append("grade", data.groupGrade);
  if (data.groupType !== "") formData.append("group_type", data.groupType);

  // if (data.groupSubject !== "") formData.append("subject", data.groupSubject);

  let value = Object.fromEntries(formData.entries());
  value = { ...value, id: data.groupId };

  return async (dispatch) => {
    await axios({
      method: "put",
      url: `${PUT_GROUP_DETAILS_API}${data.groupId}/`,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({ type: PUT_GROUP_DETAILS, payload: value });
          dispatch(handleSuccessMessage("Successfully updated group"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
export const editEcommerce = (data) => {
  console.log("line517", data);
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  if (data.name !== "") formData.append("name", data.name);

  if (data.description !== "") formData.append("description", data.description);

  if (data.sub_title !== "") formData.append("sub_title", data.sub_title);
  if (data.value !== 0) formData.append("value", data.value);

  if (data.discounted_price !== 0)
    formData.append("discounted_price", data.discounted_price);

  // if (data.groupSubject !== "") formData.append("subject", data.groupSubject);

  let value = Object.fromEntries(formData.entries());
  value = { ...value, id: data.groupId };

  return async (dispatch) => {
    await axios({
      method: "put",
      url: `${EDIT_PRODUCT_SERVICE_API}${data.id}/`,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response?.data?.status == "1"
        ) {
          // dispatch({ type: PUT_GROUP_DETAILS, payload: value });
          dispatch(handleSuccessMessage("Successfully updated ECommerce"));
          window.location = `/group/${data.id}`;
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const deleteGroup = (data) => {
  console.log("line287", data);
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${PUT_GROUP_DETAILS_API}${data}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        dispatch({ type: DELETE_GROUP, payload: data });
        dispatch(handleSuccessMessage("Group deleted successfully"));
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
export const deleteEcommerce = (data) => {
  console.log("line287", data);
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_PRODUCT_SERVICE_API}${data}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        dispatch({ type: DELETE_GROUP, payload: data });
        dispatch(handleSuccessMessage("Group deleted successfully"));
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getGroupPosts = (groupId) => {
  return async (dispatch) => {
    const token = store.getState()?.profile?.user?.token;
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/group/${groupId}/`,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          const data = response.data.data.filter(
            (obj) =>
              obj.visibility_type == "Group" ||
              obj.visibility_type == "GROUP" ||
              obj.visibility_type == "CLASS"
            // obj.post_type.includes("GROUP")
          );
          let posts = data.map((posts) => posts);

          dispatch({ type: GET_GROUP_POST, payload: posts });
          return posts;
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };
};

// ---------------

export const getGroupOwners = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_GROUPS_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const groupOwnerCodes = response.data.data.map(
          (groupData) => groupData.groups.group_owner
        );
        dispatch({ type: GET_ALL_GROUP_OWNER_CODES, payload: groupOwnerCodes });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
