import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./css/BottomContainer.module.css";
// import Photo from './Image/DashboardImages/postModImages/photo.svg'
// import Briefcase from './Image/DashboardImages/postModImages/briefcase.svg'
// import Video from './Image/DashboardImages/postModImages/video.svg'
// import Doc from './Image/DashboardImages/postModImages/document.svg'
import DropBox from "./DropBox";

class DiscussionBottomContainer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        {this.props.actionType !== "DELETE" ? (
          <div className={styles.innerBox}>
            <div
              className={styles.uploadBox}
              style={{ position: "relative", cursor: "pointer" }}
            >
              <input
                onChange={this.props.fileInput}
                type="file"
                accept="image/*"
                className={styles.fileUpload}
              />
              <img
                src="/Image/DashboardImages/postModImages/photo.svg"
                alt="Photos"
                className={styles.photo}
              />
            </div>
            <div className={styles.uploadBox} style={{ position: "relative" }}>
              <input
                onChange={this.props.videoInput}
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                className={styles.fileUpload}
              />
              <img
                src="/Image/DashboardImages/postModImages/video.svg"
                alt="Video"
                className={styles.photo}
              />
            </div>
            <div className={styles.uploadBox} style={{ position: "relative" }}>
              <input
                onChange={this.props.mediaFileInput}
                type="file"
                accept=".doc,.docx,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.txt"
                className={styles.fileUpload}
              />
              <img
                src="/Image/DashboardImages/postModImages/filePost.svg"
                alt="Files"
                className={styles.photo}
              />
            </div>
            {/* <img src={Briefcase} alt="Briefcase" className={styles.photo}/> */}
            {/* <img src={Doc} alt="Document" className={styles.photo}/> */}
          </div>
        ) : null}

        <div className={styles.rightInner}>
          <div
            onClick={
              this.props.actionType == "EDIT"
                ? this.props.onEdit
                : this.props.actionType == "DELETE"
                ? this.props.onDelete
                : this.props.onPost
            }
            className={styles.post}
          >
            {this.props.actionType == "EDIT"
              ? t("common.edit")
              : this.props.actionType == "DELETE"
              ? t("common.delete")
              : t("common.save")}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(DiscussionBottomContainer);
