 import React from "react";
import { updateCourseStatus, deleteCourse } from "../../../redux/actions/courses";
 import './css/dropdown.css'
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { handleErrorMessage  } from "../../../redux/actions/classes";
import { withRouter } from "react-router-dom";

 class DropDownBtn extends React.Component {  

  constructor(props) {
    super(props);    
    this.state = {
      isOpen: false,
      labelItem: null,
      typeDropdown: null,
    };
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.globalClickListener)
  }
  componentWillMount() {
    const { label } = this.props.list[0];
    let firstItem = null;    
    if (typeof label != 'undefined') {
      this.checkType(false);
      firstItem = label;
    } else {
      this.checkType(true);
      firstItem = this.props.list[0];
    }        
    this.setState({
        labelItem: firstItem
    });    
  }

    globalClickListener = (e)=>{
      this.setState({isOpen:false},()=>{
        window.removeEventListener('click',this.globalClickListener)
      })
    }
  

  checkType = (value) => {
    this.setState({
        typeDropdown: value
    });    
  };
  showDropdown = (e) => {
    e.stopPropagation()
    this.setState(prevState => ({isOpen:!prevState.isOpen}), ()=>{
      if(this.state.isOpen){
        window.addEventListener('click',this.globalClickListener)
      }
    })
  };

  chooseItem = (value) => {    

    if(value===1){
        // this is for edit course page
        this.props?.handleRedirect();
    }

    else if(value===2){
       // this is for delete
      this.props?.handleShowActionModal('delete');

    }

    else if(value===3){
      // this is for inactive/active action 
      if(this.props?.data?.courses?.currentCourse?.active){
        // this.props.updateCourseStatus( this.props?.data?.courses?.currentCourse?.id, false, false);
        this.props?.handleShowActionModal('inactive');
      }
      else {
        // this.props.updateCourseStatus( this.props?.data?.courses?.currentCourse?.id, true, false);
        this.props?.handleShowActionModal('active');
      }
    } 
  };
  
  renderDataDropDown = (item, index) => {    
    const {value, label} = this.state.typeDropdown ? {value: index, label: item} : item;    
    return (
      <li
        key={index}
        value={value}
        onClick={() => this.chooseItem(value)}
      >
        <a>{label}</a>
      </li> 
    )
  };
  render () {
    const { list } = this.props;    
    return ( <>
      <div className={`dropdown ${this.state.isOpen ? 'open' : ''}`}>
        <button className="dropdown-toggle" type="button" onClick={this.showDropdown}>
        {this.props.t("courseMonetisation.Action")}
          {/* <span className="caret"></span> */}
          <img className="dropdownIcon" src="/Image/Icons/dropDownIcon.svg" alt="down"/>
        </button>
        <ul className="dropdown-menu">
          {list.map(this.renderDataDropDown)}
        </ul>
    </div>
    </>)
  }
}

function mapStateToProps(state, props) {
  return {
    data:state,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateCourseStatus: (courseId, isActive, isFeed) => dispatch(updateCourseStatus(courseId,isActive, isFeed)),
    deleteCourse: (courseId) => dispatch(deleteCourse(courseId)),
    handleErrorMessage: (message) => dispatch(handleErrorMessage(message)),
  };
}



export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(DropDownBtn))
);

