import React from "react";
import { Component } from "react";
import styles from "./groupcss/groupsidebar.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";

class ClassSidebar extends Component {
  state = {
    img: "/Image/DashImage/live-session.svg",
    showClassMenu: false,
    myGroups: ["Numerical Practice", "Doubt Discussion", "Exam Preperation"],
    activeGroup: "Numerical Practice",
    showGroupMenu: false,
  };
  toogleMenu1 = () => {
    this.setState({
      showGroupMenu: !this.state.showGroupMenu,
    });
  };
  changeActiveSect = (selectedSection) => {
    if (this.props.activeSection != selectedSection) {
      this.props.changeActiveSection();
    }
  };
  Mygroups = () => {
    if (this.state.showGroupMenu) {
      const ClassesList = () => (
        <ul>
          {this.state.myGroups.map((myGroup, index) => (
            <div className={styles.menu1}>
              <li
                style={{
                  marginTop: 6,
                  marginBottom: 6,
                  font: "normal normal normal 16px/19px Montserrat",
                  color: this.state.activeGroup !== myGroup ? "#555555" : "#00d498",
                }}
              >
                {myGroup}
              </li>
              {index < this.state.myGroups.length - 1 ? <hr className={styles.ClassHr} /> : null}
            </div>
          ))}
        </ul>
      );
      return (
        <div className={styles.dropdown_menu1}>
          <ClassesList />
        </div>
      );
    } else return <></>;
  };
  render() {
    this.state.isPostActiveSection = this.props.activeSection == "Post";
    return (
      <div className={styles.Class_Sidebar}>
        <div className={styles.Classsidebar_mycontent}>
          <div className={styles.Class_side}>
            <img src="/Image/DashImage/Chalkmate_Mygroup.svg" className={styles.Classsidebar_icon1} style={{ width: 35, height: 35, cursor: "pointer" }} />
            <h3 style={{ marginLeft: 15, fontSize: 16 }}>My Groups</h3>
          </div>
          <div className={styles.Classnavbar_menu1}>
            <MoreHorizIcon className={styles.Classsidebar_icon2} style={{ marginTop: 7, cursor: "pointer" }} onClick={this.toogleMenu1} />
            <this.Mygroups />
          </div>
        </div>

        <div className={styles.Classsidebar_stats}>
          <div className={styles.Classsidebar_stat} onClick={() => this.changeActiveSect("Post")}>
            <p
              style={{
                color: this.state.isPostActiveSection ? "#00D498" : "black",
                padding: 0,
                margin: 0,
              }}
            >
              Post
            </p>
            <p
              style={{
                color: "#00D498",
                fontWeight: 600,
                paddingRight: 14,
                margin: 0,
              }}
            >
              8
            </p>
          </div>
          <hr className={styles.ClassHr} />
          <div className={styles.Classsidebar_stat} onClick={() => this.changeActiveSect("Members")}>
            <p
              style={{
                color: !this.state.isPostActiveSection ? "#00D498" : "black",
                padding: 0,
                margin: 0,
              }}
            >
              Members
            </p>
            <p
              style={{
                color: "#00D498",
                fontWeight: 600,
                paddingRight: 14,
                margin: 0,
              }}
            >
              5
            </p>
          </div>
        </div>
        <div
          className={styles.Classsidebar_mycontent}
          onMouseEnter={() => {
            this.setState({
              img: "/Image/DashImage/live-session-hover.svg",
            });
          }}
          onMouseOut={() => {
            this.setState({
              img: "/Image/DashImage/live-session.svg",
            });
          }}
        >
          <Link to="/video" style={{ textDecoration: "none", width: "100%" }}>
            <button
              className={styles.live_session}
              onMouseEnter={() => {
                this.setState({
                  img: "/Image/DashImage/live-session-hover.svg",
                });
              }}
            >
              <img
                src={this.state.img}
                onMouseEnter={() => {
                  this.setState({
                    img: "/Image/DashImage/live-session-hover.svg",
                  });
                }}
                className={styles.video}
                style={{ width: 24, height: 24 }}
              />
              <span
                onMouseEnter={() => {
                  this.setState({
                    img: "/Image/DashImage/live-session-hover.svg",
                  });
                }}
                style={{ marginLeft: 10 }}
              >
                START LIVE CLASS
              </span>
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default ClassSidebar;
