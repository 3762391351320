import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./groups";
import { handleSuccessMessage } from "./success";

export function addVolunteer(veState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("organisation", veState.organisation);
    data.append("description", veState.description);
    data.append("start_date", veState.start_date);
    data.append("end_date", veState.end_date);
    data.append("role", veState.role);
    data.append("cause", veState.cause);
    data.append("profile_id", JSON.parse(localStorage.getItem("userData")).profile_id);
    data.append("upload_img", veState.pictureAsFile, veState.pictureAsFile.name);

    // validations
    if (veState.start_date == null || veState.end_date == null) {
      return { val: false, message: "error at adding volunteer - Empty startdate and end date" };
    } else if (veState.start_date > veState.end_date) {
      return { val: false, message: "error at adding educaiton - start date must be less than end date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/volunteer/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          dispatch(handleSuccessMessage("Added Successfully"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateVolunteer(veState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("organisation", veState.organisation);
    data.append("description", veState.description);
    data.append("start_date", veState.start_date);
    data.append("end_date", veState.end_date);
    data.append("role", veState.role);
    data.append("cause", veState.cause);
    if (veState.uploadImageFlag) {
      data.append("upload_img", veState.pictureAsFile, veState.pictureAsFile.name);
    }

    // validaitions
    if (veState.start_date == null || veState.end_date == null) {
      return { val: false, message: "error at adding volunteer - Empty upload image" };
    } else if (veState.start_date > veState.end_date) {
      return { val: false, message: "error at adding volunteer - start date must be less than end date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/volunteer/${veState.id}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status=="1") {
          let value = Object.fromEntries(data.entries());
          if (veState.uploadImageFlag) {
            value = {...value, id: veState.id, upload_img: URL.createObjectURL(veState.pictureAsFile)}
          }
          else{
            value = {...value, id: veState.id, upload_img: veState.pictureAsFile}
          }
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated Successfully"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
