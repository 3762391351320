import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function fetchModelSkills(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/modelskills/`, { headers: headers })
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateSkills(action, data, arrData, type="add") {
  const modelSkill = store.getState()?.profile.modelSkills;
  const  skillUpdated = store.getState()?.profile.skills;
  if(arrData!=undefined && type!="del"){
    arrData.forEach((arrInd) => {
      const index = modelSkill.findIndex(modelSkill => {
        return modelSkill.id == arrInd;
      });
      skillUpdated.push(modelSkill[index]);
    })
  }
  else if(arrData!=undefined && type=="del"){
    arrData.forEach((arrInd) => {
      const index = modelSkill.findIndex(modelSkill => {
        return modelSkill.id == arrInd;
      });
      while(skillUpdated.length > 0) {
        skillUpdated.pop();
      }
    
      skillUpdated.push(modelSkill[index]);
    })
  }
  if(data.skills==""){
    skillUpdated.splice(0,skillUpdated.length)
  }
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/skill/edit/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: skillUpdated,
          });
          dispatch(handleSuccessMessage("Updated successfully"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
