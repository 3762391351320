import { PAYMENT_CREATE, PAYMENT_FAILURE, PAYMENT_SUCCESS,PAYMENT_CREATE_COURSE } from "../constants/actionTypes";
import axios from "axios";
import { PAYMENT_CREATE_API, PAYMENT_FAILURE_API, PAYMENT_SUCCESS_API } from "../constants/apis";
import { store } from "../store";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { zoomSubscriptionPackages } from "../constants/zoomSubscriptionPackages";
import { handleSuccessMessage } from "./success";
import { handleErrorMessage } from "./classes";

const CURRENCY = "INR"
const SERVICE = "Zoom"

export const createPayment = () => {
    const token = store.getState()?.profile?.user?.token;
    return async (dispatch) => {
      await axios({
        method: "post",
        url: PAYMENT_CREATE_API,
        data: {
            currency: CURRENCY,
            service: SERVICE,
            // amount: zoomSubscriptionPackages.subscriptionAmount
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
            dispatch({ type: PAYMENT_CREATE, payload: response.data.data });
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    };
  };

  export const successPayment = (data) => {
    const token = store.getState()?.profile?.user?.token;
    return async (dispatch) => {
      await axios({
        method: "put",
        url: PAYMENT_SUCCESS_API,
        data,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
            dispatch({ type: PAYMENT_SUCCESS, payload: response.data.data });
            // dispatch(handleSuccessMessage(response.data.data.message))            
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    };
  }
  export const failurePayment = (data) => {
    const token = store.getState()?.profile?.user?.token;
    return async (dispatch) => {
      await axios({
        method: "put",
        url: PAYMENT_FAILURE_API,
        data,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
            dispatch({ type: PAYMENT_FAILURE, payload: response.data.data });
            // dispatch(handleSuccessMessage(response.data.data.message))            

        })
        .catch(async (error) => {
          // dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    };
  }


  // create payment method for courseMonetisation

  
export const createPaymentCourse = (id,amount) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: PAYMENT_CREATE_API,
      data: {
          currency: "USD",
          product_id:id,
          service: "course",
          amount: amount
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
          console.log("response:",response.data.data);
          dispatch({ type: PAYMENT_CREATE_COURSE, payload: response.data.data });
          

      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


