import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ContentTypeButtonMenu.module.css";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    disableScrollLock={false}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    // minWidth: 180,
    // color:
    //   theme.palette.mode === "light"
    //     ? "rgb(55, 65, 81)"
    //     : theme.palette.grey[300],
    color: "#000",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ContentTypeButtonMenu = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#00D498",
    borderColor: "#00D498",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#00D498",
      color: "#00D498",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    '@media (max-width: 500px)': {
      height: "22px",
      padding: "5px 11px 6px 11px"
    },
  };

  const styleArrowDownIcon = {
    width: "15px",
    height: "15px"
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableRipple
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon sx={styleArrowDownIcon} />}
        sx={styleButtonMUI}
        disabled={props.disabled}
      >
        <span className={styles.btn_label}>{t("common.content")}</span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            props.onClickVideo();
          }}
          disableRipple
        >
          <img src={"/Image/CourseMonetisation/video-line.svg"} className={styles.menu_icon} />
          <span className={styles.menu_item_label}>{t("common.video")}</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.onClickArticle();
          }}
          disableRipple
        >
          <img src={"/Image/lecturePlan/article-(student).svg"} className={styles.menu_icon} />
          <span className={styles.menu_item_label}>{t("common.article")}</span>
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default ContentTypeButtonMenu;
