import React from 'react'
import { useState } from 'react';
import ReactPlayer from "react-player";
import styles from './commoncss/VideoCard.module.css'

const VideoCard = (props) => {
    const [playButton, setPlayButton] = useState(true);

    const handlePlayButton = () => {
        setPlayButton(false)
    }
    return (
        <div className={styles.player_container}>
            <ReactPlayer
                className='video'
                // url='/Image/FeatureVideos/Chalkboard.m4v'
                url={props?.path}
                playing={!playButton}
                controls={true}
                width='100%'
                height='100%'
                onPause={() => setPlayButton(true)}
                onEnded={() => setPlayButton(true)}
                onPlay={() => setPlayButton(false)}
            />
            {
                playButton &&
                <div className={styles.play_btn} id="playButton" onClick={() => handlePlayButton()}>
                    <div className={styles.play_btn_icon}>
                        <img src="/Image/play-icon.svg" alt="" />
                    </div>
                </div>
            }
        </div>
    )
}

export default VideoCard;