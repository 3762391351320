import React, { Component } from "react";
import styles from "./CSSFiles/suggestionCard.module.css";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";

function SuggestionCard({ Background, link }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img
        src={Background}
        width="100%"
        style={{
          borderRadius: 15,
          maxHeight: "140px",
          objectFit: "cover",
          minHeight: "140px",
        }}
      />

      <Box sx={{ position: "absolute", bottom: 9, left: 50 }}>
        <Button
          onClick={() => window.open(link)}
          disableRipple
          size="small"
          sx={{
            textTransform: "none",
            background: "var(--primary-gradient)",
            borderRadius: "20px",
            color: "#fff",
            px: 2,
            mt: 1,
            ":hover": {
              background: "var(--primary-gradient)",
            },
            fontWeight: 500,
            lineHeight: { xs: 1.75, sm: 1.75, md: 1.25, lg: 1.75 },
          }}
        >
          Get more details
        </Button>
      </Box>
    </div>
  );
}

export default SuggestionCard;
