import { GET_SUCCESS_MESSAGE } from "../constants/actionTypes";
let successData = {};
export default (state = {}, action) => {
  switch (action.type) {
    case GET_SUCCESS_MESSAGE:
      successData = { ...state };
      successData.successMessage = action.payload;
      return successData;
    default:
      return state;
  }
};
