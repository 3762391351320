import React from "react";

import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";
import {
  fetchModelInterests,
  updateInterests,
} from "../../../../../../redux/actions/interest";
import {
  GET_MODEL_INTERESTS,
  UPDATE_INTERESTS,
} from "../../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

class EditInterests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intIds: [],
      intContent: this.props.intContent,

      deletedIds: [],
      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  setIntIds() {
    let intIds = [];
    this.props.intContent.map((item) => {
      intIds.push(item.id);
    });

    let csvIds = "";
    intIds.map((item) => {
      csvIds += item + ",";
    });

    this.setState({ intIds: intIds, csvIntIds: csvIds });
  }

  getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  componentDidMount() {
    this.setIntIds();
    if (this.props.modelInterest == null) {
      this.props.fetchModelInterests(GET_MODEL_INTERESTS);
    }
  }

  onClickDelete(id) {
    let updatedDeletedIds = [...this.state.deletedIds, id];

    let updatedIntContent = this.state.intContent.filter(
      (item) => !updatedDeletedIds.includes(item.id)
    );

    let updatedIntIds = this.state.intIds.filter((item) => item != id);

    this.setState({
      intContent: updatedIntContent,
      deletedIds: updatedDeletedIds,
      intIds: updatedIntIds,
    });
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  getAllAndConvertToCSV() {
    if (this.state.intIds.length == 0) {
      return "";
    }

    let csvIds = "";
    this.state.intIds.map((item) => {
      csvIds += item + ",";
    });
    return csvIds.slice(0, csvIds.length - 1);
  }

  SaveButtonOnClick() {
    // this.updateDeletedContent()
    if (this.state.deletedIds.length == 0) {
      document.body.style.overflow = "visible";
      return;
    }
    const data = { interests: this.getAllAndConvertToCSV() };
    this.props
      .updateInterests(UPDATE_INTERESTS, data, this.state.intIds, "del")
      .then((res) => {
        if (res.val) {
          this.props.changeEdit();
          document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
  }

  ScrollBody() {
    const { t } = this.props;

    if (this.state.intContent.length == 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <p style={{ fontSize: 16, color: "#000" }}>
            {t("profile.noInformationAvailable")}
          </p>
        </div>
      );
    }

    return (
      <div
        id={styles.scroll_div}
        style={{
          maxHeight: 400,
          overflow: "scroll",
          padding: 3,
        }}
      >
        {this.state.intContent.map((item, idx) => {
          return (
            <div key={idx} style={{ margin: 5, flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: 20,
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={item.upload_img}
                        height="60px"
                        width="60px"
                        style={{ borderRadius: 40, marginRight: 10 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p className={styles.section_title}>{item.title}</p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/Image/apps/profile-resume-edit-view/delete.svg"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => this.onClickDelete(item.id)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.modalTitle}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    intContent: state.profile?.interests || [],
    modelInterest: state.profile?.modelInterests || null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInterests: (action, data, arrData, type) =>
      dispatch(updateInterests(action, data, arrData, type)),
    fetchModelInterests: (action) => dispatch(fetchModelInterests(action)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EditInterests)
);
