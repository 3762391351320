import { Button, Card, Typography } from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { useContext } from "react";
import { DiscoverContext } from "./DiscoverContext";
import { useHistory } from "react-router-dom";

const CoverCard = ({ place }) => {
  const { MarkInterested } = useContext(DiscoverContext);
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        position: "relative",
        background: `url(${
          place?.profile_data?.profile_pic ||
          "/Image/Discover/Business/discover_business.png"
        })`,
        minHeight: 200,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Button
        onClick={() => MarkInterested(null, place?.profile_id)}
        variant="contained"
        disableRipple
        sx={{
          background: "var(--primary-gradient)",
          textTransform: "capitalize",
          mb: 1.5,
          width: "70%",
          borderRadius: 2,
          color: "#fff",
          fontWeight: 600,
          ":hover": {
            background: "var(--primary-gradient)",
            boxShadow: "none",
          },
          boxShadow: "none",
        }}
      >
        <CalendarMonthIcon sx={{ color: "#fff", mr: 0.5 }} />
        Interested
      </Button>
    </Card>
  );
};

export const EventCoverCard = () => {
  const history = useHistory();

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        position: "relative",
        background: `url("/Image/Discover/event-cover-image.jpg")`,
        minHeight: 250,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "28px",
          fontWeight: 700,
          lineHeight: "32px",
          ml: 2,
          mr: 2,
          color: "#fff",
        }}
      >
        Invite your neighbors to a party, food drive, or meetup
      </Typography>
      <Button
        onClick={() =>
          history.push({
            pathname: "/user",
            state: { isModalOpen: true, isEventModalOpen: true },
          })
        }
        variant="contained"
        disableRipple
        sx={{
          background: "var(--primary-gradient)",
          textTransform: "capitalize",
          mb: 3,
          width: "70%",
          borderRadius: 2,
          color: "#fff",
          fontWeight: 600,
          ":hover": {
            background: "var(--primary-gradient)",
            boxShadow: "none",
          },
          boxShadow: "none",
          mt: 2,
        }}
      >
        Create Event
      </Button>
    </Card>
  );
};

export const SellCoverCard = ({ place }) => {
  const { MarkInterested } = useContext(DiscoverContext);
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        position: "relative",
        background: `url("${
          place?.sell_images !== null && place?.sell_images?.length !== 0
            ? place?.sell_images[0]?.image
            : "/Image/Discover/Sale/discover_sale.png"
        }")`,
        minHeight: 250,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "17px",
          fontWeight: 600,
          lineHeight: "24px",
          ml: 2,
          mr: 2,
          mb: 1,
          color: "#fff",
        }}
      >
        {place?.post_details?.title}
      </Typography>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "20px",
          ml: 2,
          mr: 2,
          color: "#fff",
        }}
      >
        {place?.post_details?.free
          ? "Free"
          : `₹${
              place?.post_details?.discounted_price
                ? place?.post_details?.discounted_price
                : place?.post_details?.pricing
            }  ${
              place?.post_details?.discounted_price
                ? `· ${place?.post_details?.discount_offer.slice(0, 2)}% off`
                : ""
            }`}
      </Typography>
      {/* <Button
        variant="contained"
        disableRipple
        sx={{
          background: "#E5E5E5",
          textTransform: "capitalize",
          mb: 3,
          width: "70%",
          borderRadius: 2,
          color: "#000",
          fontWeight: 600,
          ":hover": {
            background: "#E5E5E5",
            boxShadow: "none",
          },
          boxShadow: "none",
          mt: 2,
        }}
      >
        <QuestionAnswerOutlinedIcon sx={{ color: "#000", mr: 0.5 }} />
        Message
      </Button> */}
      <Button
        onClick={() => MarkInterested(place?.id, null)}
        variant="contained"
        disableRipple
        sx={{
          background: "var(--primary-gradient)",
          textTransform: "capitalize",
          mb: 3,
          width: "70%",
          borderRadius: 2,
          color: "#fff",
          fontWeight: 600,
          ":hover": {
            background: "var(--primary-gradient)",
            boxShadow: "none",
          },
          boxShadow: "none",
        }}
      >
        <CalendarMonthIcon sx={{ color: "#fff", mr: 0.5 }} />
        Interested
      </Button>
    </Card>
  );
};

export const GroupCoverCard = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        position: "relative",
        background: `url("/Image/Discover/group-cover-image.jpg")`,
        minHeight: 250,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "17px",
          fontWeight: 600,
          lineHeight: "24px",
          ml: 2,
          mr: 2,
          mb: 1,
          color: "#fff",
        }}
      >
        Stress relief Support
      </Typography>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "20px",
          ml: 2,
          mr: 2,
          color: "#fff",
        }}
      >
        100 members
      </Typography>
      <Button
        variant="contained"
        disableRipple
        sx={{
          background: "var(--primary-gradient)",
          textTransform: "capitalize",
          mb: 3,
          width: "70%",
          borderRadius: 2,
          color: "#fff",
          fontWeight: 600,
          ":hover": {
            background: "var(--primary-gradient)",
            boxShadow: "none",
          },
          boxShadow: "none",
          mt: 2,
        }}
      >
        <GroupOutlinedIcon sx={{ color: "#fff", mr: 0.5 }} />
        Join
      </Button>
    </Card>
  );
};

export default CoverCard;
