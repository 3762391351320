import { React, useRef, useState, useEffect } from "react";
import "../Sign.css";
import { Link, useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { loginUser, loginSuccess } from "../../../redux/actions/login";
import { useTranslation } from "react-i18next";
import SignNav from "../../home/Nav/SignNav";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

function Signin({ user, error, loginUser, loginSuccess, errorMessage }) {
  useEffect(() => {
    const userData = localStorage.getItem("userData");

    if (userData) {
      loginSuccess(JSON.parse(userData));
      setalreadyLoggedIn(true);
    }
  }, []);
  const { t } = useTranslation();

  const horizontal = "center";
  const vertical = "top";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [alreadyLoggedIn, setalreadyLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const history = useHistory();
  const userRef = useRef(user);
  userRef.current = user;
  const errorRef = useRef(error);
  errorRef.current = error;
  const errorsRef = useRef(errorMessage);
  errorsRef.current = errorMessage;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(data) {
    const payload = {
      username: data.email,
      password: data.password,
    };
    const onSuccess = (res) => {
      console.log(res);
      setMessage("Login Successful");
      setSeverity("success");
      setOpen(true);
      history.push(`/user`);
    };
    const onError = (error) => {
      console.log(error.response);
      const errorObj = error.response.data?.error;
      const keys = errorObj && Object.keys(errorObj);
      setMessage(
        error.response?.data?.detail || errorObj[keys[0]] || "Login Failed"
      );
      setSeverity("error");
      setOpen(true);
    };

    loginUser(JSON.stringify(payload), onSuccess, onError);
  }

  return (
    <div className="sign-container">
      {alreadyLoggedIn ? <Redirect to={`/user`} /> : null}
      <SignNav />
      <Grid container spacing={6}>
        <Grid item xs={12} md={6} sx={{ ml: 5, mr: { xs: 10, md: 0 } }}>
          <div className="sign-container-left">
            <div className="sign-form">
              <div className="sign-heading">
                <div>{t("common.signIn")}</div>
                <div>{t("signin.signInDesc")}</div>
              </div>
              <Box
                className="sign-input"
                sx={{ width: { xs: "300px", md: "450px" } }}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  className="sign-field"
                  {...register("email", {
                    required: true,
                    pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                  })}
                  type="text"
                  placeholder={t("common.email")}
                />
                <div className={`input-alert`}>
                  {errors.email?.type === "required" && t("errors.emailError")}
                </div>
                <div className={`input-alert`}>
                  {errors.email?.type === "pattern" && t("errors.validEmail")}
                </div>

                <input
                  className="sign-field"
                  {...register("password", { required: true, minLength: 8 })}
                  type="password"
                  placeholder={t("common.password")}
                />
                <div className={`input-alert`}>
                  {errors.password?.type === "required" &&
                    t("errors.passwordError")}
                </div>
                <div className={`input-alert`}>
                  {errors.password?.type === "minLength" &&
                    t("errors.validPasswordError")}
                </div>

                <div className="sign-action">
                  <Link to="forgotpassword">
                    <div className="forgot-password">
                      {t("signin.forgotPassword")}
                    </div>
                  </Link>
                </div>
                <button className="button sign-btn" onClick={handleSubmit}>
                  {t("common.login")}
                </button>

                <div className="or">
                  {/* <hr /> */}
                  {t("common.or")}
                  {/* <hr /> */}
                </div>
                <div className="account-already">
                  {t("signin.noAccount")}
                  <span>
                    {" "}
                    <Link to="signup">{t("common.signup")}</Link>
                  </span>
                </div>
              </Box>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          md={5}
          sx={{
            mr: 4,
            display: { xs: "none", md: "block" },
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "15vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 8.png"
                alt="sign photo"
                style={{ width: "70%", borderRadius: "20px", marginRight: 20 }}
              />
              <img
                src="/Image/signImage/Asset 7.png"
                alt="sign photo"
                style={{ width: "85%", borderRadius: "20px" }}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "5vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 9.svg"
                alt="sign photo"
                style={{ width: "40%", borderRadius: "20px" }}
              />
              <img
                src="/Image/signImage/Asset 6.png"
                alt="sign photo"
                style={{ width: "90%", borderRadius: "20px" }}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "center",
                width: "100%",
                mt: 5,
              }}
            >
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "26px", fontWeight: 600, color: "#ed2f75" }}
              >
                {t("signup.bottomLine2")}{" "}
              </Typography>
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine3")}{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine2")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state

  return {
    user: state?.profile?.user,
    error: state.profile.error,
    errorMessage: state.errors.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  //mapDisptachToProps is used to dispatch actions
  return {
    loginUser: (data, onSuccess, onError) =>
      dispatch(loginUser(data, onSuccess, onError)),
    loginSuccess: (userData) => dispatch(loginSuccess(userData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
