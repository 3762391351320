import React, { createContext, Component } from "react";
// import PostSampleImg2 from './Image/DashboardImages/DashCommonImages/postSampleImg2.png';
import { connect } from "react-redux";
import axios from "axios";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { CircularProgress } from "@mui/material";
import {
  fetchProfileDataEdit,
  fetchProfileDataView,
} from "../../../redux/actions/profile";
import {
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
} from "../../../redux/constants/actionTypes";

export const ProfileContext = createContext();

class ProfileProviderComponent extends Component {
  state = {
    profileGallery: [],
    loading: true,
  };

  getProfileGallery = (profile_id) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/getprofilegallery/${profile_id}/`,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              profileGallery: response.data.data,
              loading: false,
            });
          } else {
            this.props.handleErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.handleErrorMessage(error.message);
        });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  addGalleryImage = (img, id) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    const data = new FormData();
    data.append("images", img);

    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/profilegallery/${parsedUserData.profile_id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.props.handleSuccessMessage("Image uploaded successfully");
            this.getProfileGallery(id);
          } else {
            alert("Alert Response: " + response.data.message);
          }
        })
        .catch((error) => {
          alert("Something went wrong, Please try again.");
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  addAvatar = (gender) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    const data = new FormData();
    data.append("gender", gender);

    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/profilepic/${parsedUserData.profile_id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.props.fetchProfileDataEdit(
              GET_PROFILE_DATA_EDIT,
              parsedUserData.token,
              parsedUserData.profile_id
            );
            this.props.handleSuccessMessage("Avatar selected successfully");
          } else {
            alert("Alert Response: " + response.data.message);
          }
        })
        .catch((error) => {
          alert("Something went wrong, Please try again.");
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  addRating = (rating, profile_liked_to) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    const data = new FormData();
    data.append("profile_liked_to", profile_liked_to);
    data.append("rating", rating);
    data.append("profile_liked_by", parsedUserData.profile_id);

    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/profile_rating/`,
        data: data,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          this.props.fetchProfileDataView(
            GET_PROFILE_DATA_VIEW,
            parsedUserData.token,
            this.props.profileIdView
          );
          console.log("check");
          this.props.handleSuccessMessage("Profile rated successfully");
        })
        .catch((error) => {
          alert("You have already rated this profile.");
        });
    } catch (error) {
      console.log(error);
      alert("You have already rated this profile.");
    }
  };

  render() {
    let contextData = {
      state: this.state,
      addGalleryImage: this.addGalleryImage,
      getProfileGallery: this.getProfileGallery,
      addAvatar: this.addAvatar,
      addRating: this.addRating,
    };
    return (
      <ProfileContext.Provider value={contextData}>
        {this.props.children}
      </ProfileContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    profileIdView: state.profile.profileIdView,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
    fetchProfileDataEdit: (action, token, id) =>
      dispatch(fetchProfileDataEdit(action, token, id)),
    fetchProfileDataView: (action, token, id) =>
      dispatch(fetchProfileDataView(action, token, id)),
  };
}

export const ProfileProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileProviderComponent);
