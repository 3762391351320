import React, { useEffect, useRef, useState } from "react";
import styles from "./CSSFiles/MessageSender.module.css";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PostModal from "../postModal/PostModal";
import { Button, Paper, TextField, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function MessageSender() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const [showModal, toggleModal] = useState(
    location?.state?.isModalOpen ? location?.state?.isModalOpen : false
  );
  const profilePicUrl = useSelector((state) => state.profile.userProfilePic);
  const avatarImg = useSelector((state) => state.profile.avatarImg);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showEventModal, setShowEventModal] = useState(
    location?.state?.isEventModalOpen
      ? location?.state?.isEventModalOpen
      : false
  );
  const [showExpertModal, setShowExpertModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);

  const inputRef = useRef < HTMLInputElement > null;

  useEffect(() => {
    const success = async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      try {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GoogleMapsApiKey}`
        );

        localStorage.setItem("lat", JSON.stringify(latitude));
        localStorage.setItem("lng", JSON.stringify(longitude));
        localStorage.setItem(
          "address",
          JSON.stringify(res.data.results[0].formatted_address)
        );
        localStorage.setItem(
          "shortAddress",
          JSON.stringify(res.data.results[0].address_components[3])
        );
      } catch (error) {
        console.log("failed to fetch location", error);
      }
    };

    const error = (error) => {
      console.log("Unable to retrieve your location", error);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  const OpenModal = () => {
    toggleModal(true);
  };

  const EventModalOpener = () => {
    setShowEventModal(true);
  };

  const EventModalCloser = () => {
    setShowEventModal(false);
  };

  const ExpertModalOpener = () => {
    setShowExpertModal(true);
  };

  const ExpertModalCloser = () => {
    setShowExpertModal(false);
  };

  const closeModal = () => {
    toggleModal(false);
    setIsDisabled(false);
    history.replace("/user", { isEventModalOpen: false, isModalOpen: false });
  };

  const handleEventButton = () => {
    OpenModal();
    EventModalOpener();
  };

  const handleExpertButton = () => {
    OpenModal();
    ExpertModalOpener();
  };

  const handleImageButton = () => {
    OpenModal();
  };

  const postButtons = [
    {
      name: "postPhoto",
      icon: "/Image/DashboardImages/postModImages/picture_icon.svg",
      // color: "#378fe9",
      text: "common.photo",
      method: handleImageButton,
    },
    {
      name: "postSell",
      icon: "/Image/DashboardImages/postModImages/sell_icon.svg",
      // color: "#5f9b41",
      text: "common.sell",
      method: () => {
        OpenModal(), setShowSellModal(true);
      },
    },
    {
      name: "postEvent",
      icon: "/Image/DashboardImages/postModImages/event_icon.svg",
      // color: "#c37d16",
      text: "common.events",
      method: handleEventButton,
    },
    {
      name: "findAnExpert",
      icon: "/Image/DashboardImages/postModImages/expert_icon.svg",
      // color: "#e16745",
      text: "common.expert",
      method: handleExpertButton,
    },
  ];

  return (
    <Paper variant="outlined" sx={{ mb: 2, borderRadius: "10px" }}>
      <div className={styles.messageSender_top}>
        <div className={styles.message_input}>
          <PostModal
            actionType="ADD"
            showModal={showModal}
            closeModal={closeModal}
            showEventModal={showEventModal}
            EventModalOpener={EventModalOpener}
            EventModalCloser={EventModalCloser}
            showExpertModal={showExpertModal}
            ExpertModalOpener={ExpertModalOpener}
            ExpertModalCloser={ExpertModalCloser}
            inputRef={inputRef}
            showSellModal={showSellModal}
            setShowSellModal={setShowSellModal}
            visiblity_type="Anyone"
          />
          <img
            src={
              profilePicUrl
                ? profilePicUrl
                : avatarImg
                ? avatarImg
                : "/Image/header/ddefault_img.svg"
            }
            style={{
              width: 48,
              height: 48,
              borderRadius: "100%",
              objectFit: "cover",
            }}
          />
          <form
            onClick={() => {
              OpenModal(), setIsDisabled(true);
            }}
          >
            <TextField
              disabled={isDisabled}
              placeholder={t("dashboard.shareThoughts")}
              autoComplete="off"
              InputProps={{
                sx: {
                  borderRadius: "35px",
                  ":hover": {
                    background: "rgba(0,0,0,0.08)",
                  },
                  input: {
                    cursor: "pointer",
                    padding: "12px 14px",
                  },
                },
              }}
              sx={{
                ml: 2,
                "&:hover fieldset": {
                  border: "none",
                },
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </form>
        </div>

        <div className={styles.messageSender_bottom}>
          {postButtons.map((button) => (
            <Button
              startIcon={
                <Avatar
                  variant="rounded"
                  src={button.icon}
                  alt="post icons"
                  sx={{ width: 25, height: "auto" }}
                />
              }
              disableRipple
              key={button.name}
              name={button.name}
              variant="contained"
              onClick={button.method}
              sx={{
                background: "#fff",
                boxShadow: "none",
                color: "primary.black",
                textTransform: "capitalize",
                ":hover": {
                  background: "rgba(0,0,0,0.08)",
                  boxShadow: "none",
                },
              }}
            >
              <div style={{ display: "flex" }}>
                {/* <img src={button.icon} alt="post icons" /> */}
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "15px",
                    display: { sm: "block", xs: "none" },
                  }}
                >
                  {t(button.text)}
                </Typography>
              </div>
            </Button>
          ))}
        </div>
      </div>
    </Paper>
  );
}

export default MessageSender;
