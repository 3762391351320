import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import axios from "axios";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";

function EditModal(props) {
    const { open, defaultValue, handleClose, channelId, messageId, socket } =
        props;
    const [value, setValue] = useState(defaultValue);

    function handleOnChange(e) {
        setValue(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const payload = {
            msg_id: messageId,
            content: value,
            event: "update_message",
            channel_id: channelId,
            user_id: parsedUserData.user_id,
        };
        socket.send(JSON.stringify(payload));
        handleClose();
    }

    return (
        <Dialog
            sx={{ "& .MuiDialog-paper": { width: "30%", maxHeight: 435 } }}
            onClose={handleClose}
            open={open}
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>Edit Text</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    label="New Message"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={handleOnChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditModal;
