import React, { useState, useRef, useEffect } from "react";
import styles from "../apps/Profile/Profile.module.css";
import i18next from "i18next";
import { connect } from "react-redux";
import {
  fetchProfileDataEdit,
  fetchProfileDataView,
} from "../redux/actions/profile";
import { changeCurrentLanguage } from "../redux/actions/dashboard";
import {
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
} from "../redux/constants/actionTypes";
import { getClassDetails } from "../redux/actions/classes";
import { getGroupDetails } from "../redux/actions/groups";

function LanguageButton({
  changeCurrentLanguage,
  fetchProfileDataEdit,
  fetchProfileDataView,
  getClassDetails,
  getGroupDetails,
}) {
  const langua =
    localStorage.getItem("i18nextLng") ||
    navigator.language ||
    navigator.userLanguage;
  const [lang, setLang] = useState(langua);
  const [showLangMenu, setShowLangMenu] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowLangMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const toggleLanguageMenu = () => {
    setShowLangMenu(!showLangMenu);
  };

  const changeSelectedLanguage = (selectedLang) => {
    i18next.changeLanguage(selectedLang);
    localStorage.setItem("i18nextLng", selectedLang);
    changeCurrentLanguage(selectedLang);
    setLang(selectedLang);

    if (window.location.pathname.split("/")[1] == "class") {
      const id = window.location.pathname.split("/")[2];
      getClassDetails(id).then(() => {});
    }
    if (window.location.pathname.split("/")[1] == "group") {
      const id = window.location.pathname.split("/")[2];
      getGroupDetails(id).then(() => {});
    }
    if (
      window.location.pathname.split("/")[1] == "profile" &&
      window.location.pathname.split("/")[3] == "view"
    ) {
      fetchProfileDataView(
        GET_PROFILE_DATA_VIEW,
        JSON.parse(localStorage.getItem("userData"))?.token,
        window.location.pathname.split("/")[2]
      ).then(() => {});
    }
  };

  return (
    <div
      className={styles.language_div_dark}
      onClick={() => toggleLanguageMenu()}
      ref={ref}
    >
      <img
        className={styles.language_header}
        src="/Image/header/language_icon.svg"
        alt="language icon"
      />
      {/* <div className={styles.langTextDiv}>
        {lang == "uz"
          ? "o'zbek"
          : lang == "ru"
          ? "русский"
          : lang == "hi"
          ? "हिंदी"
          : "English"}
      </div> */}
      {showLangMenu ? (
        <div className={styles.dropdownLanguage}>
          <ul className={styles.dropdownList}>
            <div>
              <li
                className={
                  lang == "en" ? styles.activeList : styles.inactiveList
                }
                onClick={() => changeSelectedLanguage("en")}
              >
                English
              </li>
              {/* <hr style={{ margin: "8px 0" }} />
              <li
                className={
                  lang == "ru" ? styles.activeList : styles.inactiveList
                }
                onClick={() => changeSelectedLanguage("ru")}
              >
                русский
              </li>
              <hr style={{ margin: "8px 0" }} />
              <li
                className={
                  lang == "uz" ? styles.activeList : styles.inactiveList
                }
                onClick={() => changeSelectedLanguage("uz")}
              >
                o'zbek
              </li> */}
              <hr style={{ margin: "8px 0" }} />
              <li
                className={
                  lang == "hi" ? styles.activeList : styles.inactiveList
                }
                onClick={() => changeSelectedLanguage("hi")}
              >
                हिंदी
              </li>
            </div>
          </ul>
        </div>
      ) : null}
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataEdit: (action, token, id) =>
      dispatch(fetchProfileDataEdit(action, token, id)),
    fetchProfileDataView: (action, token, id) =>
      dispatch(fetchProfileDataView(action, token, id)),
    changeCurrentLanguage: (lang) => dispatch(changeCurrentLanguage(lang)),
    getClassDetails: (id) => dispatch(getClassDetails(id)),
    getGroupDetails: (id) => dispatch(getGroupDetails(id)),
  };
}

export default connect(null, mapDispatchToProps)(LanguageButton);
