import React, { useEffect } from 'react'
import { useState } from 'react';
import styles from "./css/CourseButton.module.css"
import DropDownBtn from './DropdownBtn';
import { withTranslation, useTranslation } from "react-i18next";
import CoursePayment from './CoursePayment';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useSelector } from 'react-redux';

const CourseButton = (props) => {

    // props
    // share
    // teacher
    // btnText
  
    const { t } = useTranslation();
    const paymentCreated  = useSelector((state)=>state?.courses?.paymentCreated);

    const [Active,setActive] = useState("");

    const activeLabel = !props?.data?.courses?.currentCourse?.active 
                        ? t("courseMonetisation.makeItActive")
                        : t("courseMonetisation.makeItInactive")  


    useEffect(() => {
      setActive(activeLabel);
    },[props]);

    return (
          <>
              <div className={styles.dropdownBtn}>
                  
                  {props.teacher ? 
                    // this is the action dropdown for the owner of the course
                    <DropDownBtn handleShowActionModal={props.handleShowActionModal} handleRedirect={props.handleRedirect} showModal={props.showModal} list={[{value: 1, label: t("common.edit")},{value: 2, label: t("common.delete")},{value: 3, label: Active}]} />
                  :
                      // this is the button for payment and sharing
                      <CoursePayment data={props}/>
                }
              </div>
          </>
      )
  }
  

  
const mapStateToProps = (state) => {
  return {
    data:state
  };
};



export default withTranslation()(
  connect(mapStateToProps, null)(withRouter(CourseButton))
);