import React, { Component } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import MiddleContainer from "./MiddleContainer";
import styles from "./css/modal.module.css";
import BottomContainer from "./BottomContainer";
import { GroupFeedsContext } from "../../ClassesGroups/CreateClass/GroupFeedsContext";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validateFileSize } from "../../../redux/constants/commonFunctions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const lat = JSON.parse(localStorage.getItem("lat"));
const lng = JSON.parse(localStorage.getItem("lng"));

class PostModalGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.description
          : "",
      photoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.file_url
          : null,

      photo: null,
      video: null,
      mediaFile: null,
      dropDown: "Anyone",
      open: false,
      onImageEdit: false,
      onVideoEdit: false,
      onMediaFileEdit: false,
      showOccasionModal: false,
      showPollModal: false,
      pollPost: null,
      expertPost: null,
      showVisibilityModal: false,
      sellPost: null,
      showLocationModal: false,
      coordinates: {
        lat: lat,
        lng: lng,
        address: null,
      },
      eventPost: null,
    };
  }

  VisibilityModalOpener = () => {
    this.setState({ showVisibilityModal: true });
  };

  VisibilityModalCloser = () => {
    this.setState({ showVisibilityModal: false });
  };

  LocationModalOpener = () => {
    this.setState({ showLocationModal: true });
  };

  LocationModalCloser = () => {
    this.setState({ showLocationModal: false });
  };

  UpdateExpertPost = (details) => {
    this.setState({ expertPost: details });
  };

  updatePollPost = (details) => {
    this.setState({ pollPost: details });
  };

  updateSellPost = (details) => {
    this.setState({ sellPost: details });
  };

  PollModalOpener = () => {
    this.setState({ showPollModal: true });
  };

  PollModalCloser = () => {
    this.setState({ showPollModal: false });
  };

  OccasionModalOpener = () => {
    this.setState({ showOccasionModal: true });
  };

  OccasionModalCloser = () => {
    this.setState({ showOccasionModal: false });
  };

  updateEventPost = (details) => {
    this.setState({ eventPost: details });
  };

  getState = () => {
    this.setState({
      message:
        this.props?.actionType === "EDIT"
          ? this.props?.postData?.description
          : "",
      photoUrl:
        this.props?.actionType === "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoUrl:
        this.props?.actionType === "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileUrl:
        this.props?.actionType === "EDIT"
          ? this.props?.postData?.file_url
          : null,
      photo: null,
      video: null,
      mediaFile: null,
      pollPost: null,
      expertPost: null,
      coordinates: {
        lat: lat,
        lng: lng,
        address: null,
      },
      sellPost: null,
      eventPost: null,
    });
  };
  componentDidMount() {
    this.getState();
    if (
      this.props?.postData?.post_details?.purpose != null &&
      this.props?.actionType == "EDIT"
    ) {
      this.setState({
        expertPost: {
          profession: this.props?.postData?.post_details?.purpose,
          location: this.props?.postData?.post_details?.location,
          expertDesc: this.props?.postData?.post_details?.description,
        },
      });
    }

    if (
      this.props?.postData?.post_details?.poll_options != null &&
      this.props?.actionType == "EDIT"
    ) {
      const optionsArray = Object.keys(
        this.props?.postData?.post_details?.poll_options
      ).map((key, idx) => ({
        name: `option${idx + 1}`,
        desc: key,
        display: `Option ${idx + 1}`,
        placeholder: `PollModal.${idx + 1}Placeholder`,
      }));
      this.setState({
        pollPost: {
          question: this.props?.postData?.post_details?.poll_question,
          options: optionsArray,
          pollDuration: "1 week",
        },
      });
    }

    if (
      this.props?.postData?.post_details?.category != null &&
      this.props?.actionType == "EDIT"
    ) {
      const imagesUrlArray = this.props?.postData?.sell_images.map(
        (img, idx) => ({ id: img?.id, src: img?.image })
      );
      this.setState({
        sellPost: {
          photos: [],
          photoUrls: imagesUrlArray,
          title: this.props?.postData?.post_details?.title,
          desc: this.props?.postData?.post_details?.sell_description,
          category: this.props?.postData?.post_details?.category,
          pickLocation: this.props?.postData?.post_details?.location,
          price: this.props?.postData?.post_details?.pricing,
          isFree: this.props?.postData?.post_details?.free,
          discountedPrice: this.props?.postData?.post_details?.discounted_price,
          discountOff: this.props?.postData?.post_details?.discount_offer,
        },
      });
    }

    if (
      this.props?.postData?.post_details?.name != null &&
      this.props?.actionType == "EDIT"
    ) {
      this.setState({
        eventPost: {
          event_type: this.props?.postData?.post_details?.event_type,
          name: this.props?.postData?.post_details?.name,
          address: this.props?.postData?.post_details?.address,
          free: this.props?.postData?.post_details?.free,
          event_link: this.props?.postData?.post_details?.event_link,
          event_description:
            this.props?.postData?.post_details?.event_description,
          start_date_time: this.props?.postData?.post_details?.start_date_time,
          end_date_time: this.props?.postData?.post_details?.end_date_time,
          timezone: this.props?.postData?.post_details?.timezone,
          price: this.props?.postData?.post_details?.pricing,
          discountedPrice: this.props?.postData?.post_details?.discounted_price,
        },
      });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  CloseAllModals = () => {
    this.props.closeModal();
    this.VisibilityModalCloser();
    this.PollModalCloser();
    this.OccasionModalCloser();
    this.LocationModalCloser();
    this.props.EventModalCloser();
    this.props.ExpertModalCloser();
    this.props.setShowSellModal(false);
    this.setState({
      message: "",
      photoUrl: null,
      videoUrl: null,
      mediaFileUrl: null,
      photo: null,
      video: null,
      mediaFile: null,
      pollPost: null,
      expertPost: null,
      coordinates: {
        lat: lat,
        lng: lng,
        address: null,
      },
      sellPost: null,
      eventPost: null,
    });
  };

  onPost = () => {
    let post = {};
    post.message = this.state.message;
    post.photoUrl = this.state.photo;
    post.videoUrl = this.state.video;
    post.mediaFileUrl = this.state.mediaFile;
    post.pollPost = this.state.pollPost;
    post.expertPost = this.state.expertPost;
    post.locationPost = this.state.coordinates;
    post.language = this.props.currentLanguage;
    post.sellPost = this.state.sellPost;
    post.eventPost = this.state.eventPost;
    post.type =
      this.state.eventPost !== null
        ? "EVENT"
        : this.state.sellPost !== null
        ? "SELL"
        : this.state.pollPost !== null
        ? "POLL"
        : this.state.expertPost !== null
        ? "EXPERT"
        : this.state.coordinates.address !== null &&
          this.state.photo === null &&
          this.state.video === null &&
          this.state.mediaFile === null
        ? "LOCATION"
        : "NORMAL";
    // post.type = "DASHBOARD";
    post.visibility = this.props.visiblity_type;
    if (
      this.state.message.trim() != "" ||
      this.state.photo != null ||
      this.state.video != null ||
      this.state.mediaFile != null ||
      this.state.pollPost != null ||
      this.state.expertPost != null ||
      this.state.coordinates.address != null ||
      this.state.sellPost != null
    ) {
      this.context.addPost(post);
    } else {
      this.setState({
        open: true,
        alertMessage: "Please Add Something before posting",
      });
    }
    this.setState({
      message: "",
      photoUrl: null,
      videoUrl: null,
      photo: null,
      video: null,
      mediaFileUrl: null,
      mediaFile: null,
      dropDown: "Anyone",
      pollPost: null,
      expertPost: null,
      coordinates: {
        lat: lat,
        lng: lng,
        address: null,
      },
      sellPost: null,
      eventPost: null,
    });
    this.CloseAllModals();
  };

  postDelete = () => {
    let id = this.props.postData.id;
    this.context.deletePost(id);
    this.CloseAllModals();
  };

  postEdit = () => {
    if (
      this.state.photo === null ||
      this.state.video === null ||
      this.state.mediaFile === null
    ) {
      this.setState({
        photo: this.props?.postData?.image_url,
        video: this.props?.postData?.video_url,
        mediaFile: this.props?.postData?.file_url,
      });
    }
    if (this.state.message === "") {
      this.setState({ message: this.props?.postData?.description });
    }

    let post = {};
    post.message = this.state.message;
    post.photoUrl = this.state.photo;
    post.videoUrl = this.state.video;
    post.mediaFileUrl = this.state.mediaFile;
    post.id = this.props?.postData?.id;
    post.onImageEdit = this.state.onImageEdit;
    post.onVideoEdit = this.state.onVideoEdit;
    post.onMediaFileEdit = this.state.onMediaFileEdit;
    post.expertPost = this.state.expertPost;
    post.pollPost = this.state.pollPost;
    post.sellPost = this.state.sellPost;
    post.locationPost = this.state.coordinates;
    post.type = "DASHBOARD";
    // post.visibility = this.state.dropDown;
    post.visibility = this.props.visibility_type;
    if (
      this.props?.postData?.description.trim() !== this.state.message.trim() ||
      this.state.photo !== this.props?.postData?.image_url ||
      this.state.video !== this.props?.postData?.video_url ||
      this.state.mediaFile !== this.props?.postData?.file_url ||
      this.state.expertPost !== null ||
      this.state.pollPost !== null ||
      this.state.sellPost !== null ||
      this.state.coordinates.address !== null
    ) {
      if (
        this.state.message !== "" ||
        this.state.photo !== null ||
        this.state.video !== null ||
        this.state.mediaFile !== null ||
        this.state.expertPost !== null ||
        this.state.pollPost !== null ||
        this.state.sellPost !== null ||
        this.state.coordinates.address !== null
      ) {
        this.context.editPost(post);
      }
    } else {
    }
    // console.log("changeddd", this.props?.postData?.description, this.state.message)
    this.setState({
      onImageEdit: false,
      onVideoEdit: false,
      onMediaFileEdit: false,
      expertPost: null,
      pollPost: null,
      sellPost: null,
    });

    this.CloseAllModals();
  };

  onChange = (e) => {
    this.setState({ message: e.target.value });
  };

  onChangeMessage = (msg) => {
    this.setState({ message: msg });
  };

  onDropodownChange = (item) => {
    this.setState({ dropDown: item });
  };
  onPicInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 5120);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            photoUrl: URL.createObjectURL(e.target.files[0]),
            photo: e.target.files[0],
            videoUrl: null,
            video: null,
            // mediaFileUrl: null,
            // mediaFile: null,
            onImageEdit: true,
          }
    );
  };

  videoInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 5120);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            videoUrl: URL.createObjectURL(e.target.files[0]),
            video: e.target.files[0],
            photoUrl: null,
            photo: null,
            mediaFileUrl: null,
            mediaFile: null,
            onVideoEdit: true,
          }
    );
  };
  mediaFileInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 5120);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            mediaFileUrl: URL.createObjectURL(e.target.files[0]),
            mediaFile: e.target.files[0],
            // photoUrl: null,
            // photo: null,
            videoUrl: null,
            video: null,
            onMediaFileEdit: true,
          }
    );
  };

  handleRemoveImage = () => {
    this.setState({ photoUrl: null, photo: null });
  };

  handleRemoveVideo = () => {
    this.setState({ videoUrl: null, video: null });
  };

  handleRemovePdf = () => {
    this.setState({ mediaFileUrl: null, mediaFile: null });
  };

  handleAddress = (add) => {
    this.setState((prevState) => ({
      coordinates: {
        ...prevState.coordinates,
        address: add,
      },
    }));
  };

  handleCoordinatesChange = (coor) => {
    this.setState((prevState) => ({
      coordinates: {
        ...prevState.coordinates,
        lat: coor.lat,
        lng: coor.lng,
      },
    }));
  };

  removeLocationPost = () => {
    this.setState({
      coordinates: {
        lat: lat,
        lng: lng,
        address: null,
      },
    });
  };

  updateCoordinates = (details) => {
    this.setState({ coordinates: details });
  };

  render() {
    return (
      <>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
        <div>
          <ModalWithHeader
            title={
              this.props.actionType == "EDIT"
                ? this.props.t("dashboard.editPost")
                : this.props.actionType == "DELETE"
                ? this.props.t("dashboard.deletePost")
                : this.state.showVisibilityModal
                ? this.props.t("visibilityModal.title")
                : this.props.showEventModal
                ? this.props.t("eventModal.title")
                : this.state.showOccasionModal
                ? this.props.t("occasionModal.title")
                : this.state.showPollModal
                ? this.props.t("PollModal.title")
                : this.props.showExpertModal
                ? this.props.t("expertModal.title")
                : this.props.showSellModal
                ? this.props.t("sellModal.title")
                : ""
            }
            show={this.props.showModal}
            closeHandler={this.CloseAllModals}
            maxWidth={750}
            // margin={"5% 0"}
          >
            <div className={styles.modalBody}>
              <MiddleContainer
                actionType={this.props.actionType}
                postData={this.props.postData}
                pic={this.state.photoUrl}
                video={this.state.videoUrl}
                file={this.state.mediaFileUrl}
                state={this.state}
                onChange={this.onChange}
                onDropdownChange={this.onDropodownChange}
                showOccasionModal={this.state.showOccasionModal}
                OccasionModalCloser={this.OccasionModalCloser}
                onChangeMessage={this.onChangeMessage}
                fileInput={this.onPicInput}
                showEventModal={this.props.showEventModal}
                EventModalCloser={this.props.EventModalCloser}
                showPollModal={this.state.showPollModal}
                PollModalOpener={this.PollModalOpener}
                PollModalCloser={this.PollModalCloser}
                pollPost={this.state.pollPost}
                updatePollPost={this.updatePollPost}
                showExpertModal={this.props.showExpertModal}
                ExpertModalCloser={this.props.ExpertModalCloser}
                expertPost={this.state.expertPost}
                UpdateExpertPost={this.UpdateExpertPost}
                ExpertModalOpener={this.props.ExpertModalOpener}
                handleRemoveImage={this.handleRemoveImage}
                handleRemoveVideo={this.handleRemoveVideo}
                showVisibilityModal={this.state.showVisibilityModal}
                VisibilityModalOpener={this.VisibilityModalOpener}
                VisibilityModalCloser={this.VisibilityModalCloser}
                showSellModal={this.props.showSellModal}
                setShowSellModal={this.props.setShowSellModal}
                updateSellPost={this.updateSellPost}
                sellPost={this.state.sellPost}
                showLocationModal={this.state.showLocationModal}
                LocationModalCloser={this.LocationModalCloser}
                coordinates={this.state.coordinates}
                handleAddress={this.handleAddress}
                handleCoordinatesChange={this.handleCoordinatesChange}
                removeLocationPost={this.removeLocationPost}
                updateCoordinates={this.updateCoordinates}
                handleRemovePdf={this.handleRemovePdf}
                postId={this?.props?.postData?.id}
                updateEventPost={this.updateEventPost}
                LocationModalOpener={this.LocationModalOpener}
                eventPost={this.state.eventPost}
                EventModalOpener={this.props.EventModalOpener}
                source="GROUP"
              />
              {!this.state.showVisibilityModal &&
                !this.props.showEventModal &&
                !this.state.showOccasionModal &&
                !this.state.showPollModal &&
                !this.props.showExpertModal &&
                !this.props.showSellModal && (
                  <BottomContainer
                    actionType={this.props.actionType}
                    videoInput={this.videoInput}
                    fileInput={this.onPicInput}
                    onPost={this.onPost}
                    postEdit={this.postEdit}
                    postDelete={this.postDelete}
                    mediaFileInput={this.mediaFileInput}
                    pic={this.state.photoUrl}
                    video={this.state.videoUrl}
                    file={this.state.mediaFileUrl}
                    OccasionModalOpener={this.OccasionModalOpener}
                    EventModalOpener={this.props.EventModalOpener}
                    PollModalOpener={this.PollModalOpener}
                    pollPost={this.state.pollPost}
                    ExpertModalOpener={this.props.ExpertModalOpener}
                    inputRef={this.props.inputRef}
                    setShowSellModal={this.props.setShowSellModal}
                    expertPost={this.state.expertPost}
                    sellPost={this.state.sellPost}
                    coordinates={this.state.coordinates}
                  />
                )}
            </div>
            {/*  <img height="100px" src={this.state.image} /> */}
          </ModalWithHeader>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    currentLanguage:
      state?.dashboard?.currentLanguage ||
      localStorage.getItem("i18nextLng") ||
      navigator.language ||
      navigator.userLanguage ||
      "en",
  };
};

PostModalGroup.contextType = GroupFeedsContext;

export default withTranslation()(connect(mapStateToProps)(PostModalGroup));
