import { gql } from "graphql-request";
// Add all courseMonetisation flow relatd queries here

// get all courseFeedPost
export const GET_ALL_COURSES_QUERY = gql`
  query ($input: AllCourseInput!) {
    allcourses(input: $input) {
      edges {
        id
        thumbnail
        name
        description
        subtitle
        public
        owner {
          name
          id
        }
        files {
          id
          course_id
          media
        }
        urls {
          url
          id
        }
        active
        free
        price
        members_count
        classes {
          id
          name
        }
        is_enrolled
        course_mins
        total_lecs
        progress_bar
        is_draft
      }
      total_count
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export const GET_COURSE_QUERY = gql`
  query ($id: ID!) {
    getCoursesById(id: $id) {
      id
      thumbnail
      name
      description
      subtitle
      public
      owner {
        name
        id
        profile_link
      }
      user_profile {
        id
        name
        profile_pic
      }
      files {
        id
        course_id
        media
      }
      urls {
        url
        id
      }
      active
      free
      price
      currency
      members_count
      classes {
        id
        name
      }
      is_enrolled
      section {
        id
        title
        lectures {
          id
          title
          description
          fileLink
          url
          fileType
          article
          duration
          completed
          isPublished
        }
        total_lecs
        sec_mins
        quiz {
          id
          title
          details
          max_marks
          template {
            ques_count
          }
        }
      }
      course_mins
      total_lecs
      progress_bar
      is_draft
    }
  }
`;


export const GET_COURSE_PROGRESS_QUERY = gql`
  query ($courseID: ID!) {
    getCoursesById(id: $courseID) {
      progress_bar
    }
  }
`;
