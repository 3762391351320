import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styles from "../../../Profile.module.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { FeedsContext } from "../../../../Dashboard/Components/FeedsContext";
import Post from "../../../../Dashboard/Components/Post";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class PostsFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    this.context.getPostsByUserId(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getPostByProfileId/${id}/`,
      true
    );
  }

  render() {
    const { t } = this.props;
    console.log(this?.context?.state?.postForProfile);
    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
          <img
            src="/Image/Profile/posts.svg"
            alt="about"
            style={{ width: "17px" }}
          />
          <Typography
            sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
          >
            {t("profile.posts")}
          </Typography>
        </Box>

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "20px 10px",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxHeight: "720px",
            overflowY: "scroll",
          }}
        >
          {this?.context?.state?.postForProfile
            .slice(0, 5)
            .map((post, index) => (
              <div key={index} style={{ width: "80%" }}>
                <Post
                  post_owner_id={post?.post_owner_profile?.id}
                  name={post?.post_owner_profile?.name}
                  follower={post?.post_owner_profile?.connections}
                  timestamp={post?.timestamp}
                  message={
                    post?.repost_of
                      ? post?.original_post_details?.description
                      : post?.description
                  }
                  // tag = {post.tag}
                  profileImage={
                    post?.post_owner_profile?.profile_pic
                      ? post?.post_owner_profile?.profile_pic
                      : post?.post_owner_profile?.avatarImg
                      ? post?.post_owner_profile?.avatarImg
                      : "/Image/header/ddefault_img.svg"
                  }
                  photoUrl={
                    post?.repost_of
                      ? post?.original_post_details?.image_url
                      : post?.image_url
                  }
                  video={
                    post?.repost_of
                      ? post?.original_post_details?.video_url
                      : post?.video_url
                  }
                  likes={post.likes}
                  likeDetails={post.reactions}
                  shareLink={post.share_link}
                  file_url={
                    post?.repost_of
                      ? post?.original_post_details?.file_url
                      : post.file_url
                  }
                  tags={post.tags}
                  postId={post.id}
                  comments={post.comments}
                  shareModalOpener={() =>
                    this.props.shareModalOpener(post.share_link)
                  }
                  menu={
                    post?.post_owner_profile?.id == this.props?.userId
                      ? true
                      : false
                  }
                  postData={post}
                  source="DASHBOARD"
                  // language_code={post?.language_code}
                  postLanguage={post?.language_code}
                  handleOpenSelectedComment={this.handleOpenSelectedComment}
                  openSelectedComment={this.state.openSelectedComment}
                  post_details={
                    post?.repost_of
                      ? post?.original_post_details?.post_details
                      : post?.post_details
                  }
                  postType={post?.post_type}
                  sellImages={post?.sell_images}
                  pollOptions={post?.poll_options}
                  pollVotes={post?.poll_votes}
                  original_post_owner={
                    post?.original_post_details?.post_details
                      ?.post_owner_profile
                  }
                  user_type={post?.post_owner_profile?.user_details?.user_type}
                />
              </div>
            ))}
          {this?.context?.state?.loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
            </div>
          ) : null}
          {this?.context?.state?.postForProfile?.length === 0 &&
          !this?.context?.state?.firstLoad ? (
            <div style={{ textAlign: "center" }}>
              <h4>{t("profile.noInformationAvailable")}</h4>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || [],
    about: state?.profile?.aboutView,
    userId: state?.profile?.profileId,
  };
}

PostsFeed.contextType = FeedsContext;

export default withTranslation()(
  withRouter(connect(mapStateToProps, null)(PostsFeed))
);
