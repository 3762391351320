import React from "react";
import styles from "./UpcomingAssignmentList.module.css";
import { formatDate } from '../../../redux/constants/commonFunctions'
import { useTranslation } from "react-i18next";
const UpcomingAssignmentList = (props) => {
const { t } = useTranslation() 
  return (
    <div className={styles.list_container}>
      <div className={styles.title_and_date}>
          <p className={styles.assignment_title}>{props.assignmentTitle}</p>
          <p className={styles.assignment_due_date}>
            {t("assignment.due")} {formatDate(props?.assignmentDueDate)} 
          </p>
      </div>
      <hr className={styles.ClassHr} />
    </div>
  );
};

export default UpcomingAssignmentList;
