import React from "react";
import { useSelector } from "react-redux";
import styles from "./commoncss/subjectCard.module.css";
import PublicIcon from "@mui/icons-material/Public";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function SubjectCard({
  eachSubject,
  getSubjectDetails,
  memberCheck,
  editClassModalOpener,
  editClassId,
  deleteClassModalOpener,
  setGroupname,
  validMember,
  toggleModal,
  index
}) {
  const { name, grade, image_url, subject, id, code, description } =
    eachSubject;
  const history = useHistory();
  const class_owner = useSelector((state) => state.profile.profileId);
  const image_urll = "/Image/Group/default_grp.png";
  const handleClassEdit = () => {
    editClassModalOpener();
    editClassId(eachSubject);
  };

  const handleClassDelete = () => {
    deleteClassModalOpener();
    /* editClassId(name, grade, image_url, subject, id, code) */
    editClassId(eachSubject);
  };
  const handleClick = (id) => {
    setGroupname(eachSubject);
    const onSuccess = (response) => {
      if (response) {
        history.push({
          pathname: `/group/${id}`,
          state: { subjectId: id },
        });
      }
    };
    const onError = (error) => {
      if (error.response.status === 403) {
        toggleModal();
      }
    };
    validMember(id, onSuccess, onError);
  };

  return (
    <div
      className={styles.groupCardContainer}
      style={{ position: "relative" }}
      onClick={() => setGroupname(eachSubject)}
      key={index}
    >
      <div className={styles.groupCardImageContainer}>
        <img src={image_urll} className={styles.groupCardImage} />
      </div>
      <div className={styles.groupCardTextContainer}>
        <p className={styles.groupCardName}>{name}</p>
        <p className={styles.groupCardCode}>Group Code - {code}</p>
        <p className={styles.groupCardVisiblity}>
          <PublicIcon sx={{ fontSize: 12, color: "gray" }} />
          everyone
        </p>
        <div
          className={styles.groupCardDescContainer}
          style={{ marginBottom: "10px" }}
        >
          <p className={styles.groupCardDescriptionTitle}>Description</p>
          <p className={styles.groupCardDescription}>
            {description.length > 50
              ? description.slice(0, 50) + "..."
              : description}
          </p>
        </div>
      </div>
      <div className={styles.groupCardButtonContainer}>
        <button
          className={styles.groupCardButton}
          onClick={() => {
            handleClick(id);
          }}
        >
          {class_owner == eachSubject.class_owner ? (
            "Go to Group"
          ) : (
            <>
              <AddIcon
                sx={{
                  fontSize: 14,
                  background: "white",
                  borderRadius: "4px",
                  color: "#068e46",
                }}
              />
              Join Group
            </>
          )}
        </button>
      </div>
      {class_owner == eachSubject.class_owner ? (
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "0 50px 20px 50px",
            borderRadius: "0px 0px 10px 10px",
            position: "absolute",
            top: "15px",
            right: "-40px",
          }}
        >
          <img
            onClick={handleClassEdit}
            src="/Image/class/edit.svg"
            style={{
              height: "25px",
              width: "auto",
              margin: "0 10px",
              cursor: "pointer",
            }}
          />
          <img
            onClick={handleClassDelete}
            src="/Image/class/delete.svg"
            style={{
              height: "25px",
              width: "auto",
              margin: "0 10px",
              cursor: "pointer",
            }}
          />
        </div>
      ) : null}
    </div>
    // <div className={styles.container}>
    //   <div onClick={() => memberCheck(id)}>
    //     <div className={styles.imageContainer}>
    //       <img src={image_url} className={styles.subjectImage} />
    //     </div>
    //     <div className={styles.textContainer}>
    //       <div className={styles.nameText}>{name}</div>
    //       <div className={styles.gradeText}>{description}</div>
    //       <div className={styles.gradeText}>{subject}</div>
    //       <div className={styles.subjectText}>{code}</div>
    //     </div>
    //   </div>
    //   {
    //     class_owner == eachSubject.class_owner
    //       ? <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "white", padding: "0 50px 20px 50px", borderRadius: "0px 0px 10px 10px" }}>
    //         <img onClick={handleClassEdit} src="/Image/class/edit.svg" style={{ height: "25px", width: "auto", margin: "0 10px", cursor: "pointer" }} />
    //         <img onClick={handleClassDelete} src="/Image/class/delete.svg" style={{ height: "25px", width: "auto", margin: "0 10px", cursor: "pointer" }} />
    //       </div>
    //       : null
    //   }
    // </div>
  );
}

export default SubjectCard;
