import React, { Component } from "react";
// import Eye from './Image/DashboardImages/postModImages/eye.svg'
import styles from "./css/DropBox.module.css";
import ReactDOM from "react-dom";
// import DropArrow from './Image/DashboardImages/postModImages/dropArrow.svg'
// import Message from './Image/DashboardImages/postModImages/message.svg'

export default class DropBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu1: false,
      endIdx: 1,
      options: this.props.options,
      selectedOption: this.props.title,
    };
  }

  changeActiveElement = (item) => {
    this.setState({ selectedOption: item });
  };

  drop = () => {
    if (this.state.showMenu1) {
      return (
        <div className={styles.dropdown_menu1}>
          <ul>
            <div className={styles.menu1}>
              {this.state.options
                .slice(0, this.state.endIdx)
                .map((item, idx) => {
                  if (item == this.state.selectedOption) {
                    return (
                      <>
                        <li
                          key={idx}
                          style={{
                            marginTop: 6,
                            marginBottom: 6,
                            color: "#00D498",
                            cursor: "pointer",
                          }}
                        >
                          {item}
                        </li>
                        <hr className={styles.Hr3} />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <li
                          key={idx}
                          style={{
                            marginTop: 6,
                            marginBottom: 6,
                            color: "#555555",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.props.changeActiveElement(item);
                            this.changeActiveElement(item);
                          }}
                        >
                          {item}
                        </li>
                        <hr className={styles.Hr3} />
                      </>
                    );
                  }
                })}
            </div>
          </ul>
        </div>
      );
    } else return <></>;
  };

  toogleMenu1 = () => {
    this.setState({
      showMenu1: !this.state.showMenu1,
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showMenu1: false,
      });
    }
  };

  render() {
    return (
      <div className={styles.navbar_menu1}>
        <div
          style={this.props.styles}
          className={styles.container}
          onClick={this.toogleMenu1}
        >
          {this.props.eye && (
            <img
              className={styles.eye}
              src="/Image/DashboardImages/postModImages/eye.svg"
              alt="visibility"
            />
          )}
          {this.props.message && (
            <img
              className={styles.eye}
              src="/Image/DashboardImages/postModImages/message.svg"
              alt="message"
            />
          )}
          <span className={this.props.light ? styles.light : styles.normal}>
            {this.props.title}
          </span>
          {this.props.drop && (
            <img
              src="/Image/DashboardImages/postModImages/dropArrow.svg"
              className={styles.dropArrow}
              alt="dropArrow"
            />
          )}
        </div>
        {this.props.drop && <this.drop />}
      </div>
    );
  }
}
