import React, { useState, useEffect } from "react";
import styles from "./ZoomConfigure.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  putUserSettings,
  getUserSettings,
} from "../../../redux/actions/zoomAction";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { withTranslation } from "react-i18next";
import { handleSuccessMessage } from "../../../redux/actions/success";

class zoomConfigure extends React.Component {
  constructor(props) {
    super();

    this.state = {
      meetingLink: "",
      meetingLinkValidation: 0,
    };
  }
  putZoomSettings = () => {
    var expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    let { history } = this.props;
    let payload = {
      zoom_settings: { default_meeting_link: this.state.meetingLink },
    };
    if (this.state.meetingLink.match(regex)) {
      this.props.putUserSettings(payload).then(() => {
        history.push("/video");
        this.props.handleSuccessMessage("Configured it successfully");
      });
    } else {
      this.props.handleErrorMessage("Enter valid link");
    }
  };
  getZoomSettings = () => {
    this.props.getUserSettings().then(() => { });
  };
  componentDidMount() {
    // this.getZoomSettings()
  }

  render() {
    return (
      <div className={styles.configureContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.heading}>
            {this.props.t("subscription.configureZoom")}
          </div>
          <div className={styles.subHeading}>
            {this.props.t("subscription.configureZoomDescription")}
          </div>
          <div className={styles.field}>
            <input
              className={styles.configureField}
              placeholder={this.props.t("subscription.meetingLink")}
              onChange={(e) =>
                this.setState({
                  meetingLink: e.target.value,
                  meetingLinkValidation: 0,
                })
              }
            />
            {this.state.meetingLinkValidation === 1 ? (
              this.state.meetingLinkValidation === 2 ? (
                <div className={styles.inputAlert}>
                  {this.props.t("subscription.enterConfigurationLink")}
                </div>
              ) : null
            ) : null}
          </div>
          <button
            className={styles.zoomBtn}
            onClick={() => this.putZoomSettings()}
          >
            {this.props.t("subscription.configureZoomButton")}
          </button>
        </div>
        <div className={styles.rightImage}>
          <img src="Image/zoom_configure.jpeg" style={{ width: '100%', height: '100%', marginTop: '100px' }} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    meetingLink: state?.zoom?.zoom_settings?.zoomSettings?.default_meeting_link,
    zoomId: state?.zoom?.zoom_settings?.zoomId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putUserSettings: (accountType) => dispatch(putUserSettings(accountType)),
    getUserSettings: () => dispatch(getUserSettings()),
    handleErrorMessage: (message) => dispatch(handleErrorMessage(message)),
    handleSuccessMessage: (message) => dispatch(handleSuccessMessage(message)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(zoomConfigure))
);
