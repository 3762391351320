import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/shareModal.module.css";
// import Copy from './Image/DashboardImages/sharemodImages/copy.svg';
class ShareModal extends Component {
  render() {
    return (
      <div>
        <ModalWithHeader
          title={this.props.t("common.share")}
          show={this.props.show}
          closeHandler={this.props.closeModal}
          maxWidth="569px"
          minWidth="350px"
        >
          <div className={styles.container}>
            <div
              style={{ fontSize: "16px", fontWeight: "700", color: "#3f3f3f" }}
            >
              {this.props.t("modals.shareVia")}
            </div>
            <div className={styles.socialContainer}>
              <WhatsappShareButton
                url={this.props.shareLink}
                quote={this.props.t("modals.shareText")}
              >
                <img
                  src="/Image/DashImage/whatsapp.png"
                  height={45}
                  width={45}
                ></img>
              </WhatsappShareButton>
              <FacebookShareButton
                url={this.props.shareLink}
                quote={this.props.t("modals.shareText")}
              >
                <img
                  src="/Image/DashImage/facebook.png"
                  height={45}
                  width={45}
                ></img>
              </FacebookShareButton>
              <TwitterShareButton
                url={this.props.shareLink}
                quote={this.props.t("modals.shareText")}
              >
                <img
                  src="/Image/DashImage/twitter.png"
                  height={45}
                  width={45}
                ></img>
              </TwitterShareButton>
              {/* <LinkedinShareButton
                url={this.props.shareLink}
                quote={this.props.t("modals.shareText")}
              >
                <img
                  src="/Image/DashImage/linkedin.png"
                  height={45}
                  width={45}
                ></img>
              </LinkedinShareButton> */}
              <PinterestShareButton
                media={this.props.shareLink}
                url={this.props.shareLink}
                quote={this.props.t("modals.shareText")}
              >
                <img
                  src="/Image/DashImage/pinterest.png"
                  height={45}
                  width={45}
                ></img>
              </PinterestShareButton>
            </div>
            <div
              style={{ fontSize: "16px", fontWeight: "700", color: "#3f3f3f" }}
            >
              {this.props.t("modals.copyLink")}
            </div>
            <div className={styles.copyContainer}>
              <p
                className={styles.link}
                style={{
                  width: "300px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {this.props.shareLink}
              </p>
              <img
                onClick={() => {
                  navigator.clipboard.writeText(this.props.shareLink);
                  // navigator.clipboard.writeText(`https://chalkmates.com/post/${this.props.link}`);
                }}
                className={styles.copyButton}
                height="25px"
                width="25px"
                src="/Image/DashboardImages/sharemodImages/copy.svg"
              />
            </div>
          </div>
        </ModalWithHeader>
      </div>
    );
  }
}

export default withTranslation()(ShareModal);
