import { combineReducers } from "redux";
import posts from "./reducers/posts";
import success from "./reducers/success";
import users from "./reducers/users";
import classes from "./reducers/classes";
import profile from "./reducers/profile";
import aboutUser from "./reducers/aboutUser";
import errors from "./reducers/errors";
import groups from "./reducers/groups";
import zoom from "./reducers/zoom";
import dashboard from "./reducers/dashboard";
import assignment from "./reducers/assignment";
import quiz from "./reducers/quiz";
import courses from "./reducers/courses";

export default combineReducers({
  posts,
  success,
  users,
  classes,
  profile,
  aboutUser,
  groups,
  zoom,
  dashboard,
  errors,
  assignment,
  quiz,
  courses,
});
