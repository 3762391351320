import React from "react";
import "./CSS/EventSidebar.css";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EventSidebar = ({ params, setParams }) => {
  const history = useHistory();
  const [nearby, setNearby] = useState("Just My neighborhood");

  return (
    <div className="event_page_sidebar_parent">
      <p
        className="event_page_sidebar_gohome"
        style={{ display: "flex", gap: "5px", alignItems: "center" }}
        onClick={() => {
          history.push({ pathname: "/user" });
        }}
      >
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 1024 1024"
          fill="#000000"
          class="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z"
            fill=""
          />
        </svg>
        <span>Home</span>
      </p>
      <div>
        <p
          className="event_page_sidebar_list_title"
          style={{ marginTop: "10px" }}
        >
          Filters
        </p>
        <ul className="event_page_sidebar_ul">
          <li
            onClick={() =>
              setParams((prev) => {
                const { freeData, ...rest } = prev;
                return rest;
              })
            }
            className="event_page_sidebar_li"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            {params?.freeData === undefined && <DoneIcon fontSize="small" />}
            <span
              style={{ fontWeight: params?.freeData === undefined ? 600 : 400 }}
            >
              All Events
            </span>
          </li>
          <li
            onClick={() => setParams((prev) => ({ ...prev, freeData: true }))}
            style={{
              fontWeight: params?.freeData === true ? 600 : 400,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            className="event_page_sidebar_li"
          >
            {params?.freeData === true && <DoneIcon fontSize="small" />}
            Free
          </li>
          <li
            onClick={() => setParams((prev) => ({ ...prev, freeData: false }))}
            style={{
              fontWeight: params?.freeData === false ? 600 : 400,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            className="event_page_sidebar_li"
          >
            {params?.freeData === false && <DoneIcon fontSize="small" />}
            Paid
          </li>
        </ul>
      </div>
      <div>
        <p className="event_page_sidebar_list_title">Sort By</p>
        <ul className="event_page_sidebar_ul">
          <li
            onClick={() =>
              setParams((prev) => {
                const { newest, ...rest } = prev;
                return rest;
              })
            }
            className="event_page_sidebar_li"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            {!params?.newest && <DoneIcon fontSize="small" />}
            <span style={{ fontWeight: !params?.newest ? 600 : 400 }}>
              Most Relevant
            </span>
          </li>
          <li
            onClick={() => setParams((prev) => ({ ...prev, newest: true }))}
            style={{
              fontWeight: params?.newest ? 600 : 400,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            className="event_page_sidebar_li"
          >
            {params?.newest && <DoneIcon fontSize="small" />}
            Newest
          </li>
        </ul>
      </div>
      <div>
        <p className="event_page_sidebar_list_title">Show items from</p>
        <ul className="event_page_sidebar_ul">
          <li
            onClick={() => setNearby("Just My neighborhood")}
            className="event_page_sidebar_li"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            {nearby === "Just My neighborhood" && <DoneIcon fontSize="small" />}
            <span
              style={{
                fontWeight: nearby === "Just My neighborhood" ? 600 : 400,
              }}
            >
              Just My neighborhood
            </span>
          </li>
          <li
            onClick={() => setNearby("Nearby neighborhoods")}
            className="event_page_sidebar_li"
            style={{
              fontWeight: nearby === "Nearby neighborhoods" ? 600 : 400,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            {nearby === "Nearby neighborhoods" && <DoneIcon fontSize="small" />}
            Nearby neighborhoods
          </li>
        </ul>
      </div>
      <div>
        <p className="event_page_sidebar_list_title">Categories</p>
        <ul className="event_page_sidebar_ul">
          <li
            onClick={() =>
              setParams((prev) => {
                const { event_type, ...rest } = prev;
                return rest;
              })
            }
            className="event_page_sidebar_li"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            {!params?.event_type && <DoneIcon fontSize="small" />}
            <span style={{ fontWeight: !params?.event_type ? 600 : 400 }}>
              All Categories
            </span>
          </li>
          <li
            onClick={() =>
              setParams((prev) => ({ ...prev, event_type: "INPERSON" }))
            }
            style={{
              fontWeight: params?.event_type === "INPERSON" ? 600 : 400,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            className="event_page_sidebar_li"
          >
            {params?.event_type === "INPERSON" && <DoneIcon fontSize="small" />}
            In-Person
          </li>
          <li
            onClick={() =>
              setParams((prev) => ({ ...prev, event_type: "ONLINE" }))
            }
            style={{
              fontWeight: params?.event_type === "ONLINE" ? 600 : 400,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            className="event_page_sidebar_li"
          >
            {params?.event_type === "ONLINE" && <DoneIcon fontSize="small" />}
            Online
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EventSidebar;
