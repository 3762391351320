import React, { useState } from "react";
import styles from "./UpdateLectureComponent.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import ButtonMUI from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress } from "@mui/material";
import AddArticleVideoComponent from "./AddArticleVideoComponent";
import ContentTypeButtonMenu from "./ContentTypeButtonMenu";
import {
  createLecture,
  createSection,
  updateLecture,
  updateSection,
} from "../../../redux/actions/courses";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../../../redux/actions/groups";

const UpdateLectureComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLecture = props.lecture;

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [title, setTitle] = useState(props.lecture?.title ?? "");

  const handleUpdateLecture = () => {
    setIsLoadingBtn(true);
    const lectureID = currentLecture.id;
    const sectionID = currentLecture.sectionID;
    const data = {};

    data.title = title;
    data.isPublished = currentLecture?.isPublished;
    // data.description = currentLecture?.description;
    // data.fileLink = currentLecture?.fileLink;
    // data.url = currentLecture?.url;
    // data.fileType = currentLecture?.fileType;
    // data.duration = currentLecture?.duration;

    if (data.title.trim() == "") {
      dispatch(handleErrorMessage(t("courseMonetisation.enterLectureTitle")));
      setIsLoadingBtn(false);
    } else {
      dispatch(updateLecture(lectureID, sectionID, data)).then(() => {
        setIsLoadingBtn(false);
        props.onCancel();
      });
    }
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#00D498",
    borderColor: "#00D498",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#00D498",
      color: "#00D498",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    '@media (max-width: 500px)': {
      height: "23px",
      padding: "6.756px 6.647px 8px 10px",
    },
  };

  return (
    <div className={styles.main}>
      <span className={styles.heading}>
        {t("courseMonetisation.updateLecture")}:
      </span>
      <div className={styles.input_container}>
        <span>{t("courseMonetisation.lectureTitle")}:</span>
        <input
          type="text"
          placeholder={t("common.enterTitle")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className={styles.content_container}>
        <span>{t("common.content")}:</span>
        <div className={styles.content}>
          <span>
            {currentLecture?.url ?? currentLecture?.fileLink?.split("/")?.pop()}
          </span>
          <img src="/Image/lecturePlan/cross.svg" onClick={props.onCrossingContentLink} />
        </div>
      </div>

      <div className={styles.btn_div}>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.onCancel}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{t("common.cancel")}</span>
        </ButtonMUI>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={handleUpdateLecture}
          sx={styleButtonMUI}
        >
          {isLoadingBtn ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            <span className={styles.btn_label}>
              {t("courseMonetisation.updateLecture")}
            </span>
          )}
        </ButtonMUI>
      </div>
    </div>
  );
};

export default UpdateLectureComponent;
