import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getClasses, joinClassByCode, postMembers } from "../../../redux/actions/classes";
import styles from "./CSSFiles/MyClass.module.css";
import JoinClassModal from "./JoinClassModal";
import {withTranslation} from"react-i18next"

class MyClass extends Component {
  state = {
    showShareModal: false,
    joinClassCode: "",
  };
  shareModalOpener = () => {
    this.setState({ showShareModal: true });
  };
  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  addClassMember = () => {
    this.setState({ showShareModal: false });
    const joinClassCodeOnly = this.state.joinClassCode.split("/").pop();
    this.props.joinClassByCode({ class_code: joinClassCodeOnly });
  };

  changeJoinClassCode = (e) => {
    this.setState({ joinClassCode: e.target.value });
  };
 
  render() {
    return (
      <div className={styles.dropdown_menu1}>
        <ul>
          <div className={styles.menu1}>
            <Link to="/classes" style={{ textDecoration: "none" }}>
              <li style={{ marginTop: 6, marginBottom: 6, color: "#555555" }}>{this.props.t("common.myClasses")}</li>
            </Link>
            <hr className={styles.Hr3} />
            {/* <Link to="/video" style={{ textDecoration: "none" }}> */}
            <li onClick={this.shareModalOpener} style={{ marginTop: 6, marginBottom: 6, color: "#555555" }} className={styles.menuList}>
              {this.props.t("common.joinClass")}
            </li>
            <JoinClassModal
              show={this.state.showShareModal}
              shareModalCloser={this.shareModalCloser}
              addClassMember={this.addClassMember}
              changeJoinClassCode={this.changeJoinClassCode}
            />
            {/* </Link> */}
          </div>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // memberSuggestions:state.users
    user: state.profile.user,
    //memberSuggestions:[{name:'Anmol',id:1},{id:2,name:'Himani'},{id:3,name:'Shivam'},{id:4,name:'Harsh'},{id:5,name:'Anshika'},{id:6,name:'Medha'}],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    joinClassByCode: (data) => dispatch(joinClassByCode(data)),
    getClasses: () => dispatch(getClasses()),
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MyClass));
