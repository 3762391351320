import React from "react";
import { connect } from "react-redux";
import HandleApiError from "../../components/commons/HandleApiError";
import { updateProfileData } from "../../redux/actions/profile";
import { updateProfilePic } from "../../redux/actions/user_profile";
import {
  EDIT_PROFILE_DATA,
  UPDATE_PROFILE_PIC,
} from "../../redux/constants/actionTypes";
import ProfilePhotoModal from "../../apps/Profile/pages/edit/components/Modals/ProfilePhotoModal";

class DpicFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureAsFile: null,
      showProfilePhotoModal: false,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    if (event.target.files[0] != null)
      this.props
        .updateProfilePic(event.target.files[0], "UPDATE_PROFILE_PIC")
        .then((res) => {
          if (res) {
            this.setState({ showProfilePhotoModal: false });
            return;
          }
        });
  }

  OpenProfilePhotoModal = () => {
    this.setState({ showProfilePhotoModal: true });
  };

  CloseProfilePhotoModal = () => {
    this.setState({ showProfilePhotoModal: false });
  };

  render() {
    console.log(this.props.avatarImg);
    return (
      <>
        <ProfilePhotoModal
          showProfilePhotoModal={this.state.showProfilePhotoModal}
          CloseProfilePhotoModal={this.CloseProfilePhotoModal}
          handleImageChange={this.handleImageChange}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginTop: 15,
              height: 160,
              width: 160,
              borderRadius: 100,
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 30,
              position: "relative",
            }}
          >
            {this.props.editpage ? (
              <div
                style={{
                  position: "absolute",
                  width: 160,
                  height: 160,
                  overflow: "hidden",
                  borderRadius: 100,
                }}
              >
                <img
                  src={
                    this.props.profilePicUrl
                      ? this.props.profilePicUrl
                      : this.props.avatarImg
                      ? this.props.avatarImg
                      : "/Image/header/ddefault_img.svg"
                  }
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: 160,
                  height: 160,
                  overflow: "hidden",
                  borderRadius: 100,
                }}
              >
                <img
                  src={
                    this.props.profilePicUrlview
                      ? this.props.profilePicUrlview
                      : this.props.avatarImgView
                      ? this.props.avatarImgView
                      : "/Image/header/ddefault_img.svg"
                  }
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingBottom: 20,
              }}
            >
              {this.props.editpage ? (
                <div
                  style={{
                    padding: 8,
                    backgroundColor: "#fff",
                    borderRadius: 100,
                    cursor: "pointer",
                    height: 15,
                    width: 15,
                    position: "absolute",
                    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
                    right: 0,
                  }}
                >
                  <div onClick={this.OpenProfilePhotoModal}>
                    <img
                      src="/Image/Profile/upload_img.svg"
                      alt="upload_img"
                      style={{
                        cursor: "pointer",
                        width: "35px",
                        position: "absolute",
                        bottom: -2,
                        right: -2,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    border: "5px solid #fff",
                    padding: 8,
                    borderRadius: 20,
                    backgroundColor: "#6fc16a",
                    zIndex: 2,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    profilePicUrl: state.profile.userProfilePic,
    profilePicUrlview: state.profile.userProfilePicView,
    avatarImg: state.profile.avatarImg,
    avatarImgView: state.profile.avatarImgView,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action, profilePic) =>
      dispatch(updateProfileData(action, profilePic)),
    updateProfilePic: (action, profilePic) =>
      dispatch(updateProfilePic(action, profilePic)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DpicFeed);
