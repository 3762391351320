import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 3,
};

export default function ConfirmationModal({
  confirmDelete,
  confirmDeleteCloser,
  deleteUser,
}) {
  const history = useHistory();
  return (
    <div>
      <Modal
        open={confirmDelete}
        onClose={confirmDeleteCloser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
            Are you sure you want to delete your account?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              disableRipple
              onClick={confirmDeleteCloser}
              sx={{
                background: "#f2f2f2",
                ":hover": {
                  background: "#e2e2e2",
                  border: "none",
                },
                textTransform: "none",
                color: "#000",
                borderRadius: "10px",
                px: 4,
                border: "none",
                fontWeight: 600,
              }}
            >
              Cancel
            </Button>
            <Button
              disableRipple
              onClick={() => {
                deleteUser();
                history.push("/signup");
              }}
              sx={{
                background: "#349946",
                ":hover": {
                  background: "#349946",
                  border: "none",
                },
                textTransform: "none",
                color: "#fff",
                borderRadius: "10px",
                px: 4,
                border: "none",
                fontWeight: 600,
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

// onClick={() => {
//     this.props.deleteUser();
//     this.props.history.push("/signup");
//   }}
