import { AiOutlinePlus } from "react-icons/ai";
import { React, Component } from "react";
import { connect } from "react-redux";
import Chat from "../../../components/chat/Chat";
import { ChatProvider } from "../../../components/chat/ChatContext";
import SubjectCard from "../../../components/commons/SubjectCard";
import styles from "./myClasses.module.css";
import ClassModal from "../ClassModal/ClassModal";
import GroupModal from "../../ClassesGroups/GroupModal/GroupModal.js";
import { withRouter } from "react-router";
import { getClassDetails } from "../../../redux/actions/classes";
import { withTranslation } from "react-i18next";
import { store } from "../../../redux/store.js";
import {
  getGroups,
  getGroupDetails,
  joinGroup,
  validMember,
} from "../../../redux/actions/groups";
import JoinPublicGroupModal from "../../Dashboard/Components/JoinPublicGroupModal";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "react-bootstrap";

// import Header from "../../../components/profile-header-explicit/Header";
// import carrerModal from "../../../components/careerModal/CarrerModal";
// import QRModal from "../../../components/QrModal/qrmodal.js"
// import ShareModal from "../../../components/shareModal/ShareModal";

class MySubjectsComponent extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showQrModal: false,
      showCareerModal: false,
      showShareModal: false,
      actionType: "",
      editData: {},
      showShareJoinClassModal: false,
      showShareJoinGroupModal: false,
      joinGroupCode: "",
      joinClassCode: "",
      group_id: "",
      group_name: "asd",
    };
  }
  componentDidMount = () => {
    this.props.getGroups();
    this.props.getGroupDetails();
    // this.props.joinGroup();
    // this.props.getPosts();
  };
  ClassModalCloser = () => {
    this.setState({ showModal: false });
  };

  ClassModalOpener = () => {
    this.setState({ showModal: true });
  };
  // QRModalCloser = () => {
  //   this.setState({ showQrModal: false });
  // };

  // QRModalOpener = () => {
  //   this.setState({ showQrModal: true });
  // };

  // CareerModalCloser = () => {
  //   this.setState({ showCareerModal: false });
  // };

  // CareerModalOpener = () => {
  //   this.setState({ showCareerModal: true });
  // };
  getSubjectDetails = (subjectId) => {
    let { history } = this.props;
    if (this.props.routeType === "/class") {
      history.push({
        pathname: `/class/${subjectId}`,
        state: { subjectId: subjectId },
      });
    } else if (this.props.routeType === "/group") {
      history.push({
        pathname: `/group/${subjectId}`,
        state: { subjectId: subjectId },
      });
    }
  };

  handleAddClass = () => {
    this.setState({ showModal: true });
    this.setState({ actionType: "ADD" });
  };

  handleEditClass = () => {
    this.setState({ showModal: true });
    this.setState({ actionType: "EDIT" });
  };

  handleDeleteClass = () => {
    this.setState({ showModal: true });
    this.setState({ actionType: "DELETE" });
  };

  editClassId = (eachSubject) => {
    this.setState({ editData: eachSubject });
  };
  shareModalOpener = () => {
    this.setState({ showShareJoinGroupModal: true });
  };
  shareJoinGroupModalCloser = () => {
    this.setState({ showShareJoinGroupModal: false });
  };

  memberCheck = (id) => {
    this.props.getGroupDetails(id).then(() => {
      const stateCheck = store.getState();
      const owner = store.getState()?.profile?.user?.profile_id;
      const arr = stateCheck?.groups?.members;
      const ifMember = arr.map((item) => {
        if (item.profileId == owner) {
          return true;
        } else {
          return false;
        }
      });
      if (ifMember.includes(true)) {
        this.shareModalOpener();
        // let { history } = this.props;
        // history.push({
        //   pathname: `/group/${id}`,
        //   state: { subjectId: id },
        // });
      } else {
        this.setState({ group_id: id });
        this.shareModalOpener();
      }
    });
  };

  toggleModal = () => {
    // this.setState({ group_id: id })
    this.shareModalOpener();
  };

  join = (id) => {
    this.props.joinGroup({ group_id: id }).then((res) => console.log(res));
    let { history } = this.props;
    history.push({
      pathname: `/group/${id}`,
      state: { subjectId: id },
    });
  };
  setGroupname = (eachSubject) => {
    this.setState({
      group_id: eachSubject.id,
      group_name: eachSubject.name,
    });
    // this.memberCheck(data.id);
  };
  render() {
    const { t } = this.props;
    const owner = store.getState()?.profile?.user.profile_id;
    const backgroundImagePath =
      this.props.routeType === "/class"
        ? "/Image/Classes/myclass.png"
        : "/Image/Classes/newmygroup.png";
    return (
      <div className={styles.app}>
        {this.props.routeType === "/class" ? (
          <ClassModal
            editData={this.state.editData}
            actionType={this.state.actionType}
            closeModal={this.ClassModalCloser}
            show={this.state.showModal}
          />
        ) : (
          <GroupModal
            editData={this.state.editData}
            actionType={this.state.actionType}
            closeModal={this.ClassModalCloser}
            show={this.state.showModal}
          />
        )}
        <div className={styles.app_body}>
          <div className="col-xs-0 col-sm-0 col-md-1"></div>
          <div
            className={styles.classContainer}
            style={{
              background: "#fef6f6",
              backgroundRepeat: "no-repeat",
              backgroundOrigin: "padding-box",
              backgroundPositionX: "50%",
              backgroundSize: "contain",
              width: "100%",
            }}
          >
            <JoinPublicGroupModal
              show={this.state.showShareJoinGroupModal}
              shareModalCloser={this.shareJoinGroupModalCloser}
              group_id={this.state.group_id}
              joinPublicGroup={this.join}
              group_name={this.state.group_name}
            />
            {/* <div className={styles.topHeading}>{`${this.props.routeType === "/class" ? t("common.myClasses") : t("common.myGroups")}`}</div> */}
            <div className={styles.subjectCardsContainer}>
              <div
                className={styles.groupAddCardContainer}
                onClick={this.handleAddClass}
              >
                <img
                  src="/Image/Group/group1.svg"
                  style={{ width: 200, height: 200 }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <button className={styles.groupAddCardButton}>
                    <AddIcon
                      sx={{
                        fontSize: 15,
                        background: "white",
                        borderRadius: "4px",
                        color: "#3755a6",
                      }}
                    />
                    Create New Group
                  </button>
                </div>
                {/* // <AiOutlinePlus className={styles.plusSign} /> */}
                {/* <div className={styles.sampleCardText}>{`${t( */}
                {/* // "classGroup.createNew" */}
                {/* // )} ${this.props.routeType === "/class" */}
                {/* // ? t("class.class") */}
                {/* // : t("group.group") */}
                {/* // }`}</div> */}
              </div>
              {/* <SubjectCard /> */}
              {this.props.subjects.map((eachSubject, index) => {
                if (
                  eachSubject.class_owner == owner ||
                  eachSubject.group_type == "PUBLIC"
                ) {
                  return (
                    <SubjectCard
                      validMember={validMember}
                      setGroupname={this.setGroupname}
                      toggleModal={this.toggleModal}
                      editClassId={this.editClassId}
                      eachSubject={eachSubject}
                      memberCheck={this.memberCheck}
                      editClassModalOpener={this.handleEditClass}
                      deleteClassModalOpener={this.handleDeleteClass}
                      key={index}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="col-xs-0 col-sm-0 col-md-1"></div>
        </div>
        <div className="col-xs-0 col-sm-0 col-md-3">
          <ChatProvider>
            <Chat
              toggleChatList={this.props.toggleChatList}
              isChatListOpen={this.props.isChatListOpen}
            />
          </ChatProvider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    myGroups:
      state?.groups?.groups?.map((each_group) => {
        return {
          name: each_group?.name,
          id: each_group?.id,
          coverImg: each_group?.cover_image,
          description: each_group?.description,
          group_owner: each_group?.group_owner,
          group_type: each_group?.group_type,
          grade: each_group?.grade,
        };
      }) || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroups: () => dispatch(getGroups()),
    getGroupDetails: (data) => dispatch(getGroupDetails(data)),
    joinGroup: (data) => dispatch(joinGroup(data)),
    getClassDetails: (subjectId) => dispatch(getClassDetails(subjectId)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MySubjectsComponent))
);
