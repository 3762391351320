import React from "react";
import styles from "./WelcomeToCourseModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import { useHistory, Link } from "react-router-dom";

// expected props: "Welcome: true/false"
// courseID and courseName in case of Welcome modal
// "congrats: true" for Congrats modal

const WelcomeToCourseModal = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirect = () => {
    if (props.welcome) {
      history.push(`/course/stream/${props.courseID}`);
    } else {
      history.push("/user");
    }
  };

  return (
    <div className={styles.main}>
      <img
        src={`/Image/lecturePlan/${
          props.welcome ? "Welcome-illustration.png" : "Congratulations.png"
        }`}
        className={styles.illustrations}
      />

      {props.welcome && (
        <p className={styles.description}>
          {t("courseMonetisation.welcomeTo")} - {props.courseName ?? ""}.<br/>
          {t("courseMonetisation.accessNow")}
        </p>
      )}

      {props.congrats && (
        <>
          <span className={styles.heading}>{t("common.congrats")}</span>
          <div className={styles.description2}>
            {t("courseMonetisation.successfullyCompleted")}
          </div>
        </>
      )}

      <div className={styles.btn_div}>
        <Link
          to={props.welcome ? `/course/stream/${props.courseID}` : `/user`}
          target={`_blank`}
          className={styles.link}
        >
          <Button
            type="solid"
            label={
              props?.welcome
                ? t("courseMonetisation.startLearning")
                : t("common.continue")
            }
            clickHandler={props.closeModal}
            className={styles.Button}  
          />
        </Link>
      </div>
    </div>
  );
};

export default WelcomeToCourseModal;
