import React from "react";
import desc from "../../templates/desc";
import styles from "../../../Profile.module.css";
import InfoCard from "../../../../../components/commons/InfoCard";
import { connect } from "react-redux";
import EditProfile from "./EditProfile";
import Modal from "../../../../../components/commons/Modal";
import { withTranslation } from "react-i18next";
import SocailFeed from "./SocialFeed";

class EditDescFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      editcontact: false,
      showMenu: false,
      profileLink: desc.website,
      phone: "",
      email: "",
      username: "",
      name: "",
      address: "",
      designation: "",
      dob: "",
      location: "",
      organisation: "",
    };
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  componentDidMount() {
    this.setState({
      designation: this.props.designation,
      organisation: this.props.organisation,
      location: this.props.location,
      address: this.props.address,
      connections: this.props.connections,
    });
  }

  handleEditClick = () => {
    this.setState({ edit: true });
    document.body.style.overflow = "hidden";
  };

  render() {
    const { t } = this.props;
    return (
      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: "20px 12px",
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfoCard
          mobileView={this.props.mobileView}
          editpage={this.props.editpage}
          designation={this.props.designation}
          location={this.props.location}
          organisation={this.props.organisation}
          connections={this.props.connections}
          handleEditClick={this.handleEditClick}
        />

        <SocailFeed editpage={this.props.editPageSocial} />

        {this.state.edit ? (
          <Modal
            card={
              <EditProfile
                title={t("profile.editDetails")}
                location={this.state.location}
                dob={this.state.dob}
                organisation={this.state.organisation}
                name={this.state.name}
                address={this.state.address}
                designation={this.state.designation}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user || "",
    connections: state?.profile?.about?.connections || "",
    name: state?.profile?.name || "",
    designation: state?.profile?.designation || "",
    address: state?.profile?.address || "",
    dob: state?.profile?.dob || "",
    location: state?.profile?.location || "",
    organisation: state?.profile?.organisation || "",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(EditDescFeed));
