import React from "react";

import styles from "../../../../Profile.module.css";

import SkillInterestImage from "../../../../components/SkillInterestImage";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";
import { fetchModelSkills, updateSkills } from "../../../../../../redux/actions/skill";
import { GET_MODEL_SKILLS, UPDATE_SKILLS } from "../../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

class AddSkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skillIds: [],
      csvSkillIds: "",
      skillContent: [],

      selectedIds: [],
      unselectedIds: [],

      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  setSkillIds() {
    let skillIds = [];
    this.props.skillContent.map((item) => {
      skillIds.push(item.id);
    });

    let csvIds = "";
    skillIds.map((item) => {
      csvIds += item + ",";
    });

    this.setState({ skillIds: skillIds, csvSkillIds: csvIds });
  }

  getAllAndConvertToCSV() {
    let csvIds = this.state.csvSkillIds;
    this.state.selectedIds.map((item) => {
      csvIds += item + ",";
    });
    return csvIds.slice(0, csvIds.length - 1);
  }

  getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  removeAddedSkillContent(allContent) {
    let skillContent = [];
    let unselectedIds = [];
    allContent.map((item) => {
      if (!this.state.skillIds.includes(item.id)) {
        skillContent.push(item);
        unselectedIds.push(item.id);
      }
    });
    this.setState({ skillContent: skillContent, unselectedIds: unselectedIds });
  }

  getExcludedSkillContent() {
    this.props.fetchModelSkills(GET_MODEL_SKILLS).then((res) => {
      if (res.val) {
        this.removeAddedSkillContent(this.props.modelSkills);
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  componentDidMount() {
    this.setSkillIds();
    this.getExcludedSkillContent();
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  addSelectedId(id) {
    let updatedSelectedIds = [...this.state.selectedIds, id];
    this.setState({ selectedIds: updatedSelectedIds });

    let updatedUnselectedIds = this.state.unselectedIds;
    const index = updatedUnselectedIds.indexOf(id);
    if (index > -1) {
      updatedUnselectedIds.splice(index, 1);
    }
    this.setState({ unselectedIds: updatedUnselectedIds });
  }

  removeSelectedId(id) {
    let updatedUnselectedIds = [...this.state.unselectedIds, id];
    this.setState({ unselectedIds: updatedUnselectedIds });

    let updatedSelectedIds = this.state.selectedIds;
    const index = updatedSelectedIds.indexOf(id);
    if (index > -1) {
      updatedSelectedIds.splice(index, 1);
    }
    this.setState({ selectedIds: updatedSelectedIds });
  }

  ScrollBodyOnClick(id) {
    if (this.state.selectedIds.includes(id)) {
      this.removeSelectedId(id);
    } else {
      this.addSelectedId(id);
    }
  }

  ScrollBody() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.state.skillContent.map((item, idx) => {
          return (
            <SkillInterestImage
              pointer={true}
              image={item.upload_img}
              onClick={() => this.ScrollBodyOnClick(item.id)}
              idx={idx}
              bgcolor={this.state.selectedIds.includes(item.id) ? "#00D498" : "#fff"}
              textDecoration={this.state.selectedIds.includes(item.id) ? "underline" : "none"}
              color={this.state.selectedIds.includes(item.id) ? "#00D498" : "#000"}
              name={item.title}
              follower={this.getRandomNumber()}
            />
          );
        })}
      </div>
    );
  }

  getAllAndConvertToCSV() {
    let csvIds = this.state.csvSkillIds;
    this.state.selectedIds.map((item) => {
      csvIds += item + ",";
    });
    //
    return csvIds.slice(0, csvIds.length - 1);
  }

  SaveButtonOnClick() {
    if (this.state.selectedIds.length == 0) {
      document.body.style.overflow = "visible";
      return;
    }

    const data = { skills: this.getAllAndConvertToCSV() };
    this.props.updateSkills(UPDATE_SKILLS, data, this.state.selectedIds).then((res) => {
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  render() {
    const {t} = this.props;
    return (
      <div
        id={styles.template_modal}
        style={{
          backgroundColor: "#fff",
          padding: 30,
          borderRadius: 12,
          width: 550,
        }}
      >
        <ModalHeader title={this.props.modalTitle} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SaveButton onClick={this.SaveButtonOnClick} />

          <div>
            <p style={{ fontSize: 14, color: "#00d498", fontWeight: 500 }}>{this.state.selectedIds.length + " " + t("common.selected")}</p>
          </div>
        </div>

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modelSkills: state.profile?.modelSkills || [],
    skillContent: state.profile?.skills || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchModelSkills: (action) => dispatch(fetchModelSkills(action)),
    updateSkills: (action, data, arrData) => dispatch(updateSkills(action, data, arrData)),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddSkills));
