import React, { Component, componentDidMoun, createRef } from "react";
import SmallChatBox from "./SmallChatBox";
import styles from "./css/chat.module.css";
import BigBox from "./BigBox";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import { dummyData } from "../../lib/utils/globals";

class Chat extends Component {
    constructor(props) {
        super(props);
        this.socket = createRef();
    }
    state = {
        openChats: [],
        showNewMessageBox: false,
        dmList: [],
        newMessageObj: {},
        updatedMessageObj: {},
    };

    convertTimeFromMs(totalMilliseconds) {
        const totalSeconds = Math.floor(totalMilliseconds / 1000); // Convert ms to seconds

        if (totalSeconds >= 86400) {
            // 24 hours
            let days = Math.floor(totalSeconds / 86400);
            return `${days}d`;
        } else if (totalSeconds >= 3600) {
            // 60 minutes
            let hours = Math.floor(totalSeconds / 3600);
            return `${hours}h`;
        } else if (totalSeconds >= 60) {
            // 60 seconds
            let minutes = Math.floor(totalSeconds / 60);
            return `${minutes}m`;
        } else {
            return `${totalSeconds}s`;
        }
    }

    componentDidMount() {
        // fetch dm list for the first time. Maybe we dont need this
        // this.updateDmList();

        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const userId = parsedUserData.user_id;

        this.socket.current = new WebSocket(
            `${process.env.REACT_APP_API_WS_URL}ws/chat/?user_id=${userId}`,
        );

        this.socket.current.addEventListener("message", (e) => {
            const eventData = JSON.parse(e.data);
            const eventType = eventData.event;

            if (eventType === "send_dm_list") {
                const chats = eventData.data.map((item) => {
                    const messageTimestamp = item.dm_info.msg_timestamp;
                    const elapsedTime = this.convertTimeFromMs(
                        new Date() - new Date(messageTimestamp),
                    );
                    return { ...item, elapsedTime };
                });

                this.setState({ dmList: [...chats] });
            } else if (eventType === "chat_message") {
                const messageObj = {
                    id: eventData.message_data.id || 0,
                    body: eventData.message_data.body,
                    sender: eventData.message_data.sender,
                    channelId: eventData.message_data.channel,
                };
                this.setState({ newMessageObj: messageObj });
            } else if (eventType === "update_message") {
                const updatedMessageObj = {
                    id: eventData.id,
                    body: eventData.message_data.body,
                    channelId: eventData.channel_id,
                };
                this.setState({ updatedMessageObj });
            }
        });
    }

    async updateDmList() {
        const chats = await this.fetchMyDmList();
        this.setState({ dmList: chats });
    }

    // api to get all the dm chats
    async fetchMyDmList() {
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const headers = {
            Authorization: `Token ${parsedUserData.token}`,
        };
        const chats = await axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channel-subscriptions/`,
                { headers },
            )
            .then((res) => {
                if (!res.data) return;
                return res.data.data;
            })
            .catch(console.error);
        return chats;
    }

    // open chat on the based of channelId
    chatOpener = (channelId) => {
        if (!this.state.openChats.includes(channelId)) {
            this.setState({ openChats: [...this.state.openChats, channelId] });
        } else {
            let array = [...this.state.openChats];
            var filtered = array.filter(function (value, index, arr) {
                return value !== channelId;
            });
            this.setState({ openChats: [...filtered, channelId] });
        }
    };

    showChats = () => {
        return this.state.openChats.map((id) => {
            // get the channelIndex which is clicked from the list of chat
            const channelIndex = this.state.dmList.findIndex(
                (item) => item.channel === id,
            );

            if (id != "new_message" && channelIndex === -1) return null;

            // get the channel details of the chat/channel
            const channelDetails = this.state.dmList[channelIndex];
            const name = channelDetails?.dm_info.members
                .map((item) => item.dm_name)
                .join(", ");

            // id = "new_message" is for the new message big box
            return (
                <BigBox
                    socket={this.socket.current}
                    updatedMessageObj={this.state.updatedMessageObj}
                    newMessageObj={this.state.newMessageObj}
                    key={id}
                    id={id}
                    dmDetails={channelDetails}
                    closeChat={this.closeChat}
                    {...(id === "new_message" && { showImage: false })}
                    name={id === "new_message" ? "New Message" : name}
                    chatOpener={this.chatOpener}
                    dmList={this.state.dmList}
                    updateDmList={this.updateDmList.bind(this)}
                />
            );
        });
    };

    closeChat = (channelId) => {
        let array = [...this.state.openChats];
        var filtered = array.filter(function (value, index, arr) {
            return value !== channelId;
        });
        this.setState({ openChats: filtered });
    };

    // handleNewMessageClick = (e) => {
    //     e.stopPropagation();
    //     this.setState({ showNewMessageBox: true });
    // };

    // handleCloseNewMessageBox = () => {
    //     this.setState({ showNewMessageBox: false });
    // };

    render() {
        const { pathname } = this.props.location;
        if (pathname === "/messaging") {
            return null;
        }

        return (
            <div id="chat-container" className={styles.container}>
                {this.showChats()}
                {/* {this.state.showNewMessageBox && (
                    <NewMessageBox
                        handleCloseNewMessageBox={this.handleCloseNewMessageBox}
                    />
                )} */}
                <SmallChatBox
                    toggleChatList={this.props.toggleChatList}
                    isChatListOpen={this.props.isChatListOpen}
                    chatOpener={this.chatOpener}
                    dmList={this.state.dmList}
                />
            </div>
        );
    }
}

const WithRouter = withRouter((props) => <Chat {...props} />);

export default WithRouter;
