import React from 'react'
import styles from "./css/InstructorName.module.css"

const InstructorName = (props) => {
  return (
    <div className={styles.instructor_name}>
      <img style={{ width: 16, height: 16 }} src="/Image/CourseMonetisation/user-2-line.svg" alt="" />
      <span>{props.instructor ?? ""}</span>
    </div>
  )
}

export default InstructorName;