// imports
import React from "react";
import desc from "./desc";
import "./Template4.css";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import AspectRatio from "react-aspect-ratio";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

// icons
import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";

// compoents
import RightTitleDescComponentTemp4 from "./components/RightTitleDescComponentTemp4";
import TitleIconDescComp from "./components/TitleIconDescComp";
import ExpTimelineVerticalTemp4 from "./components/ExpTimelineVerticalTemp4";
import ProgressBarStatic from "./components/ProgressBarStatic";

import { fetchProfileDataView } from "../../../../redux/actions/profile";
import { GET_PROFILE_DATA_VIEW } from "../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../components/commons/HandleApiError";
import ShareModal from "../../../Dashboard/shareModal/ShareModal";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class Template4 extends React.Component {
  state = {
    // show: false,
    showShareModal: false,
    link: "ccy-beag-tdz",
  };
  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };
  bgcolor = ["#ffaf02", "#00d498", "#7569de", "#0c1b7a", "#ff0289"];

  skillsDp = [
    [
      { name: "Music", val: 12 },
      // { name: "Music", val: 8 },
    ],
    [
      { name: "Reading", val: 19 },
      { name: "Reading", val: 1 },
    ],
    [
      { name: "Swimming", val: 3 },
      { name: "Swimming", val: 17 },
    ],
    [
      { name: "Fishing", val: 5 },
      { name: "Fishing", val: 15 },
    ],
    [
      { name: "Art", val: 15 },
      { name: "Art", val: 5 },
    ],
  ];

  skillsName = ["Music", "Reading", "Swimming", "Fishing", "Art"];

  componentDidMount() {
    this.props.fetchProfileDataView(GET_PROFILE_DATA_VIEW, JSON.parse(localStorage.userData).token, window.location.pathname.slice(9, -11));
  }
  render() {
    const { t } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 100 }}>
        <AspectRatio ratio="3/4" style={{ maxWidth: "100em", userSelect: "none" }}>
          <div
            id="topviewable"
            style={{
              display: "flex",
            }}
          >
            <div>
              <img
                style={{
                  opacity: 0.1,
                  position: "absolute",
                  marginLeft: "13em",
                  marginTop: "20em",
                  height: "60em",
                  width: "56em",
                }}
                src="/Image/apps/profile-resume-edit-view/Chalkmate_Watermark.png"
              />
            </div>
            <img src={"/Image/apps/profile-resume-edit-view/bgtop1.png"} style={{ width: "65em", height: "auto", position: "absolute", zIndex: -1 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // flex: 1,
                width: 600,
              }}
            >
              <div
                style={{
                  marginTop: 140,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  // crossOrigin=""
                  src={this.props.dp}
                  style={{
                    border: "5px solid #f5a700",
                    borderRadius: 200,
                    marginLeft: 130,
                    position: "absolute",
                    marginTop: 60,
                    width: 225,
                    height: 225,
                  }}
                />
                <div style={{ position: "relative" }}>
                  <img src={"/Image/apps/profile-resume-edit-view/bgline.png"} style={{ position: "absolute", zIndex: -1, height: 900, width: "auto", left: 280, top: -70 }} />
                  <img src={"/Image/apps/profile-resume-edit-view/bglinestart.png"} style={{ position: "absolute", zIndex: -1, height: 900, width: "auto", left: 170, top: -70 }} />
                </div>
              </div>
              <div
                style={{
                  padding: "320px 80px 0px 100px",
                }}
              >
                <p
                  style={{
                    color: "#000000",
                    margin: "30px 0px 0px 0px",
                    fontSize: 40,
                    fontWeight: 500,
                  }}
                >
                  {t("template.hello")}
                </p>
                <p
                  style={{
                    color: "#0c1b7a",
                    margin: "8px 0px 0px 0px",
                    fontSize: 45,
                    fontWeight: 600,
                  }}
                >
                  {t("template.iam")} {this.props.nameView.split(" ")[0]}
                  <br />
                  {this.props.nameView.split(" ")[1]}
                </p>
                <p
                  style={{
                    color: "#0c1b7a",
                    margin: "20px 0px 0px 0px",
                    fontSize: 35,
                    fontWeight: 500,
                  }}
                >
                  {this.props.profession}
                </p>
                <p
                  style={{
                    color: "#656565",
                    margin: "30px 0px 0px 0px",
                    fontSize: 17,
                    fontWeight: 500,
                    lineHeight: 1.5,
                  }}
                >
                  {this.props.about}
                </p>
              </div>
              <div
                style={{
                  padding: 50,
                }}
              >
                {/* {this.skillsDp.map((item, idx) => {
                  return (
                    <div style={{ height: "8em", width: "8em", fontSize: 15, textAlign: "center", display: "inline-block", width: "30%", marginBottom: 0 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={item}
                            dataKey="val"
                            cx="50%"
                            cy="50%"
                            innerRadius={23}
                            outerRadius={50}
                            fill="#82ca9d"
                            startAngle={90}
                            endAngle={-270}
                            isAnimationActive={false}
                          >
                            {this.skillsDp.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={index == 0 ? this.bgcolor[idx] : "#dddddd"} />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                      <p style={styles.cvtitle}>{this.skillsName[idx]}</p>
                    </div>
                  );
                })} */}
                {this.props.interestsView.slice(0, 6).map((item, idx) => {
                  return (
                    <div style={{ height: "8em", width: "8em", fontSize: 15, textAlign: "center", display: "inline-block", width: "30%", marginBottom: 0 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={[{ name: this.props.interestsView[idx].title, val: 5 }]}
                            // data={{name: this.props.skillsView[idx].title, val:5}}
                            dataKey="val"
                            cx="50%"
                            cy="50%"
                            innerRadius={23}
                            outerRadius={50}
                            fill="#82ca9d"
                            startAngle={90}
                            endAngle={-270}
                            isAnimationActive={false}
                          >
                            {this.props.interestsView.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={index == 0 ? this.bgcolor[idx] : "#dddddd"} />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                      {/* <p style={styles.cvtitle}>{this.skillsName[idx]}</p> */}
                      <p style={styles.cvtitle}>{this.props.interestsView[idx].title}</p>
                    </div>
                  );
                })}
              </div>
              <div style={{ display: "flex", justifyContent: "space-around", padding: "30px 0px 60px 50px" }}>
                {/* <div style={styles.scard}>
                  <TitleIconDescComp
                    title={this.props.phone}
                    desc="Phone"
                    titleSize={16}
                    descSize={13}
                    titleColor="#555555"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/dob.png"
                  />
                </div> */}
                <br />
                <div style={styles.scard}>
                  <TitleIconDescComp
                    title={this.props.nationality}
                    desc={t("common.address")}
                    titleSize={16}
                    descSize={13}
                    titleColor="#555555"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/nationality.png"
                  />
                </div>
                <br />
                <div style={styles.scard}>
                  <TitleIconDescComp
                    title={this.props.residencecity}
                    desc={t("common.location")}
                    titleSize={16}
                    descSize={13}
                    titleColor="#555555"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/state.png"
                  />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#236ae8",
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTopRightRadius: 50,
                  borderBottomRightRadius: 50,
                  alignItems: "center",
                  // marginRight: 60,
                  marginBottom: 100,
                  width: 660,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 25,
                    marginRight: 10,
                  }}
                >
                  {/* <EmailIcon width={10} height={10} style={{ color: '#fff' }} /> */}
                  <img src={"/Image/apps/profile-resume-edit-view/mail.png"} style={{ height: 25 }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 13,
                      paddingLeft: 10,
                      color: "#fff",
                    }}
                  >
                    {this.props.email}
                  </p>

                  <img src="/Image/apps/profile-resume-edit-view/website4.png" width={35} style={{ color: "#fff", paddingLeft: 50 }} />
                  {/* <CallIcon width={10} height={10} style={{ color: '#fff', paddingLeft: 50 }} /> */}
                  <p
                    style={{
                      margin: 0,
                      fontSize: 13,
                      paddingLeft: 10,
                      color: "#fff",
                    }}
                  >
                    {this.props.website}
                  </p>
                </div>
                {/* <div
                                    style={{ position: 'relative' }}
                                >
                                    <img src={'/Image/apps/profile-resume-edit-view/bgbottom1.png'} style={{ position: 'absolute', zIndex: -1, width: '65em', height: 'auto', top: "-83em", left: -330 }} />

                                </div> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                // flex: 1,
                marginTop: 50,
                justifyContent: "center",
                width: 400,
                marginLeft: 250,
              }}
            >
              <div>
                <div style={{ flex: 1 }}>
                  <RightTitleDescComponentTemp4 title={t("profile.experience")} />
                  <div style={{ minHeight: "360px" }}>
                    <ExpTimelineVerticalTemp4
                      style={{ height: "560px" }}
                      data={
                        this.props.experience.length != 0
                          ? this.props.experience
                          : [
                              {
                                company: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                employment_type: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                                title: t("template.noInfoAvailable"),
                              },
                              {
                                company: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                employment_type: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                                title: t("template.noInfoAvailable"),
                              },
                              {
                                company: t("template.noInfoAvailable"),
                                description: t("template.noInfoAvailable"),
                                employment_type: t("template.noInfoAvailable"),
                                end_date: t("template.noInfoAvailable"),
                                start_date: t("template.noInfoAvailable"),
                                title: t("template.noInfoAvailable"),
                              },
                            ]
                      }
                    />
                  </div>
                </div>
                <div style={{ marginTop: 70, flex: 1 }}>
                  <RightTitleDescComponentTemp4 title={t("profile.skills")} />
                  <div style={{ minHeight: "205px" }}>
                    {this.props.skillsView.length != 0
                      ? this.props.skillsView.slice(0, 4).map((lval) => {
                          return <ProgressBarStatic fg={"#6564DB"} progress={"100"} title={lval.title} bgcolor="#E5E7E7" weight={500} />;
                        })
                      : t("template.noInfoAvailable")}
                  </div>
                </div>
                {/* <br /> */}
                <br />
                <div style={{ marginTop: 70, flex: 1 }}>
                  <RightTitleDescComponentTemp4 title={t("profile.education")} />
                  <ExpTimelineVerticalTemp4
                    data={
                      this.props.educationWork.length != 0
                        ? this.props.educationWork
                        : [
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                          ]
                    }
                  />
                </div>
                <div style={{ marginTop: 80, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div>
                    {/* <img src={"/Image/apps/profile-resume-edit-view/qrcode.png"} style={{ height: 180 }} /> */}
                    <QRCode
                      value={`${window.location.origin}${"/profile/" + this.props.profile_id + "/view"}`}
                      id="qrCodeEl"
                      size={111}
                      style={{ height: "200px", width: "180px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AspectRatio>
        <br />
        <br />
        <br />

        <div style={{ margin: "30px 0px 100px 0px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <div
            onClick={() => {
              document.body.style.maxWidth = "100em";
              const input = document.getElementById("topviewable");

              html2canvas(input, {
                y: 0,
                allowTaint : true,
                useCORS: true,
                logging: true, 
              }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "px", "a4", true);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                pdf.addImage(imgData, "JPEG", 0, 0, width, height);
                pdf.save("resume.pdf");
                // window.open(pdf.output('datauristring'))
                document.body.style.maxWidth = "100%";
              });
            }}
          >
            <div id="btn-ftr" style={styles.footerbtn}>
              <p style={styles.btntitle}>{t("template.printThisResume")}</p>
            </div>
          </div>
          <div onClick={this.shareModalOpener}>
            <div id="btn-ftr" style={styles.footerbtn}>
              <p style={styles.btntitle}>{t("template.shareThisResume")}</p>
            </div>
          </div>
          <div>
            <Link to={`/profile/${this.props.profile_id}/view`} style={{ textDecoration: "none" }}>
              <div id="btn-ftr" style={styles.footerbtn}>
                <p style={styles.btntitle}>{t("template.viewDetailedResume")}</p>
              </div>
            </Link>
          </div>
        </div>
        <ShareModal closeModal={this.shareModalCloser} show={this.state.showShareModal} shareLink={window.location.href} />
      </div>
    );
  }
}
function mapStateToProps(state,props) {
  const {t} = props;
  return {
    nameView: state?.profile?.nameView || "",
    // lname: state?.profile?.nameView?.nameView.split(" ")[1] || "",
    website: window.location.href.replace("template/1", "") || "www.chalkmates.com",
    profession: state?.profile?.designationView || t("template.noInfoAvailable"),
    dob: state?.profile?.dobView || t("template.noInfoAvailable"),
    nationality: state?.profile?.addressView || t("template.noInfoAvailable"),
    residencecity: state?.profile?.locationView || t("template.noInfoAvailable"),
    residencestate: t("template.noInfoAvailable"),
    email: state?.profile?.user_email || t("template.noInfoAvailable"),
    dp: state?.profile?.userProfilePicView || "/Image/apps/profile-resume-edit-view/default-user.png",
    about: state?.profile?.aboutView || t("template.noInfoAvailable"),
    languages: state?.profile?.language_knownView || t("template.noInfoAvailable"),
    interestsView: state?.profile?.interestsView || [],
    skillsView: state?.profile?.skillsView || [],
    educationWork: state?.profile?.educationView || [
      {
        degree: t("template.noInfoAvailable"),
        description: t("template.noInfoAvailable"),
        end_date: t("template.noInfoAvailable"),
        field_of_study: t("template.noInfoAvailable"),
        participations: t("template.noInfoAvailable"),
        school: t("template.noInfoAvailable"),
        start_date: t("template.noInfoAvailable"),
      },
    ],
    experience: state?.profile?.experienceView || [
      {
        company: "DataPitcher",
        description: "",
        employment_type: "Internship",
        end_date: "2021-04-15",
        start_date: "2020-12-15",
        title: "Angular Developer",
      },
    ],
    profile_id: state?.profile?.profileIdView || "",

    // nameView: state?.profile?.user?.first_name || "Write a short bio about yourself.",
    certificationView: state?.profile?.certificationView || [],
    volunteerView: state?.profile?.volunteerView || [],
    achievementView: state?.profile?.achievementView || [],
    organisationView: state?.profile?.organisationView || t("template.noInfoAvailable"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
  };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Template4));

const styles = {
  ssklsrd: {
    // boxShadow: '0px 0px 10px #5555551A',
    borderRadius: 10,
    padding: "4px 12px 4px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0px 10px 0px 10px",
  },

  vwcnv: {
    height: 80,
    width: 80,
    position: "relative",
  },

  cvtitle: {
    fontSize: 17,
    fontWeight: 500,
    color: "#5e5e5e",
    textTransform: "capitalize",
    margin: 0,
  },

  scard: {
    flex: 1,
    boxShadow: "0px 0px 10px #5555551A",
    borderRadius: 10,
    padding: "10px 10px 10px 20px",
  },

  li: {
    margin: 0,
    color: "#236ae8",
    fontSize: 40,
    display: "flex",
    alignItems: "center",
    marginLeft: -5,
    marginBottom: 20,
    // zIndex: 10
  },

  lihead: {
    color: "#7d7d7d",
    margin: 0,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.5,
  },

  lidesc: {
    color: "#606060",
    margin: "0px 0px 0px 0px",
    fontSize: 19,
    fontWeight: 600,
    lineHeight: 1.5,
  },

  vwbtnftr: {
    display: "flex",
    flexDirection: "row",
  },

  footerbtn: {
    borderRadius: 30,
    border: "2px solid #00d498",
    margin: "10px 60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"10px"
  },

  btntitle: {
    padding: "3px 40px 3px 40px",
    fontSize: 10,
    fontWeight: 600,
  },
};
