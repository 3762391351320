import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styles from "../../../Profile.module.css";
import { Box, Typography } from "@mui/material";

class AboutFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      text: "No information available.",
    };

    this.changeText = this.changeText.bind(this);
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeText(text) {
    this.setState({ text: text });
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.about_div}>
        <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
          <img
            src={
              this.props.userTypeView === "INDIVIDUAL"
                ? "/Image/Profile/about.svg"
                : "/Image/Business Profile/business_info.svg"
            }
            alt={
              this.props.userTypeView === "INDIVIDUAL"
                ? "about"
                : "business info"
            }
            style={{ width: "20px" }}
          />
          <Typography
            sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
          >
            {this.props.userTypeView === "INDIVIDUAL"
              ? t("profile.about")
              : t("profile.businessInfo")}
          </Typography>
        </Box>

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "20px 30px",
            borderRadius: 10,
          }}
        >
          <p className={styles.section_desc}>
            {this.props.about || t("profile.noInformationAvailable")}
          </p>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || [],
    about: state?.profile?.aboutView,
    userTypeView: state?.profile?.user_type_view,
  };
}

export default withTranslation()(connect(mapStateToProps, null)(AboutFeed));
