import React from "react";
import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
// import ModalHeader from "../../../../../../components/commons/ModalHeader"
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import { updateProfileData } from "../../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import CancelIcon from "@mui/icons-material/Cancel";
import { withTranslation } from "react-i18next";

class AddLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langContent: this.props?.langContent || "",
      language: "",

      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    document.body.style.overflow = "visible";
    this.props.changeEdit(false);
  }

  SaveButtonOnClick() {
    this.props.changeEdit(false);
    let data = new FormData();
    data.append("language_known", this.state.langContent);

    this.props.updateProfileData(EDIT_PROFILE_DATA, data).then((res) => {
      //
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  ScrollBody() {
    const {t} = this.props;
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
          {/* <form onSubmit={e => {
            e.preventDefault()
            const myList = ["Hindi", "English", "Pubjabi", "Telegu", "Spanish", "Arab"]
            if (!myList.includes(this.state.language)) {
              return
            }
            var newLangContent = ""
            newLangContent = this.state.language
            if (this.state.langContent.length != 0) {
              newLangContent += "," + this.state.langContent
            }
            this.setState({ langContent: newLangContent, language: "" })
          }}>

            <datalist id="mylist">
              <option value="Hindi" />
              <option value="English" />
              <option value="Punjabi" />
              <option value="Telegu" />
              <option value="Spanish" />
              <option value="Arab" />
            </datalist>


            <input
              className={`${styles.modal_para} ${styles.modal_input_text}`}
              type="search" list="mylist"
              placeholder="Add Languages"
              value={this.state.language}
              onChange={(event) => {
                this.setState({ language: event.target.value });
              }}

            />
          </form> */}
          {/* 
          <form onSubmit={e => {
            e.preventDefault()
            var newLangContent = ""
            newLangContent = this.state.language
            if (this.state.langContent.length != 0) {
              newLangContent += "," + this.state.langContent
            }
            this.setState({ langContent: newLangContent, language: "" })
          }}>
            <input
              className={`${styles.modal_para} ${styles.modal_input_text}`}
              type="text"
              placeholder="Add Languages"
              value={this.state.language}
              onChange={(event) => {
                this.setState({ language: event.target.value });
              }}
            />
          </form> */}

          <div style={{ display: "flex", marginBottom: 15, justifyContent: "space-between", alignItems: "center" }}>
            <input
              className={`${styles.modal_para} ${styles.modal_input_text}`}
              style={{
                flex: 1,
              }}
              type="text"
              placeholder={t("profile.AddLanguages")}
              value={this.state.tag}
              onChange={(event) => {
                this.setState({ language: event.target.value });
              }}
            />

            {/* <div> */}
              &nbsp;
            <button
              className={`${styles.btn} ${styles.connect_btn}`}
              style={{
                flex: 0.1,
              }}
              onClick={(e) => {
                e.preventDefault();
                var newLangContent;
                if(this.state.language.trim()  != ""){
                  if( !this.props.langContent.split(',').includes(this.state.language.trim())){
                    newLangContent = this.state.language;
                    if (this.state.langContent.length != 0) {
                      newLangContent += "," + this.state.langContent;
                    }
                    this.setState({ langContent: newLangContent, language: "" });
                  }
                  else{
                    this.setState({error: true, message:t("errors.CantAddDuplicateLanguage")})
                  }
                }
                else{
                 this.setState({error: true, message:t("errors.CantAddEmptyLanguage")})

                }
               
              }}
            >
              <p className={styles.btn_text} style={{padding: '0 12px'}}>{t("common.add")}</p>
            </button>

            <br />
          </div>
          <div style={{ borderBottom: "2px solid #5555555a" }} />

          <br />
        </div>

        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.state.langContent.length == 0
                ? null
                : this.state.langContent?.split(",").map((item, idx) => {
                    return (
                      <>
                      {item != "" ?
                        <li key={idx} className={styles.tagList}>
                          {item}
                          <div style={{display: "flex", margin: '0 -10px 0 8px'}} >
                          <CancelIcon
                            style={{ fill: "#878484", cursor: "pointer" }}
                            size={10}
                            onClick={() => {
                              const updatedContent = this.state.langContent.split(",").filter((f) => {
                                return f === item ? false : true;
                              });

                              this.setState({ langContent: updatedContent.join(",") });
                            }}
                          />
                          </div>
                        </li> : 
                        null
                      }
                      </>
                    );
                  })}
            </ul>
          }

          {/* </Stack> */}
        </ul>
      </div>
    );
  }

  render() {
    //
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    langContent: state?.profile?.language || "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action, data) => dispatch(updateProfileData(action, data)),
  };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddLanguage));
