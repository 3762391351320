import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";
import DoneButton from "../../../../../Dashboard/shared/ui/Modal/DoneButton";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../../../contexts/ProfileContext";
import { useContext } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function ProfilePhotoModal({
  showProfilePhotoModal,
  CloseProfilePhotoModal,
  handleImageChange,
}) {
  const [avatar, setAvatar] = React.useState(null);
  const { t } = useTranslation();
  const { addAvatar } = useContext(ProfileContext);

  return (
    <div>
      <Modal
        open={showProfilePhotoModal}
        onClose={CloseProfilePhotoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Profile Picture
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "#656565" }}
          >
            Select any avatar or upload your profile picture
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <img
                onClick={() => setAvatar("male")}
                src="https://nxnearby-assets.s3.ap-south-1.amazonaws.com/assets/male_profile.png"
                alt="male_avatar"
                style={{ width: "70px", cursor: "pointer" }}
              />
              {avatar === "male" && (
                <CheckOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    color: "green",
                    right: -10,
                    background: "#fff",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                />
              )}
            </Box>
            <Box sx={{ position: "relative" }}>
              <img
                onClick={() => setAvatar("female")}
                src="https://nxnearby-assets.s3.ap-south-1.amazonaws.com/assets/female_profile.png"
                alt="female_avatar"
                style={{ width: "70px", cursor: "pointer" }}
              />
              {avatar === "female" && (
                <CheckOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    color: "green",
                    right: -10,
                    background: "#fff",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                />
              )}
            </Box>
            <Divider orientation="vertical" flexItem sx={{ color: "#000" }} />
            <label
              for="iconimage-dp"
              style={{
                background: "#1e4387",
                textTransform: "none",
                color: "#fff",
                borderRadius: "10px",
                padding: "10px 15px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="/Image/DashboardImages/postModImages/add_post_icon.svg"
                alt="add_image"
                style={{ width: "18px", marginRight: "5px" }}
              />
              Upload Picture
            </label>
            <input
              type="file"
              id="iconimage-dp"
              onChange={handleImageChange}
              style={{ display: "none", cursor: "pointer" }}
              accept="image/png, image/jpeg, image/svg"
            />
          </Box>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              onClick={CloseProfilePhotoModal}
              disableRipple
              variant="outlined"
              sx={{
                background: "#f2f2f2",
                ":hover": {
                  background: "#e2e2e2",
                  border: "none",
                },
                textTransform: "none",
                color: "#000",
                borderRadius: "10px",
                px: 4,
                border: "none",
              }}
            >
              {t("occasionModal.back")}
            </Button>

            <Button
              onClick={() => {
                addAvatar(avatar);
                CloseProfilePhotoModal();
              }}
              disableRipple
              variant="outlined"
              sx={{
                background: "#6fc16a",
                ":hover": {
                  background: "#6fc16a",
                  border: "none",
                },
                textTransform: "none",
                color: "#000",
                borderRadius: "10px",
                px: 4,
                border: "none",
              }}
            >
              {t("common.save")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
