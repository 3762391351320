import styles from "../css/ConversationCard.module.css";

function ConversationCard(props) {
    const {
        name = "",
        image = "",
        lastMessage = "",
        lastMessageTime = "",
    } = props;
    return (
        <>
            <div
                className={`${styles["image"]} rounded-pill`}
                style={{
                    backgroundImage: `url(${image})`,
                }}
            ></div>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex justify-content-center flex-column">
                    <p className="m-0">{name}</p>
                    <p
                        className={`m-0 ${styles["message-description"]} text-secondary`}
                    >
                        {lastMessage}
                    </p>
                </div>
                <div>
                    <p className={`m-0 ${styles["last-message-time"]}`}>
                        {lastMessageTime}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ConversationCard;
