import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { loginSuccess, logoutUser } from "../redux/actions/login";

class IsAuthenticated extends Component {
  componentWillMount = () => {
    const handleInvalidToken = (e) => {
      if (e.key === "userData" && e.oldValue && !e.newValue) {
        // Your logout logic here
        this.props.logoutUser();
      } else if (e.key === "userData" && e.oldValue != e.newValue) {
        this.props.loginSuccess(e.newValue);
      }
    };

    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  };

  sendAlert(message) {
    let userData = localStorage.getItem("userData");
    if (userData) {
      this.props.loginSuccess(JSON.parse(userData));
    } else {
      if (message) alert(message);
      return <Redirect to="/signin" />;
    }
  }
  render() {
    return <div>{!this.props.error && this.props?.user?.token ? null : this.sendAlert(this.props.error.message)}</div>;
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user,
    error: state.profile.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  //mapDisptachToProps is used to dispatch actions
  return {
    loginSuccess: (userData) => dispatch(loginSuccess(userData)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IsAuthenticated);
