import { Box, Paper, Typography, Button, Modal } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const eventsList = [
  "/Image/DashImage/Events/event2.jpg",
  "/Image/DashImage/Events/event1.jpg",
  "/Image/DashImage/Events/event3.jpg",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "none",
  borderRadius: "15px",
};

const EventsCarousel = () => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const { t } = useTranslation();

  const handleImageClick = (src) => {
    setImage(src);
    setOpen(true);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={image}
            alt="event"
            style={{
              width: "100%",
              borderRadius: "15px",
              objectFit: "cover",
              height: "auto",
            }}
          />
        </Box>
      </Modal>
      <Paper
        elevation={0}
        sx={{
          boxShadow: "rgba(85, 85, 85, 0.1) 0px 0px 20px",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "15px 20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img
              src="/Image/DashboardImages/postModImages/event_icon.svg"
              alt="event icon"
              style={{ width: "25px" }}
            />
            <Typography
              sx={{ fontWeight: 600, fontSize: "15px", color: "#535353" }}
            >
              {t("eventModal.rightTitle")}
            </Typography>
          </Box>
          <MoreHorizIcon />
        </Box>

        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={false}
          infinite={true}
        >
          {eventsList.map((event, idx) => (
            <img
              onClick={() => handleImageClick(event)}
              key={idx}
              src={event}
              alt="event"
              style={{ width: "100%", borderRadius: "15px", cursor: "pointer" }}
            />
          ))}
        </Carousel>

        <Box
          sx={{ p: "8px 15px  ", display: "flex", justifyContent: "flex-end" }}
        >
          <Link
            to="/discover/event"
            style={{
              textDecoration: "none",
              color: "#396dd4",
              fontSize: "14px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            {t("dashboard.allGroups")}
          </Link>
        </Box>
      </Paper>
    </>
  );
};

export default EventsCarousel;
