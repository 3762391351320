import ConversationCard from "./ConversationCard";
import { Fragment } from "react";

function ConversationList(props) {
    const { data, user, handleConversationCardClick } = props;
    if (!data) return null;

    return (
        <>
            {data.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <div
                            className={`d-flex p-3 gap-2`}
                            style={{
                                background:
                                    user?.id === item.id
                                        ? "#fef7f8"
                                        : "transparent",
                            }}
                            onClick={() => handleConversationCardClick(item)}
                        >
                            <ConversationCard {...item} />
                        </div>
                    </Fragment>
                );
            })}
        </>
    );
}

export default ConversationList;
