import React, { Component } from "react";
import styles from "./css/bigBox.module.css";
import MyMessage from "./MyMessage";
import YourMessage from "./YourMessage";
import ChatHeader from "./ChatHeader";
import Add from "./images/whiteAdd.svg";
import Emoji from "./images/Emoji.png";
import GIF from "./images/GIF.png";
import Thumbup from "./images/Thumbup.png";
import { ChatContext } from "./ChatContext";
import NewMessageView from "./NewMessageView";
import axios from "axios";

export default class BigBox extends Component {
    state = {
        showChat: true,
        message: "",
        messages: [],
        openDialog: false,
    };

    scrollToBottom = () => {
        if (this.state.showChat && this.messagesEnd)
            this.messagesEnd.scrollIntoView();
    };

    componentDidMount() {
        this.scrollToBottom();

        if (this.props.id !== "new_message") {
            // fetch all the previous messages of a channelId for if old chat/channel is opened
            this.updateMessages();
        }

        if (!document.getElementById("div-bigboxcontainer")) return;

        if (window.innerWidth > 1100) {
            document
                .getElementById("div-messagebox")
                .style.removeProperty("position");
            document
                .getElementById("div-messagebox")
                .style.removeProperty("top");
            document
                .getElementById("div-messagebox")
                .style.removeProperty("left");
            document
                .getElementById("div-messagebox")
                .style.removeProperty("right");
            document
                .getElementById("div-messagebox")
                .style.removeProperty("bottom");
            document
                .getElementById("div-messagebox")
                .style.removeProperty("zIndex");

            document.getElementById("div-bigboxcontainer").style.height =
                "350px";
            document.getElementById("div-bigboxcontainer").style.width =
                "350px";
        } else if (window.innerWidth < 1100) {
            document.getElementById("div-messagebox").style.position =
                "absolute";
            document.getElementById("div-messagebox").style.top = "0px";
            document.getElementById("div-messagebox").style.left = "0px";
            document.getElementById("div-messagebox").style.right = "0px";
            document.getElementById("div-messagebox").style.bottom = "75px";
            document.getElementById("div-messagebox").style.zIndex = 1;

            document
                .getElementById("div-bigboxcontainer")
                .style.removeProperty("height");
            document.getElementById("div-bigboxcontainer").style.height =
                "100%";
            document.getElementById("div-bigboxcontainer").style.width = "100%";
        }
    }

    componentDidUpdate(prevProps) {
        this.scrollToBottom();

        if (this.props.id === "new_message") return;

        // append a new message
        if (
            prevProps.newMessageObj !== this.props.newMessageObj &&
            this.props.newMessageObj.channelId === this.props.dmDetails.channel
        ) {
            this.setState({
                messages: [
                    ...this.state.messages,
                    { ...this.props.newMessageObj },
                ],
            });
        }

        // update the message
        if (
            prevProps.updatedMessageObj !== this.props.updatedMessageObj &&
            this.props.updatedMessageObj.channelId ===
                this.props.dmDetails.channel
        ) {
            const messages = [...this.state.messages];
            messages.map((item) => {
                if (item.id == this.props.updatedMessageObj.id) {
                    item.body = this.props.updatedMessageObj.body;
                }
            });
            this.setState({ messages });
        }
    }

    hideChat = () => {
        // close the chat using channel id or if close chat by id = new_message
        this.props.closeChat(this.props?.dmDetails?.channel || this.props.id);
    };

    minimizeChat = () => {
        this.setState({
            showChat: !this.state.showChat,
        });
    };

    change = (e) => {
        this.setState({
            message: e.target.value,
        });
    };

    // send a post message to a channel using channel id
    sendMessage = async (e) => {
        e.preventDefault();
        const message = this.state.message;
        if (!message) return;

        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const channelId = this.props.dmDetails.channel;
        const messagePayload = {
            message,
            sender_id: parsedUserData.user_id,
            channel_id: channelId,
            event: "chat_message",
        };
        this.props.socket.send(JSON.stringify(messagePayload));
        this.setState({ message: "" });
    };

    // fetch messages using channelid or from the props
    updateMessages = async (channelId = "") => {
        this.fetchMessagesByChannelId(channelId).then((messages) => {
            this.setState({ messages });
        });
    };

    // fetch messages by channel id
    fetchMessagesByChannelId = async (channel_id) => {
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const headers = {
            Authorization: `Token ${parsedUserData.token}`,
        };
        const channelId = channel_id || this.props?.dmDetails?.channel;
        const messages = await axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/store_msg/?channel_id=${channelId}&dynamic_limit=50`,
                { headers },
            )
            .then((res) => {
                if (!res.data) return;
                return res.data.data;
            })
            .catch(console.error);
        return messages;
    };

    // delete message based on message id
    async deleteMessage(id) {
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const headers = {
            Authorization: `Token ${parsedUserData.token}`,
        };
        const result = await axios({
            url: `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/store_msg/del-msgs/`,
            method: "delete",
            headers,
            data: {
                dlt_all: false,
                msg_ids: [id],
            },
        })
            .then((res) => {
                return res.data;
            })
            .catch(console.error);
        return result;
    }

    emojiAdder = (emoji) => {
        let mess = this.state.message;
        mess += emoji;
        this.setState({
            message: mess,
        });
    };

    showDetailedView = () => {
        // show new message view when the id is new_message
        if (this.props.id === "new_message") {
            return (
                <div className={`${this.state.showChat ? "" : "d-none"}`}>
                    <NewMessageView
                        openChat={this.props.chatOpener}
                        dmList={this.props.dmList}
                        updateDmList={this.props.updateDmList}
                    />
                    ;
                </div>
            );
        }

        // else old chat/channel is opened
        const userData = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        const userId = parsedUserData.user_id;

        return (
            <div
                id="div-bigboxcontainer"
                className={`${styles.container} ${
                    this.state.showChat ? "" : "d-none"
                }`}
            >
                <div className={styles.middleContainer}>
                    {/* <p className={styles.unreadText}>
                        <span className={styles.unreadSpan}>
                            {this.context.state.messages.length} unread messages
                        </span>
                    </p> */}

                    {this.state.messages &&
                        this.state.messages.map((messData) => {
                            // if the messages are deleted, dont show them
                            if (messData.deleted_for_sender) {
                                return null;
                            }
                            // if the user id is the sender id
                            if (userId === messData.sender) {
                                return (
                                    <MyMessage
                                        socket={this.props.socket}
                                        id={messData.id}
                                        channelId={this.props.dmDetails.channel}
                                        message={messData.body}
                                        deleteMessage={this.deleteMessage}
                                        updateMessages={this.updateMessages}
                                    />
                                );
                            } else {
                                return (
                                    <YourMessage
                                        id={messData.id}
                                        channelId={this.props.dmDetails.channel}
                                        message={messData.body}
                                        deleteMessage={this.deleteMessage}
                                        updateMessages={this.updateMessages}
                                    />
                                );
                            }
                        })}

                    {/* <MyMessage message="kahsvbckjasbckjbas"/> */}
                    {/* <YourMessage message="jasbvckjasbvckjavbskcjvaskjchvaskhcvaskjhcvjasvhcjavshc"/> */}
                    {/* <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/> */}
                    <div
                        style={{
                            height: "4px",
                            float: "left",
                            clear: "both",
                        }}
                        ref={(el) => {
                            this.messagesEnd = el;
                        }}
                    ></div>
                </div>
                <div className={styles.inputContainer}>
                    <form
                        onSubmit={(e) => this.sendMessage(e)}
                        className={styles.innerInputContainer}
                    >
                        <div className={styles.addContainer}>
                            <img height="30px" src={Add} alt="Add" />
                        </div>
                        <input
                            onChange={(e) => this.change(e)}
                            value={this.state.message}
                            className={styles.inputBox}
                            type="text"
                            placeholder="Aa"
                        ></input>
                        <div className={styles.imagesContainer}>
                            {/* <img className={styles.greyImage} height="25px" src={Emoji} alt="Emoji" /> */}
                            {/* <img height="25px" src={GIF} alt="GIF" /> */}
                            {/* <img
                                onClick={() => {
                                    this.emojiAdder("👍");
                                }}
                                className={styles.greyImage}
                                height="23px"
                                src={Thumbup}
                                alt="Thumbup"
                            /> */}
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div id="div-messagebox" style={{ marginTop: "auto" }}>
                <ChatHeader
                    showImage={this.props.showImage}
                    click={this.minimizeChat}
                    title={this.props.name}
                    cross
                    arrowClick={this.hideChat}
                />
                {this.showDetailedView()}
            </div>
        );
    }
}

BigBox.contextType = ChatContext;
