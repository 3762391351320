import React from "react";
import { Component } from "react";
import styles from "./groupcss/grouprightsidebar.module.css";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Link } from "react-router-dom";
import moment from "moment";
import CareerModal from "../../../components/careerModal/CarrerModal";

class GroupRightSidebar extends Component {
  state = {
    isModalOpen: false,
  };
  openComingSoonModal = () => {
    this.setState({ isModalOpen: true });
  };
  closeComingSoonModal = () => {
    this.setState({ isModalOpen: false });
  };
  render() {
    let date = moment().format("LLLL").split(",");
    date = date[0] + `, ` + date[1];
    return (
      <div className={styles.group_rightsidebar}>
        <CareerModal show={this.state.isModalOpen} closeModal={this.closeComingSoonModal} />
        <div className={styles.groupRightsidebar_stats}>
          <div className={styles.groupRightsidebar_stat}>
            <div className={styles.groupRightsidebar_inner_stat}>
              <p
                style={{
                  font: "normal normal normal 14px/20px Montserrat",
                  color: "#929292",
                }}
              >
                Calendar
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "0.99" }}></div>
              <FiberManualRecordIcon
                style={{
                  color: "#FC1159",
                  height: 8,
                  width: 8,
                  cursor: "pointer",
                }}
              />
            </div>
            <h4
              style={{
                font: "font: normal normal medium 16px/20px Montserrat",
                color: "#000000",
              }}
            >
              {date}
            </h4>
          </div>
        </div>
        <div>
          <button className={styles.groupinviteButton} onClick={this.openComingSoonModal}>
            INVITE
          </button>
        </div>
      </div>
    );
  }
}

export default GroupRightSidebar;
