import React from "react";
import { withTranslation } from "react-i18next";

import styles from "../../apps/Profile/Profile.module.css";
import IconTitle from "./IconTitle";

class UpskillingFeed extends React.Component {
  // contentImages = [
  //     "/Image/upskilling1.png",
  //     "/Image/upskilling2.png",
  //     "/Image/upskilling3.png",
  //     "/Image/upskilling4.png",
  //     "/Image/upskilling4.png",
  //     "/Image/upskilling4.png"
  // ]

  state = {
    content: [
      {
        name: "Web with JavaScript",
        image: "/Image/apps/profile-resume-edit-view/upskilling1.jpg",
        viewres: Math.floor(Math.random() * 100000),
        href: "https://www.edx.org/course/programming-for-the-web-with-javascript",
      },

      {
        name: "Complete Website Ethical Hacking ",
        image: "/Image/apps/profile-resume-edit-view/upskilling2.jpg",
        viewres: Math.floor(Math.random() * 100000),
        href: "https://www.udemy.com/course/complete-webapplication-penetration-testing-practical-cwapt/",
      },

      {
        name: "CS50's Introduction to Artificial Intelligence",
        image: "/Image/apps/profile-resume-edit-view/upskilling3.png",
        viewres: Math.floor(Math.random() * 100000),
        href: "https://online-learning.harvard.edu/course/cs50s-introduction-artificial-intelligence-python?delta=0",
      },

      {
        name: "Antibiotic Stewardshi",
        image: "/Image/apps/profile-resume-edit-view/upskilling4.jpg",
        viewres: Math.floor(Math.random() * 100000),
        href: "https://online.stanford.edu/courses/som-ycme0043-antibiotic-stewardship",
      },

      {
        name: "Google Project Management: Professional Certificate",
        image: "/Image/apps/profile-resume-edit-view/upskilling5.webp",
        viewres: Math.floor(Math.random() * 100000),
        href: "https://www.coursera.org/professional-certificates/google-project-management",
      },

      {
        name: "What Every Primary Care Provider Needs to Know",
        image: "/Image/apps/profile-resume-edit-view/upskilling6.png",
        viewres: Math.floor(Math.random() * 100000),
        href: "https://online.stanford.edu/courses/som-ycme0006-congenital-hypothyroidism-what-every-primary-care-provider-needs-know",
      },
    ],
  };

  render() {
    const {t} = this.props;
    return (
      <div
        id="Upskilling and Reskilling"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <IconTitle img="/Image/apps/profile-resume-edit-view/learning.svg" title={t("profile.upskillingAndReskilling")} />

        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 20,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: 10, display: "flex", flexWrap: "wrap" }}>
            {this.state.content.map((item, idx) => {
              return (
                <a key={idx} style={{ width: "50%", cursor: "pointer", textDecorationColor: "#00d498" }} href={item.href} target="_blank">
                  <div
                    className={styles.up}
                    style={{
                      width: "100%",
                      marginTop: idx == 0 || idx == 1 ? 0 : 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 5,
                        height: 70,
                      }}
                    >
                      <img src={item.image} style={{ width: "90%", height: "auto" }} />
                    </div>
                    <div
                      className={styles.up_desc}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: 5,
                      }}
                    >
                      <p
                        className={styles.followers}
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginTop: 5,
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          opacity: 0.8,
                          margin: 0,
                        }}
                      >
                        <i>{item.viewres} {t("common.viewers")}</i>
                      </p>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(UpskillingFeed);