import React from "react";
import Modal from "../../../../../components/commons/Modal";
import AddTags from "./Modals/AddTags";
import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class CreateBusiness extends React.Component {
  state = {
    edit: false,
  };

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        id="CreateBusiness"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "10px 20px",
            borderRadius: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/Image/DashImage/business.svg"
              alt="business"
              style={{ marginTop: 8, width: "100%" }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              textAlign: "left",
              ml: 2,
              color: "#535353",
              fontStyle: "italic",
            }}
          >
            {this.props.t("dashboard.ownBusiness")}
          </Typography>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              textAlign: "left",
              ml: 2,
              color: "#535353",
              mr: { lg: 0, md: 5 },
              mt: 1,
            }}
          >
            {this.props.t("dashboard.businessDesc")}
          </Typography>

          <div className={styles.view}>
            <Button
              startIcon={<StorefrontIcon />}
              onClick={() =>
                this.props.history.push({
                  pathname: "/signup",
                  state: { detail: "LOCAL BUSINESS" },
                })
              }
              disableRipple
              size="medium"
              sx={{
                textTransform: "none",
                background: "var(--primary-optional)",
                borderRadius: "20px",
                color: "#fff",
                px: 2,
                mt: 2,
                ":hover": {
                  background: "var(--primary-optional)",
                },
                fontWeight: 500,
                lineHeight: { xs: 1.75, sm: 1.75, md: 1.25, lg: 1.75 },
                mb: 2,
              }}
            >
              {this.props.t("dashboard.createBusinessPage")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || "",
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, null)(CreateBusiness))
);
