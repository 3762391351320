import { WindowRounded } from "@mui/icons-material";
import React, { useEffect } from "react";
import { extractDomain } from "../../../redux/constants/commonFunctions";
import styles from "./css/AttachmentContainer.module.css";

function AttachmentContainer(props) {
  const handleClick = () => {
    if (props?.assignmentFileType === "FILE") {
      window.open(props?.file, "_blank");
    } else {
      window.open(props?.link, "_blank");
    }
  };

  const getIcon = (fileType) => {
    // return `/Image/AssignmentAndQuizzes/${
    //   fileType === "FILE" ? "File" : "Link"
    // }.png`;
    if(fileType === 'FILE'){
      const extension = props?.fileName.split('.').pop().toLowerCase();
      switch(extension){
        case "pdf":
          return "/Image/assignment/file-pdf.svg";
        case "ppt":
        case "pptx":
          return"/Image/CourseMonetisation/green-variant/file-ppt-line.svg";
        case "doc":
        case "docx":
          return "/Image/CourseMonetisation/green-variant/file-word-line.svg";
        case "xls":
        case "xlsx":
          return "/Image/CourseMonetisation/green-variant/file-excel-line.svg";
        case "mp3":
          return "/Image/CourseMonetisation/green-variant/music-line.svg";
        case "mp4":
          return "/Image/CourseMonetisation/green-variant/video-line.svg";
        case "jpg":
        case "jpeg":
        case "png":
          return "/Image/CourseMonetisation/green-variant/image-line.svg";
        default:
          return "/Image/CourseMonetisation/file-code-line.svg";  
      }

    } else {
      return "/Image/assignment/Add link.svg";
    }
  };

  return (props.file === null || props.link === null) ? null : props?.pageType !== "Post" ? (
    props?.pageType !== "Submission" ? (
      <div
        className={styles.attachment_container}
        onClick={() => handleClick()}
      >
        <div className={styles.attachment_file}>
          <img
            src={getIcon(props?.assignmentFileType)}
            alt=""
            className={styles.attachmentImage}
          />
          <p className={`${styles.file_name} ${styles.link_type}`}>
            {props.assignmentFileType === "FILE"
              ? `${props?.fileName?.substr()}`
              : extractDomain(props?.link)}
          </p>
        </div>
        <p
          className={`${styles.file_name}  ${styles.link_type} ${
            props.assignmentFileType === "FILE" ? styles.file_type : null
          }`}
        >
          {props.assignmentFileType === "FILE"
            ? props?.file?.split("/").pop().split(".").pop()
            : `${props?.link?.substr()}`}
        </p>
        {/* <p className={styles.file_name}>{props.assignmentFileType === "FILE" ? props?.file?.size: null}</p> */}
      </div>
    ) : props.file === null || props.link === null ? null : (
      <div className={styles.attachment_container}>
        <div className={styles.attachment_file} onClick={() => handleClick()}>
          <img
            src={getIcon(props.assignmentFileType)}
            alt=""
            className={styles.attachmentImage}
          />
          <p className={`${styles.file_name} ${styles.link_type}`}>
            {props.assignmentFileType === "FILE"
              ? props.fileName
              : extractDomain(props?.link)}
          </p>
        </div>
        {typeof props?.file == "string" ? (
          <p className={`${styles.file_name} ${styles.link_type}`}>
            {props.assignmentFileType === "FILE"
              ? props?.file?.split("/").pop().split(".").pop()
              : props?.link?.substr()}
          </p>
        ) : (
          <p className={`${styles.file_name} ${styles.link_type}`}>
            {props.assignmentFileType === "FILE"
              ? props?.file?.type
              : props?.link?.substr()}
          </p>
        )}
        {props?.closeBtn ? (
          <p
            className={styles.file_name}
            style={{ cursor: "pointer" }}
            onClick={() => props.removeSubmittedFile(props?.assignmentFileType, props?.id)}
          >
            <img 
            alt=""
            src="/Image/assignment/Cross.svg"    
            />
          </p>
        ) : null}
      </div>
    )
  ) : (
    <div className={` ${styles.attachment_post_container}`}>
      <div className={styles.attachment_file}>
        <img
          src={getIcon(props.assignmentFileType)}
          alt=""
          className={styles.attachmentImage}
        />
        <div className={styles.post_attachment_details}>
          <p className={`${styles.post_file_name}  ${styles.link_type}`}>
            {props.assignmentFileType === "FILE"
              ? props.fileName
              : extractDomain(props?.link)}
          </p>
          <p
            className={
              props.assignmentFileType === "FILE"
                ? `${styles.post_file_desc} ${styles.post_file_desc_up}  ${styles.link_type}`
                : `${styles.post_file_desc}  ${styles.link_type}`
            }
          >
            {props.assignmentFileType === "FILE"
              ? props?.file?.split("/").pop().split(".").pop()
              : props?.link?.substr()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AttachmentContainer;
