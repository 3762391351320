import { React, Component } from "react";
import { connect } from "react-redux";
import MySubjectsComponent from "./MySubjectsComponent";
import { getClasses } from "../../../redux/actions/classes";

class MyClasses extends Component {
  constructor() {
    super();
    this.state = {
      showClassModal: false,
    };
  }
  ClassModalCloser = () => {
    this.setState({ showClassModal: false });
  };

  ClassModalOpener = () => {
    this.setState({ showClassModal: true });
  };
  componentDidMount = () => {
    this.props.getClasses();
  };
  render() {
    {
    }
    return (
      <MySubjectsComponent
        toggleChatList={this.props.toggleChatList}
        isChatListOpen={this.props.isChatListOpen}
        subjects={this.props.subjects}
        routeType={this.props.routeType}
        user={
          this.props?.user?.first_name && this.props?.user?.last_name
            ? this.props.user.first_name + " " + this.props.user.last_name
            : ""
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    subjects:
      state.classes.classes.map((each_class) => {
        return {
          id: each_class.id,
          name: each_class.name,
          grade: each_class.grade,
          subject: each_class.subject,
          code: each_class.code,
          class_owner: each_class.class_owner,
          description: each_class.description,
          image_url:
            each_class.cover_image || "/Image/DashImage/class-cover.png",
        };
      }) || [],
    routeType: "/class",
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClasses: () => dispatch(getClasses()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyClasses);
