import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../Profile.module.css";
import AddCategory from "./Modals/AddCategory";
import { useSelector } from "react-redux";

const CategoryFeed = ({ editPage }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const businessCategory = useSelector(
    (state) => state.profile.business_category
  );
  const businessCategoryView = useSelector(
    (state) => state.profile.business_category_view
  );

  return (
    <>
      <AddCategory edit={edit} setEdit={setEdit} />
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 20px 0px 0px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
            <img
              src="/Image/Business Profile/business_category.svg"
              alt="about"
              style={{ width: "20px" }}
            />
            <Typography
              sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
            >
              {t("profile.businessCategory")}
            </Typography>
          </Box>
          {editPage && (
            <div onClick={() => setEdit(true)}>
              <img
                src="/Image/Profile/edit.svg"
                style={{ height: 20, cursor: "pointer" }}
              />
            </div>
          )}
        </div>

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "15px 30px",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 8,
          }}
        >
          <img
            src={`/Image/CategoryIcons/${
              editPage ? businessCategory : businessCategoryView
            }.svg`}
            alt="business_category_image"
            style={{ width: "30px" }}
          />
          <Typography sx={{ color: "#606060" }}>
            {editPage ? businessCategory : businessCategoryView}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default CategoryFeed;
