import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { logoutUser } from "../redux/actions/login";

class Logout extends Component {
  signOut = () => {
    this.props.logoutUser();
    return <Redirect to="/signin" />;
  };

  componentWillMount = () => {
    this.signOut();
  };

  render() {
    return <div></div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  //mapDisptachToProps is used to dispatch actions
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(null, mapDispatchToProps)(Logout);
