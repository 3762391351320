import { Avatar, Box, Button, Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const DoneButton = ({
  closeModal,
  disabled = false,
  showDivider = true,
  type = "button",
  backMethod,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {showDivider && (
        <Divider sx={{ mb: 2, background: "rgba(0,0,0,0.08)" }} />
      )}
      <Box sx={{ textAlign: "right" }}>
        <Button
          onClick={backMethod}
          disableRipple
          variant="outlined"
          sx={{
            mb: 2,
            mr: 2,
            background: "#f2f2f2",
            ":hover": {
              background: "#e2e2e2",
              border: "none",
            },
            textTransform: "none",
            color: "#000",
            borderRadius: "10px",
            px: 4,
            border: "none",
          }}
        >
          {t("occasionModal.back")}
        </Button>
        <Button
          type={type}
          disabled={disabled}
          onClick={closeModal}
          disableRipple
          variant="contained"
          sx={{
            mb: 2,
            mr: 2,
            background: "#1e4387",
            ":hover": {
              background: "#1c3d72",
            },
            textTransform: "none",
            color: "#fff",
            borderRadius: "10px",
            px: 1.5,
            "& .MuiButton-startIcon": { marginRight: "7px" },
          }}
          startIcon={
            <Avatar
              variant="square"
              src="/Image/DashboardImages/postModImages/add_post_icon.svg"
              sx={{
                color: "#fff",
                width: "auto",
                height: "18px",
              }}
            />
          }
        >
          {t("common.addPost")}
        </Button>
      </Box>
    </>
  );
};

export default DoneButton;
