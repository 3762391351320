import { 
    CREATE_COURSE, 
    EDIT_COURSE, 
    GET_SUCCESS_MESSAGE, 
    GET_ALL_COURSES, 
    GET_ALL_CREATED_COURSES, 
    CLEAR_ALL_COURSES,
    DELETE_COURSE,
    UPDATE_COURSE_STATUS,
    GET_COURSE,
    GET_COURSE_FEED,
    GET_PUBLIC_COURSES,
    GET_ENROLLED_COURSES,
    PAYMENT_CREATE_COURSE,
    ENROLL_COURSE,
    UPDATE_CURRENT_COURSE_STATUS,
    MARK_AS_COMPLETED,
    GET_COURSE_PROGRESS,
    CREATE_SECTION,
    CREATE_LECTURE,
    DELETE_SECTION,
    DELETE_LECTURE,
    UPDATE_COURSE,
    UPDATE_SECTION,
    UPDATE_LECTURE
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const defaultState = {
    courses: [],
    allCourses: [],
    startCursor: null,
    endCursor: null,
    hasNextPage: true,
    createdCourses: [],
    currentCourse:null,
    courseFeed: [],
    enrolledCourses: [],
    publicCourses: [],
    paymentCreated: {}

}

let courseData = {};
let index = 0;
let successData = {};
let data = {};
let lectureIndex = null;
let sectionIndex = null;

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_COURSE:
            if(action.payload.classes.length>0){
                courseData = cloneDeep(state); 
                courseData.courseFeed.push(action.payload)
                courseData.currentCourse = action.payload;
                return courseData;
            } else {
                courseData = cloneDeep(state); 
                courseData.currentCourse = action.payload;
                return courseData;
            }
            
        case EDIT_COURSE:
            if(action.payload.classes.length > 0){
                data = {...state};
                data.currentCourse = action.payload // It's for when editing a course from CourseView
                index = data.courseFeed.findIndex((course) => course.id === action.payload.id);
                if(index !== -1) data.courseFeed[index] = action.payload;
                return data; 
            } else {
                data = {...state};
                data.currentCourse = action.payload // It's for when editing a course from CourseView
                index = data.courseFeed.findIndex((course) => course.id === action.payload.id);
                if (index !== -1) data.courseFeed.splice(index, 1);   
                return data;

            }
            
            
        case GET_SUCCESS_MESSAGE:
            successData = { ...state };
            successData.successMessage = action.payload;
            return successData;
        
        case GET_ALL_COURSES:
            data = { ...state };
            data.allCourses = action.payload;
            data.startCursor = null;
            data.endCursor = null;
            data.hasNextPage = false;
            return data;
                   
        case GET_ALL_CREATED_COURSES:
            data = { ...state };
            data.createdCourses = action.payload;
            data.startCursor = null;
            data.endCursor = null;
            data.hasNextPage = false;
            return data;

        case CLEAR_ALL_COURSES:
            data = cloneDeep(state);
            data.allCourses = [];
            data.createdCourses = [];
            data.publicCourses = [];
            data.enrolledCourses = [];
            data.courseFeed = [];
            data.startCursor = null;
            data.endCursor = null;
            data.hasNextPage = false;
            data.currentCourse = null;
            return data;
        
        case DELETE_COURSE:
            data = {...state};
            index = data.courseFeed.findIndex((course) => course.id === action.payload);
            if (index !== -1) data.courseFeed.splice(index, 1);   
            return data;
        
        case UPDATE_COURSE_STATUS:
            data = {...state};
            index = data.courseFeed.findIndex((course) => course.id === action.payload.id);
            if(index !== -1){ data.courseFeed[index].active = action.payload.active;}
            return data;  

        case UPDATE_CURRENT_COURSE_STATUS:
            data = {...state};
            data.currentCourse.active = action.payload.active            
            return data; 
        
        case GET_COURSE:
            data = { ...state };
            data.currentCourse = action.payload;
            return data;
        
        case GET_COURSE_FEED:
            data = { ...state };
            data.courseFeed = data.courseFeed.concat(action.payload.edges);
            data.startCursor = action.payload.pageInfo.startCursor;
            data.endCursor = action.payload.pageInfo.endCursor;
            data.hasNextPage = action.payload.pageInfo.hasNextPage;
            return data;
        
        case GET_PUBLIC_COURSES:
            data = {...state};
            data.publicCourses = action.payload;
            data.startCursor = null;
            data.endCursor = null;
            data.hasNextPage = false;
            return data;
        
        case GET_ENROLLED_COURSES:
            data = {...state};
            data.enrolledCourses = action.payload;
            data.startCursor = null;
            data.endCursor = null;
            data.hasNextPage = false;
            return data;
        
            case PAYMENT_CREATE_COURSE:
            return {
               ...state,
               paymentCreated: action.payload
            }
        case ENROLL_COURSE:
            // data = { ...state };
            data = cloneDeep(state);
            // this case is to be dealt later
            if(action.payload.enrollcourse.message === "Payment Failure captured Enrollment unsuccess."){
                data.currentCourse.is_enrolled = false;
            }
            else {
                data.currentCourse.is_enrolled = true;
                data.currentCourse.showWelcomeModal = true;
            } 
            return data;

        case MARK_AS_COMPLETED:
            data = cloneDeep(state);
            
            if (data?.currentCourse?.section[action.payload?.secIndex]?.lectures[action.payload?.lecIndex]?.id == action.payload?.lecID){
                data.currentCourse.section[action.payload.secIndex].lectures[action.payload.lecIndex].completed = action.payload.status;
                data.currentCourse.progress_bar = action.payload.progress;            
            }
            return data;

        // case GET_COURSE_PROGRESS:
        //     data = cloneDeep(state);
        //     data.currentCourse.progress_bar = action.payload;
        //     return data;

        case CREATE_SECTION:
            data = cloneDeep(state);
            data.currentCourse.section.push(action.payload);
            return data;
        
        case CREATE_LECTURE:
            data = cloneDeep(state);
            index = data.currentCourse.section.findIndex((section) => section.id === action.payload.sectionID);
            if(index !== -1) {
                data.currentCourse.section[index].lectures.push(action.payload.lectureData);
                data.currentCourse.section[index].total_lecs += 1; 
            }
            return data;

        case DELETE_SECTION:
            data = cloneDeep(state);
            index = data.currentCourse.section.findIndex((section) => section.id === action.payload);
            if (index !== -1) {
                data.currentCourse.section.splice(index, 1);
            }   
            return data;
            
        case DELETE_LECTURE:
            data = cloneDeep(state);
            sectionIndex = data.currentCourse.section.findIndex((section) => section.id === action.payload.sectionID);
            if(sectionIndex !== -1){
               lectureIndex = data.currentCourse.section[sectionIndex].lectures.findIndex((lec) => lec.id === action.payload.lectureID);
            }
            if (lectureIndex !== -1) {
                data.currentCourse.section[sectionIndex].lectures.splice(lectureIndex, 1);
            }   
            return data;

        case UPDATE_SECTION:
            data = cloneDeep(state);
            index = data.currentCourse.section.findIndex((section)=>section.id === action.payload.sectionID);
            if(index !== -1) {
                data.currentCourse.section[index] = action.payload.sectionData;
            }
            return data;

        case UPDATE_LECTURE:
            data = cloneDeep(state);
            sectionIndex = data.currentCourse.section.findIndex((section) => section.id === action.payload.sectionID);
            if(sectionIndex !== -1){
                lectureIndex = data.currentCourse.section[sectionIndex].lectures.findIndex((lec) => lec.id === action.payload.lectureID);
            }
            if (lectureIndex !== -1) {
                data.currentCourse.section[sectionIndex].lectures[lectureIndex] = action.payload.lectureData;
            }   
            return data;

        


        default:
            return { ...state };
    }
};
