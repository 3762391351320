import { CHANGE_CURRENT_LANGUAGE, COMMENT_DISLIKE, COMMENT_LIKE, GET_DASHBOARD_POST, POST_COMMENT, POST_DISLIKE, POST_LIKE, TRANSLATE_DATA } from "../constants/actionTypes";

const defaultState = {
  posts: [],
  currentLanguage: localStorage.getItem("i18nextLng"),
};
let postData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_POST:
      postData = { ...state };
      postData.posts = action.payload;
      return postData;
    case POST_LIKE:
      postData = { ...state };
      postData.newLike = action.payload;
      return postData;
    case POST_COMMENT:
      postData = { ...state };
      postData.newComment = action.payload;
      return postData;
    case COMMENT_LIKE:
      postData = { ...state };
      postData.newCommentLike = action.payload;
      return postData;
    case POST_DISLIKE:
      return { ...state };
    case COMMENT_DISLIKE:
      return { ...state };
    case TRANSLATE_DATA: 
      postData = {...state};
      postData.newTranslation = action.payload;
      return postData;
    case CHANGE_CURRENT_LANGUAGE:
      postData = {...state};
      postData.currentLanguage = action.payload;
      return postData;

    default:
      return state;
  }
};
