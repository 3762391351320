import {
  GET_INDIVIDUAL_ASSIGNMENT,
  GET_ALL_ASSIGNMENTS,
  CREATE_CLASS_ALL_ASSIGNMENTS,
  GET_ASSIGNMENT,
  GET_STUDENT_ASSIGNMENT_LIST,
  GET_STUDENT_INDIVIDUAL_ASSIGNMENT,
  GET_STUDENT_SUBMISSION,
  UPDATE_STUDENT_ASSIGNMENT_SOLUTION,
  UPDATE_CLASS_ASSIGNMENT,
  DELETE_CLASS_ASSIGNMENT,
  GET_ASSIGNMENT_PAGE,
  GET_ASSIGNMENT_SUBMISSION,
  POST_CREATE_ASSIGNMENT_SUBMISSION,
  PUT_ASSIGNMENT_SUBMISSION_UPDATE,
  NEW_REQUEST_FOR_ASSIGNMENT_SUBMISSION,
  GET_STUDENT_LIST,
  CREATE_CLASS_ASSIGNMENT,
  SUBMIT_ASSIGNMENT_SOLUTION,
  SAVE_ASSIGNMENT_MARKS,
  ADD_PRIVATE_COMMENT,
  REMOVE_STUDENT_SUBMISSION,
  GET_ALL_ASSIGNMENT_SUBMISSIONS,
  GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION,
  GET_ALL_FILTERED_ASSIGNMENT_SUBMISSIONS,
  RETURN_ASSIGNMENT_MARKS,
  PROGRESS_BAR_COUNT_ASG,
  GET_ALL_ASSIGNMENT_QUIZ,
  IS_LOADING,
  ERROR_STATUS_CODE,
  CLEAR_ASSIGNMENT_SUBMISSION,
  ASSIGN_STUDENTS_TO_ASSIGNMENT,
  UNASSIGN_STUDENTS_FROM_ASSIGNMENT,
  GRADE_ZERO,
  GET_INDIVIDUAL_ASSIGNMENT_PRE_SUBMISSION_DATA,
} from "../constants/actionTypes";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import axios from "axios";
import {
  GET_ALL_ASSIGNMENTS_API,
  GET_STUDENT_ASSIGNMENT_LIST_API,
  GET_STUDENT_INDIVIDUAL_ASSIGNMENT_API,
  GET_STUDENT_SUBMISSION_API,
  SUBMIT_ASSIGNMENT_SOLUTION_API,
  UPDATE_STUDENT_ASSIGNMENT_SOLUTION_API,
  POST_ASSIGNMENT_API,
  GET_ASSIGNMENT_DETAILS_API,
  PUT_ASSIGNMENT_DETAILS_API,
  GET_INDIVIDUAL_ASSIGNMENT_API,
  GET_STUDENT_LIST_API,
  UPDATE_ASSIGNMENT_API,
  DELETE_ASSIGNMENT_API,
  SAVE_ASSIGNMENT_MARKS_API,
  RETURN_ASSIGNMENT_MARKS_API,
  GET_ALL_ASSIGNMENT_SUBMISSIONS_API,
  GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_API,
  GET_ALL_ASSIGNMENT_QUIZ_API,
  ASSIGN_STUDENTS_API,
  UNASSIGN_STUDENTS_API,
  GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID_API,
  GRADE_ZERO_API,
} from "../constants/apis";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
// export const actionName = (data) => {
//   return async (dispatch) => {
//     const token = store.getState()?.profile?.user?.token;
//   };
// };

// --------------- Create Assignment ----------------------------

export const createAssignment = (formData, type) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    // const dataNew = { ...data };
    // delete dataNew.code;
    // if (Object.values(dataNew).every((field) => field === "")) {
    //     dispatch(handleErrorMessage("You can't submit an empty form"));
    //   } else {}

    // const dataNew = { ...formData };
    let data = new FormData();
    data.append("title", formData.title);
    data.append("details", formData.details);
    data.append("max_marks", formData.max_marks);
    data.append("due_date", formData.due_date);
    data.append("is_lock", formData.is_lock);
    data.append("assign_all", formData.assign_all);
    if (formData.url != null) {
      data.append("url", formData.url);
    }
    if (formData.file != null) {
      data.append("file", formData.file);
    }
    if (type == "group") {
      data.append("groups", formData.classes);
    } else {
      data.append("classes", formData.classes);
    }

    await axios({
      method: "post",
      url: `${POST_ASSIGNMENT_API}`,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: CREATE_CLASS_ASSIGNMENT,
            payload: response.data.data,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.assignmentCreatedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// --------------- Edit/Update Assignment ----------------------------

export const updateAssignment = (formData, type) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${UPDATE_ASSIGNMENT_API}${formData.id}/`;

  return async (dispatch) => {
    let data = new FormData();
    data.append("title", formData.title);
    data.append("details", formData.details);
    data.append("max_marks", formData.max_marks);
    data.append("due_date", formData.due_date);
    data.append("is_lock", formData.is_lock);
    if (formData.url != null) {
      data.append("url", formData.url);
    }
    if (formData.file != null) {
      data.append("file", formData.file);
    }
    if (type == "group") {
      data.append("groups", formData.classes);
    } else {
      data.append("classes", formData.classes);
    }

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: UPDATE_CLASS_ASSIGNMENT,
            payload: response.data.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.assignmentUpdatedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// --------------- DELETE Assignment ----------------------------

export const deleteAssignment = (assignment_id) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_ASSIGNMENT_API}${assignment_id}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch({ type: DELETE_CLASS_ASSIGNMENT, payload: assignment_id });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.assignmentDeletedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            i18n.t("errorMessageTranslations.cantDeleteError"),
            ": ",
            error
          )
        );
        return error;
      });
  };
};

// --------------- GET All Assignments ----------------------------

export const getAllAssignments = (classId, type) => {
  const token = store.getState()?.profile?.user?.token;
  let assignmentPayload;
  let idType = "";
  if (type === 'group') {
    idType = 'group_id'
  }
  else {
    idType = 'class_id'
  }
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  return async (dispatch) => {
    await axios
      .get(`${GET_ALL_ASSIGNMENTS_API}?${idType}=${classId}&?target=${lang}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {

        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const assignments = response?.data?.data.sort((a, b) => {
            return b.id - a.id;
          });

          dispatch({ type: GET_ALL_ASSIGNMENTS, payload: assignments });
        }
      })
      .catch(async (error) => {

        dispatch(handleErrorMessage(errorMessageFromResponse(error)))
        window.location = `/user`;
        return error;
      });
  };
};

// --------------- GET Individual Assignments ----------------------------

export const getIndividualAssignment = (assignment_id) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  const endpoint = `${GET_INDIVIDUAL_ASSIGNMENT_API}${assignment_id}/?target=${lang}/`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const assignment = response.data.data;
          dispatch({
            type: GET_INDIVIDUAL_ASSIGNMENT,
            payload: assignment,
          });
        }
      })
      .catch(async (error) => {
        if (error.response.status === 400) {
          dispatch({
            type: ERROR_STATUS_CODE,
            payload: error.response.status,
          })
        } else {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        }
        return error;
      });
  };
};

// --------------- GET Student List ----------------------------

export const getStudentList = (assignment_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_STUDENT_LIST_API}?assignment_id=${assignment_id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const student_list = response.data.data;
          dispatch({
            type: GET_STUDENT_LIST,
            payload: student_list,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// --------------------- Student -----------

export const studentIndividualAssignment = (id) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  return async (dispatch) => {
    await axios
      .get(`${GET_STUDENT_INDIVIDUAL_ASSIGNMENT_API}${id}/?target=${lang}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const assignmentInd = response?.data?.data;
          const existingPreSubData = store.getState()?.assignment?.individualAssignmentPreSubmissionData;

          if (existingPreSubData?.is_submitted || existingPreSubData?.is_submitted_late || existingPreSubData?.is_returned) {
            dispatch(getStudentSubmission(response?.data?.data?.submission_id));
          } else {
            dispatch({ type: REMOVE_STUDENT_SUBMISSION, payload: null });
          }
          dispatch({
            type: GET_STUDENT_INDIVIDUAL_ASSIGNMENT,
            payload: assignmentInd,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getStudentAssignmentList = (id, type) => {
  const token = store.getState()?.profile?.user?.token;
  let idType = "";
  if (type === "group") {
    idType = "group_id";
  } else {
    idType = "class_id";
  }
  return async (dispatch) => {
    await axios
      .get(`${GET_STUDENT_ASSIGNMENT_LIST_API}?${idType}=${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const assignments = response?.data?.data;
          dispatch({ type: GET_STUDENT_ASSIGNMENT_LIST, payload: assignments });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const submitAssignmentSolution = (submissionData) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  let percent = 0;
  // formData.append("assignment", submissionData.assignmentId);
  if (submissionData?.file != null) {
    formData.append(
      "file_submitted",
      submissionData?.file,
      submissionData?.file?.name
    );
  }
  if (submissionData?.url != null) {
    formData.append("url", submissionData.url);
  }
  return async (dispatch) => {
    dispatch({ type: IS_LOADING, payload: true });
    await axios({
      method: "put",
      url: `${SUBMIT_ASSIGNMENT_SOLUTION_API}${submissionData?.subId}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: formData,
      // onUploadProgress:(progressEvent) => {
      // const {loaded, total} = progressEvent;
      // percent = Math.floor((loaded * 100) / total)
      // console.log( `${loaded}kb of ${total}kb | ${percent}%` )
      // dispatch({ type: IS_LOADING, payload: true });
      // }
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const submission = response?.data?.data;
          dispatch({ type: SUBMIT_ASSIGNMENT_SOLUTION, payload: submission });
          dispatch(
            handleSuccessMessage(
              i18n.t(
                "assignmentSuccessMessages.assignmentSubmittedSuccessfully"
              )
            )
          );
          dispatch({ type: IS_LOADING, payload: false });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        dispatch({ type: IS_LOADING, payload: false });
        return error;
      });
  };
};

export const getStudentSubmission = (submissionId) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(`${GET_STUDENT_SUBMISSION_API}${submissionId}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const submission = response?.data?.data;
          dispatch({ type: GET_STUDENT_SUBMISSION, payload: submission });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateStudentAssignmentSolution = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  if (data?.file != null && data?.file?.name) {
    formData.append("file_submitted", data?.file, data?.file?.name);
  }
  if (data?.url !== null) {
    formData.append("url", data.url);
  }

  return async (dispatch) => {
    dispatch({ type: IS_LOADING, payload: true });
    await axios({
      method: "put",
      url: `${UPDATE_STUDENT_ASSIGNMENT_SOLUTION_API}${data.submissionId}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: formData,
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const submission = response?.data?.data;
          dispatch({
            type: UPDATE_STUDENT_ASSIGNMENT_SOLUTION,
            payload: submission,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t(
                "assignmentSuccessMessages.assignmentSubmissionUpdatedSuccessfully"
              )
            )
          );
          dispatch({ type: IS_LOADING, payload: false });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        dispatch({ type: IS_LOADING, payload: false });
        return error;
      });
  };
};

export const saveAssignmentMarks = (formData) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    let data = new FormData();
    data.append("marks", formData.marks);

    const url = `${SAVE_ASSIGNMENT_MARKS_API}${formData.id}/`;

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: SAVE_ASSIGNMENT_MARKS,
            payload: response.data.data,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.assignmentGradedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const returnAssignmentMarks = (data) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    const url = `${RETURN_ASSIGNMENT_MARKS_API}`;

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: RETURN_ASSIGNMENT_MARKS,
            payload: data.submission_list,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.returnSuccessMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
// ------------------ get all assignment submissions

export const getAllAssignmentSubmissions = (assignment_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_ALL_ASSIGNMENT_SUBMISSIONS_API}?assignment_id=${assignment_id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const assignment_submissions = response.data.data;

          dispatch({
            type: GET_ALL_ASSIGNMENT_SUBMISSIONS,
            payload: assignment_submissions,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ------------------ Get All Filtered Assignment Submissions ---------

export const getAllFilteredAssignmentSubmissions = (assignment_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_ALL_ASSIGNMENT_SUBMISSIONS_API}?assignment_id=${assignment_id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const assignment_submissions = response.data.data;

          const filtered_submissions_list = assignment_submissions.filter(
            (item) =>
              item.submission_status === "SUB" ||
              item.submission_status === "DL"
          );

          dispatch({
            type: GET_ALL_FILTERED_ASSIGNMENT_SUBMISSIONS,
            payload: filtered_submissions_list,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------

export const getIndividualAssignmentSubmission = (submission_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_API}${submission_id}/`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const assignment_submission = response.data.data;
          dispatch({
            type: GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION,
            payload: assignment_submission,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const clearAssignmentSubmission = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_ASSIGNMENT_SUBMISSION, payload: {} });
  };
};

export const getAllAssignmentsQuizes = (id, type) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  return async (dispatch) => {
    await axios
      .get(`${GET_ALL_ASSIGNMENT_QUIZ_API}?${type}_id=${id}&?target=${lang}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const assignmentsQuizes = response?.data?.data;
          dispatch({
            type: GET_ALL_ASSIGNMENT_QUIZ,
            payload: assignmentsQuizes,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------- ZERO-LOGIC ACTIONS ------------------

export const assignStudentToAssignment = (studentList, assignmentId) => {
  const token = store.getState()?.profile?.user?.token;
  const existingStudentList = store.getState()?.assignment?.student_list;

  let data = new FormData();
  data.append("student_list", JSON.stringify(studentList));
  data.append("assignment", assignmentId);

  return async (dispatch) => {
    const url = `${ASSIGN_STUDENTS_API}`;

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          studentList.map((id) => {
            let index = existingStudentList.map((item) => item.students).indexOf(id);
            if (index != -1) {
              existingStudentList[index].submission_status = "AS";
              delete existingStudentList[index].new_member;
            }
          })

          dispatch({
            type: ASSIGN_STUDENTS_TO_ASSIGNMENT,
            payload: existingStudentList,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.studentsAssignedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

//--------------- unassign students ---------------

export const unassignStudentFromAssignment = (studentList) => {
  const token = store.getState()?.profile?.user?.token;
  const existingStudentList = store.getState()?.assignment?.student_list;

  let data = {
    "submission_list": studentList
  }

  return async (dispatch) => {
    const url = `${UNASSIGN_STUDENTS_API}`;

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          studentList.map((id) => {
            let index = existingStudentList.map((item) => item.id).indexOf(id);
            if (index != -1) {
              existingStudentList[index].submission_status = null;
              existingStudentList[index].submission_id = null;
              existingStudentList[index].new_member = true;
            }
          })

          dispatch({
            type: UNASSIGN_STUDENTS_FROM_ASSIGNMENT,
            payload: existingStudentList,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.studentsUnassignedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

//----------- GRADE ZERO -----------
export const gradeZero = (studentList) => {
  const token = store.getState()?.profile?.user?.token;
  const existingStudentList = store.getState()?.assignment?.student_list;

  let data = {
    "submission_list": studentList
  }

  return async (dispatch) => {
    const url = `${GRADE_ZERO_API}`;

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          studentList.map((id) => {
            let index = existingStudentList.map((item) => item.id).indexOf(id);
            if (index != -1) {
              existingStudentList[index].submission_status = "GRD";
              existingStudentList[index].marks = 0;
              existingStudentList[index].submission_date = null;
            }
          })

          dispatch({
            type: GRADE_ZERO,
            payload: existingStudentList,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.studentsGradedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


// ----------

export const getIndividualAssignmentSubmissionId = (asg_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID_API}?assignment_id=${asg_id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: GET_INDIVIDUAL_ASSIGNMENT_PRE_SUBMISSION_DATA,
            payload: response?.data?.data[0],
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};