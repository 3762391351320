import React, { useState, useEffect } from "react";
import styles from "./css/CourseFeed.module.css";
// import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
// import Modal from "../../../components/commons/Modal"
import CourseFeedPost from "./CourseFeedPost";
import PickPublicPrivateCourseModal from "../Modals/PickPublicPrivateCourseModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses, clearCourses } from "../../../redux/actions/courses";
import { thumbnailList } from "../../../redux/constants/commonFunctions";
import axios from "axios";
import { store } from "../../../redux/store";
import { getProductService } from "../../../redux/actions/groups";
import { POST_PRODUCT_SERVICE } from "../../../redux/constants/actionTypes";

const CourseFeed = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const toggleAddCourseModal = () => {
    setShowModal(!showModal);
  };

  //--- useSelector Section
  // const courses = useSelector((state) => state?.courses?.courseFeed) || [];
  const profileId = useSelector((state) => state?.profile?.profileId) || 0;
  const groupId = useSelector((state) => state?.groups?.group?.id) || 0;
  // const isFaculty = useSelector(
  //   (state) => state?.profile?.profileId == state?.classes?.class?.class_owner
  // );
  const hasNextPage = useSelector((state) => state?.courses?.hasNextPage);
  // const classId = props?.classId;
  const productData = useSelector((state) => state?.groups?.productService);
  let owner = store?.getState()?.profile?.profileId;
  let groupOwner = store?.getState()?.groups?.group?.group_owner;

  const handleScroll = (e) => {
    console.log("hasNextValue", hasNextPage);
    if (
      window.innerHeight + window.scrollY > document.body.scrollHeight - 20 &&
      hasNextPage != false
    ) {
      dispatch(getAllCourses(props?.classId, null, null, null, true));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const onSuccess = (response) => {};
    const onError = (error) => {};
    getProductService(groupId, onSuccess, onError);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasNextPage]);

  return (
    <div className={styles.myCoursesFeedContainer}>
      {showModal && (
        <PickPublicPrivateCourseModal
          handleClose={toggleAddCourseModal}
          courseFeedCourse={true}
        />
      )}

      {owner == groupOwner && (
        <div
          className={styles.addCourseCard}
          onClick={() => toggleAddCourseModal()}
        >
          <div className={styles.innerBorder}>
            <div className={styles.addCourseText}>
              <img
                alt=""
                src="/Image/CourseMonetisation/plus_icon.svg"
                className={styles.plus}
              />
              {/* <span style={{ marginLeft: 10 }}>{t("common.createNewCourse")}</span> */}
              <span style={{ marginLeft: 10 }}>
                {t("common.createNewProductService")}
              </span>
            </div>
          </div>
        </div>
      )}

      {productData?.length > 0
        ? productData?.map((course, index) => (
            <CourseFeedPost
              key={course?.id}
              group_id={course?.group_id}
              id={course?.id}
              coverImage={course?.thumbnail ?? thumbnailList[index % 13]}
              title={course?.name}
              subtitle={course?.subtitle}
              courseDescription={course?.description}
              classes={course?.classes}
              isActive={course?.active}
              isFree={course.free}
              mediaFiles={course?.files}
              urls={course?.urls}
              isInstructor={course?.owner?.id == profileId}
              isPublic={
                course?.product_service_type == "PRIVATE" ? false : true
              }
              price={course?.value}
              instructorName={course?.owner?.name}
              discounted_price={course?.discounted_price}
              enrolledStudentsCount={course?.members_count}
              isEnrolled={course?.is_enrolled}
              lectureCount={course?.total_lecs}
              courseLength={course?.course_mins}
              currentCourse={course}
            />
          ))
        : owner !== groupOwner && (
            <span style={{ textAlign: "center", width: "100%" }}>
              No Ecommerce
            </span>
          )}
    </div>
  );
};

export default CourseFeed;
