import { Card, Rating, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const RatingFeed = ({ editPage }) => {
  const averageProfileRatingView = useSelector(
    (state) => state.profile.average_profile_rating_view
  );
  const averageProfileRating = useSelector(
    (state) => state.profile.average_profile_rating
  );

  return (
    <Card
      elevation={1}
      sx={{
        padding: "25px 20px",
        borderRadius: "10px",
        backgroundColor: "#faf9f5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ color: "#9b9c9b", fontSize: "14px" }}>
        Overall Rating
      </Typography>
      <Typography sx={{ color: "#000000", fontSize: "22px", fontWeight: 800 }}>
        {editPage ? averageProfileRating : averageProfileRatingView}
      </Typography>
      <Rating
        name="read-only"
        value={editPage ? averageProfileRating : averageProfileRatingView}
        readOnly
        size="large"
        precision={0.1}
      />
      {/* <Typography sx={{ color: "#9b9c9b", fontSize: "12px" }}>
        Based on 510 reviews
      </Typography> */}
    </Card>
  );
};

export default RatingFeed;
