import React from "react";
import { Link } from "react-router-dom";
import TemplatePreviewCard from "./TemplatePreviewCard";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

import styles from "../../Profile.module.css";
import HandleApiError from "../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";

class ChooseTemplates extends React.Component {
  state = {
    templatePreview: false,
    templateIndex: 0,
    error: false,
    message: "",
  };

  changeTemplatePreviewState(st, tIdx) {
    this.setState({ templatePreview: st, templateIndex: tIdx });
  }

  templateViewImage = [
    "/Image/apps/profile-resume-edit-view/template1-preview.png",
    "/Image/apps/profile-resume-edit-view/template2-preview.png",
    "/Image/apps/profile-resume-edit-view/template3-preview.png",
    "/Image/apps/profile-resume-edit-view/template4-preview.png",
  ];

  render() {
    const { t } = this.props;

    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
          position: "fixed",
        }}
      >
        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => {
            this.setState({ error: false });
          }}
        />

        <div
          id={styles.template_modal}
          style={{
            backgroundColor: "#fff",
            padding: 30,
            borderRadius: 12,
            height: this.state.templatePreview ? "auto" : 320,
            position: "relative",
            // width: 'auto'
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #5555551A", paddingBottom: 20, alignItems: "center" }}>
            <p style={{ margin: 0, fontWeight: 600, fontSize: 16 }}>{this.state.templatePreview ? t("template.templatePreview") : t("template.chooseTemplate")}</p>
            {this.state.templatePreview ? (
              <a onClick={() => this.setState({ templatePreview: false })} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </a>
            ) : (
              <a
                onClick={() => {
                  document.body.style.overflow = "visible";
                  this.props.changeShowTemplatesState(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseIcon />
              </a>
            )}
          </div>

          <div
            className={styles.scroll_div}
            id={styles.scroll_div}
            style={{
              position: "relative",
              overflowY: "scroll",
              height: this.state.templatePreview ? "auto" : 290,
              display: this.state.templatePreview ? "block" : "flex",
              justifyContent: this.state.templatePreview ? "none" : "center",
            }}
          >
            {/* <div> */}

            {this.state.templatePreview ? (
              <div
                style={{
                  padding: 15,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img src={this.templateViewImage[this.state.templateIndex]} style={{ height: 450, width: "auto", marginBottom: 10, objectFit: "contain" }} />
                {this.props.mobileView ? (
                  <div
                    style={{
                      borderRadius: 20,
                      border: "2px solid #00d498",
                      textAlign: "center",
                      padding: "10px 0px",
                      backgroundColor: "#00d498",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ error: true, message: t("template.featureNotAvailableMsg")});
                    }}
                  >
                    <p style={{ margin: 0, color: "#fff", fontSize: 12, fontWeight: 500, textTransform: "uppercase" }}>{t("template.viewThisTemplate")}</p>
                  </div>
                ) : (
                  <Link to={`/profile/${window.location.pathname.slice(9, -5)}/template/${this.state.templateIndex + 1}`} style={{ textDecoration: "none" }} target="_blank">
                    <div
                      style={{
                        borderRadius: 20,
                        border: "2px solid #00d498",
                        textAlign: "center",
                        padding: "10px 0px",
                        backgroundColor: "#00d498",
                        cursor: "pointer",
                      }}
                    >
                      <p style={{ margin: 0, color: "#fff", fontSize: 12, fontWeight: 500, textTransform: "uppercase" }}>{t("template.viewThisTemplate")}</p>
                    </div>
                  </Link>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: 10,
                  flexWrap: "wrap",
                }}
              >
                {this.templateViewImage.map((img, idx) => {
                  return <TemplatePreviewCard key={idx} img={img} idx={idx} changeTemplatePreviewState={this.changeTemplatePreviewState.bind(this)} />;
                })}
              </div>
            )}
          </div>

          {/* </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    short_bio: state?.profile?.short_bio || "",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(ChooseTemplates));
