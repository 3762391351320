import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./groups";
import { handleSuccessMessage } from "./success";

////////////////////////////////////////////// user profile pic //////////////////////////////////////////////

export function fetchUserProfilePic(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/`, { headers: headers })
      .then((res) => {
        if (res.status == 200) {
          // setState({ abtContent: res.data })
          dispatch({
            type: action,
            payload: res.data.data.profile_pic == null ? "/Image/DashImage/profile.png" : res.data.data.profile_pic,
          });
          return true;
        }
        // alert(`Some error occured - ${res.data.message}`)
        return false;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateProfilePic(profilePic, action) {
  const profileId = store.getState()?.profile?.user?.profile_id;
  return async (dispatch) => {
    let data = new FormData();
    data.append("profile_pic", profilePic);

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${profileId}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status=="1") {
          // this.props.editExp(abtState.id, abtState.upload_img, abtState.title, abtState.description, abtState.start_date, abtState.end_date, abtState.employment_type, abtState.company)
          dispatch({
            type: action,
            payload: URL.createObjectURL(profilePic),
          });
          dispatch(handleSuccessMessage("Updated successfully"))
          // document.body.style.overflow = 'visible'
          return true;
        }
        // alert(`Some error occured - ${res.data}`)
        return false;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

////////////////////////////////////////////// user profile pic //////////////////////////////////////////////

export function fetchUserCoverPic(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/`, { headers: headers })
      .then((res) => {
        if (res.status == 200) {
          // setState({ abtContent: res.data })
          dispatch({
            type: action,
            payload: res.data.data.cover_pic == null ? "/Image/apps/profile-resume-edit-view/cover-image.png" : res.data.data.cover_pic,
          });
          return true;
        }
        // alert(`Some error occured - ${res.data.message}`)
        return false;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateCoverPic(coverPic, action) {
  const profileId = store.getState()?.profile?.user?.profile_id;
  return async (dispatch) => {
    let data = new FormData();
    data.append("cover_pic", coverPic);

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${profileId}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status=="1") {
          // this.props.editExp(abtState.id, abtState.upload_img, abtState.title, abtState.description, abtState.start_date, abtState.end_date, abtState.employment_type, abtState.company)
          dispatch({
            type: action,
            payload: URL.createObjectURL(coverPic),
          });
          dispatch(handleSuccessMessage("Updated successfully"))

          // document.body.style.overflow = 'visible'
          return { val: true };
        }
        // alert(`Some error occured - ${res.data}`)
        return { val: false, message: res.data.message };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
