import React, { Component } from "react";
import styles from "./css/BottomContainer.module.css";
import { withTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

class BottomContainer extends Component {
  state = {
    selectedOption: "Anyone",
    options: ["Anyone", "Groups Members", "Connection Only"],
    value: "",
    showOccasionModal: false,
  };

  changeActiveElement = (item) => {
    this.setState({ selectedOption: item });
  };

  iconButtonStyles = {
    cursor: "pointer",
    padding: "10px 10px",
    position: "relative",
    mb: 1,
  };

  properButtonStyles = {
    textTransform: "none",
    color: "#fff",
    borderRadius: "10px",
    px: 1.5,
    "& .MuiButton-startIcon": { marginRight: "7px" },
    minWidth: "0px",
    paddingRight: { xs: "4px", sm: "12px" },
    py: { xs: 1, sm: 0.5 },
  };

  render() {
    const source = localStorage.getItem("source");
    return (
      <>
        <div className={styles.container}>
          <Box>
            {(this.props.actionType == "EDIT" &&
              this.props.pic === null &&
              this.props.video === null &&
              this.props.file === null &&
              this.props.pollPost === null &&
              this.props.expertPost === null &&
              this.props.sellPost === null) ||
            (this.props.actionType == "ADD" &&
              this.props.pic === null &&
              this.props.video === null &&
              this.props.file === null &&
              this.props.pollPost === null &&
              this.props.expertPost === null &&
              this.props.sellPost === null) ? (
              <div className={styles.innerBox}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <Button
                    // onClick={(e) => this.props.fileInput(e)}
                    disableRipple
                    size="small"
                    sx={{
                      background: "#7155a3",
                      ":hover": {
                        background: "#7155a3",
                      },
                      ...this.properButtonStyles,
                    }}
                    startIcon={
                      <Avatar
                        variant="square"
                        src="/Image/DashboardImages/postModImages/photo_icon.svg"
                        sx={{ color: "#fff", width: "auto", height: "18px" }}
                      />
                    }
                  >
                    <input
                      onChange={(e) => this.props.fileInput(e)}
                      ref={this.props.inputRef}
                      type="file"
                      accept="image/*"
                      className={styles.fileUpload}
                    />
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      Photo
                    </Box>
                  </Button>
                  <Button
                    onClick={this.props.EventModalOpener}
                    disableRipple
                    size="small"
                    sx={{
                      background: "#ef476f",
                      ":hover": {
                        background: "#ef476f",
                      },
                      ...this.properButtonStyles,
                    }}
                    startIcon={
                      <Avatar
                        variant="square"
                        src="/Image/DashboardImages/postModImages/event_post_icon.svg"
                        sx={{ color: "#fff", width: "auto", height: "18px" }}
                      />
                    }
                  >
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      Event
                    </Box>
                  </Button>
                  <Button
                    onClick={() => this.props.setShowSellModal(true)}
                    disableRipple
                    size="small"
                    sx={{
                      background: "#f6851f",
                      ":hover": {
                        background: "#f6851f",
                      },
                      ...this.properButtonStyles,
                    }}
                    startIcon={
                      <Avatar
                        variant="square"
                        src="/Image/DashboardImages/postModImages/deal_icon.svg"
                        sx={{ color: "#fff", width: "auto", height: "18px" }}
                      />
                    }
                  >
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      Deals
                    </Box>
                  </Button>
                  {source == "DASHBOARD" && (
                    <Button
                      onClick={this.props.ExpertModalOpener}
                      disableRipple
                      size="small"
                      sx={{
                        background: "#537dbf",
                        ":hover": {
                          background: "#537dbf",
                        },
                        ...this.properButtonStyles,
                      }}
                      startIcon={
                        <Avatar
                          variant="square"
                          src="/Image/DashboardImages/postModImages/expert_icon_post.svg"
                          sx={{ color: "#fff", width: "auto", height: "18px" }}
                        />
                      }
                    >
                      <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        Expert
                      </Box>
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    mt: 1,
                  }}
                >
                  <Tooltip title="Add a video" placement="top">
                    <IconButton
                      sx={{
                        ...this.iconButtonStyles,
                        background: "#507737",
                        ":hover": { background: "#507737" },
                      }}
                    >
                      <input
                        onChange={this.props.videoInput}
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        className={styles.fileUpload}
                      />
                      <img
                        src="/Image/DashboardImages/postModImages/video_icon.svg"
                        alt="post video"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Celebrate an occasion" placement="top">
                    <IconButton
                      onClick={this.props.OccasionModalOpener}
                      sx={{
                        ...this.iconButtonStyles,
                        background: "#febe11",
                        ":hover": { background: "#febe11" },
                      }}
                    >
                      <img
                        src="/Image/DashboardImages/postModImages/celebration_icon.svg"
                        alt="post occasion"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Create a poll" placement="top">
                    <IconButton
                      onClick={this.props.PollModalOpener}
                      sx={{
                        ...this.iconButtonStyles,
                        background: "#079396",
                        ":hover": { background: "#079396" },
                      }}
                    >
                      <img
                        src="/Image/DashboardImages/postModImages/poll_icon.svg"
                        alt="post occasion"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add a document" placement="top">
                    <IconButton
                      sx={{
                        ...this.iconButtonStyles,
                        background: "#9e2b2b",
                        ":hover": { background: "#9e2b2b" },
                      }}
                    >
                      <input
                        onChange={this.props.mediaFileInput}
                        type="file"
                        accept=".doc,.docx,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        className={styles.fileUpload}
                      />
                      <img
                        src="/Image/DashboardImages/postModImages/document_icon.svg"
                        alt="post occasion"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </div>
            ) : null}
          </Box>

          <div className={styles.rightInner}>
            <Button
              onClick={
                this.props.actionType == "EDIT"
                  ? this.props.postEdit
                  : this.props.actionType == "DELETE"
                  ? this.props.postDelete
                  : this.props.onPost
              }
              disableRipple
              size="small"
              sx={{
                background:
                  this.props.actionType === "DELETE" ? "red" : "#1e4387",
                ":hover": {
                  background:
                    this.props.actionType === "DELETE" ? "red" : "#1e4387",
                },
                ...this.properButtonStyles,
                paddingRight: "12px",
              }}
              startIcon={
                <Avatar
                  variant="square"
                  src={
                    this.props.actionType === "DELETE"
                      ? "/Image/Connections/Asset 4.svg"
                      : "/Image/DashboardImages/postModImages/add_post_icon.svg"
                  }
                  sx={{
                    color: "#fff",
                    width: "auto",
                    height: "18px",
                  }}
                />
              }
            >
              {this.props.actionType == "EDIT"
                ? this.props.t("common.edit")
                : this.props.actionType == "DELETE"
                ? this.props.t("common.delete")
                : this.props.t("common.addPost")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(BottomContainer);
