import React, { Component, Suspense, lazy } from "react";
import { headerVisible } from "../../../redux/actions/zoomAction";
import { connect } from "react-redux";

// ...

class ZoomPage extends Component {
  componentDidMount() {
    this.props.headerVisible(true);
  }

  render() {
    const Zoom = lazy(() => import("./Zoom"));
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Zoom props={this.props} />
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    headerVisible: (val) => {
      dispatch(headerVisible(val));
    },
  };
};
export default connect(null, mapDispatchToProps)(ZoomPage);
