import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AssignmentHeading from "../../components/assignmentAndQuiz/AssignmentHeading";
import AssignmentListIndividual from "../../components/assignmentAndQuiz/AssignmentListIndividual";
import IconTitle from "../../components/commons/IconTitle";
import {
  getAllAssignments,
  getStudentAssignmentList,
} from "../../redux/actions/assignment";
import { 
  getAllQuizzes, 
  getStudentQuizList 
} from "../../redux/actions/quiz";
import { getStatusDescription } from "../../redux/constants/commonFunctions";
import styles from "./css/StudentAssignmentList.module.css";
import { CircularProgress } from "@mui/material";

function StudentAssignmentList(props) {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  // const class_owner = location?.state?.isClassOwner;
  // const group_owner = location?.state?.isGroupOwner;
  const class_name = location?.state?.class_name;
  const class_id = location?.state?.class_id;
  const routeType = location?.state?.routeType;
  const isAsg = props.assignmentType === "ASSIGNMENT"? true : false; 
  const isOwner =  location?.state?.isOwner;
  // isOwner checks for groupOwner in case of groups and ("FACULTY" type) in case of class.
  // class_name would be class or group, already passed that condition <UpcomingAssignmentsComponent/> in classrightsidebar.js

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const assignmentListForStudent = useSelector((state)=>props.assignmentType === "ASSIGNMENT" ? state?.assignment?.assignmentList : state?.quiz?.quizList);
  const assignmentListForTeacher = useSelector((state)=>props.assignmentType === "ASSIGNMENT" ? state?.assignment.assignments : state?.quiz.quizzes);

  useEffect(() => {    
    if (isOwner) {
      isAsg ? dispatch(getAllAssignments(params.id, routeType)).then(()=>{setIsLoading(false)}) : dispatch(getAllQuizzes(params.id,routeType)).then(()=>{setIsLoading(false)});
    } else {
      isAsg ? dispatch(getStudentAssignmentList(params.id,routeType)).then(()=>{setIsLoading(false)}) : dispatch(getStudentQuizList(params.id,routeType)).then(()=>{setIsLoading(false)})     
    }
  }, []);

  const handleRedirect = (assignmentId) => {
    const type = isAsg? "assignment":"quiz";
    if (isOwner) {
      // history.push(`/assignment/view/${assignmentId}`);
      history.push({
        pathname: `/${type}/view/${assignmentId}`,
        state: { routeType: routeType, isOwner: isOwner },
      });
    } else {
      // history.push(`/student/assignment/${assignmentId}`);
      history.push({
        pathname: `/student/${type}/${assignmentId}`,
        state: { routeType: routeType, isOwner: isOwner },
      });
    }
  };

  return ( isLoading ? (
      <div className={styles.loader_style}>
        <CircularProgress sx={{color: "#00d498"}} size={50} />
      </div>
      ) :  
      <div className={styles.main_container}>
        <div className={styles.title_container}>
          {/* <AssignmentHeading title={t("assignment.assignment")} /> */}
          <IconTitle
            title={isAsg ? t("assignment.assignments"):t("quiz.quizzes")}
            img={isAsg ? "/Image/assignment/White/Assignment.svg" : "/Image/assignment/White/Quiz.svg"}
            notClickable={true}
            type="ASSIGNMENT"
          />
        </div>
        <div className={styles.assignment_class_container}>
          <div className={styles.assignment_class_name}>
            <h4 className={styles.assignment_class_head}>{class_name || ""}</h4>
          </div>
        </div>
        {isOwner ? (
          <div className={styles.assignment_list}>
            {assignmentListForTeacher?.map((assignment) => (
              <AssignmentListIndividual
                listType="TEACHER"
                id={assignment?.id}
                title={assignment?.title}
                dueDate={assignment?.due_date}
                assigned={assignment?.assigned}
                submitted={assignment?.submitted}
                graded={assignment?.graded}
                clickHandler={handleRedirect}
                isAsg={isAsg}
                key={assignment.id}
              />
            ))}
          </div>
        ) : (
          <div className={styles.assignment_list}>
            {assignmentListForStudent?.map((assignment) => (
              <AssignmentListIndividual
                listType="STUDENT"
                title={assignment?.title}
                dueDate={assignment?.due_date}
                status={getStatusDescription(assignment?.sub_status)}
                clickHandler={handleRedirect}
                id={assignment?.id}
                isAsg={isAsg}
                key={assignment.id}
              />
            ))}
          </div>
        )}
      </div>
  );
}

export default StudentAssignmentList;

// const mapStateToProps = (state,props) => {
//   //U can destructure here to get only the required attributes from the state
//   return {
//     assignmentListForStudent: props.assignmentType === "ASSIGNMENT" ? state?.assignment?.assignmentList : state?.quiz?.quizList,
//     assignmentListForTeacher: props.assignmentType === "ASSIGNMENT" ? state?.assignment.assignments : state?.quiz.quizzes,
//   };
// };

// function mapDispatchToProps(dispatch) {
//   return {
//     getStudentAssignmentList: (id, type) =>
//       dispatch(getStudentAssignmentList(id, type)),
//     getStudentQuizList: (id,type)=> dispatch(getStudentQuizList(id,type)),
//     getAllAssignments: (class_id, type) => dispatch(getAllAssignments(class_id, type)),
//     getAllQuizzes: (class_id, type) => dispatch(getAllQuizzes(class_id, type))
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(StudentAssignmentList);
