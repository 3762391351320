import React from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Nav from "./template/home/Nav/Nav";
import HomeView from "./template/home/HeroNew/Hero";
import ProtectedRoute, {
    ProtectedRouteWithHeader,
} from "./components/ProtectedRoute";
import HomePage from "./apps/VdoConf/Home";
import ZoomPage from "./apps/VdoConf/Zoom/ZoomPage";
import Dashboard from "./apps/Dashboard/Components/Dashboard";
import MapPage from "./apps/Discover/MapPage";
import Template1 from "./apps/Profile/pages/templates/Template1";
import Template2 from "./apps/Profile/pages/templates/Template2";
import Template3 from "./apps/Profile/pages/templates/Template3";
import Template4 from "./apps/Profile/pages/templates/Template4";
import { Feed as ProfileView } from "./apps/Profile/pages/view/index";
import { Feed as ProfileEdit } from "./apps/Profile/pages/edit/index";
import SearchInvitations from "./components/search/SearchInvitations";
import styles from "./apps/Profile/Profile.module.css";
import Class from "./apps/ClassesGroups/CreateClass/class";
import Group from "./apps/ClassesGroups/CreateGroup/group";
import Signup from "./template/Sign/Signup/Signup";
import Signin from "./template/Sign/Signin/Signin";
import Forgot_Password from "./template/Sign/Forgot_Password/Forgot_Password";
import ChangePassword from "./template/Sign/Forgot_Password/ChangePassword";
import ZoomSubscription from "./apps/VdoConf/ZoomSubscription/zoomSubscription";
import ZoomConfigure from "./apps/VdoConf/ZoomConfigure/ZoomConfigure";
import Settings from "./apps/ZoomSettings/Settings";
import NotificationsPage from "./apps/Dashboard/NotificationsPage/NotificationsPage";
import MobileBurger from "./apps/Dashboard/Components/Mobileburger";
import InviteStudents from "./apps/ClassesGroups/CreateClass/InviteStudents";
import ErrorFourZeroFour from "./components/ErrorFourZeroFour";
import MyClasses from "./apps/ClassesGroups/Classes/MyClasses";
import Academics from "./apps/Academics/Pages/Academics";
import MyGroups from "./apps/ClassesGroups/Groups/MyGroups";
import PublicCourses from "./apps/CourseMonetization/Pages/PublicCourses";
import Connections from "./components/commons/Connections";
import UnProtectedRouteWithoutHeader from "./components/UnProtectedRoute";
import { connect } from "react-redux";
import { getUserSettings } from "./redux/actions/zoomAction";
import CreateUpdateAssignmentFormComponent from "./apps/AssignmentAndQuiz/CreateUpdateAssignmentFormComponent";
import ViewAssignmentPage from "./apps/AssignmentAndQuiz/ViewAssignmentPage";
import ViewAssignmentStatus from "./apps/AssignmentAndQuiz/ViewAssignmentStatus";
import ViewAssignmentGradingPage from "./apps/AssignmentAndQuiz/ViewAssignmentGradingPage";
import StudentAssignmentDetailsPage from "./apps/AssignmentAndQuiz/StudentAssignmentDetailsPage";
import StudentAssignmentList from "./apps/AssignmentAndQuiz/StudentAssignmentList";
import UpcomingAssignmentsComponent from "./apps/AssignmentAndQuiz/Components/UpcomingAssignmentsComponent";
import OpenAsgFromMail from "./apps/AssignmentAndQuiz/Components/OpenAsgFromMail";
import CourseView from "./apps/CourseMonetization/Pages/CourseView/CourseView";
import FeatureVideosPage from "./template/FeatureVideos/FeatureVideosPage";
import CourseStreamPage from "./apps/CourseLecturePlan/CourseStreamPage";
import CreateCoursePage from "./apps/CourseLecturePlan/CreateCoursePage";
import ReviewCoursePage from "./apps/CourseLecturePlan/ReviewCoursePage";
import AddCourseContentPage from "./apps/CourseLecturePlan/AddCourseContentPage";
import EventpPage from "./apps/Discover/EventpPage";
import SalePage from "./apps/Discover/SalePage";
import BuisnessPage from "./apps/Discover/BuisnessPage";
import MyFavourites from "./apps/Discover/MyFavourites";
import MessagingPage from "./apps/Messaging/MessagingPage";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editpage: false,
            searchpage: false,
            zoomData: undefined,
            redirectToZoom: false,
        };

        this.setZoomData = this.setZoomData.bind(this);
        this.toggleChatList = this.toggleChatList.bind(this);

        this.ProfileVw = this.ProfileVw.bind(this);
        this.ProfileRedirect = this.ProfileRedirect.bind(this);
    }

    toggleChatList = () => {
        this.setState({ chatList: !this.state.chatList });
    };

    setEditPageState(editval) {
        this.setState({ editpage: editval });
    }

    setSearchPageState(editval) {
        this.setState({ searchpage: editval });
    }

    setZoomData(newData, create = 0) {
        if (newData) {
            newData.create = create;
            this.setState({ zoomData: newData, redirectToZoom: true });
        }
    }

    ProfileVw(e) {
        return (
            <div className={styles.mains}>
                <br />
                <div className={styles.feed}>
                    <ProfileView editpage={e.editpage} />
                </div>
            </div>
        );
    }

    ProfileEdt(e) {
        return (
            <div className={styles.mains}>
                <br />
                <div className={styles.feed}>
                    <ProfileEdit editpage={e.editpage} />
                </div>
            </div>
        );
    }

    Home() {
        return (
            <div className="App">
                <Nav />
                {/* <Hero /> */}
                <HomeView />
            </div>
        );
    }

    ProfileRedirect({ id }) {
        if (!localStorage?.userData) return <Redirect to="/signin" />;
        if (JSON.parse(localStorage?.userData)?.profile_id == id) {
            return <Redirect to={`/profile/${id}/edit`} />;
        } else {
            return <Redirect to={`/profile/${id}/view`} />;
        }
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <this.Home />
                        </Route>

                        <Route exact path="/sidebar">
                            <MobileBurger />
                        </Route>

                        <Route exact path="/features">
                            <Nav />

                            <FeatureVideosPage />
                        </Route>

                        <ProtectedRouteWithHeader exact path="/video">
                            {this.props.accountType == "PAID" ? (
                                <>
                                    <HomePage
                                        redirect={this.state.redirectToZoom}
                                        zoomData={this.state.zoomData}
                                        setZoomData={this.setZoomData}
                                    />
                                </>
                            ) : null}
                            {this.props.accountType == "FREE" ? (
                                <>
                                    <Redirect to={`/zoomconfiguration`} />
                                </>
                            ) : null}
                            {this.props.accountType == "" ? (
                                <>
                                    <Redirect to={`/subscription`} />
                                </>
                            ) : null}
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/subscription">
                            {this.props.accountType == "" ? (
                                <>
                                    <ZoomSubscription />
                                </>
                            ) : null}
                            {this.props.accountType == "FREE" ? (
                                <>
                                    <Redirect to={`/zoomconfiguration`} />
                                </>
                            ) : null}
                            {this.props.accountType == "PAID" ? (
                                <>
                                    <Redirect to={`/video`} />
                                </>
                            ) : null}
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/zoomconfiguration"
                        >
                            {this.props.accountType == "FREE" ? (
                                <>
                                    <ZoomConfigure />
                                </>
                            ) : null}
                            {this.props.accountType == "" ? (
                                <>
                                    <Redirect to={`/subscription`} />
                                </>
                            ) : null}
                            {this.props.accountType == "PAID" ? (
                                <>
                                    <Redirect to={`/video`} />
                                </>
                            ) : null}
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/settings">
                            <Settings />
                        </ProtectedRouteWithHeader>

                        <ProtectedRoute exact path="/chalkmeet">
                            <ZoomPage
                                zoomData={this.state.zoomData}
                                setZoomData={this.setZoomData}
                            />
                        </ProtectedRoute>

                        <Route exact path="/signup">
                            <Signup />
                        </Route>

                        <Route exact path="/share_referral/:id">
                            <Redirect to="/" />
                        </Route>

                        <Route exact path="/signin">
                            <Signin />
                        </Route>

                        <Route exact path="/forgotpassword">
                            <UnProtectedRouteWithoutHeader />
                            <Forgot_Password />
                        </Route>

                        <Route exact path="/reset_password">
                            <ChangePassword />
                        </Route>

                        <ProtectedRouteWithHeader exact path="/class/:id">
                            <Class />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/group/:id">
                            <Group />
                        </ProtectedRouteWithHeader>

                        <ProtectedRoute path="/invite">
                            <InviteStudents />
                        </ProtectedRoute>

                        <ProtectedRouteWithHeader exact path="/user">
                            <Dashboard userPost={false} />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/post/:idOfPost">
                            <Dashboard userPost={false} />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/user/:code">
                            <Dashboard userPost={false} />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/postandactivity/:idOfUser"
                        >
                            <Dashboard userPost={true} />
                        </ProtectedRouteWithHeader>

                        <ProtectedRoute exact path="/notifications">
                            <NotificationsPage />
                        </ProtectedRoute>

                        <ProtectedRouteWithHeader exact path="/connections">
                            <Connections />
                        </ProtectedRouteWithHeader>

                        <Route
                            exact
                            path="/profile/:id"
                            render={({ match }) => (
                                <this.ProfileRedirect id={match.params.id} />
                            )}
                        />

                        <ProtectedRouteWithHeader
                            exact
                            path="/profile/:id/view"
                        >
                            <this.ProfileVw />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/classes">
                            <div className="classGroupIndividually">
                                <MyClasses />
                            </div>
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/academics">
                            <Academics />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/discover">
                            <MapPage />
                        </ProtectedRouteWithHeader>
                        <ProtectedRouteWithHeader exact path="/discover/event">
                            <EventpPage />
                        </ProtectedRouteWithHeader>
                        <ProtectedRouteWithHeader exact path="/discover/sale">
                            <SalePage />
                        </ProtectedRouteWithHeader>
                        <ProtectedRouteWithHeader
                            exact
                            path="/discover/business"
                        >
                            <BuisnessPage />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/groups">
                            <div className="classGroupIndividually">
                                <MyGroups />
                            </div>
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader exact path="/courses">
                            <PublicCourses />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/profile/:id/edit"
                        >
                            <this.ProfileEdt editpage={true} />
                        </ProtectedRouteWithHeader>

                        {/* <ProtectedRouteWithHeader path="/search/results">
              <SearchGlobal props={this.props} />
            </ProtectedRouteWithHeader> */}

                        <ProtectedRouteWithHeader path="/myfavourites">
                            <MyFavourites />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader path="/search/invitations">
                            <SearchInvitations />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/profile/:id/template/1"
                        >
                            <Template1 />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/profile/:id/template/2"
                        >
                            <Template2 />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/profile/:id/template/3"
                        >
                            <Template3 />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/profile/:id/template/4"
                        >
                            <Template4 />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/create/:id"
                            hideMobileNav={true}
                        >
                            <CreateUpdateAssignmentFormComponent assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>
                        <ProtectedRouteWithHeader
                            exact
                            path="/quiz/create/:id"
                            hideMobileNav={true}
                        >
                            <CreateUpdateAssignmentFormComponent assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/view/:id"
                            hideMobileNav={true}
                        >
                            <ViewAssignmentPage assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/quiz/view/:id"
                            hideMobileNav={true}
                        >
                            <ViewAssignmentPage assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/status/:id"
                            hideMobileNav={true}
                        >
                            <ViewAssignmentStatus assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/quiz/status/:id"
                            hideMobileNav={true}
                        >
                            <ViewAssignmentStatus assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/grading/:id"
                            hideMobileNav={true}
                        >
                            <ViewAssignmentGradingPage assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/quiz/grading/:id"
                            hideMobileNav={true}
                        >
                            <ViewAssignmentGradingPage assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/upcoming"
                            hideMobileNav={true}
                        >
                            <UpcomingAssignmentsComponent />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/student/assignment/:id"
                            hideMobileNav={true}
                        >
                            <StudentAssignmentDetailsPage type="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>
                        <ProtectedRouteWithHeader
                            exact
                            path="/student/quiz/:id"
                            hideMobileNav={true}
                        >
                            <StudentAssignmentDetailsPage type="QUIZ" />
                        </ProtectedRouteWithHeader>
                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/list/:id"
                            hideMobileNav={true}
                        >
                            <StudentAssignmentList assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/quiz/list/:id"
                            hideMobileNav={true}
                        >
                            <StudentAssignmentList assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/assignment/create"
                            hideMobileNav={true}
                        >
                            <CreateUpdateAssignmentFormComponent
                                assignmentType="ASSIGNMENT"
                                assignmentClass="Laws of motion"
                            />
                        </ProtectedRouteWithHeader>

                        {/* --- openAsg and openQuiz from mail --- */}

                        <ProtectedRouteWithHeader
                            exact
                            path="/class/assignment/view/:id"
                            hideMobileNav={true}
                        >
                            <OpenAsgFromMail assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/class/quiz/view/:id"
                            hideMobileNav={true}
                        >
                            <OpenAsgFromMail assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/group/assignment/view/:id"
                            hideMobileNav={true}
                        >
                            <OpenAsgFromMail assignmentType="ASSIGNMENT" />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/group/quiz/view/:id"
                            hideMobileNav={true}
                        >
                            <OpenAsgFromMail assignmentType="QUIZ" />
                        </ProtectedRouteWithHeader>

                        {/* Course Monitization Routes */}

                        <ProtectedRouteWithHeader
                            exact
                            path="/course/view/:id"
                            hideMobileNav={true}
                        >
                            <CourseView />
                        </ProtectedRouteWithHeader>

                        <ProtectedRoute
                            exact
                            path="/course/stream/:id"
                            hideMobileNav={true}
                        >
                            <CourseStreamPage />
                        </ProtectedRoute>

                        <ProtectedRouteWithHeader
                            exact
                            path="/course/create/"
                            hideMobileNav={true}
                        >
                            <CreateCoursePage />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            k
                            path="/course/content/1"
                            hideMobileNav={true}
                        >
                            <AddCourseContentPage />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/course/review/:id"
                            hideMobileNav={true}
                        >
                            <ReviewCoursePage />
                        </ProtectedRouteWithHeader>

                        <ProtectedRouteWithHeader
                            exact
                            path="/messaging"
                            hideMobileNav={true}
                        >
                            <MessagingPage />
                        </ProtectedRouteWithHeader>

                        <Route component={ErrorFourZeroFour} />
                    </Switch>
                </Router>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getUserSettings: () => dispatch(getUserSettings()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
