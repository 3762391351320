import {
  CHANGE_CURRENT_LANGUAGE,
  COMMENT_DISLIKE,
  COMMENT_LIKE,
  GET_DASHBOARD_POST,
  GET_ERROR_MESSAGE,
  POST_COMMENT,
  POST_DISLIKE,
  POST_LIKE,
  TRANSLATE_DATA,
} from "../constants/actionTypes";
import axios from "axios";
import {
  COMMENT_LIKE_API,
  POST_COMMENT_API,
  POST_LIKE_API,
  TRANSLATE_DATA_API,
} from "../constants/apis";
import { store } from "../store";
import { errorMessageFromResponse } from "../constants/commonFunctions";

export const handleErrorMessage = (errorMessage) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessageFromResponse(errorMessage),
  };
};

export const getPosts = () => {
  return async (dispatch) => {
    const token = store.getState()?.profile?.user?.token;
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post?limit=100/`,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          const data = response.data.data;
          let posts = data.map((post) => {
            if (post.post_type === "Poll") {
              const newResponse = { ...post };
              if (typeof post.post_details.poll_options === "string") {
                post.post_details.poll_options = JSON.parse(
                  post.post_details.poll_options
                );
              }
              console.log(post.post_details.poll_options);
              const parsedData = post.post_details.poll_options;
              newResponse.post_details = {
                ...newResponse.post_details,
                poll_options: parsedData,
              };
              return newResponse;
            } else {
              return post;
            }
          });

          dispatch({ type: GET_DASHBOARD_POST, payload: posts });
          return response;
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };
};

export const postLike = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: POST_LIKE_API,
      data: {
        post_liked_to: data.postId,
        profile_liked_by: data.profileId,
        reaction: data.reaction,
        // reaction: 1,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: POST_LIKE, payload: response.data.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const postComment = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const name = store.getState()?.profile?.name;
  const profile_pic = store.getState()?.profile?.userProfilePic;
  const commentProfileDetail = { name, profile_pic };
  return async (dispatch) => {
    await axios({
      method: "post",
      url: POST_COMMENT_API,
      data: {
        profile_id: store.getState()?.profile?.user.profile_id,
        post_id: data.postId,
        description: data.description,
        date_and_time: data.dateTime,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          const commentPayload = {
            ...response.data.data,
            profile_id: commentProfileDetail,
            likedComment: null,
          };
          dispatch({ type: POST_COMMENT, payload: commentPayload });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const commentLike = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: COMMENT_LIKE_API,
      data: {
        comment_liked_to: data.commentId,
        profile_liked_by: store.getState()?.profile?.user.profile_id,
        reaction: 6,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: COMMENT_LIKE, payload: response.data.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const postLikeDelete = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: POST_LIKE_API + `${data}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: POST_DISLIKE, payload: response.data.status });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const commentLikeDelete = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: COMMENT_LIKE_API + `${data}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: COMMENT_DISLIKE, payload: response.data.status });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const translatePost = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "post",
      url: TRANSLATE_DATA_API,

      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        target: data.target,
        text: data.text,
      },
    })
      .then((response) => {
        if (response.data.status) {
          dispatch({ type: TRANSLATE_DATA, payload: response.data.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const changeCurrentLanguage = (lang) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_CURRENT_LANGUAGE, payload: lang });
  };
};
