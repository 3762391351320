import React, { useState } from 'react'
import CourseTitle from './CourseTitle';
import styles from "./css/CourseFeedPost.module.css"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InstructorName from "./InstructorName"
import StudentCount from "./StudentCount"
import ClassTag from './ClassTag';
import Modal from '../../../components/commons/Modal';
import CourseActionModal from '../Modals/CourseActionModal';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from "../../../../src/components/assignmentAndQuiz/Button/Button"
import CreateEditCourseModal from '../Modals/CreateEditCourseModal';
import { useDispatch } from 'react-redux';
import { deleteCourse, updateCourseStatus } from '../../../redux/actions/courses';
import {
  handleErrorMessage,
} from "../../../redux/actions/classes";
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CourseLength from './CourseLength';
import { store } from '../../../redux/store';
import { deleteEcommerce } from '../../../redux/actions/groups';

const CourseFeedPost = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const courseName = props.title ?? "";
  const courseSubtitle = props.subtitle ?? "";
  const coursePriceValue = props.price ?? 0;
  const courseCoverImage = props.coverImage;
  const courseDescription = props.courseDescription ?? "";
  const courseClasses = props.classes?.map((item) => item.name) || [""];

  const profileId = useSelector((state) => state?.profile?.profileId) || 0;
  const classes = useSelector((state) => state.classes.classes).filter(
    (item) => item.class_owner == profileId
  );

  const statusType = props.isActive ? "active" : "inactive";
  const actionsObject = { "edit": "common.edit", "delete": "common.delete", "active": "courseMonetisation.makeItActive", "inactive": "courseMonetisation.makeItInactive" };

  const [showActionModal, setShowActionModal] = useState(false);
  const [courseAction, setCourseAction] = useState("active")
  const { t } = useTranslation();
  const isInstructor = props?.isInstructor;

  const handleActionModal = () => {
    // setShowActionModal(!showActionModal)
    if (courseAction == "delete") {
      if (props?.enrolledStudentsCount == 0)
        dispatch(deleteCourse(props?.id)).then(() => setShowActionModal(false));
      else {
        dispatch(handleErrorMessage(t("courseMonetisation.cannotDeleteCourse")));
        setShowActionModal(false);
      }

    } else {
      dispatch(updateCourseStatus(props.id, !props.isActive))
      setShowActionModal(false);
    }
  }

  const handleCancel = () => {
    setShowActionModal(false)
  }

  const handleDelete = (id) => {
    dispatch(deleteEcommerce(id))
  }


  // --- Dropdown Menu section ---
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCourseAction = (act) => {
    handleClose();
    if (act === "edit") {
      // setShowModal(!showModal)
      history.push({
        pathname: '/course/create',
        state: {
          'edit': true,
          'courseID': props.id,
          'currentCourse': props.currentCourse,
          'courseType': props.isPublic ? 'Private' : 'Private',
          'courseTitleType': "Edit",
          'classes': classes,
        }
      })
    } else {
      setCourseAction(act);
      setShowActionModal(!showActionModal);
    }
  }

  console.log('line100', props.currentCourse)

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  }

  console.log('pppppp', props?.id)
  const handleEnrollOrView = () => {
    history.push({
      pathname: `/course/view/${props?.id}`,

    })
  }

  let owner = store?.getState()?.profile?.profileId;
  let groupOwner = store?.getState()?.groups?.group?.group_owner;
  console.log('line118', props.currentCourse)
  return (
    <div className={styles.course_card_container}>
      {showActionModal &&
        <Modal
          card={<CourseActionModal type={courseAction} onCancel={handleCancel} onConfirm={handleActionModal} />}>
        </Modal>
      }
      {showModal && (
        <CreateEditCourseModal
          courseId={props?.id}
          currentMediaFiles={props?.mediaFiles ?? []}
          currentUrls={props?.urls ?? []}
          courseTitleType={t("common.edit")}
          coursePriceValue={coursePriceValue}
          isCourseFreeEdit={coursePriceValue === 0 ? "FREE" : "PAID"}
          courseName={courseName}
          courseSubtitle={courseSubtitle}
          courseDescription={courseDescription}
          classTags={props.classes}
          courseType={props.isPublic ? t("common.Public") : t("common.Private")}
          closeModal={handleModal}
          show={showModal}
          isActive={props?.isActive}
        />
      )}
      <div className={styles.image_container} onClick={() => {
        //  handleEnrollOrView() 
      }} >
        <img src={courseCoverImage} alt="" className={styles.course_image} />
      </div>

      <div className={styles.bottom_container}>
        <div className={styles.course_header}>
          <CourseTitle title={props?.title} />
          {isInstructor && <div className={styles.dropdown}>
            <MoreHorizIcon
              style={{ cursor: "pointer", opacity: "0.6" }}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}

              PaperProps={{
                style: {
                  boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                  borderRadius: "10px",
                  fontFamily: 'Montserrat',
                },
              }}
            >
              {Object.keys(actionsObject).map(element => {
                if (element.includes('active')) {
                  if (statusType === element) return null;
                }
                return (<MenuItem onClick={() => handleCourseAction(element)} sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: '400',
                  fontSize: '12px',
                  color: '#000000',
                }} >
                  {t(actionsObject[element])}
                </MenuItem>)
              })}
              {/* <MenuItem onClick={()=>handleCourseAction("edit")}>
                    {t("common.edit")}
                  </MenuItem>
                  <MenuItem onClick={()=> handleCourseAction("delete")}>
                    {t("common.delete")}
                  </MenuItem>
                  <MenuItem onClick={()=> handleCourseAction("inactive")}>
                    {t("courseMonetisation.makeItInactive")}
                  </MenuItem> */}
            </Menu>
          </div>}
        </div>

        <div className={styles.text_container}>
          <span className={styles.subtitle}>{ReactHtmlParser(courseSubtitle)}</span>
          <span className={styles.description}>{ReactHtmlParser(courseDescription)}</span>
          <span className={styles.price}>{!props?.currentCourse?.isFree ? props?.currentCourse?.discounted_price > 0 ? <>< span style={{ textDecoration: 'line-through', marginRight: '10px' }}> Rs{props?.currentCourse?.value} </span>< span > Rs{props?.currentCourse?.discounted_price}  </span> </> : <span>{t("common.free")}</span> : t("common.free")}</span>
          {/* <InstructorName instructor={props?.instructorName}/> */}
          {/* <StudentCount count={props?.enrolledStudentsCount} /> */}
          {/* <CourseLength courseLength={props.courseLength ?? 0} lectureCount={props.lectureCount ?? 0} price={props?.price}
            discounted_price={props?.discounted_price} /> */}
        </div>
        {owner == groupOwner && <p style={{ position: 'absolute', top: '61%', right: '8%' }}>
          <img
            src="/Image/class/edit.svg"
            alt=""
            height={25}
            width={25}
            style={{ cursor: "pointer" }}
            onClick={() => handleCourseAction('edit')}
          />
        </p>}
        {owner == groupOwner && <p style={{ position: 'absolute', top: '60%', right: '20%' }}>
          <img
            src="/Image/Icons/delete.svg"
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(props?.id)}
          />
        </p>}

        {/* 
        <div className={styles.class_tag_container}>
          {owner !== groupOwner && <ClassTag curbList={true} courseClasses={courseClasses} /> :
        <div className={styles.enroll_btn_container} >
            <span className={styles.course_price_for_learner}>{props?.currentCourse?.value > 0 ? `Rs ${props?.currentCourse?.value}` : t("common.free")}</span> <span>{""}</span>

          </div>
        </div> */}
        {owner !== groupOwner && <div className={styles.payReserveButtonCont}>

          <Button
            type="solid"
            fullWidth={true}
            className={styles.payReserveButton}
            sx={{
              fontSize: '14px'
            }}
            label={!props.isEnrolled ? t("common.payAndReserve") : t("courseMonetisation.enroll")
            }
          // clickHandler={handleEnrollOrView}
          // disable={!props.isActive && !props.isEnrolled ? true : false}
          />
        </div>}

      </div>
    </div >
  )
}

export default CourseFeedPost;