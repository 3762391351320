import React, { Component } from "react";
import SaveButton from "../../../components/commons/SaveButton";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import { withTranslation } from "react-i18next";
import { Stack, Typography, Button } from "@mui/material";

class JoinGroupModal extends Component {
  render() {
    const { searchedGroup, code } = this.props;
    return (
      <div>
        <ModalWithHeader
          title={this.props.t("common.joinGroup")}
          closeHandler={this.props.shareModalCloser}
          show={this.props.show}
          style={{ backgroundColor: "white", padding: 20, maxWidth: 350 }}
        >
          <br />
          {!code && (
            <input
              type="text"
              placeholder={this.props.t("dashboard.groupCode")}
              onChange={(e) => this.props.changeJoinGroupCode(e)}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
                width: "90%",
              }}
            />
          )}
          {/* <SaveButton btnTitle={this.props.t("common.join")} onClick={this.props.addGroupMember} /> */}
          {!code && (
            <SaveButton
              btntitle="Search"
              onClick={(e) => this.props.addGroupMember(e)}
            />
          )}

          {searchedGroup?.id && (
            <Stack
              justifyContent="space-between"
              p={1}
              alignItems="center"
              direction="row"
              sx={{
                boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Stack direction="column">
                <Typography fontWeight={600} fontSize="large">
                  {searchedGroup?.name}
                </Typography>
                <Typography fontWeight={400} fontSize="small">
                  {searchedGroup?.description}
                </Typography>
              </Stack>
              <Button
                btntitle="Search"
                variant="outlined"
                sx={{
                  backgroundColor: "#F33070",
                  color: "#fff",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#F33070",
                  },
                }}
                onClick={() => this.props.joinGroupMember(searchedGroup?.id)}
              >
                Join
              </Button>
            </Stack>
          )}
        </ModalWithHeader>
      </div>
    );
  }
}

export default withTranslation()(JoinGroupModal);
