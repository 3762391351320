import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import translationEn from "./locales/en/translation.json";
import translationRu from "./locales/ru/translation.json";
import translationUz from "./locales/uz/translation.json";
import translationHi from "./locales/hi/translation.json";
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["en"];
const availableLanguages = ["en", "uz", "ru", "hi"];
const resources = {
  en: {
    translation: translationEn,
  },
  ru: {
    translation: translationRu,
  },
  uz: {
    translation: translationUz,
  },
  hi: {
    translation: translationHi,
  },
};
const options = {
  // order and from where user language should be detected
  order: ["localStorage", "navigator"],

  lookupLocalStorage: "i18nextLng",
};

i18n
  .use(Backend)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    detection: options,
    resources,
    // lng: "uz",
    // preload: ['en', 'ru', 'uz'],
    // partialBundledLanguages: true,
    // // preload: true,
    // ns: ['translation', 'dashboard'],
    // defaultNS: 'translation',
    // backend:{
    //   loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
    // },

    debug: true,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    react: { useSuspense: false },
  });

export default i18n;
