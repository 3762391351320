import React from "react";
import AboutFeed from "./AboutFeed";
import InterestsFeed from "./InterestsFeed";
import ExperienceFeed from "./ExperienceFeed";
import EducationFeed from "./EducationFeed";
import LicencesFeed from "./LicencesFeed";
import VolunteerFeed from "./VolunteerFeed";
import EndorsementsFeed from "./EndorsmentsFeed";
import AchievementsFeed from "./AchievementsFeed";
import MidDpicFeed from "./MidDpicFeed";
import EditDescFeed from "./EditDescFeed";
import styles from "../../../Profile.module.css";
import PostsFeed from "../../view/components/PostsFeed";
import FeaturedFeed from "../../view/components/FeaturedFeed";
import { connect } from "react-redux";
import CategoryFeed from "./CategoryFeed";
import OfferingFeed from "./OfferingFeed";

class LeftFeed extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          justifyContent: "center",
        }}
      >
        <div className={styles.leftbar_hamburger_view}>
          <div className={styles.dpic_inside_ham}>
            <MidDpicFeed editPage={true} />
          </div>

          <EditDescFeed editpage={true} mobileView={true} />
          <br />
        </div>

        <div
          id={this.props.userType === "INDIVIDUAL" ? "About" : "Business Info"}
          style={{ scrollMarginTop: 120 }}
        >
          <AboutFeed editpage={this.props.editpage} />
        </div>

        <br />

        {this.props.userType === "LOCAL BUSINESS" && (
          <>
            <div id="Business Category" style={{ width: "100%" }}>
              <CategoryFeed editPage={true} />
            </div>

            <br />

            <div id="Business Offering" style={{ width: "100%" }}>
              <OfferingFeed editPage={true} />
            </div>

            <br />
          </>
        )}

        {this.props.userType === "INDIVIDUAL" && (
          <>
            <div id="Last 5 Posts" style={{ width: "100%" }}>
              <PostsFeed editpage={true} />
            </div>

            <br />

            <div
              id="Photo Gallery"
              class={styles.Featured}
              style={{ width: "100%" }}
            >
              <FeaturedFeed editPage={true} />
            </div>

            <br />
          </>
        )}

        {this.props.userType === "LOCAL BUSINESS" && (
          <>
            <div
              id="Photo Gallery"
              class={styles.Featured}
              style={{ width: "100%" }}
            >
              <FeaturedFeed editPage={true} />
            </div>

            <br />

            <div id="Last 5 Posts" style={{ width: "100%" }}>
              <PostsFeed editpage={true} />
            </div>

            <br />

            <div
              id="License and Certification"
              style={{ scrollMarginTop: 120 }}
            >
              <LicencesFeed editpage={this.props.editpage} />
            </div>

            <br />
          </>
        )}

        {this.props.userType === "INDIVIDUAL" && (
          <>
            <div id="Hobbies & Interests" style={{ scrollMarginTop: 120 }}>
              <InterestsFeed />
            </div>

            <br />

            <div id="Professional Experience" style={{ scrollMarginTop: 120 }}>
              <ExperienceFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div
              id="Educational Qualification"
              style={{ scrollMarginTop: 120 }}
            >
              <EducationFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Volunteer Experience" style={{ scrollMarginTop: 120 }}>
              <VolunteerFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Skills" style={{ scrollMarginTop: 120 }}>
              <EndorsementsFeed editpage={this.props.editpage} />
            </div>

            <br />

            <div id="Achievements" style={{ scrollMarginTop: 120 }}>
              <AchievementsFeed editpage={this.props.editpage} />
            </div>

            <br />
          </>
        )}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    userType: state?.profile?.user?.user_type,
  };
};

export default connect(mapStateToProps, null)(LeftFeed);
