import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../apps/Profile/Profile.module.css";
import Header from "../../components/profile-header-explicit/Header";

class SearchInvitations extends React.Component {
  render() {
    return (
      <div className={styles.root_search}>
        <Header />

        <div className={`${styles.mains} ${styles.mains_search}`} style={{ marginTop: 120 }}>
          <div className={`${styles.search_feed}`}>
            <div
              // className={styles.profile_title}
              style={{ marginBottom: 10 }}
            >
              <a
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.icon_container}>
                      <img className={styles.inner_icon} src="/Image/apps/profile-resume-edit-view/about.svg" alt=" " />
                    </div>
                    <div>
                      <p className={styles.icon_text}>Invitations</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className={styles.invitation_content} style={{ paddingBottom: 0 }}>
              {this.props.history.location.state.invitationsContent.map((item, idx) => {
                return (
                  <div key={idx} className={styles.inner_invitation}>
                    <div style={{ display: "flex" }}>
                      <div className={`${styles.center_div} ${styles.inner_invitation_img}`}>
                        <img src={`/Image/search/search-avatar${idx + 1}.png`} height="60px" width="60px" style={{ borderRadius: 40 }} />
                      </div>
                      <div className={styles.flex_col_cen}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p className={styles.section_title}>
                              {item.heading}
                              <br />
                              <span className={styles.followers}>{item.subheading}</span>
                            </p>
                          </div>
                        </div>
                        <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                          {item.subsubheading}
                        </p>
                      </div>
                    </div>
                    <div className={styles.flex_sp}>
                      {/* <div>

                                                    <button
                                                        className={`${styles.btn} ${styles.connect_btn}`}
                                                    >
                                                        <p
                                                            className={styles.btn_text}
                                                        >connect</p>
                                                    </button>

                                                </div> */}
                      <div>
                        <button className={`${styles.btn} ${styles.connect_btn}`}>
                          <p className={styles.btn_text}>view profile</p>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div></div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchInvitations);
