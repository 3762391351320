import React, { Component } from "react";
import { range } from "lodash";
import ReactDataGrid from "react-data-grid";
import styles from "./classcss/invitestudents.module.css";

const defaultParsePaste = (str) => str.split(/\r\n|\n|\r/).map((row) => row.split("\t"));

class MyDataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.rows,
      topLeft: {},
      botRight: {},
    };

    // Copy paste event handler
    document.addEventListener("copy", this.handleCopy);
    document.addEventListener("paste", this.handlePaste);
  }

  componentWillUnmount() {
    this.removeAllListeners();
  }

  removeAllListeners = () => {
    document.removeEventListener("copy", this.handleCopy);
    document.removeEventListener("paste", this.handlePaste);
  };

  rowGetter = (i) => {
    const { rows } = this.state;
    return rows[i];
  };

  updateRows = (startIdx, newRows) => {
    this.setState((state) => {
      const rows = state.rows.slice();
      for (let i = 0; i < newRows.length; i++) {
        if (startIdx + i < rows.length) {
          rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] };
        }
      }
      return { rows };
    });
  };

  handleCopy = (e) => {
    e.preventDefault();
    const { topLeft, botRight } = this.state;

    // Loop through each row
    const text = range(topLeft.rowIdx, botRight.rowIdx + 1)
      .map(
        // Loop through each column
        (rowIdx) =>
          this.props.columns
            .slice(topLeft.colIdx, botRight.colIdx + 1)
            .map(
              // Grab the row values and make a text string
              (col) => this.rowGetter(rowIdx)[col.key]
            )
            .join("\t")
      )
      .join("\n");
    e.clipboardData.setData("text/plain", text);
  };

  handlePaste = (e) => {
    e.preventDefault();
    const { topLeft } = this.state;

    const newRows = [];
    const pasteData = defaultParsePaste(e.clipboardData.getData("text/plain"));

    pasteData.forEach((row) => {
      const rowData = {};
      // Merge the values from pasting and the keys from the columns
      this.props.columns.slice(topLeft.colIdx, topLeft.colIdx + row.length).forEach((col, j) => {
        // Create the key-value pair for the row
        rowData[col.key] = row[j];
      });
      // Push the new row to the changes
      newRows.push(rowData);
    });

    this.updateRows(topLeft.rowIdx, newRows);
  };

  onGridRowsUpdated = ({ fromRow, toRow, updated, action }) => {
    if (action !== "COPY_PASTE") {
      this.setState((state) => {
        const rows = state.rows.slice();
        for (let i = fromRow; i <= toRow; i++) {
          rows[i] = { ...rows[i], ...updated };
        }
        return { rows };
      });
    }
  };

  setSelection = (args) => {
    this.setState({
      topLeft: {
        rowIdx: args.topLeft.rowIdx,
        colIdx: args.topLeft.idx,
      },
      botRight: {
        rowIdx: args.bottomRight.rowIdx,
        colIdx: args.bottomRight.idx,
      },
    });
  };
  render() {
    const { rows } = this.state;
    return (
      <div>
        <ReactDataGrid
          columns={this.props.columns}
          rowGetter={(i) => rows[i]}
          rowsCount={rows.length}
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
          cellRangeSelection={{
            onComplete: this.setSelection,
          }}
          onCellSelected={(s) => this.setSelection({ topLeft: s, bottomRight: s })}
        />
      </div>
    );
  }
}

export default MyDataGrid;
