import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CourseHeader.module.css";
import StudentNameComponent from "../../AssignmentAndQuiz/Components/StudentNameComponent";
import ProgressComponent from "./ProgressComponent";
import { useHistory } from "react-router-dom";

const CourseHeader = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.main}>
      <img
        src="/Image/lecturePlan/chevron_left.svg"
        alt="angleLeft"
        className={styles.angleLeft}
      />
      <img
        src="/Image/HomeImages/logo-text-transparant.svg"
        alt="logo"
        className={styles.logo}
        onClick={() => history.push("/user")}
      />
      <span className={styles.title}>{props.title}</span>
      <div className={styles.progress}>
        <ProgressComponent progress={props.progress} />
        <span>{t("courseMonetisation.yourProgress")}</span>
      </div>
      <StudentNameComponent
        studentName={props.studentName}
        profilePic={props.profilePic}
      />
    </div>
  );
};

export default CourseHeader;
