import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PublicIcon from "@mui/icons-material/Public";
import GroupIcon from "@mui/icons-material/Group";
import { useTranslation } from "react-i18next";
import DoneButton from "../shared/ui/Modal/DoneButton";
import { ModalWithHeader } from "../shared/ui/Modal/ModalNew";

const VisibilityModal = ({
  closeModal,
  show,
  selectedOption,
  updateSelectedOption,
}) => {
  const { t } = useTranslation();
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 25,
    height: 25,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto #0074E4",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#0074E4",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 25,
      height: 25,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#0267C8",
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const styleRadioButton = {
    display: "flex",
    justifyContent: "space-between",
    color: "#000",
    padding: "10px 30px",
    mr: 0,
    ml: 0,
    ":hover": {
      background: "rgba(0,0,0,0.08)",
    },
  };

  const RadioButtonLabel = ({ Display, text1, text2 }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Display
          sx={{
            background: "rgba(0,0,0,0.08)",
            borderRadius: "100%",
            padding: "15px",
          }}
        />
        <div>
          <Typography sx={{ ml: 2, fontWeight: 500 }}>{text1}</Typography>
          {text2 != "" ? (
            <Typography
              sx={{
                ml: 2,
                fontSize: "14px",
                lineHeight: 1,
                color: "rgba(0,0,0,0.6)",
              }}
            >
              {text2}
            </Typography>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <Box sx={{ background: "#fff", borderRadius: "0px 0px 15px 15px" }}>
        <Divider sx={{ mb: 2, background: "rgba(0,0,0,0.08)" }} />
        <Typography
          sx={{ color: "#000", fontWeight: 500, padding: "10px 25px" }}
        >
          {t("visibilityModal.ques")}
        </Typography>
        <FormControl
          sx={{
            width: "100%",
            mb: 4,
          }}
        >
          <RadioGroup
            defaultValue="female"
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={selectedOption}
            onChange={updateSelectedOption}
          >
            <FormControlLabel
              value="Anyone"
              control={<BpRadio />}
              label={
                <RadioButtonLabel
                  Display={PublicIcon}
                  text1={t("visibilityModal.anyone")}
                  text2={t("visibilityModal.anyoneSubline")}
                />
              }
              labelPlacement="start"
              sx={{ ...styleRadioButton }}
            />
            {/* <FormControlLabel
                value="Connections only"
                control={<BpRadio />}
                label={
                  <RadioButtonLabel
                    Display={GroupIcon}
                    text1={t("visibilityModal.connections")}
                  />
                }
                labelPlacement="start"
                sx={{ ...styleRadioButton }}
              /> */}
          </RadioGroup>
        </FormControl>

        <DoneButton backMethod={closeModal} closeModal={closeModal} />
      </Box>
    </>
  );
};

export default VisibilityModal;
