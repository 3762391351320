import React, { useState } from "react";
import styles from "./css/yourmessage.module.css";
import OptionModal from "./OptionModal";

export default function YourMessage(props) {
    const { message, deleteMessage, id, updateMessages, channelId } = props;
    const [open, setOpen] = useState(false);

    function handleOptionClick() {
        setOpen(true);
    }

    function handleModalClose() {
        setOpen(false);
    }

    function handleListItemClick() {
        deleteMessage(id).finally(() => {
            updateMessages(channelId);
            setOpen(false);
        });
    }
    return (
        <div className={styles.outer}>
            <div className={styles.container}>
                {message}
                <img
                    onClick={handleOptionClick}
                    src="/Image/chat/options.svg"
                    style={{
                        marginLeft: "10px",
                        height: "13.64px",
                        cursor: "pointer",
                        width: "3.9px",
                    }}
                />
                <OptionModal
                    open={open}
                    handleClose={handleModalClose}
                    handleListItemClick={handleListItemClick}
                />
            </div>
        </div>
    );
}
