import React from "react";
import styles from "../../apps/Profile/Profile.module.css";

export default class ScrollDivBody extends React.Component {
  render() {
    return (
      <div id={styles.scroll_div} className={`${styles.scroll_div_class}`}>
        {this.props.body()}
      </div>
    );
  }
}
