import axios from "axios";
import { GET_MODEL_INTERESTS } from "../constants/actionTypes";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function fetchModelInterests(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/modelinterest/`,
        { headers: headers }
      )
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function fetchInterests(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/interest/`, {
        headers: headers,
      })
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: action,
            payload: res.data?.data?.interests,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateInterests(action, data, arrData, type = "add") {
  const modelInterest = store.getState()?.profile.modelInterests;
  const interestUpdated = store.getState()?.profile.interests;
  if (arrData != undefined && type != "del") {
    arrData.forEach((arrInd) => {
      const index = modelInterest.findIndex((modelInterest) => {
        return modelInterest.id == arrInd;
      });
      interestUpdated.push(modelInterest[index]);
    });
  } else if (arrData != undefined && type == "del") {
    while (interestUpdated.length > 0) {
      interestUpdated.pop();
    }
    arrData.forEach((arrInd) => {
      const index = modelInterest.findIndex((modelInterest) => {
        return modelInterest.id == arrInd;
      });

      interestUpdated.push(modelInterest[index]);
    });
  }
  if (data.interests == "") {
    interestUpdated.splice(0, interestUpdated.length);
  }

  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/interest/edit/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Updated successfully"));
          dispatch({
            type: action,
            payload: interestUpdated,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
