import React from "react";
import { withRouter } from "react-router-dom";
import desc from "../../templates/desc";
import styles from "../../../Profile.module.css";
import InfoCard from "../../../../../components/commons/InfoCard";
import { connect } from "react-redux";
import {
  sendConnectionRequest,
  acceptConnectionRequest,
} from "../../../../../redux/actions/connections";
import {
  SEND_CONNECTION,
  ACCPET_CONNECTION,
} from "../../../../../redux/constants/actionTypes";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import SocailFeed from "./SocialFeed";

class EditDescFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      showMenu: false,
      connect: false,
      profile_type: this.props.profile_type,
      profileLink: desc.website,
      phone: desc.phone,
      address: desc.residencecity + " " + desc.residencestate,
      email: desc.email,
      dob: desc.dob,

      username:
        this.props?.user?.first_name && this.props?.user?.last_name
          ? this.props.user.first_name + " " + this.props.user.last_name
          : "",
      designation: this.props?.user?.profile_id?.designation
        ? this.props.user.profile_id.designation
        : "Director-Corporate Affairs",
      organisation: "J kakehasti Services Pvt Ltd.",
      location: "Kolkata, West Bengal, India",
    };
  }

  sendConnections(profileIdView) {
    let data = { connectionState: 2 };
    this.props
      .sendConnectionRequest(SEND_CONNECTION, profileIdView, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
  }

  acceptConnections(connectionId) {
    let data = { connectionState: 1 };
    this.props
      .acceptConnectionRequest(ACCPET_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeConnect(editconnect) {
    this.setState({ editconnect: editconnect });
  }

  componentDidMount() {
    this.setState({
      designation: this.props.designation,
      organisation: this.props.organisation,
      location: this.props.location,
      connections: this.props.connections,
    });

    setTimeout(() => {
      this.setState({
        profile_type: this.props.profile_type || "DEFAULT_VALUE",
      });
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile_type !== this.props.profile_type) {
      this.setState({
        profile_type: this.props.profile_type || "DEFAULT_VALUE",
      });
    }
  }

  connectionStateSwitch(connectionState) {
    const { t } = this.props;
    switch (connectionState) {
      case 0:
        return (
          <Button
            sx={{
              backgroundColor: "#F33070",
              padding: "5px 20px",
              borderRadius: "16px",
              border: "1px solid #F33070",
              color: "#fff",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#F33070",
                color: "#fff",
              },
              fontWeight: 600,
            }}
          >
            <p
              onClick={() => this.sendConnections(this.props.profileIdView)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="/Image/Connections/Asset 5.svg"
                alt="accept"
                style={{ width: "16px" }}
              />
              {t("common.connect")}
            </p>
          </Button>
        );
      case 1:
        return (
          <Button
            sx={{
              backgroundColor: "#F33070",
              padding: "5px 20px",
              borderRadius: "16px",
              border: "1px solid #F33070",
              color: "#fff",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#F33070",
                color: "#fff",
              },
              fontWeight: 600,
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="/Image/Connections/Asset 5.svg"
                alt="accept"
                style={{ width: "16px" }}
              />
              {t("common.connected")}
            </p>
          </Button>
        );
      case 2:
        return (
          <Button
            sx={{
              backgroundColor: "#F33070",
              padding: "5px 20px",
              borderRadius: "16px",
              border: "1px solid #F33070",
              color: "#fff",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#F33070",
                color: "#fff",
              },
              fontWeight: 600,
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="/Image/Connections/Asset 5.svg"
                alt="accept"
                style={{ width: "16px" }}
              />
              {t("common.requestSent")}
            </p>
          </Button>
        );
      case 3:
        return (
          <Button
            sx={{
              backgroundColor: "#F33070",
              padding: "5px 20px",
              borderRadius: "16px",
              border: "1px solid #F33070",
              color: "#fff",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#F33070",
                color: "#fff",
              },
              fontWeight: 600,
            }}
          >
            <p
              onClick={() =>
                this.acceptConnections(this.props.connection_id_view)
              }
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="/Image/Connections/Asset 5.svg"
                alt="accept"
                style={{ width: "16px" }}
              />
              {t("common.acceptRequest")}
            </p>
          </Button>
        );
      case 4:
        return (
          <Button
            sx={{
              backgroundColor: "#F33070",
              padding: "5px 20px",
              borderRadius: "16px",
              border: "1px solid #F33070",
              color: "#fff",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#F33070",
                color: "#fff",
              },
              fontWeight: 600,
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="/Image/Connections/Asset 5.svg"
                alt="accept"
                style={{ width: "16px" }}
              />
              {t("common.myProfile")}
            </p>
          </Button>
        );
      default:
        return (
          <Button
            sx={{
              backgroundColor: "#F33070",
              padding: "5px 20px",
              borderRadius: "16px",
              border: "1px solid #F33070",
              color: "#fff",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#F33070",
                color: "#fff",
              },
              fontWeight: 600,
            }}
          >
            <p
              onClick={() => this.sendConnections(this.props.profileIdView)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="/Image/Connections/Asset 5.svg"
                alt="accept"
                style={{ width: "16px" }}
              />
              {t("common.connect")}
            </p>
          </Button>
        );
    }
  }

  render() {
    return (
      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfoCard
          mobileView={this.props.mobileView}
          designation={this.props.designation}
          location={this.props.location}
          organisation={this.props.organisation}
          connections={this.props.connections}
        />

        {/* view CODE */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 24,
          }}
        >
          <div
            className={styles.editdescfeed_dropdown}
            style={{ cursor: "pointer" }}
          >
            <div
              className={styles.editdescfeed_dropbtn}
              style={{ cursor: "pointer" }}
            >
              <div className={styles.buttonsContainer}>
                {this.connectionStateSwitch(this.props.connectionStateView)}
              </div>
            </div>
          </div>
        </div>

        {this.state.profile_type === "PUBLIC" && (
          <SocailFeed editpage={this.props.editpage} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user || "",
    dob: state?.profile?.dobView || "",
    organisation: state?.profile?.organisationView || "",
    designation: state?.profile?.designationView || "",
    location: state?.profile?.locationView || "",
    connections: state?.profile?.connectionsView || 0,
    sendConnectionRequest: state.profile?.sendConnectionRequest || [],
    profileIdView: state.profile.profileIdView,
    myConnections: state.profile?.connections || [],
    connectionStateView: state.profile?.connectionStateView || "",
    // acceptConnection: state.profile?.acceptConnection || [],
    connection_id_view: state.profile?.connection_id_view || "",
    profile_type: state?.profile?.profile_type || "PUBLIC",
  };
};
function mapDispatchToProps(dispatch) {
  return {
    sendConnectionRequest: (action, id, data) =>
      dispatch(sendConnectionRequest(action, id, data)),
    acceptConnectionRequest: (action, id, data) =>
      dispatch(acceptConnectionRequest(action, id, data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDescFeed))
);
// export default withRouter(EditDescFeed)
