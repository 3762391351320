import React from "react";
import GroupSidebar from "./groupsidebar";
import GroupRightSidebar from "./grouprightsidebar";
import "./groupcss/groupBurger.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ClassSidebar from "../CreateClass/classsidebar";
import { getGroupDetails } from "../../../redux/actions/groups";
import {
  changeActiveSection,
  getClassDetails,
} from "../../../redux/actions/classes";
import ClassRightSideBar from "../CreateClass/classrightsidebar";

class GroupBurger extends React.Component {
  state = {
    showQrModal: false,
    activeSection: "Post",
    showCareerModal: false,
    showShareModal: false,
    showCourseModal: false,
    samplePosts: [],
    subjectId: "",
    subjectName: "",
  };

  getActiveClassDetails = (subjectId) => {
    if (this.props.routeType === "Group") {
      // this.props.getGroupDetails(subjectId).then(() => {
      this.setState({ subjectId: this.props.subjectId });
      this.setState({ subjectName: this.props.subjectName });
      // });
    } else {
      // this.props.getClassDetails(subjectId).then(() => {
      this.setState({ subjectId: this.props.subjectId });
      this.setState({ subjectName: this.props.subjectName });
      // });
    }
  };
  componentDidMount = () => {
    const subjectId = this.props?.subjectId || "";
    this.getActiveClassDetails(subjectId);
  };

  openBurger = () => {
    document.getElementById("classComponent").style.display = "block";
    document.querySelector(".mobileBurger").classList.toggle("showBurger");
    document.getElementById("mobileBurg").style.display = "none";
  };
  changeActiveSection = (selectedSection) => {
    this.setState({ activeSection: selectedSection });
    this.props.changeActiveSection(selectedSection);
    this.openBurger();
  };

  render() {
  
    return (
      <div id="mobileBurg" className="mobileBurger">
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            justifyContent: "flex-end",
            // marginBottom: 10,
            margin: "15px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              height: "50",
            }}
          >
            <img
              onClick={this.openBurger}
              src="/Image/apps/profile-resume-edit-view/cross.svg"
            />
          </div>
        </div>

        <div>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            {/* <GroupSidebar />
            <GroupRightSidebar /> */}
            <ClassSidebar
              // numOfPosts={this.props.numOfPosts}
              numOfMembers={this.props.members.length}
              subjectName={this.props.subjectName}
              myCourses={this.props.myCourses}
              class_id={this.props.class_id}
              group_id={this.props.group_id}
              myClasses={this.props.myClasses}
              changeActiveSection={this.changeActiveSection}
              getActiveClassDetails={this.getActiveClassDetails}
              activeSection={this.state.activeSection}
              routeType={this.props.routeType}
              meetings={this.props.meetings}
            />
            <ClassRightSideBar
              getActiveClassDetails={this.getActiveClassDetails}
              subjectCode={this.props.subjectCode}
              routeType={this.props.routeType}
              class_id={this.props.class_id}
              group_id={this.props.group_id}
            />
            <div style={{ height: 20, width: "100vw" }}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  if (ownProps?.routeType === "Class") {
    return {
      user: state.profile.user,
      //imageCover: "/Image/DashImage/class-cover.png",
      subjectName: state?.classes?.class?.name || "",
      subjectCode: state?.classes?.class?.code || "",
      grade: state?.classes?.class?.grade || "",
      // numOfPosts: state?.classes?.posts.length,
      class_id: state?.classes?.class?.id || "",
      group_id: state?.groups?.group?.id || "",
      subject: state?.classes?.class?.subject || "",
      imageCover:
        state?.classes?.class?.cover_image ||
        "/Image/DashImage/class-cover.png",
      myClasses:
        state?.classes?.classes?.map((each_class) => {
          return { name: each_class.name, id: each_class.id };
        }) || [],
      myCourses: [...state?.classes?.courses] || [],
      members: state?.classes?.members,
      meetings: state?.classes?.meetings || [],
    };
  } else {

    return {
      user: state.profile.user || "",
      subjectName: state?.groups?.group?.name || "",
      subjectCode: state?.groups?.group?.code || "",
      user: state.profile.user || "",
      grade: state?.groups?.group?.grade || "",
      subject: state?.groups?.group?.subject || "",
      // numOfPosts: state?.groups?.posts.length,
      imageCover:
        state?.groups?.group?.cover_image || "/Image/DashImage/group-cover.png",
      myClasses:
        state?.groups?.groups?.map((each_group) => {
        
          return { name: each_group?.name, id: each_group?.id,group_type:each_group?.group_type,group_owner:each_group?.group_owner };
        }) || [],
      myCourses: [...state?.groups?.courses] || [],
      members: state?.groups?.members,
      class_id: state?.groups?.group?.id || "",
      group_id: state?.groups?.group?.id || "",
      meetings: state?.groups?.meetings || [],
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupDetails: (subjectId) => dispatch(getGroupDetails(subjectId)),
    getClassDetails: (subjectId) => dispatch(getClassDetails(subjectId)),
    changeActiveSection: (sectionName) =>
      dispatch(changeActiveSection(sectionName)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupBurger));
