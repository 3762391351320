import React, { useContext, Suspense, useEffect } from "react";
import "./CSS/BuisnessPage.css";
import { useState } from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { DiscoverContext } from "./DiscoverContext";
import BuisnessSidebar from "./BuisnessSidebar";

const BuisnessCard = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./BuisnessCard")), 1000);
  });
});

const BuisnessPage = () => {
  const [next, setNext] = useState(8);
  const { state, onPaginatedBusinessSearch, getLocalBusiness } =
    useContext(DiscoverContext);
  const [params, setParams] = useState(null);

  useEffect(() => {
    getLocalBusiness(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/users/getLocalBusiness?limit=25`,
      params,
      true
    );
  }, [params]);

  const handleMoreEvent = () => {
    if (next < state?.eventPosts?.length) setNext(next + 4);
  };

  return (
    <div className="buisness_page_container">
      <div className="buisness_page_sidebar">
        <BuisnessSidebar params={params} setParams={setParams} />
      </div>

      <div className="parent_buisness">
        <div className="buisness_top">
          <h1>Local Business Near You</h1>
        </div>
        <div className="buisness_bottom">
          {state?.localBusiness?.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={"/Image/apps/profile-resume-edit-view/no_result.jpeg"}
                style={{ width: "40%" }}
              />
            </Box>
          )}
          {state?.localBusiness?.map((item, index) => (
            <div key={index}>
              <Suspense
                fallback={
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={270} height={170} />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={250}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={30}
                      sx={{ fontSize: "1rem", marginTop: "20px" }}
                    />
                    <Skeleton variant="rounded" width={270} height={60} />
                  </Stack>
                }
              >
                <BuisnessCard business={item} />
              </Suspense>
            </div>
          ))}
        </div>

        {state?.localBusiness?.length >= 25 && (
          <div className="event_page_load_more_button">
            <Button
              variant="outlined"
              onClick={() => onPaginatedBusinessSearch(params)}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuisnessPage;
