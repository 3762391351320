import React, { useContext, Suspense, useEffect, useState } from "react";
import "./CSS/EventPage.css";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import EventSidebar from "./EventSidebar";
import { DiscoverContext } from "./DiscoverContext";

const EventCard = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./EventCard")), 1000);
  });
});

const EventpPage = () => {
  const { state, onPaginatedSearch, getCategoryPosts } =
    useContext(DiscoverContext);
  const [params, setParams] = useState(null);

  useEffect(() => {
    getCategoryPosts(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/post/getdashboardposts?post_type=EVENT&limit=25`,
      "EVENT",
      params,
      true
    );
  }, [params]);

  return (
    <div className="event_page_container">
      <div className="event_page_sidebar">
        <EventSidebar params={params} setParams={setParams} />
      </div>

      <div className="parent_event">
        <div className="events_top">
          <h1>Events Near You</h1>
        </div>

        <div className="events_bottom">
          {state?.eventPosts?.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={"/Image/apps/profile-resume-edit-view/no_result.jpeg"}
                style={{ width: "40%" }}
              />
            </Box>
          )}
          {state?.eventPosts?.map((event, index) => (
            <div key={index}>
              <Suspense
                fallback={
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={270} height={170} />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={250}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={50}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={30}
                      sx={{ fontSize: "1rem", marginTop: "20px" }}
                    />
                    <Skeleton variant="rounded" width={270} height={60} />
                  </Stack>
                }
              >
                <EventCard event={event} />
              </Suspense>
            </div>
          ))}
        </div>

        {state?.eventPosts?.length >= 25 && (
          <div className="event_page_load_more_button">
            <Button
              variant="outlined"
              onClick={() => onPaginatedSearch(params)}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventpPage;
