import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../../components/assignmentAndQuiz/Button/Button'
import { ModalWithHeader } from '../../Dashboard/shared/ui/Modal/Modal'
import styles from "../css/LinkModal.module.css"
class LinkModal extends Component {
  render() {
    return (
        <div>
        <ModalWithHeader title={this.props.t("assignment.addLink")} 
        closeHandler={this.props.shareModalCloser} show={this.props.show} 
        className={styles.link_modal} style={{ backgroundColor: "white", padding: 20, maxWidth: 350 }}>
          <br />
          <input type="text" placeholder={this.props.t("assignment.addUrl")} className={styles.input_link} 
          onChange={(e) => this.props.changeLink(e)}
          value={this.props?.linkVal}
          />
          <div className={styles.button_div}>
          <Button label={this.props.t("assignment.addLink")} type="solid" clickHandler={this.props.submitLink}/> 
          </div>
         
        </ModalWithHeader>
      </div>
    )
  }
}

export default withTranslation()(LinkModal)