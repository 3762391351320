import React from 'react'
import styles from "./css/CourseTitle.module.css"

// pass props.cross true and handleClose function, if you want cross

const CourseTitle = (props) => {
  return (
    <div className={styles.main}>
      <div className={styles.heading}>
        <div className={styles.img_container}>
          <img style={{ width: 16, height: 16 }} src="/Image/CourseMonetisation/booklet-line-2.svg" alt="" />
        </div>
        <span>{props?.title ?? ""}</span>
      </div>

      {props.cross &&
        <div className={styles.cross} onClick={props.handleClose}>
          <img alt="" src="/Image/assignment/Cross.svg" />
        </div>
      }

   </div>

  )
}

export default CourseTitle;