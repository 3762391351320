import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ModalWithoutHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/career.module.css";
// import Career from '././Image/DashboardImages/careerModalImages/careermodal.svg';

class CarrerModal extends Component {
  render() {
    return (
      <div>
        <ModalWithoutHeader
          maxWidth={this.props.maxWidth}
          closeHandler={this.props.closeModal}
          show={this.props.show}
        >
          <div className={styles.outerContainer}>
            <div className={styles.comingSoon}>
              {this.props.t("common.comingSoon")}
            </div>
            <span onClick={this.props.closeModal} className={styles.close}>
              <img src="/Image/cross.svg" />
            </span>
            <div className={styles.bottomContainer}>
              <img
                className={styles.diamond}
                src="/Image/DashboardImages/careerModalImages/careermodal.svg"
              />
              <h2 className={styles.title}>
                {this.props.t("modals.chalkmatesCareer")}
              </h2>
              <p className={styles.desc}>
                {this.props.t("modals.comingSoonText")}
              </p>
            </div>
          </div>
        </ModalWithoutHeader>
      </div>
    );
  }
}

export default withTranslation()(CarrerModal);
