import React from "react";
import styles from "./LectureItem.module.css";
import { useTranslation } from "react-i18next";
import { deleteLecture } from "../../../redux/actions/courses";
import { useDispatch } from "react-redux";

const LectureItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lectureIcon =
    props.fileType == "video"
      ? "Video.svg"
      : props.fileType == "pdf"
      ? "article-(teacher).svg"
      : props.fileType == "quiz"
      ? "quiz.svg"
      : "assignment-(teacher).svg";

  return (
    <div className={styles.main}>
      <div className={styles.lecture_title}>
        <div className={styles.lecture_first_name}>
          {`${props.fileType == "quiz" ? t("quiz.quiz") : t("common.lecture")} ${
            props.index + 1
          }: `}
        </div>
        <div className={styles.lecture_last_name}>
          <img src={`/Image/lecturePlan/${lectureIcon}`} />
          <span>{props.title}</span>
        </div>
      </div>

      <div className={props.isEditing ? styles.icons_disabled : styles.icons}>
        <img
          src={`/Image/lecturePlan/${props.isEditing ? "Edit-grey" : "Edit"}.svg`}
          onClick={props.isEditing ? null : ()=> props.onEdit(props.sectionID ,props.lecture)}
        />
        <img
          src={`/Image/lecturePlan/${props.isEditing ? "delete-bin-grey" : "delete-bin"}.svg`}
          onClick={props.isEditing ? null : () =>
            dispatch(deleteLecture(props.sectionID, props.lectureID))
          }
        />
      </div>
    </div>
  );
};

export default LectureItem;
