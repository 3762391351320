import React from "react";
import styles from "../../apps/Profile/Profile.module.css";
import SaveButton from "./SaveButton";
import ModalHeader from "./ModalHeader";
import ScrollDivBody from "./ScrollDivBody";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import qrstyles from "../QrModal/css/qrModal.module.css";
import QRCode from "qrcode.react";
import { withRouter } from "react-router-dom";
class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      contacts: [
        {
          icon: "/Image/Profile/contact_info_icons/profile url.svg",
          heading: t("profile.yourProfile"),
          subheading: window.location.href.replace(/edit/i, ""),
        },

        {
          icon: "/Image/Profile/contact_info_icons/address.svg",
          heading: t("common.address"),
          subheading: this.props.address,
        },

        {
          icon: "/Image/Profile/contact_info_icons/email.svg",
          heading: t("common.email"),
          subheading: this.props.email,
        },

        {
          icon: "/Image/Profile/contact_info_icons/dob.svg",
          heading:
            this.props.userType === "INDIVIDUAL"
              ? t("common.dob")
              : t("common.dateOfRegistration"),
          subheading: this.props.dob,
        },
      ],

      contactsView: [
        {
          icon: "/Image/Profile/contact_info_icons/profile url.svg",
          heading: t("profile.yourProfile"),
          subheading: window.location.href.replace(/view/i, ""),
        },

        {
          icon: "/Image/Profile/contact_info_icons/address.svg",
          heading: t("common.address"),
          subheading: this.props.addressView,
        },

        {
          icon: "/Image/Profile/contact_info_icons/email.svg",
          heading: t("common.email"),
          subheading: this.props.user_email,
        },

        {
          icon: "/Image/Profile/contact_info_icons/dob.svg",
          heading:
            this.props.userTypeView === "INDIVIDUAL"
              ? t("common.dob")
              : t("common.dateOfRegistration"),
          subheading: this.props.dobView,
        },
      ],
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      <div>
        <div className={qrstyles.qrImage}>
          <img
            src={
              this.props.profilePicUrlview || this.props.profilePicUrl
                ? this.props.editpage
                  ? this.props.profilePicUrl
                    ? this.props.profilePicUrl
                    : this.props.avatarImg
                    ? this.props.avatarImg
                    : "/Image/header/ddefault_img.svg"
                  : this.props.profilePicUrlview
                  ? this.props.profilePicUrlview
                  : this.props.avatarImgView
                  ? this.props.avatarImgView
                  : "/Image/header/ddefault_img.svg"
                : "/Image/header/ddefault_img.svg"
            }
            style={{ width: "60px", height: "60px" }}
          />
        </div>
        <div className={qrstyles.qrdetails}>
          <h3 style={{ textTransform: "capitalize" }}>
            {this.props.editpage ? this.props.name : this.props.nameView}
            <br />
          </h3>

          {/* <h4 style={{ fontStyle: "italic" }}>
            {this.props?.user?.user_subtype
              ? this.props.user.user_subtype
              : "Pet Lover, Traveller, Foodie"}
          </h4> */}
        </div>
        <div className={qrstyles.qrcode}>
          <QRCode
            value={`${window.location.origin}${
              "/profile/" + this.props.match.params.id + "/view"
            }`}
            id="qrCodeEl"
            size={111}
            style={{ height: "130px", width: "130px" }}
          />
          <p style={{ marginTop: "10px", color: "#555555" }}>
            {this.props.t("modals.scanCode")}
          </p>
        </div>
        {this.props.editpage
          ? this.state.contacts.map((item, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={item.icon} style={{ width: "25px" }} />
                  </div>
                  <div
                    style={{
                      margin: "8px 10px",
                    }}
                  >
                    <p
                      style={{
                        font: "normal normal 600 14px/16px Montserrat",
                        margin: "3px 0px",
                      }}
                    >
                      {item.heading}
                    </p>

                    {idx == 0 ? (
                      <a href={item.subheading} target="_blank" style={{}}>
                        {item.subheading}
                      </a>
                    ) : (
                      <p>{item.subheading}</p>
                    )}
                  </div>
                </div>
              );
            })
          : this.state.contactsView.map((item, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={item.icon} style={{ width: "25px" }} />
                  </div>
                  <div
                    style={{
                      margin: "8px 10px",
                    }}
                  >
                    <p
                      style={{
                        font: "normal normal 600 14px/16px Montserrat",
                        margin: "3px 0px",
                      }}
                    >
                      {item.heading}
                    </p>

                    {idx == 0 ? (
                      <a href={item.subheading} target="_blank" style={{}}>
                        {item.subheading}
                      </a>
                    ) : (
                      <p>{item.subheading}</p>
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={t("profile.contactInfo")}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    address: state?.profile?.address || "Address not found",
    dob: state?.profile?.dob || "dd/mm/yyyy",
    location: state?.profile?.location || "",
    email: state?.profile?.user?.email || "user@gmail.com",
    profileId: state?.profile?.profileId || "",
    addressView: state?.profile?.addressView || "Address not found",
    dobView: state?.profile?.dobView || "dd/mm/yyyy",
    locationView: state?.profile?.locationView || "",
    user_email: state?.profile?.user_email || "Email not found",
    profileIdView: state?.profile?.profileIdView || "",
    name: state?.profile?.name || "Vijaya Vajpai",
    address: state?.profile?.address || "Address not found",
    dob: state?.profile?.dob || "dd/mm/yyyy",
    location: state?.profile?.location || "",
    email: state?.profile?.user?.email || "user@gmail.com",
    profile_id: state?.profile?.user?.profile_id || "4",
    addressView: state?.profile?.addressView || "Address not found",
    dobView: state?.profile?.dobView || "dd/mm/yyyy",
    locationView: state?.profile?.locationView || "",
    user_email: state?.profile?.user_email || "user@gmail.com",
    profilePicUrlview: state.profile.userProfilePicView,
    avatarImgView: state.profile.avatarImgView,
    user: state.profile.user || "",
    nameView: state?.profile?.nameView || "",
    name: state?.profile?.name || "",
    profilePicUrl: state.profile.userProfilePic,
    avatarImg: state.profile.avatarImg,
    userType: state?.profile?.user?.user_type,
    userTypeView: state?.profile?.user_type_view,
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, null)(ContactModal))
);
