import {
  Box,
  Button,
  Card,
  CardMedia,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { DiscoverContext } from "./DiscoverContext";

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #000;
    color: #fff;
    border-radius: 20px;
  }
`;

const InterestedButton = ({ postId, profileId }) => {
  const { MarkInterested } = useContext(DiscoverContext);
  return (
    <Button
      onClick={() => MarkInterested(postId, profileId)}
      variant="contained"
      disableRipple
      sx={{
        background: "var(--primary-gradient)",
        textTransform: "capitalize",
        mb: 2,
        width: "80%",
        borderRadius: 2,
        color: "#fff",
        fontWeight: 600,
        ":hover": {
          background: "var(--primary-gradient)",
          boxShadow: "none",
        },
        mt: 1,
        boxShadow: "none",
        px: 2,
      }}
    >
      <CalendarMonthIcon sx={{ color: "#fff", mr: 0.5 }} />
      Interested
    </Button>
  );
};

const ItemCards = ({ place }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        ":hover": {
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 16px 0px",
        },
        height: "100%",
      }}
    >
      <CardMedia
        component="img"
        height="130"
        image={
          place?.profile_data?.profile_pic ||
          "/Image/Discover/Business/discover_business.png"
        }
        alt={place?.enterprise_name}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "60%",
          width: "100%",
        }}
      >
        <Box>
          <StyledTooltip title={place?.enterprise_name} placement="top">
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "20px",
                ml: 1,
                mr: 1,
                textAlign: "center",
                mt: 1,
                overflow: "hidden",
              }}
            >
              {place?.enterprise_name?.slice(0, 15)}{" "}
              {place?.enterprise_name?.length > 15 ? "..." : ""}
            </Typography>
          </StyledTooltip>
          <StyledTooltip title={place?.location} placement="top">
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "20px",
                ml: 1,
                mr: 1,
                mt: 1,
                textAlign: "center",
              }}
            >
              {place?.location?.slice(1, 30)}{" "}
              {place?.location?.length > 30 ? "..." : ""}
            </Typography>
          </StyledTooltip>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <InterestedButton profileId={place?.profile_id} />
        </Box>
      </Box>
    </Card>
  );
};

export const EventItemCards = ({ place }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        transition: ".5s ease all",
        ":hover": {
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 16px 0px",
        },
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%", maxHeight: "130px", overflow: "hidden" }}>
        <CardMedia
          component="img"
          height="130"
          image={
            place?.image_url || "/Image/Discover/Events/discover_event.png"
          }
          alt="event posts"
          sx={{
            ":hover": { transform: "scale(1.1)" },
            transition: ".5s ease all",
          }}
        />
      </Box>
      <StyledTooltip title={place?.post_details?.name} placement="top">
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "20px",
            m: 1,
            textAlign: "center",
            // minHeight: "6vh",
            overflow: "hidden",
          }}
        >
          {place?.post_details?.name?.slice(0, 15)}{" "}
          {place?.post_details?.name?.length > 15 ? "..." : ""}
        </Typography>
      </StyledTooltip>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "20px",
          ml: 1,
          mr: 1,
          textAlign: "center",
        }}
      >
        {place?.post_details?.start_date_time}
      </Typography>
      <InterestedButton postId={place?.id} />
    </Card>
  );
};

export const SellItemCards = ({ place }) => {
  console.log("linr172", place);
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        transition: ".5s ease all",
        ":hover": {
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 16px 0px",
        },
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%", maxHeight: "130px", overflow: "hidden" }}>
        <CardMedia
          component="img"
          height="130"
          image={
            place?.sell_images
              ? place?.sell_images[0]?.image
              : "/Image/Discover/Sale/discover_sale.png"
          }
          alt="sell posts"
          sx={{
            ":hover": { transform: "scale(1.1)" },
            transition: ".5s ease all",
          }}
        />
      </Box>
      <StyledTooltip title={place?.post_details?.title} placement="top">
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "20px",
            ml: 1,
            mr: 1,
            textAlign: "center",
            mt: 1,
            // minHeight: "6vh",
            // maxHeight: "6vh",
            overflow: "hidden",
          }}
        >
          {place?.post_details?.title?.slice(0, 15)}{" "}
          {place?.post_details?.title?.length > 15 ? "..." : ""}
        </Typography>
      </StyledTooltip>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "20px",
          ml: 1,
          mr: 1,
          textAlign: "center",
        }}
      >
        {place?.post_details?.free
          ? "Free"
          : `₹${
              place?.post_details?.discounted_price
                ? place?.post_details?.discounted_price
                : place?.post_details?.pricing
            }  ${
              place?.post_details?.discounted_price
                ? `· ${place?.post_details?.discount_offer.slice(0, 2)}% off`
                : ""
            }`}
      </Typography>

      <InterestedButton postId={place?.id} />
    </Card>
  );
};

export const GroupItemCards = ({ place }) => {
  console.log("line222", place);
  if (place?.photos && place.photos.length > 0) {
    var photoUrl = place.photos[0]?.getUrl({ maxWidth: 400, maxHeight: 400 });
  }

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        height="130"
        image={
          place.id % 2 == 0
            ? "/Image/DashImage/RightSidebar/group_1.png"
            : "/Image/DashImage/RightSidebar/group_2.png"
        }
        // image={photoUrl ? photoUrl : place?.icon}
        alt={place?.name}
      />

      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "20px",
          ml: 1,
          mr: 1,
          textAlign: "center",
          mt: 1,
          minHeight: "6vh",
          maxHeight: "6vh",
          overflow: "hidden",
        }}
      >
        {place?.name?.slice(0, 23)}
        {place?.name?.length <= 23 ? "" : "..."}
      </Typography>

      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "20px",
          ml: 1,
          mr: 1,
          textAlign: "center",
        }}
      >
        100 members
      </Typography>
      <Button
        variant="contained"
        disableRipple
        sx={{
          background: "var(--primary-gradient)",
          textTransform: "capitalize",
          mb: 2,
          width: "80%",
          borderRadius: 2,
          color: "#fff",
          fontWeight: 600,
          ":hover": {
            background: "var(--primary-gradient)",
            boxShadow: "none",
          },
          mt: 1,
          boxShadow: "none",
          px: 2,
        }}
      >
        <GroupOutlinedIcon sx={{ color: "#fff", mr: 0.5 }} />
        Join
      </Button>
    </Card>
  );
};

export const NeighbourItemCards = ({ person }) => {
  console.log("person:", person);
  const history = useHistory();

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: 4,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={
          person.profile_pic
            ? person.profile_pic
            : "/Image/header/ddefault_img.svg"
        }
        alt={person?.name}
        style={{ height: "130px", width: "130px" }}
      />

      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "20px",
          ml: 1,
          mr: 1,
          textAlign: "center",
          mt: 1,
          minHeight: "6vh",
          maxHeight: "6vh",
          overflow: "hidden",
        }}
      >
        {person?.name}
      </Typography>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "20px",
          ml: 1,
          mr: 1,
          textAlign: "center",
        }}
      >
        Nearby neighbor
      </Typography>
      <Button
        onClick={() => history.push(`/profile/${person.id}/edit`)}
        variant="contained"
        disableRipple
        sx={{
          background: "var(--primary-gradient)",
          textTransform: "capitalize",
          mb: 2,
          width: "80%",
          borderRadius: 2,
          color: "#fff",
          fontWeight: 600,
          ":hover": {
            background: "var(--primary-gradient)",
            boxShadow: "none",
          },
          mt: 1,
          boxShadow: "none",
          px: 2,
        }}
      >
        <PersonAddAlt1OutlinedIcon sx={{ color: "#fff", mr: 0.5 }} />
        Connect
      </Button>
    </Card>
  );
};

export default ItemCards;
