import React from "react";

import styles from "../../apps/Profile/Profile.module.css";

export default class SocialHandles extends React.Component {
  render() {
    return (
      <div className={styles.desc} style={{ marginTop: 8 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 8,
          }}
        >
          <a
            href={this.props.facebook || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.facebook ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/fb.svg"
              alt="facebook"
              style={{ width: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.linkedin || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.linkedin ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/twitter.svg"
              alt="Twitter"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.twitter || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.twitter ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/instagram.png"
              alt="Instagram"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.instagaram || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.instagaram ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/linkedIn.svg"
              alt="Linkedin"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>
          <a
            href={this.props.medium || "#"}
            style={{ textDecoration: "none" }}
            target={this.props.medium ? "_blank" : "_parent"}
          >
            <img
              src="/Image/Profile/website.svg"
              alt="website"
              style={{ height: 28, cursor: "pointer" }}
            />
          </a>

          <this.props.addSocial />
        </div>

        <this.props.socialModal />
      </div>
    );
  }
}
