import { Box, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

const ExpertDisplay = ({
  expertPost,
  ExpertModalOpener,
  show = true,
  UpdateExpertPost,
  purpose,
  location,
  actionType,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "8px 20px",
        mr: show === true ? 3 : 1,
        ml: 1,
        mb: 3,
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 500, display: "flex", alignItems: "center" }}
        >
          <img
            src="/Image/Discover/Events/Asset 6.svg"
            alt="profession icon"
            style={{
              width: "14px",
              marginRight: 8,
            }}
          />
          {purpose ? purpose : expertPost?.profession}
        </Typography>
        {show === true ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              disableRipple
              onClick={ExpertModalOpener}
              sx={{ background: "#666", padding: "5px" }}
            >
              <EditIcon fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
            {actionType !== "EDIT" && (
              <IconButton
                disableRipple
                onClick={() => UpdateExpertPost(null)}
                sx={{ background: "#666", ml: 1, padding: "5px" }}
              >
                <CloseIcon fontSize="small" sx={{ color: "#fff" }} />
              </IconButton>
            )}
          </Box>
        ) : null}
      </Box>
      <Box>
        <Typography sx={{ fontSize: "13px", display: "flex" }}>
          <img
            src="/Image/Discover/Events/Asset 7.svg"
            alt="location icon"
            style={{
              width: "9px",
              marginRight: 13,
            }}
          />
          <Box>{location ? location : expertPost?.location}</Box>
        </Typography>
        <Typography
          sx={{ fontSize: "13px", display: "flex", alignItems: "center" }}
        >
          <img
            src="/Image/Discover/Events/Asset 8.svg"
            alt="service icon"
            style={{
              width: "14px",
              marginRight: 8,
            }}
          />
          Looking for services
        </Typography>
      </Box>
    </Paper>
  );
};

export default ExpertDisplay;
