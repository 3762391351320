import SearchPlaceholder from "../../../components/chat/SearchPlaceholder";
import ConversationList from "./ConversationList";

function SearchView(props) {
    const { searchData, ...rest } = props;
    if (!searchData.length) {
        return <SearchPlaceholder />;
    }

    return <ConversationList data={searchData} {...rest} />;
}

export default SearchView;
