import React from "react";
import { withRouter } from "react-router";
import styles from "../../apps/Profile/Profile.module.css";
import FollowButton from "./FollowButton";
import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { viewMyConnections } from "../../redux/actions/connections";
import { GET_PEOPLE_YOU_MAY_KNOW } from "../../redux/constants/actionTypes";
import { MY_CONNECTIONS } from "../../redux/constants/actionTypes";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

class ConnectionRightFeedComp extends React.Component {
  componentDidMount() {
    let token = JSON.parse(localStorage.getItem("userData"))?.token;
    this.props
      .fetchPeopleYouMayKnow(GET_PEOPLE_YOU_MAY_KNOW, token)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
    this.props.viewMyConnections(MY_CONNECTIONS, token).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }

  render() {
    return (
      <div
        id="People You May Know"
        className="people-div"
        style={{
          scrollMargin: 120,
        }}
      >
        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              mt: 1,
              p: "0px 15px",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <img
                src="/Image/DashImage/RightSidebar/connect_icon.svg"
                alt="fire icon"
                style={{ width: "15px" }}
              />
              <Typography
                sx={{ fontWeight: "600", color: "#535353", fontSize: "15px" }}
              >
                {this.props.t("connections.myConnections")}
              </Typography>
            </Box>
            <MoreHorizIcon />
          </Box>
          {this.props.peopleYouMayKnowContent.slice(0, 3).map((item, idx) => {
            return (
              <div
                key={idx}
                className={`${styles.col} ${styles.interestIndividual}`}
              >
                <div
                  className="hovme"
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={item.profile_pic || "/Image/header/ddefault_img.svg"}
                      height="60px"
                      width="60px"
                      style={{
                        borderRadius: 40,
                        margin: "5px 10px 5px 0px",
                      }}
                    />

                    <p className={`${styles.hovmep} ${styles.section_title}`}>
                      {item.name}
                    </p>
                  </Box>

                  <FollowButton id={item.id} t={this.props.t} />
                </div>
              </div>
            );
          })}
          <div
            className={styles.sidebar_stat1}
            onClick={() => this.props.history.push("/connections")}
          >
            <p style={{ fontSize: "14px", color: "#396dd4" }}>
              <span style={{ marginLeft: 5 }}>
                {this.props.t("dashboard.seeConnections")}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //U can destructure here to get only the required attributes from the state
  return {
    peopleYouMayKnowContent: state.profile?.peopleYouMayKnow || [],
    myConnections: state.profile?.myConnections || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
    viewMyConnections: (action, token) =>
      dispatch(viewMyConnections(action, token)),
  };
}

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ConnectionRightFeedComp)
  )
);
