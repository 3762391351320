import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function addCertification(laeState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("name", laeState.name);
    data.append("description", laeState.description);
    data.append("issue_date", laeState.issue_date);
    data.append("expiry_date", laeState.expiry_date);
    data.append("issuer", laeState.issuer);
    data.append("certification_id", laeState.certification_id);
    data.append("certification_url", laeState.certification_url);
    data.append("profile_id", JSON.parse(localStorage.getItem("userData")).profile_id);
    data.append("upload_img", laeState.pictureAsFile, laeState.pictureAsFile.name);

    // validations
    if (laeState.issue_date == null || laeState.expiry_date == null) {
      return { val: false, message: "Error at adding certification - Empty issue date and expiry date" };
    } else if (laeState.issue_date > laeState.expiry_date) {
      return { val: false, message: "Error at adding certification - issue date must be less than expiry date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/certification/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Added successfully!"))
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateCertification(laeState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("name", laeState.name);
    data.append("description", laeState.description);
    data.append("issue_date", laeState.issue_date);
    data.append("expiry_date", laeState.expiry_date);
    data.append("issuer", laeState.issuer);
    data.append("certification_id", laeState.certification_id);
    data.append("certification_url", laeState.certification_url);
    if (laeState.uploadImageFlag) {
      data.append("upload_img", laeState.pictureAsFile, laeState.pictureAsFile.name);
    }

    // validaitions
    if (laeState.issue_date == null || laeState.expiry_date == null) {
      return { val: false, message: "Error at adding certification - Empty issue date and expiry date" };
    } else if (laeState.issue_date > laeState.expiry_date) {
      return { val: false, message: "Error at adding certification - issue date must be less than expiry date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/certification/${laeState.id}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300  && res.data.status=="1") {
          let value = Object.fromEntries(data.entries());
          if (laeState.uploadImageFlag) {
            value = {...value, id: laeState.id, upload_img: URL.createObjectURL(laeState.pictureAsFile)}
          }
          else{
            value = {...value, id: laeState.id, upload_img: laeState.pictureAsFile}
          }
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated successfully!"))
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
