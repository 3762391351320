import "../Sign.css";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../redux/actions/forgotPassword";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SignNav from "../../home/Nav/SignNav";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

function Forgot_Password() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  function onSubmit(data) {
    dispatch(forgotPassword(data.email))
  }
  return (
    <>
      <SignNav />
      <img src="Image/signbgLeft.svg" alt="#" className="fp-bg" />
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ ml: 5, mr: { xs: 8, lg: 0 }, pr: { xs: 0, lg: 8 } }}
        >
          <Box sx={{ height: "90vh", display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 5,
              }}
            >
              <div className="sign-heading">
                <div>{t("signin.forgotPassword")}</div>
                <div>{t("signin.forgotPasswordDesc")}</div>
              </div>
              <form className="forgot-input" onSubmit={handleSubmit(onSubmit)}>
                <input
                  className="sign-field"
                  {...register("email", {
                    required: true,
                    pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                  })}
                  type="text"
                  placeholder={t("common.email")}
                />
                <div className={`input-alert`}>
                  {errors.email?.type === "required" && t("errors.emailError")}
                </div>
                <div className={`input-alert`}>
                  {errors.email?.type === "pattern" && t("errors.validEmail")}
                </div>

                <button className="button sign-btn">
                  {t("signin.sendResetLink")}
                </button>
              </form>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={0}
          md={5}
          sx={{
            mr: 4,
            display: { xs: "none", md: "block" },
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "15vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 8.png"
                alt="sign photo"
                style={{ width: "70%", borderRadius: "20px", marginRight: 20 }}
              />
              <img
                src="/Image/signImage/Asset 7.png"
                alt="sign photo"
                style={{ width: "85%", borderRadius: "20px" }}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "5vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 9.svg"
                alt="sign photo"
                style={{ width: "40%", borderRadius: "20px" }}
              />
              <img
                src="/Image/signImage/Asset 6.png"
                alt="sign photo"
                style={{ width: "90%", borderRadius: "20px" }}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "center",
                width: "100%",
                mt: 5,
              }}
            >
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "26px", fontWeight: 600, color: "#ed2f75" }}
              >
                {t("signup.bottomLine2")}{" "}
              </Typography>
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine3")}{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine2")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(forgotPassword(email)),
  };
};

export default connect(null, mapDispatchToProps)(Forgot_Password);
