import React from "react";
import "./CSS/SaleCardNew.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { DiscoverContext } from "./DiscoverContext";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

const SaleCardNew = ({ sell, removeFromWishlist = false, id, params }) => {
  const { MarkInterested, RemoveFromWishlist } = useContext(DiscoverContext);

  const ButtonClick = () => {
    if (removeFromWishlist) {
      RemoveFromWishlist(id, true, params);
    } else {
      MarkInterested(sell?.id);
    }
  };

  return (
    <div className="sale_page_sale_card_container">
      <div classname="sale_page_sale_card_top">
        <img
          className="sale_page_sale_card_image"
          src={
            sell?.sell_images?.length !== 0 && sell?.sell_images !== null
              ? sell?.sell_images[0]?.image
              : "/Image/Discover/Sale/discover_sale.png"
          }
          alt="event"
          style={{ width: "100%", height: "23vh", objectFit: "cover" }}
        />
      </div>

      <div classname="sale_page_sale_card_mid">
        <div className="sale_page_sale_time_date">
          <span className="sale_page_sale_date">
            {sell?.post_details?.free ? (
              <Typography
                sx={{ fontSize: "20px", color: "#000", fontWeight: 600 }}
              >
                Free
              </Typography>
            ) : (
              <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                <Typography
                  sx={{ fontSize: "20px", color: "#000", fontWeight: 600 }}
                >
                  ₹ {sell?.post_details?.discounted_price}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textDecoration: "line-through",
                    fontWeight: 600,
                  }}
                >
                  ₹ {sell?.post_details?.pricing}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", color: "green", fontWeight: 600 }}
                >
                  {(
                    ((sell?.post_details?.pricing -
                      sell?.post_details?.discounted_price) /
                      sell?.post_details?.pricing) *
                    100
                  ).toFixed(0)}
                  {"% off"}
                </Typography>
              </Box>
            )}
          </span>
        </div>
        <div className="sale_page_sale_title">
          <p style={{ overflowX: "hidden" }}>
            {sell?.post_details?.title?.slice(0, 25)}{" "}
            {sell?.post_details?.title?.length > 25 ? "..." : ""}
          </p>
          <img
            src="/Image/Discover/infoIcon.svg"
            alt="loc icon"
            style={{
              width: "12px",
              filter: "grayscale(100%)",
            }}
          />
        </div>
        <div className="sale_page_sale_mode">
          <span className="sale_page_sale_location_span">
            <img
              src="/Image/DashImage/RightSidebar/connect_icon.svg"
              alt="loc icon"
              style={{
                width: "9px",
                filter: "grayscale(100%)",
                marginRight: 5,
              }}
            />
            {sell?.post_details?.location?.slice(0, 65)}{" "}
            {sell?.post_details?.location?.length > 65 ? "..." : ""}
          </span>
          <span className="sale_page_sale_category_span">
            Category : {sell?.post_details?.category}
          </span>
        </div>
      </div>

      <div classname="sale_page_sale_card_bottom">
        <div className="sale_page_sale_interested">
          <span>0 interested : </span>
          <span>1 going </span>
        </div>
        <div className="sale_page_sale_interested_button_cont">
          <button
            onClick={ButtonClick}
            className="sale_page_sale_interested_button"
          >
            {removeFromWishlist ? (
              <DeleteOutlineRoundedIcon sx={{ color: "#fff", mr: 0.5 }} />
            ) : (
              <CalendarMonthIcon sx={{ color: "#fff", mr: 0.5 }} />
            )}

            {removeFromWishlist ? "Remove from wishlist" : "Interested"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaleCardNew;
