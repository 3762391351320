import React from "react";
import DpicFeed from "../../../../../components/commons/DpicFeed";
import EditDescFeed from "./EditDescFeed";
import IconSectionFeed from "../../../../../components/commons/IconSectionFeed";
import StatsFeed from "../../../../../components/commons/StatsFeed";
import Tags from "./Tags";
import styles from "../../../Profile.module.css";
import InstituteCode from "./InstituteCode";
import Languages from "../components/Languages";
import CreateBusiness from "./CreateBusiness";
import { connect } from "react-redux";
import RatingFeed from "./RatingFeed";

class LeftSideBar extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <DpicFeed props={this.props} editpage={true} />
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          <EditDescFeed
            editpage={true}
            editPageSocial={this.props.props.editpage}
          />
          <br />
        </div>

        {this.props.userType === "INDIVIDUAL" && (
          <>
            <div
              style={{
                width: "100%",
              }}
            >
              <CreateBusiness />
              <br />
            </div>
          </>
        )}

        {this.props.userType === "LOCAL BUSINESS" && (
          <div
            style={{
              width: "100%",
            }}
          >
            <RatingFeed editPage={true} />
            <br />
          </div>
        )}

        <div
          style={{
            width: "100%",
          }}
        >
          <Tags editpage={this.props.props.editpage} />
          <br />
        </div>

        <div
          className={styles.icon_section_feed}
          style={{
            width: "100%",
          }}
        >
          <IconSectionFeed />
          <br />
        </div>

        {this.props.userType === "INDIVIDUAL" && (
          <div
            className={styles.icon_section_feed}
            style={{
              width: "100%",
            }}
          >
            <Languages />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    userType: state?.profile?.user?.user_type,
  };
};

export default connect(mapStateToProps, null)(LeftSideBar);
