import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconTitle from "../../components/commons/IconTitle";
import styles from "./css/ViewAssignmentPage.module.css";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import AssignmentListIndividual from "../../components/assignmentAndQuiz/AssignmentListIndividual";
import DropDown from "../../components/assignmentAndQuiz/DropDown/DropDown";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";
import {
  getIndividualAssignment,
  deleteAssignment,
} from "../../redux/actions/assignment";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DueDate from "../../components/assignmentAndQuiz/DueDate";
import { deleteQuiz, getIndividualQuiz } from "../../redux/actions/quiz";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";
import { CircularProgress } from "@mui/material";

const ViewAssignmentPage = (props) => {
  // PropsUsed: assignmentClass, assignment_id, assignmentType
  // props.isOwner and props.viaMail coming from OpenAsgFromMail component, when the owner open the assignment or quiz from email.

  //--- useSelector Section
  const assignment = useSelector((state)=>props.assignmentType == "QUIZ"
  ? state?.quiz?.quizInd
  : state?.assignment?.assignment || {})

  const isIdValid = useSelector((state)=>state?.errors?.statusCode === 400 ? false : true)


  const [activeSection, setActiveSection] = useState("Assigned");
  const [isLoading, setIsLoading] = useState(true);

  const changeActiveSect = (selectedSection) => {
    setActiveSection(selectedSection);
  };

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const routeType = location?.state?.routeType ?? props?.routeType;
  const isOwner =  location?.state?.isOwner ?? (props?.isOwner || false);
  const showFourZeroFour = !isOwner || !isIdValid  
  // console.log("viewAsgPage routeType",routeType);
  // console.log("viewAsgPage isOwner", isOwner);
  const dispatch = useDispatch();
  
  

  useEffect(() => {
    // Pass assignment id via the ViewAssignment Modal from the class feed
    const assignmentId = props.viaMail ? window.location.pathname.split("/")[4] : window.location.pathname.split("/")[3];
    if (props.assignmentType == "QUIZ") {
      dispatch(getIndividualQuiz(assignmentId)).then(()=>{setIsLoading(false)});
    }
    if (props.assignmentType == "ASSIGNMENT") {
      dispatch(getIndividualAssignment(assignmentId)).then(()=>{setIsLoading(false)});
    }
  }, []);

  const current_assignment = assignment;

  const studentWorkClickHandler = () => {
    if (props.assignmentType == "QUIZ") {
      history.push({
        pathname: `/quiz/status/${current_assignment.id}`,
        state: { assignment: assignment, routeType: routeType, isOwner: isOwner},
      });
    } else {
      history.push({
        pathname: `/assignment/status/${current_assignment.id}`,
        state: { assignment: assignment, routeType: routeType, isOwner: isOwner },
      });
    }
  };

  // ---------- Edit/Delete Menu ----------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editClickHandler = () => {
    setAnchorEl(null);
    // console.log("EDIT TIME");
    if (props.assignmentType == "QUIZ") {
      history.push({
        pathname: `/quiz/create/${assignment?.classes ?? assignment?.groups}`,
        state: { assignment: assignment, actionType: "edit", routeType: routeType, isOwner: isOwner },
      });
    } else {
      history.push({
        pathname: `/assignment/create/${assignment?.classes ?? assignment?.groups}`,
        state: { assignment: assignment, actionType: "edit", routeType: routeType, isOwner: isOwner },
      });
    }
  };

  const deleteClickHandler = () => {
    setAnchorEl(null);
    if(routeType === "group"){
      if (props.assignmentType == "QUIZ") {
        dispatch(deleteQuiz(assignment.id));
        history.push({
          pathname: `/group/${assignment?.groups}`,
          state: { activeSection: "Assignments" },
        });
      } else {
        dispatch(deleteAssignment(assignment.id));
        history.push({
          pathname: `/group/${assignment?.groups}`,
          state: { activeSection: "Assignments" },
        });
      }

    } else {
      if (props.assignmentType == "QUIZ") {
        dispatch(deleteQuiz(assignment.id));
        history.push({
          pathname: `/class/${assignment?.classes}`,
          state: { activeSection: "Assignments" },
        });
      } else {
        dispatch(deleteAssignment(assignment.id));
        history.push({
          pathname: `/class/${assignment?.classes}`,
          state: { activeSection: "Assignments" },
        });
      }
    }
  };

  //----------------------------------------

  return (
    <>
      { isLoading ? (
      <div className={styles.loader_style}>
        <CircularProgress sx={{color: "#00d498"}} size={50} />
      </div>
    ) : showFourZeroFour ? <ErrorFourZeroFour/> :  
      <div className={styles.main_container}>
      {/* <h5 className={styles.back_btn} onClick={() => {history.goBack(), history.push({state: {activeSection:'Assignments'}})}}> */}
      <p
        className={styles.back_btn}
        onClick={() => { props.viaMail ? history.push("/user") : history.goBack()}
          // history.push({
          //   pathname: `/class/${props.assignment?.classes}`,
          //   state: { activeSection: "Assignments" },
          // })
        }
      >
        <img src="/Image/assignment/back_arrow.svg" alt="" />
        &nbsp; &nbsp;{assignment?.class_name || assignment?.group_name || t("common.back")}
      </p>

      {/* {console.log("ID_VALID????-- ", props.isIdValid)} */}

      <div className={styles.feed_container}>
        <div className={styles.title_container}>
          <IconTitle
            title={current_assignment?.title}
            img="/Image/assignment/assignment-2.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />
          <span className={styles.duedate_component_mobile}>
            <DueDate date={current_assignment?.due_date} />
          </span>

          <div className={styles.dueDate}>
            <span className={styles.duedate_component}>
              <DueDate date={current_assignment?.due_date} />
            </span>

            <div className={styles.dropdown}>
              <img
                className={styles.threeDotsMenu}
                src="/Image/assignment/dropdown.svg"
                alt=""
                onClick={handleClick}
              />

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  style: {
                    boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                  },
                }}
              >
                <MenuItem onClick={editClickHandler}>
                  {t("common.edit")}
                </MenuItem>
                <MenuItem onClick={deleteClickHandler}>
                  {t("common.delete")}
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        <div className={styles.submissions_container}>
          <div className={styles.choice_menu}>
            <div className={styles.choice}>
              <div
                className={styles.status}
                onClick={() => changeActiveSect("Assigned")}
              >
                <span
                  className={styles.count}
                  style={{
                    color: activeSection == "Assigned" ? "#00D498" : "black",
                  }}
                >
                  {current_assignment?.assigned}
                </span>
                <span
                  className={styles.options}
                  style={{
                    color: activeSection == "Assigned" ? "#00D498" : "#7b7b7b",
                  }}
                >
                  {t("viewAssignment.assigned")}
                </span>
              </div>

              <div
                className={styles.status}
                onClick={() => changeActiveSect("Submitted")}
              >
                <span
                  className={styles.count}
                  style={{
                    color: activeSection == "Submitted" ? "#00D498" : "black",
                  }}
                >
                  {current_assignment?.submitted}
                </span>
                <span
                  className={styles.options}
                  style={{
                    color: activeSection == "Submitted" ? "#00D498" : "#7b7b7b",
                  }}
                >
                  {t("viewAssignment.submitted")}
                </span>
              </div>

              <div
                className={styles.status}
                onClick={() => changeActiveSect("Graded")}
              >
                <span
                  className={styles.count}
                  style={{
                    color: activeSection == "Graded" ? "#00D498" : "black",
                  }}
                >
                  {current_assignment?.graded}
                </span>
                <span
                  className={styles.options}
                  style={{
                    color: activeSection == "Graded" ? "#00D498" : "#7b7b7b",
                  }}
                >
                  {t("viewAssignment.graded")}
                </span>
              </div>

              {/* --------- notAssigned Section */}

             {props.assignmentType == "ASSIGNMENT" &&  <div
                className={styles.status}
                onClick={() => changeActiveSect("notAssigned")}
              >
                <span
                  className={styles.count}
                  style={{
                    color: activeSection == "notAssigned" ? "#00D498" : "black",
                  }}
                >
                  {current_assignment?.not_assigned ?? 0}  
                </span>
                <span
                  className={styles.options}
                  style={{
                    color: activeSection == "notAssigned" ? "#00D498" : "#7b7b7b",
                  }}
                >
                  {t("viewAssignment.notAssigned")}
                </span>
              </div>}

            </div>
            <div className={styles.dropdown_mobile}>
              <img
                className={styles.threeDotsMenu}
                src="/Image/assignment/dropdown.svg"
                onClick={handleClick}
              />
              {/* {showMenu ? console.log("Found") : console.log("NoMenuFound")} */}
              {/* <> </> */}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  style: {
                    boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                  },
                }}
              >
                <MenuItem onClick={editClickHandler}>
                  {t("common.edit")}
                </MenuItem>
                <MenuItem onClick={deleteClickHandler}>
                  {t("common.delete")}
                </MenuItem>
              </Menu>
            </div>
          </div>
          <span className={styles.std_work_btn}>
            <Button
              label={t("assignment.studentWork")}
              clickHandler={studentWorkClickHandler}
            />
          </span>
        </div>
        <div className={styles.assignment_details_container}>
          <div className={styles.assignment_details_description}>
            <p>{current_assignment?.details}</p>
          </div>
          <div className={styles.attachment_container_div}>
            {current_assignment?.file != null ? (
              <>
                <br />
                <AttachmentContainer
                  assignmentFileType="FILE"
                  fileName={`${current_assignment?.file
                    ?.split("/")
                    .pop()
                    .substr()}`}
                  fileSize=""
                  file={current_assignment?.file}
                  // link="google.com"
                  pageType=""
                />
              </>
            ) : null}
            {current_assignment?.url != null ? (
              <>
                <br />
                <AttachmentContainer
                  assignmentFileType="LINK"
                  linkName={current_assignment?.url
                    ?.split("/")
                    .pop()
                    .substr()}
                  fileSize=""
                  link={current_assignment?.url}
                  pageType=""
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div> }
    </>    
  );
};
export default ViewAssignmentPage;

// const mapStateToProps = (state, props) => {
//   return {
//     // class_id: state?.classes?.class?.id || 0,
//     assignment:
//       props.assignmentType == "QUIZ"
//         ? state?.quiz?.quizInd
//         : state?.assignment?.assignment || {},
//     isIdValid: state?.errors?.statusCode === 400 ? false : true,  
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getIndividualAssignment: (assignment_id) =>
//       dispatch(getIndividualAssignment(assignment_id)),
//     deleteAssignment: (assignment_id) =>
//       dispatch(deleteAssignment(assignment_id)),
//     getIndividualQuiz: (quiz_id) => dispatch(getIndividualQuiz(quiz_id)),
//     deleteQuiz: (quiz_id) => dispatch(deleteQuiz(quiz_id)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(ViewAssignmentPage);

