import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getGroups,
  joinGroupByCode,
  joinGroup,
} from "../../../redux/actions/groups";
import styles from "./CSSFiles/MyClass.module.css";
import JoinGroupModal from "./JoinGroupModal";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { store } from "../../../redux/store";

class MyGroup extends Component {
  state = {
    showShareModal: false,
    joinGroupCode: "",
    searchedGroup: {},
    code: "",
  };
  componentDidMount() {
    const { match } = this.props;
    if (match.params.code) {
      this.setState({ code: match.params.code });
      this.setState({ showShareModal: true });
      this.addGroupMember(null, match.params.code);
    }
  }

  shareModalOpener = () => {
    this.setState({ showShareModal: true });
  };
  shareModalCloser = () => {
    this.setState({ showShareModal: false });
    const { match } = this.props;
    if (match.params.code) {
      let { history } = this.props;
      history.push({
        pathname: `/`,
      });
    }
  };
  addGroupMember = (e, code) => {
    let joinGroupCodeOnly = "";
    if (code) {
      joinGroupCodeOnly = code;
    } else {
      joinGroupCodeOnly = this.state.joinGroupCode;
    }
    this.props.joinGroupByCode({ group_code: joinGroupCodeOnly }).then(() => {
      const data = store.getState().groups?.searchedGroup;
      this.setState({ searchedGroup: { ...data } });
    });
  };

  joinGroupMember = (id) => {
    this.props.joinGroup({ group_id: id }).then(() => {
      let { history } = this.props;
      history.push({
        pathname: `/group/${id}`,
        state: { subjectId: id },
      });
    });
  };

  changeJoinGroupCode = (e) => {
    this.setState({ joinGroupCode: e.target.value });
  };
  render() {
    return (
      <div className={styles.dropdown_menu1}>
        <ul>
          <div className={styles.menu1}>
            <Link to="/groups" style={{ textDecoration: "none" }}>
              <li style={{ marginTop: 6, marginBottom: 6, color: "#555555" }}>
                {this.props.t("common.myGroups")}
              </li>
            </Link>
            <hr className={styles.Hr3} />
            <li
              onClick={this.shareModalOpener}
              style={{ marginTop: 6, marginBottom: 6, color: "#555555" }}
              className={styles.menuList}
            >
              {this.props.t("common.joinPrivateGroup")}
            </li>
            <JoinGroupModal
              show={this.state.showShareModal}
              code={this.state.code}
              shareModalCloser={this.shareModalCloser}
              addGroupMember={this.addGroupMember}
              changeJoinGroupCode={this.changeJoinGroupCode}
              searchedGroup={this.state.searchedGroup}
              joinGroupMember={this.joinGroupMember}
            />
          </div>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    joinGroupByCode: (data) => dispatch(joinGroupByCode(data)),
    joinGroup: (data) => dispatch(joinGroup(data)),
    getGroups: () => dispatch(getGroups()),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MyGroup))
);
