import React from 'react'
import styles from "./css/StudentCount.module.css"
import { useTranslation } from 'react-i18next';

const StudentCount = (props) => {

  const count = props.count ?? 0;
  const {t} = useTranslation();
  
  return (
    <div className={styles.student_count}>
      <img style={{ width: 16, height: 16 }}  src="/Image/CourseMonetisation/student1.svg" alt="" />
      <span>{(count > 1) ? (count + ` ${t("courseMonetisation.students")}`) : count + ` ${t("courseMonetisation.student")}` }</span>
    </div>
  )
}

export default StudentCount;