import React, { Component } from "react";
import { ModalWithoutHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/career.module.css";
// import Career from '././Image/DashboardImages/careerModalImages/careermodal.svg';

export default class paymentSuccessModal extends Component {
  render() {
    return (
      <div>
        <ModalWithoutHeader maxWidth={this.props.maxWidth} closeHandler={this.props.closeModal} show={this.props.show}>
          <div className={styles.outerContainer} style={{ maxWidth: "100%"}}>
            <div className={styles.comingSoon} style={{font: "normal normal bold 36px/14px Montserrat"}}>Congratulations!</div>
            <span onClick={this.props.closeModal} className={styles.close}>
              <img src="/Image/cross.svg" />
            </span>
            <div className={styles.bottomContainer}>
              <img className={styles.diamond} src="/Image/confetti.svg" />
              <p className={styles.desc}>
              You have successfully subscribed for the Pro Plan.  It will get renewed automatically next month. You can now enjoy all the pro features.
              </p>
            </div>
          </div>
        </ModalWithoutHeader>
      </div>
    );
  }
}
