import axios from "axios";
import {
  ADD_PRIVATE_COMMENT,
  CREATE_CLASS_QUIZ,
  DELETE_CLASS_QUIZ,
  GET_ALL_FILTERED_QUIZ_SUBMISSIONS,
  GET_ALL_QUIZ_SUBMISSIONS,
  GET_INDIVIDUAL_QUIZ,
  GET_INDIVIDUAL_QUIZ_SUBMISSIONS,
  GET_QUIZ_LIST,
  GET_QUIZ_STUDENT_LIST,
  GET_STUDENT_INDIVIDUAL_QUIZ,
  GET_STUDENT_QUIZ_SUBMISSION,
  REMOVE_STUDENT_SUBMISSION,
  SAVE_QUIZ_MARKS,
  SUBMIT_QUIZ_SOLUTION,
  UPDATE_CLASS_QUIZ,
  UPDATE_STUDENT_QUIZ_SOLUTION,
  RETURN_QUIZ_MARKS,
  GET_STUDENT_QUIZ_LIST,
  PROGRESS_BAR_COUNT_QUIZ,
  IS_LOADING_QUIZ,
  ERROR_STATUS_CODE,
  CLEAR_QUIZ_SUBMISSION,
  DISPLAY_PRIVATE_COMMENT_ASSIGNMENT,
  DISPLAY_PRIVATE_COMMENT_QUIZ
} from "../constants/actionTypes";
import {
  GET_QUIZ_LIST_API,
  GET_STUDENT_INDIVIDUAL_QUIZ_API,
  GET_STUDENT_QUIZ_SUBMISSION_API,
  SUBMIT_QUIZ_SOLUTION_API,
  UPDATE_STUDENT_QUIZ_SOLUTION_API,
  POST_QUIZ_API,
  GET_INDIVIDUAL_QUIZ_API,
  PUT_QUIZ_API,
  DELETE_QUIZ_API,
  GET_QUIZ_STUDENT_LIST_API,
  RETURN_QUIZ_MARKS_API,
  SAVE_QUIZ_MARKS_API,
  GET_ALL_QUIZ_SUBMISSIONS_API,
  GET_INDIVIDUAL_QUIZ_SUBMISSIONS_API,
  GET_STUDENT_QUIZ_LIST_API,
  POST_QUIZ_COMMENTS,
  GET_QUIZ_COMMENTS,
  GET_ASS_COMMENTS,
  POST_ASS_COMMENTS,
} from "../constants/apis";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import quiz from "../reducers/quiz";
export const getAllQuizzes = (classId, type) => {
  const token = store.getState()?.profile?.user?.token;
  let idType;
  if (type == "group") {
    idType = "group_id";
  } else {
    idType = "class_id";
  }
  let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
  return async (dispatch) => {
    await axios
      .get(`${GET_QUIZ_LIST_API}?${idType}=${classId}&?target=${lang}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const quizzes = response?.data?.data.sort((a, b) => {
            return b.id - a.id;
          });
          dispatch({ type: GET_QUIZ_LIST, payload: quizzes });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const studentIndividualQuiz = (id) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
  return async (dispatch) => {
    await axios
      .get(`${GET_STUDENT_INDIVIDUAL_QUIZ_API}${id}/?target=${lang}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const quizInd = response?.data?.data;
          if (response?.data?.data?.submission_id !== null && response?.data?.data?.submission_id !== undefined) {
            dispatch(
              getQuizStudentSubmission(response?.data?.data?.submission_id)
            );
          } else {
            dispatch({ type: REMOVE_STUDENT_SUBMISSION, payload: null });
          }
          dispatch({ type: GET_STUDENT_INDIVIDUAL_QUIZ, payload: quizInd });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getQuizStudentSubmission = (submissionId) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(`${GET_STUDENT_QUIZ_SUBMISSION_API}${submissionId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const submission = response?.data?.data;
          dispatch({ type: GET_STUDENT_QUIZ_SUBMISSION, payload: submission });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const submitQuizSolution = (submissionData) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  let percent = 0;
  formData.append("quiz", submissionData.assignmentId);
  if (submissionData?.file != null) {
    formData.append(
      "file_submitted",
      submissionData?.file,
      submissionData?.file?.name
    );
  }
  if (submissionData?.url != null) {
    formData.append("url", submissionData.url);
  }
  return async (dispatch) => {
    dispatch({ type: IS_LOADING_QUIZ, payload: true });
    await axios({
      method: "post",
      url: `${SUBMIT_QUIZ_SOLUTION_API}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: formData,
      // onUploadProgress:(progressEvent) => {
      //   const {loaded, total} = progressEvent;
      //   percent = Math.floor((loaded * 100) / total)
      //   // console.log( `${loaded}kb of ${total}kb | ${percent}%` )
      //   dispatch({ type: PROGRESS_BAR_COUNT_QUIZ, payload: percent });
      // }
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const submission = response?.data?.data;
          dispatch({ type: SUBMIT_QUIZ_SOLUTION, payload: submission });
          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.quizSubmittedSuccessfully")
            )
          );
          dispatch({ type: IS_LOADING_QUIZ, payload: false });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        dispatch({ type: IS_LOADING_QUIZ, payload: false });
        return error;
      });
  };
};

export const updateStudentQuizSolution = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  if (data?.file != null && data?.file?.name) {
    formData.append("file_submitted", data?.file, data?.file?.name);
  }
  if (data?.url !== null) {
    formData.append("url", data.url);
  }
  if (data?.file?.name === undefined && data?.url === undefined) {
    return async (dispatch) => {
      dispatch(
        handleSuccessMessage(
          i18n.t("quizSuccessMessages.quizUpdatedSuccessfully")
        )
      );
    };
  }
  return async (dispatch) => {
    dispatch({ type: IS_LOADING_QUIZ, payload: true });
    await axios({
      method: "put",
      url: `${UPDATE_STUDENT_QUIZ_SOLUTION_API}${data.submissionId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: formData,
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const submission = response?.data?.data;
          dispatch({ type: UPDATE_STUDENT_QUIZ_SOLUTION, payload: submission });
          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.quizSubmissionUpdatedSuccessfully")
            )
          );
          dispatch({ type: IS_LOADING_QUIZ, payload: false });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        dispatch({ type: IS_LOADING_QUIZ, payload: false });
        return error;
      });
  };
};

export const clearQuizSubmission = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_QUIZ_SUBMISSION, payload: {} });
  };
};

// Teachers quiz actions

export const createQuiz = (formData, type) => {
  const token = store.getState()?.profile?.user?.token;
  let data = new FormData();
  if (type == "group") {
    data.append("groups", formData.classes);
  } else {
    data.append("classes", formData.classes);
  }
  data.append("title", formData.title);
  data.append("details", formData.details);
  data.append("max_marks", formData.max_marks);
  data.append("due_date", formData.due_date);
  data.append("is_lock", formData.is_lock);
  if (formData.url != null) {
    data.append("url", formData.url);
  }
  if (formData.file != null) {
    data.append("file", formData.file);
  }
  return async (dispatch) => {
    await axios({
      method: "post",
      url: `${POST_QUIZ_API}`,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({
            type: CREATE_CLASS_QUIZ,
            Ppayload: response.data.data,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.quizCreatedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
      });
  };
};

export const getIndividualQuiz = (id) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
  return async (dispatch) => {
    await axios
      .get(`${GET_INDIVIDUAL_QUIZ_API}${id}/?target=${lang}/`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({
            type: GET_INDIVIDUAL_QUIZ,
            payload: response?.data?.data,
          });
        }
      })
      .catch(async (error) => {
        if(error.response.status === 400){
          dispatch({
            type: ERROR_STATUS_CODE,
            payload: error.response.status,
          })
        } else {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        }        
        return error;
      });
  };
};

export const updateQuiz = (formData, type) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${PUT_QUIZ_API}${formData.id}/`;

  return async (dispatch) => {
    let data = new FormData();
    data.append("title", formData.title);
    data.append("details", formData.details);
    data.append("max_marks", formData.max_marks);
    data.append("due_date", formData.due_date);
    data.append("is_lock", formData.is_lock);
    if (formData.url != null) {
      data.append("url", formData.url);
    }
    //   data.append("file", formData.file);
    if (type == "group") {
      data.append("groups", formData.classes);
    } else {
      data.append("classes", formData.classes);
    }

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: UPDATE_CLASS_QUIZ,
            payload: response.data.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.quizUpdatedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const deleteQuiz = (id) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_QUIZ_API}${id}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch({ type: DELETE_CLASS_QUIZ, payload: id });
          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.quizDeletedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            i18n.t("errorMessageTranslations.cantDeleteError"),
            ": ",
            error
          )
        );
        return error;
      });
  };
};

export const getQuizStudentList = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_QUIZ_STUDENT_LIST_API}?quiz_id=${id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const student_list = response.data.data;
          dispatch({
            type: GET_QUIZ_STUDENT_LIST,
            payload: student_list,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const returnQuizMarks = (data) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    const url = `${RETURN_QUIZ_MARKS_API}`;

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: RETURN_QUIZ_MARKS,
            payload: data.submission_list,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.returnSuccessMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const saveQuizMarks = (formData) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    let data = new FormData();
    data.append("marks", formData.marks);

    const url = `${SAVE_QUIZ_MARKS_API}${formData.id}/`;

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch({
            type: SAVE_QUIZ_MARKS,
            payload: response.data.data,
          });
          dispatch(
            handleSuccessMessage(
              i18n.t("quizSuccessMessages.quizGradedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getAllQuizSubmissions = (quiz_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_ALL_QUIZ_SUBMISSIONS_API}?quiz_id=${quiz_id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const quiz_submissions = response.data.data;

          dispatch({
            type: GET_ALL_QUIZ_SUBMISSIONS,
            payload: quiz_submissions,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ------------------ Get All Filtered Assignment Submissions ---------

export const getAllFilteredQuizSubmissions = (quiz_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_ALL_QUIZ_SUBMISSIONS_API}?quiz_id=${quiz_id}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const quiz_submissions = response.data.data;

          const filtered_submissions_list = quiz_submissions.filter(
            (item) =>
              item.submission_status === "SUB" ||
              item.submission_status === "DL"
          );

          dispatch({
            type: GET_ALL_FILTERED_QUIZ_SUBMISSIONS,
            payload: filtered_submissions_list,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------

export const getIndividualQuizSubmission = (submission_id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = `${GET_INDIVIDUAL_QUIZ_SUBMISSIONS_API}${submission_id}/`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          const quiz_submission = response.data.data;
          dispatch({
            type: GET_INDIVIDUAL_QUIZ_SUBMISSIONS,
            payload: quiz_submission,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getStudentQuizList = (id, type) => {
  const token = store.getState()?.profile?.user?.token;
  let idType = "";
  if (type === "group") {
    idType = "group_id";
  } else {
    idType = "class_id";
  }
  return async (dispatch) => {
    await axios
      .get(`${GET_STUDENT_QUIZ_LIST_API}?${idType}=${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const assignments = response?.data?.data;
          dispatch({ type: GET_STUDENT_QUIZ_LIST, payload: assignments });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

//post quiz and assignment comments api call action
export const addPrivateComment = (type, comment, quiz_id, student_id) => {
  console.log('insideAdd', {type, comment, quiz_id, student_id});
  const token = store.getState()?.profile?.user?.token;
  let data = new FormData();
  data.append(type === "QUIZ" ? "quiz" : "assignment", quiz_id);
  data.append("student_access", student_id);
  data.append("description", comment);

  return async (dispatch) => {
    await axios({
      method: "post",
      url: type === "QUIZ" ? `${POST_QUIZ_COMMENTS}` : `${POST_ASS_COMMENTS}`,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then((response) => {
      if (
        response.data.status == "1" &&
        response.status >= 200 &&
        response.status < 300
      ) {
        dispatch({
          type: ADD_PRIVATE_COMMENT,
          payload: response.data.data,
        });
      }
    });
  };
};

//get quiz and assignment comments api call action
export const getPrivateComment = (type, quiz_id, student_id = 357) => {
  console.log('insideCommentAction', {type, quiz_id, student_id})
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(
        type === "QUIZ"
          ? `${GET_QUIZ_COMMENTS}=${quiz_id}&student_access=${student_id}`
          : `${GET_ASS_COMMENTS}=${quiz_id}&student_access=${student_id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if(type === "QUIZ"){
            dispatch({
              type: DISPLAY_PRIVATE_COMMENT_QUIZ,
              payload: response.data.data,
            });

          } else {
            dispatch({
              type: DISPLAY_PRIVATE_COMMENT_ASSIGNMENT,
              payload: response.data.data,
            });

          }
          
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
