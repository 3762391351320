import { Dialog, ListItemText } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

function OptionModal(props) {
    const { open, handleListItemClick, handleClose, showEdit = false } = props;

    return (
        <Dialog
            sx={{ "& .MuiDialog-paper": { width: "30%", maxHeight: 435 } }}
            onClose={handleClose}
            open={open}
        >
            <List sx={{ pt: 0 }}>
                <ListItem disableGutters key="">
                    <ListItemButton
                        onClick={() => handleListItemClick("delete")}
                    >
                        <ListItemText primary="Delete" />
                    </ListItemButton>
                </ListItem>
                {showEdit && (
                    <ListItem disableGutters key="">
                        <ListItemButton
                            onClick={() => handleListItemClick("edit")}
                        >
                            <ListItemText primary="Edit" />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}

export default OptionModal;
